import { axiosApiAdmin } from "helpers/api_helper";
import {
  ADD_EDIT_SMS_SERVICE_URL,
  ADD_SERVICE_URL,
  ADD_SITE_URL,
  DELETE_APP_MENU_URL,
  DELETE_SERVICE_URL,
  DELETE_SITE_PAYMENT_SERVICE_URL,
  FETCH_AFFILIATE_REFERRAL_URL,
  FETCH_PAYMENT_SERVICE_URL,
  FETCH_RATE_CONFIGURATION_URL,
  FETCH_SERVER_CREDENTIAL_URL,
  FETCH_SMS_SERVERS_MASTER_DATA,
  GET_AFFILIATE_MODAL_VIEW,
  GET_AFFILIATE_PAYOUT_LISTING,
  GET_DASHBOARD_PAGE_CONTACT_API,
  GET_HIGHEST_CONVERTING_URLS,
  GET_LATEST_AFFILIATE_REGISTRATIONS,
  GET_MostValuable_Affiliates,
  GET_Recent_Referrals,
  GET_SYSTEM_CONFIGURATION,
  LIST_AFFILIATE,
  LIST_AFFILIATE_VISIT_DATA_URL,
  LIST_ALL_APP_MENU,
  LIST_ALL_SERVICES,
  LIST_ALL_SITES_URL,
  LIST_INTAKE_FORM_URL,
  LIST_SMS_SERVICES_CREDENTIALS,
  LIST_SYSTEMPAGE_AND_PAGELINK,
  ORDER_APP_MENU_URL,
  SAVE_APP_MENU_URL,
  SAVE_DASHBOARD_PAGE_CONTACT_API,
  SAVE_INTAKE_FORM_DATA_URL,
  SAVE_PAYMENT_SERVICE_URL,
  SAVE_RATE_CONFIGURATION_URL,
  UPDATE_SYSTEM_CONFIGURATION,
} from "helpers/url_helper";
import { toast } from "react-toastify";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  addEditServiceError,
  addEditServiceSuccess,
  addEditSiteError,
  addEditSiteSuccess,
  deleteEmailServiceError,
  deleteEmailServiceSuccess,
  deleteSitePaymentServiceError,
  deleteSitePaymentServiceSuccess,
  fetchAffiliatePayoutError,
  fetchAffiliatePayoutSuccess,
  fetchAffiliateReferralError,
  fetchAffiliateReferralSuccess,
  fetchHighestConvertingUrlsError,
  fetchHighestConvertingUrlsSuccess,
  fetchHomeDashboardError,
  fetchHomeDashboardSuccess,
  fetchLatestAffiliateUsersError,
  fetchLatestAffiliateUsersSuccess,
  fetchMostValuableAffiliateError,
  fetchMostValuableAffiliateSuccess,
  fetchPaymentServiceError,
  fetchPaymentServiceSuccess,
  fetchRateConfigurationError,
  fetchRateConfigurationSuccess,
  fetchRecentReferralsError,
  fetchRecentReferralsSuccess,
  fetchServerCredentialError,
  fetchServerCredentialSuccess,
  fetchSitesSuccess,
  fetchSmsServerCredentialError,
  fetchSmsServerCredentialSuccess,
  getAffiliateError,
  getAffiliateSuccess,
  getAllMenuError,
  getAllMenuList,
  getAllMenuSuccess,
  getServicesError,
  getServicesSuccess,
  getSmsServicesError,
  getSmsServicesSuccess,
  getSystemConfigurationError,
  getSystemConfigurationSuccess,
  listAffiliateVisitDataError,
  listAffiliateVisitDataSuccess,
  listSystemPageLinkError,
  listSystemPageLinkSuccess,
  orderApplicationMenuError,
  orderApplicationMenuSuccess,
  saveApplicationMenuError,
  saveApplicationMenuSuccess,
  savePaymentServiceError,
  savePaymentServiceSuccess,
  saveRateConfigurationError,
  saveRateConfigurationSuccess,
  saveSystemConfigError,
  saveSystemConfigSuccess,
  setAffiliateModalData,
  setAffiliateModalDataLoading,
  setErrorMessage,
  setSuccessMessage,
} from "store/actions";
import {
  ADD_EDIT_SERVICE,
  ADD_EDIT_SITE,
  ADD_EDIT_SMS_SERVICE,
  DELETE_APP_MENU,
  DELETE_EMAIL_SERVICE,
  DELETE_SITE_PAYMENT_SERVICE,
  FETCH_AFFILIATE_MODAL_DATA,
  FETCH_AFFILIATE_PAYOUT,
  FETCH_AFFILIATE_REFERRAL,
  FETCH_HIGHEST_CONVERTING_URLS,
  FETCH_LATEST_AFFILIATE_USERS,
  FETCH_MOST_VALUABLE_AFFILIATES,
  FETCH_PAYMENT_SERVICE,
  FETCH_RATE_CONFIGURATION,
  FETCH_RECENT_REFERRALS,
  FETCH_SERVER_CREDENTIAL,
  FETCH_SITES,
  FETCH_SMS_SERVER_CREDENTIAL,
  GET_AFFILIATE,
  GET_ALL_APP_MENU,
  GET_DASHBOARD_PAGE_CONTACT,
  GET_SERVICE,
  GET_SMS_SERVICE,
  GET_SYSTEM_CONFIG,
  INTAKE_LIST,
  INTAKE_LIST_ERROR,
  INTAKE_LIST_LOADING,
  LIST_AFFILIATE_VISIT_DATA,
  LIST_VIDEO,
  LIST_VIDEO_ERROR,
  LIST_VIDEO_LOADING,
  ORDER_APP_MENU,
  SAVE_APP_MENU,
  SAVE_HOME_DASHBOARD_DETAILS,
  SAVE_INTAKE_DATA_LOADING,
  SAVE_INTAKE_DATA_SUCCESS,
  SAVE_PAYMENT_SERVICE,
  SAVE_RATE_CONFIGURATION,
  SAVE_SYSTEM_CONFIG,
  SYSTEM_PAGE_PAGELINK,
} from "./actionTypes";

const fetchPaymentServiceCall = id =>
  axiosApiAdmin.get(`${FETCH_PAYMENT_SERVICE_URL}` + "/" + `${id}`);
const savePaymentServiceCall = payload =>
  axiosApiAdmin.post(SAVE_PAYMENT_SERVICE_URL, payload);
const fetchServices = data => axiosApiAdmin.get(`${LIST_ALL_SERVICES}/${data}`);
const deleteServiceCall = data =>
  axiosApiAdmin.delete(`${DELETE_SERVICE_URL}/${data}`);
const addUpdateService = data => axiosApiAdmin.post(ADD_SERVICE_URL, data);
const fetchServerCredentialCall = () =>
  axiosApiAdmin.get(`${FETCH_SERVER_CREDENTIAL_URL}`);
const deleteSitePaymentServiceCall = id =>
  axiosApiAdmin.delete(`${DELETE_SITE_PAYMENT_SERVICE_URL}/${id}`);
const addUpdateSite = data => axiosApiAdmin.post(ADD_SITE_URL, data);
const fetchSitesCall = () => axiosApiAdmin.get(LIST_ALL_SITES_URL);
const fetchAllAppMenu = data => axiosApiAdmin.post(LIST_ALL_APP_MENU, data);
const saveAppMenu = data => axiosApiAdmin.put(SAVE_APP_MENU_URL, data);
const deleteAppMenu = data => axiosApiAdmin.put(DELETE_APP_MENU_URL, data);
const orderAppMenu = data => axiosApiAdmin.put(ORDER_APP_MENU_URL, data);
const fetchSmsServicesCredentials = data =>
  axiosApiAdmin.get(LIST_SMS_SERVICES_CREDENTIALS + `${data.siteId}`);
const fetchSmsServersMasterData = () =>
  axiosApiAdmin.get(`${FETCH_SMS_SERVERS_MASTER_DATA}`);

const addUpdateSmsService = data =>
  axiosApiAdmin.post(ADD_EDIT_SMS_SERVICE_URL, data);

const fetchSystemConfig = data =>
  axiosApiAdmin.post(GET_SYSTEM_CONFIGURATION, data);
const saveSystemConfiguration = data =>
  axiosApiAdmin.post(UPDATE_SYSTEM_CONFIGURATION, data);

const fetchSystemPageLink = data =>
  axiosApiAdmin.post(LIST_SYSTEMPAGE_AND_PAGELINK, data);

const getAllAffiliate = data => axiosApiAdmin.post(LIST_AFFILIATE, data);
const fetchRateConfigurationCall = () =>
  axiosApiAdmin.get(`${FETCH_RATE_CONFIGURATION_URL}`);
const saveRateConfigurationCall = payload =>
  axiosApiAdmin.put(SAVE_RATE_CONFIGURATION_URL, payload);
const fetchAffiliateLatestUsers = payload =>
  axiosApiAdmin.get(GET_LATEST_AFFILIATE_REGISTRATIONS, payload);
const fetchHighestConvertingUrls = payload =>
  axiosApiAdmin.get(GET_HIGHEST_CONVERTING_URLS, payload);
const fetchMostValuableAffiliate = payload =>
  axiosApiAdmin.get(GET_MostValuable_Affiliates, payload);
const fetchRecentReferrals = payload =>
  axiosApiAdmin.get(GET_Recent_Referrals, payload);
const fetchAffiliatePayout = payload =>
  axiosApiAdmin.post(GET_AFFILIATE_PAYOUT_LISTING, payload);
const fetchAffiliateVisitData = data =>
  axiosApiAdmin.post(LIST_AFFILIATE_VISIT_DATA_URL, data);
const fetchAffiliateReferralCall = payload =>
  axiosApiAdmin.post(FETCH_AFFILIATE_REFERRAL_URL, payload);
const getModalAffiliateData = data =>
  axiosApiAdmin.post(GET_AFFILIATE_MODAL_VIEW, data);
const fetchVideosForTV = data => axiosApiAdmin.post("/listAllVideo", data);
const fetchHomeDashboardCall = () =>
  axiosApiAdmin.get(`${GET_DASHBOARD_PAGE_CONTACT_API}`);
const saveHomeDashboardCall = payload => {
  axiosApiAdmin.post(SAVE_DASHBOARD_PAGE_CONTACT_API, payload);
};

const fetchIntakeCall = payload =>
  axiosApiAdmin.post(LIST_INTAKE_FORM_URL, payload);
const saveIntakeDataCall = payload =>
  axiosApiAdmin.put(SAVE_INTAKE_FORM_DATA_URL, payload);

//intake

function* fetchIntakeFormList({ payload }) {
  try {
    const res = yield call(fetchIntakeCall, payload);

    if (res?.data?.data) {
      yield put({ type: INTAKE_LIST, payload: res?.data?.data });
    }
  } catch (err) {
    toast.error(err.response.data.message);
    yield put({ type: INTAKE_LIST_ERROR });
  }
}

function* saveIntakeFormData({ payload: data, cb }) {
  try {
    const res = yield call(saveIntakeDataCall, data.values);

    if (res) {
      yield put({ type: SAVE_INTAKE_DATA_SUCCESS, payload: false });
      cb(res.data.data);
    }
  } catch (err) {
    toast.error(err.response.data.message);
  }
}

function* fetchVideos({ payload }) {
  try {
    const res = yield call(fetchVideosForTV, payload);
    if (res?.data?.data) {
      yield put({ type: LIST_VIDEO, payload: res?.data?.data?.catgory_data });
    }
  } catch (err) {
    toast.error(err.response.data.message);
    yield put({ type: LIST_VIDEO_ERROR });
  }
}

function* handleFetchPaymentService({ id }) {
  const res = yield call(fetchPaymentServiceCall, id);
  if (res?.data?.statusCode === 200 && res?.data?.data)
    yield put(fetchPaymentServiceSuccess(res.data.data.data));
  try {
  } catch (err) {
    yield put(fetchPaymentServiceError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

//Nandini changes 10/3/2023
function* handleFetchHomeDashboard() {
  const res = yield call(fetchHomeDashboardCall);
  if (res?.data?.statusCode === 200 && res?.data?.data)
    yield put(fetchHomeDashboardSuccess(res?.data?.data?.dashboard_data[0]));
  try {
  } catch (err) {
    yield put(fetchHomeDashboardError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleSaveHomeDashboard({ payload, cb }) {
  try {
    const res = yield call(saveHomeDashboardCall, payload);
    if (res) {
      yield put({ type: LIST_VIDEO, payload: res?.data?.data?.catgory_data });
      yield put(setSuccessMessage(res.data.data.message));

      if (!!cb) cb();
    }
  } catch (err) {
    toast.error(err.response.data.message);
    yield put({ type: LIST_VIDEO_ERROR });
  }
}

function* handleSavePaymentService({ payload: { obj, cb } }) {
  try {
    const res = yield call(savePaymentServiceCall, obj);
    if (res.data.statusCode === 200) {
      yield put(savePaymentServiceSuccess(res?.data?.data?.data));
      yield put(setSuccessMessage(res.data.message));
      if (!!cb) cb();
    }
  } catch (err) {
    yield put(savePaymentServiceError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchService() {
  try {
    const { siteId } = yield select(state => state.Applications);
    const res = yield call(fetchServices, siteId);

    if (res.data?.data) {
      yield put(getServicesSuccess(res.data.data.rows));
    }
  } catch (err) {
    yield put(getServicesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAddEditService({ payload: { data, cb } }) {
  try {
    const res = yield call(addUpdateService, data);
    if (res.data.statusCode === 200 && res?.data?.data) {
      yield put(addEditServiceSuccess(res.data.data));
      yield put(setSuccessMessage(res.data.message));
      if (!!cb) cb();
    }
  } catch (err) {
    yield put(addEditServiceError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteEmailService({ payload }) {
  try {
    const res = yield call(deleteServiceCall, payload);
    if (res.status) {
      yield put(deleteEmailServiceSuccess());
      yield put(setSuccessMessage(res.data.message));
    }
  } catch (err) {
    yield put(deleteEmailServiceError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchServerCredential() {
  try {
    const res = yield call(fetchServerCredentialCall);
    if (res.data.statusCode === 200 && res.data?.data?.result) {
      yield put(fetchServerCredentialSuccess(res.data.data.result));
    }
  } catch (err) {
    yield put(fetchServerCredentialError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleDeleteSitePaymentService({ id }) {
  try {
    const res = yield call(deleteSitePaymentServiceCall, id);
    if (res.data.statusCode === 200) {
      yield put(setSuccessMessage(res.data.message));
      yield put(deleteSitePaymentServiceSuccess());
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
    yield put(deleteSitePaymentServiceError());
  }
}

function* handleFetchSites() {
  try {
    const res = yield call(fetchSitesCall);
    if (res.data.statusCode === 200 && res.data?.data?.data?.rows) {
      yield put(fetchSitesSuccess(res.data.data.data.rows));
    }
  } catch (err) {}
}
function* handleAddEditSite({ payload: { values, cb } }) {
  try {
    const res = yield call(addUpdateSite, values);
    if (res.status) {
      yield put(addEditSiteSuccess(values));
      yield put(setSuccessMessage(res.data.message));
      if (!!cb && typeof cb === "function") cb();
    }
  } catch (err) {
    yield put(addEditSiteError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchAppMenuList() {
  try {
    const { siteId, activeMenuType } = yield select(
      state => state.Applications
    );
    let payload = {
      site_id: activeMenuType === 4 ? 1 : siteId,
      menu_type: activeMenuType,
    };
    const res = yield call(fetchAllAppMenu, payload);

    if (res.data?.data) {
      yield put(getAllMenuSuccess(res.data.data.application_menu));
    }
  } catch (err) {
    yield put(getAllMenuError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleSaveAppMenu({ payload: { data, cb } }) {
  try {
    const res = yield call(saveAppMenu, data);
    if (res.data.statusCode === 200 && res?.data?.data) {
      yield put(saveApplicationMenuSuccess(res.data.data));
      yield put(setSuccessMessage(res.data.message));
      yield put(getAllMenuList());
      cb();
    }
  } catch (err) {
    yield put(saveApplicationMenuError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteAppMenuFolder({ payload: { data, cb } }) {
  try {
    const res = yield call(deleteAppMenu, data);

    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(getAllMenuList());
      if (!!cb) cb();
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleOrderAppMenu({ payload }) {
  try {
    const res = yield call(orderAppMenu, payload);
    if (res.data.statusCode === 200 && res?.data?.data) {
      yield put(orderApplicationMenuSuccess(res.data.data));
      yield put(setSuccessMessage(res.data.message));
      yield put(getAllMenuList());
    }
  } catch (err) {
    yield put(orderApplicationMenuError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchSmsService({ payload }) {
  try {
    const res = yield call(fetchSmsServicesCredentials, payload);
    if (res.data?.data) {
      yield put(getSmsServicesSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(getSmsServicesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchSmsServerCredential() {
  try {
    const res = yield call(fetchSmsServersMasterData);
    if (res.data.statusCode === 200 && res.data?.data?.data) {
      yield put(fetchSmsServerCredentialSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(fetchSmsServerCredentialError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAddEditSmsService({ payload: { data, cb } }) {
  try {
    const res = yield call(addUpdateSmsService, data);
    if (res.data.statusCode === 200 && res?.data?.data) {
      yield put(addEditServiceSuccess(res.data.data));
      yield put(setSuccessMessage(res.data.message));
    }
    if (!!cb) cb();
  } catch (err) {
    yield put(addEditServiceError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchSystemConfiguration({ payload }) {
  try {
    const res = yield call(fetchSystemConfig, payload);
    if (res.data.statusCode === 200 && res?.data?.data) {
      yield put(getSystemConfigurationSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getSystemConfigurationError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleSaveSystemConfiguration({ payload }) {
  try {
    const res = yield call(saveSystemConfiguration, payload);
    if (res.data.statusCode === 200 && res?.data?.data) {
      yield put(saveSystemConfigSuccess());
      yield put(setSuccessMessage(res.data.message));
    }
  } catch (err) {
    yield put(saveSystemConfigError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleSystemPageLink({ payload }) {
  try {
    const res = yield call(fetchSystemPageLink, payload);
    if (res?.data?.data?.data) {
      yield put(listSystemPageLinkSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(listSystemPageLinkError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

//affiliate listing
function* handleGetAllAffiliate({ payload }) {
  try {
    const res = yield call(getAllAffiliate, payload);
    if (res.data.statusCode === 200 && res?.data?.data) {
      yield put(
        getAffiliateSuccess({
          rows: res.data.data.rows,
          count: res.data.data.count,
        })
      );
      //yield put(setSuccessMessage(res.data.message))
    }
  } catch (err) {
    yield put(getAffiliateError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchRateConfiguration() {
  const res = yield call(fetchRateConfigurationCall);
  if (res?.data?.statusCode === 200 && res?.data?.data)
    yield put(fetchRateConfigurationSuccess(res.data.data.data));
  try {
  } catch (err) {
    yield put(fetchRateConfigurationError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleSaveRateConfiguration({ payload: { data, cb } }) {
  try {
    const res = yield call(saveRateConfigurationCall, data);
    if (res.status) {
      yield put(saveRateConfigurationSuccess(res?.data?.data?.data));
      yield put(setSuccessMessage(res.data.message));
      if (!!cb) cb();
    }
  } catch (err) {
    yield put(saveRateConfigurationError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleFetchLatestAffiliateUsers({ payload }) {
  try {
    const res = yield call(fetchAffiliateLatestUsers, payload);
    if (res.data.statusCode === 200) {
      yield put(fetchLatestAffiliateUsersSuccess(res?.data?.data?.rows));
    }
  } catch (err) {
    yield put(fetchLatestAffiliateUsersError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
//Totals
function* handleHighestConvertingUrls({ payload }) {
  try {
    const res = yield call(fetchHighestConvertingUrls, payload);
    if (res.data.statusCode === 200) {
      yield put(fetchHighestConvertingUrlsSuccess(res?.data?.data?.rows));
      //yield put(setSuccessMessage(res.data.message));
    }
  } catch (err) {
    yield put(fetchHighestConvertingUrlsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

//Most Valuable Affiliate
function* handleFetchMostValuableAffiliates({ payload }) {
  try {
    const res = yield call(fetchMostValuableAffiliate, payload);
    if (res.data.statusCode === 200) {
      yield put(fetchMostValuableAffiliateSuccess(res?.data?.data?.rows));
      //yield put(setSuccessMessage(res.data.message));
    }
  } catch (err) {
    yield put(fetchMostValuableAffiliateError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

//Most Recent Referrals
function* handleFetchRecentReferrals({ payload }) {
  try {
    const res = yield call(fetchRecentReferrals, payload);
    if (res.data.statusCode === 200) {
      yield put(fetchRecentReferralsSuccess(res?.data?.data?.rows));
      //yield put(setSuccessMessage(res.data.message));
    }
  } catch (err) {
    yield put(fetchRecentReferralsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

//affiliate payout listing
function* handleFetchAffiliatePayout({ payload }) {
  try {
    const res = yield call(fetchAffiliatePayout, payload);
    if (res.data.statusCode === 200 && res?.data?.data) {
      yield put(
        fetchAffiliatePayoutSuccess({
          rows: res.data.data.rows,
          count: res.data.data.count,
        })
      );
      //yield put(setSuccessMessage(res.data.message))
    }
  } catch (err) {
    yield put(fetchAffiliatePayoutError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

//Affiliate Visit Data
function* handleListAffiliatesVisitData({ payload }) {
  try {
    const res = yield call(fetchAffiliateVisitData, payload);
    if (res?.data?.statusCode === 200 && res?.data?.data)
      yield put(
        listAffiliateVisitDataSuccess({
          rows: res.data.data.rows.map((item, index) => ({
            ...item,
            id: index,
          })),
          count: res.data.data.count,
        })
      );
  } catch (err) {
    yield put(listAffiliateVisitDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

//Affiliate Referrals
function* handleFetchAffiliateReferral({ payload }) {
  try {
    const res = yield call(fetchAffiliateReferralCall, payload);
    if (res?.data?.statusCode === 200 && res?.data?.data) {
      yield put(
        fetchAffiliateReferralSuccess({
          data: res.data.data.rows,
          count: res.data.data.count,
        })
      );
    }
  } catch (err) {
    yield put(fetchAffiliateReferralError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchAffiliateModalDataView({ payload }) {
  try {
    yield put(setAffiliateModalDataLoading(true));
    const res = yield call(getModalAffiliateData, payload);
    if (res?.data?.statusCode === 200 && res?.data?.data) {
      yield put(setAffiliateModalData(res.data.data));
      yield put(setAffiliateModalDataLoading(false));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
    yield put(setAffiliateModalDataLoading(false));
  }
}

function* applicationsSaga() {
  yield takeEvery(FETCH_PAYMENT_SERVICE, handleFetchPaymentService);
  yield takeEvery(GET_DASHBOARD_PAGE_CONTACT, handleFetchHomeDashboard);
  yield takeEvery(SAVE_HOME_DASHBOARD_DETAILS, handleSaveHomeDashboard);
  yield takeEvery(SAVE_PAYMENT_SERVICE, handleSavePaymentService);
  yield takeEvery(GET_SERVICE, handleFetchService);
  yield takeEvery(ADD_EDIT_SERVICE, handleAddEditService);
  yield takeEvery(DELETE_EMAIL_SERVICE, handleDeleteEmailService);
  yield takeEvery(FETCH_SERVER_CREDENTIAL, handleFetchServerCredential);
  yield takeEvery(DELETE_SITE_PAYMENT_SERVICE, handleDeleteSitePaymentService);
  yield takeEvery(ADD_EDIT_SITE, handleAddEditSite);
  yield takeEvery(FETCH_SITES, handleFetchSites);

  yield takeEvery(GET_ALL_APP_MENU, handleFetchAppMenuList);
  yield takeEvery(SAVE_APP_MENU, handleSaveAppMenu);
  yield takeEvery(DELETE_APP_MENU, handleDeleteAppMenuFolder);
  yield takeEvery(ORDER_APP_MENU, handleOrderAppMenu);
  /** 25-02-22 */
  yield takeEvery(GET_SMS_SERVICE, handleFetchSmsService);
  yield takeEvery(FETCH_SMS_SERVER_CREDENTIAL, handleFetchSmsServerCredential);
  yield takeEvery(ADD_EDIT_SMS_SERVICE, handleAddEditSmsService);
  //policy
  yield takeEvery(GET_SYSTEM_CONFIG, handleFetchSystemConfiguration);
  yield takeEvery(SAVE_SYSTEM_CONFIG, handleSaveSystemConfiguration);

  yield takeEvery(SYSTEM_PAGE_PAGELINK, handleSystemPageLink);

  //Affiliate
  yield takeEvery(GET_AFFILIATE, handleGetAllAffiliate);
  yield takeEvery(FETCH_RATE_CONFIGURATION, handleFetchRateConfiguration);
  yield takeEvery(SAVE_RATE_CONFIGURATION, handleSaveRateConfiguration);
  yield takeEvery(
    FETCH_LATEST_AFFILIATE_USERS,
    handleFetchLatestAffiliateUsers
  );
  yield takeEvery(FETCH_HIGHEST_CONVERTING_URLS, handleHighestConvertingUrls);
  yield takeEvery(
    FETCH_MOST_VALUABLE_AFFILIATES,
    handleFetchMostValuableAffiliates
  );
  yield takeEvery(FETCH_RECENT_REFERRALS, handleFetchRecentReferrals);
  yield takeEvery(FETCH_AFFILIATE_PAYOUT, handleFetchAffiliatePayout);
  yield takeEvery(LIST_AFFILIATE_VISIT_DATA, handleListAffiliatesVisitData);
  yield takeEvery(FETCH_AFFILIATE_REFERRAL, handleFetchAffiliateReferral);
  yield takeEvery(
    FETCH_AFFILIATE_MODAL_DATA,
    handleFetchAffiliateModalDataView
  );
  yield takeEvery(LIST_VIDEO_LOADING, fetchVideos);
  yield takeEvery(INTAKE_LIST_LOADING, fetchIntakeFormList);
  yield takeEvery(SAVE_INTAKE_DATA_LOADING, saveIntakeFormData);
}

export default applicationsSaga;
