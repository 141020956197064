// Va 05 - 01 - 21
import { axiosApiAdmin } from "helpers/api_helper";
import { LIST_ACTIVE_SUBSCRIPTION } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchlistActiveSubscriptionError,
  fetchlistActiveSubscriptionSuccess,
  fetchlistSubscriptionReportForExportSuccess,
  setErrorMessage,
} from "store/actions";
import {
  FATCH_LIST_ACTIVE_SUBSCRIPTION,
  FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT,
} from "./actionTypes";

const fetchActiveSubscription = data =>
  axiosApiAdmin.post(LIST_ACTIVE_SUBSCRIPTION, data);

function* handleFetchActiveSubscription({ payload, cb }) {
  try {
    const res = yield call(fetchActiveSubscription, payload);
    if (res) {
      cb(res);
      yield put(fetchlistActiveSubscriptionSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchlistActiveSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchActiveSubscriptionExport({ payload }) {
  try {
    const res = yield call(fetchActiveSubscription, payload);
    if (res) {
      yield put(
        fetchlistSubscriptionReportForExportSuccess(res.data.data.rows)
      );
    }
  } catch (err) {
    yield put(fetchlistActiveSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

export function* ActiveSubscriptionSaga() {
  yield takeEvery(
    FATCH_LIST_ACTIVE_SUBSCRIPTION,
    handleFetchActiveSubscription
  );
}

export function* ActiveSubscriptionExportSaga() {
  yield takeEvery(
    FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT,
    handleFetchActiveSubscriptionExport
  );
}
