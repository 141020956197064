// Smit : 23-11 / 24-11

import {
  ADD_EDIT_USER,
  ADD_EDIT_USER_ERROR,
  ADD_EDIT_USER_SUCCESS,
  CHANGE_USER_ROLE,
  CLEAR_USER_FEATURES,
  DELETE_USERS,
  DELETE_USERS_ERROR,
  DELETE_USERS_SUCCESS,
  FETCH_TOTAl_USERS_SUCCESS,
  FETCH_USERS,
  FETCH_USERS_ERROR,
  FETCH_USERS_SUCCESS,
  FETCH_USER_BY_ID,
  FETCH_USER_BY_ID_ERROR,
  FETCH_USER_BY_ID_SUCCESS,
  FILTER_BY_APPLICATIONS,
  SET_IS_CUSTOMER,
  SET_USER,
  SET_USER_PAGE,
  SET_USER_SEARCH,
  SET_USER_SIZE_PER_PAGE,
  SET_USER_SORT_FIELD,
  SET_USER_SORT_ORDER,
} from "./actionTypes";

const customerInitialValues = {
  search: "",
  sizePerPage: 25,
  page: 1,
  sortField: "user_id",
  sortOrder: "DESC",
  userRole: 0,
  filterByApplications: 0,
};

const staffInitialValues = {
  search: "",
  sizePerPage: 25,
  page: 1,
  sortField: "user_id",
  sortOrder: "DESC",
  userRole: 0,
};

const initialState = {
  loading: false,
  users: [],
  totalSize: 0,
  customer: { ...customerInitialValues },
  staff: {
    ...staffInitialValues,
  },
  totalUsers: [],
  user: null,
  isCustomer: null,
};

const change = (state, value) =>
  state.isCustomer
    ? {
        customer: {
          ...state.customer,
          ...value,
        },
      }
    : {
        staff: {
          ...state.staff,
          ...value,
        },
      };

const usersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_USER_PAGE:
      return {
        ...state,
        ...change(state, { page: payload }),
      };
    case SET_USER_SIZE_PER_PAGE:
      return {
        ...state,
        ...change(state, { sizePerPage: payload }),
      };
    case SET_USER_SEARCH:
      return {
        ...state,
        ...change(state, { search: payload, page: 1 }),
      };
    case SET_USER_SORT_FIELD:
      return {
        ...state,
        ...change(state, { sortField: payload }),
      };
    case SET_USER_SORT_ORDER:
      return {
        ...state,
        ...change(state, { sortOrder: payload }),
      };
    case CHANGE_USER_ROLE:
      return {
        ...state,
        ...change(state, { userRole: payload, page: 1 }),
      };
    case FETCH_USERS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: payload.rows,
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_USERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_USER_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_BY_ID_SUCCESS:
      return {
        ...state,
        user: payload,
        loading: false,
      };
    case FETCH_USER_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ADD_EDIT_USER:
      return {
        ...state,
        loading: true,
      };
    case ADD_EDIT_USER_SUCCESS:
      return {
        ...state,
      };
    case ADD_EDIT_USER_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DELETE_USERS:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_USERS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_TOTAl_USERS_SUCCESS:
      return {
        ...state,
        totalUsers: action.payload,
      };

    case SET_IS_CUSTOMER:
      return {
        ...state,
        isCustomer: payload,
      };
    case SET_USER:
      return {
        ...state,
        user: payload,
      };
    case FILTER_BY_APPLICATIONS:
      return {
        ...state,
        ...change(state, { filterByApplications: payload, page: 1 }),
      };
    case CLEAR_USER_FEATURES:
      return {
        ...state,
        ...(!payload.isStaff
          ? { customer: { ...customerInitialValues } }
          : { staff: { ...staffInitialValues } }),
      };
    default:
      return state;
  }
};

export default usersReducer;
