// Va 06 - 01 - 21
import { axiosApiAdmin } from "helpers/api_helper";
import { FETCH_SUBSCRIPTION_DETAIL_URL } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchActiveSubscriptionsError,
  fetchActiveSubscriptionsSuccess,
  setErrorMessage,
} from "store/actions";
import { FETCH_ACTIVE_SUBSCRIPTIONS } from "./actionTypes";

const fetchActiveSubscriptions = data =>
  axiosApiAdmin.post(FETCH_SUBSCRIPTION_DETAIL_URL, data);

function* handleFetchActiveSubscriptions({ payload }) {
  try {
    const res = yield call(fetchActiveSubscriptions, payload);
    if (res.data?.data) {
      yield put(fetchActiveSubscriptionsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchActiveSubscriptionsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* activeMembershipSaga() {
  yield takeEvery(FETCH_ACTIVE_SUBSCRIPTIONS, handleFetchActiveSubscriptions);
}

export default activeMembershipSaga;
