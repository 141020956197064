import {
  FETCH_LOGS,
  FETCH_LOGS_ERROR,
  FETCH_LOGS_SUCCESS,
  SET_LOGS_FILTERS,
  SET_LOGS_PAGE,
  SET_LOGS_SEARCH,
  SET_LOGS_SIZE_PER_PAGE,
  SET_LOGS_SORT_FIELD,
  SET_LOGS_SORT_ORDER,
} from "./actionTypes";

const initialState = {
  loadingLogs: false,
  logs: [],
  search: "",
  page_record: 25,
  page_no: 1,
  totalSize: 0,
  sortField: "admin_activity_log_id",
  sortOrder: "DESC",
  filter: [],
};

const logReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_LOGS:
      return {
        ...state,
        loadingLogs: true,
      };
    case FETCH_LOGS_SUCCESS:
      return {
        ...state,
        logs: payload.rows,
        totalSize: payload.count,
        loadingLogs: false,
      };
    case FETCH_LOGS_ERROR:
      return {
        ...state,
        loadingLogs: false,
      };
    case SET_LOGS_PAGE:
      return {
        ...state,
        page_no: payload,
      };
    case SET_LOGS_SIZE_PER_PAGE:
      return {
        ...state,
        page_record: payload,
      };
    case SET_LOGS_SEARCH:
      return { ...state, search: payload };
    case SET_LOGS_FILTERS:
      return {
        ...state,
        filter: payload,
      };
    case SET_LOGS_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };
    case SET_LOGS_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    default:
      return state;
  }
};

export default logReducer;
