import { axiosApiAdmin } from "helpers/api_helper";
import {
  ADD_EDIT_CALENDER_ITEM_URL,
  ADD_EDIT_HUB_ANNOUNCEMENT_URL,
  DELETE_ANNOUNCEMENT_URL,
  DELETE_CALENDER_ITEM_URL,
  DUPLICATE_ELEMENT,
  GET_BUILDING_BLOCKS,
  GET_MB_ELEMENTS,
  GET_SERIES_ELEMENT_BY_SERIES_ID,
  GET_SERIES_RESOURCES_DETAILS_API,
  LIST_ALL_ANNOUNCEMENT_URL,
  LIST_ALL_CALENDER_ITEM_URL,
  UPDATE_BUILDER__ELEMENT_DETAILS,
  UPDATE_MESSAGE_AND_ELEMENTS,
  VALID_VIDEO_URL,
} from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_EDIT_HUB_ANNOUNCEMENT,
  ADD_EDIT_HUB_CALENDAR_ITEMS,
  DELETE_ANNOUNCEMENT,
  DELETE_CALENDAR_ITEMS,
  FETCH_APP_BUILDER_DETAIL_LISTING,
  FETCH_BUILDING_BLOCKS_LISTING,
  FETCH_MB_ELEMENTS,
  FETCH_RESOURCE_CURR_DETAIL,
  LIST_ANNOUNCEMENT,
  LIST_CALENDAR_ITEMS,
  MB_DELETE_ELEMENT,
  MB_DUPLICATE_ELEMENT,
  MESSAGE_VIDEO_ELEMENT,
  SAVE_ON_CHANGE,
} from "./actionTypes";

import {
  addEditHubAnnouncementError,
  addEditHubAnnouncementSuccess,
  deleteAnnouncementError,
  deleteAnnouncementSuccess,
  deleteCalendarItemsError,
  deleteCalendarItemsSuccess,
  fetchAppBuilderDetailListing,
  fetchAppBuilderDetailListingSuccess,
  fetchBuildingBlockListingError,
  fetchBuildingBlockListingSuccess,
  fetchMBElements,
  fetchMBElementsError,
  fetchMBElementsSuccess,
  getResourcesElementsDetailError,
  getResourcesElementsDetailSuccess,
  listAnnouncement,
  listAnnouncementError,
  listAnnouncementSuccess,
  listCalendarItems,
  listCalendarItemsError,
  listCalendarItemsSuccess,
  MBDuplicateElementError,
  MBDuplicateElementSuccess,
  messageVideoElementSuccess,
} from "./actions";

import Log from "helpers/logger";
import { setErrorMessage, setSuccessMessage } from "store/actions";

const addEditHubAnnouncements = data =>
  axiosApiAdmin.post(ADD_EDIT_HUB_ANNOUNCEMENT_URL, data);
const listAnnouncements = data =>
  axiosApiAdmin.get(`${LIST_ALL_ANNOUNCEMENT_URL}/${data}`);
const listCalendarItem = data =>
  axiosApiAdmin.get(`${LIST_ALL_CALENDER_ITEM_URL}/${data}`);
const deleteAnnouncements = data =>
  axiosApiAdmin.delete(`${DELETE_ANNOUNCEMENT_URL}/${data}`);
const deleteCalendarItems = data =>
  axiosApiAdmin.delete(`${DELETE_CALENDER_ITEM_URL}/${data}`);
const addEditHubCalendarItems = data =>
  axiosApiAdmin.post(ADD_EDIT_CALENDER_ITEM_URL, data);
const getBuildingBlocks = data => axiosApiAdmin.post(GET_BUILDING_BLOCKS, data);
const getMBElements = data => axiosApiAdmin.post(GET_MB_ELEMENTS, data);
const duplicateElement = data => axiosApiAdmin.post(DUPLICATE_ELEMENT, data);
const updateBuilderElementDetails = data =>
  axiosApiAdmin.post(UPDATE_BUILDER__ELEMENT_DETAILS, data);
const updateMessageAndElements = data =>
  axiosApiAdmin.post(UPDATE_MESSAGE_AND_ELEMENTS, data);
const validVideo = data => axiosApiAdmin.post(VALID_VIDEO_URL, data);
const getSeriesElementBySeriesId = data =>
  axiosApiAdmin.post(GET_SERIES_ELEMENT_BY_SERIES_ID, data);
const getCurriCulumResourcesElements = data =>
  axiosApiAdmin.get(`${GET_SERIES_RESOURCES_DETAILS_API}/${data}`);

function* handleAddEditAnnouncement({ payload: { data, cb } }) {
  try {
    const res = yield call(addEditHubAnnouncements, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(addEditHubAnnouncementSuccess());
      yield put(listAnnouncement({ category_id: data.category_id }));
      cb(data);
    }
  } catch (err) {
    yield put(addEditHubAnnouncementError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

//Building Block listing MB
function* handleBuildingBlocksListing(data) {
  try {
    const res = yield call(getBuildingBlocks, data.payload);
    if (res.data) {
      yield put(fetchBuildingBlockListingSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(fetchBuildingBlockListingError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

//MB element listing
function* handleFetchMBElements(data) {
  try {
    const res = yield call(getMBElements, data.payload);
    if (res.data) {
      yield put(fetchMBElementsSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(fetchMBElementsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
//MB element duplicate
function* handleMBElementDuplicate(data) {
  try {
    const res = yield call(duplicateElement, data.payload);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(MBDuplicateElementSuccess(res.data.data.data));
      if (res.data.data.data.is_series === 0) {
        yield put(fetchMBElements({ id: res.data.data.data.build_list_id }));
      } else {
        yield put(
          fetchAppBuilderDetailListing({
            series_build_list_id: res.data.data.data.build_list_id,
          })
        );
      }
    }
  } catch (err) {
    yield put(MBDuplicateElementError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
//MB element delete
function* handleMBElementDelete(data) {
  try {
    const res = yield call(updateBuilderElementDetails, data.payload);
    if (res.data) {
      // Log.volumeBuilder("Grow Builder").editSeries(
      //   parseInt(res.data.data.data?.volume_id)
      // );
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
//Save elements
function* handleSaveOnChangeMB(data) {
  try {
    const res = yield call(updateMessageAndElements, data.payload);
    if (res.data) {
      Log.volumeBuilder("Grow Builder").editGrowBuilderMessageItem(
        data.payload.message_build_list_id
      );
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
//video url validate elements
function* handleMessageVideoElement({ payload: { data, cb } }) {
  try {
    const res = yield call(validVideo, data);
    if (res.data) {
      yield put(messageVideoElementSuccess(res.data.data.flag));
      cb(res.data.data.flag);
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
// Fetch app series detail
function* handleFetchAppBuilderDetailListing({
  payload: { data, cb = () => {} },
}) {
  try {
    yield put(fetchAppBuilderDetailListingSuccess([]));

    const res = yield call(getSeriesElementBySeriesId, data);
    if (res.data) {
      yield put(fetchAppBuilderDetailListingSuccess(res.data.data));
      cb(res.data);
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleListALlAnnouncement({ payload }) {
  try {
    const res = yield call(listAnnouncements, payload.category_id);

    if (res.data?.data?.rows) {
      yield put(listAnnouncementSuccess(res.data.data.rows));
    }
  } catch (err) {
    yield put(listAnnouncementError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleListALlCalenderItems({ payload }) {
  try {
    const res = yield call(listCalendarItem, payload.category_id);

    if (res.data?.data?.rows) {
      yield put(listCalendarItemsSuccess(res.data.data.rows));
    }
  } catch (err) {
    yield put(listCalendarItemsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteAnnouncement({ payload: { data, cb, category_id } }) {
  try {
    const res = yield call(deleteAnnouncements, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(deleteAnnouncementSuccess());
      yield put(listAnnouncement({ category_id: category_id }));
      cb(data);
    }
  } catch (err) {
    yield put(deleteAnnouncementError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteCalendarItems({ payload: { data, cb, category_id } }) {
  try {
    const res = yield call(deleteCalendarItems, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(deleteCalendarItemsSuccess());
      yield put(listCalendarItems({ category_id: category_id }));
      cb(data);
    }
  } catch (err) {
    yield put(deleteCalendarItemsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAddEditCalendarItems({ payload: { data, cb } }) {
  try {
    const res = yield call(addEditHubCalendarItems, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(addEditHubAnnouncementSuccess());
      yield put(listCalendarItems({ category_id: data.category_id }));
      cb(data);
    }
  } catch (err) {
    yield put(addEditHubAnnouncementError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

//Building Block listing MB
function* handleResourcesListingCurriculum({ payload }) {
  try {
    const res = yield call(getCurriCulumResourcesElements, payload);
    if (res.data) {
      yield put(getResourcesElementsDetailSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(getResourcesElementsDetailError());
  }
}

function* curriculumPostSeriesSaga() {
  yield takeEvery(ADD_EDIT_HUB_ANNOUNCEMENT, handleAddEditAnnouncement);
  yield takeEvery(LIST_ANNOUNCEMENT, handleListALlAnnouncement);
  yield takeEvery(LIST_CALENDAR_ITEMS, handleListALlCalenderItems);
  yield takeEvery(DELETE_ANNOUNCEMENT, handleDeleteAnnouncement);
  yield takeEvery(DELETE_CALENDAR_ITEMS, handleDeleteCalendarItems);
  yield takeEvery(ADD_EDIT_HUB_CALENDAR_ITEMS, handleAddEditCalendarItems);
  yield takeEvery(FETCH_BUILDING_BLOCKS_LISTING, handleBuildingBlocksListing);
  yield takeEvery(FETCH_MB_ELEMENTS, handleFetchMBElements);
  yield takeEvery(MB_DUPLICATE_ELEMENT, handleMBElementDuplicate);
  yield takeEvery(MB_DELETE_ELEMENT, handleMBElementDelete);
  yield takeEvery(SAVE_ON_CHANGE, handleSaveOnChangeMB);
  yield takeEvery(MESSAGE_VIDEO_ELEMENT, handleMessageVideoElement);
  yield takeEvery(
    FETCH_APP_BUILDER_DETAIL_LISTING,
    handleFetchAppBuilderDetailListing
  );
  yield takeEvery(FETCH_RESOURCE_CURR_DETAIL, handleResourcesListingCurriculum);
}

export default curriculumPostSeriesSaga;
