import {
  CLEAR_ORDERS_FEATURES,
  FETCH_ORDERS,
  FETCH_ORDERS_ERROR,
  FETCH_ORDERS_SUCCESS,
  FILTER_BY_ORDER_STATUS,
  SET_ORDERS_PAGE,
  SET_ORDERS_SIZE_PER_PAGE,
  SET_ORDER_SEARCH,
  SET_ORDER_SORT_FIELD,
  SET_ORDER_SORT_ORDER,
} from "./actionTypes";

const initialState = {
  orders: [],
  loadingOrders: false,
  search: "",
  orderStatus: 0,
  pageRecord: 25,
  pageNo: 1,
  totalSize: 0,
  siteId: 0,
  sortField: "user_order_date",
  sortOrder: "DESC",
};

const ordersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS:
      return {
        ...state,
        loadingOrders: true,
        siteId: payload,
      };
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        orders: payload?.order_list || [],
        totalSize: payload?.count || 0,
        loadingOrders: false,
      };
    case FETCH_ORDERS_ERROR:
      return {
        ...state,
        loadingOrders: false,
      };
    case SET_ORDERS_PAGE:
      return {
        ...state,
        pageNo: payload,
      };
    case SET_ORDER_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };
    case SET_ORDER_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    case SET_ORDERS_SIZE_PER_PAGE:
      return {
        ...state,
        pageRecord: payload,
      };
    case SET_ORDER_SEARCH:
      return {
        ...state,
        search: payload,
      };
    case CLEAR_ORDERS_FEATURES:
      return {
        orders: [],
        loadingOrders: false,
        search: "",
        orderStatus: 0,
        pageRecord: 25,
        pageNo: 1,
        totalSize: 0,
        siteId: 0,
      };
    case FILTER_BY_ORDER_STATUS:
      return {
        ...state,
        orderStatus: payload,
      };
    default:
      return state;
  }
};
export default ordersReducer;
