export const TAB_CHANGE_GROW_FREE_TRIAL = "TAB_CHANGE_GROW_FREE_TRIAL";
export const GROW_STORIES = "GROW_STORIES";
export const GROW_STEWARDS = "GROW_STEWARDS";
export const GROW_STORY_CREATE = "GROW_STORY_CREATE";
export const GROW_ADD_BUTTON_STORY = "GROW_ADD_BUTTON_STORY";
export const GROW_STEWARD = "GROW_STEWARD";
export const GROW_HELPFUL = "GROW_HELPFUL";
export const GROW_STORIES_LISTING = "GROW_STORIES_LISTING";
export const GROW_COLOR_LISTING = "GROW_COLOR_LISTING";
export const GROW_STEWARD_LISTING = "GROW_STEWARD_LISTING";
export const GROW_HELPFUL_LISTING = "GROW_HELPFUL_LISTING";
export const ADD_EDIT_STEWARD_SWITCH = "ADD_EDIT_STEWARD_SWITCH";
export const ADD_EDIT_HELPFUL_SWITCH = "ADD_EDIT_HELPFUL_SWITCH";
export const GROW_SAVE_STEWARD = "GROW_SAVE_STEWARD";
export const GROW_APPLICATION_ADS_SAVE_COLOR =
  "GROW_APPLICATION_ADS_SAVE_COLOR";
export const GROW_COLOR = "GROW_COLOR";
export const GROW_SAVE_HELPFUL_RESOURCES = "GROW_SAVE_HELPFUL_RESOURCES";
export const GROW_EDIT_STORIES = "GROW_EDIT_STORIES";
export const GROW_DELETE_STORIES = "GROW_DELETE_STORIES";
export const GROW_DELETE_STEWARD = "GROW_DELETE_STEWARD";
export const GROW_DELETE_HELPFUL = "GROW_DELETE_HELPFUL";
export const GROW_EDIT_STEWARD = "GROW_EDIT_STEWARD";
export const GROW_EDIT_HELPFUL = "GROW_EDIT_HELPFUL";
export const GROW_FETCH_STORY_LOADER = "GROW_FETCH_STORY_LOADER";
export const GROW_FETCH_HELPFUL_LOADER = "GROW_FETCH_HELPFUL_LOADER";
export const GROW_FETCH_STEWARD_LOADER = "GROW_FETCH_STEWARD_LOADER";
export const GROW_TO_DO_LIST_TAB_CHANGE = "GROW_TO_DO_LIST_TAB_CHANGE";
export const FETCH_SERIES_CURRICULUM = "FETCH_SERIES_CURRICULUM";
export const STORE_SERIES_CURRICULUM = "STORE_SERIES_CURRICULUM";
export const FETCH_SERIES_KIDS = "FETCH_SERIES_KIDS";
export const STORE_SERIES_KIDS = "STORE_SERIES_KIDS";
//GROW_SAVE_TO_DO_LIST

export const GROW_TO_DO_LIST_SAVE = "GROW_TO_DO_LIST_SAVE";
export const GROW_TODO = "GROW_TODO";
export const GROW_TODO_LISTING = "GROW_TODO_LISTING";
export const GROW_DELETE_TODO = "GROW_DELETE_TODO";
export const GROW_EDIT_TODO = "GROW_EDIT_TODO";
export const GROW_FETCH_TODO_LOADER = "GROW_FETCH_TODO_LOADER";
//LOADER

export const GROW_ADD_EDIT_HELPFUL_LOADER = "GROW_ADD_EDIT_HELPFUL_LOADER";
export const GROW_ADD_EDIT_STORY_LOADER = "GROW_ADD_EDIT_STORY_LOADER";
export const GROW_ADD_EDIT_STEWARDS_LOADER = "GROW_ADD_EDIT_STEWARDS_LOADER";
export const GROW_ADD_EDIT_TODO_LOADER = "GROW_ADD_EDIT_TODO_LOADER";

export const GROW_EDIT_TUTORIALS = "GROW_EDIT_TUTORIALS";
export const GROW_ADD_BUTTON_TUTORIALS = "GROW_ADD_BUTTON_TUTORIALS";
export const GROW_TUTORIALS_TAB_CHANGE = "GROW_TUTORIALS_TAB_CHANGE";
export const GROW_TUTORIALS_CREATE = "GROW_TUTORIALS_CREATE";
export const GROW_DELETE_TUTORIAL = "GROW_DELETE_TUTORIAL";
export const GROW_TUTORIALS = "GROW_TUTORIALS";
export const GROW_TUTORIALS_LISTING = "GROW_TUTORIALS_LISTING";
