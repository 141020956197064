import { dynamicSiteId } from "constants/siteDetails";
import { compareTypes } from "modules/Analytics/common/constants";
import DateRange from "modules/Analytics/common/utils";
import moment from "moment";
import {
  ANALYTICS_USER_PAGE_SIZE,
  ANALYTICS_USER_SET_TZ,
  ANALYTICS_USER_SIZE_PER_PAGE,
  ANALYTICS_USER_SORT_FIELD,
  ANALYTICS_USER_SORT_ORDER,
  CLEAR_SEARCH_QUERY_REPORT,
  GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA,
  GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA_ERROR,
  GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA_SUCCESS,
  GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA,
  GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA_ERROR,
  GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA_SUCCESS,
  GET_ANALYTICS_USERS_GRAPH_DATA,
  GET_ANALYTICS_USERS_GRAPH_DATA_ERROR,
  GET_ANALYTICS_USERS_GRAPH_DATA_SUCCESS,
  GET_ANALYTICS_USER_COUNT,
  GET_ANALYTICS_USER_COUNT_ERROR,
  GET_ANALYTICS_USER_COUNT_SUCCESS,
  GET_ANALYTICS_USER_DATA,
  GET_ANALYTICS_USER_DATA_ERROR,
  GET_ANALYTICS_USER_DATA_SUCCESS,
  SET_ANALYTICS_USERS_ACTIVE_TAB,
  SET_ANALYTICS_USERS_COMPARE_TYPE,
  SET_ANALYTICS_USERS_RANGE_DATE,
  SET_ANALYTICS_USER_CURRENT_SITE,
  SET_BY_DAY,
} from "./actionTypes";

const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();
const defaultCompareType = compareTypes.PERIOD;

const reports = {
  registered_users: {
    value: 0,
    previousPeriodValue: 0,
  },
  free_users: {
    value: 0,
    previousPeriodValue: 0,
  },
  subscribe_users: {
    value: 0,
    previousPeriodValue: 0,
  },
};

const initialStates = {
  loadingAnalyticsUsersCount: false,
  analyticsUsersCount: reports,
  loadingAnalyticsGraphData: false,
  analyticsGraphData: [],
  loadingAnalyticsUsersData: false,
  analyticsUsersData: [],
  analyticsUserPageNo: 1,
  analyticsUserSizePerSize: 25,
  analyticsUserSortField: "",
  analyticsUserSortOrder: "DESC",
  analyticsUserTotalSize: 0,
  activeTab: "registered_users",
  range: {
    startDate,
    endDate,
  },
  compareToPrevious: defaultCompareType,
  comparedRange: new DateRange(startDate, endDate).getPreviousDates(
    defaultCompareType
  ),
  loadingAnalyticsSubscribedGraphData: false,
  analyticsSubscribedGraphData: [],
  by: "day",
  loadingAnalyticsRegisterGraphData: false,
  analyticsRegisterGraphData: [],
  siteId: dynamicSiteId.Curriculum,
  tz: "-0400",
};

const AnalyticsUsersReducer = (state = initialStates, action) => {
  const { type, payload } = action;
  switch (type) {
    case ANALYTICS_USER_SET_TZ:
      return {
        ...state,
        tz: payload,
      };

    case SET_ANALYTICS_USER_CURRENT_SITE:
      return {
        ...state,
        siteId: payload,
      };
    case CLEAR_SEARCH_QUERY_REPORT:
      return {
        ...state,
        range: {
          ...state.range,
          startDate,
          endDate,
        },
      };
    case SET_ANALYTICS_USERS_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };
    case SET_ANALYTICS_USERS_RANGE_DATE:
      const prevBy = state.by;
      const newBy = new DateRange(
        payload.startDate,
        payload.endDate
      ).getByOptions()[0].value;
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
        comparedRange: new DateRange(
          payload.startDate,
          payload.endDate
        ).getPreviousDates(state.compareToPrevious),
        ...(prevBy !== newBy ? { by: newBy } : {}),
      };
    case SET_ANALYTICS_USERS_COMPARE_TYPE:
      return {
        ...state,
        compareToPrevious: payload,
        comparedRange: new DateRange(
          state.range.startDate,
          state.range.endDate
        ).getPreviousDates(payload),
      };

    case GET_ANALYTICS_USER_COUNT:
      return {
        ...state,
        loadingAnalyticsUsersCount: true,
      };

    case GET_ANALYTICS_USER_COUNT_SUCCESS:
      return {
        ...state,
        loadingAnalyticsUsersCount: false,
        analyticsUsersCount: payload,
      };

    case GET_ANALYTICS_USER_COUNT_ERROR:
      return {
        ...state,
        loadingAnalyticsUsersCount: false,
      };

    case GET_ANALYTICS_USERS_GRAPH_DATA:
      return {
        ...state,
        loadingAnalyticsGraphData: true,
      };
    case GET_ANALYTICS_USERS_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        loadingAnalyticsGraphData: false,
        analyticsGraphData: payload.rows.map(item => {
          return {
            cp_date_time: item.current.start_date,
            pp_date_time: item.previous.start_date,
            cp: item.current.current_count,
            pp: item.previous.previous_count,
          };
        }),
      };

    case GET_ANALYTICS_USERS_GRAPH_DATA_ERROR:
      return {
        ...state,
        loadingAnalyticsGraphData: false,
      };

    case GET_ANALYTICS_USER_DATA:
      return {
        ...state,
        loadingAnalyticsUsersData: true,
      };

    case GET_ANALYTICS_USER_DATA_SUCCESS:
      return {
        ...state,
        loadingAnalyticsUsersData: false,
        analyticsUsersData: payload.user_data,
        analyticsUserTotalSize: payload.user_count,
      };

    case GET_ANALYTICS_USER_DATA_ERROR:
      return {
        ...state,
        loadingAnalyticsUsersData: false,
      };

    case ANALYTICS_USER_PAGE_SIZE:
      return {
        ...state,
        analyticsUserPageNo: payload,
      };

    case ANALYTICS_USER_SIZE_PER_PAGE:
      return {
        ...state,
        analyticsUserSizePerSize: payload,
      };

    case ANALYTICS_USER_SORT_FIELD:
      return {
        ...state,
        analyticsUserSortField: payload,
      };

    case ANALYTICS_USER_SORT_ORDER:
      return {
        ...state,
        analyticsUserSortOrder: payload,
      };

    case GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA:
      return {
        ...state,
        loadingAnalyticsSubscribedGraphData: true,
      };
    case GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        loadingAnalyticsSubscribedGraphData: false,
        analyticsSubscribedGraphData: payload.rows.map(item => {
          return {
            cp_date_time: item.current.start_date,
            pp_date_time: item.previous.start_date,
            cp: item.current.current_count,
            pp: item.previous.previous_count,
          };
        }),
      };

    case GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA_ERROR:
      return {
        ...state,
        loadingAnalyticsSubscribedGraphData: false,
      };

    case SET_BY_DAY:
      return {
        ...state,
        by: payload,
      };

    case GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA:
      return {
        ...state,
        loadingAnalyticsRegisterGraphData: true,
      };

    case GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        loadingAnalyticsRegisterGraphData: false,
        analyticsRegisterGraphData: payload.rows.map(item => {
          return {
            cp_date_time: item.current.start_date,
            pp_date_time: item.previous.start_date,
            cp: item.current.current_count,
            pp: item.previous.previous_count,
          };
        }),
      };

    case GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA_ERROR:
      return {
        ...state,
        loadingAnalyticsRegisterGraphData: false,
      };

    default:
      return state;
  }
};

export default AnalyticsUsersReducer;
