import {
  CLEAR_SUBSCRIPTION_FEATURES,
  CLEAR_SUB_STATE,
  EDIT_SUBSCRIPTION,
  EDIT_SUBSCRIPTION_ERROR,
  EDIT_SUBSCRIPTION_SUCCESS,
  FETCH_NEW_SUBSCRIPTION,
  FETCH_NEW_SUBSCRIPTION_ERROR,
  FETCH_NEW_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION,
  FETCH_SUBSCRIPTION_COUNTRY,
  FETCH_SUBSCRIPTION_COUNTRY_SUCCESS,
  FETCH_SUBSCRIPTION_ERROR,
  FETCH_SUBSCRIPTION_STATE,
  FETCH_SUBSCRIPTION_STATE_SUCCESS,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_TOTAL_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_DATA,
  GET_SUBSCRIPTION_DATA_SUCCESS,
  RESET_DISCOUNT,
  SEND_RECEIPT,
  SEND_RECEIPT_SUCCESS,
  SEND_REFUND_RECEIPT,
  SEND_REFUND_RECEIPT_SUCCESS,
  SET_BUTTON_TYPE,
  SET_SUBSCRIPTION,
  SET_SUBSCRIPTION_PAGE,
  SET_SUBSCRIPTION_SEARCH,
  SET_SUBSCRIPTION_SIZE_PER_PAGE,
  SET_SUBSCRIPTION_SORT_FIELD,
  SET_SUBSCRIPTION_SORT_ORDER,
  SET_SUBSCRIPTION_STATUS,
  SET_SUBSCRIPTION_TOTAL_VALUE,
  SUBMIT_SUBSCRIPTION,
  SUBMIT_SUBSCRIPTION_ERROR,
  SUBMIT_SUBSCRIPTION_SUCCESS,
  VERIFY_COUPON,
  VERIFY_COUPON_ERROR,
  VERIFY_COUPON_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  subscription: [],
  totalSize: 0,
  search: "",
  sizePerPage: 25,
  page: 1,
  sortField: "user_subscription_id",
  sortOrder: "DESC",
  newSubscription: {},
  subscriptionCountry: [],
  subscriptionState: [],
  verifyCouponData: "",
  verifyCouponDataId: [],
  existingSubscription: null,
  totalSubscription: [],
  subscriptionStatus: 0,
  userDetails: {},
  subscription_number: 0,
  site_id: 0,
  loadingSaveSubscription: false,
  loadingAddSubscription: false,
  loadingVerifyCoupon: false,
  submitBtnType: 0,
  subscriptionTotalValue: "",
};

const subscriptionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SUBSCRIPTION_PAGE:
      return {
        ...state,
        page: payload,
      };
    case SET_SUBSCRIPTION_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    case SET_SUBSCRIPTION_SEARCH:
      return {
        ...state,
        search: payload,
      };
    case SET_SUBSCRIPTION_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };
    case SET_SUBSCRIPTION_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    case CLEAR_SUBSCRIPTION_FEATURES:
      return {
        ...state,
        totalSize: 0,
        search: "",
        sizePerPage: 25,
        page: 1,
        sortField: "user_subscription_id",
        sortOrder: "DESC",
      };
    case FETCH_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: payload.subscription_list,
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_NEW_SUBSCRIPTION:
      return {
        ...state,
        loadingAddSubscription: true,
      };
    case FETCH_NEW_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        newSubscription: payload.subscription,
        userDetails: payload.user,
        loadingAddSubscription: false,
      };
    case FETCH_NEW_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loadingAddSubscription: false,
      };
    case FETCH_SUBSCRIPTION_COUNTRY:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUBSCRIPTION_COUNTRY_SUCCESS:
      return {
        ...state,
        subscriptionCountry: payload.countryList,
        loading: false,
      };
    case FETCH_SUBSCRIPTION_STATE:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUBSCRIPTION_STATE_SUCCESS:
      return {
        ...state,
        subscriptionState: payload.stateList,
        loading: false,
      };
    case VERIFY_COUPON:
      return {
        ...state,
        loadingVerifyCoupon: true,
      };
    case VERIFY_COUPON_SUCCESS:
      return {
        ...state,
        verifyCouponData: payload.couponDetails,
        verifyCouponDataId: payload.couponDetails.coupons_data,
        loadingVerifyCoupon: false,
      };
    case VERIFY_COUPON_ERROR:
      return {
        ...state,
        loadingVerifyCoupon: false,
      };
    case SUBMIT_SUBSCRIPTION:
      return {
        ...state,
        loadingSaveSubscription: true,
      };
    case SUBMIT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loadingSaveSubscription: false,
        submitBtnType: 0,
      };
    case SUBMIT_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loadingSaveSubscription: false,
      };
    case EDIT_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
      };
    case EDIT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        existingSubscription: payload.subscription,
        loading: false,
      };
    case EDIT_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_TOTAL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        totalSubscription: action.payload,
      };
    case SET_SUBSCRIPTION_STATUS:
      return {
        ...state,
        subscriptionStatus: action.payload,
      };
    case SET_SUBSCRIPTION:
      return {
        ...state,
        existingSubscription: action.payload,
      };
    case RESET_DISCOUNT:
      return {
        ...state,
        verifyCouponData: "",
        verifyCouponDataId: "",
      };
    case GET_SUBSCRIPTION_DATA:
      return {
        ...state,
      };
    case GET_SUBSCRIPTION_DATA_SUCCESS:
      return {
        ...state,
        subscription_number: action.payload?.subscription_number,
        site_id: action.payload?.site_id,
      };
    case CLEAR_SUB_STATE:
      return {
        ...state,
        existingSubscription: null,
      };
    case SEND_RECEIPT:
      return {
        ...state,
        loading: true,
      };
    case SEND_RECEIPT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SEND_REFUND_RECEIPT:
      return {
        ...state,
        loading: true,
      };
    case SEND_REFUND_RECEIPT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SET_BUTTON_TYPE:
      return {
        ...state,
        submitBtnType: payload,
      };
    case SET_SUBSCRIPTION_TOTAL_VALUE:
      return {
        ...state,
        subscriptionTotalValue: payload,
      };
    default:
      return state;
  }
};
export default subscriptionReducer;
