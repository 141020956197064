// Fetch payment methods
export const FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS";
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";
export const FETCH_PAYMENT_METHODS_ERROR = "FETCH_PAYMENT_METHODS_ERROR";
export const CLEAR_PAYMENT_METHODS = "CLEAR_PAYMENT_METHODS";

export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS";
export const ADD_PAYMENT_METHOD_ERROR = "ADD_PAYMENT_METHOD_ERROR";

export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD";
export const DELETE_PAYMENT_METHOD_SUCCESS = "DELETE_PAYMENT_METHOD_SUCCESS";
export const DELETE_PAYMENT_METHOD_ERROR = "DELETE_PAYMENT_METHOD_ERROR";
