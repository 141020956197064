import {
  ANALYTICS_EMAIL_SET_TZ,
  CLEAR_VIEW_TOTAL_DATA,
  FETCH_ANALYTICS_EMAIL_SITES,
  GET_ANALYTICS_EMAIL_GRAPH,
  GET_ANALYTICS_EMAIL_GRAPH_ERROR,
  GET_ANALYTICS_EMAIL_GRAPH_SUCCESS,
  GET_EMAIL_LIST,
  GET_EMAIL_LIST_ERROR,
  GET_EMAIL_LIST_SUCCESS,
  GET_TOTAL_BY_DATE,
  GET_TOTAL_BY_DATE_ERROR,
  GET_TOTAL_BY_DATE_SUCCESS,
  GET_TOTAL_PAGE,
  GET_TOTAL_SEARCH,
  GET_TOTAL_SIZE_PER_PAGE,
  GET_TOTAL_SORT_FIELD,
  GET_TOTAL_SORT_ORDER,
  SET_ANALYTICS_EMAILS_RANGE_DATE,
  SET_ANALYTICS_EMAIL_BY,
  SET_ANALYTICS_EMAIL_GRAPH_BY,
  SET_ANALYTICS_EMAIL_PAGE,
  SET_ANALYTICS_EMAIL_SEARCH,
  SET_ANALYTICS_EMAIL_SIZE_PER_PAGE,
  SET_ANALYTICS_EMAIL_SORT_FIELD,
  SET_ANALYTICS_EMAIL_SORT_ORDER,
} from "./actionTypes";

export const setEmailTZ = data => ({
  type: ANALYTICS_EMAIL_SET_TZ,
  payload: data,
});
// Report
export const setAnalyticEmailsRangeDate = data => ({
  type: SET_ANALYTICS_EMAILS_RANGE_DATE,
  payload: data,
});

//  Graph
export const getAnalyticsEmailGraph = () => ({
  type: GET_ANALYTICS_EMAIL_GRAPH,
});

export const getAnalyticsEmailGraphSuccess = data => ({
  type: GET_ANALYTICS_EMAIL_GRAPH_SUCCESS,
  payload: data,
});

export const getAnalyticsEmailGraphError = () => ({
  type: GET_ANALYTICS_EMAIL_GRAPH_ERROR,
});

export const setAnalyticsEmailBy = data => ({
  type: SET_ANALYTICS_EMAIL_BY,
  payload: data,
});

// Table
export const getEmailList = () => ({
  type: GET_EMAIL_LIST,
});

export const getEmailListSuccess = data => ({
  type: GET_EMAIL_LIST_SUCCESS,
  payload: data,
});

export const getEmailListError = () => ({
  type: GET_EMAIL_LIST_ERROR,
});

export const setAnalyticsEmailPage = data => ({
  type: SET_ANALYTICS_EMAIL_PAGE,
  payload: data,
});

export const setAnalyticsEmailSearch = data => ({
  type: SET_ANALYTICS_EMAIL_SEARCH,
  payload: data,
});

export const setAnalyticsEmailSizePerPage = data => ({
  type: SET_ANALYTICS_EMAIL_SIZE_PER_PAGE,
  payload: data,
});

export const setAnalyticsEmailSortOrder = data => ({
  type: SET_ANALYTICS_EMAIL_SORT_ORDER,
  payload: data,
});

export const setAnalyticsEmailSortFiled = data => ({
  type: SET_ANALYTICS_EMAIL_SORT_FIELD,
  payload: data,
});

export const fetchAnalyticsEmailSites = data => ({
  type: FETCH_ANALYTICS_EMAIL_SITES,
  payload: data,
});

export const getTotalByDate = data => ({
  type: GET_TOTAL_BY_DATE,
  payload: data,
});

export const getTotalByDateSuccess = data => ({
  type: GET_TOTAL_BY_DATE_SUCCESS,
  payload: data,
});

export const getTotalByDateError = () => ({
  type: GET_TOTAL_BY_DATE_ERROR,
});

export const getTotalPages = data => ({
  type: GET_TOTAL_PAGE,
  payload: data,
});

export const getTotalSizePerPage = data => ({
  type: GET_TOTAL_SIZE_PER_PAGE,
  payload: data,
});

export const getTotalSortOrder = data => ({
  type: GET_TOTAL_SORT_ORDER,
  payload: data,
});

export const getTotalSortFiled = data => ({
  type: GET_TOTAL_SORT_FIELD,
  payload: data,
});

export const getTotalSearch = data => ({
  type: GET_TOTAL_SEARCH,
  payload: data,
});

export const setAnalyticsEmailGraphBy = data => ({
  type: SET_ANALYTICS_EMAIL_GRAPH_BY,
  payload: data,
});
export const clearViewTotalData = () => ({
  type: CLEAR_VIEW_TOTAL_DATA,
});
