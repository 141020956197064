import { axiosApiAdmin } from "helpers/api_helper";
import {
  GET_PERMISSIONS,
  GET_PERMISSION_PROFILES,
  REMOVE_PERMISSION_PROFILE,
} from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { setErrorMessage, setSuccessMessage } from "store/actions";
import {
  deletePermissionProfileError,
  deletePermissionProfileSUCCESS,
  getPermissionProfiles,
  getPermissionProfilesError,
  getPermissionsError,
  getPermissionsProfilesSuccess,
  getPermissionsSuccess,
} from "./actions";
import {
  DELETE_PERMISSION_PROFILE,
  FETCH_PERMISSIONS,
  FETCH_PERMISSION_PROFILES,
} from "./actionTypes";

const fetchPermissionProfiles = () =>
  axiosApiAdmin.get(GET_PERMISSION_PROFILES);
const fetchPermissions = () => axiosApiAdmin.get(GET_PERMISSIONS);
const deletePermissionProfile = data =>
  axiosApiAdmin.delete(`${REMOVE_PERMISSION_PROFILE}/${data}`);

function* handleFetchPermissionProfiles() {
  try {
    const res = yield call(fetchPermissionProfiles);
    if (res.data?.data?.data) {
      yield put(getPermissionsProfilesSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(getPermissionProfilesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleFetchPermissions() {
  try {
    const res = yield call(fetchPermissions);

    if (res.data?.data?.data) {
      yield put(getPermissionsSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(getPermissionsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleDeletePermissionProfile({ payload: { data, cb } }) {
  try {
    const res = yield call(deletePermissionProfile, data);

    if (res.status) {
      cb(data);
      yield put(setSuccessMessage(res.data.message));
      yield put(deletePermissionProfileSUCCESS());
      yield put(getPermissionProfiles());
    }
  } catch (err) {
    yield put(deletePermissionProfileError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* permissionBuilderSaga() {
  yield takeEvery(FETCH_PERMISSION_PROFILES, handleFetchPermissionProfiles);
  yield takeEvery(FETCH_PERMISSIONS, handleFetchPermissions);
  yield takeEvery(DELETE_PERMISSION_PROFILE, handleDeletePermissionProfile);
}

export default permissionBuilderSaga;
