// Va 05 - 01 - 21

import {
  CLEAR_LOGIN_LOGS,
  FETCH_LOGIN_LOGS,
  FETCH_LOGIN_LOGS_ERROR,
  FETCH_LOGIN_LOGS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  loginLogs: [],
  totalSize: 0,
  sizePerPage: 25,
};

const loginLogsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LOGIN_LOGS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LOGIN_LOGS_SUCCESS:
      return {
        ...state,
        loginLogs: [...state.loginLogs, ...payload.rows],
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_LOGIN_LOGS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_LOGIN_LOGS:
      return {
        ...state,
        loginLogs: [],
      };
    default:
      return state;
  }
};

export default loginLogsReducer;
