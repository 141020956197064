// Smit : 23-11 / 24-11

import { axiosApiAccount, axiosApiAdmin } from "helpers/api_helper";
import {
  DELETE_USER,
  EMAIL_RESET_USER,
  FETCH_TOTAL_USERS_URL,
  FETCH_USER_PROFILE_BY_ID,
  LIST_ALL_USERS,
  UPDATE_USER_PROFILE,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  ADD_EDIT_USER,
  DELETE_USERS,
  FETCH_RESET_USER,
  FETCH_TOTAL_USERS,
  FETCH_USERS,
  FETCH_USER_BY_ID,
} from "./actionTypes";

import {
  addEditUserError,
  deleteUsersError,
  fetchTotalUsersSuccess,
  fetchUser,
  fetchUserByIdError,
  fetchUserByIdSuccess,
  fetchUserError,
  fetchUserSuccess,
  setErrorMessage,
  setSuccessMessage,
} from "store/actions";
import { setUser } from "./actions";

const fetchUsers = data => axiosApiAdmin.post(LIST_ALL_USERS, data);
const fetchUserProfileById = id =>
  axiosApiAccount.get(`${FETCH_USER_PROFILE_BY_ID}/${id}`);
const deleteUsers = data => axiosApiAdmin.post(DELETE_USER, data);
const addEditUsers = data => axiosApiAccount.post(UPDATE_USER_PROFILE, data);
const fetchResetUser = data => axiosApiAccount.post(EMAIL_RESET_USER, data);
const fetchTotalUsersCall = () => axiosApiAdmin.get(FETCH_TOTAL_USERS_URL);

const getPayloadData = key => ({
  search: key.search.trim(),
  page_record: key.sizePerPage,
  page_no: key.page,
  sortField: key.sortField,
  sortOrder: key.sortOrder,
  user_role: key.userRole,
  via_portal: key.filterByApplications,
});

function* handleFetchUsers() {
  const { isCustomer, customer, staff } = yield select(state => state.User);

  const data = {
    ...getPayloadData(isCustomer ? customer : staff),
    is_customer: isCustomer ? 1 : 0,
  };
  try {
    const res = yield call(fetchUsers, data);
    if (res.data?.data?.data) {
      yield put(fetchUserSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(fetchUserError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchUserById({ payload: { id } }) {
  try {
    const res = yield call(fetchUserProfileById, id);
    if (res.data?.data?.user) {
      yield put(fetchUserByIdSuccess(res.data.data.user));
    }
  } catch (err) {
    yield put(fetchUserByIdError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteUser({ payload: { data, cb } }) {
  try {
    const res = yield call(deleteUsers, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchUser());
      cb();
    }
  } catch (err) {
    yield put(deleteUsersError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAddEditUser({ payload: { data, cb } }) {
  try {
    const res = yield call(addEditUsers, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      if (data.user_id) yield put(setUser(null));
      cb();
    }
  } catch (err) {
    yield put(addEditUserError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleResetUser({ payload }) {
  try {
    const res = yield call(fetchResetUser, payload);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchTotalUsers() {
  try {
    const res = yield call(fetchTotalUsersCall);
    if (res?.data?.statusCode === 200 && res.data?.data?.totalUsers) {
      yield put(fetchTotalUsersSuccess(res.data.data.totalUsers));
    }
  } catch (err) {}
}

function* userSaga() {
  yield takeEvery(FETCH_USERS, handleFetchUsers);
  yield takeEvery(FETCH_USER_BY_ID, handleFetchUserById);
  yield takeEvery(DELETE_USERS, handleDeleteUser);
  yield takeEvery(ADD_EDIT_USER, handleAddEditUser);
  yield takeEvery(FETCH_RESET_USER, handleResetUser);
  yield takeEvery(FETCH_TOTAL_USERS, handleFetchTotalUsers);
}

export default userSaga;
