import {
  CLEAR_SUBSCRIPTION_FEATURES,
  FETCH_APPLICATION_SUBSCRIPTIONS,
  FETCH_APPLICATION_SUBSCRIPTIONS_ERROR,
  FETCH_APPLICATION_SUBSCRIPTIONS_SUCCESS,
  FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS,
  FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS_ERROR,
  FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS_SUCCESS,
  SET_APPLICATION_SUBSCRIPTIONS_ACTIVE_STATUS,
  SET_APPLICATION_SUBSCRIPTIONS_PAGE,
  SET_APPLICATION_SUBSCRIPTIONS_SEARCH,
  SET_APPLICATION_SUBSCRIPTIONS_SIZE_PER_PAGE,
  SET_APPLICATION_SUBSCRIPTIONS_SORT_FIELD,
  SET_APPLICATION_SUBSCRIPTIONS_SORT_ORDER,
} from "./actionTypes";

export const fetchApplicationSubscriptions = () => ({
  type: FETCH_APPLICATION_SUBSCRIPTIONS,
});
export const fetchApplicationSubscriptionsSuccess = data => ({
  type: FETCH_APPLICATION_SUBSCRIPTIONS_SUCCESS,
  payload: data,
});
export const fetchApplicationSubscriptionsError = () => ({
  type: FETCH_APPLICATION_SUBSCRIPTIONS_ERROR,
});

export const setApplicationSubscriptionPage = data => ({
  type: SET_APPLICATION_SUBSCRIPTIONS_PAGE,
  payload: data,
});
export const setApplicationSubscriptionSizePerPage = data => ({
  type: SET_APPLICATION_SUBSCRIPTIONS_SIZE_PER_PAGE,
  payload: data,
});
export const setApplicationSubscriptionActiveStatus = data => ({
  type: SET_APPLICATION_SUBSCRIPTIONS_ACTIVE_STATUS,
  payload: data,
});
export const setApplicationSubscriptionSearch = data => ({
  type: SET_APPLICATION_SUBSCRIPTIONS_SEARCH,
  payload: data,
});
export const setApplicationSubscriptionSortField = data => ({
  type: SET_APPLICATION_SUBSCRIPTIONS_SORT_FIELD,
  payload: data,
});
export const setApplicationSubscriptionSortOrder = data => ({
  type: SET_APPLICATION_SUBSCRIPTIONS_SORT_ORDER,
  payload: data,
});

export const fetchApplicationTotalSubscriptions = () => ({
  type: FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS,
});
export const fetchApplicationTotalSubscriptionsSuccess = data => ({
  type: FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS_SUCCESS,
  payload: data,
});
export const fetchApplicationTotalSubscriptionsError = () => ({
  type: FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS_ERROR,
});

export const clearSubscriptionFeatures = () => ({
  type: CLEAR_SUBSCRIPTION_FEATURES,
});
