import {
  FETCH_SUBSCRIPTION_REPORTS,
  FETCH_SUBSCRIPTION_REPORTS_ERROR,
  FETCH_SUBSCRIPTION_REPORTS_SUCCESS,
  FETCH_SUBSCRIPTION_TABLE_DATA,
  FETCH_SUBSCRIPTION_TABLE_DATA_ERROR,
  FETCH_SUBSCRIPTION_TABLE_DATA_SUCCESS,
  SET_ANALYTICS_SUBSCRIPTION_RENEWAL_PAGE,
  SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SIZE_PER_PAGE,
  SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SORT_FIELD,
  SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SORT_ORDER,
  SET_RENEWAL_ANALYTICS_MONTH,
  SET_RENEWAL_ANALYTICS_YEAR,
} from "./actionTypes";

export const getSubscriptionReports = data => ({
  type: FETCH_SUBSCRIPTION_REPORTS,
  payload: data,
});

export const getSubscriptionReportsSuccess = data => ({
  type: FETCH_SUBSCRIPTION_REPORTS_SUCCESS,
  payload: data,
});

export const getSubscriptionReportsError = () => ({
  type: FETCH_SUBSCRIPTION_REPORTS_ERROR,
});

export const setAnalyticsSubscriptionRenewalPage = data => ({
  type: SET_ANALYTICS_SUBSCRIPTION_RENEWAL_PAGE,
  payload: data,
});
export const setAnalyticsSubscriptionRenewalSizePerPage = data => ({
  type: SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SIZE_PER_PAGE,
  payload: data,
});
export const setAnalyticsSubscriptionRenewalSortField = data => ({
  type: SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SORT_FIELD,
  payload: data,
});
export const setAnalyticsSubscriptionRenewalSortOrder = data => ({
  type: SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SORT_ORDER,
  payload: data,
});

export const getRenewalSubscriptionTableData = data => ({
  type: FETCH_SUBSCRIPTION_TABLE_DATA,
  payload: data,
});

export const getRenewalSubscriptionTableDataSuccess = data => ({
  type: FETCH_SUBSCRIPTION_TABLE_DATA_SUCCESS,
  payload: data,
});

export const getRenewalSubscriptionTableDataError = () => ({
  type: FETCH_SUBSCRIPTION_TABLE_DATA_ERROR,
});

export const setAnalyticsSubscriptionRenewalMonth = data => ({
  type: SET_RENEWAL_ANALYTICS_MONTH,
  payload: data,
});

export const setAnalyticsSubscriptionRenewalYear = data => ({
  type: SET_RENEWAL_ANALYTICS_YEAR,
  payload: data,
});
