import { axiosApiAdmin } from "helpers/api_helper";
import { GET_NOT_ACTIVE_SUBSCRIPTION } from "helpers/url_helper";
import { DateTimeTz } from "modules/Analytics/common/utils";
import { setErrorMessage } from "store/actions";
import {
  getNotActiveSubscriptionError,
  getNotActiveSubscriptionSuccess,
} from "./action";
import { FETCH_NOT_ACTIVE_SUBSCRIPTION } from "./actionType";

const { takeEvery, put, select, call } = require("redux-saga/effects");

const fetchNotActiveSubscriptionData = payload =>
  axiosApiAdmin.post(GET_NOT_ACTIVE_SUBSCRIPTION, payload);

function* FormerSubscriptionSaga() {
  function* handleFetchNotActiveSubscription() {
    try {
      const {
        NotActiveSubscriptionTableData: {
          page,
          sizePerPage,
          sortOrder,
          sortField,
          search,
          filter,
        },
        range,
        tz,
      } = yield select(state => state.FormerSubscription);

      const payload = {
        search: search,
        page_record: sizePerPage,
        page_no: page,
        sort_field: sortField,
        sort_order: sortOrder,
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD"),
        end_date: DateTimeTz(range.endDate).format("YYYY-MM-DD"), // .endTime(tz) 4HoursIssue comment
        filter: filter,
      };

      const res = yield call(fetchNotActiveSubscriptionData, payload);
      if (res?.status && res.data?.data) {
        yield put(
          getNotActiveSubscriptionSuccess({
            totalSize: res?.data?.data?.count,
            rows: res?.data?.data?.rows,
          })
        );
      }
    } catch (err) {
      yield put(getNotActiveSubscriptionError());
      yield put(setErrorMessage(err?.response?.data?.message || err.message));
    }
  }

  yield takeEvery(
    FETCH_NOT_ACTIVE_SUBSCRIPTION,
    handleFetchNotActiveSubscription
  );
}

export default FormerSubscriptionSaga;
