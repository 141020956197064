export const SET_SLIDE_SHOW_ID = "SET_SLIDE_SHOW_ID";
export const SET_SLIDR_DOCUMENT_TITLE = "SET_SLIDR_DOCUMENT_TITLE";

export const SET_SLIDR_SLIDES = "SET_SLIDR_SLIDES";
export const SET_SLIDR_ACTIVE_SLIDE = "SET_SLIDR_ACTIVE_SLIDE";
export const SLIDR_ADD_TEXT = "SLIDR_ADD_TEXT";
export const SLIDR_ADD_IMAGE = "SLIDR_ADD_IMAGE";
export const SLIDR_ADD_SLIDE = "SLIDR_ADD_SLIDE";
export const SLIDR_ADD_SLIDE_WITHOUT_TEXT = "SLIDR_ADD_SLIDE_WITHOUT_TEXT";
export const SLIDR_ADD_SLIDE_BELOW_CURRENT_SLIDE =
  "SLIDR_ADD_SLIDE_BELOW_CURRENT_SLIDE";
export const SLIDR_COPY_SLIDE = "SLIDR_COPY_SLIDE";
export const SLIDR_DELETE_SLIDE = "SLIDR_DELETE_SLIDE";
export const SET_SLIDR_ACTIVE_FIELD = "SET_SLIDR_ACTIVE_FIELD";
export const SET_SLIDR_FIELD_VALUE = "SET_SLIDR_FIELD_VALUE";
export const SLIDR_FIELD_DELETE = "SLIDR_FIELD_DELETE";
export const SLIDR_FIELD_COPY = "SLIDR_FIELD_COPY";
export const SLIDR_FIELD_SET_TO_TOP = "SLIDR_FIELD_SET_TO_TOP";
export const SLIDR_FIELD_SET_TO_BACK = "SLIDR_FIELD_SET_TO_BACK";
export const SET_SLIDR_BACKGROUND_OR_IMAGE_VIDEO =
  "SET_SLIDR_BACKGROUND_OR_IMAGE_VIDEO";
export const SLIDR_SET_SHOW_VIDEO_PREVIEW = "SLIDR_SET_SHOW_VIDEO_PREVIEW";
export const SET_SLIDR_SETTINGS_OPTIONS = "SET_SLIDR_SETTINGS_OPTIONS";

export const SET_GLOBAL_COPY_FIELD = "SET_GLOBAL_COPY_FIELD";
export const SET_GLOBAL_PASTE_FIELD = "SET_GLOBAL_PASTE_FIELD";

export const SLIDR_SLIDES_CHANGE = "SLIDR_SLIDES_CHANGE";
export const SLIDR_ACTION_UNDO = "SLIDR_ACTION_UNDO";
export const SLIDR_ACTION_REDO = "SLIDR_ACTION_REDO";

export const SAVE_SLIDE_SHOW = "SAVE_SLIDE_SHOW";
export const SAVE_SLIDE_SHOW_SUCCESS = "SAVE_SLIDE_SHOW_SUCCESS";
export const SAVE_SLIDE_SHOW_ERROR = "SAVE_SLIDE_SHOW_ERROR";
export const INCREMENT_NEW_SLIDE_COUNTER = "INCREMENT_NEW_SLIDE_COUNTER";
export const DECREMENT_NEW_SLIDE_COUNTER = "DECREMENT_NEW_SLIDE_COUNTER";

export const SET_LAST_PUBLISHED = "SET_LAST_PUBLISHED";

export const RESET_SLIDR = "RESET_SLIDR";
