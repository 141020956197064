import {
  FETCH_CHECKOUT_CAMPAIGN_REPORTS,
  FETCH_CHECKOUT_CAMPAIGN_REPORTS_ERROR,
  FETCH_CHECKOUT_CAMPAIGN_REPORTS_SUCCESS,
} from "./actionTypes";

export const getCheckoutCampaignData = data => ({
  type: FETCH_CHECKOUT_CAMPAIGN_REPORTS,
  payload: data,
});

export const getCheckoutCampaignDataSuccess = data => ({
  type: FETCH_CHECKOUT_CAMPAIGN_REPORTS_SUCCESS,
  payload: data,
});

export const getCheckoutCampaignDataError = () => ({
  type: FETCH_CHECKOUT_CAMPAIGN_REPORTS_ERROR,
});
