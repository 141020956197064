import {
  COMPONENT_LOADER_DASHBOARD,
  FETCH_CANCELLED_SUBSCRIPTION_CHART,
  FETCH_CANCELLED_SUBSCRIPTION_CHART_ERROR,
  FETCH_CANCELLED_SUBSCRIPTION_CHART_SUCCESS,
  FETCH_DASHBOARD_CARD_DATA,
  FETCH_DASHBOARD_CARD_DATA_ERROR,
  FETCH_DASHBOARD_CARD_DATA_SUCCESS,
  FETCH_EARNINGS_CHART_DATA,
  FETCH_EARNINGS_CHART_DATA_ERROR,
  FETCH_EARNINGS_CHART_DATA_SUCCESS,
  FETCH_NEW_REGISTERED_USER_CHART,
  FETCH_NEW_REGISTERED_USER_CHART_ERROR,
  FETCH_NEW_REGISTERED_USER_CHART_SUCCESS,
  FETCH_NEW_SUBSCRIPTION_CHART,
  FETCH_NEW_SUBSCRIPTION_CHART_ERROR,
  FETCH_NEW_SUBSCRIPTION_CHART_SUCCESS,
  FETCH_ORDER_BY_PRODUCT_CHART_DATA,
  FETCH_ORDER_BY_PRODUCT_CHART_DATA_ERROR,
  FETCH_ORDER_BY_PRODUCT_CHART_DATA_SUCCESS,
  FETCH_RENEWED_SUBSCRIPTION_CHART,
  FETCH_RENEWED_SUBSCRIPTION_CHART_ERROR,
  FETCH_RENEWED_SUBSCRIPTION_CHART_SUCCESS,
  FETCH_SALES_ANALYTICS_CHART_DATA,
  FETCH_SALES_ANALYTICS_CHART_DATA_ERROR,
  FETCH_SALES_ANALYTICS_CHART_DATA_SUCCESS,
  FETCH_TOP_SELLING_PRODUCTS_CHART_DATA,
  FETCH_TOP_SELLING_PRODUCTS_CHART_DATA_ERROR,
  FETCH_TOP_SELLING_PRODUCTS_CHART_DATA_SUCCESS,
  FETCH_TOP_VISITORS_CHART_DATA,
  FETCH_TOP_VISITORS_CHART_DATA_ERROR,
  FETCH_TOP_VISITORS_CHART_DATA_SUCCESS,
  LIST_ALL_SYSTEM_CONFIGURATION_ERROR,
  LIST_ALL_SYSTEM_CONFIGURATION_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  newUserRegisteredChart: [],
  cancelledSubscription: [],
  topSellingProductsChartData: [],
  topVisitorsChartData: [],
  earningsChartData: [],
  dashboardCardData: [],
  renewSubscription: [],
  newSubscription: [],
  loadingComponents: [],
  salesAnalyticsChartData: [],
  orderByProductChartData: [],
  systemConfiguration: [],
};

const dashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COMPONENT_LOADER_DASHBOARD:
      return {
        ...state,
        loadingComponents: [...state.loadingComponents, payload],
      };
    case FETCH_NEW_REGISTERED_USER_CHART:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NEW_REGISTERED_USER_CHART_SUCCESS:
      return {
        ...state,
        newUserRegisteredChart: payload.monthData,
        loadingComponents: state.loadingComponents.filter(
          item => item !== "newUserRegisteredChart"
        ),
      };
    case FETCH_NEW_REGISTERED_USER_CHART_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_CANCELLED_SUBSCRIPTION_CHART:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CANCELLED_SUBSCRIPTION_CHART_SUCCESS:
      return {
        ...state,
        cancelledSubscription: payload.monthData,
        loadingComponents: state.loadingComponents.filter(
          item => item !== "cancelledSubscription"
        ),
      };
    case FETCH_CANCELLED_SUBSCRIPTION_CHART_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_DASHBOARD_CARD_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DASHBOARD_CARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardCardData: payload,
        loadingComponents: state.loadingComponents.filter(
          item => item !== "dashboardCardData"
        ),
      };
    case FETCH_DASHBOARD_CARD_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_EARNINGS_CHART_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EARNINGS_CHART_DATA_SUCCESS:
      return {
        ...state,
        earningsChartData: payload,
        loadingComponents: state.loadingComponents.filter(
          item => item !== "earningsChartData"
        ),
      };
    case FETCH_EARNINGS_CHART_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_TOP_SELLING_PRODUCTS_CHART_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TOP_SELLING_PRODUCTS_CHART_DATA_SUCCESS:
      return {
        ...state,
        topSellingProductsChartData: payload.topSellingProduct,
        loadingComponents: state.loadingComponents.filter(
          item => item !== "topSellingProductsChartData"
        ),
      };
    case FETCH_TOP_SELLING_PRODUCTS_CHART_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_TOP_VISITORS_CHART_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TOP_VISITORS_CHART_DATA_SUCCESS:
      return {
        ...state,
        topVisitorsChartData: payload,
        loadingComponents: state.loadingComponents.filter(
          item => item !== "topVisitorsChartData"
        ),
      };
    case FETCH_TOP_VISITORS_CHART_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_RENEWED_SUBSCRIPTION_CHART:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RENEWED_SUBSCRIPTION_CHART_SUCCESS:
      return {
        ...state,
        renewSubscription: payload.monthData,
        loadingComponents: state.loadingComponents.filter(
          item => item !== "renewSubscription"
        ),
      };
    case FETCH_RENEWED_SUBSCRIPTION_CHART_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_NEW_SUBSCRIPTION_CHART:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NEW_SUBSCRIPTION_CHART_SUCCESS:
      return {
        ...state,
        newSubscription: payload.monthData,
        loadingComponents: state.loadingComponents.filter(
          item => item !== "newSubscription"
        ),
      };
    case FETCH_NEW_SUBSCRIPTION_CHART_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_SALES_ANALYTICS_CHART_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SALES_ANALYTICS_CHART_DATA_SUCCESS:
      return {
        ...state,
        salesAnalyticsChartData: payload.salesAnalytics,
        loadingComponents: state.loadingComponents.filter(
          item => item !== "salesAnalyticsChartData"
        ),
      };
    case FETCH_SALES_ANALYTICS_CHART_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ORDER_BY_PRODUCT_CHART_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ORDER_BY_PRODUCT_CHART_DATA_SUCCESS:
      return {
        ...state,
        orderByProductChartData: payload.orderByProduct,
        loadingComponents: state.loadingComponents.filter(
          item => item !== "orderByProductChartData"
        ),
      };
    case FETCH_ORDER_BY_PRODUCT_CHART_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case LIST_ALL_SYSTEM_CONFIGURATION_SUCCESS:
      return {
        ...state,
        systemConfiguration: action.payload,
        loading: true,
      };
    case LIST_ALL_SYSTEM_CONFIGURATION_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default dashboardReducer;
