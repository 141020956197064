import { axiosApiAdmin } from "helpers/api_helper";
import { USER_WISE_ADMIN_ACTIVITY_LOG } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {} from "store/actions";
import {
  fetchAdminActiveLogsError,
  fetchAdminActiveLogsSuccess,
} from "./action";
import { FETCH_ADMIN_ACTIVE_LOGS } from "./actionTypes";

const fetchAdminActivityLogs = data =>
  axiosApiAdmin.post(USER_WISE_ADMIN_ACTIVITY_LOG, data);

function* handleFetchAdminActivityLogs({ payload }) {
  try {
    const res = yield call(fetchAdminActivityLogs, payload);
    if (res?.data?.data) {
      yield put(fetchAdminActiveLogsSuccess(res?.data?.data));
    }
  } catch (err) {
    yield put(fetchAdminActiveLogsError());
  }
}

function* activeMembershipSaga() {
  yield takeEvery(FETCH_ADMIN_ACTIVE_LOGS, handleFetchAdminActivityLogs);
}

export default activeMembershipSaga;
