import { toast } from "react-toastify";
import { SET_ERROR, SET_SUCCESS } from "./actionTypes";

export const setSuccessMessage = data => {
  if (!!data) toast.success(data);
  return {
    type: SET_SUCCESS,
    payload: data,
  };
};

export const setErrorMessage = data => {
  if (!!data) toast.error(data);
  return { type: SET_ERROR, payload: data };
};
