import {
  CLEAR_PAGINATION,
  FETCH_LIVE_CHAT_ARC_DATA,
  FETCH_LIVE_CHAT_ARC_DATA_ERROR,
  FETCH_LIVE_CHAT_ARC_DATA_SUCCESS,
  SET_ACTIVE_CHAT_ID,
  SET_CUSTOMER_RANDOM_COLOR,
  SET_CUSTOMER_USER_NAME,
  SET_LIST_LIVE_CHAT,
  SET_LIST_LIVE_CHAT_USER_INFO,
  SET_LIVE_CHAT_ARC_DATA,
  SET_LIVE_CHAT_ARC_DATE_RANGE,
  SET_LIVE_CHAT_ARC_ORDER,
  SET_LIVE_CHAT_ARC_SEARCH,
  SET_LIVE_CHAT_PAGE_NO,
  SET_LIVE_CHAT_PAGINATION,
  SET_PRE_CHAT_FORM_DATA,
} from "./actionTypes";

export const fetchLiveChatData = data => ({
  type: FETCH_LIVE_CHAT_ARC_DATA,
  payload: data,
});

export const fetchLiveChatDataSuccess = data => ({
  type: FETCH_LIVE_CHAT_ARC_DATA_SUCCESS,
  payload: data,
});

export const fetchLiveChatDataError = data => ({
  type: FETCH_LIVE_CHAT_ARC_DATA_ERROR,
  payload: data,
});

export const setLiveChatData = data => ({
  type: SET_LIVE_CHAT_ARC_DATA,
  payload: data,
});

export const setLiveChatDataList = data => ({
  type: SET_LIST_LIVE_CHAT,
  payload: data,
});

export const setLiveChatUserInfo = data => ({
  type: SET_LIST_LIVE_CHAT_USER_INFO,
  payload: data,
});

export const setActiveChatId = data => ({
  type: SET_ACTIVE_CHAT_ID,
  payload: data,
});

export const setPreChatFormData = data => ({
  type: SET_PRE_CHAT_FORM_DATA,
  payload: data,
});

export const setActiveUserRandomColor = data => ({
  type: SET_CUSTOMER_RANDOM_COLOR,
  payload: data,
});

export const setActiveUserName = data => ({
  type: SET_CUSTOMER_USER_NAME,
  payload: data,
});

export const setLiveChatPageNo = data => ({
  type: SET_LIVE_CHAT_PAGE_NO,
  payload: data,
});

export const setLiveChatSearchTerm = data => ({
  type: SET_LIVE_CHAT_ARC_SEARCH,
  payload: data,
});

export const setLiveChatDateRange = data => ({
  type: SET_LIVE_CHAT_ARC_DATE_RANGE,
  payload: data,
});

export const setLiveChatSortOrder = data => ({
  type: SET_LIVE_CHAT_ARC_ORDER,
  payload: data,
});

export const setPaginationState = data => ({
  type: SET_LIVE_CHAT_PAGINATION,
  payload: data,
});

export const clearPaginationState = data => ({
  type: CLEAR_PAGINATION,
  payload: data,
});
