import {
  ADD_EDIT_ICEBREAKER,
  ADD_EDIT_ICEBREAKER_ERROR,
  ADD_EDIT_ICEBREAKER_SUCCESS,
  CLEAR_ICEBREAKER_FEATURES,
  DELETE_ICEBREAKER,
  DELETE_ICEBREAKER_ERROR,
  DELETE_ICEBREAKER_SUCCESS,
  FETCH_ICEBREAKER,
  FETCH_ICEBREAKER_ERROR,
  FETCH_ICEBREAKER_FILTER,
  FETCH_ICEBREAKER_FILTER_ERROR,
  FETCH_ICEBREAKER_FILTER_SUCCESS,
  FETCH_ICEBREAKER_SUCCESS,
  GET_ICEBREAKER_DETAILS,
  GET_ICEBREAKER_DETAILS_ERROR,
  GET_ICEBREAKER_DETAILS_SUCCESS,
  SET_PAGE_ICEBREAKER,
  SET_SEARCH_ICEBREAKER,
  SET_SIZE_PER_PAGE_ICEBREAKER,
  SET_SORT_FIELD_ICEBREAKER,
  SET_SORT_ORDER_ICEBREAKER,
  SET_SYSTEM,
} from "./actionsTypes";

const initialState = {
  loading: false,
  icebreakers: [],
  totalSize: 0,
  search: "",
  sizePerPage: 25,
  page: 1,
  sortField: "icebreaker_title",
  sortOrder: "DESC",
  icebreakersFilters: [],
  icebreaker: [],
  is_system: 1,
};

const Icebreaker = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ICEBREAKER:
      return {
        ...state,
        loading: true,
      };

    case FETCH_ICEBREAKER_SUCCESS:
      return {
        ...state,
        icebreakers: payload.data.rows,
        loading: false,
        totalSize: payload.data.count,
      };

    case FETCH_ICEBREAKER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_PAGE_ICEBREAKER:
      return {
        ...state,
        page: payload,
      };

    case SET_SIZE_PER_PAGE_ICEBREAKER:
      return {
        ...state,
        sizePerPage: payload,
      };

    case SET_SEARCH_ICEBREAKER:
      return {
        ...state,
        search: payload,
      };
    case CLEAR_ICEBREAKER_FEATURES:
      return {
        ...state,
        totalSize: 0,
        search: "",
        sizePerPage: 25,
        page: 1,
        sortField: "icebreaker_title",
        sortOrder: "DESC",
      };

    case ADD_EDIT_ICEBREAKER:
      return {
        ...state,
        loading: true,
      };

    case ADD_EDIT_ICEBREAKER_SUCCESS:
      return {
        ...state,
        icebreakers: [...state.icebreakers, payload],
      };

    case ADD_EDIT_ICEBREAKER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case FETCH_ICEBREAKER_FILTER:
      return {
        ...state,
        loading: true,
      };

    case FETCH_ICEBREAKER_FILTER_SUCCESS:
      return {
        ...state,
        icebreakersFilters: payload.data.rows,
        loading: false,
      };

    case FETCH_ICEBREAKER_FILTER_ERROR:
      return {
        ...state,
        loading: false,
      };
    case GET_ICEBREAKER_DETAILS:
      return {
        ...state,
        loading: true,
      };

    case GET_ICEBREAKER_DETAILS_SUCCESS:
      return {
        ...state,
        icebreaker: payload.data,
        loading: false,
      };

    case GET_ICEBREAKER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_ICEBREAKER:
      return {
        ...state,
        loading: true,
      };

    case DELETE_ICEBREAKER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case DELETE_ICEBREAKER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_SORT_FIELD_ICEBREAKER:
      return {
        ...state,
        sortField: action.payload,
      };
    case SET_SORT_ORDER_ICEBREAKER:
      return {
        ...state,
        sortOrder: action.payload,
      };

    case SET_SYSTEM:
      return {
        ...state,
        is_system: action.payload,
      };
    default:
      return state;
  }
};

export default Icebreaker;
