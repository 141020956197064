import { axiosApiAdmin } from "helpers/api_helper";
import {
  GET_BLOG_SITEMAP_CHART,
  GET_BLOG_SITE_MAP_DATA,
} from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  getBlogSiteMapChartSuccess,
  getBlogSitemapPotential,
  getBlogSitemapPotentialNum,
  getBlogSiteMapReportLinkedPostSuccess,
  getBlogSiteMapReportNonLinkedPostSuccess,
  getBlogSiteMapReportNumberLinkedPostSuccess,
  getBlogSiteMapReportNumberNonLinkedPostSuccess,
  getBlogSiteMapReportSuccess,
  getBlogSiteMapReportTotalLinkedPostSuccess,
} from "./action";
import { FETCH_BLOG_SITEMAP, FETCH_BLOG_SITEMAP_CHART } from "./actionTypes";

const fetchAllBlogSitemap = data => {
  return axiosApiAdmin.post(GET_BLOG_SITE_MAP_DATA, data);
};

const fetchAllBlogSitemapChart = data => {
  return axiosApiAdmin.get(GET_BLOG_SITEMAP_CHART, data);
};

function* handleFetchBlogSitemap({ payload }) {
  try {
    const res = yield call(fetchAllBlogSitemap, payload);
    if (res.status) {
      yield put(getBlogSiteMapReportSuccess(res.data.data));
      yield put(
        getBlogSiteMapReportLinkedPostSuccess(res.data.data.linkedPost)
      );
      yield put(
        getBlogSiteMapReportNonLinkedPostSuccess(res.data.data.nonLinkedPost)
      );
      yield put(
        getBlogSiteMapReportNumberLinkedPostSuccess(res.data.data.linkedPostNum)
      );
      yield put(
        getBlogSiteMapReportNumberNonLinkedPostSuccess(
          res.data.data.nonLinkedPostNum
        )
      );
      yield put(
        getBlogSiteMapReportTotalLinkedPostSuccess(res.data.data.totalPostNum)
      );
      yield put(getBlogSitemapPotential(res.data.data.potentialLinkedPost));
      yield put(
        getBlogSitemapPotentialNum(res.data.data.potentialLinkedPostNum)
      );
    }
  } catch (err) {
    console.error(err);
  }
}

function* handleFetchBlogSitemapChartData() {
  try {
    const res = yield call(fetchAllBlogSitemapChart);
    if (res.status) {
      yield put(getBlogSiteMapChartSuccess(res.data.data));
    }
  } catch (err) {
    console.error(err);
  }
}

function* blogSiteMapSaga() {
  yield takeEvery(FETCH_BLOG_SITEMAP, handleFetchBlogSitemap);
  yield takeEvery(FETCH_BLOG_SITEMAP_CHART, handleFetchBlogSitemapChartData);
}

export default blogSiteMapSaga;
