import {
  DELETE_PERMISSION_PROFILE,
  DELETE_PERMISSION_PROFILE_ERROR,
  DELETE_PERMISSION_PROFILE_SUCCESS,
  FETCH_PERMISSIONS,
  FETCH_PERMISSIONS_ERROR,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSION_PROFILES,
  FETCH_PERMISSION_PROFILES_ERROR,
  FETCH_PERMISSION_PROFILES_SUCCESS,
} from "./actionTypes";

export const getPermissionProfiles = () => ({
  type: FETCH_PERMISSION_PROFILES,
});
export const getPermissionsProfilesSuccess = data => ({
  type: FETCH_PERMISSION_PROFILES_SUCCESS,
  payload: data,
});
export const getPermissionProfilesError = () => ({
  type: FETCH_PERMISSION_PROFILES_ERROR,
});

export const getPermissions = () => ({
  type: FETCH_PERMISSIONS,
});
export const getPermissionsSuccess = data => ({
  type: FETCH_PERMISSIONS_SUCCESS,
  payload: data,
});
export const getPermissionsError = () => ({
  type: FETCH_PERMISSIONS_ERROR,
});

export const deletePermissionProfile = data => ({
  type: DELETE_PERMISSION_PROFILE,
  payload: data,
});
export const deletePermissionProfileSUCCESS = () => ({
  type: DELETE_PERMISSION_PROFILE_SUCCESS,
});
export const deletePermissionProfileError = () => ({
  type: DELETE_PERMISSION_PROFILE_ERROR,
});
