import {
  SUBSCRIPTION_FEEDBACK_ERROR,
  SUBSCRIPTION_FEEDBACK_LOADING,
  SUBSCRIPTION_FEEDBACK_SUCCESS,
} from "./actionTypes";

const initialState = {
  FeedbackLoading: false,
  subscriptionFeedbackData: [],
};

const ActiveSubscriptionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SUBSCRIPTION_FEEDBACK_LOADING:
      return {
        ...state,
        FeedbackLoading: true,
      };
    case SUBSCRIPTION_FEEDBACK_SUCCESS:
      return {
        ...state,
        FeedbackLoading: false,
        subscriptionFeedbackData: payload,
      };
    case SUBSCRIPTION_FEEDBACK_ERROR:
      return {
        ...state,
        FeedbackLoading: false,
      };
    default:
      return state;
  }
};

export default ActiveSubscriptionReducer;
