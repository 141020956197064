export const FETCH_GAME_RATING = "FETCH_GAME_RATING";
export const FETCH_GAME_RATING_SUCCESS = "FETCH_GAME_RATING_SUCCESS";
export const FETCH_GAME_RATING_ERROR = "FETCH_GAME_RATING_ERROR";

export const SET_REVIEW_PAGE = "SET_REVIEW_PAGE";
export const SET_REVIEW_SIZE_PER_PAGE = "SET_REVIEW_SIZE_PER_PAGE";

export const SET_REVIEW_SORT_FIELD = "SET_REVIEW_SORT_FIELD";
export const SET_REVIEW_SORT_ORDER = "SET_REVIEW_SORT_ORDER";

export const DELETE_REVIEW = "DELETE_REVIEW";
export const CLEAR_REVIEW_FEATURES = "CLEAR_REVIEW_FEATURES";
