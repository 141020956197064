export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USER_BY_ID = "FETCH_USER_BY_ID";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USER_BY_ID_SUCCESS = "FETCH_USER_BY_ID_SUCCESS";
export const FETCH_USERS_ERROR = "FETCH_USERS_ERROR";
export const FETCH_USER_BY_ID_ERROR = "FETCH_USER_BY_ID_ERROR";
export const SET_USER = "SET_USER";

export const ADD_EDIT_USER = "ADD_EDIT_USER";
export const ADD_EDIT_USER_SUCCESS = "ADD_EDIT_USER_SUCCESS";
export const ADD_EDIT_USER_ERROR = "ADD_EDIT_USER_ERROR";

export const DELETE_USERS = "DELETE_USERS";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_ERROR = "DELETE_USERS_ERROR";

export const SET_USER_PAGE = "SET_USER_PAGE";
export const SET_USER_SIZE_PER_PAGE = "SET_USER_SIZE_PER_PAGE";
export const SET_USER_SEARCH = "SET_USER_SEARCH";
export const SET_USER_SORT_FIELD = "SET_USER_SORT_FIELD";
export const SET_USER_SORT_ORDER = "SET_USER_SORT_ORDER";
export const SET_IS_CUSTOMER = "SET_IS_CUSTOMER";

export const FETCH_RESET_USER = "FETCH_RESET_USER";

export const FETCH_TOTAL_USERS = "FETCH_TOTAL_USERS";
export const FETCH_TOTAl_USERS_SUCCESS = " FETCH_TOTAl_USERS_SUCCESS";
export const CHANGE_USER_ROLE = "CHANGE_USER_ROLE";

export const FILTER_BY_APPLICATIONS = "FILTER_BY_APPLICATIONS";
export const CLEAR_USER_FEATURES = "CLEAR_USER_FEATURES";
