//ADD/EDIT CATEGOTY
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_ERROR = "ADD_CATEGORY_ERROR";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const EDIT_CATEGORY_SUCCESS = "EDIT_CATEGORY_SUCCESS";
export const EDIT_CATEGORY_ERROR = "EDIT_CATEGORY_ERROR";

export const FETCH_HABIT_CATEGORY = "FETCH_HABIT_CATEGORY";
export const FETCH_HABIT_CATEGORY_SUCCESS = "FETCH_HABIT_CATEGORY_SUCCESS";
export const FETCH_HABIT_CATEGORY_ERROR = "FETCH_HABIT_CATEGORY_ERROR";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR";

//GET ICONS
export const FETCH_LINKS = "FETCH_LINKS";
export const FETCH_LINKS_SUCCESS = "FETCH_LINKS_SUCCESS";
export const FETCH_LINKS_ERROR = "FETCH_LINKS_ERROR";
