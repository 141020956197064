import { axiosApiAdmin } from "helpers/api_helper";
import {
  DELETE_USER_PAYMENT_METHODS,
  LIST_USER_PAYMENT_METHODS,
  SAVE_USER_PAYMENT_METHODS,
} from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  FETCH_PAYMENT_METHODS,
} from "./actionTypes";

import { setErrorMessage, setSuccessMessage } from "store/actions";
import {
  addPaymentMethodError,
  addPaymentMethodSuccess,
  deletePaymentMethodError,
  deletePaymentMethodSuccess,
  fetchUserPaymentMethods,
  fetchUserPaymentMethodsError,
  fetchUserPaymentMethodsSuccess,
} from "./actions";

const fetchUserPaymentMethod = data =>
  axiosApiAdmin.post(LIST_USER_PAYMENT_METHODS, data);
const addPaymentMethod = data =>
  axiosApiAdmin.post(SAVE_USER_PAYMENT_METHODS, data);
const deletePaymentMethod = data =>
  axiosApiAdmin.post(DELETE_USER_PAYMENT_METHODS, data);

function* handleFetchUserPaymentMethods({ payload }) {
  try {
    const res = yield call(fetchUserPaymentMethod, payload);
    if (res.data?.data?.rows) {
      yield put(fetchUserPaymentMethodsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchUserPaymentMethodsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAddPaymentMethod({ payload: { data, cb } }) {
  try {
    const res = yield call(addPaymentMethod, data);
    if (res.status) {
      yield put(addPaymentMethodSuccess(res.data.data.card));
      yield put(setSuccessMessage(res.data.message));
      yield put(
        fetchUserPaymentMethods({
          page_no: 1,
          page_record: 10,
          user_id: data.user_id,
        })
      ); /// 23/1/23 arvina changes delete card
      if (!!cb && typeof cb === "function") cb();
    }
  } catch (err) {
    yield put(addPaymentMethodError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeletePaymentMethod({ payload: { data, cb } }) {
  try {
    const res = yield call(deletePaymentMethod, data);
    if (res?.data) {
      yield put(deletePaymentMethodSuccess(data.pg_customer_card_id));
      yield put(setSuccessMessage(res.data.message));
      cb();
    }
  } catch (err) {
    yield put(deletePaymentMethodError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* paymentMethodSaga() {
  yield takeEvery(FETCH_PAYMENT_METHODS, handleFetchUserPaymentMethods);
  yield takeEvery(ADD_PAYMENT_METHOD, handleAddPaymentMethod);
  yield takeEvery(DELETE_PAYMENT_METHOD, handleDeletePaymentMethod);
}

export default paymentMethodSaga;
