// -- Indicates Menu

const permissions = {
  // Dashboard
  dashboard_menu: "dashboard_menu",
  // -- reports
  total_sales: "total_sales",
  net_sales: "net_sales",
  registered_users: "registered_users",
  subscribed_users: "subscribed_users",
  orders: "orders",
  discounted_orders: "discounted_orders",
  items_sold: "items_sold",
  net_discounted_amount: "net_discounted_amount",
  active_subscriptions: "active_subscriptions",
  grow_kids: "grow_kids",
  grow_students: "grow_students",
  grow_groups: "grow_groups",
  monthly: "monthly",
  quarterly: "quarterly",
  yearly: "yearly",
  // -- charts
  gross_sales_chart: "gross_sales_chart",
  net_sales_chart: "net_sales_chart",
  orders_chart: "orders_chart",
  discounted_orders_chart: "discounted_orders_chart",
  net_discounted_amount_chart: "net_discounted_amount_chart",
  registered_users_chart: "registered_users_chart",
  renewed_subscriptions_chart: "renewed_subscriptions_chart",
  cancelled_subscriptions_chart: "cancelled_subscriptions_chart",
  sales_analytics_chart: "sales_analytics_chart",
  // -- leader board
  top_customers: "top_customers",
  top_coupons: "top_coupons",
  top_products: "top_products",

  // Customer
  customer_menu: "customer_menu",
  customer_dashboard: "customer_dashboard",
  add_customer: "add_customer",
  edit_customer: "edit_customer",
  subscription: "subscriptions",
  membership: "membership",
  active_product: "active_products",
  payment_info: "payment_info",
  login_activity: "login_activity",
  email_log: "email_logs",
  support_tickets_log: "support_tickets_log",
  transaction_log: "transaction_logs",
  application_statistic_data: "application_statistic_data",
  // Staff
  staff_menu: "staff_menu",
  staff_dashboard: "staff_dashboard",
  add_staff: "add_staff",
  edit_staff: "edit_staff",
  // Application
  application_menu: "application_menu",

  //Curriculum Application
  curriculum_application_dashboard: "curriculum_application_dashboard",
  curriculum_edit_application: "curriculum_edit_application",
  curriculum_app_subscription: "curriculum_app_subscription",
  curriculum_app_product: "curriculum_app_product",
  curriculum_app_coupon: "curriculum_app_coupon",
  curriculum_app_membership: "curriculum_app_membership",
  curriculum_app_orders: "curriculum_app_orders",
  curriculum_app_setting: "curriculum_app_setting",

  //Builder Application
  builder_application_dashboard: "builder_application_dashboard",
  builder_edit_application: "builder_edit_application",
  builder_app_product: "builder_app_product",
  builder_app_subscription: "builder_app_subscription",
  builder_app_coupon: "builder_app_coupon",
  builder_app_membership: "builder_app_membership",
  builder_app_orders: "builder_app_orders",
  builder_app_setting: "builder_app_setting",

  //Game Application
  game_application_dashboard: "game_application_dashboard",
  game_edit_application: "game_edit_application",
  games: "games",
  icebreakers: "icebreakers",
  slideshows: "slideshows",
  reviews: "reviews",
  notification: "notification",
  logs: "logs",
  game_filters: "game_filters",
  game_app_setting: "game_app_setting",

  //Hub Application
  hub_application_dashboard: "hub_application_dashboard",
  hub_edit_application: "hub_edit_application",
  hub_app_product: "hub_app_product",
  hub_app_coupon: "hub_app_coupon",
  hub_app_membership: "hub_app_membership",
  hub_app_subscription: "hub_app_subscription",
  hub_app_orders: "hub_app_orders",
  hub_app_setting: "hub_app_setting",

  //Slidr Application
  slidr_application_dashboard: "slidr_application_dashboard",
  slidr_edit_application: "slidr_edit_application",
  slidr_app_product: "slidr_app_product",
  slidr_app_coupon: "slidr_app_coupon",
  slidr_app_membership: "slidr_app_membership",
  slidr_app_subscription: "slidr_app_subscription",
  slidr_app_orders: "slidr_app_orders",
  slidr_app_setting: "slidr_app_setting",

  //People Application
  people_application_dashboard: "people_application_dashboard",
  people_edit_application: "people_edit_application",
  people_app_product: "people_app_product",
  people_app_coupon: "people_app_coupon",
  people_app_membership: "people_app_membership",
  people_app_subscription: "people_app_subscription",
  people_app_orders: "people_app_orders",
  people_app_setting: "people_app_setting",

  //Affiliates Application
  affiliates_application_dashboard: "affiliates_application_dashboard",
  affiliates_edit_application: "affiliates_edit_application",
  affiliates: "affiliates",
  referrals: "referrals",
  visits: "visits",
  payouts: "payouts",
  affiliates_app_setting: "affiliates_app_setting",

  //Habit Application
  habit_application_dashboard: "habit_application_dashboard",
  habit_edit_application: "habit_edit_application",
  habit_app_category: "habit_app_category",
  habit_app_habit: "habit_app_habit",
  habit_app_setting: "habit_app_setting",

  //Habit Kids Application
  habit_kids_application_dashboard: "habit_kids_application_dashboard",
  habit_kids_edit_application: "habit_kids_edit_application",
  habit_kids_app_category: "habit_kids_app_category",
  habit_kids_app_habit: "habit_kids_app_habit",
  habit_kids_app_setting: "habit_kids_app_setting",

  //Board Application
  board_application_dashboard: "board_application_dashboard",
  board_edit_application: "board_edit_application",
  board_app_product: "board_app_product",
  board_app_coupon: "board_app_coupon",
  board_app_membership: "board_app_membership",
  board_app_subscription: "board_app_subscription",
  board_app_orders: "board_app_orders",
  board_app_setting: "board_app_setting",

  //Grow YOU Application
  grow_you_application_dashboard: "grow_you_application_dashboard",
  grow_you_edit_application: "grow_you_edit_application",
  grow_you_app_product: "grow_you_app_product",
  grow_you_app_coupon: "grow_you_app_coupon",
  grow_you_app_subscription: "grow_you_app_subscription",
  grow_you_app_orders: "grow_you_app_orders",
  grow_you_app_setting: "grow_you_app_setting",

  //Grow Kids Music Application
  kids_application_dashboard: "kids_application_dashboard",
  kids_music_edit_application: "kids_music_edit_application",
  kids_app_product: "kids_app_product",
  kids_app_coupon: "kids_app_coupon",
  kids_app_subscription: "kids_app_subscription",
  kids_app_orders: "kids_app_orders",
  kids_app_setting: "kids_app_setting",
  kids_app_music: "kids_app_music",

  // -- Tools
  tool_menu: "tool_menu",
  // Volume Builder
  volume_builder: "volume_builder",
  //Menu Permission
  permission_builder: "permission_builder",
  // System Page
  system_page: "system_page",
  // Redirection
  redirection: "redirection",
  // Cloud Storage
  cloud_storage: "cloud_storage",
  // Integration
  integration: "integration",
  // Debugger Tool
  debugger_tool: "debugger_tool",
  // Support Ticket
  support_ticket: "support_ticket",
  //Blog Site Map
  blog_sitemap: "blog_sitemap",
  // realtime
  realtime_dashboard: "realtime_dashboard", //arvina sidebar changes
  email_logs_tool: "email_logs_tool",
  // Activity Log
  log_activity: "log_activity",
  // Analytics
  analytics: "analytics",
  analytics_revenues: "analytics_revenues",
  analytics_products: "analytics_products",
  analytics_coupons: "analytics_coupons",
  analytics_users: "analytics_users",
  analytics_subscriptions: "analytics_subscriptions",
  analytics_emails: "analytics_emails",
  analytics_subscribers: "analytics_subscribers",

  //reports
  reports_menu: "reports_menu",
  // Active Subscriptions
  active_subscription: "active_subscription",
  // New Active Subscriptions
  new_active_subscriptions: "new_active_subscriptions",
  new_active_subscription: "new-active_subscription",
  // Success Subscriptions
  success_subscription: "success_subscription",
  // Expired Subscriptions
  expired_subscription: "expired_subscription",
  // Former Subscriptions
  former_subscription: "former_subscription",
  // Failed Subscriptions
  failed_subscription: "failed_subscription",
  //cancel Subscriptions
  cancel_subscription: "cancel_subscription",
  //Active Users
  active_users: "active_users",
  //Email Sent
  sent_email: "sent_email",
  // Admin Log
  admin_log: "admin_log",
  //Disputed Transactions
  disputed_transactions: "disputed_transactions",
  //live_chat
  live_chat: "live_chat",
  //geo Location
  geo_location: "geo_location",
  //Metric
  metric: "metric",
  //Air Table Ticket
  air_table_ticket: "air_table_ticket",
  air_table_new_ticket: "air_table_new_ticket",
  air_table_list_ticket: "air_table_list_ticket",
  //Quick links
  quick_links: "quick_links",

  // numbers_application_dashboard

  numbers_application_dashboard: "numbers_application_dashboard",
  grow_free_trial: "grow_free_trial",
  free_trial_application_dashboard: "free_trial_application_dashboard",
  grow_books_application_dashboard: "grow_books_application_dashboard",
  grow_free_trial_edit_application: "grow_free_trial_edit_application",
  numbers_app_setting: "numbers_app_setting",
  numbers_edit_application: "numbers_edit_application",
};

export default permissions;
