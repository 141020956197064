import {
  CLEAR_ORDERS_FEATURES,
  FETCH_ORDERS,
  FETCH_ORDERS_ERROR,
  FETCH_ORDERS_SUCCESS,
  FILTER_BY_ORDER_STATUS,
  SET_ORDERS_PAGE,
  SET_ORDERS_SIZE_PER_PAGE,
  SET_ORDER_SEARCH,
  SET_ORDER_SORT_FIELD,
  SET_ORDER_SORT_ORDER,
} from "./actionTypes";

export const fetchOrders = data => ({
  type: FETCH_ORDERS,
  payload: data,
});
export const fetchOrdersSuccess = data => ({
  type: FETCH_ORDERS_SUCCESS,
  payload: data,
});
export const fetchOrdersError = () => ({
  type: FETCH_ORDERS_ERROR,
});
export const setOrdersPage = data => ({
  type: SET_ORDERS_PAGE,
  payload: data,
});
export const setOrdersSizePerPage = data => ({
  type: SET_ORDERS_SIZE_PER_PAGE,
  payload: data,
});
export const setOrderSearch = data => ({
  type: SET_ORDER_SEARCH,
  payload: data,
});
export const setOrderSortField = data => ({
  type: SET_ORDER_SORT_FIELD,
  payload: data,
});
export const setOrderSortOrder = data => ({
  type: SET_ORDER_SORT_ORDER,
  payload: data,
});
export const clearOrdersFeatures = () => ({
  type: CLEAR_ORDERS_FEATURES,
});
export const filterByOrderStatus = data => ({
  type: FILTER_BY_ORDER_STATUS,
  payload: data,
});
