import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_REMOVE_FILES,
  ADD_SHARE_FILE,
  GET_CS_USER,
  GET_SHARED_FILES,
  REMOVE_SHARE_FILE,
} from "./actionTypes";

import { axiosApiAdmin, axiosCloudStorage } from "helpers/api_helper";
import { get, set } from "helpers/cookie_helper";
import Log from "helpers/logger";
import {
  GET_FILES_URL,
  GET_THIRDPARTY_CONFIG_DETAILS,
  REGISTER_CLOUD_LOG,
} from "helpers/url_helper";
import { setSuccessMessage } from "store/actions";
import { getCsUserSuccess, getSharedFilesSuccess } from "./actions";

const addRemoveFileCall = data =>
  axiosCloudStorage.post(GET_FILES_URL, data, {
    headers: { Authorization: `Basic ${get("cloudtoken")}` },
  });
const shareFilesCall = data =>
  axiosCloudStorage.post(GET_FILES_URL, data, {
    headers: { Authorization: `Basic ${get("cloudtoken")}` },
  });
const removeShareFilesCall = data =>
  axiosCloudStorage.post(GET_FILES_URL, data, {
    headers: { Authorization: `Basic ${get("cloudtoken")}` },
  });
const getShareFilesCall = () =>
  axiosCloudStorage.post(
    GET_FILES_URL,
    { getShares: true },
    { headers: { Authorization: `Basic ${get("cloudtoken")}` } }
  );

const getUser = () =>
  axiosApiAdmin.get(`${GET_THIRDPARTY_CONFIG_DETAILS}/${3}`);

const RegisterLog = log => axiosApiAdmin.post(REGISTER_CLOUD_LOG, log);

// activity_type :
// 8: "Create Files or Folders",
// 9: "Share Files or Folders",
// 10: "UnShare Files or Folders",
// 11: "Delete Files or Folders"

function* handleAddRemoveFile({ payload: { data, cb } }) {
  try {
    const res = yield call(addRemoveFileCall, data);
    if (res.status) {
      let log = {
        request: {},
        response: {},
        activity_type: 1,
        status: 200,
      };

      if (data.addFile) {
        log.request = JSON.stringify({
          path: data.path,
          action: "Add File Or Folder",
        });
        log.activity_type = 8;
        log.response = JSON.stringify(res);
        Log.cloudStorage().addFolderFile(data.path);
      }

      if (data.removeFile) {
        log.request = JSON.stringify({
          path: data.path,
          action: "Delete File Or Folder",
        });
        log.activity_type = 11;
        log.response = JSON.stringify(res);
        Log.cloudStorage().deleteFolderFile(data.path);
      }

      call(RegisterLog(log));

      if (typeof cb === "function") cb();
    }
  } catch (err) {
    if (typeof cb === "function") cb();
  }
}

/**
 * Share File
 */
function* handleFileShare({ payload: { data, cb } }) {
  try {
    const res = yield call(shareFilesCall, data);
    if (res?.data.status === "complete") {
      let log = {
        request: JSON.stringify(data),
        response: JSON.stringify(res),
        activity_type: 9,
        status: 200,
      };

      call(RegisterLog(log));
      Log.cloudStorage().shareFolderFile(data.path);
      const files = yield call(getShareFilesCall);
      yield put(getSharedFilesSuccess(files.data.data));
      yield put(cb(res?.data.data.ocs.data.token));
      yield put(setSuccessMessage("Shareable Link Copied.!"));
    }
  } catch (err) {}
}

/**
 * Get User Credentials
 */
function* handleCSUser() {
  try {
    const res = yield call(getUser);
    if (res?.data?.data?.response) {
      let a = res.data.data.response.configuration_json;
      set("cloudtoken", btoa(`${a.user_id}:${a.password}`));
      yield put(getCsUserSuccess(a));
    }
  } catch (err) {}
}

function* handleGetFileShares() {
  try {
    const res = yield call(getShareFilesCall);
    if (res?.data.status === "complete") {
      yield put(getSharedFilesSuccess(res.data.data));
    }
  } catch (err) {}
}

/**
 * Remove Share File
 */
function* handleRemoveFileShares({ payload: { data, cb } }) {
  try {
    const res = yield call(removeShareFilesCall, data);
    if (res?.data.status === "complete") {
      Log.cloudStorage().unShareFolderFile(data.shareId);
      const files = yield call(getShareFilesCall);
      yield put(getSharedFilesSuccess(files.data.data));
      if (!!cb && typeof cb === "function") cb();
    }
  } catch (err) {
    if (!!cb && typeof cb === "function") cb();
  }
}

function* userSaga() {
  yield takeEvery(ADD_REMOVE_FILES, handleAddRemoveFile);
  yield takeEvery(GET_CS_USER, handleCSUser);
  yield takeEvery(ADD_SHARE_FILE, handleFileShare);
  yield takeEvery(GET_SHARED_FILES, handleGetFileShares);
  yield takeEvery(REMOVE_SHARE_FILE, handleRemoveFileShares);
}

export default userSaga;
