import { dynamicSiteId } from "constants/siteDetails";
import { compareTypes } from "modules/Analytics/common/constants";
import DateRange from "modules/Analytics/common/utils";
import moment from "moment";
import {
  ANALYTICS_PRODUCT_SET_TZ,
  CLEAR_PRODUCT_REPORT,
  CLEAR_SEARCH_QUERY_REPORT,
  CLEAR_VIEW_ORDER_DATA,
  FETCH_PRODUCTS_REPORTS,
  FETCH_PRODUCTS_REPORTS_ERROR,
  FETCH_PRODUCTS_REPORTS_SUCCESS,
  GET_ANALYTICS_PRODUCT_GRAPH,
  GET_ANALYTICS_PRODUCT_GRAPH_ERROR,
  GET_ANALYTICS_PRODUCT_GRAPH_SUCCESS,
  GET_ORDER_BY_DATE,
  GET_ORDER_BY_DATE_ERROR,
  GET_ORDER_BY_DATE_SUCCESS,
  GET_ORDER_PAGE,
  GET_ORDER_SIZE_PER_PAGE,
  GET_PRODUCT_SOLD_LIST,
  GET_PRODUCT_SOLD_LIST_ERROR,
  GET_PRODUCT_SOLD_LIST_SUCCESS,
  SET_ANALYTICS_PRODUCTS_ACTIVE_TAB,
  SET_ANALYTICS_PRODUCTS_COMPARE_TYPE,
  SET_ANALYTICS_PRODUCTS_RANGE_DATE,
  SET_ANALYTICS_PRODUCT_BY,
  SET_ANALYTICS_PRODUCT_CURRENT_SITE,
  SET_ANALYTICS_PRODUCT_PAGE,
  SET_ANALYTICS_PRODUCT_SIZE_PER_PAGE,
  SET_ANALYTICS_PRODUCT_SORT_FIELD,
  SET_ANALYTICS_PRODUCT_SORT_ORDER,
} from "./actionTypes";

const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();
const defaultCompareType = compareTypes.PERIOD;

const reports = {
  items_sold: {
    value: 0,
    previousPeriodValue: 0,
  },
  net_sales: {
    value: 0,
    previousPeriodValue: 0,
  },
  orders: {
    value: 0,
    previousPeriodValue: 0,
  },
};

const initialState = {
  loadingReports: false,
  reports: reports,
  chartData: [],
  activeTab: "items_sold",
  range: {
    startDate,
    endDate,
  },
  compareToPrevious: defaultCompareType,
  comparedRange: new DateRange(startDate, endDate).getPreviousDates(
    defaultCompareType
  ),
  table: {
    search: "",
    pageRecord: 25,
    pageNo: 1,
    totalRecord: 0,
    loading: false,
    products: [],
    sortOrder: "desc",
    sortField: "net_sale",
  },
  loadingGraph: false,
  by: "day",
  loadingOrderByDate: false,
  orderByDateData: [],
  orderPages: 1,
  orderSizePerPage: 25,
  orderTotalSize: 0,
  siteId: dynamicSiteId.Curriculum,
  tz: "-0400",
};

const analyticsProductsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ANALYTICS_PRODUCT_SET_TZ:
      return {
        ...state,
        tz: payload,
      };
    case SET_ANALYTICS_PRODUCT_CURRENT_SITE:
      return {
        ...state,
        siteId: payload,
      };
    case CLEAR_SEARCH_QUERY_REPORT:
      return {
        ...state,
        range: {
          ...state.range,
          startDate,
          endDate,
        },
      };
    case CLEAR_PRODUCT_REPORT:
      return {
        ...state,
        table: {
          ...state.table,
          pageNo: 1,
        },
      };
    // Report
    case SET_ANALYTICS_PRODUCTS_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };
    case SET_ANALYTICS_PRODUCTS_RANGE_DATE:
      const prevBy = state.by;
      const newBy = new DateRange(
        payload.startDate,
        payload.endDate
      ).getByOptions()[0].value;
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
        comparedRange: new DateRange(
          payload.startDate,
          payload.endDate
        ).getPreviousDates(state.compareToPrevious),
        ...(prevBy !== newBy ? { by: newBy } : {}),
      };
    case SET_ANALYTICS_PRODUCTS_COMPARE_TYPE:
      return {
        ...state,
        compareToPrevious: payload,
        comparedRange: new DateRange(
          state.range.startDate,
          state.range.endDate
        ).getPreviousDates(payload),
      };
    case FETCH_PRODUCTS_REPORTS:
      return {
        ...state,
        loadingReports: true,
      };
    case FETCH_PRODUCTS_REPORTS_SUCCESS:
      return {
        ...state,
        reports: payload,
        loadingReports: false,
      };
    case FETCH_PRODUCTS_REPORTS_ERROR:
      return {
        ...state,
        loadingReports: false,
      };

    // Graph
    case GET_ANALYTICS_PRODUCT_GRAPH:
      return {
        ...state,
        loadingGraph: true,
      };
    case GET_ANALYTICS_PRODUCT_GRAPH_SUCCESS:
      return {
        ...state,
        loadingGraph: false,
        chartData: payload.map(x => ({
          cp_date_time: x.current.start_date,
          cp: x.current.current_count,
          pp: x.previous.previous_count,
          pp_date_time: x.previous.start_date,
        })),
      };
    case GET_ANALYTICS_PRODUCT_GRAPH_ERROR:
      return {
        ...state,
        loadingGraph: false,
      };

    // Table
    case GET_PRODUCT_SOLD_LIST:
      return {
        ...state,
        table: {
          ...state.table,
          loading: true,
        },
      };
    case GET_PRODUCT_SOLD_LIST_SUCCESS:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          products: payload?.rows || [],
          totalRecord: payload?.count || 0,
        },
      };
    case GET_PRODUCT_SOLD_LIST_ERROR:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
        },
      };
    case SET_ANALYTICS_PRODUCT_PAGE:
      return {
        ...state,
        table: {
          ...state.table,
          pageNo: payload,
        },
      };
    case SET_ANALYTICS_PRODUCT_SIZE_PER_PAGE:
      return {
        ...state,
        table: {
          ...state.table,
          pageRecord: payload,
        },
      };
    case SET_ANALYTICS_PRODUCT_BY:
      return {
        ...state,
        by: payload,
      };
    case SET_ANALYTICS_PRODUCT_SORT_FIELD:
      return {
        ...state,
        table: {
          ...state.table,
          sortField: payload,
        },
      };
    case SET_ANALYTICS_PRODUCT_SORT_ORDER:
      return {
        ...state,
        table: {
          ...state.table,
          sortOrder: payload,
        },
      };

    case GET_ORDER_BY_DATE:
      return {
        ...state,
        loadingOrderByDate: true,
      };

    case GET_ORDER_BY_DATE_SUCCESS:
      return {
        ...state,
        loadingOrderByDate: false,
        orderByDateData: payload.order_list,
        orderTotalSize: payload.count,
      };

    case GET_ORDER_BY_DATE_ERROR:
      return {
        ...state,
        loadingOrderByDate: false,
      };

    case GET_ORDER_PAGE:
      return {
        ...state,
        orderPages: payload,
      };
    case GET_ORDER_SIZE_PER_PAGE:
      return {
        ...state,
        orderSizePerPage: payload,
      };

    case CLEAR_VIEW_ORDER_DATA:
      return {
        ...state,
        orderByDateData: [],
        orderPages: 1,
        orderSizePerPage: 25,
      };

    default:
      return state;
  }
};

export default analyticsProductsReducer;
