import {
  ADD_UPDATE_REDIRECTION,
  ADD_UPDATE_REDIRECTION_ERROR,
  ADD_UPDATE_REDIRECTION_FOLDER,
  ADD_UPDATE_REDIRECTION_FOLDER_ERROR,
  ADD_UPDATE_REDIRECTION_FOLDER_SUCCESS,
  ADD_UPDATE_REDIRECTION_SUCCESS,
  CLEAR_REDIRECTION_FEATURES,
  CREATE_REDIRECT_FOLDER_LIST,
  CREATE_REDIRECT_FOLDER_LIST_ERROR,
  CREATE_REDIRECT_FOLDER_LIST_SUCCESS,
  DELETE_REDIRECTION_LINK,
  DELETE_REDIRECT_FOLDER_LIST,
  DELETE_REDIRECT_FOLDER_LIST_ERROR,
  DELETE_REDIRECT_FOLDER_LIST_SUCCESS,
  FETCH_REDIRECTION_LIST,
  FETCH_REDIRECTION_LIST_ERROR,
  FETCH_REDIRECTION_LIST_SUCCESS,
  FETCH_REDIRECT_FOLDER_LIST,
  FETCH_REDIRECT_FOLDER_LIST_ERROR,
  FETCH_REDIRECT_FOLDER_LIST_SUCCESS,
  SET_REDIRECTION_FILTER,
  SET_REDIRECTION_PAGE,
  SET_REDIRECTION_SEARCH,
  SET_REDIRECTION_SIZE_PER_PAGE,
  SET_STATUS_REDIRECTION_LINK,
  SET_STATUS_REDIRECTION_LINK_SUCCESS,
} from "./actionTypes";
export const fetchRedirectionLink = data => ({
  type: FETCH_REDIRECTION_LIST,
  payload: data,
});
export const fetchRedirectionLinkSuccess = data => ({
  type: FETCH_REDIRECTION_LIST_SUCCESS,
  payload: data,
});
export const fetchRedirectionLinkError = data => ({
  type: FETCH_REDIRECTION_LIST_ERROR,
  payload: data,
});

export const setRedirectionPage = data => ({
  type: SET_REDIRECTION_PAGE,
  payload: data,
});

export const clearRedirectionFeatures = data => ({
  type: CLEAR_REDIRECTION_FEATURES,
  payload: data,
});
export const setRedirectionSizePerPage = data => ({
  type: SET_REDIRECTION_SIZE_PER_PAGE,
  payload: data,
});
export const setRedirectionSearch = data => ({
  type: SET_REDIRECTION_SEARCH,
  payload: data,
});
export const setFilterRedirection = data => ({
  type: SET_REDIRECTION_FILTER,
  payload: data,
});

export const addUpdateRedirection = data => ({
  type: ADD_UPDATE_REDIRECTION,
  payload: data,
});

export const addUpdateRedirectionError = () => ({
  type: ADD_UPDATE_REDIRECTION_ERROR,
});
export const addUpdateRedirectionSuccess = () => ({
  type: ADD_UPDATE_REDIRECTION_SUCCESS,
});

export const setStatusRedirectionLink = data => ({
  type: SET_STATUS_REDIRECTION_LINK,
  payload: data,
});

export const setStatusRedirectionLinkSuccess = data => ({
  type: SET_STATUS_REDIRECTION_LINK_SUCCESS,
  payload: data,
});

export const deleteRedirectionLink = data => ({
  type: DELETE_REDIRECTION_LINK,
  payload: data,
});

/*** redirection apis */
export const fetchRedirectFolderList = () => ({
  type: FETCH_REDIRECT_FOLDER_LIST,
});
export const fetchRedirectFolderListSuccess = data => ({
  type: FETCH_REDIRECT_FOLDER_LIST_SUCCESS,
  payload: data,
});
export const fetchRedirectFolderListError = data => ({
  type: FETCH_REDIRECT_FOLDER_LIST_ERROR,
  payload: data,
});

export const createRedirectionFolder = data => ({
  type: CREATE_REDIRECT_FOLDER_LIST,
  payload: data,
});
export const createRedirectionFolderSuccess = data => ({
  type: CREATE_REDIRECT_FOLDER_LIST_SUCCESS,
  payload: data,
});
export const createRedirectionFolderError = data => ({
  type: CREATE_REDIRECT_FOLDER_LIST_ERROR,
  payload: data,
});

export const deleteRedirectionFolder = data => ({
  type: DELETE_REDIRECT_FOLDER_LIST,
  payload: data,
});
export const deleteRedirectionFolderSuccess = data => ({
  type: DELETE_REDIRECT_FOLDER_LIST_SUCCESS,
  payload: data,
});
export const deleteRedirectionFolderError = data => ({
  type: DELETE_REDIRECT_FOLDER_LIST_ERROR,
  payload: data,
});

export const updateRedirectionFolder = data => ({
  type: ADD_UPDATE_REDIRECTION_FOLDER,
  payload: data,
});

export const updateRedirectionFolderError = () => ({
  type: ADD_UPDATE_REDIRECTION_FOLDER_ERROR,
});
export const updateRedirectionFolderSuccess = () => ({
  type: ADD_UPDATE_REDIRECTION_FOLDER_SUCCESS,
});
