import { axiosApiAdmin } from "helpers/api_helper";
import { DELETE_NOTES, LIST_NOTE, SAVE_NOTE } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  addNoteError,
  deleteNoteError,
  fetchCampaignError,
  fetchCampaignSuccess,
  fetchNote,
  fetchNoteError,
  fetchNoteSuccess,
  setErrorMessage,
  setSuccessMessage,
} from "store/actions";
import {
  ADD_NOTE,
  DELETE_NOTE,
  FETCH_CAMPAINGN,
  FETCH_NOTE,
} from "./actionTypes";

const saveNote = data => axiosApiAdmin.post(SAVE_NOTE, data);
const getNotes = data => axiosApiAdmin.post(LIST_NOTE, data);
const deleteNote = data => axiosApiAdmin.get(`${DELETE_NOTES}/${data}`);

function* handleSaveNote({ payload: { data } }) {
  try {
    const res = yield call(saveNote, data);
    if (res.data?.data) {
      yield put(setSuccessMessage("Note added successfully."));
      if (data.type === 2) {
        yield put(
          fetchNote({
            data: {
              event_type_id: data.event_type_id,
              type: 2,
              user_id: 0,
            },
          })
        );
      } else {
        yield put(
          fetchNote({
            data: {
              event_type_id: data.is_customer,
              type: 0,
              user_id: data.event_type_id,
            },
          })
        );
      }
    }
  } catch (err) {
    yield put(addNoteError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchNotes({ payload: { data } }) {
  try {
    const res = yield call(getNotes, data);
    if (res?.data?.data) {
      yield put(fetchNoteSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchNoteError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* fetchCampaigns({ payload: { data } }) {
  try {
    const res = yield call(getNotes, data);
    if (res?.data?.data) {
      yield put(fetchCampaignSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchCampaignError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteNote({
  payload: { note_id, event_type_id, type, user_id },
}) {
  try {
    const res = yield call(deleteNote, note_id);
    if (res?.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchNote({ data: { event_type_id, type, user_id } }));
    }
  } catch (err) {
    yield put(deleteNoteError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* orderNoteSaga() {
  yield takeEvery(ADD_NOTE, handleSaveNote);
  yield takeEvery(FETCH_NOTE, handleFetchNotes);
  yield takeEvery(DELETE_NOTE, handleDeleteNote);
  yield takeEvery(FETCH_CAMPAINGN, fetchCampaigns);
}
export default orderNoteSaga;
