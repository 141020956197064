// Va 05 - 01 - 21

import {
  CLEAR_EXPIRED_SUBSCRIPTION,
  FATCH_LIST_EXPIRED_SUBSCRIPTION,
  FATCH_LIST_EXPIRED_SUBSCRIPTION_ERROR,
  FATCH_LIST_EXPIRED_SUBSCRIPTION_EXPORT,
  FATCH_LIST_EXPIRED_SUBSCRIPTION_EXPORT_NULL,
  FATCH_LIST_EXPIRED_SUBSCRIPTION_EXPORT_SUCCESS,
  FATCH_LIST_EXPIRED_SUBSCRIPTION_SUCCESS,
  SET_ORDER_RANGE_DATE_EXPIRED_SUBSCRIPTION,
  SET_PAGE_EXPIRED_SUBSCRIPTION,
  SET_SEARCH_EXPIRED_SUBSCRIPTION,
  SET_SIZE_PER_PAGE_EXPIRED_SUBSCRIPTION,
  SET_SORT_FIELD_EXPIRED_SUBSCRIPTION,
  SET_SORT_ORDER_EXPIRED_SUBSCRIPTION,
} from "./actionTypes";

export const fetchlistExpiredSubscription = (data, cb) => ({
  type: FATCH_LIST_EXPIRED_SUBSCRIPTION,
  payload: data,
  cb: cb,
});

export const fetchlistExpiredSubscriptionForExport = (data, cb) => ({
  type: FATCH_LIST_EXPIRED_SUBSCRIPTION_EXPORT,
  payload: data,
  cb: cb,
});

export const fetchlistExpiredSubscriptionForExportSuccess = data => ({
  type: FATCH_LIST_EXPIRED_SUBSCRIPTION_EXPORT_SUCCESS,
  payload: data,
});
export const fetchlistExpiredSubscriptionForExportNull = data => ({
  type: FATCH_LIST_EXPIRED_SUBSCRIPTION_EXPORT_NULL,
  payload: data,
});
export const fetchlistExpiredSubscriptionSuccess = data => ({
  type: FATCH_LIST_EXPIRED_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const fetchlistExpiredSubscriptionError = () => ({
  type: FATCH_LIST_EXPIRED_SUBSCRIPTION_ERROR,
});
export const clearExpiredSubscription = () => ({
  type: CLEAR_EXPIRED_SUBSCRIPTION,
});

export const setPageExpiredSubscription = data => {
  return {
    type: SET_PAGE_EXPIRED_SUBSCRIPTION,
    payload: data,
  };
};

export const setSizePerPageExpiredSubscription = data => {
  return {
    type: SET_SIZE_PER_PAGE_EXPIRED_SUBSCRIPTION,
    payload: data,
  };
};

export const setSearchExpiredSubscription = data => {
  return {
    type: SET_SEARCH_EXPIRED_SUBSCRIPTION,
    payload: data,
  };
};

export const setSortFieldExpiredSubscription = data => ({
  type: SET_SORT_FIELD_EXPIRED_SUBSCRIPTION,
  payload: data,
});

export const setSortOrderExpiredSubscription = data => ({
  type: SET_SORT_ORDER_EXPIRED_SUBSCRIPTION,
  payload: data,
});

export const setOrderRangeDateExpiredSubscription = data => ({
  type: SET_ORDER_RANGE_DATE_EXPIRED_SUBSCRIPTION,
  payload: data,
});
