export const FETCH_APPLICATION_SUBSCRIPTIONS =
  "FETCH_APPLICATION_SUBSCRIPTIONS";
export const FETCH_APPLICATION_SUBSCRIPTIONS_SUCCESS =
  "FETCH_APPLICATION_SUBSCRIPTIONS_SUCCESS";
export const FETCH_APPLICATION_SUBSCRIPTIONS_ERROR =
  "FETCH_APPLICATION_SUBSCRIPTIONS_ERROR";

// Features
export const SET_APPLICATION_SUBSCRIPTIONS_PAGE =
  "SET_APPLICATION_SUBSCRIPTIONS_PAGE";
export const SET_APPLICATION_SUBSCRIPTIONS_SIZE_PER_PAGE =
  "SET_APPLICATION_SUBSCRIPTIONS_SIZE_PER_PAGE";
export const SET_APPLICATION_SUBSCRIPTIONS_ACTIVE_STATUS =
  "SET_APPLICATION_SUBSCRIPTIONS_ACTIVE_STATUS";
export const SET_APPLICATION_SUBSCRIPTIONS_SEARCH =
  "SET_APPLICATION_SUBSCRIPTIONS_SEARCH";
export const SET_APPLICATION_SUBSCRIPTIONS_SORT_FIELD =
  "SET_APPLICATION_SUBSCRIPTIONS_SORT_FIELD";
export const SET_APPLICATION_SUBSCRIPTIONS_SORT_ORDER =
  "SET_APPLICATION_SUBSCRIPTIONS_SORT_ORDER";

// Total Subscriptions
export const FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS =
  "FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS";
export const FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS_SUCCESS =
  "FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS_SUCCESS";
export const FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS_ERROR =
  "FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS_ERROR";

export const CLEAR_SUBSCRIPTION_FEATURES = "CLEAR_SUBSCRIPTION_FEATURES";
