// ADD/EDIT PAGES
export const ADD_EDIT_VOLUME_PAGE = "ADD_EDIT_VOLUME_PAGE";
export const ADD_EDIT_VOLUME_PAGE_SUCCESS = "ADD_EDIT_VOLUME_PAGE_SUCCESS";
export const ADD_EDIT_VOLUME_PAGE_ERROR = "ADD_EDIT_VOLUME_PAGE_ERROR";

// GET PAGE DETAIL
export const GET_VOLUME_PAGE_DETAILS = "GET_VOLUME_PAGE_DETAILS";
export const GET_VOLUME_PAGE_DETAILS_SUCCESS =
  "GET_VOLUME_PAGE_DETAILS_SUCCESS";
export const GET_VOLUME_PAGE_DETAILS_ERROR = "GET_VOLUME_PAGE_DETAILS_ERROR";

// GET PAGES
export const FETCH_VOLUME_PAGES = "FETCH_VOLUME_PAGES";
export const FETCH_VOLUME_PAGES_SUCCESS = "FETCH_VOLUME_PAGES_SUCCESS";
export const FETCH_VOLUME_PAGES_ERROR = "FETCH_VOLUME_PAGES_ERROR";

// PUBLISH PAGES
export const PUBLISH_VOLUME_PAGES = "PUBLISH_VOLUME_PAGES";
export const PUBLISH_VOLUME_PAGES_SUCCESS = "PUBLISH_VOLUME_PAGES_SUCCESS";
export const PUBLISH_VOLUME_PAGES_ERROR = "PUBLISH_VOLUME_PAGES_ERROR";

// GET PAGE FOLDER
export const FETCH_VOLUME_PAGE_FOLDERS = "FETCH_VOLUME_PAGE_FOLDERS";
export const FETCH_VOLUME_PAGE_FOLDER_SUCCESS =
  "FETCH_VOLUME_PAGE_FOLDER_SUCCESS";
export const FETCH_VOLUME_PAGE_FOLDER_ERROR = "FETCH_VOLUME_PAGE_FOLDER_ERROR";

// DELETE PAGE FOLDER
export const DELETE_VOLUME_PAGE_FOLDER = "DELETE_VOLUME_PAGE_FOLDER";
export const DELETE_VOLUME_PAGE_FOLDER_SUCCESS =
  "DELETE_VOLUME_PAGE_FOLDER_SUCCESS";
export const DELETE_VOLUME_PAGE_FOLDER_ERROR =
  "DELETE_VOLUME_PAGE_FOLDER_ERROR";

export const ADD_EDIT_VOLUME_PAGE_FOLDER = "ADD_EDIT_VOLUME_PAGE_FOLDER";
export const ADD_EDIT_VOLUME_PAGE_FOLDER_SUCCESS =
  "ADD_EDIT_VOLUME_PAGE_FOLDER_SUCCESS";
export const ADD_EDIT_VOLUME_PAGE_FOLDER_ERROR =
  "ADD_EDIT_VOLUME_PAGE_FOLDER_ERROR";

export const GET_VOLUME_PAGE_FOLDER_DETAILS = "GET_VOLUME_PAGE_FOLDER_DETAILS";
export const GET_VOLUME_PAGE_FOLDER_DETAILS_SUCCESS =
  "GET_VOLUME_PAGE_FOLDER_DETAILS_SUCCESS";

// Table
export const SET_VOLUME_PAGE = "SET_VOLUME_PAGE";
export const SET_SIZE_PER_VOLUME_PAGE = "SET_SIZE_PER_VOLUME_PAGE";
export const SET_SEARCH = "SET_SEARCH";
export const SET_SORT_FIELD = "SET_SORT_FIELD";
export const SET_SORT_ORDER = "SET_SORT_ORDER";

// DELETE COUPON
export const DELETE_VOLUME_PAGES = "DELETE_VOLUME_PAGES";
export const DELETE_VOLUME_PAGES_SUCCESS = "DELETE_VOLUME_PAGES_SUCCESS";
export const DELETE_VOLUME_PAGES_ERROR = "DELETE_VOLUME_PAGES_ERROR";

// GET LIST ALL CONTENT TYPE ID AND TITLE
export const LIST_ALL_CONTENT_TYPE_ID_AND_TITLE =
  "LIST_ALL_CONTENT_TYPE_ID_AND_TITLE";
export const LIST_ALL_CONTENT_TYPE_ID_AND_TITLE_SUCCESS =
  "LIST_ALL_CONTENT_TYPE_ID_AND_TITLE_SUCCESS";

// GET Curriculum Category
export const FETCH_CURRICULUM_VOLUME_PAGE_CATEGORY =
  "FETCH_CURRICULUM_VOLUME_PAGE_CATEGORY";
export const FETCH_CURRICULUM_CATEGORY_VOLUME_PAGE_SUCCESS =
  "FETCH_CURRICULUM_CATEGORY_VOLUME_PAGE_SUCCESS";

export const LIST_ALL_OPTIONS = "LIST_ALL_OPTIONS";
export const LIST_ALL_OPTIONS_SUCCESS = "LIST_ALL_OPTIONS_SUCCESS";
export const LIST_ALL_OPTIONS_ERROR = "LIST_ALL_OPTIONS_ERROR";
