import { axiosApiAdmin } from "helpers/api_helper";
import { DELETE_GAME_REVIEW_URL, LIST_GAME_RATING } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { fetchGameRatingSuccess, setErrorMessage } from "store/actions";
import { DELETE_REVIEW, FETCH_GAME_RATING } from "./actionType";

const fetchGameRatingCall = payload =>
  axiosApiAdmin.post(LIST_GAME_RATING, payload);
const deleteGameRatingCall = payload =>
  axiosApiAdmin.post(DELETE_GAME_REVIEW_URL, payload);

function* handleFetchGameRating({ payload }) {
  try {
    const res = yield call(fetchGameRatingCall, payload);
    if (res.data.statusCode && res?.data?.data) {
      yield put(fetchGameRatingSuccess(res.data.data));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleDeleteReview({ payload: { payload, callBack } }) {
  try {
    const res = yield call(deleteGameRatingCall, payload);
    if (res.data.statusCode) {
      callBack();
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* ReviewSaga() {
  yield takeEvery(FETCH_GAME_RATING, handleFetchGameRating);
  yield takeEvery(DELETE_REVIEW, handleDeleteReview);
}
export default ReviewSaga;
