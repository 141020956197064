import {
  FETCH_CANCEL_SUBSCRIPTION,
  FETCH_CANCEL_SUBSCRIPTION_ERROR,
  FETCH_CANCEL_SUBSCRIPTION_LOADING,
} from "./actionTypes";

const initialState = {
  CancelSubscriptionLoading: false,
  CancelSubscriptionData: [],
};

const CancelSubscriptionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CANCEL_SUBSCRIPTION_LOADING:
      return {
        ...state,
        CancelSubscriptionLoading: true,
      };
    case FETCH_CANCEL_SUBSCRIPTION:
      return {
        ...state,
        CancelSubscriptionData: payload,
        CancelSubscriptionLoading: false,
      };

    case FETCH_CANCEL_SUBSCRIPTION_ERROR:
      return {
        ...state,
        CancelSubscriptionLoading: false,
      };

    default:
      return state;
  }
};

export default CancelSubscriptionReducer;
