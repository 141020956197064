import {
  FETCH_SUPPORT_TICKET,
  FETCH_SUPPORT_TICKET_ERROR,
  FETCH_SUPPORT_TICKET_NOTE,
  FETCH_SUPPORT_TICKET_NOTE_ERROR,
  FETCH_SUPPORT_TICKET_NOTE_SUCCESS,
  FETCH_SUPPORT_TICKET_SUCCESS,
} from "./actionTypes";

const initialState = {
  display_name: "",
  profile_image: "",
  assignStaff: [],
  status: "",
  date_created: "",
  date_updated: "",
  ticketType: "",
  id: "",
  subject: "",
  description: "",
  loadingTickets: false,
  AI_Summary: "",
  notes: [],
  isNoteLoading: false,
  links: []
};

const supportTicketReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_SUPPORT_TICKET:
      return {
        ...state,
        loadingTickets: true,
      };
    case FETCH_SUPPORT_TICKET_SUCCESS:
      return {
        ...state,
        users: payload.rows[0].users,
        users_info: payload.rows[0].users_info,
        addedByUsers: payload.rows[0].addedByUsers,
        assignedTo: payload.rows[0].staff,
        statusOption: payload.rows[0].status,
        date_created: payload.rows[0].date_created,
        date_updated: payload.rows[0].date_updated,
        ticketType: payload.rows[0].type,
        id: payload.rows[0].id,
        subject: payload.rows[0].subject,
        description: payload.rows[0].description,
        loadingTickets: false,
        application: payload.rows[0].application,
        links: payload.rows[0].ba_support_tickets_links,
        AI_Summary: payload.rows[0].ai_summary,
        chatMessages: payload.chatMessages,
      };
    case FETCH_SUPPORT_TICKET_ERROR:
      return {
        ...state,
        loadingTickets: false,
      };
    case FETCH_SUPPORT_TICKET_NOTE:
      return {
        ...state,
        isNoteLoading: true,
      };
    case FETCH_SUPPORT_TICKET_NOTE_SUCCESS:
      return {
        ...state,
        notes: payload,
        isNoteLoading: false,
      };
    case FETCH_SUPPORT_TICKET_NOTE_ERROR:
      return {
        ...state,
        isNoteLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default supportTicketReducer;
