import {
  API_LIST_LOGS,
  API_LIST_LOGS_ERROR,
  API_LIST_LOGS_SUCCESS,
  API_LOG_PAGES,
  API_LOG_PER_PAGES,
  CLEAR_API_LOG_FEATURES,
  GET_ERROR_FILE_LIST,
  GET_ERROR_FILE_LIST_ERROR,
  GET_ERROR_FILE_LIST_SUCCESS,
  GET_ERROR_LOG_FILES,
  GET_ERROR_LOG_FILES_ERROR,
  GET_ERROR_LOG_FILES_SUCCESS,
  GET_SITES_LISTS,
  GET_SITES_LISTS_ERROR,
  GET_SITES_LISTS_SUCCESS,
  SET_ERROR_LOG_DATA,
  SITES_LISTS_ACTIVE_INACTIVE,
  SITES_LISTS_ACTIVE_INACTIVE_SUCCESS,
} from "./actionsTypes";

const initialStates = {
  loadingApiListLogs: false,
  ApiLogs: [],
  logPage: 1,
  logSizePerPage: 25,
  logTotalSize: 0,
  loadingSitesLists: false,
  sitesLists: [],
  loadingActiveInactive: false,
  loadingErrorFileList: false,
  ErrorFileList: [],
  loadingReadErrorFileList: false,
  ReadErrorLogFile: [],
};

const DebuggerToolReducer = (state = initialStates, action) => {
  const { type, payload } = action;
  switch (type) {
    case API_LIST_LOGS:
      return {
        ...state,
        loadingApiListLogs: true,
      };
    case API_LIST_LOGS_SUCCESS:
      return {
        ...state,
        ApiLogs: payload.rows,
        logTotalSize: payload.count,
        loadingApiListLogs: false,
      };
    case API_LIST_LOGS_ERROR:
      return {
        ...state,
        loadingApiListLogs: false,
      };
    case API_LOG_PAGES:
      return {
        ...state,
        logPage: payload,
      };
    case API_LOG_PER_PAGES:
      return {
        ...state,
        logSizePerPage: payload,
      };
    case CLEAR_API_LOG_FEATURES:
      return {
        ...state,
        logPage: 1,
        logSizePerPage: 25,
        logTotalSize: 0,
      };
    case GET_SITES_LISTS:
      return {
        ...state,
        loadingSitesLists: true,
      };
    case GET_SITES_LISTS_SUCCESS:
      return {
        ...state,
        sitesLists: payload,
        loadingSitesLists: false,
      };
    case GET_SITES_LISTS_ERROR:
      return {
        ...state,
        loadingSitesLists: false,
      };

    case SITES_LISTS_ACTIVE_INACTIVE:
      return {
        ...state,
        loadingActiveInactive: true,
      };

    case SITES_LISTS_ACTIVE_INACTIVE_SUCCESS:
      return {
        ...state,
        sitesLists: state.sitesLists.map(site => {
          if (
            payload.site_id.includes(site.site_id) ||
            payload.site_id.includes(0)
          ) {
            site.is_debugger = payload.is_debugger;
          }
          return site;
        }),
      };

    case GET_ERROR_FILE_LIST:
      return {
        ...state,
        loadingErrorFileList: true,
      };

    case GET_ERROR_FILE_LIST_SUCCESS:
      return {
        ...state,
        ErrorFileList: payload,
        loadingErrorFileList: false,
      };

    case GET_ERROR_FILE_LIST_ERROR:
      return {
        ...state,
        loadingErrorFileList: false,
      };

    case GET_ERROR_LOG_FILES:
      return {
        ...state,
        loadingReadErrorFileList: true,
      };

    case GET_ERROR_LOG_FILES_SUCCESS:
      return {
        ...state,
        ReadErrorLogFile: payload,
        loadingReadErrorFileList: false,
      };

    case GET_ERROR_LOG_FILES_ERROR:
      return {
        ...state,
        loadingReadErrorFileList: false,
      };

    case SET_ERROR_LOG_DATA:
      return {
        ...state,
        ErrorFileList: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default DebuggerToolReducer;
