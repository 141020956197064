import {
  CLEAR_EMAIL_FILTER,
  CLEAR_EMAIL_REPORT,
  FETCH_EMAIL_LOG_DATA,
  FETCH_EMAIL_LOG_DATA_ERROR,
  FETCH_EMAIL_LOG_DATA_SUCCESS,
  SET_EMAIL_LOG_RANGE_DATE,
  SET_EMAIL_LOG_RANGE_DATE_FILTER,
  SET_EMAIL_LOG_RANGE_DATE_PAGE,
  SET_EMAIL_LOG_RANGE_DATE_SIZE_PER_PAGE,
  SET_EMAIL_LOG_SEARCH,
  SET_EMAIL_LOG_SORT_FIELD,
  SET_EMAIL_LOG_SORT_ORDER,
} from "./actionTypes";

import moment from "moment";

const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();

const initialState = {
  range: {
    startDate,
    endDate,
  },
  filter: {
    title: "",
    status: "",
  },
  loading: false,
  emails: {
    rows: [],
    search: "",
    pageNo: 1,
    pageRecord: 25,
    count: 0,
    sortField: "created_datetime",
    sortOrder: "DESC",
  },
};

const EmailReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_EMAIL_LOG_RANGE_DATE_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...payload,
        },
      };
    case SET_EMAIL_LOG_RANGE_DATE_PAGE:
      return {
        ...state,
        emails: {
          ...state.emails,
          pageNo: payload,
        },
      };
    case SET_EMAIL_LOG_RANGE_DATE_SIZE_PER_PAGE:
      return {
        ...state,
        emails: {
          ...state.emails,
          pageRecord: payload,
        },
      };
    case SET_EMAIL_LOG_SEARCH:
      return {
        ...state,
        emails: {
          ...state.emails,
          search: payload,
          pageNo: 1,
        },
      };
    case SET_EMAIL_LOG_RANGE_DATE:
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
      };
    case SET_EMAIL_LOG_SORT_FIELD:
      return {
        ...state,
        emails: {
          ...state.emails,
          sortField: payload,
        },
      };
    case SET_EMAIL_LOG_SORT_ORDER:
      return {
        ...state,
        emails: {
          ...state.emails,
          sortOrder: payload,
        },
      };
    case CLEAR_EMAIL_REPORT:
      return {
        ...state,
        emails: {
          ...state.emails,
          pageNo: 1,
        },
      };
    case CLEAR_EMAIL_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          title: "",
          status: "",
        },
      };
    // table

    case FETCH_EMAIL_LOG_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EMAIL_LOG_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        emails: {
          ...state.emails,
          rows: payload.rows,
          count: payload.count ? payload?.count : 0,
        },
      };
    case FETCH_EMAIL_LOG_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };

    // table
    default:
      return state;
  }
};

export default EmailReducer;
