import { axiosApiAdmin } from "helpers/api_helper";
import {
  LIST_SIGNUP_METRIC_GRAPH_REPORT,
  LIST_SIGNUP_METRIC_REPORT,
} from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  getMatrixDataError,
  getMatrixDataSuccess,
  getMatrixGraphDataError,
  getMatrixGraphDataSuccess,
} from "./actions";
import {
  LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA,
  LIST_SIGNUP_METRIC_REPORT_LOADING,
} from "./actionTypes";

const fetchVolumeListFolders = data =>
  axiosApiAdmin.post(LIST_SIGNUP_METRIC_REPORT, data);

const fetchVolumeListGraph = data =>
  axiosApiAdmin.post(LIST_SIGNUP_METRIC_GRAPH_REPORT, data);

function* handelGetVolumeFolderDetails({ payload }) {
  try {
    const res = yield call(fetchVolumeListFolders, payload);
    if (res.data?.data) {
      yield put(getMatrixDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getMatrixDataError(err.response?.data?.message || err.message));
  }
}

function* handelGetVolumeGraphDetails({ payload }) {
  try {
    const res = yield call(fetchVolumeListGraph, payload);
    if (res.data?.data) {
      yield put(getMatrixGraphDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(
      getMatrixGraphDataError(err.response?.data?.message || err.message)
    );
  }
}

function* metricReport() {
  yield takeEvery(
    LIST_SIGNUP_METRIC_REPORT_LOADING,
    handelGetVolumeFolderDetails
  );
  yield takeEvery(
    LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA,
    handelGetVolumeGraphDetails
  );
}

export default metricReport;
