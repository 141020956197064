export const FETCH_NOT_ACTIVE_SUBSCRIPTION = "FETCH_NOT_ACTIVE_SUBSCRIPTION";
export const FETCH_NOT_ACTIVE_SUBSCRIPTION_SUCCESS =
  "FETCH_NOT_ACTIVE_SUBSCRIPTION_SUCCESS";
export const FETCH_NOT_ACTIVE_SUBSCRIPTION_ERROR =
  "FETCH_NOT_ACTIVE_SUBSCRIPTION_ERROR";

export const CLEAR_NOT_ACTIVE_SUBSCRIPTION = "CLEAR_NOT_ACTIVE_SUBSCRIPTION";

export const SET_PAGE_NOT_ACTIVE_SUBSCRIPTION =
  "SET_PAGE_NOT_ACTIVE_SUBSCRIPTION";

export const SET_SIZE_PER_PAGE_NOT_ACTIVE_SUBSCRIPTION =
  "SET_SIZE_PER_PAGE_NOT_ACTIVE_SUBSCRIPTION";

export const SET_SEARCH_NOT_ACTIVE_SUBSCRIPTION =
  "SET_SEARCH_NOT_ACTIVE_SUBSCRIPTION";

export const SET_SORT_FIELD_NOT_ACTIVE_SUBSCRIPTION =
  "SET_SORT_FIELD_NOT_ACTIVE_SUBSCRIPTION";

export const SET_SORT_ORDER_NOT_ACTIVE_SUBSCRIPTION =
  "SET_SORT_ORDER_NOT_ACTIVE_SUBSCRIPTION";

export const SET_ORDER_RANGE_DATE_NOT_ACTIVE_SUBSCRIPTION =
  "SET_ORDER_RANGE_DATE_NOT_ACTIVE_SUBSCRIPTION";

export const FILTER_BY_APPLICATIONS = "FILTER_BY_APPLICATIONS";
//export const SET_PAGE_SUPPORT_TICKETS = "SET_PAGE_SUPPORT_TICKETS";
