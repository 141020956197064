// Va 05 - 01 - 21
import { axiosApiAdmin } from "helpers/api_helper";
import { LIST_SUBSCRIPTION_REPORT } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchlistSubscriptionReportError,
  fetchlistSubscriptionReportForExportSuccess,
  fetchlistSubscriptionReportSuccess,
  setErrorMessage,
} from "store/actions";
import {
  FATCH_LIST_SUBSCRIPTION_REPORT,
  FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT,
} from "./actionTypes";

const fetchSubscriptionReport = data =>
  axiosApiAdmin.post(LIST_SUBSCRIPTION_REPORT, data);

function* handleFetchSubscriptionReport({ payload, cb }) {
  try {
    const res = yield call(fetchSubscriptionReport, payload);
    if (res) {
      cb(res);
      yield put(fetchlistSubscriptionReportSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchlistSubscriptionReportError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchSubscriptionReportExport({ payload, cb }) {
  try {
    const res = yield call(fetchSubscriptionReport, payload);
    if (res) {
      cb(res.data.data.rows);
      yield put(
        fetchlistSubscriptionReportForExportSuccess(res.data.data.rows)
      );
    }
  } catch (err) {
    yield put(fetchlistSubscriptionReportError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

export function* subscriptionReportSaga() {
  yield takeEvery(
    FATCH_LIST_SUBSCRIPTION_REPORT,
    handleFetchSubscriptionReport
  );
}

export function* subscriptionReportExportSaga() {
  yield takeEvery(
    FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT,
    handleFetchSubscriptionReportExport
  );
}
