import {
  ADD_EDIT_PAGE_HANDLE,
  GROW_ADD_BUTTON_BOOK_CATEGORY,
  GROW_ADD_EDIT_AUTHOR_LOADER,
  GROW_ADD_EDIT_BOOK_CATEGORY_LOADER,
  GROW_AUTHOR,
  GROW_AUTHOR_LISTING,
  GROW_AUTHOR_LIST_SAVE,
  GROW_AUTHOR_NAME_LIST,
  GROW_AUTHOR_NAME_LIST_SUCCESS,
  GROW_AUTHOR_TAB_CHANGE,
  GROW_BOOK_CATEGORY,
  GROW_BOOK_CATEGORY_CREATE,
  GROW_BOOK_CATEGORY_LIST,
  GROW_BOOK_CATEGORY_LISTING,
  GROW_BOOK_CATEGORY_LIST_SUCCESS,
  GROW_DELETE_AUTHOR,
  GROW_DELETE_BOOKS,
  GROW_DELETE_BOOK_CATEGORY,
  GROW_EDIT_AUTHOR,
  GROW_EDIT_BOOK_CATEGORY,
  GROW_EDIT_BOOK_DETAILS,
  GROW_FETCH_AUTHOR_LOADER,
  GROW_LIST_BOOK_CATEGORY_LOADER,
  HANDLE_OPEN_ADD_EDIT,
  LIST_ALL_BOOKS_LISTING,
  LIST_ALL_GROW_BOOKS,
  SAVE_GROW_BOOKS,
} from "./actionType";

export const getGrowBookCategoryList = data => ({
  type: GROW_BOOK_CATEGORY_LISTING,
  payload: data,
});

export const getGrowBookCategory = data => ({
  type: GROW_BOOK_CATEGORY,
  payload: data,
});
export const getGrowAuthorData = data => ({
  type: GROW_AUTHOR,
  payload: data,
});
export const getGrowAuthorList = data => ({
  type: GROW_AUTHOR_LISTING,
  payload: data,
});
export const handleEditAuthor = data => ({
  type: GROW_EDIT_AUTHOR,
  payload: data,
});
export const handleAuthorTabChange = data => ({
  type: GROW_AUTHOR_TAB_CHANGE,
  payload: data,
});
export const handelDeleteAuthor = data => ({
  type: GROW_DELETE_AUTHOR,
  payload: data,
});
export const handelLoaderAuthor = data => ({
  type: GROW_FETCH_AUTHOR_LOADER,
  payload: data,
});
export const handleLoaderAddEditAuthor = data => ({
  type: GROW_ADD_EDIT_AUTHOR_LOADER,
  payload: data,
});
export const handleAddAuthor = data => ({
  type: GROW_AUTHOR_LIST_SAVE,
  payload: data,
});

export const handleAddBookCategory = data => ({
  type: GROW_ADD_BUTTON_BOOK_CATEGORY,
  payload: data,
});

export const handleEditBookCategory = data => ({
  type: GROW_EDIT_BOOK_CATEGORY,
  payload: data,
});

export const handleLoaderAddEditBookCategory = data => ({
  type: GROW_ADD_EDIT_BOOK_CATEGORY_LOADER,
  payload: data,
});

export const handleLoaderListBookCategory = data => ({
  type: GROW_LIST_BOOK_CATEGORY_LOADER,
  payload: data,
});

export const handleCreateBookCategory = (data, callBack = () => {}) => ({
  type: GROW_BOOK_CATEGORY_CREATE,
  payload: data,
  callBack: callBack,
});

export const handleDeleteBookCategory = data => ({
  type: GROW_DELETE_BOOK_CATEGORY,
  payload: data,
});

export const handleOpenAddEdit = data => ({
  type: HANDLE_OPEN_ADD_EDIT,
  payload: data,
});
export const getAllBooksList = data => ({
  type: LIST_ALL_BOOKS_LISTING,
  payload: data,
});
export const getAllBooks = data => ({
  type: LIST_ALL_GROW_BOOKS,
  payload: data,
});

export const saveBooks = data => ({
  type: SAVE_GROW_BOOKS,
  payload: data,
});

export const handelDeleteBooks = data => ({
  type: GROW_DELETE_BOOKS,
  payload: data,
});
export const handleAuthorName = () => ({
  type: GROW_AUTHOR_NAME_LIST,
});

export const handleAuthorNameSuccess = data => ({
  type: GROW_AUTHOR_NAME_LIST_SUCCESS,
  payload: data,
});

export const handleBookCatgeoryNames = () => ({
  type: GROW_BOOK_CATEGORY_LIST,
});

export const handleCategoryBookSuccess = data => ({
  type: GROW_BOOK_CATEGORY_LIST_SUCCESS,
  payload: data,
});

export const handlePageAddEditSuccess = data => ({
  type: ADD_EDIT_PAGE_HANDLE,
  payload: data,
});
export const handleEditBooks = data => ({
  type: GROW_EDIT_BOOK_DETAILS,
  payload: data,
});
