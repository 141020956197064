import axios from "axios";
import { axiosApiAdmin } from "helpers/api_helper";
import {
  DEBUGGER_ACTIVE_INACTIVE,
  LIST_API_LOGS,
  SITES_LISTS,
} from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  apiListLogsError,
  apiListLogsSuccess,
  getErrorFileListError,
  getErrorFileListSuccess,
  getErrorLogFilesError,
  getErrorLogFilesSuccess,
  getSitesListsError,
  getSitesListsSuccess,
  setErrorMessage,
  sitesListsActiveInactiveSuccess,
} from "store/actions";
import {
  API_LIST_LOGS,
  GET_ERROR_FILE_LIST,
  GET_ERROR_LOG_FILES,
  GET_SITES_LISTS,
  SITES_LISTS_ACTIVE_INACTIVE,
} from "./actionsTypes";

const fetchApiLogs = payload => axiosApiAdmin.post(LIST_API_LOGS, payload);
const fetchSitesLists = () => axiosApiAdmin.get(SITES_LISTS);
const fetchActiveInactive = payload =>
  axiosApiAdmin.post(DEBUGGER_ACTIVE_INACTIVE, payload);
const fetchErrorFileList = url => axios.get(`${url}errorFileList`);
const fetchReadErrorLog = (url, payload) =>
  axios.post(`${url}readErrorLogFiles`, payload);

function* handleApiLogs({ payload }) {
  try {
    const res = yield call(fetchApiLogs, payload);
    if (res?.data?.data) {
      yield put(apiListLogsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(apiListLogsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleSiteLists() {
  try {
    const res = yield call(fetchSitesLists);
    if (res?.data?.data) {
      yield put(getSitesListsSuccess(res.data.data.sitesList));
    }
  } catch (err) {
    yield put(getSitesListsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleActiveInactive({ payload }) {
  try {
    const res = yield call(fetchActiveInactive, payload);
    if (res.status) {
      yield put(sitesListsActiveInactiveSuccess(payload));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data.message || err.message));
  }
}

function* handleErrorFileList({ payload }) {
  try {
    const res = yield call(fetchErrorFileList, payload);
    if (res?.data?.data) {
      yield put(getErrorFileListSuccess(res.data.data.filesArr));
    }
  } catch (err) {
    yield put(getErrorFileListError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleReadErrorLogFileList({ payload: { data, fileName, cb } }) {
  try {
    const res = yield call(fetchReadErrorLog, data, fileName);
    if (res?.data?.data) {
      yield put(getErrorLogFilesSuccess(res.data.data));
      if (!!cb && typeof cb === "function") cb(res.data.data);
    }
  } catch (err) {
    yield put(getErrorLogFilesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* DebuggerToolSaga() {
  yield takeEvery(API_LIST_LOGS, handleApiLogs);
  yield takeEvery(GET_SITES_LISTS, handleSiteLists);
  yield takeEvery(SITES_LISTS_ACTIVE_INACTIVE, handleActiveInactive);
  yield takeEvery(GET_ERROR_FILE_LIST, handleErrorFileList);
  yield takeEvery(GET_ERROR_LOG_FILES, handleReadErrorLogFileList);
}

export default DebuggerToolSaga;
