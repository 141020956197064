import {
  COMPONENT_LOADER_DASHBOARD,
  FETCH_CANCELLED_SUBSCRIPTION_CHART,
  FETCH_CANCELLED_SUBSCRIPTION_CHART_ERROR,
  FETCH_CANCELLED_SUBSCRIPTION_CHART_SUCCESS,
  FETCH_DASHBOARD_CARD_DATA,
  FETCH_DASHBOARD_CARD_DATA_ERROR,
  FETCH_DASHBOARD_CARD_DATA_SUCCESS,
  FETCH_EARNINGS_CHART_DATA,
  FETCH_EARNINGS_CHART_DATA_ERROR,
  FETCH_EARNINGS_CHART_DATA_SUCCESS,
  FETCH_NEW_REGISTERED_USER_CHART,
  FETCH_NEW_REGISTERED_USER_CHART_ERROR,
  FETCH_NEW_REGISTERED_USER_CHART_SUCCESS,
  FETCH_NEW_SUBSCRIPTION_CHART,
  FETCH_NEW_SUBSCRIPTION_CHART_ERROR,
  FETCH_NEW_SUBSCRIPTION_CHART_SUCCESS,
  FETCH_ORDER_BY_PRODUCT_CHART_DATA,
  FETCH_ORDER_BY_PRODUCT_CHART_DATA_ERROR,
  FETCH_ORDER_BY_PRODUCT_CHART_DATA_SUCCESS,
  FETCH_RENEWED_SUBSCRIPTION_CHART,
  FETCH_RENEWED_SUBSCRIPTION_CHART_ERROR,
  FETCH_RENEWED_SUBSCRIPTION_CHART_SUCCESS,
  FETCH_SALES_ANALYTICS_CHART_DATA,
  FETCH_SALES_ANALYTICS_CHART_DATA_ERROR,
  FETCH_SALES_ANALYTICS_CHART_DATA_SUCCESS,
  FETCH_TOP_SELLING_PRODUCTS_CHART_DATA,
  FETCH_TOP_SELLING_PRODUCTS_CHART_DATA_ERROR,
  FETCH_TOP_SELLING_PRODUCTS_CHART_DATA_SUCCESS,
  FETCH_TOP_VISITORS_CHART_DATA,
  FETCH_TOP_VISITORS_CHART_DATA_ERROR,
  FETCH_TOP_VISITORS_CHART_DATA_SUCCESS,
  LIST_ALL_SYSTEM_CONFIGURATION,
  LIST_ALL_SYSTEM_CONFIGURATION_ERROR,
  LIST_ALL_SYSTEM_CONFIGURATION_SUCCESS,
} from "./actionTypes";

export const fetchNewRegisteredUser = data => ({
  type: FETCH_NEW_REGISTERED_USER_CHART,
  payload: data,
});

export const fetchNewRegisteredUserSuccess = data => ({
  type: FETCH_NEW_REGISTERED_USER_CHART_SUCCESS,
  payload: data,
});

export const fetchNewRegisteredUserError = () => ({
  type: FETCH_NEW_REGISTERED_USER_CHART_ERROR,
});

export const fetchCancelledSubscription = data => ({
  type: FETCH_CANCELLED_SUBSCRIPTION_CHART,
  payload: data,
});

export const fetchCancelledSubscriptionSuccess = data => ({
  type: FETCH_CANCELLED_SUBSCRIPTION_CHART_SUCCESS,
  payload: data,
});

export const fetchCancelledSubscriptionError = () => ({
  type: FETCH_CANCELLED_SUBSCRIPTION_CHART_ERROR,
});

export const fetchDashboardCardData = data => ({
  type: FETCH_DASHBOARD_CARD_DATA,
  payload: data,
});

export const fetchDashboardCardDataSuccess = data => ({
  type: FETCH_DASHBOARD_CARD_DATA_SUCCESS,
  payload: data,
});

export const fetchDashboardCardDataError = () => ({
  type: FETCH_DASHBOARD_CARD_DATA_ERROR,
});

export const fetchEarningsChartData = data => ({
  type: FETCH_EARNINGS_CHART_DATA,
  payload: data,
});

export const fetchEarningsChartDataSuccess = data => ({
  type: FETCH_EARNINGS_CHART_DATA_SUCCESS,
  payload: data,
});

export const fetchEarningsChartDataError = () => ({
  type: FETCH_EARNINGS_CHART_DATA_ERROR,
});

export const fetchTopSellingProductsChartData = data => ({
  type: FETCH_TOP_SELLING_PRODUCTS_CHART_DATA,
  payload: data,
});

export const fetchTopSellingProductsChartDataSuccess = data => ({
  type: FETCH_TOP_SELLING_PRODUCTS_CHART_DATA_SUCCESS,
  payload: data,
});

export const fetchTopSellingProductsChartDataError = () => ({
  type: FETCH_TOP_SELLING_PRODUCTS_CHART_DATA_ERROR,
});

export const fetchTopVisitorsChartData = data => ({
  type: FETCH_TOP_VISITORS_CHART_DATA,
  payload: data,
});

export const fetchTopVisitorsChartDataSuccess = data => ({
  type: FETCH_TOP_VISITORS_CHART_DATA_SUCCESS,
  payload: data,
});

export const fetchTopVisitorsChartDataError = () => ({
  type: FETCH_TOP_VISITORS_CHART_DATA_ERROR,
});

export const fetchNewSubscription = data => ({
  type: FETCH_NEW_SUBSCRIPTION_CHART,
  payload: data,
});

export const fetchNewSubscriptionSuccess = data => ({
  type: FETCH_NEW_SUBSCRIPTION_CHART_SUCCESS,
  payload: data,
});

export const fetchNewSubscriptionError = () => ({
  type: FETCH_NEW_SUBSCRIPTION_CHART_ERROR,
});

export const fetchRenewedSubscription = data => ({
  type: FETCH_RENEWED_SUBSCRIPTION_CHART,
  payload: data,
});

export const fetchRenewedSubscriptionSuccess = data => ({
  type: FETCH_RENEWED_SUBSCRIPTION_CHART_SUCCESS,
  payload: data,
});

export const fetchRenewedSubscriptionError = () => ({
  type: FETCH_RENEWED_SUBSCRIPTION_CHART_ERROR,
});

export const componentLoaderDashboard = data => ({
  type: COMPONENT_LOADER_DASHBOARD,
  payload: data,
});

export const fetchSalesAnalyticsChartData = data => ({
  type: FETCH_SALES_ANALYTICS_CHART_DATA,
  payload: data,
});

export const fetchSalesAnalyticsChartDataSuccess = data => ({
  type: FETCH_SALES_ANALYTICS_CHART_DATA_SUCCESS,
  payload: data,
});

export const fetchSalesAnalyticsChartDataError = () => ({
  type: FETCH_SALES_ANALYTICS_CHART_DATA_ERROR,
});

export const fetchOrderByProductChartData = data => ({
  type: FETCH_ORDER_BY_PRODUCT_CHART_DATA,
  payload: data,
});

export const fetchOrderByProductChartDataSuccess = data => ({
  type: FETCH_ORDER_BY_PRODUCT_CHART_DATA_SUCCESS,
  payload: data,
});

export const fetchOrderByProductChartDataError = () => ({
  type: FETCH_ORDER_BY_PRODUCT_CHART_DATA_ERROR,
});

export const listAllSystemConfiguration = id => ({
  type: LIST_ALL_SYSTEM_CONFIGURATION,
  id: id,
});

export const listAllSystemConfigurationSuccess = data => ({
  type: LIST_ALL_SYSTEM_CONFIGURATION_SUCCESS,
  payload: data,
});

export const listAllSystemConfigurationError = () => ({
  type: LIST_ALL_SYSTEM_CONFIGURATION_ERROR,
});
