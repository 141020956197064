import {
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_ERROR,
  ADD_PAYMENT_METHOD_SUCCESS,
  CLEAR_PAYMENT_METHODS,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_ERROR,
  DELETE_PAYMENT_METHOD_SUCCESS,
  FETCH_PAYMENT_METHODS,
  FETCH_PAYMENT_METHODS_ERROR,
  FETCH_PAYMENT_METHODS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  paymentMethods: [],
  totalSize: 0,
  sizePerPage: 25,
  loadingButton: false,
};

const paymentMethodReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case FETCH_PAYMENT_METHODS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethods: payload.rows,
        totalSize: payload.count,
      };
    case FETCH_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: [],
      };
    case ADD_PAYMENT_METHOD:
      return {
        ...state,
        loadingButton: true,
      };
    case ADD_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loadingButton: false,
        paymentMethods: [...state.paymentMethods, payload.card_details],
      };
    case ADD_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        loadingButton: false,
      };
    case DELETE_PAYMENT_METHOD:
      return {
        ...state,
        loading: true,
      };
    case DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethods: state.paymentMethods.filter(
          paymentMethod => paymentMethod.pg_customer_card_id !== payload
        ),
      };
    case DELETE_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default paymentMethodReducer;
