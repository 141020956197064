export const API_LIST_LOGS = "API_LIST_LOGS";
export const API_LIST_LOGS_SUCCESS = "API_LIST_LOGS_SUCCESS";
export const API_LIST_LOGS_ERROR = "API_LIST_LOGS_ERROR";

export const API_LOG_PAGES = "SET_API_LOG_PAGES";
export const API_LOG_PER_PAGES = "API_LOG_PER_PAGES";
export const CLEAR_API_LOG_FEATURES = "CLEAR_API_LOG_FEATURES";

export const GET_SITES_LISTS = "GET_SITES_LISTS";
export const GET_SITES_LISTS_SUCCESS = "GET_SITES_LISTS_SUCCESS";
export const GET_SITES_LISTS_ERROR = "GET_SITES_LISTS_ERROR";

export const SITES_LISTS_ACTIVE_INACTIVE = "SITES_LISTS_ACTIVE_INACTIVE";
export const SITES_LISTS_ACTIVE_INACTIVE_SUCCESS =
  "SITES_LISTS_ACTIVE_INACTIVE_SUCCESS";

export const GET_ERROR_FILE_LIST = "GET_ERROR_FILE_LIST";
export const GET_ERROR_FILE_LIST_SUCCESS = "GET_ERROR_FILE_LIST_SUCCESS";
export const GET_ERROR_FILE_LIST_ERROR = "GET_ERROR_FILE_LIST_ERROR";

export const GET_ERROR_LOG_FILES = "GET_ERROR_LOG_FILES";
export const GET_ERROR_LOG_FILES_SUCCESS = "GET_ERROR_LOG_FILES_SUCCESS";
export const GET_ERROR_LOG_FILES_ERROR = "GET_ERROR_LOG_FILES_ERROR";

export const SET_ERROR_LOG_DATA = "SET_ERROR_LOG_DATA";
