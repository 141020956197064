import {
  ADD_UPDATE_REDIRECTION,
  ADD_UPDATE_REDIRECTION_ERROR,
  ADD_UPDATE_REDIRECTION_FOLDER,
  ADD_UPDATE_REDIRECTION_FOLDER_ERROR,
  ADD_UPDATE_REDIRECTION_FOLDER_SUCCESS,
  ADD_UPDATE_REDIRECTION_SUCCESS,
  CLEAR_REDIRECTION_FEATURES,
  CREATE_REDIRECT_FOLDER_LIST,
  CREATE_REDIRECT_FOLDER_LIST_ERROR,
  CREATE_REDIRECT_FOLDER_LIST_SUCCESS,
  DELETE_REDIRECT_FOLDER_LIST,
  DELETE_REDIRECT_FOLDER_LIST_ERROR,
  DELETE_REDIRECT_FOLDER_LIST_SUCCESS,
  FETCH_REDIRECTION_LIST,
  FETCH_REDIRECTION_LIST_ERROR,
  FETCH_REDIRECTION_LIST_SUCCESS,
  FETCH_REDIRECT_FOLDER_LIST,
  FETCH_REDIRECT_FOLDER_LIST_ERROR,
  FETCH_REDIRECT_FOLDER_LIST_SUCCESS,
  SET_REDIRECTION_FILTER,
  SET_REDIRECTION_PAGE,
  SET_REDIRECTION_SEARCH,
  SET_REDIRECTION_SIZE_PER_PAGE,
  SET_STATUS_REDIRECTION_LINK_SUCCESS,
} from "./actionTypes";

const initialState = {
  loadingRedirection: false,
  search: "",
  sizePerPage: 25,
  page: 1,
  totalSize: 0,
  redirectionList: [],
  siteId: 0,
  loadingAddUpdateRedirection: false,
  redirectFolderList: [],
  loadingRedirectFolder: false,
  loadingCreateFolder: [],
  loadingDeleteFolder: false,
  loadingAddUpdateRedirectionFolder: false,
};

const redirectionsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_REDIRECTION_LIST:
      return {
        ...state,
        loadingRedirection: true,
      };
    case FETCH_REDIRECTION_LIST_SUCCESS:
      return {
        ...state,
        redirectionList: payload.rows,
        totalSize: payload.count,
        loadingRedirection: false,
      };
    case FETCH_REDIRECTION_LIST_ERROR:
      return {
        ...state,
        loadingRedirection: false,
      };
    case SET_REDIRECTION_PAGE:
      return {
        ...state,
        page: payload,
      };
    case CLEAR_REDIRECTION_FEATURES:
      return {
        ...state,
        search: "",
        sizePerPage: 25,
        page: 1,
        totalSize: 0,
        siteId: 0,
      };
    case SET_REDIRECTION_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    case SET_REDIRECTION_SEARCH:
      return { ...state, search: payload };

    case SET_REDIRECTION_FILTER:
      return {
        ...state,
        siteId: payload,
      };
    case ADD_UPDATE_REDIRECTION:
      return {
        ...state,
        loadingAddUpdateRedirection: true,
      };
    case ADD_UPDATE_REDIRECTION_ERROR:
      return {
        ...state,
        loadingAddUpdateRedirection: false,
      };
    case ADD_UPDATE_REDIRECTION_SUCCESS:
      return {
        ...state,
        loadingAddUpdateRedirection: false,
      };

    case SET_STATUS_REDIRECTION_LINK_SUCCESS:
      state.redirectionList.map(x => {
        if (x.page_link_id === payload.page_link_id) {
          x.is_disable = payload.is_disable;
        }
      });

    case FETCH_REDIRECT_FOLDER_LIST:
      return {
        ...state,
        loadingRedirectFolder: true,
      };
    case FETCH_REDIRECT_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        redirectFolderList: payload.rows,
        loadingRedirectFolder: false,
      };
    case FETCH_REDIRECT_FOLDER_LIST_ERROR:
      return {
        ...state,
        loadingRedirectFolder: false,
      };
    case CREATE_REDIRECT_FOLDER_LIST:
      return {
        ...state,
        loadingCreateFolder: true,
      };
    case CREATE_REDIRECT_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        //redirectFolderList: payload.rows,
        loadingCreateFolder: false,
      };
    case CREATE_REDIRECT_FOLDER_LIST_ERROR:
      return {
        ...state,
        loadingCreateFolder: false,
      };
    case DELETE_REDIRECT_FOLDER_LIST:
      return {
        ...state,
        loadingDeleteFolder: true,
      };
    case DELETE_REDIRECT_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        loadingDeleteFolder: false,
      };
    case DELETE_REDIRECT_FOLDER_LIST_ERROR:
      return {
        ...state,
        loadingDeleteFolder: false,
      };
    case ADD_UPDATE_REDIRECTION_FOLDER:
      return {
        ...state,
        loadingAddUpdateRedirectionFolder: true,
      };
    case ADD_UPDATE_REDIRECTION_FOLDER_ERROR:
      return {
        ...state,
        loadingAddUpdateRedirectionFolder: false,
      };
    case ADD_UPDATE_REDIRECTION_FOLDER_SUCCESS:
      return {
        ...state,
        loadingAddUpdateRedirectionFolder: false,
      };
    default:
      return state;
  }
};

export default redirectionsReducer;
