import {
  ADD_EDIT_FILTERS,
  ADD_EDIT_FILTERS_ERROR,
  ADD_EDIT_FILTERS_SUCCESS,
  CLEAR_FILTER_FEATURES,
  DELETE_FILTERS,
  DELETE_FILTERS_ERROR,
  DELETE_FILTERS_SUCCESS,
  FETCH_FILTERS,
  FETCH_FILTERS_ERROR,
  FETCH_FILTERS_SUCCESS,
  GET_FILTERS_DETAILS,
  GET_FILTERS_DETAILS_ERROR,
  GET_FILTERS_DETAILS_SUCCESS,
  SET_PAGE_FILTERS,
  SET_SEARCH_FILTERS,
  SET_SIZE_PER_PAGE_FILTERS,
  SET_SORT_FIELD_FILTERS,
  SET_SORT_ORDER_FILTERS,
} from "./actionsTypes";

const initialState = {
  loading: false,
  filters: [],
  totalSize: 0,
  search: "",
  sizePerPage: 25,
  page: 1,
  sortField: "name",
  sortOrder: "DESC",
  filtersDetails: [],
};

const Filters = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_FILTERS:
      return {
        ...state,
        loading: true,
      };

    case FETCH_FILTERS_SUCCESS:
      return {
        ...state,
        filters: payload.data.rows,
        loading: false,
        totalSize: payload.data.count,
      };

    case FETCH_FILTERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_PAGE_FILTERS:
      return {
        ...state,
        page: payload,
      };

    case SET_SIZE_PER_PAGE_FILTERS:
      return {
        ...state,
        sizePerPage: payload,
      };

    case SET_SEARCH_FILTERS:
      return {
        ...state,
        search: payload,
      };
    case CLEAR_FILTER_FEATURES:
      return {
        ...state,
        totalSize: 0,
        search: "",
        sizePerPage: 25,
        page: 1,
        sortField: "name",
        sortOrder: "DESC",
      };

    case SET_SORT_FIELD_FILTERS:
      return {
        ...state,
        sortField: payload,
      };

    case SET_SORT_ORDER_FILTERS:
      return {
        ...state,
        sortOrder: payload,
      };

    case ADD_EDIT_FILTERS:
      return {
        ...state,
        loading: true,
      };

    case ADD_EDIT_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case ADD_EDIT_FILTERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_FILTERS:
      return {
        ...state,
        loading: true,
      };

    case DELETE_FILTERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case DELETE_FILTERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_FILTERS_DETAILS:
      return {
        ...state,
        loading: true,
      };

    case GET_FILTERS_DETAILS_SUCCESS:
      return {
        ...state,
        filtersDetails: payload.data,
        loading: false,
      };

    case GET_FILTERS_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default Filters;
