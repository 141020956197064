//KI-09-12-21
//GET GAME ADMIN
export const FETCH_GAME = "FETCH_GAME";
export const FETCH_GAME_SUCCESS = "FETCH_GAME_SUCCESS";
export const FETCH_GAME_ERROR = "FETCH_GAME_ERROR";

// GET FILTER
export const FETCH_FILTER = "FETCH_FILTER";
export const FETCH_FILTER_SUCCESS = "FETCH_FILTER_SUCCESS";
export const FETCH_FILTER_ERROR = "FETCH_FILTER_ERROR";

//Table
export const SET_PAGE_GAME = "SET_PAGE_GAME";
export const SET_SIZE_PER_PAGE_GAME = "SET_SIZE_PER_PAGE_GAME";
export const SET_SEARCH_GAME = "SET_SEARCH_GAME";
export const CLEAR_GAME_FEATURES = "CLEAR_GAME_FEATURES";

//ADD/EDIT GAME
export const ADD_EDIT_GAME = "ADD_EDIT_GAME";
export const ADD_EDIT_GAME_SUCCESS = "ADD_EDIT_GAME_SUCCESS";
export const ADD_EDIT_GAME_ERROR = "ADD_EDIT_GAME_ERROR";

//DELETE GAME
export const DELETE_GAME = "DELETE_GAME";
export const DELETE_GAME_SUCCESS = "DELETE_GAME_SUCCESS";
export const DELETE_GAME_ERROR = "DELETE_GAME_ERROR";

//KI-09-12-21
//GET GAME USER
export const FETCH_GAME_USER = "FETCH_GAME_USER";
export const FETCH_GAME_USER_SUCCESS = "FETCH_GAME_USER_SUCCESS";
export const FETCH_GAME_USER_ERROR = "FETCH_GAME_USER_ERROR";

//Table
export const SET_PAGE_GAME_USER = "SET_PAGE_GAME_USER";
export const SET_SIZE_PER_PAGE_GAME_USER = "SET_SIZE_PER_PAGE_GAME_USER";
export const SET_SEARCH_GAME_USER = "SET_SEARCH_GAME_USER";

//GET GET_GAME_DETAILS
export const GET_GAME_DETAILS = "GET_GAME_DETAILS";
export const GET_GAME_DETAILS_SUCCESS = "GET_GAME_DETAILS_SUCCESS";
export const GET_GAME_DETAILS_ERROR = "GET_GAME_DETAILS_ERROR";

export const SET_SORT_FILED_GAME = "SET_SORT_FILED_GAME";
export const SET_SORT_ORDER_GAME = "SET_SORT_ORDER_GAME";
export const SET_CATEGORY_ID_GAME = "SET_CATEGORY_ID_GAME";

//ADD NOTIFICATION
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS";
export const ADD_NOTIFICATION_ERROR = "ADD_NOTIFICATION_ERROR";
