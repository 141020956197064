// Va 05 - 01 - 21
import { axiosApiAdmin } from "helpers/api_helper";
import { MODAL_FEEDBACK_REPORT_LIST } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchNewListActiveSubscriptionError,
  setErrorMessage,
} from "store/actions";
import { fetchNewListOnBoardSuccess } from "./actions";
import { FETCH_MODAL_FEEDBACK_LIST } from "./actionTypes";

const fetchModalFeedbackData = data =>
  axiosApiAdmin.post(MODAL_FEEDBACK_REPORT_LIST, data);

function* handleFetchModalFeedbackReport({ payload }) {
  try {
    const res = yield call(fetchModalFeedbackData, payload);
    if (res) {
      yield put(fetchNewListOnBoardSuccess(res?.data?.data));
    }
  } catch (err) {
    yield put(fetchNewListActiveSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

export function* ModalFeedbackReportList() {
  yield takeEvery(FETCH_MODAL_FEEDBACK_LIST, handleFetchModalFeedbackReport);
}
