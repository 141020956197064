//list support tickets
export const FETCH_SUPPORT_TICKETS = "FETCH_SUPPORT_TICKETS";
export const FETCH_SUPPORT_TICKETS_SUCCESS = "FETCH_SUPPORT_TICKETS_SUCCESS";
export const FETCH_SUPPORT_TICKETS_ERROR = "FETCH_SUPPORT_TICKETS_ERROR";

//support tickets details
export const SUPPORT_TICKET_DETAIL = "SUPPORT_TICKET_DETAIL";
export const SUPPORT_TICKET_DETAIL_SUCCESS = "SUPPORT_TICKET_DETAIL_SUCCESS";
export const SUPPORT_TICKET_DETAIL_ERROR = "SUPPORT_TICKET_DETAIL_ERROR";

//support tickets delete from redux
export const DELETE_COMMENT_REDUX = "DELETE_COMMENT_REDUX";

//support tickets comment reply delete from redux
export const DELETE_COMMENT_REPLY_REDUX = "DELETE_COMMENT_REPLY_REDUX";

export const CLEAR_SUPPORT_TICKETS = "CLEAR_SUPPORT_TICKETS";

//TABLE SUPPORT_TICKETS
export const SET_PAGE_SUPPORT_TICKETS = "SET_PAGE_SUPPORT_TICKETS";
export const SET_SIZE_PER_PAGE_SUPPORT_TICKETS =
  "SET_SIZE_PER_PAGE_SUPPORT_TICKETS";
export const SET_SEARCH_SUPPORT_TICKETS = "SET_SEARCH_SUPPORT_TICKETS";
export const SET_SORT_FIELD_SUPPORT_TICKETS = "SET_SORT_FIELD_SUPPORT_TICKETS";
export const SET_SORT_ORDER_SUPPORT_TICKETS = "SET_SORT_ORDER_SUPPORT_TICKETS";
