import { axiosApiAdmin } from "helpers/api_helper";
import {
  ADD_UPDATE_GAME,
  DELETE_GAME_URL,
  GAME_DETAIL,
  LIST_FILTER,
  LIST_GAME,
  LIST_USER_GAME,
  SEND_GAME_NOTIFICATION,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  addEditGameError,
  deleteGameError,
  fetchFilterError,
  fetchFilterSuccess,
  fetchGame,
  fetchGameByIdError,
  fetchGameByIdSuccess,
  fetchGameError,
  fetchGameSuccess,
  fetchGameUserError,
  fetchGameUserSuccess,
  setErrorMessage,
  setSuccessMessage,
} from "store/actions";
import {
  ADD_EDIT_GAME,
  ADD_NOTIFICATION,
  DELETE_GAME,
  FETCH_FILTER,
  FETCH_GAME,
  FETCH_GAME_USER,
  GET_GAME_DETAILS,
} from "./actionTypes";

const addEditGames = data => axiosApiAdmin.post(ADD_UPDATE_GAME, data);
const fetchGames = data => axiosApiAdmin.post(LIST_GAME, data);
const fetchFilters = data => axiosApiAdmin.post(LIST_FILTER, data);
const deleteGames = data => axiosApiAdmin.post(DELETE_GAME_URL, data);
const fetchGameUsers = data => axiosApiAdmin.post(LIST_USER_GAME, data);
const fetchGameByIds = data => axiosApiAdmin.get(GAME_DETAIL + `/${data}`);
const addNotifications = data =>
  axiosApiAdmin.post(SEND_GAME_NOTIFICATION, data);

function* handleFetchGameAdmin({ payload }) {
  const {
    search,
    is_system,
    sizePerPage,
    page,
    sortOrder,
    sortField,
    categoryId,
  } = yield select(State => State.Game);

  const data = {
    page_record: !!payload ? payload.page_record : sizePerPage,
    page_no: search ? 1 : !!payload ? payload.page_no : page,
    is_system: !!payload ? payload.is_system : is_system,
    search,
    sort_order: sortOrder,
    sort_field: sortField,
    category_id: categoryId,
  };

  try {
    const res = yield call(fetchGames, data);
    if (res.data?.data) {
      yield put(fetchGameSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchGameError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAddEditGame({ payload: { data, cb } }) {
  try {
    const res = yield call(addEditGames, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchGame());
    }
    if (!!cb) cb();
  } catch (err) {
    yield put(addEditGameError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteGame({ payload: { data, cb } }) {
  try {
    const res = yield call(deleteGames, data);
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchGame());
    }
    if (!!cb) cb();
  } catch (err) {
    yield put(deleteGameError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchFilter() {
  const data = {
    filter_type: 1,
    is_system: 1,
  };
  try {
    const res = yield call(fetchFilters, data);
    if (res.data?.data) {
      yield put(fetchFilterSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchFilterError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchGameUser() {
  const { loading, games, totalSize, search, sizePerPage, page, game } =
    yield select(State => State.Game);

  const data = {
    page_record: sizePerPage,
    page_no: !search ? 1 : page,
    search,
  };

  try {
    const res = yield call(fetchGameUsers, data);
    if (res.data?.data) {
      yield put(fetchGameUserSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchGameUserError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleGetGameDetails({ payload }) {
  try {
    const res = yield call(fetchGameByIds, payload);
    if (res?.data?.data) {
      yield put(fetchGameByIdSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchGameByIdError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleAddNotifications({ payload: { data, cb } }) {
  try {
    const res = yield call(addNotifications, data);
    if (res.data) {
      yield put(setSuccessMessage("Notification sent successfully"));
      cb();
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* gameSaga() {
  yield takeEvery(FETCH_GAME, handleFetchGameAdmin);
  yield takeEvery(ADD_EDIT_GAME, handleAddEditGame);
  yield takeEvery(FETCH_FILTER, handleFetchFilter);
  yield takeEvery(DELETE_GAME, handleDeleteGame);
  yield takeEvery(FETCH_GAME_USER, handleFetchGameUser);
  yield takeEvery(GET_GAME_DETAILS, handleGetGameDetails);
  yield takeEvery(ADD_NOTIFICATION, handleAddNotifications);
}

export default gameSaga;
