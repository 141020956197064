import {
  CLEAR_REVIEW_FEATURES,
  FETCH_GAME_RATING,
  FETCH_GAME_RATING_ERROR,
  FETCH_GAME_RATING_SUCCESS,
  SET_REVIEW_PAGE,
  SET_REVIEW_SIZE_PER_PAGE,
  SET_REVIEW_SORT_FIELD,
  SET_REVIEW_SORT_ORDER,
} from "./actionType";

const initialState = {
  loading: false,
  reviews: [],
  totalSize: 0,
  sizePerPage: 25,
  page: 1,
  sortField: "game_title",
  sortOrder: "DESC",
};
const Review = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_GAME_RATING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GAME_RATING_SUCCESS:
      return {
        ...state,
        reviews: payload?.rows ? payload.rows : [],
        loading: false,
        totalSize: payload?.count ? payload.count : 0,
      };
    case FETCH_GAME_RATING_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_REVIEW_PAGE:
      return {
        ...state,
        page: payload,
      };
    case SET_REVIEW_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    case SET_REVIEW_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };
    case SET_REVIEW_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    case CLEAR_REVIEW_FEATURES:
      return {
        ...state,
        totalSize: 0,
        sizePerPage: 25,
        page: 1,
        sortField: "game_title",
        sortOrder: "DESC",
      };

    default:
      return state;
  }
};

export default Review;
