//KI-09-12-21
import {
  ADD_HABIT,
  ADD_HABIT_ERROR,
  ADD_HABIT_SUCCESS,
  CLEAR_HABIT_FEATURES,
  DELETE_HABIT,
  DELETE_HABIT_ERROR,
  DELETE_HABIT_SUCCESS,
  EDIT_HABIT,
  EDIT_HABIT_ERROR,
  EDIT_HABIT_SUCCESS,
  FETCH_HABIT,
  FETCH_HABIT_ERROR,
  FETCH_HABIT_SUCCESS,
  GET_HABIT_DETAIL,
  GET_HABIT_DETAIL_ERROR,
  GET_HABIT_DETAIL_SUCCESS,
  SET_CATEGORY_ID_HABIT,
  SET_FROM,
  SET_PAGE_HABIT,
  SET_SEARCH_HABIT,
  SET_SIZE_PER_PAGE_HABIT,
  SET_SORT_FILED_HABIT,
  SET_SORT_ORDER_HABIT,
} from "./actionTypes";

const initialState = {
  loading: false,
  habits: [],
  totalSize: 0,
  search: "",
  sizePerPage: 10,
  is_admin: 1,
  is_from: 1,
  page: 1,
  sortField: "title",
  sortOrder: "asc",
  habit: {},
  filter: [],
  category_index: 0,
};

const HabitReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_HABIT:
      return {
        ...state,
        loading: true,
      };

    case SET_CATEGORY_ID_HABIT:
      return {
        ...state,
        category_index: payload,
      };

    case FETCH_HABIT_SUCCESS:
      return {
        ...state,
        habits: payload.rows,
        loading: false,
        totalSize: payload.count,
      };

    case FETCH_HABIT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_PAGE_HABIT:
      return {
        ...state,
        page: payload,
      };

    case SET_SIZE_PER_PAGE_HABIT:
      return {
        ...state,
        sizePerPage: payload,
      };

    case SET_SEARCH_HABIT:
      return {
        ...state,
        search: "",
        search: payload,
      };

    case SET_FROM:
      return {
        ...state,
        is_from: "",
        is_from: payload,
      };

    case CLEAR_HABIT_FEATURES:
      return {
        ...state,
        totalSize: 0,
        search: "",
        sizePerPage: 10,
        is_admin: 1,
        page: 1,
        sortField: "title",
        sortOrder: "asc",
        category_index: 0,
      };

    case ADD_HABIT:
      return {
        ...state,
        loading: true,
      };

    case ADD_HABIT_SUCCESS:
      return {
        ...state,
        habits: [...state.habits, payload],
      };

    case ADD_HABIT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case EDIT_HABIT:
      return {
        ...state,
        loading: true,
      };

    case EDIT_HABIT_SUCCESS:
      return {
        ...state,
        habits: [...state.habits, payload],
      };

    case EDIT_HABIT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_HABIT:
      return {
        ...state,
        loading: true,
      };

    case DELETE_HABIT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case DELETE_HABIT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_SORT_FILED_HABIT:
      return {
        ...state,
        sortField: action.payload,
      };

    case SET_SORT_ORDER_HABIT:
      return {
        ...state,
        sortOrder: action.payload,
      };

    case GET_HABIT_DETAIL:
      return {
        ...state,
        loading: true,
      };

    case GET_HABIT_DETAIL_SUCCESS:
      return {
        ...state,
        habit: payload.data,
        loading: false,
      };

    case GET_HABIT_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default HabitReducer;
