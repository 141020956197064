import { axiosApiAdmin } from "helpers/api_helper";
import { LIST_CON_USER } from "helpers/url_helper";
import { toast } from "react-toastify";
import { getGrowConDataError, getGrowConDataSuccess } from "./actions";
import { FETCH_GROWCON_REPORTS } from "./actionTypes";

const { takeEvery, call, put } = require("redux-saga/effects");

const fetchGrowConData = payload => axiosApiAdmin.post(LIST_CON_USER, payload);

function* GrowConSaga() {
  function* handleGrowConData({ payload }) {
    try {
      const res = yield call(fetchGrowConData, payload);
      if (!!res?.data?.data) {
        yield put(
          getGrowConDataSuccess({
            data: res?.data?.data,
            count: res?.data?.data?.count,
          })
        );
      }
    } catch (err) {
      yield put(getGrowConDataError());
      toast.error(err?.response?.data?.message || err.message);
    }
  }

  yield takeEvery(FETCH_GROWCON_REPORTS, handleGrowConData);
}

export default GrowConSaga;
