//GET FILTERS
export const FETCH_FILTERS = "FETCH_FILTERS";
export const FETCH_FILTERS_SUCCESS = "FETCH_FILTERS_SUCCESS";
export const FETCH_FILTERS_ERROR = "FETCH_FILTERS_ERROR";

//TABLE
export const SET_PAGE_FILTERS = "SET_PAGE_FILTERS";
export const SET_SIZE_PER_PAGE_FILTERS = "SET_SIZE_PER_PAGE_FILTERS";
export const SET_SEARCH_FILTERS = "SET_SEARCH_FILTERS";
export const SET_SORT_FIELD_FILTERS = "SET_SORT_FIELD_FILTERS";
export const SET_SORT_ORDER_FILTERS = "SET_SORT_ORDER_FILTERS";
export const CLEAR_FILTER_FEATURES = "CLEAR_FILTER_FEATURES";

//ADD/EDIT FILTERS
export const ADD_EDIT_FILTERS = "ADD_EDIT_FILTERS";
export const ADD_EDIT_FILTERS_SUCCESS = "ADD_EDIT_FILTERS_SUCCESS";
export const ADD_EDIT_FILTERS_ERROR = "ADD_EDIT_FILTERS_ERROR";

//DELETE FILTERS
export const DELETE_FILTERS = "DELETE_FILTERS";
export const DELETE_FILTERS_SUCCESS = "DELETE_FILTERS_SUCCESS";
export const DELETE_FILTERS_ERROR = "DELETE_FILTERS_ERROR";

//GET GET_FILTERS_DETAILS
export const GET_FILTERS_DETAILS = "GET_FILTERS_DETAILS";
export const GET_FILTERS_DETAILS_SUCCESS = "GET_FILTERS_DETAILS_SUCCESS";
export const GET_FILTERS_DETAILS_ERROR = "GET_FILTERS_DETAILS_ERROR";
