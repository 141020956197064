// Va 05 - 01 - 21
import { axiosApiAdmin } from "helpers/api_helper";
import {
  LIST_CIRCLE_SUPPORT_TICKETS,
  SUPPORT_TICKETS_DETAILS,
} from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchSupportTicketDetailError,
  fetchSupportTicketDetailSuccess,
  fetchSupportTicketsError,
  fetchSupportTicketsSuccess,
  setErrorMessage,
} from "store/actions";
import { FETCH_SUPPORT_TICKETS, SUPPORT_TICKET_DETAIL } from "./actionTypes";

const fetchSupportTickets = data =>
  axiosApiAdmin.post(LIST_CIRCLE_SUPPORT_TICKETS, data);
const supportTicketDetails = data =>
  axiosApiAdmin.post(SUPPORT_TICKETS_DETAILS, data);

function* handleFetchSupportTickets({ payload }) {
  try {
    const res = yield call(fetchSupportTickets, payload);
    if (res) {
      yield put(fetchSupportTicketsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchSupportTicketsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleSupportTicketDetails({ payload }) {
  try {
    const res = yield call(supportTicketDetails, payload);
    if (res.status) {
      yield put(fetchSupportTicketDetailSuccess(res.data.data.posts));
    }
  } catch (err) {
    yield put(fetchSupportTicketDetailError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* supportTicketsSaga() {
  yield takeEvery(FETCH_SUPPORT_TICKETS, handleFetchSupportTickets);
  yield takeEvery(SUPPORT_TICKET_DETAIL, handleSupportTicketDetails);
}

export default supportTicketsSaga;
