// Va 05 - 01 - 21

import {
  CLEAR_ACTIVE_SUBSCRIPTION,
  FATCH_LIST_ACTIVE_SUBSCRIPTION,
  FATCH_LIST_ACTIVE_SUBSCRIPTION_ERROR,
  FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT,
  FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT_NULL,
  FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT_SUCCESS,
  FATCH_LIST_ACTIVE_SUBSCRIPTION_SUCCESS,
  SET_ORDER_RANGE_DATE_ACTIVE_SUBSCRIPTION,
  SET_PAGE_ACTIVE_SUBSCRIPTION,
  SET_SEARCH_ACTIVE_SUBSCRIPTION,
  SET_SIZE_PER_PAGE_ACTIVE_SUBSCRIPTION,
  SET_SORT_FIELD_ACTIVE_SUBSCRIPTION,
  SET_SORT_ORDER_ACTIVE_SUBSCRIPTION,
} from "./actionTypes";

export const fetchlistActiveSubscription = (data, cb) => ({
  type: FATCH_LIST_ACTIVE_SUBSCRIPTION,
  payload: data,
  cb: cb,
});

export const fetchlistActiveSubscriptionForExport = data => ({
  type: FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT,
  payload: data,
});

export const fetchlistActiveSubscriptionForExportSuccess = data => ({
  type: FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT_SUCCESS,
  payload: data,
});
export const fetchlistActiveSubscriptionForExportNull = data => ({
  type: FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT_NULL,
  payload: data,
});
export const fetchlistActiveSubscriptionSuccess = data => ({
  type: FATCH_LIST_ACTIVE_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const fetchlistActiveSubscriptionError = () => ({
  type: FATCH_LIST_ACTIVE_SUBSCRIPTION_ERROR,
});
export const clearActiveSubscription = () => ({
  type: CLEAR_ACTIVE_SUBSCRIPTION,
});

export const setPageActiveSubscription = data => {
  return {
    type: SET_PAGE_ACTIVE_SUBSCRIPTION,
    payload: data,
  };
};

export const setSizePerPageActiveSubscription = data => {
  return {
    type: SET_SIZE_PER_PAGE_ACTIVE_SUBSCRIPTION,
    payload: data,
  };
};

export const setSearchActiveSubscription = data => {
  return {
    type: SET_SEARCH_ACTIVE_SUBSCRIPTION,
    payload: data,
  };
};

export const setSortFieldActiveSubscription = data => ({
  type: SET_SORT_FIELD_ACTIVE_SUBSCRIPTION,
  payload: data,
});

export const setSortOrderActiveSubscription = data => ({
  type: SET_SORT_ORDER_ACTIVE_SUBSCRIPTION,
  payload: data,
});

export const setOrderRangeDateActiveSubscription = data => ({
  type: SET_ORDER_RANGE_DATE_ACTIVE_SUBSCRIPTION,
  payload: data,
});
