import moment from "moment";
import { compareTypes } from "../constants";

const options = [
  { label: "By hour", value: "hour", diffGreat: 0, diffLess: 1 },
  { label: "By day", value: "day", diffGreat: 1, diffLess: 0 },
  { label: "By week", value: "week", diffGreat: 8, diffLess: 0 },
  { label: "By month", value: "month", diffGreat: 31, diffLess: 0 },
  { label: "By quarter", value: "quarter", diffGreat: 91, diffLess: 0 },
  { label: "By year", value: "year", diffGreat: 366, diffLess: 0 },
];

class DateRange {
  constructor(startDate, endDate) {
    this.startDate = moment(startDate);
    this.endDate = moment(endDate);

    // Date
    this.startDateText = this.startDate.format("D");
    this.endDateText = this.endDate.format("D");

    // Month
    this.startMonthText = this.startDate.format("MMM");
    this.endMonthText = this.endDate.format("MMM");

    // Year
    this.startYearText = this.startDate.format("YYYY");
    this.endYearText = this.endDate.format("YYYY");

    // Comparison
    this.isDaysSame = this.startDateText === this.endDateText;
    this.isMonthsSame = this.startMonthText === this.endMonthText;
    this.isYearsSame = this.startYearText === this.endYearText;
  }
  getRangeText() {
    if (this.isYearsSame && this.isMonthsSame && this.isDaysSame)
      return `${this.startMonthText} ${this.startDateText}, ${this.endYearText}`;
    else if (this.isYearsSame && this.isMonthsSame && !this.isDaysSame)
      return `${this.startMonthText} ${this.startDateText} - ${this.endDateText}, ${this.endYearText}`;
    else if (this.isYearsSame && !this.isMonthsSame)
      return `${this.startMonthText} ${this.startDateText} - ${this.endMonthText} ${this.endDateText}, ${this.endYearText}`;
    else if (!this.isYearsSame)
      return `${this.startMonthText} ${this.startDateText}, ${this.startYearText} - ${this.endMonthText} ${this.endDateText}, ${this.endYearText}`;
    else return "";
  }
  getPreviousDates(type) {
    let startDate = this.startDate;
    let endDate = this.endDate;

    switch (type) {
      case compareTypes.PERIOD: {
        const diff = endDate.diff(startDate, "days") + 1;
        startDate = startDate.subtract(diff, "days");
        endDate = endDate.subtract(diff, "days");
        break;
      }
      case compareTypes.MONTH: {
        startDate = startDate.subtract(1, "months");
        endDate = endDate.subtract(1, "months");
        break;
      }
      case compareTypes.QUARTER: {
        startDate = startDate.subtract(3, "months");
        endDate = endDate.subtract(3, "months");
        break;
      }
      case compareTypes.YEAR: {
        startDate = startDate.subtract(1, "years");
        endDate = endDate.subtract(1, "years");
        break;
      }
      default:
        break;
    }

    return {
      startDate,
      endDate,
    };
  }
  getPreviousDatesText(type) {
    const { startDate, endDate } = this.getPreviousDates(type);
    return new DateRange(startDate, endDate).getRangeText();
  }
  getByOptions() {
    const diff = this.endDate.diff(this.startDate, "days") + 1;

    return options.filter(
      option =>
        diff >= option.diffGreat &&
        (option.diffLess === 0 || diff <= option.diffLess)
    );
  }
}

export default DateRange;

export const kFormatter = num => {
  if (Math.abs(num) > 999) {
    const val = Math.sign(num) * (Math.abs(num) / 1000).toFixed(1);
    return val.toFixed(2) + "k";
  } else {
    return num % 1 !== 0 ? num?.toFixed(2) : num;
  }
};

export const getPercentage = (current, prev) => ((current - prev) / prev) * 100;

export class ToolTipText {
  constructor(by, dateTime) {
    this.by = by;
    this.dateTime = dateTime;
  }

  _hour() {
    return moment(this.dateTime).format("hhA MMM DD, YYYY");
  }

  _day() {
    return moment(this.dateTime).format("MMM DD, YYYY");
  }

  _week() {
    return moment(this.dateTime).format("[Week Of] MMM DD, YYYY");
  }

  _month() {
    return moment(this.dateTime).format("MMM, YYYY");
  }

  _quarter() {
    return moment(this.dateTime).format("MMM, YYYY");
  }

  _year() {
    return moment(this.dateTime).format("YYYY");
  }

  _convert() {
    switch (this.by) {
      case "hour":
        return this._hour();
      case "day":
        return this._day();
      case "week":
        return this._week();
      case "month":
        return this._month();
      case "quarter":
        return this._quarter();
      case "year":
        return this._year();
      default:
        return "";
    }
  }
}

export class XAxisText {
  constructor(payload, by, index) {
    this.payload = payload;
    this.dateTime = payload.value;
    this.by = by;
    this.index = index;

    this.top = {
      text: "",
      dx: 0,
    };

    this.bottom = {
      show: false,
      text: "",
      dx: 0,
    };
  }

  _hour() {
    this.top = {
      text: moment(this.dateTime).format("hA"),
      dx: 25 / 2,
    };
    this.bottom = {
      show: this.index === 0,
      text: moment(this.dateTime).format("MMM DD, YYYY"),
      dx: 50 / 2,
    };
  }

  _dayOrWeek() {
    this.top = {
      text: moment(this.dateTime).format("DD"),
      dx: 11 / 2,
    };
    this.bottom = {
      show: true,
      text: moment(this.dateTime).format("MMM YYYY"),
      dx: 45 / 2,
    };
  }

  _monthOrQuarter() {
    this.top = {
      text: moment(this.dateTime).format("MMM"),
      dx: 25 / 2,
    };
    this.bottom = {
      show: true,
      text: moment(this.dateTime).format("YYYY"),
      dx: 24 / 2,
    };
  }

  _year() {
    this.top = {
      text: moment(this.dateTime).format("YYYY"),
      dx: 24 / 2,
    };
    this.bottom = {
      show: false,
      text: "",
      dx: 0,
    };
  }

  _convert() {
    switch (this.by) {
      case "hour":
        this._hour();
        break;
      case "day":
      case "week":
        this._dayOrWeek();
        break;
      case "month":
      case "quarter":
        this._monthOrQuarter();
        break;
      case "year":
        this._year();
        break;
      default:
        break;
    }

    return this;
  }
}

export function DateTimeTz(date) {
  if (!(this instanceof DateTimeTz)) {
    return new DateTimeTz(date);
  }
  this.date = moment(date);
}

DateTimeTz.prototype.utcOffset = function (offset) {
  this.date = this.date.utc().utcOffset(offset);
  return this;
};

DateTimeTz.prototype.startTime = function (offset) {
  const values = {
    "-0400": date => {
      return date.startOf("day").add(4, "hours");
    },
    "0000": date => {
      return date.startOf("day");
    },
  };
  this.date = values[offset](this.date);

  return this;
};

DateTimeTz.prototype.endTime = function (offset) {
  const values = {
    "-0400": date => {
      return date.endOf("day").add(4, "hours");
    },
    "0000": date => {
      return date.endOf("day");
    },
  };
  this.date = values[offset](this.date);

  return this;
};

DateTimeTz.prototype.format = function (format) {
  return this.date.format(format);
};
