import {
  ADD_EDIT_ICEBREAKER,
  ADD_EDIT_ICEBREAKER_ERROR,
  ADD_EDIT_ICEBREAKER_SUCCESS,
  CLEAR_ICEBREAKER_FEATURES,
  DELETE_ICEBREAKER,
  DELETE_ICEBREAKER_ERROR,
  DELETE_ICEBREAKER_SUCCESS,
  FETCH_ICEBREAKER,
  FETCH_ICEBREAKER_ERROR,
  FETCH_ICEBREAKER_FILTER,
  FETCH_ICEBREAKER_FILTER_ERROR,
  FETCH_ICEBREAKER_FILTER_SUCCESS,
  FETCH_ICEBREAKER_SUCCESS,
  GET_ICEBREAKER_DETAILS,
  GET_ICEBREAKER_DETAILS_ERROR,
  GET_ICEBREAKER_DETAILS_SUCCESS,
  SET_PAGE_ICEBREAKER,
  SET_SEARCH_ICEBREAKER,
  SET_SIZE_PER_PAGE_ICEBREAKER,
  SET_SORT_FIELD_ICEBREAKER,
  SET_SORT_ORDER_ICEBREAKER,
  SET_SYSTEM,
} from "./actionsTypes";

export const fetchIcebreaker = data => {
  return {
    type: FETCH_ICEBREAKER,
    payload: data,
  };
};

export const fetchIcebreakerSuccess = data => {
  return {
    type: FETCH_ICEBREAKER_SUCCESS,
    payload: data,
  };
};

export const fetchIcebreakerError = () => {
  return {
    type: FETCH_ICEBREAKER_ERROR,
  };
};

export const setPageIcebreaker = data => {
  return {
    type: SET_PAGE_ICEBREAKER,
    payload: data,
  };
};

export const setSizePerPageIcebreaker = data => {
  return {
    type: SET_SIZE_PER_PAGE_ICEBREAKER,
    payload: data,
  };
};

export const setSearchIcebreaker = data => {
  return {
    type: SET_SEARCH_ICEBREAKER,
    payload: data,
  };
};
export const clearIcebreakerFeatures = () => {
  return {
    type: CLEAR_ICEBREAKER_FEATURES,
  };
};

export const addEditIcebreaker = data => ({
  type: ADD_EDIT_ICEBREAKER,
  payload: data,
});

export const addEditIcebreakerSuccess = data => ({
  type: ADD_EDIT_ICEBREAKER_SUCCESS,
  payload: data,
});

export const addEditIcebreakerError = () => ({
  type: ADD_EDIT_ICEBREAKER_ERROR,
});

export const fetchIcebreakerFilters = data => {
  return {
    type: FETCH_ICEBREAKER_FILTER,
    payload: data,
  };
};

export const fetchIcebreakerFiltersSuccess = data => {
  return {
    type: FETCH_ICEBREAKER_FILTER_SUCCESS,
    payload: data,
  };
};

export const fetchIcebreakerFiltersError = data => {
  return {
    type: FETCH_ICEBREAKER_FILTER_ERROR,
    payload: data,
  };
};

export const fetchIcebreakerById = data => ({
  type: GET_ICEBREAKER_DETAILS,
  payload: data,
});

export const fetchIcebreakerByIdSuccess = data => ({
  type: GET_ICEBREAKER_DETAILS_SUCCESS,
  payload: data,
});

export const fetchIcebreakerByIdError = () => ({
  type: GET_ICEBREAKER_DETAILS_ERROR,
});

export const deleteIcebreaker = data => {
  return {
    type: DELETE_ICEBREAKER,
    payload: data,
  };
};

export const deleteIcebreakerSuccess = data => {
  return {
    type: DELETE_ICEBREAKER_SUCCESS,
    payload: data,
  };
};

export const deleteIcebreakerError = data => {
  return {
    type: DELETE_ICEBREAKER_ERROR,
    payload: data,
  };
};

export const setSortFieldIcebreaker = data => ({
  type: SET_SORT_FIELD_ICEBREAKER,
  payload: data,
});
export const setSortOrderIcebreaker = data => ({
  type: SET_SORT_ORDER_ICEBREAKER,
  payload: data,
});
export const setSystem = data => ({
  type: SET_SYSTEM,
  payload: data,
});
