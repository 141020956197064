import {
  ADD_NOTE,
  ADD_NOTE_ERROR,
  ADD_NOTE_SUCCESS,
  DELETE_NOTE,
  DELETE_NOTE_ERROR,
  DELETE_NOTE_SUCCESS,
  FETCH_CAMPAINGN,
  FETCH_CAMPAINGN_ERROR,
  FETCH_CAMPAINGN_SUCCESS,
  FETCH_NOTE,
  FETCH_NOTE_ERROR,
  FETCH_NOTE_SUCCESS,
  RESET_NOTE,
} from "./actionTypes";

const initialState = {
  orderLoading: false,
  orderTotalSize: 0,
  campaingnLoading: false,
  campaingnTotalSize: 0,
  notes: [],
  pinned: [],
  campaingn: [],
};

const orderNoteReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_NOTE:
      return {
        ...state,
        orderLoading: true,
      };
    case ADD_NOTE_SUCCESS:
      return {
        ...state,
        orderLoading: false,
      };
    case ADD_NOTE_ERROR:
      return {
        ...state,
        orderLoading: false,
      };
    case FETCH_NOTE:
      return {
        ...state,
        orderLoading: true,
      };
    case FETCH_NOTE_SUCCESS:
      return {
        ...state,
        notes: payload.data.rows,
        pinned: payload.data?.pinned,
        orderLoading: false,
        orderTotalSize: payload.data.count,
      };
    case FETCH_NOTE_ERROR:
      return {
        ...state,
        orderLoading: false,
      };
    case FETCH_CAMPAINGN:
      return {
        ...state,
        campaingnLoading: true,
      };
    case FETCH_CAMPAINGN_SUCCESS:
      return {
        ...state,
        campaingn: payload.data.rows,
        campaingnLoading: false,
        campaingnTotalSize: payload.data.count,
      };
    case FETCH_CAMPAINGN_ERROR:
      return {
        ...state,
        campaingnLoading: false,
      };
    case DELETE_NOTE:
      return {
        ...state,
        orderLoading: true,
      };
    case DELETE_NOTE_SUCCESS:
      return {
        ...state,
        orderLoading: false,
      };
    case DELETE_NOTE_ERROR:
      return {
        ...state,
        orderLoading: false,
      };

    case RESET_NOTE:
      return {
        ...state,
        notes: [],
      };
    default:
      return state;
  }
};
export default orderNoteReducer;
