import { axiosApiAdmin } from "helpers/api_helper";
import {
  ADD_UPDATE_FILTER,
  DELETE_FILTER,
  FILTER_DETAIL,
  LIST_ALL_FILTER_PAGINATION,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  addEditFiltersError,
  deleteFiltersError,
  fetchFilters,
  fetchFiltersByIdError,
  fetchFiltersByIdSuccess,
  fetchFiltersError,
  fetchFiltersSuccess,
  setErrorMessage,
  setSuccessMessage,
} from "store/actions";
import {
  ADD_EDIT_FILTERS,
  DELETE_FILTERS,
  FETCH_FILTERS,
  GET_FILTERS_DETAILS,
} from "./actionsTypes";

const fetchFilter = data =>
  axiosApiAdmin.post(LIST_ALL_FILTER_PAGINATION, data);
const addEditFilter = data => axiosApiAdmin.post(ADD_UPDATE_FILTER, data);
const fetchFilterById = data => axiosApiAdmin.get(FILTER_DETAIL + `/${data}`);
const deleteFilter = data => axiosApiAdmin.post(DELETE_FILTER, data);

function* handleFetchFilters({ payload }) {
  const {
    filterType,
    gamefilter,
    search,
    sizePerPage,
    page,
    sortField,
    sortOrder,
  } = yield select(state => state.Filters);

  const data = {
    filter_type: payload ? payload.filter_type : filterType,
    page_record: sizePerPage,
    page_no: page,
    sort_field: sortField,
    sort_order: sortOrder,
    search,
  };
  try {
    const res = yield call(fetchFilter, data);
    if (res.data?.data?.rows) {
      yield put(fetchFiltersSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchFiltersError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAddEditFilters({ payload, filterType, cb }) {
  try {
    const res = yield call(addEditFilter, payload);
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchFilters({ filter_type: filterType }));
      if (!!cb) cb();
    }
  } catch (err) {
    yield put(addEditFiltersError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteFilters({ payload: { data, cb } }) {
  try {
    const res = yield call(deleteFilter, { filter_id: data.filter_id });
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchFilters({ filter_type: data.filter_type }));
      if (!!cb) cb();
    }
  } catch (err) {
    yield put(deleteFiltersError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleGetFiltersDetails({ payload }) {
  try {
    const res = yield call(fetchFilterById, payload);
    if (res?.data?.data) {
      yield put(fetchFiltersByIdSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchFiltersByIdError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* filtersSaga() {
  yield takeEvery(FETCH_FILTERS, handleFetchFilters);
  yield takeEvery(ADD_EDIT_FILTERS, handleAddEditFilters);
  yield takeEvery(DELETE_FILTERS, handleDeleteFilters);
  yield takeEvery(GET_FILTERS_DETAILS, handleGetFiltersDetails);
}

export default filtersSaga;
