import {
  CLEAR_ADMIN_ACTIVITY_LOG,
  FETCH_ADMIN_ACTIVE_LOGS,
  FETCH_ADMIN_ACTIVE_LOGS_ERROR,
  FETCH_ADMIN_ACTIVE_LOGS_SUCCESS,
} from "./actionTypes";

export const fetchAdminActiveLogs = data => {
  return {
    type: FETCH_ADMIN_ACTIVE_LOGS,
    payload: data,
  };
};

export const fetchAdminActiveLogsSuccess = data => {
  return {
    type: FETCH_ADMIN_ACTIVE_LOGS_SUCCESS,
    payload: data,
  };
};

export const fetchAdminActiveLogsError = data => {
  return {
    type: FETCH_ADMIN_ACTIVE_LOGS_ERROR,
    payload: data,
  };
};

export const clearAdminActiveLogs = () => {
  return {
    type: CLEAR_ADMIN_ACTIVITY_LOG,
  };
};
