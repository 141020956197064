// Va 24 - 11 - 21

import {
  ADD_EDIT_COUPON,
  ADD_EDIT_COUPON_ERROR,
  ADD_EDIT_COUPON_SUCCESS,
  CLEAR_COUPON_FEATURES,
  DELETE_COUPONS,
  DELETE_COUPON_ERROR,
  DELETE_COUPON_SUCCESS,
  FETCH_COUPON,
  FETCH_COUPON_ERROR,
  FETCH_COUPON_SUCCESS,
  GET_COUPON_DETAILS,
  GET_COUPON_DETAILS_ERROR,
  GET_COUPON_DETAILS_SUCCESS,
  SET_COUPON_PAGE,
  SET_COUPON_SEARCH,
  SET_COUPON_SIZE_PER_PAGE,
  SET_COUPON_SORT_FIELD,
  SET_COUPON_SORT_ORDER,
} from "./actionTypes";

// add/updating coupon
export const addEditCoupon = data => {
  return {
    type: ADD_EDIT_COUPON,
    payload: data,
  };
};

export const addEditCouponSuccess = data => ({
  type: ADD_EDIT_COUPON_SUCCESS,
  payload: data,
});

export const addEditCouponError = () => ({
  type: ADD_EDIT_COUPON_ERROR,
});

// fetching coupon detail
export const fetchCouponById = data => ({
  type: GET_COUPON_DETAILS,
  payload: data,
});

export const fetchCouponByIdSuccess = data => ({
  type: GET_COUPON_DETAILS_SUCCESS,
  payload: data,
});

export const fetchCouponByIdError = () => ({
  type: GET_COUPON_DETAILS_ERROR,
});

// fetching coupon
export const fetchCoupon = data => {
  return {
    type: FETCH_COUPON,
    payload: data,
  };
};

export const fetchCouponSuccess = data => {
  return {
    type: FETCH_COUPON_SUCCESS,
    payload: data,
  };
};

export const fetchCouponError = data => {
  return {
    type: FETCH_COUPON_ERROR,
    payload: data,
  };
};

export const setCouponPage = data => ({
  type: SET_COUPON_PAGE,
  payload: data,
});

export const setCouponSearch = data => ({
  type: SET_COUPON_SEARCH,
  payload: data,
});

export const setCouponSizePerPage = data => ({
  type: SET_COUPON_SIZE_PER_PAGE,
  payload: data,
});

export const setCouponSortField = data => ({
  type: SET_COUPON_SORT_FIELD,
  payload: data,
});

export const setCouponSortOrder = data => ({
  type: SET_COUPON_SORT_ORDER,
  payload: data,
});
export const clearCouponFeatures = () => ({
  type: CLEAR_COUPON_FEATURES,
});

// deleting coupon
export const deleteCoupons = data => ({
  type: DELETE_COUPONS,
  payload: data,
});

export const deleteCouponSuccess = data => ({
  type: DELETE_COUPON_SUCCESS,
  payload: data,
});

export const deleteCouponError = data => ({
  type: DELETE_COUPON_ERROR,
  payload: data,
});
