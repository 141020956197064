// Va 05 - 01 - 21

import {
  CLEAR_EMAIL_LOGS,
  FETCH_EMAIL_LOGS,
  FETCH_EMAIL_LOGS_ERROR,
  FETCH_EMAIL_LOGS_SUCCESS,
  SET_EMAIL_LOGS_PAGE,
} from "./actionTypes";

const initialState = {
  emailLogs: [],
  loading: false,
  totalSize: 0,
  sizePerPage: 10,
  page: 1,
};

const emailLogsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_EMAIL_LOGS_PAGE:
      return {
        ...state,
        page: payload,
      };
    case CLEAR_EMAIL_LOGS:
      return {
        ...state,
        emailLogs: [],
      };
    case FETCH_EMAIL_LOGS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EMAIL_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        emailLogs:
          state.page === 1
            ? [...payload.data.data]
            : [...state.emailLogs, ...payload.data.data],
        totalSize: payload.data.count,
      };
    case FETCH_EMAIL_LOGS_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default emailLogsReducer;
