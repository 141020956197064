import { axiosApiAdmin } from "helpers/api_helper";
import { LIST_GROW_CON_VIDEO } from "helpers/url_helper";
import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  listGrowConVideoSliderError,
  listGrowConVideoSliderSuccess,
} from "./action";
import { LIST_GROW_CON_VIDEO_SLIDER_LOADING } from "./actionTypes";

const handleFetchVideosApi = payload =>
  axiosApiAdmin.post(LIST_GROW_CON_VIDEO, payload);

function* handleFetchVideos({ payload }) {
  try {
    const res = yield call(handleFetchVideosApi, payload);

    if (res?.data) {
      yield put(listGrowConVideoSliderSuccess(res?.data?.data));
    }
  } catch (err) {
    toast.error(err.response.data.message);
    yield put(listGrowConVideoSliderError());
  }
}

function* applicationsSaga() {
  yield takeEvery(LIST_GROW_CON_VIDEO_SLIDER_LOADING, handleFetchVideos);
}

export default applicationsSaga;
