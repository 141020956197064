// browser

export const FETCH_BROWSER_DATA = "FETCH_BROWSER_DATA";
export const FETCH_BROWSER_DATA_SUCCESS = "FETCH_BROWSER_DATA_SUCCESS";
export const FETCH_BROWSER_DATA_ERROR = "FETCH_BROWSER_DATA_ERROR";

// Total Visit
export const FETCH_TOTAL_VISIT_DATA = "FETCH_TOTAL_VISIT_DATA";
export const FETCH_TOTAL_VISIT_DATA_SUCCESS = "FETCH_TOTAL_VISIT_DATA_SUCCESS";
export const FETCH_TOTAL_VISIT_COUNT_DATA_SUCCESS =
  "FETCH_TOTAL_VISIT_COUNT_DATA_SUCCESS";
export const FETCH_TOTAL_VISIT_DATA_ERROR = "FETCH_TOTAL_VISIT_DATA_ERROR";

// Unique Visit
export const FETCH_UNIQUE_VISIT_DATA = "FETCH_UNIQUE_VISIT_DATA";
export const FETCH_UNIQUE_VISIT_DATA_SUCCESS =
  "FETCH_UNIQUE_VISIT_DATA_SUCCESS";
export const FETCH_UNIQUE_VISIT_COUNT_DATA_SUCCESS =
  "FETCH_UNIQUE_VISIT_COUNT_DATA_SUCCESS";
export const FETCH_UNIQUE_VISIT_DATA_ERROR = "FETCH_UNIQUE_VISIT_DATA_ERROR";

//Chart XAxis Data
export const FETCH_X_AXIS_DATA = "FETCH_X_AXIS_DATA";

//Module Chart XAxis Data
export const FETCH_MODULE_X_AXIS_DATA = "FETCH_MODULE_X_AXIS_DATA";

//average per session
export const FETCH_AVERAGE = "FETCH_AVERAGE";
export const FETCH_AVERAGE_DATA_SUCCESS = "FETCH_AVERAGE_DATA_SUCCESS";
export const FETCH_AVERAGE_DATA_ERROR = "FETCH_AVERAGE_DATA_ERROR";

//average page per session
export const FETCH_AVERAGE_PAGE = "FETCH_AVERAGE_PAGE";
export const FETCH_AVERAGE_PAGE_DATA_SUCCESS =
  "FETCH_AVERAGE_PAGE_DATA_SUCCESS";
export const FETCH_AVERAGE_PAGE_DATA_ERROR = "FETCH_AVERAGE_PAGE_DATA_ERROR";

//location
export const FETCH_LOCATION_DATA = "FETCH_LOCATION_DATA";
export const FETCH_LOCATION_DATA_SUCCESS = "FETCH_LOCATION_DATA_SUCCESS";
export const FETCH_LOCATION_MAP_DATA_SUCCESS =
  "FETCH_LOCATION_MAP_DATA_SUCCESS";
export const FETCH_LOCATION_DATA_ERROR = "FETCH_LOCATION_DATA_ERROR";

//Location

export const FETCH_DEVICE_DATA = "FETCH_DEVICE_DATA";
export const FETCH_DEVICE_DATA_SUCCESS = "FETCH_DEVICE_DATA_SUCCESS";
export const FETCH_DEVICE_DATA_ERROR = "FETCH_DEVICE_DATA_ERROR";

//page views

export const FETCH_PAGE_VIEWS = "FETCH_PAGE_VIEWS";
export const FETCH_PAGE_VIEWS_SUCCESS = "FETCH_PAGE_VIEWS_SUCCESS";
export const FETCH_PAGE_VIEWS_ERROR = "FETCH_PAGE_VIEWS_ERROR";

//os
export const FETCH_OPERATING_SYSTEM_DATA = "FETCH_OPERATING_SYSTEM_DATA";
export const FETCH_OPERATING_SYSTEM_DATA_SUCCESS =
  "FETCH_OPERATING_SYSTEM_DATA_SUCCESS";
export const FETCH_OPERATING_SYSTEM_DATA_ERROR =
  "FETCH_OPERATING_SYSTEM_DATA_ERROR";

//pages

export const FETCH_PAGES = "FETCH_PAGES";
export const FETCH_PAGES_SUCCESS = "FETCH_PAGES_SUCCESS";
export const FETCH_PAGES_ERROR = "FETCH_PAGES_ERROR";

// sessions

export const FETCH_SESSIONS = "FETCH_SESSIONS";
export const FETCH_SESSIONS_SUCCESS = "FETCH_SESSIONS_SUCCESS";
export const FETCH_SESSIONS_ERROR = "FETCH_SESSIONS_ERROR";
export const GET_BROWSER_DATA = "GET_BROWSER_DATA";

export const SET_ACTIVE_TAB_DATA = "SET_ACTIVE_TAB_DATA";
