export const SET_ANALYTICS_ORDER_ACTIVE_TAB = "SET_ANALYTICS_ORDER_ACTIVE_TAB";
export const SET_ANALYTICS_ORDER_RANGE_DATE = "SET_ANALYTICS_ORDER_RANGE_DATE";
export const SET_ANALYTICS_ORDER_COMPARE_TYPE =
  "SET_ANALYTICS_ORDER_COMPARE_TYPE";
export const SET_ANALYTICS_ORDER_BY = "SET_ANALYTICS_ORDER_BY";
export const SET_ANALYTICS_ORDER_CURRENT_SITE =
  "SET_ANALYTICS_ORDER_CURRENT_SITE";
export const ANALYTICS_ORDER_SET_TZ = "ANALYTICS_ORDER_SET_TZ";

//TABLE

export const SET_ANALYTICS_ORDER_PAGE = "SET_ANALYTICS_ORDER_PAGE";
export const SET_ANALYTICS_ORDER_SIZE_PER_PAGE =
  "SET_ANALYTICS_ORDER_SIZE_PER_PAGE";
export const SET_ANALYTICS_ORDER_SORT_FIELD = "SET_ANALYTICS_ORDER_SORT_FIELD";
export const SET_ANALYTICS_ORDER_SORT_ORDER = "SET_ANALYTICS_ORDER_SORT_ORDER";

// APIS
export const FETCH_ORDER_REPORTS = "FETCH_ORDER_REPORTS";
export const FETCH_ORDER_REPORTS_SUCCESS = "FETCH_ORDER_REPORTS_SUCCESS";
export const FETCH_ORDER_REPORTS_ERROR = "FETCH_ORDER_REPORTS_ERROR";

export const FETCH_ORDER_GRAPH_DATA = "FETCH_ORDER_GRAPH_DATA";
export const FETCH_ORDER_GRAPH_DATA_SUCCESS = "FETCH_ORDER_GRAPH_DATA_SUCCESS";
export const FETCH_ORDER_GRAPH_DATA_ERROR = "FETCH_ORDER_GRAPH_DATA_ERROR";

export const FETCH_ORDER_TABLE_DATA = "FETCH_ORDER_TABLE_DATA";
export const FETCH_ORDER_TABLE_DATA_SUCCESS = "FETCH_ORDER_TABLE_DATA_SUCCESS";
export const FETCH_ORDER_TABLE_DATA_ERROR = "FETCH_ORDER_TABLE_DATA_ERROR";
