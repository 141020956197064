//list subsctiption report
export const FATCH_LIST_SUBSCRIPTION_REPORT = "FATCH_LIST_SUBSCRIPTION_REPORT";
export const FATCH_LIST_SUBSCRIPTION_REPORT_SUCCESS =
  "FATCH_LIST_SUBSCRIPTION_REPORT_SUCCESS";
export const FATCH_LIST_SUBSCRIPTION_REPORT_ERROR =
  "FATCH_LIST_SUBSCRIPTION_REPORT_ERROR";

export const CLEAR_SUPPORT_TICKETS = "CLEAR_SUPPORT_TICKETS";

//TABLE SUPPORT_TICKETS
export const SET_PAGE_SUPPORT_TICKETS = "SET_PAGE_SUPPORT_TICKETS";
export const SET_SIZE_PER_PAGE_SUPPORT_TICKETS =
  "SET_SIZE_PER_PAGE_SUPPORT_TICKETS";
export const SET_SEARCH_SUPPORT_TICKETS = "SET_SEARCH_SUPPORT_TICKETS";
export const SET_SORT_FIELD_SUPPORT_TICKETS = "SET_SORT_FIELD_SUPPORT_TICKETS";
export const SET_SORT_ORDER_SUPPORT_TICKETS = "SET_SORT_ORDER_SUPPORT_TICKETS";

export const FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT =
  "FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT";
export const FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT_SUCCESS =
  "FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT_SUCCESS";
export const FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT_NULL =
  "FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT_NULL";
