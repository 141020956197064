import {
  ADD_CATEGORY,
  ADD_CATEGORY_ERROR,
  ADD_CATEGORY_SUCCESS,
  DELETE_CATEGORY,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY_SUCCESS,
  EDIT_CATEGORY,
  EDIT_CATEGORY_ERROR,
  EDIT_CATEGORY_SUCCESS,
  FETCH_HABIT_CATEGORY,
  FETCH_HABIT_CATEGORY_ERROR,
  FETCH_HABIT_CATEGORY_SUCCESS,
  FETCH_LINKS,
  FETCH_LINKS_ERROR,
  FETCH_LINKS_SUCCESS,
} from "./actionTypes";

// ====================================

export const createCategory = data => ({
  type: ADD_CATEGORY,
  payload: data,
});

export const createCategorySuccess = data => ({
  type: ADD_CATEGORY_SUCCESS,
  payload: data,
});

export const createCategoryError = () => ({
  type: ADD_CATEGORY_ERROR,
});

export const editCategory = data => ({
  type: EDIT_CATEGORY,
  payload: data,
});

export const editCategorySuccess = data => ({
  type: EDIT_CATEGORY_SUCCESS,
  payload: data,
});

export const editCategoryError = () => ({
  type: EDIT_CATEGORY_ERROR,
});

export const getCategoryList = data => ({
  type: FETCH_HABIT_CATEGORY,
  payload: data,
});

export const fetchCategorySuccess = data => ({
  type: FETCH_HABIT_CATEGORY_SUCCESS,
  payload: data,
});

export const fetchCategoryError = data => ({
  type: FETCH_HABIT_CATEGORY_ERROR,
  payload: data,
});

export const deleteCategory = data => ({
  type: DELETE_CATEGORY,
  payload: data,
});

export const deleteCategorySuccess = data => ({
  type: DELETE_CATEGORY_SUCCESS,
  payload: data,
});

export const deleteCategoryError = data => ({
  type: DELETE_CATEGORY_ERROR,
  payload: data,
});

export const getIconsList = data => ({
  type: FETCH_LINKS,
  payload: data,
});

export const fetchIconsSuccess = data => ({
  type: FETCH_LINKS_SUCCESS,
  payload: data,
});

export const fetchIconsError = data => ({
  type: FETCH_LINKS_ERROR,
  payload: data,
});
