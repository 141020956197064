import {
  CLEAR_PRODUCT_REPORT_STATUS,
  CLEAR_REGISTERED_USER_FEATURES,
  CLEAR_RENEWAL_FEATURES,
  CLEAR_REVENUE_REPORT,
  GET_PRODUCT_REVENUE_REPORT_DATA,
  GET_PRODUCT_REVENUE_REPORT_DATA_ERROR,
  GET_PRODUCT_REVENUE_REPORT_DATA_SUCCESS,
  GET_RENEWAL_DATA,
  GET_RENEWAL_DATA_ERROR,
  GET_RENEWAL_DATA_SUCCESS,
  GET_REVENUE_REPORT_DATA,
  GET_REVENUE_REPORT_DATA_ERROR,
  GET_REVENUE_REPORT_DATA_SUCCESS,
  LIST_REGISTERED_USERS,
  LIST_REGISTERED_USERS_ERROR,
  LIST_REGISTERED_USERS_SUCCESS,
  REGISTERED_USER_DATA_PAGE,
  REGISTERED_USER_SIZE_PER_PAGE,
  RENEWAL_DATA_PAGE,
  RENEWAL_SIZE_PER_PAGE,
  SET_PRODUCT_REVENUE_REPORT_DATA_PAGE,
  SET_PRODUCT_REVENUE_REPORT_DATA_SEARCH,
  SET_PRODUCT_REVENUE_REPORT_DATA_SIZE_PER_PAGE,
  SET_PRODUCT_REVENUE_REPORT_END_DATE,
  SET_PRODUCT_REVENUE_REPORT_SORT_FIELD,
  SET_PRODUCT_REVENUE_REPORT_SORT_ORDER,
  SET_PRODUCT_REVENUE_REPORT_START_DATE,
  SET_REGISTERED_USER_SORT_FIELD,
  SET_REGISTERED_USER_SORT_ORDER,
  SET_RENEWAL_SORT_FIELD,
  SET_RENEWAL_SORT_ORDER,
  SET_REVENUE_REPORT_DATA_PAGE,
  SET_REVENUE_REPORT_DATA_SEARCH,
  SET_REVENUE_REPORT_DATA_SIZE_PER_PAGE,
  SET_REVENUE_REPORT_END_DATE,
  SET_REVENUE_REPORT_SORT_FIELD,
  SET_REVENUE_REPORT_SORT_ORDER,
  SET_REVENUE_REPORT_START_DATE,
} from "./actionTypes";

const initialState = {
  revenueReportData: [],
  revenueReportSizePerPage: 25,
  revenueReportTotalSize: 0,
  revenueReportLoader: false,
  revenueReportPageNumber: 1,
  revenueReportSearch: "",
  revenueReportStartDate: new Date(),
  revenueReportEndDate: new Date(),
  revenueReportSortField: "",
  revenueReportSortOrder: "DESC",
  productRevenueReportData: [],
  productRevenueReportSizePerPage: 25,
  productRevenueReportTotalSize: 0,
  productRevenueReportLoader: false,
  productRevenueReportPageNumber: 1,
  productRevenueReportSearch: "",
  productRevenueReportStartDate: new Date(),
  productRevenueReportEndDate: new Date(),
  productRevenueReportSortField: "",
  productRevenueReportSortOrder: "DESC",
  productRevenueTotal: null,
  loadingRenewalData: false,
  renewalData: [],
  renewalPageNo: 1,
  renewalSizePerPage: 25,
  renewalTotalSize: 0,
  renewalSortField: "",
  renewalSortOrder: "DESC",
  renewalTotal: null,
  loadingRegisteredUsers: false,
  resgisteredUsers: [],
  registeredUserPageNo: 1,
  registeredUserSizePerPage: 25,
  registeredUserTotalSize: 0,
  registeredUserSortField: "",
  registeredUserSortOrder: "DESC",
};

const ReportReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_REVENUE_REPORT_DATA:
      return {
        ...state,
        revenueReportLoader: true,
      };
    case GET_REVENUE_REPORT_DATA_SUCCESS:
      return {
        ...state,
        revenueReportData: payload.rows.map((item, index) => ({
          ...item,
          id: index,
        })),
        revenueReportLoader: false,
        revenueReportTotalSize: action.payload.count,
      };
    case GET_REVENUE_REPORT_DATA_ERROR:
      return {
        ...state,
        revenueReportLoader: false,
      };
    case SET_REVENUE_REPORT_DATA_PAGE:
      return {
        ...state,
        revenueReportPageNumber: payload,
      };
    case SET_REVENUE_REPORT_DATA_SEARCH:
      return {
        ...state,
        revenueReportSearch: payload,
        revenueReportPageNumber: 1,
      };
    case SET_REVENUE_REPORT_DATA_SIZE_PER_PAGE:
      return {
        ...state,
        revenueReportSizePerPage: payload,
      };
    case SET_REVENUE_REPORT_START_DATE:
      return {
        ...state,
        revenueReportStartDate: payload,
        revenueReportPageNumber: 1,
      };
    case SET_REVENUE_REPORT_END_DATE:
      return {
        ...state,
        revenueReportEndDate: payload,
        revenueReportPageNumber: 1,
      };
    //PRODUCT
    case GET_PRODUCT_REVENUE_REPORT_DATA:
      return {
        ...state,
        productRevenueReportLoader: true,
      };
    case SET_REVENUE_REPORT_SORT_FIELD:
      return {
        ...state,
        revenueReportSortField: payload,
      };
    case SET_REVENUE_REPORT_SORT_ORDER:
      return {
        ...state,
        revenueReportSortOrder: payload,
      };
    case CLEAR_REVENUE_REPORT:
      return {
        ...state,
        revenueReportSizePerPage: 25,
        revenueReportPageNumber: 1,
        revenueReportSearch: "",
        revenueReportStartDate: new Date(),
        revenueReportEndDate: new Date(),
        revenueReportSortField: "",
        revenueReportSortOrder: "DESC",
      };
    case GET_PRODUCT_REVENUE_REPORT_DATA:
      return {
        ...state,
        productRevenueReportLoader: true,
      };
    case GET_PRODUCT_REVENUE_REPORT_DATA_SUCCESS:
      return {
        ...state,
        productRevenueReportData: payload.rows,
        productRevenueReportLoader: false,
        productRevenueReportTotalSize: payload.count,
        productRevenueTotal: payload.sum || 0,
      };
    case GET_PRODUCT_REVENUE_REPORT_DATA_ERROR:
      return {
        ...state,
        productRevenueReportLoader: false,
      };
    case SET_PRODUCT_REVENUE_REPORT_DATA_PAGE:
      return {
        ...state,
        productRevenueReportPageNumber: payload,
      };
    case SET_PRODUCT_REVENUE_REPORT_DATA_SEARCH:
      return {
        ...state,
        productRevenueReportSearch: payload,
        productRevenueReportPageNumber: 1,
      };
    case SET_PRODUCT_REVENUE_REPORT_DATA_SIZE_PER_PAGE:
      return {
        ...state,
        productRevenueReportSizePerPage: payload,
      };
    case SET_PRODUCT_REVENUE_REPORT_START_DATE:
      return {
        ...state,
        productRevenueReportStartDate: payload,
        productRevenueReportPageNumber: 1,
      };
    case SET_PRODUCT_REVENUE_REPORT_END_DATE:
      return {
        ...state,
        productRevenueReportEndDate: payload,
        productRevenueReportPageNumber: 1,
      };
    case SET_PRODUCT_REVENUE_REPORT_SORT_FIELD:
      return {
        ...state,
        productRevenueReportSortField: payload,
      };
    case SET_PRODUCT_REVENUE_REPORT_SORT_ORDER:
      return {
        ...state,
        productRevenueReportSortOrder: payload,
      };
    case CLEAR_PRODUCT_REPORT_STATUS:
      return {
        ...state,
        productRevenueReportSizePerPage: 25,
        productRevenueReportPageNumber: 1,
        productRevenueReportSearch: "",
        productRevenueReportStartDate: new Date(),
        productRevenueReportEndDate: new Date(),
        productRevenueReportSortField: "",
        productRevenueReportSortOrder: "Desc",
      };
    case GET_RENEWAL_DATA:
      return {
        ...state,
        loadingRenewalData: true,
      };

    case GET_RENEWAL_DATA_SUCCESS:
      return {
        ...state,
        loadingRenewalData: false,
        renewalData: payload.rows.map((item, index) => ({
          ...item,
          id: index,
        })),
        renewalTotalSize: payload.count,
        renewalTotal: payload.sum || 0,
      };

    case GET_RENEWAL_DATA_ERROR:
      return {
        ...state,
        loadingRenewalData: false,
      };

    case RENEWAL_DATA_PAGE:
      return {
        ...state,
        renewalPageNo: payload,
      };
    case RENEWAL_SIZE_PER_PAGE:
      return {
        ...state,
        renewalSizePerPage: payload,
      };

    case SET_RENEWAL_SORT_FIELD:
      return {
        ...state,
        renewalSortField: payload,
      };
    case SET_RENEWAL_SORT_ORDER:
      return {
        ...state,
        renewalSortOrder: payload,
      };
    case CLEAR_RENEWAL_FEATURES:
      return {
        ...state,
        renewalPageNo: 1,
        renewalSizePerPage: 25,
        renewalTotalSize: 0,
        renewalSortField: "",
        renewalSortOrder: "DESC",
      };

    case LIST_REGISTERED_USERS:
      return {
        ...state,
        loadingRegisteredUsers: true,
      };

    case LIST_REGISTERED_USERS_SUCCESS:
      return {
        ...state,
        loadingRegisteredUsers: false,
        resgisteredUsers: payload.rows,
        registeredUserTotalSize: payload.count,
      };

    case LIST_REGISTERED_USERS_ERROR:
      return {
        ...state,
        loadingRegisteredUsers: false,
      };

    case REGISTERED_USER_DATA_PAGE:
      return {
        ...state,
        registeredUserPageNo: payload,
      };
    case REGISTERED_USER_SIZE_PER_PAGE:
      return {
        ...state,
        registeredUserSizePerPage: payload,
      };

    case SET_REGISTERED_USER_SORT_FIELD:
      return {
        ...state,
        registeredUserSortField: payload,
      };
    case SET_REGISTERED_USER_SORT_ORDER:
      return {
        ...state,
        registeredUserSortOrder: payload,
      };
    case CLEAR_REGISTERED_USER_FEATURES:
      return {
        ...state,
        registeredUserPageNo: 1,
        registeredUserSizePerPage: 25,
        registeredUserTotalSize: 0,
        registeredUserSortField: "",
        registeredUserSortOrder: "DESC",
      };

    default:
      return state;
  }
};

export default ReportReducer;
