import {
  CLEAR_REVIEW_FEATURES,
  DELETE_REVIEW,
  FETCH_GAME_RATING,
  FETCH_GAME_RATING_ERROR,
  FETCH_GAME_RATING_SUCCESS,
  SET_REVIEW_PAGE,
  SET_REVIEW_SIZE_PER_PAGE,
  SET_REVIEW_SORT_FIELD,
  SET_REVIEW_SORT_ORDER,
} from "./actionType";
export const fetchGameRating = data => ({
  type: FETCH_GAME_RATING,
  payload: data,
});
export const fetchGameRatingSuccess = data => ({
  type: FETCH_GAME_RATING_SUCCESS,
  payload: data,
});
export const fetchGameRatingError = () => ({
  type: FETCH_GAME_RATING_ERROR,
});

export const setReviewPage = data => ({
  type: SET_REVIEW_PAGE,
  payload: data,
});
export const setReviewSizePerPage = data => ({
  type: SET_REVIEW_SIZE_PER_PAGE,
  payload: data,
});

export const setReviewSortField = data => ({
  type: SET_REVIEW_SORT_FIELD,
  payload: data,
});
export const setReviewSortOrder = data => ({
  type: SET_REVIEW_SORT_ORDER,
  payload: data,
});

export const deleteReview = data => ({
  type: DELETE_REVIEW,
  payload: data,
});

export const clearReviewFeatures = data => ({
  type: CLEAR_REVIEW_FEATURES,
  payload: data,
});
