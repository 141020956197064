import {
  ADD_EDIT_GAME,
  ADD_EDIT_GAME_ERROR,
  ADD_EDIT_GAME_SUCCESS,
  ADD_NOTIFICATION,
  ADD_NOTIFICATION_ERROR,
  ADD_NOTIFICATION_SUCCESS,
  CLEAR_GAME_FEATURES,
  DELETE_GAME,
  DELETE_GAME_ERROR,
  DELETE_GAME_SUCCESS,
  FETCH_FILTER,
  FETCH_FILTER_ERROR,
  FETCH_FILTER_SUCCESS,
  FETCH_GAME,
  FETCH_GAME_ERROR,
  FETCH_GAME_SUCCESS,
  FETCH_GAME_USER,
  FETCH_GAME_USER_ERROR,
  FETCH_GAME_USER_SUCCESS,
  GET_GAME_DETAILS,
  GET_GAME_DETAILS_ERROR,
  GET_GAME_DETAILS_SUCCESS,
  SET_CATEGORY_ID_GAME,
  SET_PAGE_GAME,
  SET_PAGE_GAME_USER,
  SET_SEARCH_GAME,
  SET_SEARCH_GAME_USER,
  SET_SIZE_PER_PAGE_GAME,
  SET_SIZE_PER_PAGE_GAME_USER,
  SET_SORT_FILED_GAME,
  SET_SORT_ORDER_GAME,
} from "./actionTypes";

export const fetchGame = data => {
  return {
    type: FETCH_GAME,
    payload: data,
  };
};

export const fetchGameSuccess = data => ({
  type: FETCH_GAME_SUCCESS,
  payload: data,
});

export const fetchGameError = data => ({
  type: FETCH_GAME_ERROR,
  payload: data,
});

export const fetchFilter = data => ({
  type: FETCH_FILTER,
  payload: data,
});

export const fetchFilterSuccess = data => ({
  type: FETCH_FILTER_SUCCESS,
  payload: data,
});

export const fetchFilterError = data => ({
  type: FETCH_FILTER_ERROR,
  payload: data,
});

export const setPageGame = data => ({
  type: SET_PAGE_GAME,
  payload: data,
});

export const setSizePerPageGame = data => ({
  type: SET_SIZE_PER_PAGE_GAME,
  payload: data,
});

export const setSearchGame = data => ({
  type: SET_SEARCH_GAME,
  payload: data,
});

export const clearGameFeatures = () => ({
  type: CLEAR_GAME_FEATURES,
});

export const addEditGame = data => ({
  type: ADD_EDIT_GAME,
  payload: data,
});

export const addEditGameSuccess = data => ({
  type: ADD_EDIT_GAME_SUCCESS,
  payload: data,
});

export const addEditGameError = () => ({
  type: ADD_EDIT_GAME_ERROR,
});

export const deleteGame = data => ({
  type: DELETE_GAME,
  payload: data,
});

export const deleteGameSuccess = data => ({
  type: DELETE_GAME_SUCCESS,
  payload: data,
});

export const deleteGameError = data => ({
  type: DELETE_GAME_ERROR,
  payload: data,
});

export const fetchGameUser = data => ({
  type: FETCH_GAME_USER,
  payload: data,
});

export const fetchGameUserSuccess = data => ({
  type: FETCH_GAME_USER_SUCCESS,
  payload: data,
});

export const fetchGameUserError = data => ({
  type: FETCH_GAME_USER_ERROR,
  payload: data,
});

export const setPageGameUser = data => ({
  type: SET_PAGE_GAME_USER,
  payload: data,
});

export const setSizePerPageGameUser = data => ({
  type: SET_SIZE_PER_PAGE_GAME_USER,
  payload: data,
});

export const setSearchGameUser = data => ({
  type: SET_SEARCH_GAME_USER,
  payload: data,
});

export const fetchGameById = data => ({
  type: GET_GAME_DETAILS,
  payload: data,
});

export const fetchGameByIdSuccess = data => ({
  type: GET_GAME_DETAILS_SUCCESS,
  payload: data,
});

export const fetchGameByIdError = () => ({
  type: GET_GAME_DETAILS_ERROR,
});

export const setSortFieldGame = data => ({
  type: SET_SORT_FILED_GAME,
  payload: data,
});
export const setSortOrderGame = data => ({
  type: SET_SORT_ORDER_GAME,
  payload: data,
});
export const setCategoryIdGame = data => ({
  type: SET_CATEGORY_ID_GAME,
  payload: data,
});
export const addNotification = data => ({
  type: ADD_NOTIFICATION,
  payload: data,
});
export const addNotificationSuccess = data => ({
  type: ADD_NOTIFICATION_SUCCESS,
  payload: data,
});
export const addNotificationError = data => ({
  type: ADD_NOTIFICATION_ERROR,
  payload: data,
});
