import { dynamicSiteId } from "constants/siteDetails";
import { compareTypes } from "modules/Analytics/common/constants";
import DateRange from "modules/Analytics/common/utils";
import moment from "moment";
import {
  ANALYTICS_SUBSCRIPTION_SET_TZ,
  CLEAR_SEARCH_QUERY_REPORT,
  CLEAR_SUBSCRIPTION_REPORT,
  FETCH_NEW_SUBSCRIPTION_CHART_DATA,
  FETCH_NEW_SUBSCRIPTION_CHART_DATA_ERROR,
  FETCH_NEW_SUBSCRIPTION_CHART_DATA_SUCCESS,
  FETCH_RENEWED_SUBSCRIPTION_CHART_DATA,
  FETCH_RENEWED_SUBSCRIPTION_CHART_DATA_ERROR,
  FETCH_RENEWED_SUBSCRIPTION_CHART_DATA_SUCCESS,
  FETCH_SUBSCRIPTION_REPORT_DATA,
  FETCH_SUBSCRIPTION_REPORT_DATA_ERROR,
  FETCH_SUBSCRIPTION_REPORT_DATA_SUCCESS,
  FETCH_SUBSCRIPTION_REPORT_DATE_WISE,
  FETCH_SUBSCRIPTION_REPORT_DATE_WISE_ERROR,
  FETCH_SUBSCRIPTION_REPORT_DATE_WISE_SUCCESS,
  SET_ANALYTICS_NEW_SUBSCRIPTION_BY,
  SET_ANALYTICS_NEW_SUBSCRIPTION_RANGE_DATE,
  SET_ANALYTICS_RENEW_SUBSCRIPTION_BY,
  SET_ANALYTICS_RENEW_SUBSCRIPTION_RANGE_DATE,
  SET_ANALYTICS_SUBSCRIPTION_COMPARE_TYPE,
  SET_ANALYTICS_SUBSCRIPTION_CURRENT_SITE,
  SET_ANALYTICS_SUBSCRIPTION_PAGE,
  SET_ANALYTICS_SUBSCRIPTION_SIZE_PER_PAGE,
  SET_ANALYTICS_SUBSCRIPTION_SORT_FIELD,
  SET_ANALYTICS_SUBSCRIPTION_SORT_ORDER,
} from "./actionTypes";

const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();
const defaultCompareType = compareTypes.PERIOD;

const initialState = {
  range: {
    startDate,
    endDate,
  },
  compareToPrevious: defaultCompareType,
  comparedRange: new DateRange(startDate, endDate).getPreviousDates(
    defaultCompareType
  ),
  subscriptionCount: "",
  filter_renew: "day",
  filter_new: "day",
  loadingGraph: false,
  newSubscription: {
    rows: [],
    current: 0,
    previous: 0,
  },
  renewSubscription: {
    rows: [],
    current: 0,
    previous: 0,
  },
  subscriptionCountDateWise: {
    loading: false,
    rows: [],
    pageNo: 1,
    pageRecord: 25,
    sortField: "created_date",
    sortOrder: "ASC",
    count: 0,
    total: 0,
    total_new: 0,
    total_new_amount: 0,
    total_renew: 0,
    total_renew_amount: 0,
  },
  siteId: dynamicSiteId.Curriculum,
  tz: "-0400",
};

const SubscriptionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ANALYTICS_SUBSCRIPTION_SET_TZ:
      return {
        ...state,
        tz: payload,
      };
    case SET_ANALYTICS_SUBSCRIPTION_CURRENT_SITE:
      return {
        ...state,
        siteId: payload,
      };
    case CLEAR_SEARCH_QUERY_REPORT:
      return {
        ...state,
        range: {
          ...state.range,
          startDate,
          endDate,
        },
      };
    case CLEAR_SUBSCRIPTION_REPORT:
      return {
        ...state,
        subscriptionCountDateWise: {
          ...state.subscriptionCountDateWise,
          pageNo: 1,
        },
      };
    case SET_ANALYTICS_SUBSCRIPTION_PAGE:
      return {
        ...state,
        subscriptionCountDateWise: {
          ...state.subscriptionCountDateWise,
          pageNo: payload,
        },
      };
    case SET_ANALYTICS_SUBSCRIPTION_SIZE_PER_PAGE:
      return {
        ...state,
        subscriptionCountDateWise: {
          ...state.subscriptionCountDateWise,
          pageRecord: payload,
        },
      };
    case SET_ANALYTICS_SUBSCRIPTION_SORT_FIELD:
      return {
        ...state,
        subscriptionCountDateWise: {
          ...state.subscriptionCountDateWise,
          sortField: payload,
        },
      };
    case SET_ANALYTICS_SUBSCRIPTION_SORT_ORDER:
      return {
        ...state,
        subscriptionCountDateWise: {
          ...state.subscriptionCountDateWise,
          sortOrder: payload,
        },
      };

    case SET_ANALYTICS_NEW_SUBSCRIPTION_BY:
      return {
        ...state,
        filter_new: payload,
      };
    case SET_ANALYTICS_RENEW_SUBSCRIPTION_BY:
      return {
        ...state,
        filter_renew: payload,
      };
    case FETCH_RENEWED_SUBSCRIPTION_CHART_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RENEWED_SUBSCRIPTION_CHART_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        renewSubscription: {
          ...state.renewSubscription,
          rows: payload.rows,
          current: payload?.current || 0,
          previous: payload?.previous || 0,
        },
      };
    case FETCH_RENEWED_SUBSCRIPTION_CHART_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };

    case FETCH_NEW_SUBSCRIPTION_CHART_DATA:
      return {
        ...state,
        loadingGraph: true,
      };
    case FETCH_NEW_SUBSCRIPTION_CHART_DATA_SUCCESS:
      return {
        ...state,
        loadingGraph: false,
        newSubscription: {
          ...state.newSubscription,
          rows: payload.rows,
          current: payload?.current || 0,
          previous: payload?.previous || 0,
        },
      };
    case FETCH_NEW_SUBSCRIPTION_CHART_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SET_ANALYTICS_NEW_SUBSCRIPTION_RANGE_DATE:
      const prevBy = state.filter_new;
      const newBy = new DateRange(
        payload.startDate,
        payload.endDate
      ).getByOptions()[0].value;
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
        comparedRange: new DateRange(
          payload.startDate,
          payload.endDate
        ).getPreviousDates(state.compareToPrevious),
        ...(prevBy !== newBy ? { filter_new: newBy } : {}),
      };
    case SET_ANALYTICS_RENEW_SUBSCRIPTION_RANGE_DATE:
      const prevRBy = state.filter_renew;
      const newRBy = new DateRange(
        payload.startDate,
        payload.endDate
      ).getByOptions()[0].value;
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
        comparedRange: new DateRange(
          payload.startDate,
          payload.endDate
        ).getPreviousDates(state.compareToPrevious),
        ...(prevRBy !== newRBy ? { filter_renew: newRBy } : {}),
      };
    case SET_ANALYTICS_SUBSCRIPTION_COMPARE_TYPE:
      return {
        ...state,
        compareToPrevious: payload,
        comparedRange: new DateRange(
          state.range.startDate,
          state.range.endDate
        ).getPreviousDates(payload),
      };

    case FETCH_SUBSCRIPTION_REPORT_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUBSCRIPTION_REPORT_DATA_SUCCESS:
      return {
        ...state,
        subscriptionCount: payload,
        loading: false,
      };
    case FETCH_SUBSCRIPTION_REPORT_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };

    case FETCH_SUBSCRIPTION_REPORT_DATE_WISE:
      return {
        ...state,
        subscriptionCountDateWise: {
          ...state.subscriptionCountDateWise,
          loading: true,
        },
      };
    case FETCH_SUBSCRIPTION_REPORT_DATE_WISE_SUCCESS:
      return {
        ...state,
        subscriptionCountDateWise: {
          ...state.subscriptionCountDateWise,
          loading: false,
          rows: payload.rows,
          count: payload?.count || 0,
          total: payload?.total || 0,
          total_new: payload?.total_new || 0,
          total_new_amount: payload?.total_new_amount || 0,
          total_renew: payload?.total_renew || 0,
          total_renew_amount: payload?.total_renew_amount || 0,
        },
      };
    case FETCH_SUBSCRIPTION_REPORT_DATE_WISE_ERROR:
      return {
        ...state,
        subscriptionCountDateWise: {
          ...state.subscriptionCountDateWise,
          loading: false,
        },
      };

    default:
      return state;
  }
};

export default SubscriptionReducer;
