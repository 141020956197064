import {
  CLEAR_FAIL_SUBSCRIPTION,
  FETCH_FAIL_SUBSCRIPTION,
  FETCH_FAIL_SUBSCRIPTION_ERROR,
  FETCH_FAIL_SUBSCRIPTION_SUCCESS,
  SET_ORDER_RANGE_DATE_FAIL_SUBSCRIPTION,
  SET_PAGE_FAIL_SUBSCRIPTION,
  SET_SEARCH_FAIL_SUBSCRIPTION,
  SET_SIZE_PER_PAGE_FAIL_SUBSCRIPTION,
  SET_SORT_FIELD_FAIL_SUBSCRIPTION,
  SET_SORT_ORDER_FAIL_SUBSCRIPTION,
} from "./actionType";

export const getFailSubscription = () => ({
  type: FETCH_FAIL_SUBSCRIPTION,
});

export const getFailSubscriptionSuccess = data => ({
  type: FETCH_FAIL_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const getFailSubscriptionError = () => ({
  type: FETCH_FAIL_SUBSCRIPTION_ERROR,
});

export const clearFailSubscriptions = () => ({
  type: CLEAR_FAIL_SUBSCRIPTION,
});

export const setPageFailSubscriptions = data => {
  return {
    type: SET_PAGE_FAIL_SUBSCRIPTION,
    payload: data,
  };
};

export const setSizePerPageFailSubscriptions = data => {
  return {
    type: SET_SIZE_PER_PAGE_FAIL_SUBSCRIPTION,
    payload: data,
  };
};

export const setSearchFailSubscriptions = data => {
  return {
    type: SET_SEARCH_FAIL_SUBSCRIPTION,
    payload: data,
  };
};

export const setSortFieldFailSubscriptions = data => ({
  type: SET_SORT_FIELD_FAIL_SUBSCRIPTION,
  payload: data,
});

export const setSortOrderFailSubscriptions = data => ({
  type: SET_SORT_ORDER_FAIL_SUBSCRIPTION,
  payload: data,
});

export const setOrderRangeDateFailSubscription = data => ({
  type: SET_ORDER_RANGE_DATE_FAIL_SUBSCRIPTION,
  payload: data,
});
