// set range
export const SET_EMAIL_LOG_RANGE_DATE = "SET_EMAIL_LOG_RANGE_DATE";

//pagination
export const SET_EMAIL_LOG_RANGE_DATE_PAGE = "SET_EMAIL_LOG_RANGE_DATE_PAGE";
export const SET_EMAIL_LOG_RANGE_DATE_SIZE_PER_PAGE =
  "SET_EMAIL_LOG_RANGE_DATE_SIZE_PER_PAGE";

//sorting
export const SET_EMAIL_LOG_SORT_FIELD = "SET_EMAIL_LOG_SORT_FIELD";
export const SET_EMAIL_LOG_SORT_ORDER = "SET_EMAIL_LOG_SORT_ORDER";

//filter
export const SET_EMAIL_LOG_RANGE_DATE_FILTER =
  "SET_EMAIL_LOG_RANGE_DATE_FILTER";

//search
export const SET_EMAIL_LOG_SEARCH = "SET_EMAIL_LOG_SEARCH";

//clear state
export const CLEAR_EMAIL_REPORT = "CLEAR_EMAIL_REPORT";
export const CLEAR_EMAIL_FILTER = "CLEAR_EMAIL_FILTER";

// table api
export const FETCH_EMAIL_LOG_DATA = "FETCH_EMAIL_LOG_DATA";
export const FETCH_EMAIL_LOG_DATA_SUCCESS = "FETCH_EMAIL_LOG_DATA_SUCCESS";
export const FETCH_EMAIL_LOG_DATA_ERROR = "FETCH_EMAIL_LOG_DATA_ERROR";
