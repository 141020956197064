import {
  FETCH_FEEDBACK_OPTION_ERROR,
  FETCH_FEEDBACK_OPTION_LOADING,
  FETCH_FEEDBACK_OPTION_SUCCESS,
} from "./actionTypes";

const initialState = {
  FeedbackOptionLoading: false,
  FeedbackOption: [],
};

const FeedBackOptionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_FEEDBACK_OPTION_LOADING:
      return {
        ...state,
        FeedbackOptionLoading: true,
      };
    case FETCH_FEEDBACK_OPTION_SUCCESS:
      return {
        ...state,
        FeedbackOptionLoading: false,
        FeedbackOption: payload,
      };
    case FETCH_FEEDBACK_OPTION_ERROR:
      return {
        ...state,
        FeedbackOptionLoading: false,
      };

    default:
      return state;
  }
};

export default FeedBackOptionReducer;
