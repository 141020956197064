import {
  ANALYTICS_PRODUCT_SET_TZ,
  CLEAR_PRODUCT_REPORT,
  CLEAR_SEARCH_QUERY_REPORT,
  CLEAR_VIEW_ORDER_DATA,
  FETCH_PRODUCTS_REPORTS,
  FETCH_PRODUCTS_REPORTS_ERROR,
  FETCH_PRODUCTS_REPORTS_SUCCESS,
  GET_ANALYTICS_PRODUCT_GRAPH,
  GET_ANALYTICS_PRODUCT_GRAPH_ERROR,
  GET_ANALYTICS_PRODUCT_GRAPH_SUCCESS,
  GET_ORDER_BY_DATE,
  GET_ORDER_BY_DATE_ERROR,
  GET_ORDER_BY_DATE_SUCCESS,
  GET_ORDER_PAGE,
  GET_ORDER_SIZE_PER_PAGE,
  GET_PRODUCT_SOLD_LIST,
  GET_PRODUCT_SOLD_LIST_ERROR,
  GET_PRODUCT_SOLD_LIST_SUCCESS,
  SET_ANALYTICS_PRODUCTS_ACTIVE_TAB,
  SET_ANALYTICS_PRODUCTS_COMPARE_TYPE,
  SET_ANALYTICS_PRODUCTS_RANGE_DATE,
  SET_ANALYTICS_PRODUCT_BY,
  SET_ANALYTICS_PRODUCT_CURRENT_SITE,
  SET_ANALYTICS_PRODUCT_PAGE,
  SET_ANALYTICS_PRODUCT_SIZE_PER_PAGE,
  SET_ANALYTICS_PRODUCT_SORT_FIELD,
  SET_ANALYTICS_PRODUCT_SORT_ORDER,
} from "./actionTypes";

export const setProductTZ = data => ({
  type: ANALYTICS_PRODUCT_SET_TZ,
  payload: data,
});

export const setAnalyticsProductSiteId = data => ({
  type: SET_ANALYTICS_PRODUCT_CURRENT_SITE,
  payload: data,
});

export const clearProductReport = () => ({
  type: CLEAR_PRODUCT_REPORT,
});

export const clearSearchQueryReport = () => ({
  type: CLEAR_SEARCH_QUERY_REPORT,
});

// Report
export const setAnalyticsProductsActiveTab = data => ({
  type: SET_ANALYTICS_PRODUCTS_ACTIVE_TAB,
  payload: data,
});
export const setAnalyticProductsRangeDate = data => ({
  type: SET_ANALYTICS_PRODUCTS_RANGE_DATE,
  payload: data,
});
export const setAnalyticCompareType = data => ({
  type: SET_ANALYTICS_PRODUCTS_COMPARE_TYPE,
  payload: data,
});
export const getProductsReports = data => ({
  type: FETCH_PRODUCTS_REPORTS,
  payload: data,
});
export const getProductsReportsSuccess = data => ({
  type: FETCH_PRODUCTS_REPORTS_SUCCESS,
  payload: data,
});
export const getProductsReportsError = () => ({
  type: FETCH_PRODUCTS_REPORTS_ERROR,
});

//  Graph
export const getAnalyticsProductGraph = () => ({
  type: GET_ANALYTICS_PRODUCT_GRAPH,
});
export const getAnalyticsProductGraphSuccess = data => ({
  type: GET_ANALYTICS_PRODUCT_GRAPH_SUCCESS,
  payload: data,
});
export const getAnalyticsProductGraphError = () => ({
  type: GET_ANALYTICS_PRODUCT_GRAPH_ERROR,
});
export const setAnalyticsProductBy = data => ({
  type: SET_ANALYTICS_PRODUCT_BY,
  payload: data,
});

// Table
export const getProductSoldList = () => ({
  type: GET_PRODUCT_SOLD_LIST,
});
export const getProductSoldListSuccess = data => ({
  type: GET_PRODUCT_SOLD_LIST_SUCCESS,
  payload: data,
});
export const getProductSoldListError = () => ({
  type: GET_PRODUCT_SOLD_LIST_ERROR,
});
export const setAnalyticsProductPage = data => ({
  type: SET_ANALYTICS_PRODUCT_PAGE,
  payload: data,
});
export const setAnalyticsProductSizePerPage = data => ({
  type: SET_ANALYTICS_PRODUCT_SIZE_PER_PAGE,
  payload: data,
});
export const setAnalyticsProductSortOrder = data => ({
  type: SET_ANALYTICS_PRODUCT_SORT_ORDER,
  payload: data,
});
export const setAnalyticsProductSortFiled = data => ({
  type: SET_ANALYTICS_PRODUCT_SORT_FIELD,
  payload: data,
});

export const getOrderByDate = data => ({
  type: GET_ORDER_BY_DATE,
  payload: data,
});

export const getOrderByDateSuccess = data => ({
  type: GET_ORDER_BY_DATE_SUCCESS,
  payload: data,
});

export const getOrderByDateError = () => ({
  type: GET_ORDER_BY_DATE_ERROR,
});

export const getOrderPages = data => ({
  type: GET_ORDER_PAGE,
  payload: data,
});

export const getOrderSizePerPage = data => ({
  type: GET_ORDER_SIZE_PER_PAGE,
  payload: data,
});

export const clearViewOrderData = () => ({
  type: CLEAR_VIEW_ORDER_DATA,
});
