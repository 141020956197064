// APIS
export const FETCH_BLOG_SITEMAP = "FETCH_BLOG_SITEMAP";
export const FETCH_BLOG_SITEMAP_SUCCESS = "FETCH_BLOG_SITEMAP_SUCCESS";
export const SET_BLOGS_SITEMAP_PAGE = "SET_BLOGS_SITEMAP_PAGE";
export const SET_BLOGS_SITEMAP_PAGE_SIZE_PER_PAGE =
  "SET_BLOGS_SITEMAP_PAGE_SIZE_PER_PAGE";
export const GET_SEARCH_BLOGSITEMAP = "GET_SEARCH_BLOGSITEMAP";
export const GET_SORT_FIELD_BLOG_SITEMAP = "GET_SORT_FIELD_BLOG_SITEMAP";
export const GET_SORT_ORDER_BLOG_SITEMAP = "GET_SORT_ORDER_BLOG_SITEMAP";
export const GET_BLOGSITEMAP_PAGES = "GET_BLOGSITEMAP_PAGES";
export const GET_BLOGSITEMAP_SIZEPERPAGES = "GET_BLOGSITEMAP_SIZEPERPAGES";
export const FETCH_BLOG_SITEMAP_CHART = "FETCH_BLOG_SITEMAP_CHART";
export const FETCH_BLOG_SITEMAP_CHART_SUCCESS =
  "FETCH_BLOG_SITEMAP_CHART_SUCCESS";
export const FETCH_BLOG_LINK_POST_REPORT_SUCCESS =
  "FETCH_BLOG_LINK_POST_REPORT_SUCCESS";
export const FETCH_BLOG_NON_LINK_POST_REPORT_SUCCESS =
  "FETCH_BLOG_NON_LINK_POST_REPORT_SUCCESS";
export const FETCH_BLOG_NUM_LINK_POST_REPORT_SUCCESS =
  "FETCH_BLOG_NUM_LINK_POST_REPORT_SUCCESS";
export const FETCH_BLOG_NON_LINK_NUM_POST_REPORT_SUCCESS =
  "FETCH_BLOG_NON_LINK_NUM_POST_REPORT_SUCCESS";
export const FETCH_BLOG_TOTAL_POST_REPORT_SUCCESS =
  "FETCH_BLOG_TOTAL_POST_REPORT_SUCCESS";
export const SORT_ITEMS_SET = "SORT_ITEMS_SET";
export const FETCH_BLOG_POTENTIAL_LINKED_POST_SUCCESS =
  "FETCH_BLOG_POTENTIAL_LINKED_POST_SUCCESS";
export const FETCH_BLOG_POTENTIAL_LINKED_POST_NUM_SUCCESS =
  "FETCH_BLOG_POTENTIAL_LINKED_POST_NUM_SUCCESS";
