import { axiosApiAdmin } from "helpers/api_helper";
import {
  DELETE_TUTORIAL,
  GROW_FREE_TRIAL_APPLICATION_ADS_SAVE_COLOR,
  GROW_FREE_TRIAL_APPLICATON_ADS_COLOR_LIST,
  GROW_FREE_TRIAL_CREATE_STORY,
  GROW_FREE_TRIAL_HELPFUL_DELETE,
  GROW_FREE_TRIAL_HELPFUL_LIST,
  GROW_FREE_TRIAL_SAVE_STEWARD,
  GROW_FREE_TRIAL_STEWARD_DELETE,
  GROW_FREE_TRIAL_STEWARD_LIST,
  GROW_FREE_TRIAL_STORY_DELETE,
  GROW_FREE_TRIAL_STORY_LIST,
  GROW_FREE_TRIAL_TODO_DELETE,
  GROW_FREE_TRIAL_TO_DO_LIST,
  GROW_SAVE_TO_DO_LIST,
  LIST_ALL_CURRICULUM_POST,
  LIST_ALL_SERIES,
  LIST_TUTORIALS,
  SAVE_FREE_TRIAL_HELFUL_RESOURCES,
  SAVE_TUTORIAL,
} from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_SERIES_CURRICULUM,
  FETCH_SERIES_KIDS,
  GROW_APPLICATION_ADS_SAVE_COLOR,
  GROW_COLOR,
  GROW_DELETE_HELPFUL,
  GROW_DELETE_STEWARD,
  GROW_DELETE_STORIES,
  GROW_DELETE_TODO,
  GROW_DELETE_TUTORIAL,
  GROW_HELPFUL,
  GROW_SAVE_HELPFUL_RESOURCES,
  GROW_SAVE_STEWARD,
  GROW_STEWARD,
  GROW_STORIES,
  GROW_STORY_CREATE,
  GROW_TODO,
  GROW_TO_DO_LIST_SAVE,
  GROW_TUTORIALS,
  GROW_TUTORIALS_CREATE,
} from "./actionType";

import { toast } from "react-toastify";
import {
  getGrowColor,
  getGrowColorList,
  getGrowHelpful,
  getGrowHelpfulList,
  getGrowSteward,
  getGrowStewardList,
  getGrowStories,
  getGrowStoriesList,
  getGrowTodoData,
  getGrowToDoList,
  getTutorialList,
  handelLoaderHelpful,
  handelLoaderSteward,
  handelLoaderStory,
  handelLoaderToDo,
  handelStoreCurriculumData,
  handelStoreKidsData,
  handelToDoListTabChange,
  handleAddEditHelpful,
  handleAddEditSteward,
  handleAddStory,
  handleAddTutorials,
  handleLoaderAddEditHelpful,
  handleLoaderAddEditSteward,
  handleLoaderAddEditStory,
  handleLoaderAddEditTodo,
} from "./action";

const fetchGrowStories = data =>
  axiosApiAdmin.post(GROW_FREE_TRIAL_STORY_LIST, data);

const fetchTutorial = data => axiosApiAdmin.get(`${LIST_TUTORIALS}/${data}`);
const fetchGrowColorList = data =>
  axiosApiAdmin.post(GROW_FREE_TRIAL_APPLICATON_ADS_COLOR_LIST, data);

const fetchGrowSteward = data =>
  axiosApiAdmin.post(GROW_FREE_TRIAL_STEWARD_LIST, data);

const fetchGrowHelpful = data =>
  axiosApiAdmin.post(GROW_FREE_TRIAL_HELPFUL_LIST, data);

const fetchGrowTodo = data =>
  axiosApiAdmin.post(GROW_FREE_TRIAL_TO_DO_LIST, data);

const fetchGrowSaveSteward = data =>
  axiosApiAdmin.post(GROW_FREE_TRIAL_SAVE_STEWARD, data);

const fetchGrowSaveColor = data =>
  axiosApiAdmin.post(GROW_FREE_TRIAL_APPLICATION_ADS_SAVE_COLOR, data);

const addHelpfulResources = data =>
  axiosApiAdmin.post(SAVE_FREE_TRIAL_HELFUL_RESOURCES, data);

const addGrowStoryResources = data =>
  axiosApiAdmin.post(GROW_FREE_TRIAL_CREATE_STORY, data);

const addTutorialResources = data => axiosApiAdmin.post(SAVE_TUTORIAL, data);

const growStoryDelete = data =>
  axiosApiAdmin.post(GROW_FREE_TRIAL_STORY_DELETE, data);

const growTodoDelete = data =>
  axiosApiAdmin.post(GROW_FREE_TRIAL_TODO_DELETE, data);
const growStewardDelete = data =>
  axiosApiAdmin.post(GROW_FREE_TRIAL_STEWARD_DELETE, data);

const growHelpfulDelete = data =>
  axiosApiAdmin.post(GROW_FREE_TRIAL_HELPFUL_DELETE, data);
const tutorialDelete = data =>
  axiosApiAdmin.delete(`${DELETE_TUTORIAL}/${data?.tutorial_id}`);

const growCurriculumData = data => axiosApiAdmin.post(LIST_ALL_SERIES, data);
const growKidsData = data => axiosApiAdmin.post(LIST_ALL_CURRICULUM_POST, data);
const growToDoAddEdit = data => axiosApiAdmin.post(GROW_SAVE_TO_DO_LIST, data);

function* handleFetchGrowStories({ payload }) {
  try {
    if (payload.toggle) yield put(handelLoaderStory(true));
    const res = yield call(fetchGrowStories, payload.id);
    yield put(getGrowStoriesList(res?.data?.data?.stories));
    if (payload.toggle) yield put(handelLoaderStory(false));
  } catch (err) {
    // yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchTutorials({ payload }) {
  try {
    // if (payload.toggle) yield put(handelLoaderStory(true));
    const res = yield call(fetchTutorial, payload.id);
    yield put(getTutorialList(res?.data?.data?.rows));
    // if (payload.toggle) yield put(handelLoaderStory(false));
  } catch (err) {
    // yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleFetchGrowColorList({ payload }) {
  try {
    // yield put(handelLoaderStory(true));
    const res = yield call(fetchGrowColorList, payload);
    yield put(getGrowColorList(res?.data?.data?.applicationColors));

    // yield put(handelLoaderStory(false));
  } catch (err) {
    // yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchGrowSteward({ payload }) {
  try {
    if (payload.toggle) yield put(handelLoaderSteward(true));
    const res = yield call(fetchGrowSteward, payload.id);
    yield put(getGrowStewardList(res.data.data.applications));
    if (payload.toggle) yield put(handelLoaderSteward(false));
  } catch (err) {
    // yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchGrowHelpful({ payload }) {
  try {
    yield put(handelLoaderHelpful(true));

    const res = yield call(fetchGrowHelpful, payload);
    if (res) {
      yield put(getGrowHelpfulList(res.data.data.resources));
    }
    yield put(handelLoaderHelpful(false));
  } catch (err) {
    // yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchGrowTodo({ payload }) {
  try {
    yield put(handelLoaderToDo(true));
    const res = yield call(fetchGrowTodo, payload);
    yield put(getGrowToDoList(res.data.data.todoList));
    yield put(handelLoaderToDo(false));
  } catch (err) {
    // yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchGrowSaveSteward({ payload, callBack }) {
  try {
    yield put(handleLoaderAddEditSteward(true));
    const res = yield call(fetchGrowSaveSteward, payload);
    yield put(handleAddEditSteward(false));
    yield put(handleLoaderAddEditSteward(false));
    callBack(res);
    toast.success(res.data.message);
  } catch (err) {
    // yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchGrowSaveColor({ payload }) {
  try {
    const res = yield call(fetchGrowSaveColor, payload.colorSave);
    if (res) {
      yield put(getGrowColor(payload.getColor));
      toast.success(res.data.message);
    }
  } catch (err) {
    // yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleAddHelpfulResources({ payload }) {
  try {
    yield put(handleLoaderAddEditHelpful(true));
    const res = yield call(addHelpfulResources, payload);
    if (res) {
      yield put(handleAddEditHelpful(false));
      yield put(handleLoaderAddEditHelpful(false));
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err);
  }
}

function* handleAddGrowStoryResources({ payload, callBack }) {
  try {
    yield put(handleLoaderAddEditStory(true));
    const res = yield call(addGrowStoryResources, payload);
    if (res) {
      yield put(handleAddStory(false));
      yield put(handleLoaderAddEditStory(false));
      callBack(res);
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err);
  }
}

function* handleAddTutorialsResources({ payload, callBack }) {
  try {
    // yield put(handleLoaderAddEditStory(true));
    const res = yield call(addTutorialResources, payload);
    if (res) {
      yield put(handleAddTutorials(false));
      yield put(getTutorialList(res?.data?.data?.rows));
      // yield put(handleLoaderAddEditStory(false));
      callBack(res);
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err);
  }
}
function* handleDeleteGrowStory({ payload }) {
  try {
    const res = yield call(growStoryDelete, payload.deleteId);
    if (res) {
      yield put(
        getGrowStories({
          id: payload.type,
          toggle: true,
        })
      );
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err);
  }
}

function* handleDeleteGrowTodo({ payload }) {
  try {
    const res = yield call(growTodoDelete, payload.deleteId);
    if (res) {
      yield put(getGrowTodoData(payload.type));
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err);
  }
}
function* handleDeleteGrowSteward({ payload }) {
  try {
    const res = yield call(growStewardDelete, payload.deleteId);
    if (res) {
      yield put(getGrowSteward({ id: payload.type, toggle: true }));
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err);
  }
}

function* handleDeleteGrowHelpful({ payload }) {
  try {
    const res = yield call(growHelpfulDelete, payload.deleteId);
    if (res) {
      yield put(getGrowHelpful(payload.type));
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err);
  }
}

function* handleDeleteTutorial({ payload, cb }) {
  try {
    const res = yield call(tutorialDelete, payload.deleteId);
    if (res?.data) {
      cb(res?.data);
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err);
  }
}

function* handelFetchCurriculumData({ payload }) {
  try {
    const res = yield call(growCurriculumData, payload);
    if (res) {
      yield put(handelStoreCurriculumData(res?.data?.data?.series));
    }
  } catch (err) {
    toast.error(err);
  }
}

function* handelFetchKidsData({ payload }) {
  try {
    const res = yield call(growKidsData, payload);
    if (res) {
      yield put(handelStoreKidsData(res?.data?.data?.posts_list?.rows));
    }
  } catch (err) {
    toast.error(err);
  }
}

function* handelAddEditToDo({ payload }) {
  try {
    yield put(handleLoaderAddEditTodo(true));
    const res = yield call(growToDoAddEdit, payload);
    if (res) {
      yield put(handelToDoListTabChange(false));
      yield put(handleLoaderAddEditTodo(false));
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err);
  }
}

function* FreeTrialSaga() {
  yield takeEvery(GROW_STORIES, handleFetchGrowStories);
  yield takeEvery(GROW_STEWARD, handleFetchGrowSteward);
  yield takeEvery(GROW_HELPFUL, handleFetchGrowHelpful);
  yield takeEvery(GROW_TODO, handleFetchGrowTodo);
  yield takeEvery(GROW_COLOR, handleFetchGrowColorList);
  yield takeEvery(GROW_SAVE_STEWARD, handleFetchGrowSaveSteward);
  yield takeEvery(GROW_APPLICATION_ADS_SAVE_COLOR, handleFetchGrowSaveColor);
  yield takeEvery(GROW_SAVE_HELPFUL_RESOURCES, handleAddHelpfulResources);
  yield takeEvery(GROW_STORY_CREATE, handleAddGrowStoryResources);
  yield takeEvery(GROW_DELETE_STORIES, handleDeleteGrowStory);
  yield takeEvery(GROW_DELETE_TODO, handleDeleteGrowTodo);
  yield takeEvery(GROW_DELETE_STEWARD, handleDeleteGrowSteward);
  yield takeEvery(GROW_DELETE_HELPFUL, handleDeleteGrowHelpful);
  yield takeEvery(FETCH_SERIES_CURRICULUM, handelFetchCurriculumData);
  yield takeEvery(FETCH_SERIES_KIDS, handelFetchKidsData);
  yield takeEvery(GROW_TO_DO_LIST_SAVE, handelAddEditToDo);
  yield takeEvery(GROW_TUTORIALS_CREATE, handleAddTutorialsResources);
  yield takeEvery(GROW_DELETE_TUTORIAL, handleDeleteTutorial);
  yield takeEvery(GROW_TUTORIALS, handleFetchTutorials);
}

export default FreeTrialSaga;
