import {
  FETCH_FEEDBACK_OPTION_ERROR,
  FETCH_FEEDBACK_OPTION_LOADING,
  FETCH_FEEDBACK_OPTION_SUCCESS,
} from "./actionTypes";

export const fetchFeedbackOptionLoading = () => {
  return {
    type: FETCH_FEEDBACK_OPTION_LOADING,
  };
};
export const fetchFeedbackOptionSuccess = data => {
  return {
    type: FETCH_FEEDBACK_OPTION_SUCCESS,
    payload: data,
  };
};
export const fetchFeedbackOptionError = () => {
  return {
    type: FETCH_FEEDBACK_OPTION_ERROR,
  };
};
