import {
  ADD_EDIT_HELPFUL_SWITCH,
  ADD_EDIT_STEWARD_SWITCH,
  FETCH_SERIES_CURRICULUM,
  FETCH_SERIES_KIDS,
  GROW_ADD_BUTTON_STORY,
  GROW_ADD_BUTTON_TUTORIALS,
  GROW_ADD_EDIT_HELPFUL_LOADER,
  GROW_ADD_EDIT_STEWARDS_LOADER,
  GROW_ADD_EDIT_STORY_LOADER,
  GROW_ADD_EDIT_TODO_LOADER,
  GROW_APPLICATION_ADS_SAVE_COLOR,
  GROW_COLOR,
  GROW_COLOR_LISTING,
  GROW_DELETE_HELPFUL,
  GROW_DELETE_STEWARD,
  GROW_DELETE_STORIES,
  GROW_DELETE_TODO,
  GROW_DELETE_TUTORIAL,
  GROW_EDIT_HELPFUL,
  GROW_EDIT_STEWARD,
  GROW_EDIT_STORIES,
  GROW_EDIT_TODO,
  GROW_EDIT_TUTORIALS,
  GROW_FETCH_HELPFUL_LOADER,
  GROW_FETCH_STEWARD_LOADER,
  GROW_FETCH_STORY_LOADER,
  GROW_FETCH_TODO_LOADER,
  GROW_HELPFUL,
  GROW_HELPFUL_LISTING,
  GROW_SAVE_HELPFUL_RESOURCES,
  GROW_SAVE_STEWARD,
  GROW_STEWARD,
  GROW_STEWARDS,
  GROW_STEWARD_LISTING,
  GROW_STORIES,
  GROW_STORIES_LISTING,
  GROW_STORY_CREATE,
  GROW_TODO,
  GROW_TODO_LISTING,
  GROW_TO_DO_LIST_SAVE,
  GROW_TO_DO_LIST_TAB_CHANGE,
  GROW_TUTORIALS,
  GROW_TUTORIALS_CREATE,
  GROW_TUTORIALS_LISTING,
  GROW_TUTORIALS_TAB_CHANGE,
  STORE_SERIES_CURRICULUM,
  STORE_SERIES_KIDS,
  TAB_CHANGE_GROW_FREE_TRIAL,
} from "./actionType";

export const tabChange = data => ({
  type: TAB_CHANGE_GROW_FREE_TRIAL,
  payload: data,
});

export const getGrowStories = data => ({
  type: GROW_STORIES,
  payload: data,
});

export const getGrowColor = data => ({
  type: GROW_COLOR,
  payload: data,
});

export const getGrowColorList = data => ({
  type: GROW_COLOR_LISTING,
  payload: data,
});
export const getGrowTodoData = data => ({
  type: GROW_TODO,
  payload: data,
});
export const getGrowStewards = data => ({
  type: GROW_STEWARDS,
});

export const getGrowStoriesList = data => ({
  type: GROW_STORIES_LISTING,
  payload: data,
});

export const getGrowToDoList = data => ({
  type: GROW_TODO_LISTING,
  payload: data,
});
export const getGrowSteward = data => ({
  type: GROW_STEWARD,
  payload: data,
});

export const getGrowHelpful = data => ({
  type: GROW_HELPFUL,
  payload: data,
});

export const getGrowHelpfulList = data => ({
  type: GROW_HELPFUL_LISTING,
  payload: data,
});

export const getTodoList = data => ({
  type: GROW_TODO_LISTING,
  payload: data,
});
export const getGrowStewardList = data => ({
  type: GROW_STEWARD_LISTING,
  payload: data,
});

export const handleAddEditSteward = data => ({
  type: ADD_EDIT_STEWARD_SWITCH,
  payload: data,
});

export const handleAddEditHelpful = data => ({
  type: ADD_EDIT_HELPFUL_SWITCH,
  payload: data,
});

export const handleAddStory = data => ({
  type: GROW_ADD_BUTTON_STORY,
  payload: data,
});

export const handleSaveSteward = (data, callBack = () => {}) => ({
  type: GROW_SAVE_STEWARD,
  payload: data,
  callBack: callBack,
});

export const handleApplicationAdsSaveColor = data => ({
  type: GROW_APPLICATION_ADS_SAVE_COLOR,
  payload: data,
});
export const handleSaveHelpfulResources = data => ({
  type: GROW_SAVE_HELPFUL_RESOURCES,
  payload: data,
});

export const handleCreateStory = (data, callBack = () => {}) => ({
  type: GROW_STORY_CREATE,
  payload: data,
  callBack: callBack,
});

export const handelEditStory = data => ({
  type: GROW_EDIT_STORIES,
  payload: data,
});

export const handleEditTodo = data => ({
  type: GROW_EDIT_TODO,
  payload: data,
});
export const handleEditSteward = data => ({
  type: GROW_EDIT_STEWARD,
  payload: data,
});

export const handleEditHelpful = data => ({
  type: GROW_EDIT_HELPFUL,
  payload: data,
});

export const handelDeleteStory = data => ({
  type: GROW_DELETE_STORIES,
  payload: data,
});

export const handelDeleteTodo = data => ({
  type: GROW_DELETE_TODO,
  payload: data,
});

export const handelLoaderStory = data => ({
  type: GROW_FETCH_STORY_LOADER,
  payload: data,
});

export const handelLoaderHelpful = data => ({
  type: GROW_FETCH_HELPFUL_LOADER,
  payload: data,
});

export const handelLoaderToDo = data => ({
  type: GROW_FETCH_TODO_LOADER,
  payload: data,
});

export const handelLoaderSteward = data => ({
  type: GROW_FETCH_STEWARD_LOADER,
  payload: data,
});

export const handelDeleteSteward = data => ({
  type: GROW_DELETE_STEWARD,
  payload: data,
});

export const handelDeleteHelpful = data => ({
  type: GROW_DELETE_HELPFUL,
  payload: data,
});

// GROW TODOLIST

export const handelToDoListTabChange = data => ({
  type: GROW_TO_DO_LIST_TAB_CHANGE,
  payload: data,
});

export const handelCurriculumData = data => ({
  type: FETCH_SERIES_CURRICULUM,
  payload: data,
});

export const handelKidsData = data => ({
  type: FETCH_SERIES_KIDS,
  payload: data,
});

export const handelStoreCurriculumData = data => ({
  type: STORE_SERIES_CURRICULUM,
  payload: data,
});

export const handelStoreKidsData = data => ({
  type: STORE_SERIES_KIDS,
  payload: data,
});

export const handelToDoAddEdit = data => ({
  type: GROW_TO_DO_LIST_SAVE,
  payload: data,
});

export const handleLoaderAddEditHelpful = data => ({
  type: GROW_ADD_EDIT_HELPFUL_LOADER,
  payload: data,
});
export const handleLoaderAddEditStory = data => ({
  type: GROW_ADD_EDIT_STORY_LOADER,
  payload: data,
});
export const handleLoaderAddEditSteward = data => ({
  type: GROW_ADD_EDIT_STEWARDS_LOADER,
  payload: data,
});
export const handleLoaderAddEditTodo = data => ({
  type: GROW_ADD_EDIT_TODO_LOADER,
  payload: data,
});

export const handleEditTutorials = data => ({
  type: GROW_EDIT_TUTORIALS,
  payload: data,
});

export const handleAddTutorials = data => ({
  type: GROW_ADD_BUTTON_TUTORIALS,
  payload: data,
});
export const handelTutorialsTabChange = data => ({
  type: GROW_TUTORIALS_TAB_CHANGE,
  payload: data,
});
export const handleCreateTutorials = (data, callBack = () => {}) => ({
  type: GROW_TUTORIALS_CREATE,
  payload: data,
  callBack: callBack,
});

export const handelDeleteTutorial = ({ data, cb }) => ({
  type: GROW_DELETE_TUTORIAL,
  payload: data,
  cb: cb,
});

export const getTutorials = data => ({
  type: GROW_TUTORIALS,
  payload: data,
});

export const getTutorialList = data => ({
  type: GROW_TUTORIALS_LISTING,
  payload: data,
});
