import {
  ADD_EDIT_VOLUME_PAGE,
  ADD_EDIT_VOLUME_PAGE_ERROR,
  ADD_EDIT_VOLUME_PAGE_FOLDER,
  ADD_EDIT_VOLUME_PAGE_SUCCESS,
  DELETE_VOLUME_PAGES,
  DELETE_VOLUME_PAGES_ERROR,
  DELETE_VOLUME_PAGES_SUCCESS,
  DELETE_VOLUME_PAGE_FOLDER,
  DELETE_VOLUME_PAGE_FOLDER_SUCCESS,
  FETCH_CURRICULUM_CATEGORY_VOLUME_PAGE_SUCCESS,
  FETCH_CURRICULUM_VOLUME_PAGE_CATEGORY,
  FETCH_VOLUME_PAGES,
  FETCH_VOLUME_PAGES_ERROR,
  FETCH_VOLUME_PAGES_SUCCESS,
  FETCH_VOLUME_PAGE_FOLDERS,
  FETCH_VOLUME_PAGE_FOLDER_ERROR,
  FETCH_VOLUME_PAGE_FOLDER_SUCCESS,
  GET_VOLUME_PAGE_DETAILS,
  GET_VOLUME_PAGE_DETAILS_ERROR,
  GET_VOLUME_PAGE_DETAILS_SUCCESS,
  GET_VOLUME_PAGE_FOLDER_DETAILS,
  GET_VOLUME_PAGE_FOLDER_DETAILS_SUCCESS,
  LIST_ALL_CONTENT_TYPE_ID_AND_TITLE,
  LIST_ALL_CONTENT_TYPE_ID_AND_TITLE_SUCCESS,
  LIST_ALL_OPTIONS,
  LIST_ALL_OPTIONS_ERROR,
  LIST_ALL_OPTIONS_SUCCESS,
  PUBLISH_VOLUME_PAGES,
  PUBLISH_VOLUME_PAGES_ERROR,
  PUBLISH_VOLUME_PAGES_SUCCESS,
  SET_SEARCH,
  SET_SIZE_PER_VOLUME_PAGE,
  SET_SORT_FIELD,
  SET_SORT_ORDER,
  SET_VOLUME_PAGE,
} from "./actionTypes";

// add/updating PAGE
export const addEditPage = data => {
  return {
    type: ADD_EDIT_VOLUME_PAGE,
    payload: data,
  };
};

export const addEditPageSuccess = data => ({
  type: ADD_EDIT_VOLUME_PAGE_SUCCESS,
  payload: data,
});

export const addEditPageError = () => ({
  type: ADD_EDIT_VOLUME_PAGE_ERROR,
});

// fetching page
export const getPageDetails = data => {
  return {
    type: GET_VOLUME_PAGE_DETAILS,
    payload: data,
  };
};

export const getPageDetailsSuccess = data => {
  return {
    type: GET_VOLUME_PAGE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getPageDetailsError = data => {
  return {
    type: GET_VOLUME_PAGE_DETAILS_ERROR,
    payload: data,
  };
};

// fetching pages
export const fetchPages = () => {
  return {
    type: FETCH_VOLUME_PAGES,
  };
};

export const fetchVolumePagesSuccess = data => {
  return {
    type: FETCH_VOLUME_PAGES_SUCCESS,
    payload: data,
  };
};

export const fetchPagesError = data => {
  return {
    type: FETCH_VOLUME_PAGES_ERROR,
    payload: data,
  };
};

export const setSearch = data => ({
  type: SET_SEARCH,
  payload: data,
});

export const setPage = data => ({
  type: SET_VOLUME_PAGE,
  payload: data,
});

export const setSizePerPage = data => ({
  type: SET_SIZE_PER_VOLUME_PAGE,
  payload: data,
});

export const setSortField = data => ({
  type: SET_SORT_FIELD,
  payload: data,
});

export const setSortOrder = data => ({
  type: SET_SORT_ORDER,
  payload: data,
});

// deleting pages
export const deletePages = data => ({
  type: DELETE_VOLUME_PAGES,
  payload: data,
});

export const deletePagesSuccess = data => ({
  type: DELETE_VOLUME_PAGES_SUCCESS,
  payload: data,
});

export const deletePagesError = data => ({
  type: DELETE_VOLUME_PAGES_ERROR,
  payload: data,
});

// list all contentType id & title
export const listAllContentTypeIdAndTitle = data => {
  return {
    type: LIST_ALL_CONTENT_TYPE_ID_AND_TITLE,
    payload: data,
  };
};

export const listAllContentTypeIdAndTitleSuccess = data => {
  return {
    type: LIST_ALL_CONTENT_TYPE_ID_AND_TITLE_SUCCESS,
    payload: data,
  };
};

export const fetchCurriculumOption = data => {
  return {
    type: LIST_ALL_OPTIONS,
    payload: data,
  };
};

export const fetchCurriculumOptionSuccess = data => {
  return {
    type: LIST_ALL_OPTIONS_SUCCESS,
    payload: data,
  };
};
export const fetchCurriculumOptionError = () => {
  return {
    type: LIST_ALL_OPTIONS_ERROR,
  };
};

// fetching curriculum category
export const fetchCurriculumCategory = data => ({
  type: FETCH_CURRICULUM_VOLUME_PAGE_CATEGORY,
  payload: data,
});

export const fetchCurriculumCategorySuccess = data => {
  return {
    type: FETCH_CURRICULUM_CATEGORY_VOLUME_PAGE_SUCCESS,
    payload: data,
  };
};

// publish page
export const publishPages = data => {
  return {
    type: PUBLISH_VOLUME_PAGES,
    payload: data,
  };
};

export const publishPagesSuccess = data => ({
  type: PUBLISH_VOLUME_PAGES_SUCCESS,
  payload: data,
});

export const publishPagesError = () => ({
  type: PUBLISH_VOLUME_PAGES_ERROR,
});

// fatch page folder
export const fetchPageFolders = data => ({
  type: FETCH_VOLUME_PAGE_FOLDERS,
  payload: data,
});

export const fetchPageFolderSuccess = data => ({
  type: FETCH_VOLUME_PAGE_FOLDER_SUCCESS,
  payload: data,
});

export const fetchPageFolderError = data => ({
  type: FETCH_VOLUME_PAGE_FOLDER_ERROR,
  payload: data,
});

export const addEditPageFolder = data => ({
  type: ADD_EDIT_VOLUME_PAGE_FOLDER,
  payload: data.pageFolderDetails,
});

export const getPageFolderDetails = data => ({
  type: GET_VOLUME_PAGE_FOLDER_DETAILS,
  payload: data,
});

export const getPageFolderDetailsSuccess = data => {
  return {
    type: GET_VOLUME_PAGE_FOLDER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const deletePageFolder = data => ({
  type: DELETE_VOLUME_PAGE_FOLDER,
  payload: data,
});

export const deletePageFolderSuccess = data => {
  return {
    type: DELETE_VOLUME_PAGE_FOLDER_SUCCESS,
    payload: data,
  };
};
