import { axiosApiAccount } from "helpers/api_helper";
import { FEEDBACK_OPTION } from "helpers/url_helper";
import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchFeedbackOptionError,
  fetchFeedbackOptionSuccess,
} from "./actions";
import { FETCH_FEEDBACK_OPTION_LOADING } from "./actionTypes";

const fetchFeedBackOption = () => axiosApiAccount.get(FEEDBACK_OPTION);

function* FeedbackOptionSaga() {
  function* handleFetchFeedbackOption() {
    try {
      const res = yield call(fetchFeedBackOption);
      if (res?.status && res?.data?.data?.data) {
        yield put(fetchFeedbackOptionSuccess(res?.data?.data?.data));
      }
    } catch (err) {
      yield put(fetchFeedbackOptionError());
      toast.error(err?.response?.data?.message || err.message);
    }
  }

  yield takeEvery(FETCH_FEEDBACK_OPTION_LOADING, handleFetchFeedbackOption);
}

export default FeedbackOptionSaga;
