//KI-09-12-21
import {
  ADD_EDIT_GAME,
  ADD_EDIT_GAME_ERROR,
  ADD_EDIT_GAME_SUCCESS,
  ADD_NOTIFICATION,
  ADD_NOTIFICATION_ERROR,
  ADD_NOTIFICATION_SUCCESS,
  CLEAR_GAME_FEATURES,
  DELETE_GAME,
  DELETE_GAME_ERROR,
  DELETE_GAME_SUCCESS,
  //fLITERS
  FETCH_FILTER,
  FETCH_FILTER_ERROR,
  FETCH_FILTER_SUCCESS,
  FETCH_GAME,
  FETCH_GAME_ERROR,
  FETCH_GAME_SUCCESS,
  FETCH_GAME_USER,
  FETCH_GAME_USER_ERROR,
  FETCH_GAME_USER_SUCCESS,
  GET_GAME_DETAILS,
  GET_GAME_DETAILS_ERROR,
  GET_GAME_DETAILS_SUCCESS,
  SET_CATEGORY_ID_GAME,
  SET_PAGE_GAME,
  SET_PAGE_GAME_USER,
  SET_SEARCH_GAME,
  SET_SEARCH_GAME_USER,
  SET_SIZE_PER_PAGE_GAME,
  SET_SIZE_PER_PAGE_GAME_USER,
  SET_SORT_FILED_GAME,
  SET_SORT_ORDER_GAME,
} from "./actionTypes";

const initialState = {
  loading: false,
  games: [],
  totalSize: 0,
  search: "",
  sizePerPage: 25,
  is_system: 1,
  page: 1,
  sortField: "game_title",
  sortOrder: "desc",
  game: [],
  filter: [],
  categoryId: 0,
};

const gamesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_GAME:
      return {
        ...state,
        loading: true,
      };
    case SET_CATEGORY_ID_GAME:
      return {
        ...state,
        categoryId: payload,
      };

    case FETCH_GAME_SUCCESS:
      return {
        ...state,
        games: payload.rows,
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_GAME_ERROR:
      return {
        ...state,
        loading: false,
      };

    case FETCH_FILTER:
      return {
        ...state,
        loading: true,
      };

    case FETCH_FILTER_SUCCESS:
      return {
        ...state,
        filter: payload.data.rows,
        loading: false,
      };

    case FETCH_FILTER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_PAGE_GAME:
      return {
        ...state,
        page: payload,
      };

    case SET_SIZE_PER_PAGE_GAME:
      return {
        ...state,
        sizePerPage: payload,
      };
    case SET_SEARCH_GAME:
      return {
        ...state,
        search: "",
        search: payload,
      };
    case CLEAR_GAME_FEATURES:
      return {
        ...state,
        totalSize: 0,
        search: "",
        sizePerPage: 25,
        is_system: 1,
        page: 1,
        sortField: "game_title",
        sortOrder: "desc",
        categoryId: 0,
      };
    case ADD_EDIT_GAME:
      return {
        ...state,
        loading: true,
      };

    case ADD_EDIT_GAME_SUCCESS:
      return {
        ...state,
        games: [...state.games, payload],
      };

    case ADD_EDIT_GAME_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DELETE_GAME:
      return {
        ...state,
        loading: true,
      };
    case DELETE_GAME_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_GAME_ERROR:
      return {
        ...state,
        loading: false,
      };

    case FETCH_GAME_USER:
      return {
        ...state,
        loading: true,
      };

    case FETCH_GAME_USER_SUCCESS:
      return {
        ...state,
        games: payload.data.game.rows,
        loading: false,
        totalSize: payload.data.game.count,
      };
    case FETCH_GAME_USER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_PAGE_GAME_USER:
      return {
        ...state,
        page: payload,
      };

    case SET_SIZE_PER_PAGE_GAME_USER:
      return {
        ...state,
        sizePerPage: payload,
      };
    case SET_SEARCH_GAME_USER:
      return {
        ...state,
        search: "",
        search: payload,
      };

    case GET_GAME_DETAILS:
      return {
        ...state,
        loading: true,
      };

    case GET_GAME_DETAILS_SUCCESS:
      return {
        ...state,
        game: payload.data,
        loading: false,
      };

    case GET_GAME_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_SORT_FILED_GAME:
      return {
        ...state,
        sortField: action.payload,
      };
    case SET_SORT_ORDER_GAME:
      return {
        ...state,
        sortOrder: action.payload,
      };
    case ADD_NOTIFICATION:
      return {
        ...state,
        loading: true,
      };
    case ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_NOTIFICATION_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default gamesReducer;
