//listing announcement
export const LIST_ANNOUNCEMENT = "LIST_ANNOUNCEMENT";
export const LIST_ANNOUNCEMENT_SUCCESS = "LIST_ANNOUNCEMENT_SUCCESS";
export const LIST_ANNOUNCEMENT_ERROR = "LIST_ANNOUNCEMENT_ERROR";

//delete announcement
export const DELETE_ANNOUNCEMENT = "DELETE_ANNOUNCEMENT";
export const DELETE_ANNOUNCEMENT_SUCCESS = "DELETE_ANNOUNCEMENT_SUCCESS";
export const DELETE_ANNOUNCEMENT_ERROR = "DELETE_ANNOUNCEMENT_ERROR";

//linsting calander items
export const LIST_CALENDAR_ITEMS = "LIST_CALENDAR_ITEMS";
export const LIST_CALENDAR_ITEMS_SUCCESS = "LIST_CALENDAR_ITEMS_SUCCESS";
export const LIST_CALENDAR_ITEMS_ERROR = "LIST_CALENDAR_ITEMS_ERROR";

//delete calander items
export const DELETE_CALENDAR_ITEMS = "DELETE_CALENDAR_ITEMS";
export const DELETE_CALENDAR_ITEMS_SUCCESS = "DELETE_CALENDAR_ITEMS_SUCCESS";
export const DELETE_CALENDAR_ITEMS_ERROR = "DELETE_CALENDAR_ITEMS_ERROR";

// add edit Hub announcement
export const ADD_EDIT_HUB_ANNOUNCEMENT = "ADD_EDIT_HUB_ANNOUNCEMENT";
export const ADD_EDIT_HUB_ANNOUNCEMENT_SUCCESS =
  "ADD_EDIT_HUB_ANNOUNCEMENT_SUCCESS";
export const ADD_EDIT_HUB_ANNOUNCEMENT_ERROR =
  "ADD_EDIT_HUB_ANNOUNCEMENT_ERROR";

//add edit calander items

export const ADD_EDIT_HUB_CALENDAR_ITEMS = "ADD_EDIT_HUB_CALENDAR_ITEMS";
export const ADD_EDIT_HUB_CALENDAR_ITEMS_SUCCESS =
  "ADD_EDIT_HUB_CALENDAR_ITEMS_SUCCESS";
export const ADD_EDIT_HUB_CALENDAR_ITEMS_ERROR =
  "ADD_EDIT_HUB_CALENDAR_ITEMS_ERROR";

export const SET_CALENDAR_PAGE = "SET_USER_PAGE";
export const SET_CALENDAR_SIZE_PER_PAGE = "SET_USER_SIZE_PER_PAGE";

export const SET_ANNOUNCEMENT_PAGE = "SET_ANNOUNCEMENT_PAGE";
export const SET_ANNOUNCEMENT_SIZE_PER_PAGE = "SET_ANNOUNCEMENT_SIZE_PER_PAGE";

// List building blocks Message Builder
export const FETCH_BUILDING_BLOCKS_LISTING = "FETCH_BUILDING_BLOCKS_LISTING";
export const FETCH_BUILDING_BLOCKS_LISTING_SUCCESS =
  "FETCH_BUILDING_BLOCKS_LISTING_SUCCESS";
export const FETCH_BUILDING_BLOCKS_LISTING_ERROR =
  "FETCH_BUILDING_BLOCKS_LISTING_ERROR";

/** Message Builder Elements list */
export const FETCH_MB_ELEMENTS = "FETCH_MB_ELEMENTS";
export const FETCH_MB_ELEMENTS_SUCCESS = "FETCH_MB_ELEMENTS_SUCCESS";
export const FETCH_MB_ELEMENTS_ERROR = "FETCH_MB_ELEMENTS_ERROR";

/** Message Builder DUPLICATE */
export const MB_DUPLICATE_ELEMENT = "MB_DUPLICATE_ELEMENT";
export const MB_DUPLICATE_ELEMENT_SUCCESS = "MB_DUPLICATE_ELEMENT_SUCCESS";
export const MB_DUPLICATE_ELEMENT_ERROR = "MB_DUPLICATE_ELEMENT_ERROR";

/** Message Builder DELETE */
export const MB_DELETE_ELEMENT = "MB_DELETE_ELEMENT";
export const MB_DELETE_ELEMENT_SUCCESS = "MB_DELETE_ELEMENT_SUCCESS";
export const MB_DELETE_ELEMENT_ERROR = "MB_DELETE_ELEMENT_ERROR";

/** Message Builder save on change */
export const SAVE_ON_CHANGE = "SAVE_ON_CHANGE";
export const SAVE_ON_CHANGE_SUCCESS = "SAVE_ON_CHANGE_SUCCESS";
export const SAVE_ON_CHANGE_ERROR = "SAVE_ON_CHANGE_ERROR";

//Delete element from redux
export const DELETE_ELEMENT_REDUX = "DELETE_ELEMENT_REDUX";

// Handle Drag and Drop Index
export const HANDLE_MB_INDEX_SUCCESS = "HANDLE_MB_INDEX_SUCCESS";

// MBElements Sorting By Index
export const HANDLE_ELEMENT_INDEX_SUCCESS = "HANDLE_ELEMENT_INDEX_SUCCESS";
export const SET_VISIBLE_UPDATE = "SET_VISIBLE_UPDATE";

/** Message Builder Video Element */
export const MESSAGE_VIDEO_ELEMENT = "MESSAGE_VIDEO_ELEMENT";
export const MESSAGE_VIDEO_ELEMENT_SUCCESS = "MESSAGE_VIDEO_ELEMENT_SUCCESS";
export const MESSAGE_VIDEO_ELEMENT_ERROR = "MESSAGE_VIDEO_ELEMENT_ERROR";

/** App  Builder detail list */
export const FETCH_APP_BUILDER_DETAIL_LISTING =
  "FETCH_APP_BUILDER_DETAIL_LISTING";
export const FETCH_APP_BUILDER_DETAIL_LISTING_SUCCESS =
  "FETCH_APP_BUILDER_DETAIL_LISTING_SUCCESS";
export const FETCH_APP_BUILDER_DETAIL_LISTING_ERROR =
  "FETCH_APP_BUILDER_DETAIL_LISTING_ERROR";

/** To update redux when element is deleted */
export const SET_AB_SECTION = "SET_AB_SECTION";

//On/Off Slider App Elements
export const SET_VISIBLE_APP_UPDATE = "SET_VISIBLE_APP_UPDATE";

//Resources Grow Curriculum
export const FETCH_RESOURCE_CURR_DETAIL = "FETCH_RESOURCE_CURR_DETAIL";
export const FETCH_RESOURCE_CURR_DETAIL_SUCCESS =
  "FETCH_RESOURCE_CURR_DETAIL_SUCCESS";
export const FETCH_RESOURCE_CURR_DETAIL_ERROR =
  "FETCH_RESOURCE_CURR_DETAIL_ERROR";

export const UPDATE_RESOURCE_CURR_DETAIL = "UPDATE_RESOURCE_CURR_DETAIL";
export const UPDATE_RESOURCE_CURR_DETAIL_SUCCESS =
  "UPDATE_RESOURCE_CURR_DETAIL_SUCCESS";
export const UPDATE_RESOURCE_CURR_DETAIL_ERROR =
  "UPDATE_RESOURCE_CURR_DETAIL_ERROR";
