import {
  CLEAR_LOGS_FEATURES,
  FETCH_LOGS,
  FETCH_LOGS_ERROR,
  FETCH_LOGS_SUCCESS,
  SET_LOGS_FILTERS,
  SET_LOGS_PAGE,
  SET_LOGS_SEARCH,
  SET_LOGS_SIZE_PER_PAGE,
  SET_LOGS_SORT_FIELD,
  SET_LOGS_SORT_ORDER,
} from "./actionTypes";

export const fetchLogs = () => ({
  type: FETCH_LOGS,
});

export const fetchLogsSuccess = data => ({
  type: FETCH_LOGS_SUCCESS,
  payload: data,
});

export const fetchLogsError = () => ({
  type: FETCH_LOGS_ERROR,
});

export const setLogsSearch = data => ({
  type: SET_LOGS_SEARCH,
  payload: data,
});
export const setLogsPage = data => ({
  type: SET_LOGS_PAGE,
  payload: data,
});
export const setLogsSizePerPage = data => ({
  type: SET_LOGS_SIZE_PER_PAGE,
  payload: data,
});
export const setLogsSortField = data => ({
  type: SET_LOGS_SORT_FIELD,
  payload: data,
});
export const setLogsSortOrder = data => ({
  type: SET_LOGS_SORT_ORDER,
  payload: data,
});
export const setLogsFilters = data => ({
  type: SET_LOGS_FILTERS,
  payload: data,
});
export const clearLogsFeatures = () => ({
  type: CLEAR_LOGS_FEATURES,
});
