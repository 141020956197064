// Va 23 - 11 - 21
import { axiosApiAdmin } from "helpers/api_helper";
import {
  DELETE_COUPON,
  GET_COUPON,
  LIST_ALL_COUPONS,
  SAVE_COUPON,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  addEditCouponError,
  addEditCouponSuccess,
  deleteCouponError,
  fetchCoupon,
  fetchCouponByIdError,
  fetchCouponByIdSuccess,
  fetchCouponError,
  fetchCouponSuccess,
  setErrorMessage,
  setSuccessMessage,
} from "store/actions";
import {
  ADD_EDIT_COUPON,
  DELETE_COUPONS,
  FETCH_COUPON,
  GET_COUPON_DETAILS,
} from "./actionTypes";

const getCouponData = data => ({
  coupon_id: data?.coupon_id || "",
  coupon_code: data?.coupon_code || "",
  coupon_description: data?.coupon_description || "",
  rate_type: data?.rate_type || "",
  rate: data?.rate || 0,
  product_id:
    data?.sycu_coupons_products.map((product, i) => ({
      value: product.sycu_product.product_id,
      label: product.sycu_product.product_name,
    })) || "",
  coupon_expire_date_time: data?.coupon_expire_date_time || "",
  max_limit: data?.max_limit || 0,
  user_used_limit: data?.user_used_limit || 0,
  min_cart_amount: data?.min_cart_amount || 0,
});

const addEditCoupon = data => axiosApiAdmin.post(SAVE_COUPON, data);
const fetchCouponById = data => axiosApiAdmin.get(`${GET_COUPON}/${data}`);
const fetchCoupons = data => axiosApiAdmin.post(LIST_ALL_COUPONS, data);
const deleteCoupons = data => axiosApiAdmin.post(DELETE_COUPON, data);

function* handleAddEditCoupon({ payload }) {
  try {
    const res = yield call(addEditCoupon, payload.data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(addEditCouponSuccess());
      payload.callBack();
      yield put(fetchCoupon());
    }
  } catch (err) {
    yield put(addEditCouponError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleCouponDetail({ payload: { coupon_id } }) {
  try {
    const res = yield call(fetchCouponById, coupon_id);
    if (res?.data?.data) {
      yield put(fetchCouponByIdSuccess(getCouponData(res.data.data.rows)));
    }
  } catch (err) {
    yield put(fetchCouponByIdError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchCoupons({ payload: { coupon_filter } }) {
  const {
    couponPage,
    couponSearch,
    couponSizePerPage,
    couponSortField,
    couponSortOrder,
  } = yield select(state => state.Coupon);

  const { siteId } = yield select(state => state.Applications);

  const data = {
    page_no: couponPage,
    search: couponSearch,
    page_record: couponSizePerPage,
    sort_field: couponSortField,
    sort_order: couponSortOrder,
    site_id: siteId,
    coupon_filter: +coupon_filter,
  };
  try {
    const res = yield call(fetchCoupons, data);
    if (res?.data?.data) {
      yield put(fetchCouponSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchCouponError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteCoupon({ payload: { data, cb } }) {
  try {
    const res = yield call(deleteCoupons, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchCoupon());
      if (!!cb) cb();
    }
  } catch (err) {
    yield put(deleteCouponError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* couponSaga() {
  yield takeEvery(ADD_EDIT_COUPON, handleAddEditCoupon);
  yield takeEvery(GET_COUPON_DETAILS, handleCouponDetail);
  yield takeEvery(FETCH_COUPON, handleFetchCoupons);
  yield takeEvery(DELETE_COUPONS, handleDeleteCoupon);
}

export default couponSaga;
