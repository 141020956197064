import {
  FETCH_AVERAGE,
  FETCH_AVERAGE_DATA_ERROR,
  FETCH_AVERAGE_DATA_SUCCESS,
  FETCH_AVERAGE_PAGE,
  FETCH_AVERAGE_PAGE_DATA_ERROR,
  FETCH_AVERAGE_PAGE_DATA_SUCCESS,
  FETCH_BROWSER_DATA,
  FETCH_BROWSER_DATA_ERROR,
  FETCH_BROWSER_DATA_SUCCESS,
  FETCH_DEVICE_DATA,
  FETCH_DEVICE_DATA_ERROR,
  FETCH_DEVICE_DATA_SUCCESS,
  FETCH_LOCATION_DATA,
  FETCH_LOCATION_DATA_ERROR,
  FETCH_LOCATION_DATA_SUCCESS,
  FETCH_LOCATION_MAP_DATA_SUCCESS,
  FETCH_MODULE_X_AXIS_DATA,
  FETCH_OPERATING_SYSTEM_DATA,
  FETCH_OPERATING_SYSTEM_DATA_ERROR,
  FETCH_OPERATING_SYSTEM_DATA_SUCCESS,
  FETCH_PAGES,
  FETCH_PAGES_ERROR,
  FETCH_PAGES_SUCCESS,
  FETCH_PAGE_VIEWS,
  FETCH_PAGE_VIEWS_ERROR,
  FETCH_PAGE_VIEWS_SUCCESS,
  FETCH_SESSIONS,
  FETCH_SESSIONS_ERROR,
  FETCH_SESSIONS_SUCCESS,
  FETCH_TOTAL_VISIT_COUNT_DATA_SUCCESS,
  FETCH_TOTAL_VISIT_DATA,
  FETCH_TOTAL_VISIT_DATA_ERROR,
  FETCH_TOTAL_VISIT_DATA_SUCCESS,
  FETCH_UNIQUE_VISIT_COUNT_DATA_SUCCESS,
  FETCH_UNIQUE_VISIT_DATA,
  FETCH_UNIQUE_VISIT_DATA_ERROR,
  FETCH_UNIQUE_VISIT_DATA_SUCCESS,
  FETCH_X_AXIS_DATA,
  GET_BROWSER_DATA,
  SET_ACTIVE_TAB_DATA,
} from "./actionTypes";

//browser

export const getBrowserCardReport = (data, cb) => ({
  type: FETCH_BROWSER_DATA,
  payload: data,
  cb,
});

export const getBrowserCardReportSuccess = (data, cb) => ({
  type: FETCH_BROWSER_DATA_SUCCESS,
  payload: data,
  cb,
});

export const getBrowserCardReportError = data => ({
  type: FETCH_BROWSER_DATA_ERROR,
});

///average per session

export const getAveragePerSession = data => ({
  type: FETCH_AVERAGE,
  payload: data,
});

export const getAveragePerSessionSuccess = data => ({
  type: FETCH_AVERAGE_DATA_SUCCESS,
  payload: data,
});

export const getAveragePerSessionError = data => ({
  type: FETCH_AVERAGE_DATA_ERROR,
});

// average page per session

export const getAveragePagePerSession = data => ({
  type: FETCH_AVERAGE_PAGE,
  payload: data,
});

export const getAveragePagePerSessionSuccess = data => ({
  type: FETCH_AVERAGE_PAGE_DATA_SUCCESS,
  payload: data,
});

export const getAveragePagePerSessionError = data => ({
  type: FETCH_AVERAGE_PAGE_DATA_ERROR,
});

// location

export const getDataByLocation = data => ({
  type: FETCH_LOCATION_DATA,
  payload: data,
});

export const getDataByLocationSuccess = data => ({
  type: FETCH_LOCATION_DATA_SUCCESS,
  payload: data,
});

export const getDataByLocationError = data => ({
  type: FETCH_LOCATION_DATA_ERROR,
});

//page view

export const fetchPageView = (data, cb) => ({
  type: FETCH_PAGE_VIEWS,
  payload: data,
  cb,
});

export const fetchPageViewSuccess = (data, cb) => ({
  type: FETCH_PAGE_VIEWS_SUCCESS,
  payload: data,
  cb,
});

export const fetchPageViewError = message => ({
  type: FETCH_PAGE_VIEWS_ERROR,
  payload: message,
});

// Total Visit
export const getTotalVisitData = (data, cb) => ({
  type: FETCH_TOTAL_VISIT_DATA,
  payload: data,
  cb,
});

export const getTotalVisitDataSuccess = (data, cb) => ({
  type: FETCH_TOTAL_VISIT_DATA_SUCCESS,
  payload: data,
  cb,
});

export const getTotalVisitCountDataSuccess = data => ({
  type: FETCH_TOTAL_VISIT_COUNT_DATA_SUCCESS,
  payload: data,
});
// /FETCH_TOTAL_VISIT_COUNT_DATA_SUCCESS

export const getTotalVisitDataError = data => ({
  type: FETCH_TOTAL_VISIT_DATA_ERROR,
});

// Unique Visit
export const getUniqueVisitData = (data, cb) => ({
  type: FETCH_UNIQUE_VISIT_DATA,
  payload: data,
  cb,
});

export const getUniqueVisitDataSuccess = (data, cb) => ({
  type: FETCH_UNIQUE_VISIT_DATA_SUCCESS,
  payload: data,
  cb,
});

export const getUniqueVisitDataCountSuccess = data => ({
  type: FETCH_UNIQUE_VISIT_COUNT_DATA_SUCCESS,
  payload: data,
});

///FETCH_UNIQUE_VISIT_COUNT_DATA_SUCCESS

export const getUniqueVisitDataError = data => ({
  type: FETCH_UNIQUE_VISIT_DATA_ERROR,
});

//Chart XAxis Data
export const getXAxisData = data => ({
  type: FETCH_X_AXIS_DATA,
  payload: data,
});

//Module Chart XAxis Data
export const getModuleXAxisData = data => ({
  type: FETCH_MODULE_X_AXIS_DATA,
  payload: data,
});

// os

export const getOperatingCardReport = (data, cb) => ({
  type: FETCH_OPERATING_SYSTEM_DATA,
  payload: data,
  cb,
});

export const getOperatingCardReporSuccess = (data, cb) => ({
  type: FETCH_OPERATING_SYSTEM_DATA_SUCCESS,
  payload: data,
  cb,
});
export const getOperatingCardReporError = data => ({
  type: FETCH_OPERATING_SYSTEM_DATA_ERROR,
});
//location
export const getLocationData = (data, cb) => ({
  type: FETCH_LOCATION_DATA,
  payload: data,
  cb,
});

export const getLocationDataSuccess = (data, cb) => ({
  type: FETCH_LOCATION_DATA_SUCCESS,
  payload: data,
  cb,
});

export const getLocationMapDataSuccess = data => ({
  type: FETCH_LOCATION_MAP_DATA_SUCCESS,
  payload: data,
});

export const getLocationDataError = data => ({
  type: FETCH_LOCATION_DATA_ERROR,
  payload: data,
});

export const getDeviceData = (data, cb) => ({
  type: FETCH_DEVICE_DATA,
  payload: data,
  cb,
});

export const getDeviceDataSuccess = (data, cb) => ({
  type: FETCH_DEVICE_DATA_SUCCESS,
  payload: data,
  cb,
});

export const getDeviceDataError = data => ({
  type: FETCH_DEVICE_DATA_ERROR,
  payload: data,
});

//pages

export const fetchSinglePages = (data, cb) => ({
  type: FETCH_PAGES,
  payload: data,
  cb,
});
export const fetchSinglePagesSuccess = data => ({
  type: FETCH_PAGES_SUCCESS,
  payload: data,
});
export const fetchSinglePagesError = message => ({
  type: FETCH_PAGES_ERROR,
  payload: message,
});

//sessions

export const getSessionsData = (data, cb) => ({
  type: FETCH_SESSIONS,
  payload: data,
  cb,
});
export const getSessionsDataSuccess = (data, cb) => ({
  type: FETCH_SESSIONS_SUCCESS,
  payload: data,
  cb,
});
export const getSessionsDataError = message => ({
  type: FETCH_SESSIONS_ERROR,
  payload: message,
});
export const getBrowserData = (data, cb) => ({
  type: GET_BROWSER_DATA,
  payload: data,
  cb,
});

export const handleActiveTab = data => ({
  type: SET_ACTIVE_TAB_DATA,
  payload: data,
});
