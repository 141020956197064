import { compareTypes } from "modules/AnalyticsDashboard/constants";
import DateRange from "modules/AnalyticsDashboard/utils";
import moment from "moment";

const {
  FETCH_DISPUTED_TRANSACTIONS,
  FETCH_DISPUTED_TRANSACTIONS_SUCCESS,
  FETCH_DISPUTED_TRANSACTIONS_ERROR,
  SET_SEARCH_DISPUTED_TRANSACTIONS,
  SET_SORT_ORDER_DISPUTED_TRANSACTIONS,
  SET_SORT_FIELD_DISPUTED_TRANSACTIONS,
  SET_SIZE_PER_PAGE_DISPUTED_TRANSACTIONS,
  SET_PAGE_DISPUTED_TRANSACTIONS,
  SET_ORDER_RANGE_DATE_DISPUTED_TRANSACTIONS,
} = require("./actionType");

const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();
const defaultCompareType = compareTypes.PERIOD;

const initialState = {
  range: {
    startDate,
    endDate,
  },

  compareToPrevious: defaultCompareType,
  comparedRange: new DateRange(startDate, endDate).getPreviousDates(
    defaultCompareType
  ),
  by: "day",
  loadingDisputedTransactions: false,

  disputedTransactionsTableData: {
    loading: false,
    rows: [],
    totalSize: 0,
    search: "",
    page: 1,
    sizePerPage: 25,
    sortOrder: "DESC",
    sortField: "created_date",
  },
  tz: "-0400",
};

const change = (key, value, state) => ({
  ...state,
  disputedTransactionsTableData: {
    ...state.disputedTransactionsTableData,
    [key]: value,
  },
});

const DisputedTransactions = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_DISPUTED_TRANSACTIONS:
      return change("loading", true, state);

    case FETCH_DISPUTED_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        disputedTransactionsTableData: {
          ...state.disputedTransactionsTableData,
          loading: false,
          rows: payload.rows,
          totalSize: payload.totalSize,
        },
      };

    case FETCH_DISPUTED_TRANSACTIONS_ERROR:
      return change("loading", false, state);

    case SET_SEARCH_DISPUTED_TRANSACTIONS:
      return change("search", payload, state);

    case SET_PAGE_DISPUTED_TRANSACTIONS:
      return change("page", payload, state);

    case SET_SIZE_PER_PAGE_DISPUTED_TRANSACTIONS:
      return change("sizePerPage", payload, state);

    case SET_SORT_FIELD_DISPUTED_TRANSACTIONS:
      return change("sortField", payload, state);

    case SET_SORT_ORDER_DISPUTED_TRANSACTIONS:
      return change("sortOrder", payload, state);

    case SET_ORDER_RANGE_DATE_DISPUTED_TRANSACTIONS:
      const prevBy = state.by;
      const newBy = new DateRange(
        payload.startDate,
        payload.endDate
      ).getByOptions()[0].value;
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
        comparedRange: new DateRange(
          payload.startDate,
          payload.endDate
        ).getPreviousDates(state.compareToPrevious),
        ...(prevBy !== newBy ? { by: newBy } : {}),
      };

    default:
      return state;
  }
};

export default DisputedTransactions;
