import { axiosApiAdmin } from "helpers/api_helper";

import {
  ADD_UPDATE_REDIRECTION_URL,
  CREATE_UPDATE_REDIRECT_FOLDER_URL,
  DELETE_REDIRECTION_LINK_URL,
  DELETE_REDIRECT_FOLDER_URL,
  REDIRECTION_LIST_URL,
  REDIRECT_FOLDER_LIST_URL,
  STATUS_REDIRECTION_LINK_URL,
  UPDATE_REDIRECTION_FOLDER,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  addUpdateRedirectionError,
  addUpdateRedirectionSuccess,
  createRedirectionFolderError,
  createRedirectionFolderSuccess,
  deleteRedirectionFolderError,
  deleteRedirectionFolderSuccess,
  fetchRedirectFolderListError,
  fetchRedirectFolderListSuccess,
  fetchRedirectionLink,
  fetchRedirectionLinkError,
  fetchRedirectionLinkSuccess,
  setErrorMessage,
  setStatusRedirectionLinkSuccess,
  setSuccessMessage,
  updateRedirectionFolderError,
  updateRedirectionFolderSuccess,
} from "store/actions";
import {
  ADD_UPDATE_REDIRECTION,
  ADD_UPDATE_REDIRECTION_FOLDER,
  CREATE_REDIRECT_FOLDER_LIST,
  DELETE_REDIRECTION_LINK,
  DELETE_REDIRECT_FOLDER_LIST,
  FETCH_REDIRECTION_LIST,
  FETCH_REDIRECT_FOLDER_LIST,
  SET_STATUS_REDIRECTION_LINK,
} from "./actionTypes";

const redirectionApiCall = payload =>
  axiosApiAdmin.post(REDIRECTION_LIST_URL, payload);
const addUpdateRedirectionCall = payload =>
  axiosApiAdmin.put(ADD_UPDATE_REDIRECTION_URL, payload);
const statusRedirectionCall = payload =>
  axiosApiAdmin.put(STATUS_REDIRECTION_LINK_URL, payload);
const deleteRedirectionCall = page_link_id =>
  axiosApiAdmin.delete(`${DELETE_REDIRECTION_LINK_URL}/${page_link_id}`);
const redirectFolderListApiCall = payload =>
  axiosApiAdmin.post(REDIRECT_FOLDER_LIST_URL, payload);
const createUpdateFolderApiCall = payload =>
  axiosApiAdmin.post(CREATE_UPDATE_REDIRECT_FOLDER_URL, payload);
const deleteFolderApiCall = redirection_folder_id =>
  axiosApiAdmin.delete(
    `${DELETE_REDIRECT_FOLDER_URL}/${redirection_folder_id}`
  );
const addUpdateRedirectionFolderCall = payload =>
  axiosApiAdmin.post(UPDATE_REDIRECTION_FOLDER, payload);

function* handleFetchRedirectionList(data) {
  try {
    const { search, page, sizePerPage, siteId } = yield select(
      state => state.Redirections
    );
    const res = yield call(redirectionApiCall, {
      search,
      page_no: page,
      page_record: sizePerPage,
      site_id: siteId,
      redirection_folder_id: data.payload,
    });
    if (res.status && res.data?.data) {
      yield put(fetchRedirectionLinkSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchRedirectionLinkError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleAddUpdateRedirection({ payload: { payload, callBack } }) {
  try {
    const res = yield call(addUpdateRedirectionCall, payload);
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchRedirectionLink(payload.redirection_folder_id));
      yield put(addUpdateRedirectionSuccess());
      callBack(payload);
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
    yield put(addUpdateRedirectionError());
  }
}
function* handleStatusRedirectionLink({ payload }) {
  try {
    const res = yield call(statusRedirectionCall, payload);
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(setStatusRedirectionLinkSuccess(payload));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleDeleteRedirectionLink({
  payload: { deletePageLinkId, cb, activeFolder },
}) {
  try {
    const res = yield call(deleteRedirectionCall, deletePageLinkId);
    if (res.status) {
      cb();
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchRedirectionLink(activeFolder));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleFetchRedirectFolderList() {
  try {
    const res = yield call(redirectFolderListApiCall, {});
    if (res.status && res.data?.data) {
      yield put(fetchRedirectFolderListSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchRedirectFolderListError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleCreateUpdateRedirectFolder(data) {
  try {
    const res = yield call(createUpdateFolderApiCall, data.payload);
    if (res.status && res.data?.data) {
      yield put(createRedirectionFolderSuccess(res.data.data));
      data.payload.cb(1);
    }
  } catch (err) {
    yield put(createRedirectionFolderError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteRedirectFolder(data) {
  try {
    const res = yield call(
      deleteFolderApiCall,
      data.payload.redirection_folder_id
    );
    if (res.status && res.data?.data) {
      yield put(deleteRedirectionFolderSuccess(res.data.data));
      data.payload.cb(0);
    }
  } catch (err) {
    yield put(deleteRedirectionFolderError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleupdateRedirectionFolder(data) {
  try {
    const res = yield call(addUpdateRedirectionFolderCall, data.payload);
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(updateRedirectionFolderSuccess());
      data.payload.cbLink();
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
    yield put(updateRedirectionFolderError());
  }
}

function* redirectionsSaga() {
  yield takeEvery(FETCH_REDIRECTION_LIST, handleFetchRedirectionList);
  yield takeEvery(ADD_UPDATE_REDIRECTION, handleAddUpdateRedirection);
  yield takeEvery(SET_STATUS_REDIRECTION_LINK, handleStatusRedirectionLink);
  yield takeEvery(DELETE_REDIRECTION_LINK, handleDeleteRedirectionLink);
  yield takeEvery(FETCH_REDIRECT_FOLDER_LIST, handleFetchRedirectFolderList);
  yield takeEvery(
    CREATE_REDIRECT_FOLDER_LIST,
    handleCreateUpdateRedirectFolder
  );
  yield takeEvery(DELETE_REDIRECT_FOLDER_LIST, handleDeleteRedirectFolder);
  yield takeEvery(ADD_UPDATE_REDIRECTION_FOLDER, handleupdateRedirectionFolder);
}

export default redirectionsSaga;
