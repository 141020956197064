// Va 05 - 01 - 21
import { compareTypes } from "modules/AnalyticsDashboard/constants";
import DateRange from "modules/AnalyticsDashboard/utils";
import moment from "moment";
import {
  CLEAR_ACTIVE_SUBSCRIPTION,
  FATCH_LIST_ACTIVE_SUBSCRIPTION,
  FATCH_LIST_ACTIVE_SUBSCRIPTION_ERROR,
  FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT_NULL,
  FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT_SUCCESS,
  FATCH_LIST_ACTIVE_SUBSCRIPTION_SUCCESS,
  SET_ORDER_RANGE_DATE_ACTIVE_SUBSCRIPTION,
  SET_PAGE_ACTIVE_SUBSCRIPTION,
  SET_SEARCH_ACTIVE_SUBSCRIPTION,
  SET_SIZE_PER_PAGE_ACTIVE_SUBSCRIPTION,
  SET_SORT_FIELD_ACTIVE_SUBSCRIPTION,
  SET_SORT_ORDER_ACTIVE_SUBSCRIPTION,
} from "./actionTypes";
const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();
const defaultCompareType = compareTypes.PERIOD;

const initialState = {
  range: {
    startDate,
    endDate,
  },

  compareToPrevious: defaultCompareType,
  comparedRange: new DateRange(startDate, endDate).getPreviousDates(
    defaultCompareType
  ),
  by: "day",

  activeSubscription: [],
  loading: false,
  totalSize: 0,
  sizePerPage: 10,
  search: "",
  page: 1,
  sortField: "created_datetime",
  sortOrder: "DESC",
  subscriptionReportExport: [],
  tz: "-0400",
};

const ActiveSubscriptionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        activeSubscription: [],
      };
    case FATCH_LIST_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        loading: true,
      };
    case FATCH_LIST_ACTIVE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        activeSubscription: payload.rows,
        totalSize: payload.count,
      };
    case FATCH_LIST_ACTIVE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_PAGE_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        page: payload,
      };

    case SET_SIZE_PER_PAGE_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        sizePerPage: payload,
      };

    case SET_SEARCH_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        search: "",
        search: payload,
      };

    case SET_SORT_FIELD_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        sortField: action.payload,
      };

    case SET_SORT_ORDER_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        sortOrder: action.payload,
      };
    case FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT_SUCCESS:
      return {
        ...state,
        subscriptionReportExport: action.payload,
      };
    case FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT_NULL:
      return {
        ...state,
        subscriptionReportExport: [],
      };
    case SET_ORDER_RANGE_DATE_ACTIVE_SUBSCRIPTION:
      const prevBy = state.by;
      const newBy = new DateRange(
        payload.startDate,
        payload.endDate
      ).getByOptions()[0].value;
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
        comparedRange: new DateRange(
          payload.startDate,
          payload.endDate
        ).getPreviousDates(state.compareToPrevious),
        ...(prevBy !== newBy ? { by: newBy } : {}),
      };
    default:
      return state;
  }
};

export default ActiveSubscriptionReducer;
