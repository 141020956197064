import {
  ADD_EDIT_HUB_ANNOUNCEMENT,
  ADD_EDIT_HUB_ANNOUNCEMENT_ERROR,
  ADD_EDIT_HUB_ANNOUNCEMENT_SUCCESS,
  ADD_EDIT_HUB_CALENDAR_ITEMS,
  ADD_EDIT_HUB_CALENDAR_ITEMS_ERROR,
  ADD_EDIT_HUB_CALENDAR_ITEMS_SUCCESS,
  DELETE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT_ERROR,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_CALENDAR_ITEMS,
  DELETE_CALENDAR_ITEMS_ERROR,
  DELETE_CALENDAR_ITEMS_SUCCESS,
  DELETE_ELEMENT_REDUX,
  FETCH_APP_BUILDER_DETAIL_LISTING,
  FETCH_APP_BUILDER_DETAIL_LISTING_ERROR,
  FETCH_APP_BUILDER_DETAIL_LISTING_SUCCESS,
  FETCH_BUILDING_BLOCKS_LISTING,
  FETCH_BUILDING_BLOCKS_LISTING_ERROR,
  FETCH_BUILDING_BLOCKS_LISTING_SUCCESS,
  FETCH_MB_ELEMENTS,
  FETCH_MB_ELEMENTS_ERROR,
  FETCH_MB_ELEMENTS_SUCCESS,
  FETCH_RESOURCE_CURR_DETAIL,
  FETCH_RESOURCE_CURR_DETAIL_ERROR,
  FETCH_RESOURCE_CURR_DETAIL_SUCCESS,
  HANDLE_ELEMENT_INDEX_SUCCESS,
  HANDLE_MB_INDEX_SUCCESS,
  LIST_ANNOUNCEMENT,
  LIST_ANNOUNCEMENT_ERROR,
  LIST_ANNOUNCEMENT_SUCCESS,
  LIST_CALENDAR_ITEMS,
  LIST_CALENDAR_ITEMS_ERROR,
  LIST_CALENDAR_ITEMS_SUCCESS,
  MB_DELETE_ELEMENT,
  MB_DELETE_ELEMENT_ERROR,
  MB_DELETE_ELEMENT_SUCCESS,
  MB_DUPLICATE_ELEMENT,
  MB_DUPLICATE_ELEMENT_ERROR,
  MB_DUPLICATE_ELEMENT_SUCCESS,
  MESSAGE_VIDEO_ELEMENT,
  MESSAGE_VIDEO_ELEMENT_ERROR,
  MESSAGE_VIDEO_ELEMENT_SUCCESS,
  SAVE_ON_CHANGE,
  SAVE_ON_CHANGE_ERROR,
  SAVE_ON_CHANGE_SUCCESS,
  SET_AB_SECTION,
  SET_ANNOUNCEMENT_PAGE,
  SET_ANNOUNCEMENT_SIZE_PER_PAGE,
  SET_CALENDAR_PAGE,
  SET_CALENDAR_SIZE_PER_PAGE,
  SET_VISIBLE_APP_UPDATE,
  SET_VISIBLE_UPDATE,
} from "./actionTypes";

export const addEditHubAnnouncement = data => ({
  type: ADD_EDIT_HUB_ANNOUNCEMENT,
  payload: data,
});

export const addEditHubAnnouncementSuccess = data => ({
  type: ADD_EDIT_HUB_ANNOUNCEMENT_SUCCESS,
  payload: data,
});

export const addEditHubAnnouncementError = () => ({
  type: ADD_EDIT_HUB_ANNOUNCEMENT_ERROR,
});

export const listAnnouncement = data => ({
  type: LIST_ANNOUNCEMENT,
  payload: data,
});

export const listAnnouncementSuccess = data => ({
  type: LIST_ANNOUNCEMENT_SUCCESS,
  payload: data,
});

export const listAnnouncementError = () => ({
  type: LIST_ANNOUNCEMENT_ERROR,
});

export const listCalendarItems = data => ({
  type: LIST_CALENDAR_ITEMS,
  payload: data,
});

export const listCalendarItemsSuccess = data => ({
  type: LIST_CALENDAR_ITEMS_SUCCESS,
  payload: data,
});

export const listCalendarItemsError = () => ({
  type: LIST_CALENDAR_ITEMS_ERROR,
});

export const deleteAnnouncement = data => ({
  type: DELETE_ANNOUNCEMENT,
  payload: data,
});

export const deleteAnnouncementSuccess = data => ({
  type: DELETE_ANNOUNCEMENT_SUCCESS,
  payload: data,
});

export const deleteAnnouncementError = () => ({
  type: DELETE_ANNOUNCEMENT_ERROR,
});

export const deleteCalendarItems = data => ({
  type: DELETE_CALENDAR_ITEMS,
  payload: data,
});

export const deleteCalendarItemsSuccess = data => ({
  type: DELETE_CALENDAR_ITEMS_SUCCESS,
  payload: data,
});

export const deleteCalendarItemsError = () => ({
  type: DELETE_CALENDAR_ITEMS_ERROR,
});

export const addEditHubCalendarItems = data => ({
  type: ADD_EDIT_HUB_CALENDAR_ITEMS,
  payload: data,
});

export const addEditHubCalendarItemsSuccess = data => ({
  type: ADD_EDIT_HUB_CALENDAR_ITEMS_SUCCESS,
  payload: data,
});

export const addEditHubCalendarItemsError = () => ({
  type: ADD_EDIT_HUB_CALENDAR_ITEMS_ERROR,
});

export const setCalenderPage = data => ({
  type: SET_CALENDAR_PAGE,
  payload: data,
});

export const setCalenderSizePerPage = data => ({
  type: SET_CALENDAR_SIZE_PER_PAGE,
  payload: data,
});

export const setAnnouncementPage = data => ({
  type: SET_ANNOUNCEMENT_PAGE,
  payload: data,
});

export const setAnnouncementSizePerPage = data => ({
  type: SET_ANNOUNCEMENT_SIZE_PER_PAGE,
  payload: data,
});

//MB building block Listing
export const fetchBuildingBlockListing = data => {
  return {
    type: FETCH_BUILDING_BLOCKS_LISTING,
    payload: data,
  };
};
export const fetchBuildingBlockListingSuccess = data => {
  return {
    type: FETCH_BUILDING_BLOCKS_LISTING_SUCCESS,
    payload: data,
  };
};
export const fetchBuildingBlockListingError = data => {
  return {
    type: FETCH_BUILDING_BLOCKS_LISTING_ERROR,
    payload: data,
  };
};

//MB Elements Listing
export const fetchMBElements = data => {
  return {
    type: FETCH_MB_ELEMENTS,
    payload: data,
  };
};
export const fetchMBElementsSuccess = data => {
  return {
    type: FETCH_MB_ELEMENTS_SUCCESS,
    payload: data,
  };
};
export const fetchMBElementsError = data => {
  return {
    type: FETCH_MB_ELEMENTS_ERROR,
    payload: data,
  };
};
//MB Element duplicate
export const MBDuplicateElement = data => {
  return {
    type: MB_DUPLICATE_ELEMENT,
    payload: data,
  };
};
export const MBDuplicateElementSuccess = data => {
  return {
    type: MB_DUPLICATE_ELEMENT_SUCCESS,
    payload: data,
  };
};
export const MBDuplicateElementError = data => {
  return {
    type: MB_DUPLICATE_ELEMENT_ERROR,
    payload: data,
  };
};
//MB Delete
export const MBDeleteElement = data => {
  return {
    type: MB_DELETE_ELEMENT,
    payload: data,
  };
};
export const MBDeleteElementSuccess = data => {
  return {
    type: MB_DELETE_ELEMENT_SUCCESS,
    payload: data,
  };
};
export const MBDeleteElementError = data => {
  return {
    type: MB_DELETE_ELEMENT_ERROR,
    payload: data,
  };
};
//Message builder Save on change
export const saveOnChangeMB = data => {
  return {
    type: SAVE_ON_CHANGE,
    payload: data,
  };
};
export const saveOnChangeMBSuccess = data => {
  return {
    type: SAVE_ON_CHANGE_SUCCESS,
    payload: data,
  };
};
export const saveOnChangeMBError = data => {
  return {
    type: SAVE_ON_CHANGE_ERROR,
    payload: data,
  };
};
//DELETE element from redux MB
export const deleteElementRedux = data => {
  return {
    type: DELETE_ELEMENT_REDUX,
    payload: data,
  };
};

//On/Off Slider Message Elements
export const setVisableUpdate = data => {
  return {
    type: SET_VISIBLE_UPDATE,
    payload: data,
  };
};

export const handleMBIndexSuccess = data => {
  return {
    type: HANDLE_MB_INDEX_SUCCESS,
    payload: data,
  };
};

export const handleElementIndexSuccess = data => {
  return {
    type: HANDLE_ELEMENT_INDEX_SUCCESS,
    payload: data,
  };
};

/** Message Builder Video Element */
export const messageVideoElement = (data, cb) => {
  return {
    type: MESSAGE_VIDEO_ELEMENT,
    payload: { data, cb },
  };
};
export const messageVideoElementSuccess = data => {
  return {
    type: MESSAGE_VIDEO_ELEMENT_SUCCESS,
    payload: data,
  };
};
export const messageVideoElementError = data => {
  return {
    type: MESSAGE_VIDEO_ELEMENT_ERROR,
    payload: data,
  };
};
//AppBuilderDetail Listing
export const fetchAppBuilderDetailListing = (data, cb) => {
  return {
    type: FETCH_APP_BUILDER_DETAIL_LISTING,
    payload: { data, cb },
  };
};
export const fetchAppBuilderDetailListingSuccess = data => {
  return {
    type: FETCH_APP_BUILDER_DETAIL_LISTING_SUCCESS,
    payload: data,
  };
};
export const fetchAppBuilderDetailListingError = data => {
  return {
    type: FETCH_APP_BUILDER_DETAIL_LISTING_ERROR,
    payload: data,
  };
};
//Delete section from redux
export const setABSection = data => {
  return {
    type: SET_AB_SECTION,
    payload: data,
  };
};

//On/Off Slider App Elements
export const setVisibleAppUpdate = data => {
  return {
    type: SET_VISIBLE_APP_UPDATE,
    payload: data,
  };
};

export const getResourcesElementsDetail = data => {
  return {
    type: FETCH_RESOURCE_CURR_DETAIL,
    payload: data,
  };
};
export const getResourcesElementsDetailSuccess = data => {
  return {
    type: FETCH_RESOURCE_CURR_DETAIL_SUCCESS,
    payload: data,
  };
};
export const getResourcesElementsDetailError = data => {
  return {
    type: FETCH_RESOURCE_CURR_DETAIL_ERROR,
    payload: data,
  };
};
