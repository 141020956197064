import { axiosApiAdmin } from "helpers/api_helper";
import {
  DELETE_PAGE,
  DELETE_SINGLE_PAGE_FOLDERS,
  GET_ALL_CONTENT_TYPE,
  GET_PAGE,
  GET_SINGLE_PAGE_FOLDERS_DETAILS,
  LIST_ALL_PAGES,
  LIST_ALL_PAGE_FOLDERS,
  PUBLISH_PAGE,
  SAVE_PAGE,
  SAVE_PAGE_FOLDERS,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { setErrorMessage, setSuccessMessage } from "store/actions";
import {
  ADD_EDIT_VOLUME_PAGE,
  ADD_EDIT_VOLUME_PAGE_FOLDER,
  DELETE_VOLUME_PAGES,
  DELETE_VOLUME_PAGE_FOLDER,
  FETCH_VOLUME_PAGES,
  FETCH_VOLUME_PAGE_FOLDERS,
  GET_VOLUME_PAGE_DETAILS,
  GET_VOLUME_PAGE_FOLDER_DETAILS,
  LIST_ALL_CONTENT_TYPE_ID_AND_TITLE,
  LIST_ALL_OPTIONS,
  PUBLISH_VOLUME_PAGES,
} from "./actionTypes";

import {
  addEditPageError,
  addEditPageSuccess,
  deletePagesError,
  fetchCurriculumOptionSuccess,
  fetchPageFolders,
  fetchPageFolderSuccess,
  fetchPages,
  fetchPagesError,
  fetchVolumePagesSuccess,
  getPageDetailsError,
  getPageDetailsSuccess,
  getPageFolderDetailsSuccess,
  listAllContentTypeIdAndTitleSuccess,
  publishPagesError,
} from "modules/tools/store/volume-product/actions";

const addEditPage = data => axiosApiAdmin.post(SAVE_PAGE, data);
const fetchPageDetails = data => axiosApiAdmin.get(`${GET_PAGE}/${data}`);
const fetchPageList = data => axiosApiAdmin.post(LIST_ALL_PAGES, data);
const deletePage = data => axiosApiAdmin.delete(`${DELETE_PAGE}/${data}`);
const fetchContentTypeListIdAndTitle = data =>
  axiosApiAdmin.post(GET_ALL_CONTENT_TYPE, data);
const publishPageApi = data => axiosApiAdmin.post(PUBLISH_PAGE, data);
const fetchPageListFolders = data =>
  axiosApiAdmin.get(LIST_ALL_PAGE_FOLDERS, data);
const addEditPageFolder = data => axiosApiAdmin.post(SAVE_PAGE_FOLDERS, data);
const getPageFolderDetails = data =>
  axiosApiAdmin.get(`${GET_SINGLE_PAGE_FOLDERS_DETAILS}/${data}`);
const deleteSinglePageFolder = data =>
  axiosApiAdmin.get(`${DELETE_SINGLE_PAGE_FOLDERS}/${data}`);

let _itemsFolder = [];

function itemsFolder(
  data,
  parent = null,
  dummy = false,
  level = null,
  step = null
) {
  let dash = "",
    space = "";
  for (let i = 0; i < level; i++) {
    space += "  ";
    dash += "-";
    step += 3;
  }
  dash = space + dash;
  if (dummy) {
    dummy.forEach(eles => {
      _itemsFolder.push({
        page_folder_id: eles.page_folder_id,
        folder_name: dash + " " + eles.folder_name,
        parent_title: parent,
        parent_id: eles.parent_folder_id,
        level: level,
        dash: dash,
        step: step,
      });
      if (Array.isArray(eles.child_folder) && eles.child_folder.length !== 0)
        itemsFolder(data, eles.folder_name, eles.child_folder, ++level, step);
    });
  } else {
    data.forEach(eles => {
      if (eles.parent_folder_id === 0) {
        level = null;
        _itemsFolder.push({
          page_folder_id: eles.page_folder_id,
          folder_name: dash + " " + eles.folder_name,
          parent_title: parent,
          parent_id: eles.parent_folder_id,
          level: level,
          dash: dash,
          step: step,
        });
        if (Array.isArray(eles.child_folder) && eles.child_folder.length !== 0)
          itemsFolder(data, eles.folder_name, eles.child_folder, ++level, step);
      }
    });
  }
  return _itemsFolder;
}

function* handleAddEditPage({ payload: { pageDetail, cb } }) {
  try {
    const res = yield call(addEditPage, pageDetail);
    if (res.data) {
      yield put(addEditPageSuccess());
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchPages());
      cb();
    }
  } catch (err) {
    yield put(addEditPageError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchPages() {
  const {
    types: { MinistryType, catActiveTab },
  } = yield select(state => state.VolumeReducer);

  const data = {
    category_id: catActiveTab,
    ministry_type: MinistryType,
  };
  try {
    const res = yield call(fetchPageList, data);
    if (res?.data?.data) {
      yield put(fetchVolumePagesSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchPagesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handlePageDetail({ payload: { page_id } }) {
  try {
    const res = yield call(fetchPageDetails, page_id);
    if (res?.data?.data) {
      yield put(getPageDetailsSuccess(res.data.data.page));
    }
  } catch (err) {
    yield put(getPageDetailsError());
    yield put(getPageDetailsSuccess());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeletePages({ payload: { page_id, page_folder_id } }) {
  try {
    const res = yield call(deletePage, page_id);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchPages());
    }
  } catch (err) {
    yield put(deletePagesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleListAllContentTypeIdAndTitle({ payload }) {
  try {
    const res = yield call(fetchContentTypeListIdAndTitle, payload);
    if (res.data?.data) {
      yield put(listAllContentTypeIdAndTitleSuccess(res.data));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleSelectOptions({ payload }) {
  try {
    const res = yield call(fetchContentTypeListIdAndTitle, payload);
    if (res.data?.data) {
      yield put(
        fetchCurriculumOptionSuccess({
          data: {
            content_type_list: res.data.data.content_type_list,
          },
        })
      );
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handlePublishPages({ payload: { data, cb } }) {
  try {
    const res = yield call(publishPageApi, data);
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      cb();
    }
  } catch (err) {
    yield put(publishPagesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchPageFolders({ payload }) {
  try {
    // ;
    const res = yield call(fetchPageListFolders, payload);

    if (res.data?.data) {
      _itemsFolder = [];
      let a = itemsFolder(res.data.data.folder_list);
      let temp_res = { rows: a, count: a.length };
      yield put(fetchPageFolderSuccess(temp_res));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handelGetPageFolderDetails({ payload }) {
  try {
    // ;
    const res = yield call(getPageFolderDetails, payload);

    if (res.data?.data) {
      yield put(getPageFolderDetailsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handelAddEditPageFolder({ payload }) {
  try {
    const res = yield call(addEditPageFolder, payload);
    if (res.data?.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchPageFolders());
    }
  } catch (err) {
    // yield put(addEditProductError())
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeletePageFolder({ payload }) {
  try {
    const res = yield call(deleteSinglePageFolder, payload);

    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchPageFolders());
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* pageSaga() {
  yield takeEvery(LIST_ALL_OPTIONS, handleSelectOptions);
  yield takeEvery(ADD_EDIT_VOLUME_PAGE, handleAddEditPage);
  yield takeEvery(FETCH_VOLUME_PAGES, handleFetchPages);
  yield takeEvery(GET_VOLUME_PAGE_DETAILS, handlePageDetail);
  yield takeEvery(DELETE_VOLUME_PAGES, handleDeletePages);

  yield takeEvery(
    LIST_ALL_CONTENT_TYPE_ID_AND_TITLE,
    handleListAllContentTypeIdAndTitle
  );
  yield takeEvery(PUBLISH_VOLUME_PAGES, handlePublishPages);
  yield takeEvery(FETCH_VOLUME_PAGE_FOLDERS, handleFetchPageFolders);
  yield takeEvery(ADD_EDIT_VOLUME_PAGE_FOLDER, handelAddEditPageFolder);
  yield takeEvery(GET_VOLUME_PAGE_FOLDER_DETAILS, handelGetPageFolderDetails);
  yield takeEvery(DELETE_VOLUME_PAGE_FOLDER, handleDeletePageFolder);
}

export default pageSaga;
