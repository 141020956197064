import { axiosApiAdmin } from "helpers/api_helper";
import {
  GET_ACTIVE_INACTIVE,
  GET_THIRDPARTY_CONFIG_DETAILS,
  GET_THIRDPARTY_LIST,
  LIST_CIRCLE_SPACES,
  LIST_MAP_PRODUCTS,
  LIST_SHIPBOB_CHANNEL,
  LIST_SHIPBOB_PRODUCTS,
  LIST_SHIPMENT_METHOD,
  LIST_SYCU_PRODUCTS,
  LIST_THIRDPARTY_LOGS,
  SAVE_SYCU_MAP_PRODUCTS,
  SAVE_THIRDPARTY_CONFIG,
  SHIPBOB_CHANNEL_UPDATE,
  UPDATE_SHIPMENT_METHOD,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  CIRCLE_SPACES_LIST,
  FETCH_ACTIVE_INACTIVE,
  FETCH_THIRDPARTY_CONFIG_DETAILS,
  FETCH_THIRDPARTY_LIST,
  GET_SHIPBOB_CHANNEL,
  GET_SHIPBOB_PRODUCTS,
  GET_SHIPMENT_METHOD,
  GET_SYCU_MAP_PRODUCTS,
  GET_SYCU_PRODUCTS,
  GET_THIRDPARTY_LOGS,
  SAVE_SYCU_PRODUCTS,
  SAVE_THIRDPARTY_CONFIG_DETAILS,
  UPDATE_SHIPBOB_CHANNEL,
  UPDATE_SHIPMENT,
} from "./actionsTypes";

import {
  fetchActiveInactiveSuccess,
  fetchThirdPartyConfigDetails,
  fetchThirdPartyConfigDetailsSuccess,
  fetchThirdPartyListError,
  fetchThirdPartyListSuccess,
  getShipbobProductsError,
  getShipbobProductsSuccess,
  getShipmentMethodError,
  getShipmentMethodSuccess,
  getSycuMapProductsError,
  getSycuMapProductsSuccess,
  getSycuProductsError,
  getSycuProductsSuccess,
  listCircleSpacesError,
  listCircleSpacesSuccess,
  listShipbobChannelError,
  listShipbobChannelSuccess,
  listThirdPartyLogsError,
  listThirdPartyLogsSuccess,
  saveSycuProductsError,
  saveSycuProductsSuccess,
  saveThirdPartyConfigerError,
  saveThirdPartyConfigSuccess,
  setErrorMessage,
  setSuccessMessage,
  updateShipbobChannelError,
  updateShipbobChannelSucccess,
  updateShipmentError,
  updateShipmentSucccess,
} from "store/actions";

const fetchThirdParty = () => axiosApiAdmin.get(GET_THIRDPARTY_LIST);
const fetchActiveinactive = obj => axiosApiAdmin.post(GET_ACTIVE_INACTIVE, obj);
const fetchThirdPartyConfig = id =>
  axiosApiAdmin.get(`${GET_THIRDPARTY_CONFIG_DETAILS}/${id}`);
const fetchSaveThirdPartyConfig = obj =>
  axiosApiAdmin.post(SAVE_THIRDPARTY_CONFIG, obj);
const fetchCircleSpaceList = obj => axiosApiAdmin.post(LIST_CIRCLE_SPACES, obj);
const fetchThirdPartyLogs = payload =>
  axiosApiAdmin.post(LIST_THIRDPARTY_LOGS, payload);
const fetchShipmentMethod = () => axiosApiAdmin.get(LIST_SHIPMENT_METHOD);
const fetchSycuProducts = () => axiosApiAdmin.post(LIST_SYCU_PRODUCTS);
const fetchShipbobProducts = () => axiosApiAdmin.post(LIST_SHIPBOB_PRODUCTS);
const fetchSaveSycuProducts = payload =>
  axiosApiAdmin.post(SAVE_SYCU_MAP_PRODUCTS, payload);
const fetchSycuMapProducts = () => axiosApiAdmin.post(LIST_MAP_PRODUCTS);
const fetchUpdateShipment = payload =>
  axiosApiAdmin.post(UPDATE_SHIPMENT_METHOD, payload);
const fetchShipbobMethods = () => axiosApiAdmin.post(LIST_SHIPBOB_CHANNEL);
const fetchUpdateShipbobChannel = payload =>
  axiosApiAdmin.post(SHIPBOB_CHANNEL_UPDATE, payload);

function* handleUpdateShipbobChannel({ payload }) {
  try {
    const res = yield call(fetchUpdateShipbobChannel, payload);
    if (res?.data?.data) {
      yield put(updateShipbobChannelSucccess());
      yield put(setSuccessMessage(res.data.message));
    }
  } catch (err) {
    yield put(updateShipbobChannelError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleShipbobMethods() {
  try {
    const res = yield call(fetchShipbobMethods);
    if (res?.data?.data) {
      yield put(listShipbobChannelSuccess(res.data.data));
    }
  } catch (err) {
    yield put(listShipbobChannelError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleUpdateShipment({ payload }) {
  try {
    const res = yield call(fetchUpdateShipment, payload);
    if (res?.data?.data) {
      yield put(updateShipmentSucccess());
      yield put(setSuccessMessage(res.data.message));
    }
  } catch (err) {
    yield put(updateShipmentError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleSycuMapProducts() {
  try {
    const res = yield call(fetchSycuMapProducts);
    if (res?.data?.data) {
      yield put(getSycuMapProductsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getSycuMapProductsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleSaveSycuProducts({ payload }) {
  try {
    const res = yield call(fetchSaveSycuProducts, payload);
    if (res?.data?.data) {
      yield put(saveSycuProductsSuccess());
      yield put(setSuccessMessage(res.data.message));
      // yield put(getSycuMapProducts());
    }
  } catch (err) {
    yield put(saveSycuProductsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleShipbobProducts() {
  try {
    const res = yield call(fetchShipbobProducts);
    if (res?.data?.data) {
      yield put(getShipbobProductsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getShipbobProductsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleSycuProducts() {
  try {
    const res = yield call(fetchSycuProducts);
    if (res?.data?.data) {
      yield put(getSycuProductsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getSycuProductsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleShipmentMethod() {
  try {
    const res = yield call(fetchShipmentMethod);
    if (res?.data?.data) {
      yield put(getShipmentMethodSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getShipmentMethodError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleThirdPartyLogs() {
  try {
    const {
      activeTabs,
      logPage,
      logSizePerPage,
      activityType,
      statusType,
      logSearch,
    } = yield select(state => state.Integrations);
    const res = yield call(fetchThirdPartyLogs, {
      page_no: logPage,
      page_record: logSizePerPage,
      thirdparty_id: activeTabs,
      activity_type: activityType,
      status: statusType,
      search: logSearch,
    });
    if (res?.data?.data) {
      yield put(listThirdPartyLogsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(listThirdPartyLogsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleCircleSpaceList() {
  let obj;
  try {
    const res = yield call(fetchCircleSpaceList, obj);
    if (res?.data?.data) {
      yield put(listCircleSpacesSuccess(res.data.data));
    }
  } catch (err) {
    yield put(listCircleSpacesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleSaveThirdPartyDetails({ payload }) {
  try {
    const res = yield call(fetchSaveThirdPartyConfig, payload);
    if (res?.data?.data) {
      yield put(saveThirdPartyConfigSuccess());
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchThirdPartyConfigDetails());
    }
  } catch (err) {
    yield put(saveThirdPartyConfigerError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handelThirdPartyConfigDetails() {
  try {
    const { activeTabs } = yield select(state => state.Integrations);
    const res = yield call(fetchThirdPartyConfig, activeTabs);
    if (res?.data?.data?.response) {
      yield put(fetchThirdPartyConfigDetailsSuccess(res.data.data.response));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleActiveInactive({ payload }) {
  try {
    const res = yield call(fetchActiveinactive, payload);
    if (res?.data?.data?.response) {
      yield put(fetchActiveInactiveSuccess(res.data.data.response));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handelThirdPartyList() {
  try {
    const res = yield call(fetchThirdParty);
    if (res.data?.data?.response) {
      yield put(fetchThirdPartyListSuccess(res.data.data.response));
    }
  } catch (err) {
    yield put(fetchThirdPartyListError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* IntegrationsSaga() {
  yield takeEvery(FETCH_THIRDPARTY_LIST, handelThirdPartyList);
  yield takeEvery(FETCH_ACTIVE_INACTIVE, handleActiveInactive);
  yield takeEvery(
    FETCH_THIRDPARTY_CONFIG_DETAILS,
    handelThirdPartyConfigDetails
  );
  yield takeEvery(SAVE_THIRDPARTY_CONFIG_DETAILS, handleSaveThirdPartyDetails);
  yield takeEvery(CIRCLE_SPACES_LIST, handleCircleSpaceList);
  yield takeEvery(GET_THIRDPARTY_LOGS, handleThirdPartyLogs);
  yield takeEvery(GET_SHIPMENT_METHOD, handleShipmentMethod);
  yield takeEvery(GET_SYCU_PRODUCTS, handleSycuProducts);
  yield takeEvery(GET_SHIPBOB_PRODUCTS, handleShipbobProducts);
  yield takeEvery(SAVE_SYCU_PRODUCTS, handleSaveSycuProducts);
  yield takeEvery(GET_SYCU_MAP_PRODUCTS, handleSycuMapProducts);
  yield takeEvery(UPDATE_SHIPMENT, handleUpdateShipment);
  yield takeEvery(GET_SHIPBOB_CHANNEL, handleShipbobMethods);
  yield takeEvery(UPDATE_SHIPBOB_CHANNEL, handleUpdateShipbobChannel);
}

export default IntegrationsSaga;
