import {
  CIRCLE_SPACES_LIST,
  CIRCLE_SPACES_LIST_ERROR,
  CIRCLE_SPACES_LIST_SUCCESS,
  CLEAR_ACTIVITY_STATUS,
  CLEAR_LOG_FEATURES,
  FETCH_ACTIVE_INACTIVE,
  FETCH_ACTIVE_INACTIVE_SUCCESS,
  FETCH_THIRDPARTY_CONFIG_DETAILS,
  FETCH_THIRDPARTY_CONFIG_DETAILS_SUCCESS,
  FETCH_THIRDPARTY_LIST,
  FETCH_THIRDPARTY_LIST_ERROR,
  FETCH_THIRDPARTY_LIST_SUCCESS,
  GET_SHIPBOB_CHANNEL,
  GET_SHIPBOB_CHANNEL_ERROR,
  GET_SHIPBOB_CHANNEL_SUCCESS,
  GET_SHIPBOB_PRODUCTS,
  GET_SHIPBOB_PRODUCTS_ERROR,
  GET_SHIPBOB_PRODUCTS_SUCCESS,
  GET_SHIPMENT_METHOD,
  GET_SHIPMENT_METHOD_ERROR,
  GET_SHIPMENT_METHOD_SUCCESS,
  GET_SYCU_MAP_PRODUCTS,
  GET_SYCU_MAP_PRODUCTS_ERROR,
  GET_SYCU_MAP_PRODUCTS_SUCCESS,
  GET_SYCU_PRODUCTS,
  GET_SYCU_PRODUCTS_ERROR,
  GET_SYCU_PRODUCTS_SUCCESS,
  GET_THIRDPARTY_LOGS,
  GET_THIRDPARTY_LOGS_ERROR,
  GET_THIRDPARTY_LOGS_SUCCESS,
  SAVE_SYCU_PRODUCTS,
  SAVE_SYCU_PRODUCTS_ERROR,
  SAVE_SYCU_PRODUCTS_SUCCESS,
  SAVE_THIRDPARTY_CONFIG_DETAILS,
  SAVE_THIRDPARTY_CONFIG_DETAILS_ERROR,
  SAVE_THIRDPARTY_CONFIG_DETAILS_SUCCESS,
  SET_ACTIVE_TABS,
  SET_ACTIVITY_TYPE,
  SET_LOGS_PAGES,
  SET_LOGS_SEARCH,
  SET_LOGS_SIZE_PER_PAGE,
  SET_STATUS_TYPE,
  UPDATE_SHIPBOB_CHANNEL,
  UPDATE_SHIPBOB_CHANNEL_ERROR,
  UPDATE_SHIPBOB_CHANNEL_SUCCESS,
  UPDATE_SHIPMENT,
  UPDATE_SHIPMENT_ERROR,
  UPDATE_SHIPMENT_SUCCESS,
} from "./actionsTypes";

export const fetchThirdPartyList = () => ({
  type: FETCH_THIRDPARTY_LIST,
});

export const fetchThirdPartyListSuccess = data => ({
  type: FETCH_THIRDPARTY_LIST_SUCCESS,
  payload: data,
});
export const fetchThirdPartyListError = () => ({
  type: FETCH_THIRDPARTY_LIST_ERROR,
});

export const setActiveTabs = data => ({
  type: SET_ACTIVE_TABS,
  payload: data,
});

export const fetchActiveInactive = data => ({
  type: FETCH_ACTIVE_INACTIVE,
  payload: data,
});

export const fetchActiveInactiveSuccess = data => ({
  type: FETCH_ACTIVE_INACTIVE_SUCCESS,
  payload: data,
});

export const fetchThirdPartyConfigDetails = () => ({
  type: FETCH_THIRDPARTY_CONFIG_DETAILS,
});

export const fetchThirdPartyConfigDetailsSuccess = data => ({
  type: FETCH_THIRDPARTY_CONFIG_DETAILS_SUCCESS,
  payload: data,
});

export const saveThirdPartyConfig = data => ({
  type: SAVE_THIRDPARTY_CONFIG_DETAILS,
  payload: data,
});

export const saveThirdPartyConfigSuccess = () => ({
  type: SAVE_THIRDPARTY_CONFIG_DETAILS_SUCCESS,
});

export const saveThirdPartyConfigerError = () => ({
  type: SAVE_THIRDPARTY_CONFIG_DETAILS_ERROR,
});

export const listCircleSpaces = () => ({
  type: CIRCLE_SPACES_LIST,
});

export const listCircleSpacesSuccess = data => ({
  type: CIRCLE_SPACES_LIST_SUCCESS,
  payload: data,
});

export const listCircleSpacesError = () => ({
  type: CIRCLE_SPACES_LIST_ERROR,
});

export const listThirdPartyLogs = () => ({
  type: GET_THIRDPARTY_LOGS,
});

export const listThirdPartyLogsSuccess = data => ({
  type: GET_THIRDPARTY_LOGS_SUCCESS,
  payload: data,
});

export const listThirdPartyLogsError = () => ({
  type: GET_THIRDPARTY_LOGS_ERROR,
});

export const setLogPages = data => ({
  type: SET_LOGS_PAGES,
  payload: data,
});
export const setLogSearch = data => ({
  type: SET_LOGS_SEARCH,
  payload: data,
});

export const setLogSizePerPages = data => ({
  type: SET_LOGS_SIZE_PER_PAGE,
  payload: data,
});

export const setActivityType = data => ({
  type: SET_ACTIVITY_TYPE,
  payload: data,
});

export const setStatusType = data => ({
  type: SET_STATUS_TYPE,
  payload: data,
});

export const clearActivityStatus = () => ({
  type: CLEAR_ACTIVITY_STATUS,
});
export const clearLogFeatures = () => ({
  type: CLEAR_LOG_FEATURES,
});

export const getShipmentMethod = () => ({
  type: GET_SHIPMENT_METHOD,
});

export const getShipmentMethodSuccess = data => ({
  type: GET_SHIPMENT_METHOD_SUCCESS,
  payload: data,
});

export const getShipmentMethodError = () => ({
  type: GET_SHIPMENT_METHOD_ERROR,
});

export const getSycuProducts = () => ({
  type: GET_SYCU_PRODUCTS,
});

export const getSycuProductsSuccess = data => ({
  type: GET_SYCU_PRODUCTS_SUCCESS,
  payload: data,
});

export const getSycuProductsError = () => ({
  type: GET_SYCU_PRODUCTS_ERROR,
});

export const getShipbobProducts = () => ({
  type: GET_SHIPBOB_PRODUCTS,
});

export const getShipbobProductsSuccess = data => ({
  type: GET_SHIPBOB_PRODUCTS_SUCCESS,
  payload: data,
});

export const getShipbobProductsError = () => ({
  type: GET_SHIPBOB_PRODUCTS_ERROR,
});

export const saveSycuProducts = data => ({
  type: SAVE_SYCU_PRODUCTS,
  payload: data,
});

export const saveSycuProductsSuccess = () => ({
  type: SAVE_SYCU_PRODUCTS_SUCCESS,
});

export const saveSycuProductsError = () => ({
  type: SAVE_SYCU_PRODUCTS_ERROR,
});

export const getSycuMapProducts = () => ({
  type: GET_SYCU_MAP_PRODUCTS,
});

export const getSycuMapProductsSuccess = data => ({
  type: GET_SYCU_MAP_PRODUCTS_SUCCESS,
  payload: data,
});

export const getSycuMapProductsError = () => ({
  type: GET_SYCU_MAP_PRODUCTS_ERROR,
});

export const updateShipment = data => ({
  type: UPDATE_SHIPMENT,
  payload: data,
});

export const updateShipmentSucccess = () => ({
  type: UPDATE_SHIPMENT_SUCCESS,
});

export const updateShipmentError = () => ({
  type: UPDATE_SHIPMENT_ERROR,
});

export const listShipbobChannel = () => ({
  type: GET_SHIPBOB_CHANNEL,
});

export const listShipbobChannelSuccess = data => ({
  type: GET_SHIPBOB_CHANNEL_SUCCESS,
  payload: data,
});

export const listShipbobChannelError = () => ({
  type: GET_SHIPBOB_CHANNEL_ERROR,
});

export const updateShipbobChannel = data => ({
  type: UPDATE_SHIPBOB_CHANNEL,
  payload: data,
});

export const updateShipbobChannelSucccess = () => ({
  type: UPDATE_SHIPBOB_CHANNEL_SUCCESS,
});

export const updateShipbobChannelError = () => ({
  type: UPDATE_SHIPBOB_CHANNEL_ERROR,
});
