// Va 05 - 01 - 21

import {
  CLEAR_SUPPORT_TICKETS,
  DELETE_COMMENT_REDUX,
  DELETE_COMMENT_REPLY_REDUX,
  FETCH_SUPPORT_TICKETS,
  FETCH_SUPPORT_TICKETS_ERROR,
  FETCH_SUPPORT_TICKETS_SUCCESS,
  SET_PAGE_SUPPORT_TICKETS,
  SET_SEARCH_SUPPORT_TICKETS,
  SET_SIZE_PER_PAGE_SUPPORT_TICKETS,
  SET_SORT_FIELD_SUPPORT_TICKETS,
  SET_SORT_ORDER_SUPPORT_TICKETS,
  SUPPORT_TICKET_DETAIL,
  SUPPORT_TICKET_DETAIL_ERROR,
  SUPPORT_TICKET_DETAIL_SUCCESS,
} from "./actionTypes";

const initialState = {
  supportTickets: [],
  supportTicketDetail: [],
  loading: false,
  totalSize: 0,
  sizePerPage: 10,
  search: "",
  page: 1,
  sortField: "post_id",
  sortOrder: "DESC",
};

const supportTicketsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_SUPPORT_TICKETS:
      return {
        ...state,
        supportTickets: [],
      };
    case FETCH_SUPPORT_TICKETS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SUPPORT_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        supportTickets: payload.posts,
        totalSize: payload.count,
      };
    case FETCH_SUPPORT_TICKETS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SUPPORT_TICKET_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case SUPPORT_TICKET_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        supportTicketDetail: payload,
      };
    case SUPPORT_TICKET_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
      };
    //deleting comment from redux
    case DELETE_COMMENT_REDUX:
      return {
        ...state,
        //deleting comment from redux
        supportTicketDetail: {
          ...state.supportTicketDetail,
          ...(payload.comment_id && payload.comment_id
            ? {
                circle_comments:
                  state.supportTicketDetail.circle_comments.filter(
                    item => item.circle_comment_id !== payload.comment_id
                  ),
              }
            : {}),
        },
      };
    //deleting comment reply from redux
    case DELETE_COMMENT_REPLY_REDUX:
      return {
        ...state,
        //deleting comment reply  from redux
        supportTicketDetail: {
          ...state.supportTicketDetail,
          ...(payload.comment_id
            ? {
                circle_comments:
                  state.supportTicketDetail.circle_comments.filter(
                    item => item.circle_comment_id !== payload.comment_id
                  ),
              }
            : {}),
        },
      };

    case SET_PAGE_SUPPORT_TICKETS:
      return {
        ...state,
        page: payload,
      };

    case SET_SIZE_PER_PAGE_SUPPORT_TICKETS:
      return {
        ...state,
        sizePerPage: payload,
      };

    case SET_SEARCH_SUPPORT_TICKETS:
      return {
        ...state,
        search: payload,
        page: 1,
      };

    case SET_SORT_FIELD_SUPPORT_TICKETS:
      return {
        ...state,
        sortField: action.payload,
      };

    case SET_SORT_ORDER_SUPPORT_TICKETS:
      return {
        ...state,
        sortOrder: action.payload,
      };

    default:
      return state;
  }
};

export default supportTicketsReducer;
