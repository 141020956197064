// Fetch Profile
export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_ERROR = "FETCH_USER_PROFILE_ERROR";
export const SET_USER_NAME = "SET_USER_NAME";

// reset Password
export const RESET_PASSWORD_EMAIL = "RESET_PASSWORD_EMAIL";
export const RESET_PASSWORD_EMAIL_SUCCESS = "RESET_PASSWORD_EMAIL_SUCCESS";
export const RESET_PASSWORD_EMAIL_ERROR = "RESET_PASSWORD_EMAIL_ERROR";

//copyResetPasswordLik

export const COPY_RESET_PASSWORD_LINK = "COPY_RESET_PASSWORD_LINK";
export const COPY_RESET_PASSWORD_LINK_SUCCESS =
  "COPY_RESET_PASSWORD_LINK_SUCCESS";
export const COPY_RESET_PASSWORD_LINK_ERROR = "COPY_RESET_PASSWORD_LINK_ERROR";

//FETCH
export const USER_APPLICATION_STUFF = "USER_APPLICATION_STUFF";
export const USER_APPLICATION_STUFF_SUCCESS = "USER_APPLICATION_STUFF_SUCCESS";
export const USER_APPLICATION_STUFF_ERROR = "USER_APPLICATION_STUFF_ERROR";

//
export const SET_USER_ID = "SET_USER_ID";
export const SET_ACCESS_ACCOUNT_DATA = "SET_ACCESS_ACCOUNT_DATA";
export const CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS";

export const USER_SHARED_ACTIVE_DEACTIVE = "USER_SHARED_ACTIVE_DEACTIVE";
export const USER_SHARED_ACTIVE_DEACTIVE_SUCCESS =
  "USER_SHARED_ACTIVE_DEACTIVE_SUCCESS";
export const USER_SHARED_ACTIVE_DEACTIVE_ERROR =
  "USER_SHARED_ACTIVE_DEACTIVE_ERROR";

export const SET_IS_CUSTOMER_ROUTE = "SET_IS_CUSTOMER_ROUTE";
