import { axiosApiAdmin, axiosApiBoard } from "helpers/api_helper";
import {
  DELETE_SINGLE_VOLUME_FOLDERS,
  DELETE_VOLUME_SERIES,
  GET_AMAZON_INTERNALS_URL,
  GET_SERIES_EDITOR_DETAILS,
  GET_SINGLE_VOLUME_FOLDERS_DETAILS,
  GET_SINGLE_VOLUME_SERIES_DETAILS,
  GET_VOLUME_BOARD,
  LIST_ALL_SERIES,
  LIST_ALL_TIP_VIDEOS,
  LIST_ALL_VOLUME_FOLDERS,
  SAVE_AMAZON_INTERNAL_URL,
  SAVE_SERIES_EDITOR,
  SAVE_VOLUME_FOLDERS,
  SAVE_VOLUME_SERIES,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  addEditSeriesEditorError,
  deleteSeriesError,
  fetchSeries,
  fetchSeriesEditorDetailsError,
  fetchSeriesEditorDetailsSuccess,
  fetchSeriesError,
  fetchSeriesSuccess,
  fetchVolumeBuilderFolderError,
  fetchVolumeBuilderFolders,
  fetchVolumeBuilderFolderSuccess,
  getVolumeFolderDetailsSuccess,
  getVolumeSeriesDetailsSuccess,
  setErrorMessage,
  setSuccessMessage,
} from "store/actions";
import {
  addEditSeriesEditorSuccess,
  addEditVolumeFolderError,
  addEditVolumeFolderSuccess,
  addEditVolumeSeriesError,
  addEditVolumeSeriesSuccess,
  fetchBoardDataSuccess,
  fetchTipVideosError,
  fetchTipVideosSuccess,
  getAmazonInternalsError,
  getAmazonInternalsSuccess,
  saveAmazonInternalsError,
  saveAmazonInternalsSuccess,
} from "./actions";
import {
  ADD_EDIT_SERIES_EDITOR,
  ADD_EDIT_VOLUME_FOLDER,
  ADD_EDIT_VOLUME_SERIES,
  DELETE_SERIES,
  DELETE_VOLUME_FOLDER,
  FETCH_BOARD,
  FETCH_SERIES,
  FETCH_SERIES_EDITOR_DETAILS,
  FETCH_TIP_VIDEOS,
  FETCH_VOLUME_BUILDER_FOLDERS,
  GET_AMAZON_INTERNALS,
  GET_VOLUME_FOLDER_DETAILS,
  GET_VOLUME_SERIES_DETAILS,
  SAVE_AMAZON_INTERNALS,
} from "./actionTypes";

const fetchVolumeListFolders = () => axiosApiAdmin.get(LIST_ALL_VOLUME_FOLDERS);
const addEditVolumeFolder = data =>
  axiosApiAdmin.post(SAVE_VOLUME_FOLDERS, data);
const getVolumeFolderDetails = data =>
  axiosApiAdmin.get(`${GET_SINGLE_VOLUME_FOLDERS_DETAILS}/${data}`);
const deleteSingleVolumeFolder = data =>
  axiosApiAdmin.delete(`${DELETE_SINGLE_VOLUME_FOLDERS}/${data}`);
const deleteSeries = data =>
  axiosApiAdmin.delete(`${DELETE_VOLUME_SERIES}/${data}`);
const fetchSeriesList = data => axiosApiAdmin.post(LIST_ALL_SERIES, data);
const addEditVolumeSeries = data =>
  axiosApiAdmin.post(SAVE_VOLUME_SERIES, data);
const getVolumeSeriesDetails = data =>
  axiosApiAdmin.get(`${GET_SINGLE_VOLUME_SERIES_DETAILS}/${data}`);

const fetchSeriesEditorDetailsList = data =>
  axiosApiAdmin.get(`${GET_SERIES_EDITOR_DETAILS}/${data}`);
const addEditSeriesEditorPage = data =>
  axiosApiAdmin.post(SAVE_SERIES_EDITOR, data);
const fetchAllTipVideos = data => axiosApiAdmin.post(LIST_ALL_TIP_VIDEOS, data);
const fetchAllboardData = data => axiosApiBoard.post(GET_VOLUME_BOARD, data);

const saveAmazonInternalsPage = data =>
  axiosApiAdmin.post(SAVE_AMAZON_INTERNAL_URL, data);
const getAmazonInternalsList = data =>
  axiosApiAdmin.get(`${GET_AMAZON_INTERNALS_URL}/${data}`);

function* handleFetchVolumeFolders({ payload }) {
  try {
    const res = yield call(fetchVolumeListFolders);
    if (res.data?.data) {
      const firstRecord = res.data.data.volumes[0];

      yield put(
        fetchVolumeBuilderFolderSuccess({
          volumes: res.data.data.volumes,
          category_id: !!payload?.category_id
            ? +payload.category_id
            : +firstRecord?.category_id,
          ministry_type:
            /vbs/gi.test(firstRecord?.category_title) ||
            +payload?.category_id === 8
              ? 4
              : 1,
        })
      );
    }
  } catch (err) {
    yield put(fetchVolumeBuilderFolderError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handelGetVolumeFolderDetails({ payload }) {
  try {
    // ;
    const res = yield call(getVolumeFolderDetails, payload);

    if (res.data?.data) {
      yield put(getVolumeFolderDetailsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handelAddEditVolumeFolder({ payload: { v, cb } }) {
  try {
    //
    const res = yield call(addEditVolumeFolder, v);
    if (res.data?.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(addEditVolumeFolderSuccess());
      yield put(fetchVolumeBuilderFolders());
      cb();
    }
  } catch (err) {
    yield put(addEditVolumeFolderError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteVolumeFolder({ payload }) {
  try {
    const res = yield call(deleteSingleVolumeFolder, payload);

    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchVolumeBuilderFolders());
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchSeries({ payload = null }) {
  const {
    types: { MinistryType, catActiveTab },
    search,
  } = yield select(state => state.VolumeReducer);
  const data = {
    category_id: !!payload
      ? payload
      : [1, 7].includes(catActiveTab)
      ? 0
      : catActiveTab,
    ministry_type: MinistryType,
    search,
  };
  try {
    const res = yield call(fetchSeriesList, data);
    if (res?.data?.data) {
      yield put(fetchSeriesSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchSeriesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handelAddEditVolumeSeries({ payload: { v, cb } }) {
  try {
    //
    const res = yield call(addEditVolumeSeries, v);
    if (res.data?.data) {
      yield put(addEditVolumeSeriesSuccess());
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchSeries());
      cb();
    }
  } catch (err) {
    yield put(addEditVolumeSeriesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handelGetVolumeSeriesDetails({ payload }) {
  try {
    const res = yield call(getVolumeSeriesDetails, payload);
    if (res.data?.data) {
      yield put(getVolumeSeriesDetailsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteSeries({ payload }) {
  try {
    const res = yield call(deleteSeries, payload);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchSeries());
    }
  } catch (err) {
    yield put(deleteSeriesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchSeriesEditorDetails({ payload: { series_id } }) {
  try {
    const res = yield call(fetchSeriesEditorDetailsList, series_id);
    if (res?.data?.data) {
      yield put(fetchSeriesEditorDetailsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchSeriesEditorDetailsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* addEditSeriesEditor({ payload: { data, cb } }) {
  try {
    const res = yield call(addEditSeriesEditorPage, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(addEditSeriesEditorSuccess());
      cb();
    }
  } catch (err) {
    yield put(addEditSeriesEditorError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleFetchTipVideos() {
  try {
    const {
      types: { MinistryType, catActiveTab },
      tipVideoSearch,
    } = yield select(state => state.VolumeReducer);
    const res = yield call(fetchAllTipVideos, {
      category_id: catActiveTab,
      ministry_type: MinistryType,
      search: tipVideoSearch,
    });
    if (res.data?.data?.tip_videos) {
      yield put(fetchTipVideosSuccess(res.data.data.tip_videos));
    }
  } catch (err) {
    yield put(fetchTipVideosError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchBoard() {
  try {
    const {
      types: { MinistryType, catActiveTab },
    } = yield select(state => state.VolumeReducer);
    const res = yield call(fetchAllboardData, {
      category_id: catActiveTab,
      ministry_type: MinistryType,
    });
    if (res.data?.data?.boardData) {
      yield put(fetchBoardDataSuccess(res.data.data.boardData));
    } else {
      yield put(fetchBoardDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchTipVideosError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

//

function* handleSaveAmazonInternals({ payload: { data, cb } }) {
  try {
    const res = yield call(saveAmazonInternalsPage, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(saveAmazonInternalsSuccess());
      cb();
    }
  } catch (err) {
    yield put(saveAmazonInternalsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleGetAmazonInternals({ payload: { series_id } }) {
  try {
    const res = yield call(getAmazonInternalsList, series_id);
    if (res?.data?.data) {
      yield put(getAmazonInternalsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getAmazonInternalsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* pageSaga() {
  yield takeEvery(FETCH_VOLUME_BUILDER_FOLDERS, handleFetchVolumeFolders);
  yield takeEvery(ADD_EDIT_VOLUME_FOLDER, handelAddEditVolumeFolder);
  yield takeEvery(GET_VOLUME_FOLDER_DETAILS, handelGetVolumeFolderDetails);
  yield takeEvery(DELETE_VOLUME_FOLDER, handleDeleteVolumeFolder);
  yield takeEvery(FETCH_SERIES, handleFetchSeries);
  yield takeEvery(ADD_EDIT_VOLUME_SERIES, handelAddEditVolumeSeries);
  yield takeEvery(GET_VOLUME_SERIES_DETAILS, handelGetVolumeSeriesDetails);
  yield takeEvery(DELETE_SERIES, handleDeleteSeries);
  yield takeEvery(FETCH_SERIES_EDITOR_DETAILS, handleFetchSeriesEditorDetails);
  yield takeEvery(ADD_EDIT_SERIES_EDITOR, addEditSeriesEditor);
  yield takeEvery(FETCH_TIP_VIDEOS, handleFetchTipVideos);
  yield takeEvery(FETCH_BOARD, handleFetchBoard);

  yield takeEvery(SAVE_AMAZON_INTERNALS, handleSaveAmazonInternals);
  yield takeEvery(GET_AMAZON_INTERNALS, handleGetAmazonInternals);
}

export default pageSaga;
