import { axiosApiAccount, axiosApiAdmin } from "helpers/api_helper";
import {
  CHARGE_TRANSACTION_RECEIPT,
  COUPON_VERIFY,
  FETCH_TOTAL_SUBSCRIPTION_URL,
  GET_SUBSCRIPTION_DATA_URL,
  GET_SUBSCRIPTION_DETAIL,
  LIST_ALL_SUBSCRIPTION,
  LIST_COUNTRY,
  LIST_STATE,
  NEW_SUBSCRIPTION,
  REFUND_TRANSACTION_RECEIPT,
  SUBMIT_ADMIN_SUBSCRIPTION,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  fetchSubscriptionByIdError,
  fetchSubscriptionByIdSuccess,
  fetchSubscriptionCountrySuccess,
  fetchSubscriptionError,
  fetchSubscriptionStateSuccess,
  fetchSubscriptionSuccess,
  fetchTotalSubscriptionSuccess,
  getNewSubscriptionError,
  getNewSubscriptionSuccess,
  getSubscriptionDataSuccess,
  sendReceiptSuccess,
  sendRefundReceiptSuccess,
  setErrorMessage,
  setSuccessMessage,
  submitSubscriptionError,
  submitSubscriptionSuccess,
  verifyCouponError,
  verifyCouponSuccess,
} from "store/actions";
import {
  EDIT_SUBSCRIPTION,
  FETCH_NEW_SUBSCRIPTION,
  FETCH_SUBSCRIPTION,
  FETCH_SUBSCRIPTION_COUNTRY,
  FETCH_SUBSCRIPTION_STATE,
  FETCH_TOTAL_SUBSCRIPTION,
  GET_SUBSCRIPTION_DATA,
  SEND_RECEIPT,
  SEND_REFUND_RECEIPT,
  SUBMIT_SUBSCRIPTION,
  VERIFY_COUPON,
} from "./actionTypes";

const fetchSubscriptions = data =>
  axiosApiAdmin.post(LIST_ALL_SUBSCRIPTION, data);
const fetchNewSubscription = data => axiosApiAdmin.post(NEW_SUBSCRIPTION, data);
const getSubscriptionCountry = data => axiosApiAccount.get(LIST_COUNTRY, data);
const getSubscriptionState = data =>
  axiosApiAccount.get(`${LIST_STATE}/${data}`);
const couponVerify = data => axiosApiAccount.post(COUPON_VERIFY, data);
const getSubmitSubscription = data =>
  axiosApiAdmin.post(SUBMIT_ADMIN_SUBSCRIPTION, data);
const fetchSubscriptionById = data =>
  axiosApiAdmin.get(`${GET_SUBSCRIPTION_DETAIL}/${data}`);
const fetchTotalSubscriptionCall = () =>
  axiosApiAdmin.get(FETCH_TOTAL_SUBSCRIPTION_URL);
const fetchSubscriptionDataCall = id =>
  axiosApiAdmin.get(`${GET_SUBSCRIPTION_DATA_URL}/${id}`);
const sendReceipts = data =>
  axiosApiAdmin.post(CHARGE_TRANSACTION_RECEIPT, data);
const sendRefundReceipts = data =>
  axiosApiAdmin.post(REFUND_TRANSACTION_RECEIPT, data);

function* handleFetchSubscription() {
  const {
    search,
    sizePerPage,
    page,
    sortField,
    sortOrder,
    subscriptionStatus,
  } = yield select(state => state.Subscription);
  const data = {
    search,
    page_record: sizePerPage,
    page_no: page,
    sortField,
    sortOrder,
    subscription_status: subscriptionStatus,
  };

  try {
    const res = yield call(fetchSubscriptions, data);
    if (res.data?.data) {
      yield put(fetchSubscriptionSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchNewSubscription({ payload: { callBack, payload } }) {
  try {
    const res = yield call(fetchNewSubscription, payload);
    if (res.data?.data) {
      yield put(getNewSubscriptionSuccess(res.data.data));
      callBack({
        subscription_id: res.data.data?.subscription?.subscription_id,
        subscription_number: res.data.data?.subscription?.subscription_number,
      });
    }
  } catch (err) {
    yield put(getNewSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchSubscriptionCountry({ payload }) {
  try {
    const res = yield call(getSubscriptionCountry, payload);
    if (res.data?.data) {
      yield put(fetchSubscriptionCountrySuccess(res.data.data));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchSubscriptionState({ payload }) {
  try {
    const res = yield call(getSubscriptionState, payload);
    if (res.data?.data) {
      yield put(fetchSubscriptionStateSuccess(res.data.data));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleVerifyCoupon({ payload: { data, callBack, isSubmitCoupon } }) {
  try {
    const res = yield call(couponVerify, data);
    if (res.data?.data) {
      if (isSubmitCoupon) yield put(setSuccessMessage(res.data.message));
      yield put(verifyCouponSuccess(res.data.data));
      callBack(res.data.data);
    }
  } catch (err) {
    yield put(verifyCouponError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleSubmitSubscription({ payload: { data, callBack } }) {
  try {
    const res = yield call(getSubmitSubscription, data);
    if (res.data?.data) {
      yield put(submitSubscriptionSuccess());
      yield put(setSuccessMessage(res.data.message));
      callBack();
    }
  } catch (err) {
    yield put(submitSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleSubscriptionDetail({ payload: { user_subscription_id } }) {
  try {
    const res = yield call(fetchSubscriptionById, user_subscription_id);
    if (res?.data?.data) {
      yield put(fetchSubscriptionByIdSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchSubscriptionByIdError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleFetchTotalSubscription() {
  try {
    const response = yield call(fetchTotalSubscriptionCall);
    if (response.data.statusCode && response?.data?.data?.subscription) {
      yield put(fetchTotalSubscriptionSuccess(response.data.data.subscription));
    }
  } catch (error) {}
}
function* handleGetSubscriptionData({ id }) {
  try {
    const res = yield call(fetchSubscriptionDataCall, id);
    if (res.data.statusCode) {
      yield put(getSubscriptionDataSuccess(res.data?.data));
    }
  } catch (error) {}
}

function* handleSendReceipt({ payload }) {
  try {
    const res = yield call(sendReceipts, payload.value);
    if (res.data.statusCode === 200) {
      yield put(setSuccessMessage(res.data.message));
      yield put(sendReceiptSuccess());
      if (!!payload.cb && typeof payload.cb === "function") payload.cb();
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleSendRefundReceipt({ payload }) {
  try {
    const res = yield call(sendRefundReceipts, payload.value);
    if (res.data.statusCode === 200) {
      yield put(setSuccessMessage(res.data.message));
      yield put(sendRefundReceiptSuccess());
      if (!!payload.cb && typeof payload.cb === "function") payload.cb();
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* subscriptionSaga() {
  yield takeEvery(FETCH_SUBSCRIPTION, handleFetchSubscription);
  yield takeEvery(FETCH_NEW_SUBSCRIPTION, handleFetchNewSubscription);
  yield takeEvery(FETCH_SUBSCRIPTION_COUNTRY, handleFetchSubscriptionCountry);
  yield takeEvery(FETCH_SUBSCRIPTION_STATE, handleFetchSubscriptionState);
  yield takeEvery(VERIFY_COUPON, handleVerifyCoupon);
  yield takeEvery(SUBMIT_SUBSCRIPTION, handleSubmitSubscription);
  yield takeEvery(EDIT_SUBSCRIPTION, handleSubscriptionDetail);
  yield takeEvery(FETCH_TOTAL_SUBSCRIPTION, handleFetchTotalSubscription);
  yield takeEvery(GET_SUBSCRIPTION_DATA, handleGetSubscriptionData);
  yield takeEvery(SEND_RECEIPT, handleSendReceipt);
  yield takeEvery(SEND_REFUND_RECEIPT, handleSendRefundReceipt);
}

export default subscriptionSaga;
