import {
  CREATE_UPDATE_CARD_BOARD,
  CREATE_UPDATE_CARD_LOADING,
  DELETE_CARD,
  DELETE_CARD_LOADING,
  DUPLICATE_CARD_DETAILS,
  EDITOR_LIST_CANVAS_BOARD,
  EDIT_BOARD_TEMPLATE_DATA,
  FETCH_DIGITAL_BOARD_DATA,
  FETCH_DIGITAL_BOARD_DATA_ERROR,
  FETCH_DIGITAL_BOARD_DATA_SUCCESS,
  IMPORT_BOARD,
  LIST_BOARD_BG_LIBRARY,
  LOADING_BOARD_DETAILS,
  PREVIEW_LIST_CANVAS_BOARD,
  PUBLISH_DIGITAL_BOARD,
  SET_ACTIVE_CARD_DATA,
  SET_BACKGROUND_DETAILS,
  SET_BACKGROUND_IMAGES,
  SET_BACKGROUND_IMAGES_LOADING,
  SET_BOARD_DATA,
  SET_BOARD_TABLE_LOADER,
  SET_BOARD_TITLE,
  SET_CANVAS,
  SET_CANVAS_CARD_COORDINATE,
  SET_DIGITAL_BOARD_DATA_PAGE,
  SET_DIGITAL_BOARD_DATA_SEARCH,
  SET_DIGITAL_BOARD_DATA_SIZE_PER_PAGE,
  SET_DIGITAL_BOARD_DATA_SORT_FIELD,
  SET_DIGITAL_BOARD_DATA_SORT_ORDER,
  SET_FETCH_BOARD,
  SET_IMPORTED_DATA,
  SET_INVITED_USERS,
  SET_IS_INVITED_USER,
  SET_IS_PUBLIC,
  SET_OWNER_INFO,
  SET_PUBLISH_ADMIN_TEMPLATE,
  SET_ZOOM_LEVEL,
  UPDATE_CANVAS_CARD_COORDS,
  UPDATE_CARD_DETAILS,
  UPDATE_MULTI_CARD_COORDS,
} from "./actionTypes";

export const fetchDigitalBoard = data => {
  return {
    type: FETCH_DIGITAL_BOARD_DATA,
    payload: data,
  };
};

export const fetchDigitalBoardSuccess = data => ({
  type: FETCH_DIGITAL_BOARD_DATA_SUCCESS,
  payload: data,
});

export const fetchDigitalBoardError = data => ({
  type: FETCH_DIGITAL_BOARD_DATA_ERROR,
  payload: data,
});

export const setDigitalBoardTablePage = data => ({
  type: SET_DIGITAL_BOARD_DATA_PAGE,
  payload: data,
});
export const setDigitalBoardTableSizePerPage = data => ({
  type: SET_DIGITAL_BOARD_DATA_SIZE_PER_PAGE,
  payload: data,
});
export const setDigitalBoardTableSortField = data => ({
  type: SET_DIGITAL_BOARD_DATA_SORT_FIELD,
  payload: data,
});
export const setDigitalBoardTableSortOrder = data => ({
  type: SET_DIGITAL_BOARD_DATA_SORT_ORDER,
  payload: data,
});

export const setDigitalBoardTableSearch = data => ({
  type: SET_DIGITAL_BOARD_DATA_SEARCH,
  payload: data,
});

export const setBoardTableLoading = data => ({
  type: SET_BOARD_TABLE_LOADER,
  payload: data,
});

//preview

export const setLoadingBoardDetails = data => ({
  type: LOADING_BOARD_DETAILS,
  payload: data,
});

//EDITOR

export const fetchBackgroundLibrary = data => {
  return {
    type: LIST_BOARD_BG_LIBRARY,
    payload: data,
  };
};

export const fetchPreviewBoardDetails = data => {
  return {
    type: PREVIEW_LIST_CANVAS_BOARD,
    payload: data,
  };
};

export const editDigitalBoardData = data => {
  return {
    type: EDIT_BOARD_TEMPLATE_DATA,
    payload: data,
  };
};

export const setBoardTitle = data => ({
  type: SET_BOARD_TITLE,
  payload: data,
});

export const setPublishAdminTemplate = data => ({
  type: SET_PUBLISH_ADMIN_TEMPLATE,
  payload: data,
});

export const setBackgroundDetails = data => ({
  type: SET_BACKGROUND_DETAILS,
  payload: data,
});

export const setBackgroundImagesLoading = data => ({
  type: SET_BACKGROUND_IMAGES_LOADING,
  payload: data,
});

export const setBackgroundImages = data => ({
  type: SET_BACKGROUND_IMAGES,
  payload: data,
});

export const setCreateUpdateCardLoading = data => ({
  type: CREATE_UPDATE_CARD_LOADING,
  payload: data,
});

export const deleteCard = data => {
  return {
    type: DELETE_CARD,
    payload: data,
  };
};

export const updateCardCanvasCoords = data => ({
  type: UPDATE_CANVAS_CARD_COORDS,
  payload: data,
});

export const updateCardCoordinatesCanvas = data => ({
  type: SET_CANVAS_CARD_COORDINATE,
  payload: data,
});

export const updateMultiCardCanvasCoords = data => ({
  type: UPDATE_MULTI_CARD_COORDS,
  payload: data,
});

export const setZoomLevel = data => ({
  type: SET_ZOOM_LEVEL,
  payload: data,
});

export const setEditorBoardData = data => ({
  type: EDITOR_LIST_CANVAS_BOARD,
  payload: data,
});

export const updateCardDetails = data => ({
  type: UPDATE_CARD_DETAILS,
  payload: data,
});

export const createUpdateCardBoard = (data, cb) => ({
  type: CREATE_UPDATE_CARD_BOARD,
  payload: { data, cb },
});

export const duplicateCard = (data, cb) => ({
  type: DUPLICATE_CARD_DETAILS,
  payload: { data, cb },
});

export const setFetchBoardLoading = data => ({
  type: SET_FETCH_BOARD,
  payload: data,
});

export const setCanvasData = data => ({
  type: SET_CANVAS,
  payload: data,
});

export const setOwnerInfo = data => ({
  type: SET_OWNER_INFO,
  payload: data,
});

export const setInvitedUsers = data => ({
  type: SET_INVITED_USERS,
  payload: data,
});

export const setIsInvitedUser = data => ({
  type: SET_IS_INVITED_USER,
  payload: data,
});

export const setIsPublic = data => ({
  type: SET_IS_PUBLIC,
  payload: data,
});

export const boardPublish = data => ({
  type: PUBLISH_DIGITAL_BOARD,
  payload: data,
});

export const deleteCardLoading = data => ({
  type: DELETE_CARD_LOADING,
  payload: data,
});

export const setActiveCardData = data => ({
  type: SET_ACTIVE_CARD_DATA,
  payload: data,
});

export const importBoardData = data => ({
  type: IMPORT_BOARD,
  payload: data,
})

export const setBoardData = data => ({
  type: SET_BOARD_DATA,
  payload: data,
});

export const setImportData = data => ({
  type: SET_IMPORTED_DATA,
  payload: data,
})