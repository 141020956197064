import { axiosApiAdmin } from "helpers/api_helper";
import {
  ADD_UPDATE_ICEBREAKER,
  DELETE_ICEBREAKER_URL,
  LIST_FILTERS,
  LIST_ICEBREAKER,
  LIST_ICEBREAKER_BY_ID,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  addEditIcebreakerError,
  deleteIcebreakerError,
  fetchIcebreaker,
  fetchIcebreakerByIdError,
  fetchIcebreakerByIdSuccess,
  fetchIcebreakerError,
  fetchIcebreakerFiltersError,
  fetchIcebreakerFiltersSuccess,
  fetchIcebreakerSuccess,
  setErrorMessage,
  setSuccessMessage,
} from "store/actions";
import {
  ADD_EDIT_ICEBREAKER,
  DELETE_ICEBREAKER,
  FETCH_ICEBREAKER,
  FETCH_ICEBREAKER_FILTER,
  GET_ICEBREAKER_DETAILS,
} from "./actionsTypes";

const fetchIcebreakerApi = data => axiosApiAdmin.post(LIST_ICEBREAKER, data);
const addUpdateIcebreakers = data =>
  axiosApiAdmin.post(ADD_UPDATE_ICEBREAKER, data);
const fetchFilterIcebreaker = data => axiosApiAdmin.post(LIST_FILTERS, data);
const fetchIcebreakerByIds = data =>
  axiosApiAdmin.get(LIST_ICEBREAKER_BY_ID + `/${data.icebreaker_id}` + `/1`);
const deleteIcebreakers = data =>
  axiosApiAdmin.delete(DELETE_ICEBREAKER_URL, { data });

function* handleFetchIcebreaker({ payload }) {
  const { search, sizePerPage, page, sortOrder, sortField, is_system } =
    yield select(state => state.Icebreaker);

  const data = {
    page_record: !!payload ? payload.page_record : sizePerPage,
    page_no: search ? 1 : !!payload ? payload.page_no : page,
    search,
    sort_order: sortOrder,
    sort_field: sortField,
  };

  try {
    let res = yield call(fetchIcebreakerApi, data);

    if (res.data?.data) {
      yield put(fetchIcebreakerSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchIcebreakerError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAddEditIcebreaker({ payload: { data, cb } }) {
  try {
    const res = yield call(addUpdateIcebreakers, data);
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchIcebreaker());
      if (!!cb) cb();
    }
  } catch (err) {
    yield put(addEditIcebreakerError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchFilter() {
  const data = {
    filter_type: 2,
    is_system: 1,
  };
  try {
    const res = yield call(fetchFilterIcebreaker, data);
    if (res.data?.data) {
      yield put(fetchIcebreakerFiltersSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchIcebreakerFiltersError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleGetIcebreakerDetails({ payload }) {
  try {
    const res = yield call(fetchIcebreakerByIds, payload);
    if (res.data?.data) {
      yield put(fetchIcebreakerByIdSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchIcebreakerByIdError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleDeleteIcebreaker({ payload: { data, cb } }) {
  try {
    const res = yield call(deleteIcebreakers, data);
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchIcebreaker());
    }
    if (!!cb) cb();
  } catch (err) {
    yield put(deleteIcebreakerError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* icebreakerSaga() {
  yield takeEvery(FETCH_ICEBREAKER, handleFetchIcebreaker);
  yield takeEvery(ADD_EDIT_ICEBREAKER, handleAddEditIcebreaker);
  yield takeEvery(FETCH_ICEBREAKER_FILTER, handleFetchFilter);
  yield takeEvery(GET_ICEBREAKER_DETAILS, handleGetIcebreakerDetails);
  yield takeEvery(DELETE_ICEBREAKER, handleDeleteIcebreaker);
}

export default icebreakerSaga;
