//KI-09-12-21
//GET HABIT ADMIN
export const FETCH_HABIT = "FETCH_HABIT";
export const FETCH_HABIT_SUCCESS = "FETCH_HABIT_SUCCESS";
export const FETCH_HABIT_ERROR = "FETCH_HABIT_ERROR";

//Table
export const SET_PAGE_HABIT = "SET_PAGE_HABIT";
export const SET_SIZE_PER_PAGE_HABIT = "SET_SIZE_PER_PAGE_HABIT";
export const SET_SEARCH_HABIT = "SET_SEARCH_HABIT";
export const CLEAR_HABIT_FEATURES = "CLEAR_HABIT_FEATURES";
export const SET_FROM = "SET_FROM";

//ADD HABIT
export const ADD_HABIT = "ADD_HABIT";
export const ADD_HABIT_SUCCESS = "ADD_HABIT_SUCCESS";
export const ADD_HABIT_ERROR = "ADD_HABIT_ERROR";

//EDIT HABIT
export const EDIT_HABIT = "EDIT_HABIT";
export const EDIT_HABIT_SUCCESS = "EDIT_HABIT_SUCCESS";
export const EDIT_HABIT_ERROR = "EDIT_HABIT_ERROR";

//DELETE HABIT
export const DELETE_HABIT = "DELETE_HABIT";
export const DELETE_HABIT_SUCCESS = "DELETE_HABIT_SUCCESS";
export const DELETE_HABIT_ERROR = "DELETE_HABIT_ERROR";

export const SET_SORT_FILED_HABIT = "SET_SORT_FILED_HABIT";
export const SET_SORT_ORDER_HABIT = "SET_SORT_ORDER_HABIT";
export const SET_CATEGORY_ID_HABIT = "SET_CATEGORY_ID_HABIT";

//GET DETAIL
export const GET_HABIT_DETAIL = "GET_HABIT_DETAIL";
export const GET_HABIT_DETAIL_SUCCESS = "GET_HABIT_DETAIL_SUCCESS";
export const GET_HABIT_DETAIL_ERROR = "GET_HABIT_DETAIL_ERROR";
