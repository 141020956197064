import { axiosApiAdmin } from "helpers/api_helper";
import { CHEKOUT_CAMPAIGN_REPORT } from "helpers/url_helper";
import { toast } from "react-toastify";
import {
  getCheckoutCampaignDataError,
  getCheckoutCampaignDataSuccess,
} from "./actions";
import { FETCH_CHECKOUT_CAMPAIGN_REPORTS } from "./actionTypes";

const { takeEvery, call, put } = require("redux-saga/effects");

const fetchCheckoutCampaignData = payload =>
  axiosApiAdmin.post(CHEKOUT_CAMPAIGN_REPORT, payload);

function* CampaignDataSaga() {
  function* handleCampaignData({ payload }) {
    try {
      const res = yield call(fetchCheckoutCampaignData, payload);
      if (!!res?.data?.data) {
        yield put(
          getCheckoutCampaignDataSuccess({
            data: res?.data?.data?.rows,
            count: res?.data?.data?.count,
          })
        );
      }
    } catch (err) {
      yield put(getCheckoutCampaignDataError());
      toast.error(err?.response?.data?.message || err.message);
    }
  }

  yield takeEvery(FETCH_CHECKOUT_CAMPAIGN_REPORTS, handleCampaignData);
}

export default CampaignDataSaga;
