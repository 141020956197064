import { toast } from "react-toastify";

const {
  Get_GROW_BOOK_CATEGORY,
  GET_GROW_BOOK_AUTHORS,
  DELETE_GROW_BOOK_AUTHOR,
  SAVE_GROW_BOOK_AUTHOR,
  GROW_CREATE_BOOK_CATEGORY,
  GROW_BOOK_CATEGORY_DELETE,
  LIST_ALL_BOOKS,
  GROW_BOOKS_DELETE,
  SAVE_GROW_BOOKS_URL,
  LIST_AUTHOR_NAME_URL,
  LIST_BOOK_CATEGORY_URL,
} = require("helpers/url_helper");

const {
  getGrowBookCategoryList,
  handelLoaderAuthor,
  getGrowAuthorList,
  getGrowAuthorData,
  handleLoaderAddEditAuthor,
  handleAddBookCategory,
  getGrowBookCategory,
  getAllBooksList,
  getAllBooks,
  handleAuthorNameSuccess,
  handleCategoryBookSuccess,
  handleLoaderAddEditBookCategory,
  handleLoaderListBookCategory,
  handleOpenAddEdit,
} = require("./action");

const {
  GROW_BOOK_CATEGORY,
  GROW_AUTHOR,
  GROW_DELETE_AUTHOR,
  GROW_AUTHOR_LIST_SAVE,
  GROW_BOOK_CATEGORY_CREATE,
  GROW_DELETE_BOOK_CATEGORY,
  LIST_ALL_GROW_BOOKS,
  GROW_DELETE_BOOKS,
  SAVE_GROW_BOOKS,
  GROW_AUTHOR_NAME_LIST,
  GROW_BOOK_CATEGORY_LIST,
} = require("./actionType");
const { axiosApiAdmin } = require("helpers/api_helper");
const { call, put, takeEvery } = require("redux-saga/effects");

const fetchGrowBookCategory = data =>
  axiosApiAdmin.post(Get_GROW_BOOK_CATEGORY, data);
const addGrowBooksCategory = data =>
  axiosApiAdmin.post(GROW_CREATE_BOOK_CATEGORY, data);
const growBookCategoryDelete = data =>
  axiosApiAdmin.post(GROW_BOOK_CATEGORY_DELETE, data);

const fetchGrowAuthor = data => axiosApiAdmin.post(GET_GROW_BOOK_AUTHORS, data);

const growAuthorDelete = data =>
  axiosApiAdmin.post(DELETE_GROW_BOOK_AUTHOR, data);

const growAuthorAddEdit = data =>
  axiosApiAdmin.post(SAVE_GROW_BOOK_AUTHOR, data);

const fetchAllBooksList = data => axiosApiAdmin.post(LIST_ALL_BOOKS, data);
const growBooksDelete = data => axiosApiAdmin.post(GROW_BOOKS_DELETE, data);
const addSaveBooks = data => axiosApiAdmin.post(SAVE_GROW_BOOKS_URL, data);
const listAuthorsName = () => axiosApiAdmin.get(LIST_AUTHOR_NAME_URL);
const listBookCategoryList = () => axiosApiAdmin.get(LIST_BOOK_CATEGORY_URL);

function* handleFetchGrowBooksCategory({ payload }) {
  try {
    yield put(handleLoaderListBookCategory(true));
    const res = yield call(fetchGrowBookCategory, payload);
    yield put(getGrowBookCategoryList(res?.data?.data?.bookCategory));
    yield put(handleLoaderListBookCategory(false));
  } catch (err) {
    // yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAddGrowBookCategory({ payload, callBack }) {
  try {
    yield put(handleLoaderAddEditBookCategory(true));
    const res = yield call(addGrowBooksCategory, payload);
    if (res) {
      yield put(handleAddBookCategory(false));
      yield put(handleLoaderAddEditBookCategory(false));
      callBack(res);
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err);
  }
}
function* handleDeleteGrowBookCategory({ payload }) {
  try {
    const res = yield call(growBookCategoryDelete, payload.deleteId);
    if (res) {
      yield put(
        getGrowBookCategory({
          orders: "DESC",
        })
      );
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err?.response?.data?.message || err.message);
  }
}

function* handleFetchGrowAuthor({ payload }) {
  try {
    yield put(handelLoaderAuthor(true));
    const res = yield call(fetchGrowAuthor, payload);
    yield put(getGrowAuthorList(res.data.data.bookAuthors));
    yield put(handelLoaderAuthor(false));
  } catch (err) {
    // yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleDeleteGrowAuthor({ payload }) {
  try {
    const res = yield call(growAuthorDelete, payload.deleteId);
    if (res) {
      yield put(
        getGrowAuthorData({
          orders: "DESC",
        })
      );
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err?.response?.data?.message || err.message);
  }
}

function* handelAddEditAuthor({ payload }) {
  try {
    yield put(handleLoaderAddEditAuthor(true));
    const res = yield call(growAuthorAddEdit, payload);
    if (res) {
      yield put(handleLoaderAddEditAuthor(false));
      yield put(handleOpenAddEdit(false));
      toast.success(res.data.message);
    }
  } catch (err) {
    yield put(handleLoaderAddEditAuthor(false));
    toast.error(err);
  }
}

function* handleFetchAllBooksList({ payload }) {
  try {
    const res = yield call(fetchAllBooksList, payload);
    yield put(getAllBooksList(res?.data?.data?.books));
  } catch (err) {
    toast.error(err);
  }
}

function* handleDeleteGrowBooks({ payload }) {
  try {
    const res = yield call(growBooksDelete, payload.deleteId);
    if (res) {
      yield put(
        getAllBooks({
          orders: "ASC",
        })
      );
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err);
  }
}

function* handleSaveGrowBooks({ payload }) {
  try {
    const res = yield call(addSaveBooks, payload);
    if (res) {
      yield put(
        getAllBooks({
          orders: "ASC",
        })
      );
      toast.success(res.data.message);
    }
  } catch (err) {
    toast.error(err);
  }
}

function* handleListAuthorName() {
  try {
    const res = yield call(listAuthorsName);
    if (res) {
      yield put(handleAuthorNameSuccess(res?.data?.data?.bookAuthorData));
    }
  } catch (err) {
    toast.error(err);
  }
}

function* handleListCategoryName() {
  try {
    const res = yield call(listBookCategoryList);
    if (res) {
      yield put(handleCategoryBookSuccess(res?.data?.data.bookCategoryData));
    }
  } catch (err) {
    toast.error(err);
  }
}

function* GrowBooksSaga() {
  yield takeEvery(GROW_BOOK_CATEGORY, handleFetchGrowBooksCategory);
  yield takeEvery(GROW_AUTHOR, handleFetchGrowAuthor);
  yield takeEvery(GROW_DELETE_AUTHOR, handleDeleteGrowAuthor);
  yield takeEvery(GROW_AUTHOR_LIST_SAVE, handelAddEditAuthor);
  yield takeEvery(GROW_BOOK_CATEGORY_CREATE, handleAddGrowBookCategory);
  yield takeEvery(GROW_DELETE_BOOK_CATEGORY, handleDeleteGrowBookCategory);
  yield takeEvery(LIST_ALL_GROW_BOOKS, handleFetchAllBooksList);
  yield takeEvery(GROW_DELETE_BOOKS, handleDeleteGrowBooks);
  yield takeEvery(SAVE_GROW_BOOKS, handleSaveGrowBooks);
  yield takeEvery(GROW_AUTHOR_NAME_LIST, handleListAuthorName);
  yield takeEvery(GROW_BOOK_CATEGORY_LIST, handleListCategoryName);
}

export default GrowBooksSaga;
