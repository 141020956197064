import { StateChanger } from "../utils";
import {
  CREATE_UPDATE_CARD_BOARD,
  CREATE_UPDATE_CARD_LOADING,
  DELETE_BOARD_TEMPLATE_DATA,
  DELETE_CARD_LOADING,
  EDITOR_LIST_CANVAS_BOARD,
  EDIT_BOARD_TEMPLATE_DATA,
  FETCH_DIGITAL_BOARD_DATA,
  FETCH_DIGITAL_BOARD_DATA_ERROR,
  FETCH_DIGITAL_BOARD_DATA_SUCCESS,
  // UPDATE_CARD_DETAILS,
  LOADING_BOARD_DETAILS,
  SET_ACTIVE_CARD_DATA,
  SET_BACKGROUND_DETAILS,
  SET_BACKGROUND_IMAGES,
  SET_BACKGROUND_IMAGES_LOADING,
  SET_BOARD_DATA,
  SET_BOARD_TABLE_LOADER,
  SET_BOARD_TITLE,
  SET_CANVAS,
  SET_CANVAS_CARD_COORDINATE,
  SET_DIGITAL_BOARD_DATA_PAGE,
  SET_DIGITAL_BOARD_DATA_SEARCH,
  SET_DIGITAL_BOARD_DATA_SIZE_PER_PAGE,
  SET_DIGITAL_BOARD_DATA_SORT_FIELD,
  SET_DIGITAL_BOARD_DATA_SORT_ORDER,
  SET_FETCH_BOARD,
  SET_IMPORTED_DATA,
  SET_INVITED_USERS,
  SET_IS_INVITED_USER,
  SET_IS_PUBLIC,
  SET_OWNER_INFO,
  SET_PUBLISH_ADMIN_TEMPLATE,
  SET_ZOOM_LEVEL,
} from "./actionTypes";

const initialState = {
  //header
  loading: false,
  title: "New Board",
  publish_admin_template: 1,
  backgroundData: "",
  backgroundType: 1,
  loadingBackgroundImages: false,
  backgroundImages: [],
  loadingCreateUpdateCard: false,

  //table
  boardData: {
    rows: [],
    count: 0,
    pageNo: 1,
    pageRecord: 10,
    sortField: "created_datetime",
    sortOrder: "ASC",
    search: "",
  },

  boardAllData: {},
  importedBoardData: {},

  createCard: {},
  //editor
  selectedRowId: 0,
  selectedCardId: 0,
  selectedCards: {},
  cards: {},
  rows: {},
  copyCardId: 0,
  loadingCardIds: [],
  isSeparating: false,
  isDragging: false,
  invitedUsers: [],
  ownerInfo: {
    name: "",
    imgUrl: "",
    email: "",
  },
  isInvitedUser: false,
  isPublic: 0,
  deleteLoading: false,

  //canvas
  boardCards: {},
  zoomLevel: 1,
  canvasCoords: {},
  canvasLoading: false,
  boardLoading: false,

  //table
  boardTableLoading: false,
  activeCardData: null,
};

const BoardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const helper = new StateChanger(state);

  switch (type) {
    case EDIT_BOARD_TEMPLATE_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_BOARD_DATA:
        return helper.change("boardAllData", payload);
    case SET_IMPORTED_DATA:
        return helper.change("importedBoardData", payload);
    case DELETE_BOARD_TEMPLATE_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_BOARD_TITLE:
      return helper.change("title", payload);
    case SET_PUBLISH_ADMIN_TEMPLATE:
      return helper.change("publish_admin_template", payload);
    case LOADING_BOARD_DETAILS:
      return helper.change("loading", payload);
    case DELETE_CARD_LOADING:
      return helper.change("deleteLoading", payload);
    case SET_BACKGROUND_DETAILS:
      return helper.change(
        ["backgroundData", "backgroundType"],
        [payload.backgroundData, payload.backgroundType]
      );
    case SET_BACKGROUND_IMAGES_LOADING:
      return helper.change("loadingBackgroundImages", payload);
    case SET_BACKGROUND_IMAGES:
      return helper.change("backgroundImages", payload);
    case CREATE_UPDATE_CARD_LOADING:
      return helper.change("loadingCreateUpdateCard", payload);
    case SET_CANVAS:
      return helper.change("canvasLoading", payload);
    case CREATE_UPDATE_CARD_BOARD:
      return helper.change("createCard", payload);
    case SET_CANVAS_CARD_COORDINATE:
      return helper.change("canvasCoords", payload);
    case SET_FETCH_BOARD:
      return helper.change("boardLoading", payload);
    case EDITOR_LIST_CANVAS_BOARD:
      return helper.change("boardCards", payload);
    case SET_ZOOM_LEVEL:
      return helper.change("zoomLevel", payload);
    case SET_BOARD_TABLE_LOADER:
      return helper.change("boardTableLoading", payload);
    // case UPDATE_CARD_DETAILS:
    // return helper.change("cards", {
    //   ...state.cards,
    //   [payload.card_id]: { ...payload },

    case SET_OWNER_INFO:
      return helper.change("ownerInfo", payload);
    case SET_INVITED_USERS:
      return helper.change("invitedUsers", payload);
    case SET_IS_INVITED_USER:
      return helper.change("isInvitedUser", payload);
    case SET_IS_PUBLIC:
      return helper.change("isPublic", payload);

    case FETCH_DIGITAL_BOARD_DATA:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DIGITAL_BOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        boardData: {
          ...state.boardData,
          rows: payload.rows,
          count: payload.count,
        },
      };
    case FETCH_DIGITAL_BOARD_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SET_DIGITAL_BOARD_DATA_PAGE:
      return {
        ...state,
        boardData: {
          ...state.boardData,
          pageNo: payload,
        },
      };
    case SET_DIGITAL_BOARD_DATA_SIZE_PER_PAGE:
      return {
        ...state,
        boardData: {
          ...state.boardData,
          pageRecord: payload,
        },
      };
    case SET_DIGITAL_BOARD_DATA_SORT_FIELD:
      return {
        ...state,
        boardData: {
          ...state.boardData,
          sortField: payload,
        },
      };
    case SET_DIGITAL_BOARD_DATA_SORT_ORDER:
      return {
        ...state,
        boardData: {
          ...state.boardData,
          sortOrder: payload,
        },
      };
    case SET_DIGITAL_BOARD_DATA_SEARCH:
      return {
        ...state,
        boardData: {
          ...state.boardData,
          search: payload,
        },
      };
    case SET_ACTIVE_CARD_DATA:
      return {
        ...state,
        activeCardData: payload,
      };
    default:
      return state;
  }
};

export default BoardReducer;
