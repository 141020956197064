import { axiosApiAdmin } from "helpers/api_helper";
import {
  FETCH_TOTAL_SUBSCRIPTION_URL,
  LIST_ALL_SUBSCRIPTION,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  fetchApplicationSubscriptionsError,
  fetchApplicationSubscriptionsSuccess,
  fetchApplicationTotalSubscriptionsError,
  fetchApplicationTotalSubscriptionsSuccess,
  setErrorMessage,
} from "store/actions";
import {
  FETCH_APPLICATION_SUBSCRIPTIONS,
  FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS,
} from "./actionTypes";

const fetchSubscriptions = data =>
  axiosApiAdmin.post(LIST_ALL_SUBSCRIPTION, data);
const fetchTotalSubscriptionDetails = data =>
  axiosApiAdmin.get(`${FETCH_TOTAL_SUBSCRIPTION_URL}/${data}`);

function* handleFetchTotalSubscriptionDetails() {
  try {
    const { siteId } = yield select(state => state.Applications);
    const res = yield call(fetchTotalSubscriptionDetails, siteId);
    if (res.data?.data?.subscription) {
      yield put(
        fetchApplicationTotalSubscriptionsSuccess(res.data.data.subscription)
      );
    }
  } catch (err) {
    yield put(fetchApplicationTotalSubscriptionsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleFetchSubscriptions() {
  try {
    const { search, page, sizePerPage, sortField, sortOrder, activeStatus } =
      yield select(state => state.ApplicationSubscriptions);
    const { siteId } = yield select(state => state.Applications);

    const payload = {
      search,
      page_no: page,
      page_record: sizePerPage,
      site_id: siteId,
      user_id: 0,
      sortField,
      sortOrder,
      subscription_status: activeStatus,
    };

    const res = yield call(fetchSubscriptions, payload);
    if (res.data?.data?.subscription_list) {
      yield put(
        fetchApplicationSubscriptionsSuccess({
          data: res.data.data.subscription_list,
          count: res.data.data.count,
        })
      );
    }
  } catch (err) {
    yield put(fetchApplicationSubscriptionsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* applicationSubscriptionsSaga() {
  yield takeEvery(FETCH_APPLICATION_SUBSCRIPTIONS, handleFetchSubscriptions);
  yield takeEvery(
    FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS,
    handleFetchTotalSubscriptionDetails
  );
}

export default applicationSubscriptionsSaga;
