import { axiosApiAdmin } from "helpers/api_helper";
import {
  GET_ORDER_GRAPH_DATA,
  GET_ORDER_REPORTS_DATA,
  GET_ORDER_TABLE_DATA,
} from "helpers/url_helper";
import { tzs } from "modules/Analytics/common/constants";
import { DateTimeTz } from "modules/Analytics/common/utils";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  getOrderGraphDataError,
  getOrderGraphDataSuccess,
  getOrderReportsError,
  getOrderReportsSuccess,
  getOrderTableDataError,
  getOrderTableDataSuccess,
  setErrorMessage,
} from "store/actions";
import {
  FETCH_ORDER_GRAPH_DATA,
  FETCH_ORDER_REPORTS,
  FETCH_ORDER_TABLE_DATA,
} from "./actionsTypes";

const fetchOrderReport = payload =>
  axiosApiAdmin.post(GET_ORDER_REPORTS_DATA, payload);
const fetchOrderGraphData = payload =>
  axiosApiAdmin.post(GET_ORDER_GRAPH_DATA, payload);
const fetchOrderTableData = payload =>
  axiosApiAdmin.post(GET_ORDER_TABLE_DATA, payload);

function* handleFetchOrderTableData() {
  try {
    const {
      ordersTableData: { page, sizePerPage, sortOrder, sortField },
      range,
      siteId,
      tz,
    } = yield select(state => state.AnalyticsOrders);

    const payload = {
      page_record: sizePerPage,
      page_no: page,
      sort_field: sortField,
      sort_order: sortOrder,
      range: {
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(range.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      site_id: siteId,
      tz: tzs[tz],
    };

    const res = yield call(fetchOrderTableData, payload);
    if (res.status) {
      if (res?.status && res.data?.data)
        yield put(
          getOrderTableDataSuccess({
            totalSize: res.data.data.total_orders,
            ordersCount: 0,
            rows: res.data.data.rows,
          })
        );
    }
  } catch (err) {
    yield put(getOrderTableDataError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* handleFetchOrderGraphData() {
  try {
    const { range, comparedRange, activeTab, by, siteId, tz } = yield select(
      state => state.AnalyticsOrders
    );

    const payload = {
      range: {
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(range.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      compared_range: {
        start_date: DateTimeTz(comparedRange.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(comparedRange.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      by,
      type: activeTab,
      site_id: siteId,
      tz: tzs[tz],
    };
    const res = yield call(fetchOrderGraphData, payload);
    if (res?.status && res?.data?.data?.graph) {
      yield put(getOrderGraphDataSuccess(res.data.data.graph));
    }
  } catch (err) {
    yield put(getOrderGraphDataError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* handleFetchOrderReports() {
  try {
    const { range, comparedRange, siteId, tz } = yield select(
      state => state.AnalyticsOrders
    );
    const payload = {
      range: {
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(range.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      compared_range: {
        start_date: DateTimeTz(comparedRange.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(comparedRange.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      site_id: siteId,
      tz: tzs[tz],
    };
    const res = yield call(fetchOrderReport, payload);
    if (res?.status && res?.data?.data?.reports) {
      yield put(getOrderReportsSuccess(res.data.data.reports));
    }
  } catch (err) {
    yield put(getOrderReportsError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* OrdersSaga() {
  yield takeEvery(FETCH_ORDER_REPORTS, handleFetchOrderReports);
  yield takeEvery(FETCH_ORDER_GRAPH_DATA, handleFetchOrderGraphData);
  yield takeEvery(FETCH_ORDER_TABLE_DATA, handleFetchOrderTableData);
}

export default OrdersSaga;
