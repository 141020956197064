import permissions from "constants/permissions";
import {
  GET_ANALYTICS_PRODUCT_GRAPH_URL,
  GET_CANCELLED_SUBSCRIPTION_GRAPH_DATA,
  GET_COUPONS_GRAPH_DATA,
  GET_REVENUE_GRAPH_DATA,
  LIST_ANALYTICS_REGISTER_USERS_GRAPH_DATA,
  LIST_RENEWED_SUBSCRIPTION_CHART,
} from "helpers/url_helper";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { kFormatter } from "../utils";

export const compareTypes = {
  PERIOD: "period",
  MONTH: "month",
  QUARTER: "quarter",
  YEAR: "year",
};

export const performanceReportsKey = {
  total_sales: {
    title: "Total Sales",
    showCurrencySymbol: true,
    redirectURL: "/analytics/revenues",
    permission: permissions.total_sales,
  },
  net_sales: {
    title: "Net Sales",
    showCurrencySymbol: true,
    redirectURL: "/analytics/revenues",
    permission: permissions.net_sales,
  },
  registered_users: {
    title: "Registered Users",
    showCurrencySymbol: false,
    redirectURL: "/analytics/users",
    permission: permissions.registered_users,
  },
  subscribed_users: {
    title: "Subscribed Users",
    showCurrencySymbol: false,
    redirectURL: "/analytics/users",
    permission: permissions.subscribed_users,
  },
  orders: {
    title: "Orders",
    showCurrencySymbol: false,
    permission: permissions.orders,
  },
  discounted_orders: {
    title: "Discounted Orders",
    showCurrencySymbol: false,
    redirectURL: "/analytics/coupons",
    permission: permissions.discounted_orders,
  },
  items_sold: {
    title: "Items Sold",
    showCurrencySymbol: false,
    redirectURL: "/analytics/products",
    permission: permissions.items_sold,
  },
  net_discounted_amount: {
    title: "Net Discounted Amount",
    showCurrencySymbol: true,
    redirectURL: "/analytics/coupons",
    permission: permissions.net_discounted_amount,
  },
};

export const tzs = {
  "-0400": 1,
  "0000": 2,
};

const durationKeys = [
  { title: "Monthly", key: "monthly" },
  { title: "Quarterly", key: "quarterly" },
  { title: "Yearly", key: "yearly" },
];

const ageGroupKeys = [
  { title: "Kids", key: "grow_kids" },
  { title: "Students", key: "grow_students" },
  { title: "Groups", key: "grow_groups" },
];

export const subscriptionReportsKey = {
  active_subscriptions: {
    title: "Active Subscriptions",
    cardType: 2,
    key: "value",
    permission: permissions.active_subscriptions,
    allowedSites: [0, 2, 13],
    showValueKeysForSites: [0],
  },
  grow_kids: {
    title: "Grow Kids",
    valueKeys: [...durationKeys],
    cardType: 1,
    permission: permissions.grow_kids,
    allowedSites: [0, 2],
    showValueKeysForSites: [0, 2],
  },
  grow_students: {
    title: "Grow Students",
    valueKeys: [...durationKeys],
    cardType: 1,
    permission: permissions.grow_students,
    allowedSites: [0, 2],
    showValueKeysForSites: [0, 2],
  },
  grow_groups: {
    title: "Grow Groups",
    valueKeys: [...durationKeys],
    cardType: 1,
    permission: permissions.grow_groups,
    allowedSites: [0, 2],
    showValueKeysForSites: [0, 2],
  },
  monthly: {
    title: "Monthly",
    valueKeys: [...ageGroupKeys],
    cardType: 1,
    permission: permissions.monthly,
    allowedSites: [0, 2, 3, 5, 6],
    showValueKeysForSites: [0, 2],
  },
  quarterly: {
    title: "Quarterly",
    valueKeys: [...ageGroupKeys],
    cardType: 1,
    permission: permissions.quarterly,
    allowedSites: [0, 2],
    showValueKeysForSites: [0, 2],
  },
  yearly: {
    title: "Yearly",
    valueKeys: [...ageGroupKeys],
    cardType: 1,
    permission: permissions.yearly,
    allowedSites: [0, 2, 3, 5, 6],
    showValueKeysForSites: [0, 2],
  },
};

export const chartData = [
  {
    title: "Total Sales",
    permission: permissions.gross_sales_chart,
    endPoint: GET_REVENUE_GRAPH_DATA,
    key: "gross_sales",
    convertData: data => {
      return data.data.graph;
    },
    showCurrencySymbol: true,
  },
  {
    title: "Net Sales",
    permission: permissions.net_sales_chart,
    endPoint: GET_REVENUE_GRAPH_DATA,
    key: "net_sales",
    convertData: data => {
      return data.data.graph;
    },
    showCurrencySymbol: true,
  },
  {
    title: "Orders",
    permission: permissions.orders_chart,
    endPoint: GET_ANALYTICS_PRODUCT_GRAPH_URL,
    key: "orders",
    convertData: data => {
      return data.data.rows.map(row => ({
        cp: row.current.current_count,
        cp_date_time: row.current.start_date,
        pp: row.previous.previous_count,
        pp_date_time: row.previous.start_date,
      }));
    },
    showCurrencySymbol: false,
    convertPayload: ({ range, compared_range, by, siteId, tz }) => ({
      current_range: range,
      previous_range: compared_range,
      filter: by,
      site_id: siteId,
      tz,
    }),
  },
  {
    title: "Discounted Orders",
    permission: permissions.discounted_orders_chart,
    endPoint: GET_COUPONS_GRAPH_DATA,
    key: "discount_orders",
    convertData: data => {
      return data.data.rows.map(row => ({
        cp: row.current.current_count,
        cp_date_time: row.current.start_date,
        pp: row.previous.previous_count,
        pp_date_time: row.previous.start_date,
      }));
    },
    showCurrencySymbol: false,
    convertPayload: ({ range, compared_range, by, siteId, tz }) => ({
      current_range: range,
      previous_range: compared_range,
      filter: by,
      type: "discount_orders",
      site_id: siteId,
      tz,
    }),
  },
  {
    title: "Net Discounted Amount",
    permission: permissions.net_discounted_amount_chart,
    endPoint: GET_COUPONS_GRAPH_DATA,
    key: "net_discounted_amount",
    convertData: data => {
      return data.data.rows.map(row => ({
        cp: row.current.current_count,
        cp_date_time: row.current.start_date,
        pp: row.previous.previous_count,
        pp_date_time: row.previous.start_date,
      }));
    },
    showCurrencySymbol: true,
    convertPayload: ({ range, compared_range, by, siteId, tz }) => ({
      current_range: range,
      previous_range: compared_range,
      filter: by,
      type: "amount",
      site_id: siteId,
      tz,
    }),
  },
  {
    title: "Registered Users",
    permission: permissions.registered_users_chart,
    endPoint: LIST_ANALYTICS_REGISTER_USERS_GRAPH_DATA,
    key: "registered_users",
    convertData: data => {
      return data.data.rows.map(row => ({
        cp: row.current.current_count,
        cp_date_time: row.current.start_date,
        pp: row.previous.previous_count,
        pp_date_time: row.previous.start_date,
      }));
    },
    showCurrencySymbol: false,
    convertPayload: ({ range, compared_range, by, siteId, tz }) => ({
      current_year_date_range: range,
      past_year_date_range: compared_range,
      by,
      site_id: siteId,
      tz,
    }),
  },
  {
    title: "Renewed Subscriptions",
    permission: permissions.renewed_subscriptions_chart,
    endPoint: LIST_RENEWED_SUBSCRIPTION_CHART,
    key: "renewed_subscriptions",
    convertData: data => {
      return data.data.rows.map(row => ({
        cp: row.current.current_count,
        cp_date_time: row.current.start_date,
        pp: row.previous.previous_count,
        pp_date_time: row.previous.start_date,
      }));
    },
    showCurrencySymbol: false,
    convertPayload: ({ range, compared_range, by, siteId, tz }) => ({
      current_range: range,
      previous_range: compared_range,
      filter_renew: by,
      site_id: siteId,
      tz,
    }),
  },
  {
    title: "Cancelled Subscriptions",
    permission: permissions.cancelled_subscriptions_chart,
    endPoint: GET_CANCELLED_SUBSCRIPTION_GRAPH_DATA,
    key: "cancelled_subscriptions",
    convertData: data => {
      return data.data.graph;
    },
    showCurrencySymbol: false,
    convertPayload: ({ range, compared_range, by, siteId, tz }) => ({
      range,
      compared_range,
      by,
      site_id: siteId,
      tz,
    }),
  },
];

const TOP_CUSTOMERS = "/topCustomerByTotalSpend";
const TOP_PRODUCTS = "/topProductsByItemSold";
const TOP_COUPONS = "/topCouponsByNumberOfOrders";

const POST = "post";

export const leaderBoardsData = [
  {
    title: "Top Customers - Total Spend",
    permission: permissions.top_customers,
    id: "top_customers",
    columns: [
      {
        dataField: "first_name",
        text: "Customer Name",
        formatter: (cellContent, row) => {
          return (
            <>
              <p className="mb-0">
                {row.first_name} {row.last_name}
              </p>
              <Link
                to={`/${row.user_role === 3 ? "customers" : "staff"}/profile/${
                  row.user_id
                }`}
                className="mb-0"
              >
                ({row.email})
              </Link>
            </>
          );
        },
      },
      {
        dataField: "orders",
        text: "Orders",
      },
      {
        dataField: "total_transaction",
        text: "Total Spend",
        formatter: (cellContent, row) => {
          return (
            <>
              <span id={`user_spend_${row.user_id}`}>
                ${kFormatter(row.total_transaction || 0)}
              </span>
              <UncontrolledTooltip target={`user_spend_${row.user_id}`}>
                ${row.total_transaction.toFixed(2)}
              </UncontrolledTooltip>
            </>
          );
        },
      },
    ],
    viewAllConfig: {
      show: true,
      redirectUrl: "/dashboard/leader-board/top_customers",
    },
    keyField: "user_id",
    endPoint: TOP_CUSTOMERS,
    method: POST,
    convertData: data => {
      return data?.data?.top_5_high_spended_customers || [];
    },
    convertPayload: ({ startDate, endDate, siteId }) => ({
      start_date: startDate,
      end_date: endDate,
      page_no: 1,
      page_record: 5,
      site_id: siteId,
    }),
  },
  {
    title: "Top Coupons - Number of Orders",
    permission: permissions.top_coupons,
    id: "top_coupons",
    columns: [
      {
        dataField: "coupon_code",
        text: "Coupon Code",
      },
      {
        dataField: "user_orders_count",
        text: "Orders",
      },
      {
        dataField: "discount_coupon_amount",
        text: "Amount discounted",
        formatter: (cellContent, row) => {
          return (
            <>
              <span id={`user_spend_${row.coupon_id}`}>
                ${kFormatter(row.discount_coupon_amount || 0)}
              </span>
              <UncontrolledTooltip target={`user_spend_${row.coupon_id}`}>
                ${row.discount_coupon_amount.toFixed(2)}
              </UncontrolledTooltip>
            </>
          );
        },
      },
    ],
    viewAllConfig: {
      show: true,
      redirectUrl: "/dashboard/leader-board/top_coupons",
    },
    keyField: "coupon_id",
    endPoint: TOP_COUPONS,
    method: POST,
    convertData: data => {
      return data?.data?.top_5_highest_coupons_use_in_product || [];
    },
    convertPayload: ({ startDate, endDate, siteId }) => ({
      start_date: startDate,
      end_date: endDate,
      page_no: 1,
      page_record: 5,
      site_id: siteId,
    }),
  },
  {
    title: "Top Products - Items Sold",
    id: "top_products",
    permission: permissions.top_products,
    columns: [
      {
        dataField: "product_name",
        text: "Product",
      },
      {
        dataField: "total_products",
        text: "Items sold",
      },
      {
        dataField: "total_product_amount",
        text: "Net Sales",
        formatter: (cellContent, row) => {
          return (
            <>
              <span id={`top_products_${row.product_id}`}>
                ${kFormatter(row.total_product_amount || 0)}
              </span>
              <UncontrolledTooltip target={`top_products_${row.product_id}`}>
                ${row.total_product_amount.toFixed(2)}
              </UncontrolledTooltip>
            </>
          );
        },
      },
    ],
    viewAllConfig: {
      show: true,
      redirectUrl: "/dashboard/leader-board/top_products",
    },
    keyField: "product_id",
    endPoint: TOP_PRODUCTS,
    method: POST,
    convertData: data => {
      return data?.data?.high_solded_products || [];
    },
    convertPayload: ({ startDate, endDate, siteId }) => ({
      page_no: 1,
      page_record: 5,
      start_date: startDate,
      end_date: endDate,
      site_id: siteId,
    }),
  },
];

export const analyticsDashboardSettingsConfig = {
  performance: {
    total_sales: {
      title: "Total Sales",
      value: 1,
      permission: permissions.total_sales,
    },
    net_sales: {
      title: "Net Sales",
      value: 1,
      permission: permissions.net_sales,
    },
    registered_users: {
      title: "Registered Users",
      value: 1,
      permission: permissions.registered_users,
    },
    subscribed_users: {
      title: "Subscribed Users",
      value: 1,
      permission: permissions.subscribed_users,
    },
    orders: { title: "Orders", value: 1, permission: permissions.orders },
    discounted_orders: {
      title: "Discounted Orders",
      value: 1,
      permission: permissions.discounted_orders,
    },
    items_sold: {
      title: "Items Sold",
      value: 1,
      permission: permissions.items_sold,
    },
    net_discounted_amount: {
      title: "Net Discounted Amount",
      value: 1,
      permission: permissions.net_discounted_amount,
    },
  },
  subscription: {
    active_subscriptions: {
      title: "Active Subscriptions",
      value: 1,
      permission: permissions.active_subscriptions,
    },
    grow_kids: {
      title: "Grow Kids",
      value: 1,
      permission: permissions.grow_kids,
    },
    grow_students: {
      title: "Grow Students",
      value: 1,
      permission: permissions.grow_students,
    },
    grow_groups: {
      title: "Grow Groups",
      value: 1,
      permission: permissions.grow_groups,
    },
    monthly: { title: "Monthly", value: 1, permission: permissions.monthly },
    quarterly: {
      title: "Quarterly",
      value: 1,
      permission: permissions.quarterly,
    },
    yearly: { title: "Yearly", value: 1, permission: permissions.yearly },
  },
  chart: {
    gross_sales: {
      title: "Total Sales",
      value: 1,
      permission: permissions.gross_sales_chart,
    },
    net_sales: {
      title: "Net Sales",
      value: 1,
      permission: permissions.net_sales_chart,
    },
    orders: { title: "Orders", value: 1, permission: permissions.orders_chart },
    discount_orders: {
      title: "Discounted Orders",
      value: 1,
      permission: permissions.discounted_orders_chart,
    },
    net_discounted_amount: {
      title: "Net Discounted Amount",
      value: 1,
      permission: permissions.net_discounted_amount_chart,
    },
    registered_users: {
      title: "Registered Users",
      value: 1,
      permission: permissions.registered_users_chart,
    },
    renewed_subscriptions: {
      title: "Renewed Subscriptions",
      value: 1,
      permission: permissions.renewed_subscriptions_chart,
    },
    cancelled_subscriptions: {
      title: "Cancelled Subscriptions",
      value: 1,
      permission: permissions.cancelled_subscriptions_chart,
    },
    sales_analytics: {
      title: "Sales Analytics",
      value: 1,
      permission: permissions.sales_analytics_chart,
    },
  },
  leader_board: {
    top_customers: {
      title: "Top Customers",
      value: 1,
      permission: permissions.top_customers,
    },
    top_coupons: {
      title: "Top Coupons",
      value: 1,
      permission: permissions.top_coupons,
    },
    top_products: {
      title: "Top Products",
      value: 1,
      permission: permissions.top_products,
    },
  },
};

export const timeZones = [
  {
    tz: "EDT (UTC-04:00)",
    utcOffSet: "-0400",
  },
  {
    tz: "UTC",
    utcOffSet: "0000",
  },
];
