import moment from "moment";
import {
  ANALYTICS_EMAIL_SET_TZ,
  CLEAR_VIEW_TOTAL_DATA,
  FETCH_ANALYTICS_EMAIL_SITES,
  GET_ANALYTICS_EMAIL_GRAPH,
  GET_ANALYTICS_EMAIL_GRAPH_ERROR,
  GET_ANALYTICS_EMAIL_GRAPH_SUCCESS,
  GET_EMAIL_LIST,
  GET_EMAIL_LIST_ERROR,
  GET_EMAIL_LIST_SUCCESS,
  GET_TOTAL_BY_DATE,
  GET_TOTAL_BY_DATE_ERROR,
  GET_TOTAL_BY_DATE_SUCCESS,
  GET_TOTAL_PAGE,
  GET_TOTAL_SEARCH,
  GET_TOTAL_SIZE_PER_PAGE,
  GET_TOTAL_SORT_FIELD,
  GET_TOTAL_SORT_ORDER,
  SET_ANALYTICS_EMAILS_RANGE_DATE,
  SET_ANALYTICS_EMAIL_BY,
  SET_ANALYTICS_EMAIL_GRAPH_BY,
  SET_ANALYTICS_EMAIL_PAGE,
  SET_ANALYTICS_EMAIL_SEARCH,
  SET_ANALYTICS_EMAIL_SIZE_PER_PAGE,
  SET_ANALYTICS_EMAIL_SORT_FIELD,
  SET_ANALYTICS_EMAIL_SORT_ORDER,
} from "./actionTypes";

import DateRange from "modules/Analytics/common/utils";

const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();

const initialState = {
  chartData: [],
  range: {
    startDate,
    endDate,
  },
  table: {
    search: "",
    pageRecord: 25,
    pageNo: 1,
    totalRecord: 0,
    loading: false,
    emails: [],
    sortOrder: "DESC",
    sortField: "value",
  },
  loadingGraph: false,
  by: "date",
  loadingTotalByDate: false,
  totalByDateData: [],
  totalPages: 1,
  totalSizePerPage: 25,
  totalTotalSize: 0,
  totalSearch: "",
  totalSortOrder: "DESC",
  totalSortField: "created_datetime",
  emailGraphBy: "day",
  loadingSite: false,
  sites: 0,
  tz: "-0400",
};

const analyticsEmailsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ANALYTICS_EMAIL_SET_TZ:
      return {
        ...state,
        tz: payload,
      };
    // Report
    case SET_ANALYTICS_EMAILS_RANGE_DATE:
      const prevBy = state.emailGraphBy;
      const newBy = new DateRange(
        payload.startDate,
        payload.endDate
      ).getByOptions()[0].value;
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
        comparedRange: new DateRange(
          payload.startDate,
          payload.endDate
        ).getPreviousDates(state.compareToPrevious),
        ...(prevBy !== newBy ? { emailGraphBy: newBy } : {}),
      };

    // Graph
    case GET_ANALYTICS_EMAIL_GRAPH:
      return {
        ...state,
        loadingGraph: true,
      };
    case GET_ANALYTICS_EMAIL_GRAPH_SUCCESS:
      return {
        ...state,
        loadingGraph: false,
        chartData: payload.map(x => ({
          date: x.date,
          sent_count: x.sent_count,
          failed_count: x.failed_count,
        })),
      };
    case GET_ANALYTICS_EMAIL_GRAPH_ERROR:
      return {
        ...state,
        loadingGraph: false,
      };

    // Table
    case GET_EMAIL_LIST:
      return {
        ...state,
        table: {
          ...state.table,
          loading: true,
        },
      };
    case GET_EMAIL_LIST_SUCCESS:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
          emails: payload?.rows || [],
          totalRecord: payload?.count || 0,
        },
      };
    case GET_EMAIL_LIST_ERROR:
      return {
        ...state,
        table: {
          ...state.table,
          loading: false,
        },
      };
    case SET_ANALYTICS_EMAIL_SEARCH:
      return {
        ...state,
        table: {
          ...state.table,
          search: payload,
          pageNo: 1,
        },
      };
    case SET_ANALYTICS_EMAIL_PAGE:
      return {
        ...state,
        table: {
          ...state.table,
          pageNo: payload,
        },
      };
    case SET_ANALYTICS_EMAIL_SIZE_PER_PAGE:
      return {
        ...state,
        table: {
          ...state.table,
          pageRecord: payload,
        },
      };
    case SET_ANALYTICS_EMAIL_BY:
      return {
        ...state,
        by: payload,
        table: {
          ...state.table,
          search: "",
          pageNo: 1,
        },
      };
    case SET_ANALYTICS_EMAIL_SORT_FIELD:
      return {
        ...state,
        table: {
          ...state.table,
          sortField: payload,
        },
      };
    case SET_ANALYTICS_EMAIL_SORT_ORDER:
      return {
        ...state,
        table: {
          ...state.table,
          sortOrder: payload,
        },
      };

    case FETCH_ANALYTICS_EMAIL_SITES:
      return {
        ...state,
        sites: payload,
      };

    case GET_TOTAL_BY_DATE:
      return {
        ...state,
        loadingTotalByDate: true,
      };

    case GET_TOTAL_BY_DATE_SUCCESS:
      return {
        ...state,
        loadingTotalByDate: false,
        totalByDateData: payload.rows,
        totalTotalSize: payload.count,
      };

    case GET_TOTAL_BY_DATE_ERROR:
      return {
        ...state,
        loadingTotalByDate: false,
      };

    case GET_TOTAL_PAGE:
      return {
        ...state,
        totalPages: payload,
      };
    case GET_TOTAL_SIZE_PER_PAGE:
      return {
        ...state,
        totalSizePerPage: payload,
      };

    case GET_TOTAL_SORT_ORDER:
      return {
        ...state,
        totalSortOrder: payload,
      };

    case GET_TOTAL_SORT_FIELD:
      return {
        ...state,
        totalSortField: payload,
      };

    case GET_TOTAL_SEARCH:
      return {
        ...state,
        totalSearch: payload,
        totalPages: 1,
      };

    case SET_ANALYTICS_EMAIL_GRAPH_BY:
      return {
        ...state,
        emailGraphBy: payload,
      };
    case CLEAR_VIEW_TOTAL_DATA:
      return {
        ...state,
        totalByDateData: [],
        totalPages: 1,
        totalSizePerPage: 25,
        totalSearch: "",
        totalTotalSize: 0,
        totalSortOrder: "DESC",
        totalSortField: "created_datetime",
      };

    default:
      return state;
  }
};

export default analyticsEmailsReducer;
