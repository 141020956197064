export const SET_ANALYTICS_USERS_ACTIVE_TAB =
  "SET_ANALYTICS_REVENUE_ACTIVE_TAB";
export const SET_ANALYTICS_USERS_RANGE_DATE = "SET_ANALYTICS_USERS_RANGE_DATE";
export const SET_ANALYTICS_USERS_COMPARE_TYPE =
  "SET_ANALYTICS_REVENUE_COMPARE_TYPE";
export const SET_ANALYTICS_USER_CURRENT_SITE =
  "SET_ANALYTICS_USER_CURRENT_SITE";

export const ANALYTICS_USER_SET_TZ = "ANALYTICS_USER_SET_TZ";

export const CLEAR_SEARCH_QUERY_REPORT = "CLEAR_SEARCH_QUERY_REPORT";

export const GET_ANALYTICS_USER_COUNT = "GET_ANALYTICS_USER_COUNT";
export const GET_ANALYTICS_USER_COUNT_SUCCESS =
  "GET_ANALYTICS_USER_COUNT_SUCCESS";
export const GET_ANALYTICS_USER_COUNT_ERROR = "GET_ANALYTICS_USER_COUNT_ERROR";

export const GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA =
  "GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA";
export const GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA_SUCCESS =
  "GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA_SUCCESS";
export const GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA_ERROR =
  "GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA_ERROR";

export const GET_ANALYTICS_USERS_GRAPH_DATA = "GET_ANALYTICS_USERS_GRAPH_DATA";
export const GET_ANALYTICS_USERS_GRAPH_DATA_SUCCESS =
  "GET_ANALYTICS_USERS_GRAPH_DATA_SUCCESS";
export const GET_ANALYTICS_USERS_GRAPH_DATA_ERROR =
  "GET_ANALYTICS_USERS_GRAPH_DATA_ERROR";

export const GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA =
  "GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA";
export const GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA_SUCCESS =
  "GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA_SUCCESS";
export const GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA_ERROR =
  "GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA_ERROR";

export const SET_BY_DAY = "SET_BY_DAY";

export const GET_ANALYTICS_USER_DATA = "GET_ANALYTICS_USER_DATA";
export const GET_ANALYTICS_USER_DATA_SUCCESS =
  "GET_ANALYTICS_USER_DATA_SUCCESS";
export const GET_ANALYTICS_USER_DATA_ERROR = "GET_ANALYTICS_USER_DATA_ERROR";
export const ANALYTICS_USER_PAGE_SIZE = "ANALYTICS_USER_PAGE_SIZE";
export const ANALYTICS_USER_SIZE_PER_PAGE = "ANALYTICS_USER_SIZE_PER_PAGE";
export const ANALYTICS_USER_SORT_FIELD = "ANALYTICS_USER_SORT_FIELD";
export const ANALYTICS_USER_SORT_ORDER = "ANALYTICS_USER_SORT_ORDER";
