import {
  FETCH_BLOG_LINK_POST_REPORT_SUCCESS,
  FETCH_BLOG_NON_LINK_NUM_POST_REPORT_SUCCESS,
  FETCH_BLOG_NON_LINK_POST_REPORT_SUCCESS,
  FETCH_BLOG_NUM_LINK_POST_REPORT_SUCCESS,
  FETCH_BLOG_POTENTIAL_LINKED_POST_NUM_SUCCESS,
  FETCH_BLOG_POTENTIAL_LINKED_POST_SUCCESS,
  FETCH_BLOG_SITEMAP,
  FETCH_BLOG_SITEMAP_CHART,
  FETCH_BLOG_SITEMAP_CHART_SUCCESS,
  FETCH_BLOG_SITEMAP_SUCCESS,
  FETCH_BLOG_TOTAL_POST_REPORT_SUCCESS,
  GET_BLOGSITEMAP_PAGES,
  GET_BLOGSITEMAP_SIZEPERPAGES,
  GET_SEARCH_BLOGSITEMAP,
  GET_SORT_FIELD_BLOG_SITEMAP,
  GET_SORT_ORDER_BLOG_SITEMAP,
  SORT_ITEMS_SET,
} from "./actionTypes";

const initialState = {
  blogSiteMapData: [],
  linkedPostData: "",
  nonLinkedPostData: "",
  linkedPostNumData: "",
  nonLinkedPostNum: "",
  potentialLinkedPostData: "",
  potentialLinkedPostNumData: "",
  totalPostNum: "",
  loadingSiteMaps: false,
  totalSize: 0,
  sizePerPage: 10,
  search: "",
  page: 1,
  sortField: "post_title",
  sortOrder: "DESC",
  filter_type: 0,
  blogSiteMapChartData: [],
  loadingSiteMapsChart: false,
};

const BlogSiteMap = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_BLOG_SITEMAP:
      return {
        ...state,
        loadingSiteMaps: true,
      };
    case FETCH_BLOG_SITEMAP_SUCCESS:
      return {
        ...state,
        blogSiteMapData: payload.data,
        totalSize: payload.count,
        loadingSiteMaps: false,
      };
    case FETCH_BLOG_LINK_POST_REPORT_SUCCESS:
      return {
        ...state,
        linkedPostData: payload,
      };
    case FETCH_BLOG_NUM_LINK_POST_REPORT_SUCCESS:
      return {
        ...state,
        linkedPostNumData: payload,
      };
    case FETCH_BLOG_NON_LINK_NUM_POST_REPORT_SUCCESS:
      return {
        ...state,
        nonLinkedPostNum: payload,
      };
    case FETCH_BLOG_TOTAL_POST_REPORT_SUCCESS:
      return {
        ...state,
        totalPostNum: payload,
      };
    case FETCH_BLOG_NON_LINK_POST_REPORT_SUCCESS:
      return {
        ...state,
        nonLinkedPostData: payload,
      };
    case GET_SEARCH_BLOGSITEMAP:
      return {
        ...state,
        search: "",
        search: payload,
      };
    case GET_SORT_FIELD_BLOG_SITEMAP:
      return {
        ...state,
        sortField: payload,
      };
    case GET_SORT_ORDER_BLOG_SITEMAP:
      return {
        ...state,
        sortOrder: payload,
      };
    case GET_BLOGSITEMAP_PAGES:
      return {
        ...state,
        page: payload,
      };
    case GET_BLOGSITEMAP_SIZEPERPAGES:
      return {
        ...state,
        sizePerPage: payload,
      };
    case FETCH_BLOG_SITEMAP_CHART:
      return {
        ...state,
        loadingSiteMapsChart: true,
      };
    case FETCH_BLOG_SITEMAP_CHART_SUCCESS:
      return {
        ...state,
        blogSiteMapChartData: payload,
        loadingSiteMapsChart: false,
      };
    case SORT_ITEMS_SET:
      return {
        ...state,
        filter_type: payload,
      };
    case FETCH_BLOG_POTENTIAL_LINKED_POST_SUCCESS:
      return {
        ...state,
        potentialLinkedPostData: payload,
      };
    case FETCH_BLOG_POTENTIAL_LINKED_POST_NUM_SUCCESS:
      return {
        ...state,
        potentialLinkedPostNumData: payload,
      };
    default:
      return state;
  }
};

export default BlogSiteMap;
