import {
  LIST_GROW_CON_VIDEO_SLIDER_ERROR,
  LIST_GROW_CON_VIDEO_SLIDER_LOADING,
  LIST_GROW_CON_VIDEO_SLIDER_SUCCESS,
  SET_PAYLOAD,
} from "./actionTypes";

export const listGrowConVideoSlider = data => ({
  type: LIST_GROW_CON_VIDEO_SLIDER_LOADING,
  payload: data,
});
export const listGrowConVideoSliderSuccess = data => ({
  type: LIST_GROW_CON_VIDEO_SLIDER_SUCCESS,
  payload: data,
});
export const listGrowConVideoSliderError = () => ({
  type: LIST_GROW_CON_VIDEO_SLIDER_ERROR,
});
export const setPayload = data => ({
  type: SET_PAYLOAD,
  payload: data,
});
