import {
  FETCH_CANCEL_SUBSCRIPTION,
  FETCH_CANCEL_SUBSCRIPTION_ERROR,
  FETCH_CANCEL_SUBSCRIPTION_LOADING,
} from "./actionTypes";

export const fetchCancelSubscriptionLoading = data => ({
  type: FETCH_CANCEL_SUBSCRIPTION_LOADING,
  payload: data,
});

export const fetchCancelSubscription = data => ({
  type: FETCH_CANCEL_SUBSCRIPTION,
  payload: data,
});

export const fetchCancelSubscriptionError = () => ({
  type: FETCH_CANCEL_SUBSCRIPTION_ERROR,
});
