import { axiosApiAdmin } from "helpers/api_helper";
import {
  CREATE_NEW_USER_MEMBERSHIP_URL,
  DELETE_MEMBERSHIP_USER_URL,
  FETCH_USER_MEMBERSHIP_URL,
  GET_MEMBERS_URL,
  SAVE_USER_MEMBERSHIP_URL,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  createNewUserMembershipError,
  createNewUserMembershipSuccess,
  deleteUserMembershipSuccess,
  fetchMembersError,
  fetchMembersSuccess,
  fetchUserMembershipError,
  fetchUserMembershipSuccess,
  saveUserMembershipError,
  saveUserMembershipSuccess,
  setErrorMessage,
  setSuccessMessage,
} from "store/actions";
import {
  CREATE_NEW_USER_MEMBERSHIP,
  DELETE_USER_MEMBERSHIP,
  FETCH_MEMBERS,
  FETCH_USER_MEMBERSHIP,
  SAVE_USER_MEMBERSHIP,
} from "./actionTypes";

const fetchMembersCall = payload =>
  axiosApiAdmin.post(GET_MEMBERS_URL, payload);
const createNewUserMembershipCall = payload =>
  axiosApiAdmin.post(CREATE_NEW_USER_MEMBERSHIP_URL, payload);
const fetchUserMembershipCall = payload =>
  axiosApiAdmin.post(FETCH_USER_MEMBERSHIP_URL, payload);
const saveUserMembershipCall = payload =>
  axiosApiAdmin.post(SAVE_USER_MEMBERSHIP_URL, payload);
const deleteMembershipUserCall = id =>
  axiosApiAdmin.post(`${DELETE_MEMBERSHIP_USER_URL}` + "/" + `${id}`);

function* handleFetchMembers() {
  try {
    const { search, sizePerPage, page, sortField, sortOrder } = yield select(
      state => state.Members
    );
    const payload = {
      search,
      page_record: sizePerPage,
      page_no: page,
      sortField,
      sortOrder,
    };
    const response = yield call(fetchMembersCall, payload);
    if (response?.data?.data && response?.data?.statusCode === 200) {
      yield put(fetchMembersSuccess(response.data.data));
    } else {
      yield put(fetchMembersError());
    }
  } catch (error) {
    yield put(fetchMembersError());
    yield put(setErrorMessage(error.response?.data?.message || error.message));
  }
}
function* handleCreateNewUserMembership({ payload }) {
  try {
    const response = yield call(createNewUserMembershipCall, payload);
    if (response.status && response?.data?.data?.userMembershipDetail) {
      yield put(
        createNewUserMembershipSuccess(response.data.data.userMembershipDetail)
      );
    }
  } catch (error) {
    yield put(createNewUserMembershipError());
    yield put(setErrorMessage(error.response?.data?.message || error.message));
  }
}
function* handleFetchUserMembership({ payload }) {
  try {
    const response = yield call(fetchUserMembershipCall, payload);
    if (response.status) {
      yield put(
        fetchUserMembershipSuccess(response?.data?.data?.userMembershipDetail)
      );
    }
  } catch (error) {
    yield put(fetchUserMembershipError());
    yield put(setErrorMessage(error.response?.data?.message || error.message));
  }
}
function* handleSaveUserMembership({ payload: { data, cb } }) {
  try {
    const response = yield call(saveUserMembershipCall, data);
    if (response.status) {
      yield put(setSuccessMessage(response.data.message));
      yield put(saveUserMembershipSuccess());
      cb();
    }
  } catch (error) {
    yield put(saveUserMembershipError());
    yield put(setErrorMessage(error.response?.data?.message || error.message));
  }
}
function* handleDeleteMembershipUser({ payload: { user_membership_id, cb } }) {
  try {
    const response = yield call(deleteMembershipUserCall, user_membership_id);
    if (response.status) {
      yield put(setSuccessMessage(response.data.message));
      yield put(deleteUserMembershipSuccess());
      cb();
    }
  } catch (error) {
    yield put(setErrorMessage(error.response?.data?.message || error.message));
  }
}
function* memberSaga() {
  yield takeEvery(FETCH_MEMBERS, handleFetchMembers);
  yield takeEvery(CREATE_NEW_USER_MEMBERSHIP, handleCreateNewUserMembership);
  yield takeEvery(FETCH_USER_MEMBERSHIP, handleFetchUserMembership);
  yield takeEvery(SAVE_USER_MEMBERSHIP, handleSaveUserMembership);
  yield takeEvery(DELETE_USER_MEMBERSHIP, handleDeleteMembershipUser);
}

export default memberSaga;
