export const GROW_BOOK_CATEGORY_LISTING = "GROW_BOOK_CATEGORY_LISTING";
export const GROW_BOOK_CATEGORY = "GROW_BOOK_CATEGORY";

export const GROW_AUTHOR = "GROW_AUTHOR";
export const GROW_AUTHOR_LISTING = "GROW_AUTHOR_LISTING";
export const GROW_DELETE_AUTHOR = "GROW_DELETE_AUTHOR";
export const GROW_EDIT_AUTHOR = "GROW_EDIT_AUTHOR";
export const GROW_FETCH_AUTHOR_LOADER = "GROW_FETCH_AUTHOR_LOADER";
export const GROW_ADD_EDIT_AUTHOR_LOADER = "GROW_ADD_EDIT_AUTHOR_LOADER";
export const GROW_AUTHOR_TAB_CHANGE = "GROW_AUTHOR_TAB_CHANGE";
export const GROW_AUTHOR_LIST_SAVE = "GROW_AUTHOR_LIST_SAVE";
export const HANDLE_OPEN_ADD_EDIT = "HANDLE_OPEN_ADD_EDIT";

export const GROW_ADD_BUTTON_BOOK_CATEGORY = "GROW_ADD_BUTTON_BOOK_CATEGORY";
export const GROW_EDIT_BOOK_CATEGORY = "GROW_EDIT_BOOK_CATEGORY";
export const GROW_BOOK_CATEGORY_CREATE = "GROW_BOOK_CATEGORY_CREATE";
export const GROW_DELETE_BOOK_CATEGORY = "GROW_DELETE_BOOK_CATEGORY";

export const LIST_ALL_BOOKS_LISTING = "LIST_ALL_BOOKS_LISTING";
export const LIST_ALL_GROW_BOOKS = "LIST_ALL_GROW_BOOKS";
export const GROW_DELETE_BOOKS = "GROW_DELETE_BOOKS";
export const SAVE_GROW_BOOKS = "SAVE_GROW_BOOKS";
export const GROW_ADD_BUTTON_BOOK = "GROW_ADD_BUTTON_BOOK";
export const GROW_AUTHOR_NAME_LIST = "GROW_AUTHOR_NAME_LIST";
export const GROW_AUTHOR_NAME_LIST_SUCCESS = "GROW_AUTHOR_NAME_LIST_SUCCESS";
export const GROW_BOOK_CATEGORY_LIST = "GROW_BOOK_CATEGORY_LIST";
export const GROW_BOOK_CATEGORY_LIST_SUCCESS =
  "GROW_BOOK_CATEGORY_LIST_SUCCESS";
export const ADD_EDIT_PAGE_HANDLE = "ADD_EDIT_PAGE_HANDLE";
export const GROW_ADD_EDIT_BOOK_CATEGORY_LOADER =
  "GROW_ADD_EDIT_BOOK_CATEGORY_LOADER";
export const GROW_LIST_BOOK_CATEGORY_LOADER = "GROW_LIST_BOOK_CATEGORY_LOADER";
export const GROW_EDIT_BOOK_DETAILS = "GROW_EDIT_BOOK_DETAILS";
