export const ANALYTICS_DASHBOARD_SET_RANGE = "ANALYTICS_DASHBOARD_SET_RANGE";
export const ANALYTICS_DASHBOARD_SET_COMPARE_RANGE =
  "ANALYTICS_DASHBOARD_SET_COMPARE_RANGE";
export const ANALYTICS_DASHBOARD_SET_BY = "ANALYTICS_DASHBOARD_SET_BY";
export const ANALYTICS_DASHBOARD_SET_CHART_TYPE =
  "ANALYTICS_DASHBOARD_SET_CHART_TYPE";
export const ANALYTICS_DASHBOARD_SET_SETTINGS =
  "ANALYTICS_DASHBOARD_SET_SETTINGS";
export const ANALYTICS_DASHBOARD_SET_SETTINGS_KEY_VALUE =
  "ANALYTICS_DASHBOARD_SET_SETTINGS_KEY_VALUE";
export const ANALYTICS_DASHBOARD_SET_TZ = "ANALYTICS_DASHBOARD_SET_TZ";
export const ANALYTICS_DASHBOARD_SET_SITE = "ANALYTICS_DASHBOARD_SET_SITE";
// APIS

//PERFORMANCE
export const ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS =
  "ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS";
export const ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS_SUCCESS =
  "ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS_SUCCESS";
export const ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS_ERROR =
  "ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS_ERROR";

//SUBSCRIPTIONS
export const ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS =
  "ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS";
export const ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS_SUCCESS =
  "ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS_SUCCESS";
export const ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS_ERROR =
  "ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS_ERROR";
