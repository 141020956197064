import { axiosApiAdmin } from "helpers/api_helper";
import { FETCH_ORDERS_URL } from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  fetchOrdersError,
  fetchOrdersSuccess,
  setErrorMessage,
} from "store/actions";
import { FETCH_ORDERS } from "./actionTypes";

const fetchOrdersCall = data => axiosApiAdmin.post(FETCH_ORDERS_URL, data);
function* handleFetchOrders() {
  const {
    search,
    orderStatus,
    pageRecord,
    pageNo,
    siteId,
    sortOrder,
    sortField,
  } = yield select(state => state.Orders);
  const payload = {
    search: search,
    orderStatus: orderStatus,
    site_id: siteId,
    page_record: pageRecord,
    page_no: pageNo,
    sortField,
    sortOrder,
  };
  try {
    const res = yield call(fetchOrdersCall, payload);
    if (res.status && res.data?.data) {
      yield put(fetchOrdersSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchOrdersError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* ordersSaga() {
  yield takeEvery(FETCH_ORDERS, handleFetchOrders);
}
export default ordersSaga;
