import {
  CLEAR_MEMBERSHIP_FEATURES,
  CREATE_NEW_USER_MEMBERSHIP,
  CREATE_NEW_USER_MEMBERSHIP_ERROR,
  CREATE_NEW_USER_MEMBERSHIP_SUCCESS,
  DELETE_USER_MEMBERSHIP,
  DELETE_USER_MEMBERSHIP_ERROR,
  DELETE_USER_MEMBERSHIP_SUCCESS,
  FETCH_MEMBERS,
  FETCH_MEMBERS_ERROR,
  FETCH_MEMBERS_SUCCESS,
  FETCH_USER_MEMBERSHIP,
  FETCH_USER_MEMBERSHIP_ERROR,
  FETCH_USER_MEMBERSHIP_SUCCESS,
  SAVE_USER_MEMBERSHIP,
  SAVE_USER_MEMBERSHIP_ERROR,
  SAVE_USER_MEMBERSHIP_SUCCESS,
  SET_MEMBER_PAGE,
  SET_MEMBER_SEARCH,
  SET_MEMBER_SIZE_PER_PAGE,
  SET_MEMBER_SORT_FIELD,
  SET_MEMBER_SORT_ORDER,
} from "./actionTypes";

export const fetchMembers = member => ({
  type: FETCH_MEMBERS,
  payload: member,
});
export const fetchMembersSuccess = member => ({
  type: FETCH_MEMBERS_SUCCESS,
  payload: member,
});
export const fetchMembersError = member => ({
  type: FETCH_MEMBERS_ERROR,
});

export const createNewUserMembership = member => ({
  type: CREATE_NEW_USER_MEMBERSHIP,
  payload: member,
});
export const createNewUserMembershipSuccess = member => ({
  type: CREATE_NEW_USER_MEMBERSHIP_SUCCESS,
  payload: member,
});
export const createNewUserMembershipError = () => ({
  type: CREATE_NEW_USER_MEMBERSHIP_ERROR,
});

export const saveUserMembership = data => ({
  type: SAVE_USER_MEMBERSHIP,
  payload: data,
});
export const saveUserMembershipSuccess = data => ({
  type: SAVE_USER_MEMBERSHIP_SUCCESS,
  payload: data,
});
export const saveUserMembershipError = () => ({
  type: SAVE_USER_MEMBERSHIP_ERROR,
});

export const fetchUserMembership = data => ({
  type: FETCH_USER_MEMBERSHIP,
  payload: data,
});
export const fetchUserMembershipSuccess = data => ({
  type: FETCH_USER_MEMBERSHIP_SUCCESS,
  payload: data,
});
export const fetchUserMembershipError = () => ({
  type: FETCH_USER_MEMBERSHIP_ERROR,
});

export const deleteMembershipUser = data => ({
  type: DELETE_USER_MEMBERSHIP,
  payload: data,
});
export const deleteUserMembershipSuccess = () => ({
  type: DELETE_USER_MEMBERSHIP_SUCCESS,
});
export const deleteUserMembershipError = () => ({
  type: DELETE_USER_MEMBERSHIP_ERROR,
});

export const setMemberPage = data => ({
  type: SET_MEMBER_PAGE,
  payload: data,
});
export const setMemberSizePerPage = data => ({
  type: SET_MEMBER_SIZE_PER_PAGE,
  payload: data,
});
export const setMemberSearch = data => ({
  type: SET_MEMBER_SEARCH,
  payload: data,
});
export const setMemberSortField = data => ({
  type: SET_MEMBER_SORT_FIELD,
  payload: data,
});
export const setMemberSortOrder = data => ({
  type: SET_MEMBER_SORT_ORDER,
  payload: data,
});
export const clearMembershipFeatures = () => ({
  type: CLEAR_MEMBERSHIP_FEATURES,
});
