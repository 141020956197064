import {
  API_LIST_LOGS,
  API_LIST_LOGS_ERROR,
  API_LIST_LOGS_SUCCESS,
  API_LOG_PAGES,
  API_LOG_PER_PAGES,
  CLEAR_API_LOG_FEATURES,
  GET_ERROR_FILE_LIST,
  GET_ERROR_FILE_LIST_ERROR,
  GET_ERROR_FILE_LIST_SUCCESS,
  GET_ERROR_LOG_FILES,
  GET_ERROR_LOG_FILES_ERROR,
  GET_ERROR_LOG_FILES_SUCCESS,
  GET_SITES_LISTS,
  GET_SITES_LISTS_ERROR,
  GET_SITES_LISTS_SUCCESS,
  SET_ERROR_LOG_DATA,
  SITES_LISTS_ACTIVE_INACTIVE,
  SITES_LISTS_ACTIVE_INACTIVE_SUCCESS,
} from "./actionsTypes";

export const apiListLogs = data => ({
  type: API_LIST_LOGS,
  payload: data,
});

export const apiListLogsSuccess = data => ({
  type: API_LIST_LOGS_SUCCESS,
  payload: data,
});

export const apiListLogsError = () => ({
  type: API_LIST_LOGS_ERROR,
});

export const apiLogPages = data => ({
  type: API_LOG_PAGES,
  payload: data,
});

export const apiLogPerPages = data => ({
  type: API_LOG_PER_PAGES,
  payload: data,
});

export const clearAPILog = () => ({
  type: CLEAR_API_LOG_FEATURES,
});

export const getSitesLists = () => ({
  type: GET_SITES_LISTS,
});

export const getSitesListsSuccess = data => ({
  type: GET_SITES_LISTS_SUCCESS,
  payload: data,
});

export const getSitesListsError = () => ({
  type: GET_SITES_LISTS_ERROR,
});

export const sitesListsActiveInactive = data => ({
  type: SITES_LISTS_ACTIVE_INACTIVE,
  payload: data,
});

export const sitesListsActiveInactiveSuccess = data => ({
  type: SITES_LISTS_ACTIVE_INACTIVE_SUCCESS,
  payload: data,
});

export const getErrorFileList = data => ({
  type: GET_ERROR_FILE_LIST,
  payload: data,
});

export const getErrorFileListSuccess = data => ({
  type: GET_ERROR_FILE_LIST_SUCCESS,
  payload: data,
});

export const getErrorFileListError = () => ({
  type: GET_ERROR_FILE_LIST_ERROR,
});

export const getErrorLogFiles = data => ({
  type: GET_ERROR_LOG_FILES,
  payload: data,
});

export const getErrorLogFilesSuccess = data => ({
  type: GET_ERROR_LOG_FILES_SUCCESS,
  payload: data,
});

export const getErrorLogFilesError = () => ({
  type: GET_ERROR_LOG_FILES_ERROR,
});

export const setErrorLogData = data => ({
  type: SET_ERROR_LOG_DATA,
  payload: data,
});
