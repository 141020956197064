// Va 05 - 01 - 21

import {
  CLEAR_SUPPORT_TICKETS,
  DELETE_COMMENT_REDUX,
  DELETE_COMMENT_REPLY_REDUX,
  FETCH_SUPPORT_TICKETS,
  FETCH_SUPPORT_TICKETS_ERROR,
  FETCH_SUPPORT_TICKETS_SUCCESS,
  SET_PAGE_SUPPORT_TICKETS,
  SET_SEARCH_SUPPORT_TICKETS,
  SET_SIZE_PER_PAGE_SUPPORT_TICKETS,
  SET_SORT_FIELD_SUPPORT_TICKETS,
  SET_SORT_ORDER_SUPPORT_TICKETS,
  SUPPORT_TICKET_DETAIL,
  SUPPORT_TICKET_DETAIL_ERROR,
  SUPPORT_TICKET_DETAIL_SUCCESS,
} from "./actionTypes";

export const fetchSupportTickets = data => ({
  type: FETCH_SUPPORT_TICKETS,
  payload: data,
});

export const fetchSupportTicketsSuccess = data => ({
  type: FETCH_SUPPORT_TICKETS_SUCCESS,
  payload: data,
});

export const fetchSupportTicketsError = () => ({
  type: FETCH_SUPPORT_TICKETS_ERROR,
});

export const fetchSupportTicketDetail = data => ({
  type: SUPPORT_TICKET_DETAIL,
  payload: data,
});

export const fetchSupportTicketDetailSuccess = data => ({
  type: SUPPORT_TICKET_DETAIL_SUCCESS,
  payload: data,
});

export const fetchSupportTicketDetailError = () => ({
  type: SUPPORT_TICKET_DETAIL_ERROR,
});

//delete support ticket comment form redux
export const deleteCommentRedux = data => ({
  type: DELETE_COMMENT_REDUX,
  payload: data,
});

//delete support ticket comment reply form redux
export const deleteCommentReplyRedux = data => ({
  type: DELETE_COMMENT_REPLY_REDUX,
  payload: data,
});

export const clearSupportTickets = () => ({
  type: CLEAR_SUPPORT_TICKETS,
});

export const setPageSupportTickets = data => {
  return {
    type: SET_PAGE_SUPPORT_TICKETS,
    payload: data,
  };
};

export const setSizePerPageSupportTickets = data => {
  return {
    type: SET_SIZE_PER_PAGE_SUPPORT_TICKETS,
    payload: data,
  };
};

export const setSearchSupportTickets = data => {
  return {
    type: SET_SEARCH_SUPPORT_TICKETS,
    payload: data,
  };
};

export const setSortFieldSupportTickets = data => ({
  type: SET_SORT_FIELD_SUPPORT_TICKETS,
  payload: data,
});

export const setSortOrderSupportTickets = data => ({
  type: SET_SORT_ORDER_SUPPORT_TICKETS,
  payload: data,
});
