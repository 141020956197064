// Va 05 - 01 - 21
import DateRange from "modules/AnalyticsDashboard/utils";
import {
  CLEAR_NEW_ACTIVE_SUBSCRIPTION,
  FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_ERROR,
  FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT_NULL,
  FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT_SUCCESS,
  FATCH_NEW_LIST_ONBOARDING,
  FATCH_NEW_LIST_ON_BOARD_SUCCESS,
  FETCH_MODAL_FEEDBACK_LIST,
  SET_NEW_ORDER_RANGE_DATE_ACTIVE_SUBSCRIPTION,
  SET_NEW_PAGE_ACTIVE_SUBSCRIPTION,
  SET_NEW_SEARCH_ACTIVE_SUBSCRIPTION,
  SET_NEW_SIZE_PER_PAGE_ACTIVE_SUBSCRIPTION,
  SET_NEW_SORT_FIELD_ACTIVE_SUBSCRIPTION,
  SET_NEW_SORT_ORDER_ACTIVE_SUBSCRIPTION,
} from "./actionTypes";

const initialState = {
  page: 1,
  search: "",
  sizePerPage: 10,
  totalSize: 0,
  onBoardingData: [],
  loading: false,
  sortField: "created_datetime",
  sortOrder: "DESC",
  by: "day",
  tz: "-0400",
};

const ModalFeedbackReport = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_NEW_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        onBoardingnew: [],
      };
    case FATCH_NEW_LIST_ONBOARDING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MODAL_FEEDBACK_LIST:
      return {
        ...state,
        loading: true,
      };
    case FATCH_NEW_LIST_ON_BOARD_SUCCESS:
      return {
        ...state,
        loading: false,
        onBoardingData: payload?.rows || [],
        totalSize: payload?.count || 0,
      };
    case FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_NEW_PAGE_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        page: payload,
      };

    case SET_NEW_SIZE_PER_PAGE_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        sizePerPage: payload,
      };

    case SET_NEW_SEARCH_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        search: "",
        search: payload,
      };

    case SET_NEW_SORT_FIELD_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        sortField: action.payload,
      };

    case SET_NEW_SORT_ORDER_ACTIVE_SUBSCRIPTION:
      return {
        ...state,
        sortOrder: action.payload,
      };
    case FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT_SUCCESS:
      return {
        ...state,
        subscriptionReportExport: action.payload,
      };
    case FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT_NULL:
      return {
        ...state,
        subscriptionReportExport: [],
      };
    case SET_NEW_ORDER_RANGE_DATE_ACTIVE_SUBSCRIPTION:
      const prevBy = state.by;
      const newBy = new DateRange(
        payload.startDate,
        payload.endDate
      ).getByOptions()[0].value;
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
        comparedRange: new DateRange(
          payload.startDate,
          payload.endDate
        ).getPreviousDates(state.compareToPrevious),
        ...(prevBy !== newBy ? { by: newBy } : {}),
      };
    default:
      return state;
  }
};

export default ModalFeedbackReport;
