import { axiosApiAdmin } from "helpers/api_helper";
import {
  GET_ANALYTICS_PRODUCT_GRAPH_URL,
  GET_PRODUCTS_REPORTS_DATA,
  GET_PRODUCT_SOLD_LIST_URL,
  LIST_ORDER_BY_DATE,
} from "helpers/url_helper";
import { tzs } from "modules/Analytics/common/constants";
import { DateTimeTz } from "modules/Analytics/common/utils";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  getAnalyticsProductGraphError,
  getAnalyticsProductGraphSuccess,
  getOrderByDateError,
  getOrderByDateSuccess,
  getProductSoldListError,
  getProductSoldListSuccess,
  getProductsReportsError,
  getProductsReportsSuccess,
  setErrorMessage,
} from "store/actions";
import {
  FETCH_PRODUCTS_REPORTS,
  GET_ANALYTICS_PRODUCT_GRAPH,
  GET_ORDER_BY_DATE,
  GET_PRODUCT_SOLD_LIST,
} from "./actionTypes";

const fetchProductsReports = data =>
  axiosApiAdmin.post(GET_PRODUCTS_REPORTS_DATA, data);
const fetchAnalyticsGraph = data =>
  axiosApiAdmin.post(GET_ANALYTICS_PRODUCT_GRAPH_URL, data);
const fetchProductSoldList = data =>
  axiosApiAdmin.post(GET_PRODUCT_SOLD_LIST_URL, data);
const fetchOrderByDate = payload =>
  axiosApiAdmin.post(LIST_ORDER_BY_DATE, payload);

function* handleOrderByDate({ payload }) {
  try {
    const res = yield call(fetchOrderByDate, payload);
    if (res.status && res?.data?.data) {
      yield put(getOrderByDateSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getOrderByDateError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* handleFetchProductsReports() {
  try {
    const { range, comparedRange, siteId, tz } = yield select(
      state => state.AnalyticsProducts
    );
    const payload = {
      current_date_range: {
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(range.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      past_date_range: {
        start_date: DateTimeTz(comparedRange.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(comparedRange.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      site_id: siteId,
      tz: tzs[tz],
    };

    const res = yield call(fetchProductsReports, payload);
    if (res.status) {
      yield put(getProductsReportsSuccess(res.data.data.reports));
    }
  } catch (err) {
    yield put(getProductsReportsError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}
function* handleFetchAnalyticsProductGraph() {
  const { range, comparedRange, by, siteId, tz } = yield select(
    state => state.AnalyticsProducts
  );
  const payload = {
    current_range: {
      start_date: DateTimeTz(range.startDate)
        .startTime(tz)
        .format("YYYY-MM-DD HH:mm"),
      end_date: DateTimeTz(range.endDate)
        .endTime(tz)
        .format("YYYY-MM-DD HH:mm"),
    },
    previous_range: {
      start_date: DateTimeTz(comparedRange.startDate)
        .startTime(tz)
        .format("YYYY-MM-DD"),
      end_date: DateTimeTz(comparedRange.endDate)
        .endTime(tz)
        .format("YYYY-MM-DD"),
    },
    filter: by,
    site_id: siteId,
    tz: tzs[tz],
  };
  try {
    const res = yield call(fetchAnalyticsGraph, payload);
    if (res.status) {
      yield put(getAnalyticsProductGraphSuccess(res.data.data.rows));
    }
  } catch (err) {
    yield put(getAnalyticsProductGraphError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}
function* handleFetchProductSoldList() {
  try {
    const { table, range, comparedRange, siteId, tz } = yield select(
      state => state.AnalyticsProducts
    );
    const payload = {
      page_no: table.pageNo,
      page_record: table.pageRecord,
      current_date_range: {
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(range.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      past_date_range: {
        start_date: DateTimeTz(comparedRange.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(comparedRange.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      sort_order: table.sortOrder,
      sort_field: table.sortField,
      site_id: siteId,
      tz: tzs[tz],
    };
    const res = yield call(fetchProductSoldList, payload);
    if (res.status) {
      yield put(getProductSoldListSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getProductSoldListError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* productsSaga() {
  yield takeEvery(FETCH_PRODUCTS_REPORTS, handleFetchProductsReports);
  yield takeEvery(GET_PRODUCT_SOLD_LIST, handleFetchProductSoldList);
  yield takeEvery(
    GET_ANALYTICS_PRODUCT_GRAPH,
    handleFetchAnalyticsProductGraph
  );
  yield takeEvery(GET_ORDER_BY_DATE, handleOrderByDate);
}

export default productsSaga;
