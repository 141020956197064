export const LIST_SIGNUP_METRIC_REPORT_LOADING =
  "LIST_SIGNUP_METRIC_REPORT_LOADING";
export const LIST_SIGNUP_METRIC_REPORT_SUCCESS =
  "LIST_SIGNUP_METRIC_REPORT_SUCCESS";
export const LIST_SIGNUP_METRIC_REPORT_ERROR =
  "LIST_SIGNUP_METRIC_REPORT_ERROR";
export const LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA =
  "LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA";
export const LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA_SUCCESS =
  "LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA_SUCCESS";
export const LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA_ERROR =
  "LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA_ERROR";
