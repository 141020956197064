import {
  ADD_REMOVE_FILES,
  ADD_SHARE_FILE,
  GET_CS_USER,
  GET_CS_USER_SUCCESS,
  GET_FILES_LIST,
  GET_FILES_SUCCESS,
  GET_SHARED_FILES,
  GET_SHARED_FILES_SUCCESS,
  REMOVE_SHARE_FILE,
} from "./actionTypes";

/**
 * Add or Remove File or Folder
 */
export const addRemoveFile = data => {
  return {
    type: ADD_REMOVE_FILES,
    payload: data,
  };
};

/**
 * Get Folders
 */
export const getFilesSuccess = data => ({
  type: GET_FILES_SUCCESS,
  payload: data,
});

/**
 * Get Files
 */
export const getFilesList = data => ({
  type: GET_FILES_LIST,
  payload: data,
});

/**
 * Get User Credentials
 * @param {array} data
 * @returns
 */
export const getCsUser = data => {
  return {
    type: GET_CS_USER,
    payload: data,
  };
};

export const getCsUserSuccess = data => {
  return {
    type: GET_CS_USER_SUCCESS,
    payload: data,
  };
};

/**
 * using For Create Share
 */
export const addShareFile = data => {
  return {
    type: ADD_SHARE_FILE,
    payload: data,
  };
};

/**
 * using For Remove Share
 */
export const removeShare = data => {
  return {
    type: REMOVE_SHARE_FILE,
    payload: data,
  };
};

/**
 * using For Create Share
 */
export const getSharedFiles = () => {
  return {
    type: GET_SHARED_FILES,
    payload: {},
  };
};

export const getSharedFilesSuccess = data => {
  return {
    type: GET_SHARED_FILES_SUCCESS,
    payload: data,
  };
};
