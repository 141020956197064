import { dynamicSiteId } from "constants/siteDetails";
import moment from "moment";
import { analyticsDashboardSettingsConfig, compareTypes } from "../constants";
import DateRange from "../utils";
import {
  ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS,
  ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS_ERROR,
  ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS_SUCCESS,
  ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS,
  ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS_ERROR,
  ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS_SUCCESS,
  ANALYTICS_DASHBOARD_SET_BY,
  ANALYTICS_DASHBOARD_SET_CHART_TYPE,
  ANALYTICS_DASHBOARD_SET_COMPARE_RANGE,
  ANALYTICS_DASHBOARD_SET_RANGE,
  ANALYTICS_DASHBOARD_SET_SETTINGS,
  ANALYTICS_DASHBOARD_SET_SETTINGS_KEY_VALUE,
  ANALYTICS_DASHBOARD_SET_SITE,
  ANALYTICS_DASHBOARD_SET_TZ,
} from "./actionTypes";

const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();
const defaultCompareType = compareTypes.PERIOD;

const reports = {
  total_sales: {
    value: 0,
    previousPeriodValue: 0,
  },
  net_discounted_amount: {
    value: 0,
    previousPeriodValue: 0,
  },
  discounted_orders: {
    value: 0,
    previousPeriodValue: 0,
  },
  net_sales: {
    value: 0,
    previousPeriodValue: 0,
  },
  items_sold: {
    value: 0,
    previousPeriodValue: 0,
  },
  orders: {
    value: 0,
    previousPeriodValue: 0,
  },
  registered_users: {
    value: 0,
    previousPeriodValue: 0,
  },
  subscribed_users: {
    value: 0,
    previousPeriodValue: 0,
  },
};

const subscriptions = {
  grow_kids: {
    monthly: 0,
    quarterly: 0,
    yearly: 0,
  },
  grow_students: {
    monthly: 0,
    quarterly: 0,
    yearly: 0,
  },
  grow_groups: {
    monthly: 0,
    quarterly: 0,
    yearly: 0,
  },
  monthly: {
    grow_kids: 0,
    grow_students: 0,
    grow_groups: 0,
  },
  quarterly: {
    grow_kids: 0,
    grow_students: 0,
    grow_groups: 0,
  },
  yearly: {
    grow_kids: 0,
    grow_students: 0,
    grow_groups: 0,
  },
  cancelled_subscriptions: {
    value: 0,
  },
};

const initialState = {
  reports,
  loadingReports: false,
  subscriptions,
  loadingSubscriptions: false,
  range: {
    startDate,
    endDate,
  },
  comparedRange: new DateRange(startDate, endDate).getPreviousDates(
    defaultCompareType
  ),
  compareToPrevious: defaultCompareType,
  by: "day",
  chartType: 1,
  settings: { ...analyticsDashboardSettingsConfig },
  tz: "-0400",
  siteId: dynamicSiteId.Curriculum,
};

const dashboardAnalyticsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ANALYTICS_DASHBOARD_SET_SITE:
      return {
        ...state,
        siteId: payload,
      };
    case ANALYTICS_DASHBOARD_SET_TZ:
      return {
        ...state,
        tz: payload,
      };
    case ANALYTICS_DASHBOARD_SET_RANGE:
      const prevBy = state.by;
      const newBy = new DateRange(
        payload.startDate,
        payload.endDate
      ).getByOptions()[0].value;
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
        comparedRange: new DateRange(
          payload.startDate,
          payload.endDate
        ).getPreviousDates(state.compareToPrevious),
        ...(prevBy !== newBy ? { by: newBy } : {}),
      };
    case ANALYTICS_DASHBOARD_SET_COMPARE_RANGE:
      return {
        ...state,
        compareToPrevious: payload,
        comparedRange: new DateRange(
          state.range.startDate,
          state.range.endDate
        ).getPreviousDates(payload),
      };
    case ANALYTICS_DASHBOARD_SET_BY:
      return {
        ...state,
        by: payload,
      };
    case ANALYTICS_DASHBOARD_SET_CHART_TYPE:
      return {
        ...state,
        chartType: payload,
      };
    case ANALYTICS_DASHBOARD_SET_SETTINGS_KEY_VALUE:
      return {
        ...state,
        settings: {
          ...state.settings,
          [payload.dataKey]: {
            ...state.settings[payload.dataKey],
            [payload.key]: {
              ...state.settings[payload.dataKey][payload.key],
              value: payload.value,
            },
          },
        },
      };
    case ANALYTICS_DASHBOARD_SET_SETTINGS:
      const newSettings = {};
      Object.entries(state.settings).forEach(([key, value]) => {
        newSettings[key] = {};
        Object.entries(state.settings[key]).forEach(
          ([childKey, childValue]) => {
            newSettings[key][childKey] = {
              ...childValue,
              value: payload?.[key]?.[childKey] ?? 1,
            };
          }
        );
      });
      return {
        ...state,
        settings: { ...newSettings },
      };
    // APIS
    case ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS:
      return {
        ...state,
        loadingReports: true,
      };
    case ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS_SUCCESS:
      return {
        ...state,
        loadingReports: false,
        reports: { ...payload },
      };
    case ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS_ERROR:
      return {
        ...state,
        loadingReports: false,
      };
    case ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS:
      return {
        ...state,
        loadingSubscriptions: true,
      };
    case ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS_SUCCESS:
      return {
        ...state,
        loadingSubscriptions: false,
        subscriptions: { ...payload },
      };
    case ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS_ERROR:
      return {
        ...state,
        loadingSubscriptions: false,
      };
    default:
      return state;
  }
};

export default dashboardAnalyticsReducer;
