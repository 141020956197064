import moment from "moment";
import {
  CLEAR_PAGINATION,
  FETCH_LIVE_CHAT_ARC_DATA,
  FETCH_LIVE_CHAT_ARC_DATA_ERROR,
  FETCH_LIVE_CHAT_ARC_DATA_SUCCESS,
  SET_ACTIVE_CHAT_ID,
  SET_CUSTOMER_RANDOM_COLOR,
  SET_CUSTOMER_USER_NAME,
  SET_LIST_LIVE_CHAT,
  SET_LIST_LIVE_CHAT_USER_INFO,
  SET_LIVE_CHAT_ARC_DATE_RANGE,
  SET_LIVE_CHAT_ARC_ORDER,
  SET_LIVE_CHAT_ARC_SEARCH,
  SET_LIVE_CHAT_PAGE_NO,
  SET_LIVE_CHAT_PAGINATION,
  SET_PRE_CHAT_FORM_DATA,
} from "./actionTypes";

const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();

const initialState = {
  loading: false,
  liveChatSection: {},
  liveChatSectionLoading: false,
  liveChatList: [],
  chatUserInfo: [],
  range: {
    startDate,
    endDate,
  },
  chatListData: {
    rows: [],
    count: 0,
    pageNo: 1,
    pageRecord: 10,
    sortOrder: "DESC",
    search: "",
  },
  tz: "0000",
  chatListLoading: false,
  userInfo: {},
  userInfoLoading: false,
  activeChatId: 0,
  preChatData: [],
  userRandomColor: "",
  customerUser: "",
  paginationState: {},
};

const LiveChatArchiveReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_LIVE_CHAT_ARC_DATA:
      return {
        ...state,
        chatListLoading: true,
      };
    case FETCH_LIVE_CHAT_ARC_DATA_SUCCESS:
      return {
        ...state,
        chatListData: {
          ...state.chatListData,
          rows:
            state.chatListData.pageNo === 1
              ? [...payload.rows]
              : [...state.chatListData.rows, ...payload.rows],
          count: payload?.count,
        },
        chatListLoading: false,
      };
    case FETCH_LIVE_CHAT_ARC_DATA_ERROR:
      return {
        ...state,
        chatListLoading: false,
      };
    case SET_LIST_LIVE_CHAT:
      return {
        ...state,
        liveChatList: payload,
      }; //
    case SET_LIST_LIVE_CHAT_USER_INFO:
      return {
        ...state,
        chatUserInfo: payload,
      };
    case SET_ACTIVE_CHAT_ID:
      return {
        ...state,
        activeChatId: payload,
      };
    case SET_PRE_CHAT_FORM_DATA:
      return {
        ...state,
        preChatData: payload,
      };
    case SET_CUSTOMER_RANDOM_COLOR:
      return {
        ...state,
        userRandomColor: payload,
      };
    case SET_CUSTOMER_USER_NAME:
      return {
        ...state,
        customerUser: payload,
      };
    case SET_LIVE_CHAT_PAGE_NO:
      return {
        ...state,
        chatListData: {
          ...state.chatListData,
          pageNo: payload,
        },
      };
    case SET_LIVE_CHAT_ARC_SEARCH:
      return {
        ...state,
        chatListData: {
          ...state.chatListData,
          search: payload,
        },
      };
    case SET_LIVE_CHAT_ARC_DATE_RANGE:
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
      };
    case CLEAR_PAGINATION:
      return {
        ...state,
        chatListData: {
          ...state.chatListData,
          pageNo: 1,
        },
      };
    case SET_LIVE_CHAT_ARC_ORDER:
      return {
        ...state,
        chatListData: {
          ...state.chatListData,
          sortOrder: payload,
        },
      };
    case SET_LIVE_CHAT_PAGINATION:
      return {
        ...state,
        paginationState: payload,
      };
    default:
      return state;
  }
};

export default LiveChatArchiveReducer;
