import {
  FETCH_GROWCON_REPORTS,
  FETCH_GROWCON_REPORTS_ERROR,
  FETCH_GROWCON_REPORTS_SUCCESS,
} from "./actionTypes";

const initialState = {
  GrowConData: { data: [], count: 0 },
  GrowConLoading: false,
};

const DisputedTransactions = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_GROWCON_REPORTS:
      return {
        ...state,
        GrowConLoading: true,
      };
    case FETCH_GROWCON_REPORTS_SUCCESS:
      return {
        ...state,
        GrowConData: payload,
        GrowConLoading: false,
      };
    case FETCH_GROWCON_REPORTS_ERROR:
      return {
        ...state,
        GrowConLoading: false,
      };
    default:
      return state;
  }
};

export default DisputedTransactions;
