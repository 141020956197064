import {
  CLEAR_PRODUCT_REPORT_STATUS,
  CLEAR_REGISTERED_USER_FEATURES,
  CLEAR_RENEWAL_FEATURES,
  CLEAR_REVENUE_REPORT,
  GET_PRODUCT_REVENUE_REPORT_DATA,
  GET_PRODUCT_REVENUE_REPORT_DATA_ERROR,
  GET_PRODUCT_REVENUE_REPORT_DATA_SUCCESS,
  GET_RENEWAL_DATA,
  GET_RENEWAL_DATA_ERROR,
  GET_RENEWAL_DATA_SUCCESS,
  GET_REVENUE_REPORT_DATA,
  GET_REVENUE_REPORT_DATA_ERROR,
  GET_REVENUE_REPORT_DATA_SUCCESS,
  LIST_REGISTERED_USERS,
  LIST_REGISTERED_USERS_ERROR,
  LIST_REGISTERED_USERS_SUCCESS,
  REGISTERED_USER_DATA_PAGE,
  REGISTERED_USER_SIZE_PER_PAGE,
  RENEWAL_DATA_PAGE,
  RENEWAL_SIZE_PER_PAGE,
  SET_PRODUCT_REVENUE_REPORT_DATA_PAGE,
  SET_PRODUCT_REVENUE_REPORT_DATA_SEARCH,
  SET_PRODUCT_REVENUE_REPORT_DATA_SIZE_PER_PAGE,
  SET_PRODUCT_REVENUE_REPORT_END_DATE,
  SET_PRODUCT_REVENUE_REPORT_SORT_FIELD,
  SET_PRODUCT_REVENUE_REPORT_SORT_ORDER,
  SET_PRODUCT_REVENUE_REPORT_START_DATE,
  SET_REGISTERED_USER_SORT_FIELD,
  SET_REGISTERED_USER_SORT_ORDER,
  SET_RENEWAL_SORT_FIELD,
  SET_RENEWAL_SORT_ORDER,
  SET_REVENUE_REPORT_DATA_PAGE,
  SET_REVENUE_REPORT_DATA_SEARCH,
  SET_REVENUE_REPORT_DATA_SIZE_PER_PAGE,
  SET_REVENUE_REPORT_END_DATE,
  SET_REVENUE_REPORT_SORT_FIELD,
  SET_REVENUE_REPORT_SORT_ORDER,
  SET_REVENUE_REPORT_START_DATE,
} from "./actionTypes";

//Revenue Report
export const getRevenueReportData = data => ({
  type: GET_REVENUE_REPORT_DATA,
  payload: data,
});
export const getRevenueReportDataSuccess = data => ({
  type: GET_REVENUE_REPORT_DATA_SUCCESS,
  payload: data,
});
export const getRevenueReportDataError = data => ({
  type: GET_REVENUE_REPORT_DATA_ERROR,
});

export const setRevenueReportDataPage = data => ({
  type: SET_REVENUE_REPORT_DATA_PAGE,
  payload: data,
});

export const setRevenueReportDataSearch = data => {
  return {
    type: SET_REVENUE_REPORT_DATA_SEARCH,
    payload: data,
  };
};

export const setRevenueReportDataSizePerPage = data => ({
  type: SET_REVENUE_REPORT_DATA_SIZE_PER_PAGE,
  payload: data,
});

export const setRevenueReportStartDate = data => ({
  type: SET_REVENUE_REPORT_START_DATE,
  payload: data,
});
export const setRevenueReportEndDate = data => ({
  type: SET_REVENUE_REPORT_END_DATE,
  payload: data,
});
export const setRevenueSortField = data => ({
  type: SET_REVENUE_REPORT_SORT_FIELD,
  payload: data,
});
export const setRevenueSortOrder = data => ({
  type: SET_REVENUE_REPORT_SORT_ORDER,
  payload: data,
});
export const clearRevenueReport = () => ({
  type: CLEAR_REVENUE_REPORT,
});

export const getProductRevenueReportData = data => ({
  type: GET_PRODUCT_REVENUE_REPORT_DATA,
  payload: data,
});
export const getProductRevenueReportDataSuccess = data => ({
  type: GET_PRODUCT_REVENUE_REPORT_DATA_SUCCESS,
  payload: data,
});
export const getProductRevenueReportDataError = data => ({
  type: GET_PRODUCT_REVENUE_REPORT_DATA_ERROR,
});

export const setProductRevenueReportDataPage = data => ({
  type: SET_PRODUCT_REVENUE_REPORT_DATA_PAGE,
  payload: data,
});

export const setProductRevenueReportDataSearch = data => {
  return {
    type: SET_PRODUCT_REVENUE_REPORT_DATA_SEARCH,
    payload: data,
  };
};

export const setProductRevenueReportDataSizePerPage = data => ({
  type: SET_PRODUCT_REVENUE_REPORT_DATA_SIZE_PER_PAGE,
  payload: data,
});

export const setProductRevenueReportStartDate = data => ({
  type: SET_PRODUCT_REVENUE_REPORT_START_DATE,
  payload: data,
});
export const setProductRevenueReportEndDate = data => ({
  type: SET_PRODUCT_REVENUE_REPORT_END_DATE,
  payload: data,
});
export const setProductRevenueReportSortField = data => ({
  type: SET_PRODUCT_REVENUE_REPORT_SORT_FIELD,
  payload: data,
});
export const setProductRevenueReportSortOrder = data => ({
  type: SET_PRODUCT_REVENUE_REPORT_SORT_ORDER,
  payload: data,
});
export const clearProductReportStatus = () => ({
  type: CLEAR_PRODUCT_REPORT_STATUS,
});

export const getRenewalData = data => ({
  type: GET_RENEWAL_DATA,
  payload: data,
});

export const getRenewalDataSuccess = data => ({
  type: GET_RENEWAL_DATA_SUCCESS,
  payload: data,
});

export const getRenewalDataError = () => ({
  type: GET_RENEWAL_DATA_ERROR,
});

export const getRenewalPage = data => ({
  type: RENEWAL_DATA_PAGE,
  payload: data,
});

export const getRenewalSizePerPage = data => ({
  type: RENEWAL_SIZE_PER_PAGE,
  payload: data,
});

export const setRenewalSortField = data => ({
  type: SET_RENEWAL_SORT_FIELD,
  payload: data,
});

export const setRenewalSortOrder = data => ({
  type: SET_RENEWAL_SORT_ORDER,
  payload: data,
});
export const clearRenewalFeatures = () => ({
  type: CLEAR_RENEWAL_FEATURES,
});

export const listRegisteredUsers = data => ({
  type: LIST_REGISTERED_USERS,
  payload: data,
});

export const listRegisteredUsersSuccess = data => ({
  type: LIST_REGISTERED_USERS_SUCCESS,
  payload: data,
});

export const listRegisteredUsersError = () => ({
  type: LIST_REGISTERED_USERS_ERROR,
});

export const getRegisteredUserPage = data => ({
  type: REGISTERED_USER_DATA_PAGE,
  payload: data,
});

export const getRegisteredUserSizePerPage = data => ({
  type: REGISTERED_USER_SIZE_PER_PAGE,
  payload: data,
});

export const setRegisteredUserSortField = data => ({
  type: SET_REGISTERED_USER_SORT_FIELD,
  payload: data,
});

export const setRegisteredUserSortOrder = data => ({
  type: SET_REGISTERED_USER_SORT_ORDER,
  payload: data,
});
export const clearRegisteredUserFeatures = () => ({
  type: CLEAR_REGISTERED_USER_FEATURES,
});
