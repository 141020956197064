import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_CONFIG_DATA_ERROR,
  FETCH_CONFIG_DATA_LOADING,
  FETCH_CONFIG_DATA_SUCCESS,
  GET_GEO_DATA_ERROR,
  GET_GEO_DATA_LOADING,
  SAVE_CONFIG_DATA_ERROR,
  SAVE_CONFIG_DATA_LOADING,
  SAVE_CONFIG_DATA_SUCCESS,
} from "./actionTypes";

import { axiosApiAdmin } from "helpers/api_helper";

import {
  LIST_CONFIG_DATA,
  SAVE_CONFIG_DATA,
  USER_lOCATION_DATA,
} from "helpers/url_helper";
import { toast } from "react-toastify";

const fetchUserLocation = data => axiosApiAdmin.post(USER_lOCATION_DATA, data);
const fetchConfigDataApi = () => axiosApiAdmin.get(LIST_CONFIG_DATA);
const saveConfigDataApi = data => axiosApiAdmin.post(SAVE_CONFIG_DATA, data);

function* fetchGeoLocationData({ payload, cb }) {
  try {
    const res = yield call(fetchUserLocation, payload);
    if (res.status && res?.data) {
      cb(res?.data?.data?.data);
      // yield put({ type: GET_GEO_DATA_SUCCESS, payload: res?.data?.data?.data });
    }
  } catch (err) {
    yield put({ type: FETCH_CONFIG_DATA_ERROR });
    toast.error(err?.response?.data?.message || "Something want's wrong");
  }
}

function* fetchConfigData() {
  try {
    const res = yield call(fetchConfigDataApi);
    if (res.status && res?.data) {
      yield put({
        type: FETCH_CONFIG_DATA_SUCCESS,
        payload: res?.data?.data?.data,
      });
    }
  } catch (err) {
    yield put({ type: GET_GEO_DATA_ERROR });
    toast.error(err?.response?.data?.message || "Something want's wrong");
  }
}

function* saveConfigData({ payload, cb }) {
  try {
    const res = yield call(saveConfigDataApi, payload);
    if (res.status && res?.data) {
      cb(res.data);
      yield put({
        type: SAVE_CONFIG_DATA_SUCCESS,
        payload: res?.data?.data?.data,
      });
    }
  } catch (err) {
    yield put({ type: SAVE_CONFIG_DATA_ERROR });
    toast.error(err?.response?.data?.message || "Something want's wrong");
  }
}

function* geoLocationSaga() {
  yield takeEvery(GET_GEO_DATA_LOADING, fetchGeoLocationData);
  yield takeEvery(FETCH_CONFIG_DATA_LOADING, fetchConfigData);
  yield takeEvery(SAVE_CONFIG_DATA_LOADING, saveConfigData);
}

export default geoLocationSaga;
