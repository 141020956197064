//GET ICEBREAKER
export const FETCH_ICEBREAKER = "FETCH_ICEBREAKER";
export const FETCH_ICEBREAKER_SUCCESS = "FETCH_ICEBREAKER_SUCCESS";
export const FETCH_ICEBREAKER_ERROR = "FETCH_ICEBREAKER_ERROR";

//TABLE ICEBREAKER
export const SET_PAGE_ICEBREAKER = "SET_PAGE_ICEBREAKER";
export const SET_SIZE_PER_PAGE_ICEBREAKER = "SET_SIZE_PER_PAGE_ICEBREAKER";
export const SET_SEARCH_ICEBREAKER = "SET_SEARCH_ICEBREAKER";
export const CLEAR_ICEBREAKER_FEATURES = "CLEAR_ICEBREAKER_FEATURES";

//ADD /EDIT ICEBREAKER
export const ADD_EDIT_ICEBREAKER = "ADD_EDIT_ICEBREAKER";
export const ADD_EDIT_ICEBREAKER_SUCCESS = "ADD_EDIT_ICEBREAKER_SUCCESS";
export const ADD_EDIT_ICEBREAKER_ERROR = "ADD_EDIT_ICEBREAKER_ERROR";

//DELETE ICEBREAKER
export const DELETE_ICEBREAKER = "DELETE_ICEBREAKER";
export const DELETE_ICEBREAKER_SUCCESS = "DELETE_ICEBREAKER_SUCCESS";
export const DELETE_ICEBREAKER_ERROR = "DELETE_ICEBREAKER_ERROR";

//GET GET_ICEBRAKER_DETAILS
export const GET_ICEBREAKER_DETAILS = "GET_ICEBREAKER_DETAILS";
export const GET_ICEBREAKER_DETAILS_SUCCESS = "GET_ICEBREAKER_DETAILS_SUCCESS";
export const GET_ICEBREAKER_DETAILS_ERROR = "GET_ICEBREAKER_DETAILS_ERROR";

// GET ICEBREAKER FILTER
export const FETCH_ICEBREAKER_FILTER = "FETCH_ICEBREAKER_FILTER";
export const FETCH_ICEBREAKER_FILTER_SUCCESS =
  "FETCH_ICEBREAKER_FILTER_SUCCESS";
export const FETCH_ICEBREAKER_FILTER_ERROR = "FETCH_ICEBREAKER_FILTER_ERROR";

export const SET_SORT_FIELD_ICEBREAKER = "SET_SORT_FIELD_ICEBREAKER";
export const SET_SORT_ORDER_ICEBREAKER = "SET_SORT_ORDER_ICEBREAKER";

export const SET_SYSTEM = "SET_SYSTEM";
