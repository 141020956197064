import {
  DELETE_PERMISSION_PROFILE,
  DELETE_PERMISSION_PROFILE_ERROR,
  DELETE_PERMISSION_PROFILE_SUCCESS,
  FETCH_PERMISSIONS,
  FETCH_PERMISSIONS_ERROR,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSION_PROFILES,
  FETCH_PERMISSION_PROFILES_ERROR,
  FETCH_PERMISSION_PROFILES_SUCCESS,
} from "./actionTypes";

const initialState = {
  permissionProfiles: [],
  loading: false,
  permissions: [],
  loadingPermissions: false,
  loadingDeleteButton: false,
};

const permissionBuilderReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_PERMISSION_PROFILES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PERMISSION_PROFILES_SUCCESS:
      return {
        ...state,
        loading: false,
        permissionProfiles: payload,
      };
    case FETCH_PERMISSION_PROFILES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_PERMISSIONS:
      return {
        ...state,
        loadingPermissions: true,
      };
    case FETCH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loadingPermissions: false,
        permissions: payload,
      };
    case FETCH_PERMISSIONS_ERROR:
      return {
        ...state,
        loadingPermissions: false,
      };
    case DELETE_PERMISSION_PROFILE:
      return {
        ...state,
        loadingDeleteButton: true,
      };
    case DELETE_PERMISSION_PROFILE_SUCCESS:
      return {
        ...state,
        loadingDeleteButton: false,
      };
    case DELETE_PERMISSION_PROFILE_ERROR:
      return {
        ...state,
        loadingDeleteButton: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default permissionBuilderReducer;
