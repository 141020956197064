import {
  ADD_EDIT_CURRICULUM_POST,
  ADD_EDIT_CURRICULUM_POST_ERROR,
  ADD_EDIT_CURRICULUM_POST_SUCCESS,
  ADD_EDIT_PAGE_EDITOR,
  ADD_EDIT_PAGE_EDITOR_ERROR,
  ADD_EDIT_PAGE_EDITOR_SUCCESS,
  ADD_SERIES_POST,
  ADD_SERIES_POST_ERROR,
  ADD_SERIES_POST_SUCCESS,
  CLEAR_CURRICULUM_POST,
  CLEAR_POSTS,
  DELETE_AB_SERIES,
  DELETE_POST,
  DELETE_POSTS_FOLDER,
  DELETE_POSTS_FOLDER_ERROR,
  DELETE_POSTS_FOLDER_SUCCESS,
  DELETE_POST_ERROR,
  DELETE_POST_SUCCESS,
  EMPTY_SERIES_POST,
  FETCH_CURRICULUM_POST_DETAILS,
  FETCH_CURRICULUM_POST_DETAILS_ERROR,
  FETCH_CURRICULUM_POST_DETAILS_SUCCESS,
  FETCH_HEADER_FOOTER_DATA,
  FETCH_HEADER_FOOTER_DATA_ERROR,
  FETCH_HEADER_FOOTER_DATA_SUCCESS,
  GET_ALL_CONTENT,
  GET_ALL_CONTENT_ERROR,
  GET_ALL_CONTENT_SUCCESS,
  GET_MESSAGE_POST,
  GET_MESSAGE_POST_ERROR,
  GET_MESSAGE_POST_SUCCESS,
  GET_SERIES_POST,
  GET_SERIES_POST_ERROR,
  GET_SERIES_POST_SUCCESS,
  GET_SERIES_SORT_POST,
  GET_SERIES_SORT_POST_ERROR,
  GET_SERIES_SORT_POST_SUCCESS,
  GET_SORT_SERIES_POST,
  GET_SORT_SERIES_POST_ERROR,
  GET_SORT_SERIES_POST_SUCCESS,
  LIST_ALL_POSTS,
  LIST_ALL_POSTS_ERROR,
  LIST_ALL_POSTS_SUCCESS,
  SAVE_PAGE_POSTS,
  SAVE_PAGE_POSTS_ERROR,
  SAVE_PAGE_POSTS_SUCCESS,
  SAVE_PAGE_SERIES,
  SAVE_PAGE_SERIES_ERROR,
  SAVE_PAGE_SERIES_SUCCESS,
  SET_CURRENT_TAB_ID,
  SET_POSTS_PAGE,
  SET_POSTS_SEARCH,
  SET_POSTS_SIZE_PER_PAGE,
  SET_POSTS_SORT_FIELD,
  SET_POSTS_SORT_ORDER,
} from "./actionTypes";

const initialState = {
  pageData: null,
  loading: false,
  loadingAddition: false,
  currentTabId: 0,
  posts: [],
  loadingPosts: false,
  totalSize: 0,
  search: "",
  sizePerPage: 10,
  page: 1,
  editPost: null,
  headerFooterData: [],
  loadingForm: false,
  loadingHeaderFooter: false,
  loadingSeriesButton: false,
  loadingPostsButton: false,
  loadingPost: false,
  seriesMessagePosts: [],
  seriesPosts: [],
  sortSeries: [],
  sortPosts: [],
  loadingPopupPost: false,
};

const curriculumPostPageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_POSTS_PAGE:
      return {
        ...state,
        page: payload,
      };
    case SET_POSTS_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    case SET_POSTS_SEARCH:
      return {
        ...state,
        search: payload,
      };
    case SET_POSTS_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };
    case SET_POSTS_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    case GET_ALL_CONTENT:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        pageData: payload,
        currentTabId:
          payload.page_meta.sort((a, b) =>
            a.sort_order > b.sort_order ? 1 : -1
          )[0]?.original_id || 0,
      };
    case GET_ALL_CONTENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SET_CURRENT_TAB_ID:
      return {
        ...state,
        currentTabId: payload,
      };
    case LIST_ALL_POSTS:
      return {
        ...state,
        loadingPosts: true,
      };
    case LIST_ALL_POSTS_SUCCESS:
      return {
        ...state,
        posts:
          state.page === 1
            ? [...payload.rows]
            : [...state.posts, ...payload.rows],
        totalSize: payload.count,
        loadingPosts: false,
      };
    case CLEAR_POSTS:
      return {
        ...state,
        posts: [],
        totalSize: 0,
      };
    case LIST_ALL_POSTS_ERROR:
      return {
        ...state,
        loadingPosts: false,
      };
    case DELETE_POST:
      return {
        ...state,
        loadingPosts: true,
      };
    case DELETE_POST_SUCCESS:
      return {
        ...state,
        loadingPosts: false,
      };
    case DELETE_POST_ERROR:
      return {
        ...state,
        loadingPosts: false,
      };
    case ADD_EDIT_CURRICULUM_POST:
      return {
        ...state,
        loadingAddition: true,
      };
    case ADD_EDIT_CURRICULUM_POST_SUCCESS:
      return {
        ...state,
        loadingAddition: false,
      };
    case ADD_EDIT_CURRICULUM_POST_ERROR:
      return {
        ...state,
        loadingAddition: false,
      };
    case FETCH_CURRICULUM_POST_DETAILS:
      return {
        ...state,
        loadingPost: true,
      };
    case FETCH_CURRICULUM_POST_DETAILS_SUCCESS:
      return {
        ...state,
        editPost: payload,
        loadingPost: false,
      };
    case CLEAR_CURRICULUM_POST:
      return {
        ...state,
        editPost: null,
      };
    case FETCH_CURRICULUM_POST_DETAILS_ERROR:
      return {
        ...state,
        loadingPost: false,
      };
    case FETCH_HEADER_FOOTER_DATA:
      return {
        ...state,
        loadingHeaderFooter: true,
      };
    case FETCH_HEADER_FOOTER_DATA_SUCCESS:
      return {
        ...state,
        loadingHeaderFooter: false,
        headerFooterData: payload,
      };
    case FETCH_HEADER_FOOTER_DATA_ERROR:
      return {
        ...state,
        loadingHeaderFooter: false,
      };
    case ADD_EDIT_PAGE_EDITOR:
      return {
        ...state,

        loadingForm: true,

        pageData: {
          ...state.pageData,

          page_meta: state.pageData.page_meta.map(page => {
            if (page.page_meta_id === payload.data.page_meta_id) {
              return { ...payload.data };
            }
            return page;
          }),
        },
      };
    case ADD_EDIT_PAGE_EDITOR_SUCCESS:
      return {
        ...state,
        loadingForm: false,
      };
    case ADD_EDIT_PAGE_EDITOR_ERROR:
      return {
        ...state,
        loadingForm: false,
      };
    case SAVE_PAGE_SERIES:
      return { ...state, loadingSeriesButton: true };
    case SAVE_PAGE_SERIES_SUCCESS:
      return { ...state, loadingSeriesButton: false };
    case SAVE_PAGE_SERIES_ERROR:
      return { ...state, loadingSeriesButton: false };
    case SAVE_PAGE_POSTS:
      return { ...state, loadingPostsButton: true };
    case SAVE_PAGE_POSTS_SUCCESS:
      return { ...state, loadingPostsButton: false };
    case SAVE_PAGE_POSTS_ERROR:
      return { ...state, loadingPostsButton: false };
    //Add series post
    case ADD_SERIES_POST:
      return {
        ...state,
        loadingForm: true,
      };
    case ADD_SERIES_POST_SUCCESS:
      return {
        ...state,
        loadingForm: false,
      };
    case ADD_SERIES_POST_ERROR:
      return {
        ...state,
        loadingForm: true,
      };
    //Get all message series post
    case GET_MESSAGE_POST:
      return {
        ...state,
        loadingPost: true,
      };
    case GET_MESSAGE_POST_SUCCESS:
      //data sorted according to week
      //index 1 will have week 1 data index 2 will have week 2 data
      const groupBy = (array, key) => {
        return payload.reduce((result, currentValue) => {
          (result[currentValue.week_no] =
            result[currentValue.week_no] || []).push(currentValue);
          return result;
        }, {});
      };
      const weeklyData = groupBy(payload, 1);
      return {
        ...state,
        seriesMessagePosts: weeklyData,
        loadingPost: false,
      };
    case GET_MESSAGE_POST_ERROR:
      return {
        ...state,
        loadingPost: true,
      };

    //Get all volume series post
    case GET_SERIES_POST:
      return {
        ...state,
        loadingPost: true,
      };
    case GET_SERIES_POST_SUCCESS:
      return {
        ...state,
        seriesPosts: payload,
        loadingPost: false,
      };
    case GET_SERIES_POST_ERROR:
      return {
        ...state,
        loadingPost: true,
      };
    //deleting element from redux
    case EMPTY_SERIES_POST:
      return {
        ...state,
        //deleting element from redux CurriculumPostPage->seriesPosts
        seriesPosts: [] /* {
          ...state.seriesPosts,
          ...(payload.elementId && payload.isDeleteSeries == 1 ? {
            sycu_build_elements_details: state.SeriesElements.sycu_build_elements_details.filter(item => item.build_elements_details_id !== payload.elementId)

          } : {})

        } */,
      };
    //deleting SERIES from redux
    case DELETE_AB_SERIES:
      return {
        ...state,
        //deleting SERIES from redux
        seriesPosts: {
          ...state.seriesPosts,
          ...(payload.deleteId
            ? {
                gb_series_buildlists:
                  state.seriesPosts.gb_series_buildlists.filter(
                    item => item.series_build_list_id !== payload.deleteId
                  ),
              }
            : {}),
        },
      };
    case DELETE_POSTS_FOLDER:
      return {
        ...state,
        loadingPosts: true,
      };
    case DELETE_POSTS_FOLDER_SUCCESS:
      return {
        ...state,
        loadingPosts: false,
      };
    case DELETE_POSTS_FOLDER_ERROR:
      return {
        ...state,
        loadingPosts: false,
      };
    //Get all volume series Sort post
    case GET_SERIES_SORT_POST:
      return {
        ...state,
        loadingPopupPost: true,
      };
    case GET_SERIES_SORT_POST_SUCCESS:
      return {
        ...state,
        sortPosts: payload,
        loadingPopupPost: false,
      };
    case GET_SERIES_SORT_POST_ERROR:
      return {
        ...state,
        loadingPopupPost: true,
      };
    //Get all volume Sort series post
    case GET_SORT_SERIES_POST:
      return {
        ...state,
        loadingPopupPost: true,
      };
    case GET_SORT_SERIES_POST_SUCCESS:
      return {
        ...state,
        sortSeries: payload,
        loadingPopupPost: false,
      };
    case GET_SORT_SERIES_POST_ERROR:
      return {
        ...state,
        loadingPopupPost: true,
      };
    default:
      return state;
  }
};

export default curriculumPostPageReducer;
