import {
  ADD_EDIT_PRODUCT,
  ADD_EDIT_PRODUCT_ERROR,
  ADD_EDIT_PRODUCT_SUCCESS,
  CLEAR_PRODUCT_FEATURES,
  FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT,
  FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT_ERROR,
  FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT_SUCCESS,
  FETCH_PRODUCTS,
  FETCH_PRODUCT_ERROR,
  FETCH_PRODUCT_SITES,
  FETCH_PRODUCT_SITES_ERROR,
  FETCH_PRODUCT_SITES_SUCCESS,
  FETCH_PRODUCT_SUCCESS,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_SUCCESS,
  SET_PRODUCT_FILTER_CATEGORY_ID,
  SET_PRODUCT_PAGE,
  SET_PRODUCT_SEARCH,
  SET_PRODUCT_SIZE_PER_PAGE,
  SET_PRODUCT_SORT_FIELD,
  SET_PRODUCT_SORT_ORDER,
} from "./actionTypes";

const initialState = {
  loading: false,
  products: [],
  productFolders: [],
  productDetails: [],
  productFolderDetails: [],
  sites: [],
  is_modal: true,
  loadingProductDetails: false,
  totalSize: 0,
  search: "",
  sizePerPage: 25,
  page: 1,
  sortField: "product_id",
  sortOrder: "DESC",
  productVolumes: [],
  loadingProductVolumes: false,
  category_id: 0,
  loadingProductSave: false,
};

const productsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_PRODUCT_FILTER_CATEGORY_ID:
      return {
        ...state,
        category_id: payload,
      };
    case FETCH_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        products: payload.rows,
        loading: false,
        totalSize: payload.count,
      };
    case ADD_EDIT_PRODUCT:
      return {
        ...state,
        loadingProductSave: true,
      };
    case ADD_EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingProductSave: false,
      };
    case ADD_EDIT_PRODUCT_ERROR:
      return {
        ...state,
        loadingProductSave: false,
      };
    case GET_PRODUCT_DETAILS:
      return {
        ...state,
        loadingProductDetails: true,
      };
    case GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productDetails: payload.rows,
        loadingProductDetails: false,
      };
    case FETCH_PRODUCT_SITES:
      return {
        ...state,
      };
    case FETCH_PRODUCT_SITES_SUCCESS:
      return {
        ...state,
        sites: payload,
        loading: false,
      };
    case FETCH_PRODUCT_SITES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_PRODUCT_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_PRODUCT_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: action.payload,
      };
    case SET_PRODUCT_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_PRODUCT_SORT_FIELD:
      return {
        ...state,
        sortField: action.payload,
      };
    case SET_PRODUCT_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.payload,
      };
    case CLEAR_PRODUCT_FEATURES:
      return {
        ...state,
        totalSize: 0,
        search: "",
        sizePerPage: 25,
        page: 1,
        sortField: "product_id",
        sortOrder: "DESC",
      };
    case FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT:
      return {
        ...state,
        loadingProductVolumes: true,
      };
    case FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT_SUCCESS:
      return {
        ...state,
        loadingProductVolumes: false,
        productVolumes: action.payload,
      };
    case FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT_ERROR:
      return {
        ...state,
        loadingProductVolumes: false,
      };
    default:
      return state;
  }
};

export default productsReducer;
