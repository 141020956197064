export const FETCH_LOGS = "FETCH_LOGS";
export const FETCH_LOGS_SUCCESS = "FETCH_LOGS_SUCCESS";
export const FETCH_LOGS_ERROR = "FETCH_LOGS_ERROR";

export const SET_LOGS_SEARCH = "SET_LOGS_SEARCH";
export const SET_LOGS_PAGE = "SET_LOGS_PAGE";
export const SET_LOGS_SIZE_PER_PAGE = "SET_LOGS_SIZE_PER_PAGE";
export const SET_LOGS_SORT_FIELD = "SET_LOGS_SORT_FIELD";
export const SET_LOGS_SORT_ORDER = "SET_LOGS_SORT_ORDER";
export const SET_LOGS_FILTERS = "SET_LOGS_FILTERS";

export const CLEAR_LOGS_FEATURES = "CLEAR_LOGS_FEATURES";
