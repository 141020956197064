// Report
export const SET_ANALYTICS_PRODUCTS_ACTIVE_TAB =
  "SET_ANALYTICS_PRODUCTS_ACTIVE_TAB";
export const SET_ANALYTICS_PRODUCTS_RANGE_DATE =
  "SET_ANALYTICS_PRODUCTS_RANGE_DATE";
export const SET_ANALYTICS_PRODUCTS_COMPARE_TYPE =
  "SET_ANALYTICS_PRODUCTS_COMPARE_TYPE";
export const FETCH_PRODUCTS_REPORTS = "FETCH_PRODUCTS_REPORTS";
export const FETCH_PRODUCTS_REPORTS_SUCCESS = "FETCH_PRODUCTS_REPORTS_SUCCESS";
export const FETCH_PRODUCTS_REPORTS_ERROR = "FETCH_PRODUCTS_REPORTS_ERROR";
export const SET_ANALYTICS_PRODUCT_CURRENT_SITE =
  "SET_ANALYTICS_PRODUCT_CURRENT_SITE";
export const ANALYTICS_PRODUCT_SET_TZ = "ANALYTICS_PRODUCT_SET_TZ";

//clear

export const CLEAR_PRODUCT_REPORT = "CLEAR_PRODUCT_REPORT";
export const CLEAR_SEARCH_QUERY_REPORT = "CLEAR_SEARCH_QUERY_REPORT";

// Table
export const GET_PRODUCT_SOLD_LIST = "GET_PRODUCT_SOLD_LIST";
export const GET_PRODUCT_SOLD_LIST_SUCCESS = "GET_PRODUCT_SOLD_LIST_SUCCESS";
export const GET_PRODUCT_SOLD_LIST_ERROR = "GET_PRODUCT_SOLD_LIST_ERROR";
export const SET_ANALYTICS_PRODUCT_PAGE = "SET_ANALYTICS_PRODUCT_PAGE";
export const SET_ANALYTICS_PRODUCT_SIZE_PER_PAGE =
  "SET_ANALYTICS_PRODUCT_SIZE_PER_PAGE";
export const SET_ANALYTICS_PRODUCT_SORT_ORDER =
  "SET_ANALYTICS_PRODUCT_SORT_ORDER";
export const SET_ANALYTICS_PRODUCT_SORT_FIELD =
  "SET_ANALYTICS_PRODUCT_SORT_FIELD";

export const GET_ORDER_BY_DATE = "GET_ORDER_BY_DATE";
export const GET_ORDER_BY_DATE_SUCCESS = "GET_ORDER_BY_DATE_SUCCESS";
export const GET_ORDER_BY_DATE_ERROR = "GET_ORDER_BY_DATE_ERROR";

export const GET_ORDER_PAGE = "GET_ORDER_PAGE";
export const GET_ORDER_SIZE_PER_PAGE = "GET_ORDER_SIZE_PER_PAGE";
export const CLEAR_VIEW_ORDER_DATA = "CLEAR_VIEW_ORDER_DATA";

// Graph
export const GET_ANALYTICS_PRODUCT_GRAPH = "GET_ANALYTICS_PRODUCT_GRAPH";
export const GET_ANALYTICS_PRODUCT_GRAPH_SUCCESS =
  "GET_ANALYTICS_PRODUCT_GRAPH_SUCCESS";
export const GET_ANALYTICS_PRODUCT_GRAPH_ERROR =
  "GET_ANALYTICS_PRODUCT_GRAPH_ERROR";
export const SET_ANALYTICS_PRODUCT_BY = "SET_ANALYTICS_PRODUCT_BY";
