// src/context/UserListContext.js
import { createContext, useState } from "react";

export const UserListContext = createContext();

export const UserListProvider = ({ children }) => {
  const [userList, setUserList] = useState({});
  return (
    <UserListContext.Provider value={{ userList, setUserList }}>
      {children}
    </UserListContext.Provider>
  );
};
