export const STORE_DIR_CLOUD = "STORE_DIR_CLOUD";
export const STORE_DIR_CLOUD_SUCCESS = "STORE_DIR_CLOUD_SUCCESS";
export const STORE_DIR_CLOUD_ERROR = "STORE_DIR_CLOUD_ERROR";

export const GET_DIR_FILES = "GET_DIR_FILES";
export const GET_DIR_FILES_SUCCESS = "GET_DIR_FILES_SUCCESS";
export const GET_DIR_FILES_ERROR = "GET_DIR_FILES_ERROR";

export const DELETE_FILES = "DELETE_FILES";
export const DELETE_FILES_SUCCESS = "DELETE_FILES_SUCCESS";
export const DELETE_FILES_ERROR = "DELETE_FILES_ERROR";

export const SHARE_FOLDER = "SHARE_FOLDER";
export const SHARE_FOLDER_DOWNLOAD_SUCCESS = "SHARE_FOLDER_DOWNLOAD_SUCCESS";
export const SHARE_FOLDER_PREVIEW_SUCCESS = "SHARE_FOLDER_PREVIEW_SUCCESS";
export const SHARE_FOLDER_INSTRUCTION_SUCCESS =
  "SHARE_FOLDER_INSTRUCTION_SUCCESS";

export const SHARE_FOLDER_ERROR = "SHARE_FOLDER_ERROR";

export const ADMIN_ACTIVTY_LOG = "ADMIN_ACTIVTY_LOG";
export const ADMIN_ACTIVTY_LOG_SUCCESS = "ADMIN_ACTIVTY_LOG_SUCCESS";
export const ADMIN_ACTIVTY_LOG_ERROR = "ADMIN_ACTIVTY_LOG_ERROR";

export const SET_CLOUD_PAGE = "SET_CLOUD_PAGE";
export const SET_CLOUD_SIZE_PER_PAGE = "SET_CLOUD_SIZE_PER_PAGE";

export const SORT_CLOUD_PAGE = "SORT_CLOUD_PAGE";
export const SORT_ORDER_CLOUD_SIZE_PER_PAGE = "SORT_ORDER_CLOUD_SIZE_PER_PAGE";

export const SET_SEARCH_CLOUD_PAGE = "SET_SEARCH_CLOUD_PAGE";

export const CLEAR_CLOUD_PAGE = "CLEAR_CLOUD_PAGE";

export const SET_SELECTED_IDS = "SET_SELECTED_IDS";

export const UPDATE_DOWNLOAD_STATUS = "UPDATE_DOWNLOAD_STATUS";

export const EDIT_FILE_NAME_LOADING = "EDIT_FILE_NAME_LOADING";
export const EDIT_FILE_NAME = "EDIT_FILE_NAME";
export const EDIT_FILE_NAME_ERROR = "EDIT_FILE_NAME_ERROR";
