import {
  ADD_PAYMENT_METHOD,
  ADD_PAYMENT_METHOD_ERROR,
  ADD_PAYMENT_METHOD_SUCCESS,
  CLEAR_PAYMENT_METHODS,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_ERROR,
  DELETE_PAYMENT_METHOD_SUCCESS,
  FETCH_PAYMENT_METHODS,
  FETCH_PAYMENT_METHODS_ERROR,
  FETCH_PAYMENT_METHODS_SUCCESS,
} from "./actionTypes";

export const fetchUserPaymentMethods = data => ({
  type: FETCH_PAYMENT_METHODS,
  payload: data,
});

export const fetchUserPaymentMethodsSuccess = data => ({
  type: FETCH_PAYMENT_METHODS_SUCCESS,
  payload: data,
});

export const fetchUserPaymentMethodsError = () => ({
  type: FETCH_PAYMENT_METHODS_ERROR,
});

export const clearPaymentMethods = () => ({
  type: CLEAR_PAYMENT_METHODS,
});

export const addPaymentMethod = data => ({
  type: ADD_PAYMENT_METHOD,
  payload: data,
});

export const addPaymentMethodSuccess = data => ({
  type: ADD_PAYMENT_METHOD_SUCCESS,
  payload: data,
});

export const addPaymentMethodError = () => ({
  type: ADD_PAYMENT_METHOD_ERROR,
});

export const deletePaymentMethod = data => ({
  type: DELETE_PAYMENT_METHOD,
  payload: data,
});

export const deletePaymentMethodSuccess = data => ({
  type: DELETE_PAYMENT_METHOD_SUCCESS,
  payload: data,
});

export const deletePaymentMethodError = () => ({
  type: DELETE_PAYMENT_METHOD_ERROR,
});
