import {
  FETCH_SUBSCRIPTION_REPORTS,
  FETCH_SUBSCRIPTION_REPORTS_ERROR,
  FETCH_SUBSCRIPTION_REPORTS_SUCCESS,
  FETCH_SUBSCRIPTION_TABLE_DATA,
  FETCH_SUBSCRIPTION_TABLE_DATA_ERROR,
  FETCH_SUBSCRIPTION_TABLE_DATA_SUCCESS,
  SET_ANALYTICS_SUBSCRIPTION_RENEWAL_PAGE,
  SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SIZE_PER_PAGE,
  SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SORT_FIELD,
  SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SORT_ORDER,
  SET_RENEWAL_ANALYTICS_MONTH,
  SET_RENEWAL_ANALYTICS_YEAR,
} from "./actionTypes";

const reports = {
  completed: 0,
  cancelled: 0,
  due: 0,
  pending_due: 0,
};
const initialState = {
  loadingReports: false,
  subscriptionReport: reports,
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  tableData: {
    loading: false,
    rows: [],
    totalSize: 0,
    ordersSubscription: 0,
    page: 1,
    sizePerPage: 25,
    sortOrder: "ASC",
    sortField: "Date",
    cancelled_count: 0,
    renewed_count: 0,
    due_count: 0,
    overdue_count: 0,
  },
};

const change = (key, value, state) => ({
  ...state,
  tableData: {
    ...state.tableData,
    [key]: value,
  },
});

const analyticsRenewalReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SUBSCRIPTION_REPORTS:
      return {
        ...state,
        loadingReports: true,
      };
    case FETCH_SUBSCRIPTION_REPORTS_SUCCESS:
      return {
        ...state,
        subscriptionReport: payload.completed === undefined ? reports : payload,
        loadingReports: false,
      };
    case FETCH_SUBSCRIPTION_REPORTS_ERROR:
      return { ...state, loadingReports: false };

    // TABLE
    case SET_ANALYTICS_SUBSCRIPTION_RENEWAL_PAGE:
      return change("page", payload, state);
    case SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SIZE_PER_PAGE:
      return change("sizePerPage", payload, state);
    case SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SORT_FIELD:
      return change("sortField", payload, state);
    case SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SORT_ORDER:
      return change("sortOrder", payload, state);

    case FETCH_SUBSCRIPTION_TABLE_DATA:
      return change("loading", true, state);
    case FETCH_SUBSCRIPTION_TABLE_DATA_SUCCESS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          loading: false,
          rows: payload.rows,
          totalSize: payload.totalSize,
          ordersCount: payload.orderCounts || 0,
          cancelled_count: payload.cancelled,
          renewed_count: payload.renewed,
          due_count: payload.due,
          overdue_count: payload.overdue,
        },
      };
    case FETCH_SUBSCRIPTION_TABLE_DATA_ERROR:
      return change("loading", false, state);
    case SET_RENEWAL_ANALYTICS_YEAR:
      return {
        ...state,
        year: payload,
      };
    case SET_RENEWAL_ANALYTICS_MONTH:
      return {
        ...state,
        month: payload,
      };

    default:
      return state;
  }
};

export default analyticsRenewalReducer;
