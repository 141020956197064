// Va 05 - 01 - 21

import {
  CLEAR_EMAIL_LOGS,
  FETCH_EMAIL_LOGS,
  FETCH_EMAIL_LOGS_ERROR,
  FETCH_EMAIL_LOGS_SUCCESS,
  SET_EMAIL_LOGS_PAGE,
} from "./actionTypes";

export const fetchEmailLogs = data => ({
  type: FETCH_EMAIL_LOGS,
  payload: data,
});

export const fetchEmailLogsSuccess = data => ({
  type: FETCH_EMAIL_LOGS_SUCCESS,
  payload: data,
});

export const fetchEmailLogsError = () => ({
  type: FETCH_EMAIL_LOGS_ERROR,
});
export const clearEmailLogs = () => ({
  type: CLEAR_EMAIL_LOGS,
});

export const setEmailLogsPage = data => ({
  type: SET_EMAIL_LOGS_PAGE,
  payload: data,
});
