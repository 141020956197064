// Submit Note
export const ADD_NOTE = "ADD_NOTE";
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";
export const ADD_NOTE_ERROR = "ADD_NOTE_ERROR";

// Get Note
export const FETCH_NOTE = "FETCH_NOTE";
export const FETCH_NOTE_SUCCESS = "FETCH_NOTE_SUCCESS";
export const FETCH_NOTE_ERROR = "FETCH_NOTE_ERROR";

// Delete Note
export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_ERROR = "DELETE_NOTE_ERROR";
export const RESET_NOTE = "RESET_NOTE";

export const FETCH_CAMPAINGN = "FETCH_CAMPAINGN";
export const FETCH_CAMPAINGN_SUCCESS = "FETCH_CAMPAINGN_SUCCESS";
export const FETCH_CAMPAINGN_ERROR = "FETCH_CAMPAINGN_ERROR";
