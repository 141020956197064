import { dynamicSiteId } from "constants/siteDetails";
import { compareTypes } from "modules/Analytics/common/constants";
import DateRange from "modules/Analytics/common/utils";
import moment from "moment";
import {
  ANALYTICS_REVENUE_SET_TZ,
  CLEAR_REVENUE_REPORT,
  CLEAR_SEARCH_QUERY_REPORT,
  FETCH_REVENUE_GRAPH_DATA,
  FETCH_REVENUE_GRAPH_DATA_ERROR,
  FETCH_REVENUE_GRAPH_DATA_SUCCESS,
  FETCH_REVENUE_REPORTS,
  FETCH_REVENUE_REPORTS_ERROR,
  FETCH_REVENUE_REPORTS_SUCCESS,
  FETCH_REVENUE_TABLE_DATA,
  FETCH_REVENUE_TABLE_DATA_ERROR,
  FETCH_REVENUE_TABLE_DATA_SUCCESS,
  SET_ANALYTICS_REVENUE_ACTIVE_TAB,
  SET_ANALYTICS_REVENUE_BY,
  SET_ANALYTICS_REVENUE_COMPARE_TYPE,
  SET_ANALYTICS_REVENUE_CURRENT_SITE,
  SET_ANALYTICS_REVENUE_PAGE,
  SET_ANALYTICS_REVENUE_RANGE_DATE,
  SET_ANALYTICS_REVENUE_SIZE_PER_PAGE,
  SET_ANALYTICS_REVENUE_SORT_FIELD,
  SET_ANALYTICS_REVENUE_SORT_ORDER,
} from "./actionTypes";

const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();
const defaultCompareType = compareTypes.PERIOD;

const reports = {
  gross_sales: {
    value: 0,
    previousPeriodValue: 0,
  },
  refunds: {
    value: 0,
    previousPeriodValue: 0,
  },
  coupons: {
    value: 0,
    previousPeriodValue: 0,
  },
  net_sales: {
    value: 0,
    previousPeriodValue: 0,
  },
  processing_fees: {
    value: 0,
    previousPeriodValue: 0,
  },
};

const initialState = {
  loadingReports: false,
  reports: reports,
  tempChartData: {
    cp: [],
    pp: [],
  },
  chartData: [],
  loadingChartData: false,
  activeTab: "gross_sales",
  range: {
    startDate,
    endDate,
  },
  compareToPrevious: defaultCompareType,
  comparedRange: new DateRange(startDate, endDate).getPreviousDates(
    defaultCompareType
  ),
  by: "day",
  siteId: dynamicSiteId.Curriculum,
  tableData: {
    loading: false,
    rows: [],
    totalSize: 0,
    ordersCount: 0,
    page: 1,
    sizePerPage: 25,
    sortOrder: "DESC",
    sortField: "date",
  },
  tz: "-0400",
};

const change = (key, value, state) => ({
  ...state,
  tableData: {
    ...state.tableData,
    [key]: value,
  },
});

const analyticsRevenueReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ANALYTICS_REVENUE_SET_TZ:
      return {
        ...state,
        tz: payload,
      };
    case SET_ANALYTICS_REVENUE_CURRENT_SITE:
      return {
        ...state,
        siteId: payload,
      };
    case CLEAR_SEARCH_QUERY_REPORT:
      return {
        ...state,
        range: {
          ...state.range,
          startDate,
          endDate,
        },
        comparedRange: new DateRange(startDate, endDate).getPreviousDates(
          defaultCompareType
        ),
      };
    case CLEAR_REVENUE_REPORT:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          page: 1,
        },
      };
    case SET_ANALYTICS_REVENUE_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };
    case SET_ANALYTICS_REVENUE_RANGE_DATE:
      const prevBy = state.by;
      const newBy = new DateRange(
        payload.startDate,
        payload.endDate
      ).getByOptions()[0].value;
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
        comparedRange: new DateRange(
          payload.startDate,
          payload.endDate
        ).getPreviousDates(state.compareToPrevious),
        ...(prevBy !== newBy ? { by: newBy } : {}),
      };
    case SET_ANALYTICS_REVENUE_COMPARE_TYPE:
      return {
        ...state,
        compareToPrevious: payload,
        comparedRange: new DateRange(
          state.range.startDate,
          state.range.endDate
        ).getPreviousDates(payload),
      };
    case SET_ANALYTICS_REVENUE_BY:
      return {
        ...state,
        by: payload,
      };

    // TABLE
    case SET_ANALYTICS_REVENUE_PAGE:
      return change("page", payload, state);
    case SET_ANALYTICS_REVENUE_SIZE_PER_PAGE:
      return change("sizePerPage", payload, state);
    case SET_ANALYTICS_REVENUE_SORT_FIELD:
      return change("sortField", payload, state);
    case SET_ANALYTICS_REVENUE_SORT_ORDER:
      return change("sortOrder", payload, state);

    // APIS
    case FETCH_REVENUE_REPORTS:
      return {
        ...state,
        loadingReports: true,
      };
    case FETCH_REVENUE_REPORTS_SUCCESS:
      return {
        ...state,
        reports: payload,
        loadingReports: false,
      };
    case FETCH_REVENUE_REPORTS_ERROR:
      return {
        ...state,
        loadingReports: false,
      };
    case FETCH_REVENUE_GRAPH_DATA:
      return {
        ...state,
        loadingChartData: true,
      };
    case FETCH_REVENUE_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        chartData: payload,
        loadingChartData: false,
      };
    case FETCH_REVENUE_GRAPH_DATA_ERROR:
      return {
        ...state,
        loadingChartData: false,
      };
    case FETCH_REVENUE_TABLE_DATA:
      return change("loading", true, state);
    case FETCH_REVENUE_TABLE_DATA_SUCCESS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          loading: false,
          rows: payload.rows,
          totalSize: payload.totalSize,
          ordersCount: payload.orderCounts || 0,
        },
      };
    case FETCH_REVENUE_TABLE_DATA_ERROR:
      return change("loading", false, state);
    default:
      return state;
  }
};

export default analyticsRevenueReducer;
