import {
  ADD_EDIT_VOLUME_PAGE,
  ADD_EDIT_VOLUME_PAGE_ERROR,
  ADD_EDIT_VOLUME_PAGE_FOLDER,
  ADD_EDIT_VOLUME_PAGE_FOLDER_ERROR,
  ADD_EDIT_VOLUME_PAGE_FOLDER_SUCCESS,
  ADD_EDIT_VOLUME_PAGE_SUCCESS,
  DELETE_VOLUME_PAGES,
  DELETE_VOLUME_PAGES_ERROR,
  DELETE_VOLUME_PAGES_SUCCESS,
  DELETE_VOLUME_PAGE_FOLDER,
  DELETE_VOLUME_PAGE_FOLDER_ERROR,
  DELETE_VOLUME_PAGE_FOLDER_SUCCESS,
  FETCH_CURRICULUM_CATEGORY_VOLUME_PAGE_SUCCESS,
  FETCH_CURRICULUM_VOLUME_PAGE_CATEGORY,
  FETCH_VOLUME_PAGES,
  FETCH_VOLUME_PAGES_ERROR,
  FETCH_VOLUME_PAGES_SUCCESS,
  FETCH_VOLUME_PAGE_FOLDERS,
  FETCH_VOLUME_PAGE_FOLDER_ERROR,
  FETCH_VOLUME_PAGE_FOLDER_SUCCESS,
  GET_VOLUME_PAGE_DETAILS,
  GET_VOLUME_PAGE_DETAILS_ERROR,
  GET_VOLUME_PAGE_DETAILS_SUCCESS,
  GET_VOLUME_PAGE_FOLDER_DETAILS,
  GET_VOLUME_PAGE_FOLDER_DETAILS_SUCCESS,
  LIST_ALL_CONTENT_TYPE_ID_AND_TITLE,
  LIST_ALL_CONTENT_TYPE_ID_AND_TITLE_SUCCESS,
  LIST_ALL_OPTIONS,
  LIST_ALL_OPTIONS_ERROR,
  LIST_ALL_OPTIONS_SUCCESS,
  PUBLISH_VOLUME_PAGES,
  PUBLISH_VOLUME_PAGES_ERROR,
  PUBLISH_VOLUME_PAGES_SUCCESS,
  SET_VOLUME_PAGE,
} from "./actionTypes";

const initialState = {
  loading: false,
  pages: [],
  page: null,
  options: [],
  pageFolder: [],
  pageFolderDetails: [],
  loadingPageButton: false,
  loadingPageDetails: false,
  loadingOptions: false,
  loadingPubUnPub: false,
};

const VolumepagesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_EDIT_VOLUME_PAGE:
      return {
        ...state,
        loadingPageButton: true,
      };
    case ADD_EDIT_VOLUME_PAGE_SUCCESS:
      return {
        ...state,
        loadingPageButton: false,
      };
    case ADD_EDIT_VOLUME_PAGE_ERROR:
      return {
        ...state,
        loadingPageButton: false,
      };
    case FETCH_VOLUME_PAGES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_VOLUME_PAGES_SUCCESS:
      return {
        ...state,
        pages: payload.page_list,
        loading: false,
      };
    case FETCH_VOLUME_PAGES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SET_VOLUME_PAGE:
      return {
        ...state,
        page: payload,
      };

    case DELETE_VOLUME_PAGES:
      return {
        ...state,
        loading: true,
      };
    case DELETE_VOLUME_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_VOLUME_PAGES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case LIST_ALL_CONTENT_TYPE_ID_AND_TITLE:
      return {
        ...state,
        loading: true,
      };
    case LIST_ALL_CONTENT_TYPE_ID_AND_TITLE_SUCCESS:
      return {
        ...state,
        content_type_list: payload.data.content_type_list,
        loading: false,
      };
    case FETCH_CURRICULUM_VOLUME_PAGE_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CURRICULUM_CATEGORY_VOLUME_PAGE_SUCCESS:
      return {
        ...state,
        curriculum_categories: payload.rows,
        loading: false,
        totalSize: payload.count,
      };
    case LIST_ALL_OPTIONS:
      return {
        ...state,
        loadingOptions: true,
      };
    case LIST_ALL_OPTIONS_SUCCESS:
      return {
        ...state,
        loadingOptions: false,
        options: payload,
      };
    case LIST_ALL_OPTIONS_ERROR:
      return {
        ...state,
        loadingOptions: false,
      };
    case GET_VOLUME_PAGE_DETAILS:
      return {
        ...state,
        loadingPageDetails: true,
      };
    case GET_VOLUME_PAGE_DETAILS_SUCCESS:
      return {
        ...state,
        page: payload,
        loadingPageDetails: false,
      };
    case GET_VOLUME_PAGE_DETAILS_ERROR:
      return {
        ...state,
        loadingPageDetails: false,
      };
    case PUBLISH_VOLUME_PAGES:
      return {
        ...state,
        loadingPubUnPub: true,
      };
    case PUBLISH_VOLUME_PAGES_SUCCESS:
      return {
        ...state,
        page: payload,
        loadingPubUnPub: false,
      };
    case PUBLISH_VOLUME_PAGES_ERROR:
      return {
        ...state,
        loadingPubUnPub: false,
      };
    case FETCH_VOLUME_PAGE_FOLDERS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_VOLUME_PAGE_FOLDER_SUCCESS:
      return {
        ...state,
        pageFolder: payload.rows,
        loading: false,
      };
    case FETCH_VOLUME_PAGE_FOLDER_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ADD_EDIT_VOLUME_PAGE_FOLDER:
      return {
        ...state,
        loading: true,
      };
    case ADD_EDIT_VOLUME_PAGE_FOLDER_SUCCESS:
      return {
        ...state,
        is_modal: false,
      };
    case ADD_EDIT_VOLUME_PAGE_FOLDER_ERROR:
      return {
        ...state,
        loading: false,
      };

    case GET_VOLUME_PAGE_FOLDER_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case GET_VOLUME_PAGE_FOLDER_DETAILS_SUCCESS:
      return {
        ...state,
        pageFolderDetails: payload.pageFolder,
        loading: false,
      };
    case DELETE_VOLUME_PAGE_FOLDER:
      return {
        ...state,
        loading: true,
      };
    case DELETE_VOLUME_PAGE_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_VOLUME_PAGE_FOLDER_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default VolumepagesReducer;
