import { axiosApiAdmin } from "helpers/api_helper";
import { SIGNUP_CAMPAIGN_REPORT } from "helpers/url_helper";
import { toast } from "react-toastify";
import { getCampaignDataError, getCampaignDataSuccess } from "./actions";
import { FETCH_CAMPAIGN_REPORTS } from "./actionTypes";

const { takeEvery, call, put } = require("redux-saga/effects");

const fetchCampaignData = payload =>
  axiosApiAdmin.post(SIGNUP_CAMPAIGN_REPORT, payload);

function* CampaignDataSaga() {
  function* handleCampaignData({ payload }) {
    try {
      const res = yield call(fetchCampaignData, payload);
      if (!!res?.data?.data?.data) {
        yield put(
          getCampaignDataSuccess({
            data: res?.data?.data?.data?.rows,
            count: res?.data?.data?.data?.count,
          })
        );
      }
    } catch (err) {
      yield put(getCampaignDataError());
      toast.error(err?.response?.data?.message || err.message);
    }
  }

  yield takeEvery(FETCH_CAMPAIGN_REPORTS, handleCampaignData);
}

export default CampaignDataSaga;
