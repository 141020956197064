// @flow
import {
  FETCH_CONFIG_DATA_ERROR,
  FETCH_CONFIG_DATA_LOADING,
  FETCH_CONFIG_DATA_SUCCESS,
  GET_GEO_DATA_ERROR,
  GET_GEO_DATA_LOADING,
  GET_GEO_DATA_SUCCESS,
  SAVE_CONFIG_DATA_ERROR,
  SAVE_CONFIG_DATA_LOADING,
  SAVE_CONFIG_DATA_SUCCESS,
} from "./actionTypes";

const initialState = {
  fetchLoading: false,
  mapData: null,
  configLoading: false,
  configData: null,
  saveConfigLoading: false,
};

const geoLocationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_GEO_DATA_LOADING:
      return {
        ...state,
        fetchLoading: true,
      };
    case GET_GEO_DATA_SUCCESS:
      return {
        ...state,
        mapData: payload,
        fetchLoading: false,
      };
    case GET_GEO_DATA_ERROR:
      return {
        ...state,
        fetchLoading: false,
      };
    case FETCH_CONFIG_DATA_LOADING:
      return {
        ...state,
        configLoading: true,
      };
    case FETCH_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        configData: payload,
        configLoading: false,
      };
    case FETCH_CONFIG_DATA_ERROR:
      return {
        ...state,
        configLoading: false,
      };
    case SAVE_CONFIG_DATA_LOADING:
      return {
        ...state,
        saveConfigLoading: true,
      };
    case SAVE_CONFIG_DATA_SUCCESS:
      return {
        ...state,
        saveConfigLoading: false,
      };
    case SAVE_CONFIG_DATA_ERROR:
      return {
        ...state,
        saveConfigLoading: false,
      };
    default:
      return state;
  }
};

export default geoLocationReducer;
