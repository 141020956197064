// Va 06 - 01 - 21
import { axiosApiAdmin } from "helpers/api_helper";
import { USER_ACTIVE_PRODUCT } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchActiveProductError,
  fetchActiveProductSuccess,
  setErrorMessage,
} from "store/actions";
import { FETCH_ACTIVE_PRODUCT } from "./actionTypes";

const fetchActiveProducts = data =>
  axiosApiAdmin.post(USER_ACTIVE_PRODUCT, data);

function* handleFetchActiveProduct({ payload }) {
  try {
    const res = yield call(fetchActiveProducts, payload);
    if (res?.data?.data) {
      yield put(fetchActiveProductSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchActiveProductError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* activeProductSaga() {
  yield takeEvery(FETCH_ACTIVE_PRODUCT, handleFetchActiveProduct);
}

export default activeProductSaga;
