import {
  FETCH_GROWCON_REPORTS,
  FETCH_GROWCON_REPORTS_ERROR,
  FETCH_GROWCON_REPORTS_SUCCESS,
} from "./actionTypes";

export const getGrowConData = data => ({
  type: FETCH_GROWCON_REPORTS,
  payload: data,
});

export const getGrowConDataSuccess = data => ({
  type: FETCH_GROWCON_REPORTS_SUCCESS,
  payload: data,
});

export const getGrowConDataError = () => ({
  type: FETCH_GROWCON_REPORTS_ERROR,
});
