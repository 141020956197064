import {
  ANALYTICS_REVENUE_SET_TZ,
  CLEAR_REVENUE_REPORT,
  CLEAR_SEARCH_QUERY_REPORT,
  FETCH_REVENUE_GRAPH_DATA,
  FETCH_REVENUE_GRAPH_DATA_ERROR,
  FETCH_REVENUE_GRAPH_DATA_SUCCESS,
  FETCH_REVENUE_REPORTS,
  FETCH_REVENUE_REPORTS_ERROR,
  FETCH_REVENUE_REPORTS_SUCCESS,
  FETCH_REVENUE_TABLE_DATA,
  FETCH_REVENUE_TABLE_DATA_ERROR,
  FETCH_REVENUE_TABLE_DATA_SUCCESS,
  SET_ANALYTICS_REVENUE_ACTIVE_TAB,
  SET_ANALYTICS_REVENUE_BY,
  SET_ANALYTICS_REVENUE_COMPARE_TYPE,
  SET_ANALYTICS_REVENUE_CURRENT_SITE,
  SET_ANALYTICS_REVENUE_PAGE,
  SET_ANALYTICS_REVENUE_RANGE_DATE,
  SET_ANALYTICS_REVENUE_SIZE_PER_PAGE,
  SET_ANALYTICS_REVENUE_SORT_FIELD,
  SET_ANALYTICS_REVENUE_SORT_ORDER,
} from "./actionTypes";

export const setRevenueTZ = data => ({
  type: ANALYTICS_REVENUE_SET_TZ,
  payload: data,
});

export const setAnalyticsRevenueSiteId = data => ({
  type: SET_ANALYTICS_REVENUE_CURRENT_SITE,
  payload: data,
});

export const clearRevenueReport = () => ({
  type: CLEAR_REVENUE_REPORT,
});

export const clearSearchQueryReport = () => ({
  type: CLEAR_SEARCH_QUERY_REPORT,
});

export const setAnalyticsRevenueActiveTab = data => ({
  type: SET_ANALYTICS_REVENUE_ACTIVE_TAB,
  payload: data,
});

export const setAnalyticRevenueRangeDate = data => ({
  type: SET_ANALYTICS_REVENUE_RANGE_DATE,
  payload: data,
});

export const setAnalyticCompareType = data => ({
  type: SET_ANALYTICS_REVENUE_COMPARE_TYPE,
  payload: data,
});

export const setAnalyticsRevenueBy = data => ({
  type: SET_ANALYTICS_REVENUE_BY,
  payload: data,
});

// TABLE
export const setAnalyticsRevenuePage = data => ({
  type: SET_ANALYTICS_REVENUE_PAGE,
  payload: data,
});
export const setAnalyticsRevenueSizePerPage = data => ({
  type: SET_ANALYTICS_REVENUE_SIZE_PER_PAGE,
  payload: data,
});
export const setAnalyticsRevenueSortField = data => ({
  type: SET_ANALYTICS_REVENUE_SORT_FIELD,
  payload: data,
});
export const setAnalyticsRevenueSortOrder = data => ({
  type: SET_ANALYTICS_REVENUE_SORT_ORDER,
  payload: data,
});

// APIS
export const getRevenueReports = data => ({
  type: FETCH_REVENUE_REPORTS,
  payload: data,
});

export const getRevenueReportsSuccess = data => ({
  type: FETCH_REVENUE_REPORTS_SUCCESS,
  payload: data,
});

export const getRevenueReportsError = () => ({
  type: FETCH_REVENUE_REPORTS_ERROR,
});

export const getRevenueGraphData = data => ({
  type: FETCH_REVENUE_GRAPH_DATA,
  payload: data,
});

export const getRevenueGraphDataSuccess = data => ({
  type: FETCH_REVENUE_GRAPH_DATA_SUCCESS,
  payload: data,
});

export const getRevenueGraphDataError = () => ({
  type: FETCH_REVENUE_GRAPH_DATA_ERROR,
});

export const getRevenueTableData = () => ({
  type: FETCH_REVENUE_TABLE_DATA,
});

export const getRevenueTableDataSuccess = data => ({
  type: FETCH_REVENUE_TABLE_DATA_SUCCESS,
  payload: data,
});

export const getRevenueTableDataError = () => ({
  type: FETCH_REVENUE_TABLE_DATA_ERROR,
});
