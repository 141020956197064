//Revenue Report
export const GET_REVENUE_REPORT_DATA = "GET_REVENUE_REPORT_DATA";
export const GET_REVENUE_REPORT_DATA_SUCCESS =
  "GET_REVENUE_REPORT_DATA_SUCCESS";
export const GET_REVENUE_REPORT_DATA_ERROR = "GET_REVENUE_REPORT_DATA_ERROR";
export const SET_REVENUE_REPORT_DATA_PAGE = "ET_REVENUE_REPORT_DATA_PAGE";
export const SET_REVENUE_REPORT_DATA_SEARCH = "SET_REVENUE_REPORT_DATA_SEARCH";
export const SET_REVENUE_REPORT_DATA_SIZE_PER_PAGE =
  "SET_REVENUE_REPORT_DATA_SIZE_PER_PAGE";
export const SET_REVENUE_REPORT_START_DATE = "SET_REVENUE_REPORT_START_DATE";
export const SET_REVENUE_REPORT_END_DATE = "SET_REVENUE_REPORT_END_DATE";
export const SET_REVENUE_REPORT_SORT_FIELD = "SET_REVENUE_REPORT_SORT_FIELD";
export const SET_REVENUE_REPORT_SORT_ORDER = "SET_REVENUE_REPORT_SORT_ORDER";
export const CLEAR_REVENUE_REPORT = "CLEAR_REVENUE_REPORT";

//Product Revenue Report
export const GET_PRODUCT_REVENUE_REPORT_DATA =
  "GET_PRODUCT_REVENUE_REPORT_DATA";
export const GET_PRODUCT_REVENUE_REPORT_DATA_SUCCESS =
  "GET_PRODUCT_REVENUE_REPORT_DATA_SUCCESS";
export const GET_PRODUCT_REVENUE_REPORT_DATA_ERROR =
  "GET_PRODUCT_REVENUE_REPORT_DATA_ERROR";

export const SET_PRODUCT_REVENUE_REPORT_DATA_PAGE =
  "SET_PRODUCT_REVENUE_REPORT_DATA";
export const SET_PRODUCT_REVENUE_REPORT_DATA_SEARCH =
  "SET_PRODUCT_REVENUE_REPORT_DATA_SEARCH";
export const SET_PRODUCT_REVENUE_REPORT_DATA_SIZE_PER_PAGE =
  "SET_PRODUCT_REVENUE_REPORT_DATA_SIZE_PER_PAGE";
export const SET_PRODUCT_REVENUE_REPORT_START_DATE =
  "SET_PRODUCT_REVENUE_REPORT_START_DATE";
export const SET_PRODUCT_REVENUE_REPORT_END_DATE =
  "SET_PRODUCT_REVENUE_REPORT_END_DATE";
export const SET_PRODUCT_REVENUE_REPORT_SORT_FIELD =
  "SET_PRODUCT_REVENUE_REPORT_SORT_FIELD";
export const SET_PRODUCT_REVENUE_REPORT_SORT_ORDER =
  "SET_PRODUCT_REVENUE_REPORT_SORT_ORDER";

export const CLEAR_PRODUCT_REPORT_STATUS = "CLEAR_PRODUCT_REPORT_STATUS";

//Renewal report
export const GET_RENEWAL_DATA = "GET_RENEWAL_DATA";
export const GET_RENEWAL_DATA_SUCCESS = "GET_RENEWAL_DATA_SUCCESS";
export const GET_RENEWAL_DATA_ERROR = "GET_RENEWAL_DATA_ERROR";
export const RENEWAL_DATA_PAGE = "RENEWAL_DATA_PAGE";
export const RENEWAL_SIZE_PER_PAGE = "RENEWAL_SIZE_PER_PAGE";
export const SET_RENEWAL_SORT_FIELD = "SET_RENEWAL_SORT_FIELD";
export const SET_RENEWAL_SORT_ORDER = "SET_RENEWAL_SORT_ORDER";
export const CLEAR_RENEWAL_FEATURES = "CLEAR_RENEWAL_FEATURES";

//Registered Users

export const LIST_REGISTERED_USERS = "LIST_REGISTERED_USERS";
export const LIST_REGISTERED_USERS_SUCCESS = "LIST_REGISTERED_USERS_SUCCESS";
export const LIST_REGISTERED_USERS_ERROR = "LIST_REGISTERED_USERS_ERROR";
export const REGISTERED_USER_DATA_PAGE = "REGISTERED_USER_DATA_PAGE";
export const REGISTERED_USER_SIZE_PER_PAGE = "REGISTERED_USER_SIZE_PER_PAGE";
export const SET_REGISTERED_USER_SORT_FIELD = "SET_REGISTERED_USER_SORT_FIELD";
export const SET_REGISTERED_USER_SORT_ORDER = "SET_REGISTERED_USER_SORT_ORDER";
export const CLEAR_REGISTERED_USER_FEATURES = "CLEAR_REGISTERED_USER_FEATURES";
