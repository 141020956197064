import {
  LIST_GROW_CON_VIDEO_SLIDER_ERROR,
  LIST_GROW_CON_VIDEO_SLIDER_LOADING,
  LIST_GROW_CON_VIDEO_SLIDER_SUCCESS,
  SET_PAYLOAD,
} from "./actionTypes";

const initialState = {
  listVideoLoading: false,
  count: 0,
  Videos: [],
  payload: {
    search: "",
    page_no: 1,
    page_record: 10,
    is_from_admin: true,
  },
};
const applicationsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LIST_GROW_CON_VIDEO_SLIDER_LOADING:
      return {
        ...state,
        listVideoLoading: true,
      };
    case LIST_GROW_CON_VIDEO_SLIDER_SUCCESS:
      return {
        ...state,
        count: payload?.count,
        Videos: payload?.rows,
        listVideoLoading: false,
      };
    case LIST_GROW_CON_VIDEO_SLIDER_ERROR:
      return {
        ...state,
        listVideoLoading: false,
      };
    case SET_PAYLOAD:
      return {
        ...state,
        payload: action.payload,
      };

    default:
      return state;
  }
};
export default applicationsReducer;
