// Smit 22-11

import { axiosApiAccount } from "helpers/api_helper";
import { FETCH_USER_PROFILE, LOGIN } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  apiError,
  fetchProfile,
  fetchProfileError,
  fetchProfileSuccess,
  loginUserSuccess,
  setLoginEmailMessage,
} from "store/actions";
import { FETCH_LOGGED_IN_USER_PROFILE, LOGIN_USER } from "./actionTypes";

const login = user => axiosApiAccount.post(LOGIN, user);
const fetchUserProfile = () => axiosApiAccount.get(FETCH_USER_PROFILE);

function* handleLogin({ payload: { user } }) {
  try {
    const res = yield call(login, user);

    if (res.data?.data?.user) {
      yield put(loginUserSuccess());
      yield put(fetchProfile());
    } else {
      yield put(setLoginEmailMessage(res.data.message));
    }
  } catch (err) {
    yield put(apiError(err.response?.data?.message || err.message));
  }
}
function* handleFetchProfile({ payload: cb }) {
  try {
    const res = yield call(fetchUserProfile);
    if (res.data?.data?.user) {
      yield put(fetchProfileSuccess(res.data.data.user));
      if (!!cb && typeof cb === "function") cb();
    }
  } catch (err) {
    yield put(fetchProfileError());
    yield put(apiError(err.response?.data?.message || err.message));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, handleLogin);
  yield takeEvery(FETCH_LOGGED_IN_USER_PROFILE, handleFetchProfile);
}

export default authSaga;
