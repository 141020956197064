import {
  GET_ADD_NEW_CALENDAR,
  LISTING_CALENDAR,
  LISTING_LOADING_DATA,
} from "./actionType";

export const getAllShipMentDataReport = data => ({
  type: GET_ADD_NEW_CALENDAR,
  payload: data,
});

export const listingDataReport = data => ({
  type: LISTING_CALENDAR,
  payload: data,
});

export const listingDataLoading = data => ({
  type: LISTING_LOADING_DATA,
  payload: data,
});
