export const SET_ANALYTICS_COUPONS_ACTIVE_TAB =
  "SET_ANALYTICS_COUPONS_ACTIVE_TAB";
export const SET_ANALYTICS_COUPONS_RANGE_DATE =
  "SET_ANALYTICS_COUPONS_RANGE_DATE";
export const SET_ANALYTICS_COUPONS_COMPARE_TYPE =
  "SET_ANALYTICS_COUPONS_COMPARE_TYPE";
export const SET_ANALYTICS_COUPONS_BY = "SET_ANALYTICS_COUPONS_BY";
export const SET_ANALYTICS_COUPON_CURRENT_SITE =
  "SET_ANALYTICS_COUPON_CURRENT_SITE";
export const ANALYTICS_COUPON_SET_TZ = "ANALYTICS_COUPON_SET_TZ";

// CLEAR

export const CLEAR_COUPON_REPORT = "CLEAR_COUPON_REPORT";
export const CLEAR_SEARCH_QUERY_REPORT = "CLEAR_SEARCH_QUERY_REPORT";

// TABLE
export const SET_ANALYTICS_COUPONS_PAGE = "SET_ANALYTICS_COUPONS_PAGE";
export const SET_ANALYTICS_COUPONS_SIZE_PER_PAGE =
  "SET_ANALYTICS_COUPONS_SIZE_PER_PAGE";
export const SET_ANALYTICS_COUPONS_SORT_FIELD =
  "SET_ANALYTICS_COUPONS_SORT_FIELD";
export const SET_ANALYTICS_COUPONS_SORT_ORDER =
  "SET_ANALYTICS_COUPONS_SORT_ORDER";

// APIS
export const FETCH_COUPONS_REPORTS = "FETCH_COUPONS_REPORTS";
export const FETCH_COUPONS_REPORTS_SUCCESS = "FETCH_COUPONS_REPORTS_SUCCESS";
export const FETCH_COUPONS_REPORTS_ERROR = "FETCH_COUPONS_REPORTS_ERROR";

export const FETCH_COUPONS_GRAPH_DATA = "FETCH_COUPONS_GRAPH_DATA";
export const FETCH_COUPONS_GRAPH_DATA_SUCCESS =
  "FETCH_COUPONS_GRAPH_DATA_SUCCESS";
export const FETCH_COUPONS_GRAPH_DATA_ERROR = "FETCH_COUPONS_GRAPH_DATA_ERROR";

export const FETCH_COUPONS_TABLE_DATA = "FETCH_COUPONS_TABLE_DATA";
export const FETCH_COUPONS_TABLE_DATA_SUCCESS =
  "FETCH_COUPONS_TABLE_DATA_SUCCESS";
export const FETCH_COUPONS_TABLE_DATA_ERROR = "FETCH_COUPONS_TABLE_DATA_ERROR";
export const SET_SEARCH_COUPON_DATA = "SET_SEARCH_COUPON_DATA";
