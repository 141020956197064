import {
  GET_ANALYTICS_EMAIL_GRAPH,
  GET_EMAIL_LIST,
  GET_TOTAL_BY_DATE,
} from "./actionTypes";

import { axiosApiAdmin } from "helpers/api_helper";
import {
  LIST_ANALYTICS_EMAIL_DATA,
  LIST_ANALYTICS_EMAIL_DATA_BY_DATE_AND_SUBJECT,
  LIST_EMAIL_GRAPH_DATA_URL,
} from "helpers/url_helper";
import { tzs } from "modules/Analytics/common/constants";
import { DateTimeTz } from "modules/Analytics/common/utils";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  getAnalyticsEmailGraphError,
  getAnalyticsEmailGraphSuccess,
  getEmailListError,
  getEmailListSuccess,
  getTotalByDateError,
  getTotalByDateSuccess,
  setErrorMessage,
} from "store/actions";

const fetchAnalyticsGraph = data =>
  axiosApiAdmin.post(LIST_EMAIL_GRAPH_DATA_URL, data);
const fetchEmailList = data =>
  axiosApiAdmin.post(LIST_ANALYTICS_EMAIL_DATA, data);
const fetchTotalByDate = payload =>
  axiosApiAdmin.post(LIST_ANALYTICS_EMAIL_DATA_BY_DATE_AND_SUBJECT, payload);

function* handleTotalByDate({ payload }) {
  try {
    const res = yield call(fetchTotalByDate, payload);
    if (res.status && res?.data?.data) {
      yield put(getTotalByDateSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getTotalByDateError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* handleFetchAnalyticsEmailGraph() {
  const { range, emailGraphBy, sites, tz } = yield select(
    state => state.AnalyticsEmails
  );

  const payload = {
    range: {
      start_date: DateTimeTz(range.startDate)
        .startTime(tz)
        .format("YYYY-MM-DD HH:mm"),
      end_date: DateTimeTz(range.endDate)
        .endTime(tz)
        .format("YYYY-MM-DD HH:mm"),
    },
    filter: emailGraphBy,
    site_id: parseInt(sites),
    tz: tzs[tz],
  };
  try {
    const res = yield call(fetchAnalyticsGraph, payload);
    if (res.status) {
      yield put(getAnalyticsEmailGraphSuccess(res.data.data.rows));
    }
  } catch (err) {
    yield put(getAnalyticsEmailGraphError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}
function* handleFetchEmailList() {
  try {
    const { table, range, by, sites, tz } = yield select(
      state => state.AnalyticsEmails
    );
    const payload = {
      page_no: table.pageNo,
      page_record: table.pageRecord,
      range: {
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(range.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      by: by,
      sort_field: table.sortField,
      sort_order: table.sortOrder,
      search: table.search,
      filter: parseInt(sites) === 0 ? "" : { site_id: parseInt(sites) },
      tz: tzs[tz],
    };
    const res = yield call(fetchEmailList, payload);
    if (res.status) {
      yield put(getEmailListSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getEmailListError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* productsSaga() {
  yield takeEvery(GET_EMAIL_LIST, handleFetchEmailList);
  yield takeEvery(GET_ANALYTICS_EMAIL_GRAPH, handleFetchAnalyticsEmailGraph);
  yield takeEvery(GET_TOTAL_BY_DATE, handleTotalByDate);
}

export default productsSaga;
