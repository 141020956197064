export const FETCH_FAIL_SUBSCRIPTION = "FETCH_FAIL_SUBSCRIPTION";
export const FETCH_FAIL_SUBSCRIPTION_SUCCESS =
  "FETCH_FAIL_SUBSCRIPTION_SUCCESS";
export const FETCH_FAIL_SUBSCRIPTION_ERROR = "FETCH_FAIL_SUBSCRIPTION_ERROR";

export const CLEAR_FAIL_SUBSCRIPTION = "CLEAR_FAIL_SUBSCRIPTION";

export const SET_PAGE_FAIL_SUBSCRIPTION = "SET_PAGE_FAIL_SUBSCRIPTION";

export const SET_SIZE_PER_PAGE_FAIL_SUBSCRIPTION =
  "SET_SIZE_PER_PAGE_FAIL_SUBSCRIPTION";

export const SET_SEARCH_FAIL_SUBSCRIPTION = "SET_SEARCH_FAIL_SUBSCRIPTION";

export const SET_SORT_FIELD_FAIL_SUBSCRIPTION =
  "SET_SORT_FIELD_FAIL_SUBSCRIPTION";

export const SET_SORT_ORDER_FAIL_SUBSCRIPTION =
  "SET_SORT_ORDER_FAIL_SUBSCRIPTION";

export const SET_ORDER_RANGE_DATE_FAIL_SUBSCRIPTION =
  "SET_ORDER_RANGE_DATE_FAIL_SUBSCRIPTION";
