// Va 05 - 01 - 21

import {
  CLEAR_SUPPORT_TICKETS,
  FATCH_LIST_SUBSCRIPTION_REPORT,
  FATCH_LIST_SUBSCRIPTION_REPORT_ERROR,
  FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT,
  FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT_NULL,
  FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT_SUCCESS,
  FATCH_LIST_SUBSCRIPTION_REPORT_SUCCESS,
  SET_PAGE_SUPPORT_TICKETS,
  SET_SEARCH_SUPPORT_TICKETS,
  SET_SIZE_PER_PAGE_SUPPORT_TICKETS,
  SET_SORT_FIELD_SUPPORT_TICKETS,
  SET_SORT_ORDER_SUPPORT_TICKETS,
} from "./actionTypes";

export const fetchlistSubscriptionReport = (data, cb) => ({
  type: FATCH_LIST_SUBSCRIPTION_REPORT,
  payload: data,
  cb: cb,
});

export const fetchlistSubscriptionReportForExport = (data, cb) => ({
  type: FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT,
  payload: data,
  cb: cb,
});

export const fetchlistSubscriptionReportForExportSuccess = data => ({
  type: FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT_SUCCESS,
  payload: data,
});
export const fetchlistSubscriptionReportForExportNull = data => ({
  type: FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT_NULL,
  payload: data,
});
export const fetchlistSubscriptionReportSuccess = data => ({
  type: FATCH_LIST_SUBSCRIPTION_REPORT_SUCCESS,
  payload: data,
});

export const fetchlistSubscriptionReportError = () => ({
  type: FATCH_LIST_SUBSCRIPTION_REPORT_ERROR,
});
export const clearSupportTickets = () => ({
  type: CLEAR_SUPPORT_TICKETS,
});

export const setPageSupportTickets = data => {
  return {
    type: SET_PAGE_SUPPORT_TICKETS,
    payload: data,
  };
};

export const setSizePerPageSupportTickets = data => {
  return {
    type: SET_SIZE_PER_PAGE_SUPPORT_TICKETS,
    payload: data,
  };
};

export const setSearchSupportTickets = data => {
  return {
    type: SET_SEARCH_SUPPORT_TICKETS,
    payload: data,
  };
};

export const setSortFieldSupportTickets = data => ({
  type: SET_SORT_FIELD_SUPPORT_TICKETS,
  payload: data,
});

export const setSortOrderSupportTickets = data => ({
  type: SET_SORT_ORDER_SUPPORT_TICKETS,
  payload: data,
});
