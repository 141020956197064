// GET PAGE FOLDER
export const FETCH_VOLUME_BUILDER_FOLDERS = "FETCH_VOLUME_BUILDER_FOLDERS";
export const FETCH_VOLUME_BUILDER_FOLDER_SUCCESS =
  "FETCH_VOLUME_BUILDER_FOLDER_SUCCESS";
export const FETCH_VOLUME_BUILDER_FOLDER_ERROR =
  "FETCH_VOLUME_BUILDER_FOLDER_ERROR";

// DELETE PAGE FOLDER
export const DELETE_VOLUME_FOLDER = "DELETE_VOLUME_FOLDER";
export const DELETE_VOLUME_FOLDER_SUCCESS = "DELETE_VOLUME_FOLDER_SUCCESS";
export const DELETE_VOLUME_FOLDER_ERROR = "DELETE_VOLUME_FOLDER_ERROR";

export const ADD_EDIT_VOLUME_FOLDER = "ADD_EDIT_VOLUME_FOLDER";
export const ADD_EDIT_VOLUME_FOLDER_SUCCESS = "ADD_EDIT_VOLUME_FOLDER_SUCCESS";
export const ADD_EDIT_VOLUME_FOLDER_ERROR = "ADD_EDIT_VOLUME_FOLDER_ERROR";

export const GET_VOLUME_FOLDER_DETAILS = "GET_VOLUME_FOLDER_DETAILS";
export const GET_VOLUME_FOLDER_DETAILS_SUCCESS =
  "GET_VOLUME_FOLDER_DETAILS_SUCCESS";

export const FETCH_SERIES = "FETCH_SERIES";
export const FETCH_SERIES_SUCCESS = "FETCH_SERIES_SUCCESS";
export const FETCH_SERIES_ERROR = "FETCH_SERIES_ERROR";

export const ADD_EDIT_VOLUME_SERIES = "ADD_EDIT_VOLUME_SERIES";
export const ADD_EDIT_VOLUME_SERIES_SUCCESS = "ADD_EDIT_VOLUME_SERIES_SUCCESS";
export const ADD_EDIT_VOLUME_SERIES_ERROR = "ADD_EDIT_VOLUME_SERIES_ERROR";

export const GET_VOLUME_SERIES_DETAILS = "GET_VOLUME_SERIES_DETAILS";
export const GET_VOLUME_SERIES_DETAILS_SUCCESS =
  "GET_VOLUME_SERIES_DETAILS_SUCCESS";
export const GET_VOLUME_SERIES_DETAILS_SUCCESS_ERROR =
  "GET_VOLUME_SERIES_DETAILS_SUCCESS";

export const DELETE_SERIES = "DELETE_SERIES";
export const DELETE_SERIES_SUCCESS = "DELETE_SERIES_SUCCESS";
export const DELETE_SERIES_ERROR = "DELETE_SERIES_ERROR";

/*** Series editor */
export const FETCH_SERIES_EDITOR_DETAILS = "FETCH_SERIES_EDITOR_DETAILS";
export const FETCH_SERIES_EDITOR_DETAILS_SUCCESS =
  "FETCH_SERIES_EDITOR_DETAILS_SUCCESS";
export const FETCH_SERIES_EDITOR_DETAILS_ERROR =
  "FETCH_SERIES_EDITOR_DETAILS_ERROR";

export const ADD_EDIT_SERIES_EDITOR = "ADD_EDIT_SERIES_EDITOR";
export const ADD_EDIT_SERIES_EDITOR_SUCCESS = "ADD_EDIT_SERIES_EDITOR_SUCCESS";
export const ADD_EDIT_SERIES_EDITOR_ERROR = "ADD_EDIT_SERIES_EDITOR_ERROR";

export const SET_SEARCH_SERIES = "SET_SEARCH_SERIES";
export const SET_IS_VBS = "SET_IS_VBS";
export const SET_TYPES = "SET_TYPES";
export const CLEAR_SERIES = "CLEAR_SERIES";

export const FETCH_TIP_VIDEOS = "FETCH_TIP_VIDEOS";
export const FETCH_BOARD = "FETCH_BOARD";
export const FETCH_TIP_VIDEOS_SUCCESS = "FETCH_TIP_VIDEOS_SUCCESS";
export const FETCH_ALL_BOARD_DATA = "FETCH_ALL_BOARD_DATA";

export const FETCH_TIP_VIDEOS_ERROR = "FETCH_TIP_VIDEOS_ERROR";

export const SET_TIP_VIDEO_SEARCH = "SET_TIP_VIDEO_SEARCH";

export const SAVE_AMAZON_INTERNALS = "SAVE_AMAZON_INTERNALS";
export const SAVE_AMAZON_INTERNALS_SUCCESS = "SAVE_AMAZON_INTERNALS_SUCCESS";
export const SAVE_AMAZON_INTERNALS_ERROR = "SAVE_AMAZON_INTERNALS_ERROR";

export const GET_AMAZON_INTERNALS = "GET_AMAZON_INTERNALS";
export const GET_AMAZON_INTERNALS_SUCCESS = "GET_AMAZON_INTERNALS_SUCCESS";
export const GET_AMAZON_INTERNALS_ERROR = "GET_AMAZON_INTERNALS_ERROR";

export const SET_CATEGORY_VBS_ID = "SET_CATEGORY_VBS_ID";
export const SET_ACTIVE_TAB_ID = "SET_ACTIVE_TAB_ID";
