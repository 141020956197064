import {
  CLEAR_SUBSCRIPTION_FEATURES,
  FETCH_APPLICATION_SUBSCRIPTIONS,
  FETCH_APPLICATION_SUBSCRIPTIONS_ERROR,
  FETCH_APPLICATION_SUBSCRIPTIONS_SUCCESS,
  FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS,
  FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS_ERROR,
  FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS_SUCCESS,
  SET_APPLICATION_SUBSCRIPTIONS_ACTIVE_STATUS,
  SET_APPLICATION_SUBSCRIPTIONS_PAGE,
  SET_APPLICATION_SUBSCRIPTIONS_SEARCH,
  SET_APPLICATION_SUBSCRIPTIONS_SIZE_PER_PAGE,
  SET_APPLICATION_SUBSCRIPTIONS_SORT_FIELD,
  SET_APPLICATION_SUBSCRIPTIONS_SORT_ORDER,
} from "./actionTypes";

const initialState = {
  loading: false,
  subscriptions: [],
  page: 1,
  sizePerPage: 25,
  totalSize: 0,
  activeStatus: 0,
  search: "",
  sortField: "updated_datetime",
  sortOrder: "DESC",
  totalSubscriptionDetails: [],
  loadingTotalSubscriptionDetails: false,
};

const applicationSubscriptionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS:
      return {
        ...state,
        loadingTotalSubscriptionDetails: true,
      };
    case FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loadingTotalSubscriptionDetails: false,
        totalSubscriptionDetails: payload,
      };
    case FETCH_APPLICATION_TOTAL_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        loadingTotalSubscriptionDetails: false,
      };
    case SET_APPLICATION_SUBSCRIPTIONS_PAGE:
      return {
        ...state,
        page: payload,
      };
    case SET_APPLICATION_SUBSCRIPTIONS_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    case SET_APPLICATION_SUBSCRIPTIONS_ACTIVE_STATUS:
      return {
        ...state,
        activeStatus: payload,
      };
    case SET_APPLICATION_SUBSCRIPTIONS_SEARCH:
      return {
        ...state,
        search: payload,
      };
    case SET_APPLICATION_SUBSCRIPTIONS_SORT_FIELD:
      return {
        ...state,
        sortField: payload,
      };
    case SET_APPLICATION_SUBSCRIPTIONS_SORT_ORDER:
      return {
        ...state,
        sortOrder: payload,
      };
    case FETCH_APPLICATION_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_APPLICATION_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptions: payload.data,
        totalSize: payload.count,
      };
    case FETCH_APPLICATION_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_SUBSCRIPTION_FEATURES:
      return {
        ...state,
        page: 1,
        sizePerPage: 25,
        totalSize: 0,
        activeStatus: 0,
        search: "",
        sortField: "user_subscription_id",
        sortOrder: "DESC",
      };
    default:
      return { ...state };
  }
};

export default applicationSubscriptionReducer;
