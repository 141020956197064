import {
  CLEAR_USER_DETAILS,
  COPY_RESET_PASSWORD_LINK,
  COPY_RESET_PASSWORD_LINK_ERROR,
  COPY_RESET_PASSWORD_LINK_SUCCESS,
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_PROFILE_SUCCESS,
  RESET_PASSWORD_EMAIL,
  RESET_PASSWORD_EMAIL_ERROR,
  RESET_PASSWORD_EMAIL_SUCCESS,
  SET_ACCESS_ACCOUNT_DATA,
  SET_IS_CUSTOMER_ROUTE,
  SET_USER_ID,
  SET_USER_NAME,
  USER_APPLICATION_STUFF,
  USER_APPLICATION_STUFF_ERROR,
  USER_APPLICATION_STUFF_SUCCESS,
  USER_SHARED_ACTIVE_DEACTIVE,
  USER_SHARED_ACTIVE_DEACTIVE_ERROR,
  USER_SHARED_ACTIVE_DEACTIVE_SUCCESS,
} from "./actionTypes";

const initialState = {
  loadingProfile: false,
  userDetails: null,
  loadingResetPasswordButton: false,
  loadingCopyResetPasswordLink: false,
  loadingUserApplicationStuff: false,
  copiedLink: "",
  userApplication: [],
  userId: null,
  accessAccountData: {
    site: "",
    userId: null,
  },
  loadingUserSharedActiveDeactive: false,
  isCustomerRoute: false,
};

const profileReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case SET_IS_CUSTOMER_ROUTE:
      return {
        ...state,
        isCustomerRoute: payload,
      };
    case SET_USER_ID:
      return {
        ...state,
        userId: payload,
      };
    case FETCH_USER_PROFILE:
      return {
        ...state,
        loadingProfile: true,
      };
    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loadingProfile: false,
        userDetails: payload,
      };
    case FETCH_USER_PROFILE_ERROR:
      return {
        ...state,
        loadingProfile: false,
      };

    case RESET_PASSWORD_EMAIL:
      return {
        ...state,
        loadingResetPasswordButton: true,
      };
    case RESET_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        loadingResetPasswordButton: false,
      };
    case RESET_PASSWORD_EMAIL_ERROR:
      return {
        ...state,
        loadingResetPasswordButton: false,
      };
    case COPY_RESET_PASSWORD_LINK:
      return {
        ...state,
        loadingCopyResetPasswordLink: true,
      };
    case COPY_RESET_PASSWORD_LINK_SUCCESS:
      return {
        ...state,
        copiedLink: payload.link,
        loadingCopyResetPasswordLink: false,
      };
    case COPY_RESET_PASSWORD_LINK_ERROR:
      return {
        ...state,
        loadingCopyResetPasswordLink: false,
      };
    case USER_APPLICATION_STUFF:
      return {
        ...state,
        loadingUserApplicationStuff: true,
      };
    case USER_APPLICATION_STUFF_SUCCESS:
      return {
        ...state,
        userApplication: payload.data,
        loadingUserApplicationStuff: false,
      };
    case USER_APPLICATION_STUFF_ERROR:
      return {
        ...state,
        loadingUserApplicationStuff: false,
      };
    case SET_ACCESS_ACCOUNT_DATA:
      return {
        ...state,
        accessAccountData: {
          ...state.accessAccountData,
          site: payload.site,
          userId: payload.userId,
        },
      };
    case CLEAR_USER_DETAILS:
      return {
        ...state,
        userDetails: null,
      };
    case SET_USER_NAME:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          username: payload,
        },
      };

    case USER_SHARED_ACTIVE_DEACTIVE:
      return {
        ...state,
        loadingUserSharedActiveDeactive: true,
        userDetails: {
          ...state.userDetails,
          is_shared_dashboard_unlimited:
            payload.data?.is_shared_dashboard_unlimited,
        },
      };

    case USER_SHARED_ACTIVE_DEACTIVE_SUCCESS:
      return {
        ...state,
        loadingUserSharedActiveDeactive: false,
      };

    case USER_SHARED_ACTIVE_DEACTIVE_ERROR:
      return {
        ...state,
        loadingUserSharedActiveDeactive: false,
      };

    default:
      return state;
  }
};

export default profileReducer;
