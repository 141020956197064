import { axiosApiAdmin } from "helpers/api_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  FETCH_HABIT_CATEGORY,
  FETCH_LINKS,
} from "./actionTypes";

import {
  CREATE_HABIT_CATEGORY,
  DELETE_HABIT_CATEGORY,
  EDIT_HABIT_CATEGORY,
  GET_ICON_LINKS,
  LIST_HABIT_CATEGORY,
} from "helpers/url_helper";
import { setErrorMessage, setSuccessMessage } from "store/actions";
import {
  createCategoryError,
  deleteCategoryError,
  fetchCategoryError,
  fetchCategorySuccess,
  fetchIconsError,
  fetchIconsSuccess,
} from "./actions";

const fetchIcons = data => axiosApiAdmin.get(GET_ICON_LINKS, data);
const addCategoty = data => axiosApiAdmin.post(CREATE_HABIT_CATEGORY, data);
const fetchCategory = data => axiosApiAdmin.post(LIST_HABIT_CATEGORY, data);
const deleteCategory = data => axiosApiAdmin.put(DELETE_HABIT_CATEGORY, data);
const editCategory = data => axiosApiAdmin.put(EDIT_HABIT_CATEGORY, data);

function* handleAddCategory({ payload: { data, cb } }) {
  try {
    const res = yield call(addCategoty, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
    }
    if (!!cb) cb();
  } catch (err) {
    yield put(createCategoryError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleGetLinks({ payload: { data, cb } }) {
  try {
    const res = yield call(fetchIcons, data);
    if (res.data) {
      yield put(fetchIconsSuccess(res.data.data.listArray));
    }
    if (!!cb) cb();
  } catch (err) {
    yield put(fetchIconsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchCategory({ payload: { data } }) {
  try {
    const res = yield call(fetchCategory, data);
    if (res.data) {
      yield put(fetchCategorySuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchCategoryError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteCategory({ payload: { data, cb } }) {
  try {
    const res = yield call(deleteCategory, data);
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
    }
    if (!!cb) cb();
  } catch (err) {
    yield put(deleteCategoryError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleEditCategory({ payload: { data, cb } }) {
  try {
    const res = yield call(editCategory, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
    }
    if (!!cb) cb();
  } catch (err) {
    yield put(createCategoryError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* categorySaga() {
  yield takeEvery(ADD_CATEGORY, handleAddCategory);
  yield takeEvery(FETCH_HABIT_CATEGORY, handleFetchCategory);
  yield takeEvery(FETCH_LINKS, handleGetLinks);
  yield takeEvery(DELETE_CATEGORY, handleDeleteCategory);
  yield takeEvery(EDIT_CATEGORY, handleEditCategory);
}

export default categorySaga;
