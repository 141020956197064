// Va 05 - 01 - 21
import { axiosApiAdmin } from "helpers/api_helper";
import { LIST_EXPIRED_SUBSCRIPTION } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchlistExpiredSubscriptionError,
  fetchlistExpiredSubscriptionSuccess,
  fetchlistSubscriptionReportForExportSuccess,
  setErrorMessage,
} from "store/actions";
import {
  FATCH_LIST_EXPIRED_SUBSCRIPTION,
  FATCH_LIST_EXPIRED_SUBSCRIPTION_EXPORT,
} from "./actionTypes";

const fetchExpiredSubscription = data =>
  axiosApiAdmin.post(LIST_EXPIRED_SUBSCRIPTION, data);

function* handleFetchExpiredSubscription({ payload, cb }) {
  try {
    const res = yield call(fetchExpiredSubscription, payload);
    if (res) {
      cb(res);
      yield put(fetchlistExpiredSubscriptionSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchlistExpiredSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchExpiredSubscriptionExport({ payload, cb }) {
  try {
    const res = yield call(fetchExpiredSubscription, payload);
    if (res) {
      cb(res.data.data.rows);
      yield put(
        fetchlistSubscriptionReportForExportSuccess(res.data.data.rows)
      );
    }
  } catch (err) {
    yield put(fetchlistExpiredSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

export function* ExpiredSubscriptionSaga() {
  yield takeEvery(
    FATCH_LIST_EXPIRED_SUBSCRIPTION,
    handleFetchExpiredSubscription
  );
}

export function* ExpiredSubscriptionExportSaga() {
  yield takeEvery(
    FATCH_LIST_EXPIRED_SUBSCRIPTION_EXPORT,
    handleFetchExpiredSubscriptionExport
  );
}
