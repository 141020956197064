import {
  CIRCLE_SPACES_LIST,
  CIRCLE_SPACES_LIST_ERROR,
  CIRCLE_SPACES_LIST_SUCCESS,
  CLEAR_ACTIVITY_STATUS,
  CLEAR_LOG_FEATURES,
  FETCH_ACTIVE_INACTIVE,
  FETCH_ACTIVE_INACTIVE_SUCCESS,
  FETCH_THIRDPARTY_CONFIG_DETAILS,
  FETCH_THIRDPARTY_CONFIG_DETAILS_SUCCESS,
  FETCH_THIRDPARTY_LIST,
  FETCH_THIRDPARTY_LIST_ERROR,
  FETCH_THIRDPARTY_LIST_SUCCESS,
  GET_SHIPBOB_CHANNEL,
  GET_SHIPBOB_CHANNEL_ERROR,
  GET_SHIPBOB_CHANNEL_SUCCESS,
  GET_SHIPBOB_PRODUCTS,
  GET_SHIPBOB_PRODUCTS_ERROR,
  GET_SHIPBOB_PRODUCTS_SUCCESS,
  GET_SHIPMENT_METHOD,
  GET_SHIPMENT_METHOD_ERROR,
  GET_SHIPMENT_METHOD_SUCCESS,
  GET_SYCU_MAP_PRODUCTS,
  GET_SYCU_MAP_PRODUCTS_ERROR,
  GET_SYCU_MAP_PRODUCTS_SUCCESS,
  GET_SYCU_PRODUCTS,
  GET_SYCU_PRODUCTS_ERROR,
  GET_SYCU_PRODUCTS_SUCCESS,
  GET_THIRDPARTY_LOGS,
  GET_THIRDPARTY_LOGS_ERROR,
  GET_THIRDPARTY_LOGS_SUCCESS,
  SAVE_SYCU_PRODUCTS,
  SAVE_SYCU_PRODUCTS_ERROR,
  SAVE_SYCU_PRODUCTS_SUCCESS,
  SAVE_THIRDPARTY_CONFIG_DETAILS,
  SAVE_THIRDPARTY_CONFIG_DETAILS_ERROR,
  SAVE_THIRDPARTY_CONFIG_DETAILS_SUCCESS,
  SET_ACTIVE_TABS,
  SET_ACTIVITY_TYPE,
  SET_LOGS_PAGES,
  SET_LOGS_SEARCH,
  SET_LOGS_SIZE_PER_PAGE,
  SET_STATUS_TYPE,
  UPDATE_SHIPBOB_CHANNEL,
  UPDATE_SHIPBOB_CHANNEL_ERROR,
  UPDATE_SHIPBOB_CHANNEL_SUCCESS,
  UPDATE_SHIPMENT,
  UPDATE_SHIPMENT_ERROR,
  UPDATE_SHIPMENT_SUCCESS,
} from "./actionsTypes";

const initialStates = {
  loadingList: false,
  thirdPartyList: [],
  activeTabs: 0,
  thirdPartyConfig: null,
  loadingSave: false,
  loadingCircle: false,
  circleSpaceList: [],
  loadingLogs: false,
  thirdPartyLogs: [],
  logPage: 1,
  logSearch: "",
  logSizePerPage: 25,
  logTotalSize: 0,
  activityType: 0,
  statusType: "",
  loadingShipmentMethod: false,
  shipmentMethod: [],
  loadingSycuProducts: false,
  sycuProducts: [],
  loadingShipbobProducts: false,
  shipBobProducts: [],
  loadingSaveSycyProducts: false,
  loadingSycuMapProducts: false,
  listSycuMapProducts: [],
  loadingUpdateShipment: false,
  loadingShipbobChannel: false,
  shipbobChannel: [],
  loadingUpdateShipbobChannel: false,
};

const IntegrationsReducer = (state = initialStates, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_THIRDPARTY_LIST:
      return {
        ...state,
        loadingList: true,
      };
    case FETCH_THIRDPARTY_LIST_SUCCESS:
      return {
        ...state,
        thirdPartyList: payload,
        activeTabs: payload[0].thirdparty_id,
        loadingList: false,
      };
    case FETCH_THIRDPARTY_LIST_ERROR:
      return {
        ...state,
        loadingList: false,
      };

    case SET_ACTIVE_TABS:
      return {
        ...state,
        activeTabs: payload,
      };

    case FETCH_ACTIVE_INACTIVE:
      return {
        ...state,
        loadingList: true,
        thirdPartyList: state.thirdPartyList.map(list => {
          if (list.thirdparty_id === payload.thirdparty_id) {
            list.is_active = payload.is_active;
          }
          return list;
        }),
      };
    case FETCH_ACTIVE_INACTIVE_SUCCESS:
      return {
        ...state,
        loadingList: false,
      };
    case FETCH_THIRDPARTY_CONFIG_DETAILS:
      return {
        ...state,
        loadingList: true,
      };

    case FETCH_THIRDPARTY_CONFIG_DETAILS_SUCCESS:
      return {
        ...state,
        loadingList: false,
        thirdPartyConfig: payload,
      };
    case SAVE_THIRDPARTY_CONFIG_DETAILS:
      return {
        ...state,
        loadingSave: true,
      };
    case SAVE_THIRDPARTY_CONFIG_DETAILS_SUCCESS:
      return {
        ...state,
        loadingSave: false,
      };
    case SAVE_THIRDPARTY_CONFIG_DETAILS_ERROR:
      return {
        ...state,
        loadingList: false,
      };

    case CIRCLE_SPACES_LIST:
      return {
        ...state,
        loadingCircle: true,
      };

    case CIRCLE_SPACES_LIST_SUCCESS:
      return {
        ...state,
        loadingCircle: false,
        circleSpaceList: payload,
      };

    case CIRCLE_SPACES_LIST_ERROR:
      return {
        ...state,
        loadingCircle: false,
      };

    case GET_THIRDPARTY_LOGS:
      return {
        ...state,
        loadingLogs: true,
      };

    case GET_THIRDPARTY_LOGS_SUCCESS:
      return {
        ...state,
        loadingLogs: false,
        thirdPartyLogs: payload.rows,
        logTotalSize: payload.count,
      };

    case GET_THIRDPARTY_LOGS_ERROR:
      return {
        ...state,
        loadingLogs: false,
      };

    case SET_LOGS_PAGES:
      return {
        ...state,
        logPage: payload,
      };
    case SET_LOGS_SEARCH:
      return {
        ...state,
        logSearch: payload,
      };
    case SET_LOGS_SIZE_PER_PAGE:
      return {
        ...state,
        logSizePerPage: payload,
      };
    case SET_ACTIVITY_TYPE:
      return {
        ...state,
        activityType: payload,
      };

    case SET_STATUS_TYPE:
      return {
        ...state,
        statusType: payload,
      };
    case CLEAR_ACTIVITY_STATUS:
      return {
        ...state,
        activityType: 0,
        statusType: "",
      };
    case CLEAR_LOG_FEATURES:
      return {
        ...state,
        logPage: 1,
        logSizePerPage: 25,
        logTotalSize: 0,
        activityType: 0,
        statusType: "",
      };

    case GET_SHIPMENT_METHOD:
      return {
        ...state,
        loadingShipmentMethod: true,
      };

    case GET_SHIPMENT_METHOD_SUCCESS:
      return {
        ...state,
        shipmentMethod: payload.rows,
        loadingShipmentMethod: false,
      };

    case GET_SHIPMENT_METHOD_ERROR:
      return {
        ...state,
        loadingShipmentMethod: false,
      };

    case GET_SYCU_PRODUCTS:
      return {
        ...state,
        loadingSycuProducts: true,
      };

    case GET_SYCU_PRODUCTS_SUCCESS:
      return {
        ...state,
        sycuProducts: payload.rows,
        loadingSycuProducts: false,
      };

    case GET_SYCU_PRODUCTS_ERROR:
      return {
        ...state,
        loadingSycuProducts: false,
      };

    case GET_SHIPBOB_PRODUCTS:
      return {
        ...state,
        loadingShipbobProducts: true,
      };

    case GET_SHIPBOB_PRODUCTS_SUCCESS:
      return {
        ...state,
        shipBobProducts: payload.rows,
        loadingShipbobProducts: false,
      };

    case GET_SHIPBOB_PRODUCTS_ERROR:
      return {
        ...state,
        loadingShipbobProducts: false,
      };

    case SAVE_SYCU_PRODUCTS:
      return {
        ...state,
        loadingSaveSycyProducts: true,
      };

    case SAVE_SYCU_PRODUCTS_SUCCESS:
      return {
        ...state,
        loadingSaveSycyProducts: false,
      };

    case SAVE_SYCU_PRODUCTS_ERROR:
      return {
        ...state,
        loadingSaveSycyProducts: false,
      };

    case GET_SYCU_MAP_PRODUCTS:
      return {
        ...state,
        loadingSycuMapProducts: true,
      };

    case GET_SYCU_MAP_PRODUCTS_SUCCESS:
      return {
        ...state,
        listSycuMapProducts: payload.rows,
        loadingSycuMapProducts: false,
      };

    case GET_SYCU_MAP_PRODUCTS_ERROR:
      return {
        ...state,
        loadingSycuMapProducts: false,
      };

    case UPDATE_SHIPMENT:
      return {
        ...state,
        loadingUpdateShipment: true,
      };

    case UPDATE_SHIPMENT_SUCCESS:
      return {
        ...state,
        loadingUpdateShipment: false,
      };

    case UPDATE_SHIPMENT_ERROR:
      return {
        ...state,
        loadingUpdateShipment: false,
      };

    case GET_SHIPBOB_CHANNEL:
      return {
        ...state,
        loadingShipbobChannel: true,
      };

    case GET_SHIPBOB_CHANNEL_SUCCESS:
      return {
        ...state,
        shipbobChannel: payload.rows,
        loadingShipbobChannel: false,
      };

    case GET_SHIPBOB_CHANNEL_ERROR:
      return {
        ...state,
        loadingShipbobChannel: false,
      };

    case UPDATE_SHIPBOB_CHANNEL:
      return {
        ...state,
        loadingUpdateShipbobChannel: true,
      };

    case UPDATE_SHIPBOB_CHANNEL_SUCCESS:
      return {
        ...state,
        loadingUpdateShipbobChannel: false,
      };

    case UPDATE_SHIPBOB_CHANNEL_ERROR:
      return {
        ...state,
        loadingUpdateShipbobChannel: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default IntegrationsReducer;
