import { axiosApiAdmin } from "helpers/api_helper";
import {
  CHART_CANCELLED_SUBSCRIPTION_CHART,
  CHART_NEW_REGISTER_USER,
  CHART_NEW_SUBSCRIPTION,
  CHART_RENEWED_SUBSCRIPTION,
  DASHBOARD_CARD_DATA,
  DASHBOARD_TOP_VISITOR_DATA,
  FETCH_EARNINGS_CHART,
  FETCH_ORDER_BY_PRODUCT_CHART,
  FETCH_SALES_ANALYTICS_CHART,
  FETCH_TOP_SELLING_PRODUCTS_CHART,
  LIST_ALL_SYSTEM_CONFIGURATION_URL,
} from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  FETCH_CANCELLED_SUBSCRIPTION_CHART,
  FETCH_DASHBOARD_CARD_DATA,
  FETCH_EARNINGS_CHART_DATA,
  FETCH_NEW_REGISTERED_USER_CHART,
  FETCH_NEW_SUBSCRIPTION_CHART,
  FETCH_ORDER_BY_PRODUCT_CHART_DATA,
  FETCH_RENEWED_SUBSCRIPTION_CHART,
  FETCH_SALES_ANALYTICS_CHART_DATA,
  FETCH_TOP_SELLING_PRODUCTS_CHART_DATA,
  FETCH_TOP_VISITORS_CHART_DATA,
  LIST_ALL_SYSTEM_CONFIGURATION,
} from "./actionTypes";

import {
  componentLoaderDashboard,
  fetchCancelledSubscriptionError,
  fetchCancelledSubscriptionSuccess,
  fetchDashboardCardDataError,
  fetchDashboardCardDataSuccess,
  fetchEarningsChartDataError,
  fetchEarningsChartDataSuccess,
  fetchNewRegisteredUserError,
  fetchNewRegisteredUserSuccess,
  fetchNewSubscriptionError,
  fetchNewSubscriptionSuccess,
  fetchOrderByProductChartDataError,
  fetchOrderByProductChartDataSuccess,
  fetchRenewedSubscriptionError,
  fetchRenewedSubscriptionSuccess,
  fetchSalesAnalyticsChartDataError,
  fetchSalesAnalyticsChartDataSuccess,
  fetchTopSellingProductsChartDataError,
  fetchTopSellingProductsChartDataSuccess,
  fetchTopVisitorsChartDataError,
  fetchTopVisitorsChartDataSuccess,
  listAllSystemConfigurationError,
  listAllSystemConfigurationSuccess,
  setErrorMessage,
} from "store/actions";

const fetchNewRegisteredUserChart = data =>
  axiosApiAdmin.post(CHART_NEW_REGISTER_USER, data);
const fetchCancelledSubscriptionChart = data =>
  axiosApiAdmin.post(CHART_CANCELLED_SUBSCRIPTION_CHART, data);
const fetchNewSubscriptionChart = data =>
  axiosApiAdmin.post(CHART_NEW_SUBSCRIPTION, data);
const fetchRenewedSubscriptionChart = data =>
  axiosApiAdmin.post(CHART_RENEWED_SUBSCRIPTION, data);
const fetchDashboardCardData = data => axiosApiAdmin.get(DASHBOARD_CARD_DATA);
const fetchEarningsChartData = data =>
  axiosApiAdmin.get(FETCH_EARNINGS_CHART, data);
const fetchTopSellingProductsChartData = data =>
  axiosApiAdmin.post(FETCH_TOP_SELLING_PRODUCTS_CHART, data);
const fetchTopVisitorsChartData = data =>
  axiosApiAdmin.get(DASHBOARD_TOP_VISITOR_DATA, data);
const fetchSalesAnalyticsChartData = data =>
  axiosApiAdmin.get(FETCH_SALES_ANALYTICS_CHART, data);
const fetchOrderByProductChartData = data =>
  axiosApiAdmin.get(FETCH_ORDER_BY_PRODUCT_CHART, data);
const listAllSystemConfigurationCall = id =>
  axiosApiAdmin.get(`${LIST_ALL_SYSTEM_CONFIGURATION_URL}` + "/" + `${id}`);

function* handleFetchNewRegisteredUserChart({ payload }) {
  try {
    yield put(componentLoaderDashboard("newUserRegisteredChart"));
    const res = yield call(fetchNewRegisteredUserChart, payload);
    if (res.data?.data) {
      yield put(fetchNewRegisteredUserSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchNewRegisteredUserError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchCancelledSubscription({ payload }) {
  try {
    yield put(componentLoaderDashboard("cancelledSubscription"));
    const res = yield call(fetchCancelledSubscriptionChart, payload);
    if (res.data?.data) {
      yield put(fetchCancelledSubscriptionSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchCancelledSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchDashboardCardData({ payload }) {
  try {
    yield put(componentLoaderDashboard("dashboardCardData"));
    const res = yield call(fetchDashboardCardData, payload);
    if (res.data?.data) {
      yield put(fetchDashboardCardDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchDashboardCardDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchEarningData({ payload }) {
  try {
    yield put(componentLoaderDashboard("earningsChartData"));
    const res = yield call(fetchEarningsChartData, payload);
    if (res.data?.data) {
      yield put(fetchEarningsChartDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchEarningsChartDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchTopSellingProductsChartData({ payload }) {
  try {
    yield put(componentLoaderDashboard("topSellingProductsChartData"));
    const res = yield call(fetchTopSellingProductsChartData, payload);
    if (res.data?.data) {
      yield put(fetchTopSellingProductsChartDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchTopSellingProductsChartDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchTopVisitorsChartData({ payload }) {
  try {
    yield put(componentLoaderDashboard("topVisitorsChartData"));
    const res = yield call(fetchTopVisitorsChartData, payload);
    if (res.data?.data) {
      yield put(fetchTopVisitorsChartDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchTopVisitorsChartDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchNewSubscription({ payload }) {
  try {
    yield put(componentLoaderDashboard("newSubscription"));
    const res = yield call(fetchNewSubscriptionChart, payload);
    if (res.data?.data) {
      yield put(fetchNewSubscriptionSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchNewSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchRenewedSubscription({ payload }) {
  try {
    yield put(componentLoaderDashboard("renewSubscription"));
    const res = yield call(fetchRenewedSubscriptionChart, payload);
    if (res.data?.data) {
      yield put(fetchRenewedSubscriptionSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchRenewedSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchSalesAnalyticsChartData({ payload }) {
  try {
    yield put(componentLoaderDashboard("salesAnalyticsChartData"));
    const res = yield call(fetchSalesAnalyticsChartData, payload);
    if (res.data?.data) {
      yield put(fetchSalesAnalyticsChartDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchSalesAnalyticsChartDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchOrderByProductChartData({ payload }) {
  try {
    yield put(componentLoaderDashboard("orderByProductChartData"));
    const res = yield call(fetchOrderByProductChartData, payload);
    if (res.data?.data) {
      yield put(fetchOrderByProductChartDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchOrderByProductChartDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleListAllSystemConfiguration({ id }) {
  try {
    const res = yield call(listAllSystemConfigurationCall, id);
    if (res?.data?.statusCode && res.data?.data?.system_configuration) {
      yield put(
        listAllSystemConfigurationSuccess(res.data.data.system_configuration)
      );
    }
  } catch (err) {
    yield put(listAllSystemConfigurationError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* dashboardSaga() {
  yield takeEvery(
    FETCH_NEW_REGISTERED_USER_CHART,
    handleFetchNewRegisteredUserChart
  );
  yield takeEvery(
    FETCH_CANCELLED_SUBSCRIPTION_CHART,
    handleFetchCancelledSubscription
  );
  yield takeEvery(FETCH_DASHBOARD_CARD_DATA, handleFetchDashboardCardData);
  yield takeEvery(FETCH_EARNINGS_CHART_DATA, handleFetchEarningData);
  yield takeEvery(
    FETCH_TOP_SELLING_PRODUCTS_CHART_DATA,
    handleFetchTopSellingProductsChartData
  );
  yield takeEvery(
    FETCH_TOP_VISITORS_CHART_DATA,
    handleFetchTopVisitorsChartData
  );
  yield takeEvery(FETCH_NEW_SUBSCRIPTION_CHART, handleFetchNewSubscription);
  yield takeEvery(
    FETCH_RENEWED_SUBSCRIPTION_CHART,
    handleFetchRenewedSubscription
  );
  yield takeEvery(
    FETCH_SALES_ANALYTICS_CHART_DATA,
    handleFetchSalesAnalyticsChartData
  );
  yield takeEvery(
    FETCH_ORDER_BY_PRODUCT_CHART_DATA,
    handleFetchOrderByProductChartData
  );
  yield takeEvery(
    LIST_ALL_SYSTEM_CONFIGURATION,
    handleListAllSystemConfiguration
  );
}

export default dashboardSaga;
