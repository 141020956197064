import {
  FETCH_CAMPAIGN_REPORTS,
  FETCH_CAMPAIGN_REPORTS_ERROR,
  FETCH_CAMPAIGN_REPORTS_SUCCESS,
} from "./actionTypes";

const initialState = {
  CampaignData: { data: [], count: 0 },
  CampaignLoading: false,
};

const DisputedTransactions = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CAMPAIGN_REPORTS:
      return {
        ...state,
        CampaignLoading: true,
      };
    case FETCH_CAMPAIGN_REPORTS_SUCCESS:
      return {
        ...state,
        CampaignData: payload,
        CampaignLoading: false,
      };
    case FETCH_CAMPAIGN_REPORTS_ERROR:
      return {
        ...state,
        CampaignLoading: false,
      };
    default:
      return state;
  }
};

export default DisputedTransactions;
