import {
  ANALYTICS_SUBSCRIPTION_SET_TZ,
  CLEAR_SEARCH_QUERY_REPORT,
  CLEAR_SUBSCRIPTION_REPORT,
  FETCH_NEW_SUBSCRIPTION_CHART_DATA,
  FETCH_NEW_SUBSCRIPTION_CHART_DATA_ERROR,
  FETCH_NEW_SUBSCRIPTION_CHART_DATA_SUCCESS,
  FETCH_RENEWED_SUBSCRIPTION_CHART_DATA,
  FETCH_RENEWED_SUBSCRIPTION_CHART_DATA_ERROR,
  FETCH_RENEWED_SUBSCRIPTION_CHART_DATA_SUCCESS,
  FETCH_SUBSCRIPTION_REPORT_DATA,
  FETCH_SUBSCRIPTION_REPORT_DATA_ERROR,
  FETCH_SUBSCRIPTION_REPORT_DATA_SUCCESS,
  FETCH_SUBSCRIPTION_REPORT_DATE_WISE,
  FETCH_SUBSCRIPTION_REPORT_DATE_WISE_ERROR,
  FETCH_SUBSCRIPTION_REPORT_DATE_WISE_SUCCESS,
  SET_ANALYTICS_NEW_SUBSCRIPTION_BY,
  SET_ANALYTICS_NEW_SUBSCRIPTION_RANGE_DATE,
  SET_ANALYTICS_RENEW_SUBSCRIPTION_BY,
  SET_ANALYTICS_RENEW_SUBSCRIPTION_RANGE_DATE,
  SET_ANALYTICS_SUBSCRIPTION_COMPARE_TYPE,
  SET_ANALYTICS_SUBSCRIPTION_CURRENT_SITE,
  SET_ANALYTICS_SUBSCRIPTION_PAGE,
  SET_ANALYTICS_SUBSCRIPTION_SIZE_PER_PAGE,
  SET_ANALYTICS_SUBSCRIPTION_SORT_FIELD,
  SET_ANALYTICS_SUBSCRIPTION_SORT_ORDER,
} from "./actionTypes";

export const setSubscriptionTZ = data => ({
  type: ANALYTICS_SUBSCRIPTION_SET_TZ,
  payload: data,
});

export const setAnalyticsSubscriptionSiteId = data => ({
  type: SET_ANALYTICS_SUBSCRIPTION_CURRENT_SITE,
  payload: data,
});

export const clearSubscriptionReport = () => ({
  type: CLEAR_SUBSCRIPTION_REPORT,
});

export const clearSearchQueryReport = () => ({
  type: CLEAR_SEARCH_QUERY_REPORT,
});

export const setAnalyticsSubscriptionPage = data => ({
  type: SET_ANALYTICS_SUBSCRIPTION_PAGE,
  payload: data,
});
export const setAnalyticsSubscriptionSizePerPage = data => ({
  type: SET_ANALYTICS_SUBSCRIPTION_SIZE_PER_PAGE,
  payload: data,
});
export const setAnalyticsSubscriptionSortField = data => ({
  type: SET_ANALYTICS_SUBSCRIPTION_SORT_FIELD,
  payload: data,
});
export const setAnalyticsSubscriptionSortOrder = data => ({
  type: SET_ANALYTICS_SUBSCRIPTION_SORT_ORDER,
  payload: data,
});

export const getRenewedSubscriptionChartData = data => ({
  type: FETCH_RENEWED_SUBSCRIPTION_CHART_DATA,
  payload: data,
});

export const getRenewedSubscriptionChartDataSuccess = data => ({
  type: FETCH_RENEWED_SUBSCRIPTION_CHART_DATA_SUCCESS,
  payload: data,
});

export const getRenewedSubscriptionChartDataError = () => ({
  type: FETCH_RENEWED_SUBSCRIPTION_CHART_DATA_ERROR,
});

export const getNewSubscriptionChartData = data => ({
  type: FETCH_NEW_SUBSCRIPTION_CHART_DATA,
  payload: data,
});

export const getNewSubscriptionChartDataSuccess = data => ({
  type: FETCH_NEW_SUBSCRIPTION_CHART_DATA_SUCCESS,
  payload: data,
});

export const getNewSubscriptionChartDataError = () => ({
  type: FETCH_NEW_SUBSCRIPTION_CHART_DATA_ERROR,
});

export const getSubscriptionReportDateWise = data => ({
  type: FETCH_SUBSCRIPTION_REPORT_DATE_WISE,
  // payload: data,
});

export const getSubscriptionReportDateWiseSuccess = data => ({
  type: FETCH_SUBSCRIPTION_REPORT_DATE_WISE_SUCCESS,
  payload: data,
});

export const getSubscriptionReportDateWiseError = () => ({
  type: FETCH_SUBSCRIPTION_REPORT_DATE_WISE_ERROR,
});

export const setAnalyticNewSubscriptionRangeDate = data => ({
  type: SET_ANALYTICS_NEW_SUBSCRIPTION_RANGE_DATE,
  payload: data,
});
export const setAnalyticRenewSubscriptionRangeDate = data => ({
  type: SET_ANALYTICS_RENEW_SUBSCRIPTION_RANGE_DATE,
  payload: data,
});
export const setAnalyticSubscriptionCompareType = data => ({
  type: SET_ANALYTICS_SUBSCRIPTION_COMPARE_TYPE,
  payload: data,
});

export const setAnalyticsNewSubscriptionBy = data => ({
  type: SET_ANALYTICS_NEW_SUBSCRIPTION_BY,
  payload: data,
});

export const setAnalyticsRenewSubscriptionBy = data => ({
  type: SET_ANALYTICS_RENEW_SUBSCRIPTION_BY,
  payload: data,
});

export const getSubscriptionsCount = data => ({
  type: FETCH_SUBSCRIPTION_REPORT_DATA,
  payload: data,
});

export const getSubscriptionCountSuccess = data => ({
  type: FETCH_SUBSCRIPTION_REPORT_DATA_SUCCESS,
  payload: data,
});

export const getSubscriptionCountError = () => ({
  type: FETCH_SUBSCRIPTION_REPORT_DATA_ERROR,
});
