import {
  ADD_EDIT_HELPFUL_SWITCH,
  ADD_EDIT_STEWARD_SWITCH,
  GROW_ADD_BUTTON_STORY,
  GROW_ADD_BUTTON_TUTORIALS,
  GROW_ADD_EDIT_HELPFUL_LOADER,
  GROW_ADD_EDIT_STEWARDS_LOADER,
  GROW_ADD_EDIT_STORY_LOADER,
  GROW_ADD_EDIT_TODO_LOADER,
  GROW_APPLICATION_ADS_SAVE_COLOR,
  GROW_COLOR_LISTING,
  GROW_EDIT_HELPFUL,
  GROW_EDIT_STEWARD,
  GROW_EDIT_STORIES,
  GROW_EDIT_TODO,
  GROW_EDIT_TUTORIALS,
  GROW_FETCH_HELPFUL_LOADER,
  GROW_FETCH_STEWARD_LOADER,
  GROW_FETCH_STORY_LOADER,
  GROW_FETCH_TODO_LOADER,
  GROW_HELPFUL_LISTING,
  GROW_SAVE_STEWARD,
  GROW_STEWARD_LISTING,
  GROW_STORIES_LISTING,
  GROW_TODO_LISTING,
  GROW_TO_DO_LIST_TAB_CHANGE,
  GROW_TUTORIALS_LISTING,
  GROW_TUTORIALS_TAB_CHANGE,
  STORE_SERIES_CURRICULUM,
  STORE_SERIES_KIDS,
  TAB_CHANGE_GROW_FREE_TRIAL,
} from "./actionType";

const initialState = {
  tabs: 1,
  stories: {
    isAddEdit: false,
    storiesList: [],
    storiesEditField: {},
    listingLoader: false,
    addEditLoader: false,
  },
  toDos: {
    isAddEdit: false,
    toDoListData: [],
    toDosEditField: {},
    listingLoader: false,
    addEditLoader: false,
  },
  stewards: {
    isAddEdit: false,
    stewardList: [],
    colorList: [],
    stewardEditField: {},
    listingLoader: false,
    addEditLoader: false,
  },
  resources: {
    isAddEdit: false,
    helpfulResourcesList: [],
    helpfulEditField: {},
    listingLoader: false,
    addEditLoader: false,
  },
  tutorials: {
    isAddEdit: false,
    tutorialsList: [],
    tutorialsEditField: {},
  },
  curriculum_series_data: [],
  kids_series_data: [],
};

const FreeTrialReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TAB_CHANGE_GROW_FREE_TRIAL:
      return {
        ...state,
        tabs: payload,
      };
    case GROW_COLOR_LISTING:
      return {
        ...state,
        stewards: {
          ...state.stewards,
          colorList: payload,
        },
      };
    case GROW_STORIES_LISTING:
      return {
        ...state,
        stories: {
          ...state.stories,
          storiesList: payload,
        },
      };

    case GROW_TUTORIALS_LISTING:
      return {
        ...state,
        tutorials: {
          ...state.tutorials,
          tutorialsList: payload,
        },
      };
    case GROW_FETCH_STORY_LOADER:
      return {
        ...state,
        stories: {
          ...state.stories,
          listingLoader: payload,
        },
      };
    case GROW_FETCH_STEWARD_LOADER:
      return {
        ...state,
        stewards: {
          ...state.stewards,
          listingLoader: payload,
        },
      };
    case GROW_FETCH_HELPFUL_LOADER:
      return {
        ...state,
        resources: {
          ...state.resources,
          listingLoader: payload,
        },
      };
    case GROW_FETCH_TODO_LOADER:
      return {
        ...state,
        toDos: {
          ...state.toDos,
          listingLoader: payload,
        },
      };

    case GROW_HELPFUL_LISTING:
      return {
        ...state,
        resources: {
          ...state.resources,
          helpfulResourcesList: payload,
        },
      };
    case GROW_TODO_LISTING:
      return {
        ...state,
        toDos: {
          ...state.toDos,
          toDoListData: payload,
        },
      };

    case GROW_STEWARD_LISTING:
      return {
        ...state,
        stewards: {
          ...state.stewards,
          stewardList: payload,
        },
      };
    case GROW_SAVE_STEWARD:
      return {
        ...state,
      };
    case GROW_APPLICATION_ADS_SAVE_COLOR:
      return {
        ...state,
      };
    case ADD_EDIT_STEWARD_SWITCH:
      return {
        ...state,
        stewards: {
          ...state.stewards,
          isAddEdit: payload,
        },
      };
    case ADD_EDIT_HELPFUL_SWITCH:
      return {
        ...state,
        resources: {
          ...state.resources,
          isAddEdit: payload,
        },
      };
    case GROW_ADD_BUTTON_STORY:
      return {
        ...state,
        stories: {
          ...state.stories,
          isAddEdit: payload,
        },
      };
    case GROW_ADD_BUTTON_TUTORIALS:
      return {
        ...state,
        tutorials: {
          ...state.tutorials,
          isAddEdit: payload,
        },
      };

    case GROW_EDIT_STORIES:
      return {
        ...state,
        stories: {
          ...state.stories,
          isAddEdit: true,
          storiesEditField: payload,
        },
      };

    case GROW_EDIT_TODO:
      return {
        ...state,
        toDos: {
          ...state.toDos,
          isAddEdit: true,
          toDosEditField: payload,
        },
      };
    case GROW_EDIT_TUTORIALS:
      return {
        ...state,
        tutorials: {
          ...state.tutorials,
          isAddEdit: true,
          tutorialsEditField: payload,
        },
      };

    case GROW_EDIT_STEWARD:
      return {
        ...state,
        stewards: {
          ...state.stewards,
          isAddEdit: true,
          stewardEditField: payload,
        },
      };
    case GROW_EDIT_HELPFUL:
      return {
        ...state,
        resources: {
          ...state.resources,
          isAddEdit: true,
          helpfulEditField: payload,
        },
      };
    case GROW_TO_DO_LIST_TAB_CHANGE:
      return {
        ...state,
        toDos: {
          ...state.toDos,
          isAddEdit: payload,
        },
      };
    case GROW_TUTORIALS_TAB_CHANGE:
      return {
        ...state,
        tutorials: {
          ...state.tutorials,
          isAddEdit: payload,
        },
      };
    case STORE_SERIES_CURRICULUM:
      return {
        ...state,
        curriculum_series_data: payload,
      };
    case STORE_SERIES_KIDS: {
      return {
        ...state,
        kids_series_data: payload,
      };
    }
    case GROW_ADD_EDIT_HELPFUL_LOADER:
      return {
        ...state,
        resources: {
          ...state.resources,
          addEditLoader: payload,
        },
      };
    case GROW_ADD_EDIT_STORY_LOADER:
      return {
        ...state,
        stories: {
          ...state.stories,
          addEditLoader: payload,
        },
      };
    case GROW_ADD_EDIT_STEWARDS_LOADER:
      return {
        ...state,
        stewards: {
          ...state.stewards,
          addEditLoader: payload,
        },
      };
    case GROW_ADD_EDIT_TODO_LOADER:
      return {
        ...state,
        toDos: {
          ...state.toDos,
          addEditLoader: payload,
        },
      };
    default:
      return state;
  }
};

export default FreeTrialReducer;
