const {
  GROW_BOOK_CATEGORY_LISTING,
  GROW_FETCH_AUTHOR_LOADER,
  GROW_EDIT_AUTHOR,
  GROW_ADD_EDIT_AUTHOR_LOADER,
  GROW_AUTHOR_TAB_CHANGE,
  GROW_ADD_BUTTON_BOOK_CATEGORY,
  GROW_EDIT_BOOK_CATEGORY,
  HANDLE_OPEN_ADD_EDIT,
  LIST_ALL_BOOKS_LISTING,
  GROW_ADD_BUTTON_BOOK,
  FETCH_GROW_AUTHOR_NAME,
  GROW_AUTHOR_NAME_LIST_SUCCESS,
  GROW_BOOK_CATEGORY_LIST_SUCCESS,
  ADD_EDIT_PAGE_HANDLE,
  GROW_ADD_EDIT_BOOK_CATEGORY_LOADER,
  GROW_LIST_BOOK_CATEGORY_LOADER,
  GROW_EDIT_BOOK_DETAILS,
} = require("./actionType");
const { GROW_AUTHOR_LISTING } = require("./actionType");

const initialState = {
  tabs: 1,
  bookCategory: {
    isAddEdit: false,
    bookCategoryList: [],
    bookCategoryEditField: {},
    //   listingLoader: false,
    addEditLoader: false,
  },
  author: {
    isAddEdit: false,
    authorList: [],
    authorEditField: {},
    listingLoader: false,
    addEditLoader: false,
  },
  books: {
    isAddEdit: false,
    allBooksList: [],
    addAuthorNameList: [],
    listBookCategoryNames: [],
    booksEditfield: {},
  },
};

const GrowBooksReducer = (state = initialState, action) => {
  const { type, payload } = action;
  // eslint-disable-next-line default-case
  switch (type) {
    case GROW_BOOK_CATEGORY_LISTING:
      return {
        ...state,
        bookCategory: {
          ...state.bookCategory,
          bookCategoryList: payload,
        },
      };
    case GROW_ADD_BUTTON_BOOK_CATEGORY:
      return {
        ...state,
        bookCategory: {
          ...state.bookCategory,
          isAddEdit: payload,
        },
      };
    case GROW_EDIT_BOOK_CATEGORY:
      return {
        ...state,
        bookCategory: {
          ...state.bookCategory,
          isAddEdit: true,
          bookCategoryEditField: payload,
        },
      };
    case GROW_EDIT_BOOK_DETAILS:
      return {
        ...state,
        books: {
          ...state.books,
          isAddEdit: true,
          booksEditfield: payload,
        },
      };
    case GROW_ADD_EDIT_BOOK_CATEGORY_LOADER:
      return {
        ...state,
        bookCategory: {
          ...state.bookCategory,
          addEditLoader: payload,
        },
      };
    case GROW_LIST_BOOK_CATEGORY_LOADER:
      return {
        ...state,
        bookCategory: {
          ...state.bookCategory,
          listingLoader: payload,
        },
      };
    case GROW_AUTHOR_LISTING:
      return {
        ...state,
        author: {
          ...state.author,
          authorList: payload,
        },
      };
    case GROW_FETCH_AUTHOR_LOADER:
      return {
        ...state,
        author: {
          ...state.author,
          listingLoader: payload,
        },
      };
    case GROW_EDIT_AUTHOR:
      return {
        ...state,
        author: {
          ...state.author,
          isAddEdit: true,
          authorEditField: payload,
        },
      };
    case HANDLE_OPEN_ADD_EDIT:
      return {
        ...state,
        author: {
          ...state.author,
          isAddEdit: payload,
        },
      };
    case GROW_AUTHOR_TAB_CHANGE:
      return {
        ...state,
        author: {
          ...state.author,
          isAddEdit: payload,
        },
      };
    case GROW_ADD_EDIT_AUTHOR_LOADER:
      return {
        ...state,
        author: {
          ...state.author,
          addEditLoader: payload,
        },
      };
    case LIST_ALL_BOOKS_LISTING:
      return {
        ...state,
        books: {
          ...state.books,
          allBooksList: payload,
          isAddEdit: false,
        },
      };
    case GROW_ADD_BUTTON_BOOK:
      return {
        ...state,
        books: {
          ...state.books,
          isAddEdit: payload,
        },
      };
    case GROW_AUTHOR_NAME_LIST_SUCCESS:
      return {
        ...state,
        books: {
          ...state.books,
          addAuthorNameList: payload,
        },
      };
    case GROW_BOOK_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        books: {
          ...state.books,
          listBookCategoryNames: payload,
        },
      };
    case ADD_EDIT_PAGE_HANDLE:
      return {
        ...state,
        books: {
          ...state.books,
          isAddEdit: payload,
        },
      };
    default:
      return state;
  }
};
export default GrowBooksReducer;
