import {
  SUBSCRIPTION_FEEDBACK_ERROR,
  SUBSCRIPTION_FEEDBACK_LOADING,
  SUBSCRIPTION_FEEDBACK_SUCCESS,
} from "./actionTypes";

export const subscriptionFeedbackLoading = data => ({
  type: SUBSCRIPTION_FEEDBACK_LOADING,
  payload: data,
});

export const subscriptionFeedbackSuccess = data => ({
  type: SUBSCRIPTION_FEEDBACK_SUCCESS,
  payload: data,
});

export const subscriptionFeedbackError = data => ({
  type: SUBSCRIPTION_FEEDBACK_ERROR,
  payload: data,
});
