import { messages, modules } from "constants/logs";
import { sitesData } from "constants/siteDetails";
import { axiosApiAdmin } from "./api_helper";

const ADD_ACTIVITY_LOG = "/addAdminActivityLog";

const call = payload => {
  try {
    axiosApiAdmin.post(ADD_ACTIVITY_LOG, payload);
  } catch (err) {}
};

class Log {
  static user(isCustomer, user) {
    const module = isCustomer ? modules.customer : modules.staff;
    const userType = isCustomer ? "customer" : "staff";
    return {
      add: () => {
        const data = {
          module,
          submodule: "",
          activity_type: `add ${userType}`,
          description: isCustomer
            ? messages(user.email).addCustomer
            : messages(user.email).addStaff,
        };
        call(data);
      },
      edit: () => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `edit ${userType}`,
          description: isCustomer
            ? messages(user.email).editCustomer
            : messages(user.email).editStaff,
        };
        call(data);
      },
      activeDeActive: status => {
        const statusType = status === 1 ? "activated" : "de-activated";
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `active de-active`,
          description: messages(user.email, statusType).activeDeActive,
        };
        call(data);
      },
      changeUsername: newUsername => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `change username`,
          description: messages(user.email, newUsername).changeUsername,
        };
        call(data);
      },
      resetPassword: () => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `reset password`,
          description: messages(user.email).resetPassword,
        };
        call(data);
      },
      changePassword: () => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `change password`,
          description: messages(user.email).changePassword,
        };
        call(data);
      },
      editBillingAddress: () => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `edit billing address`,
          description: messages(user.email).updateBillingAddress,
        };
        call(data);
      },
      editShippingAddress: () => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `edit shipping address`,
          description: messages(user.email).updateShippingAddress,
        };
        call(data);
      },
      shareUnlimitedDashboard: isActive => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `toggle unlimited shared dashboard`,
          description: messages(user.email, isActive ? "active" : "de-active")
            .shareUnlimitedDashboard,
        };
        call(data);
      },
      visitApplication: site => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `visit application`,
          description: messages(site, user.email).visitApplication,
        };
        call(data);
      },
      addSubscription: subscriptionNumber => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `add subscription`,
          description: messages(subscriptionNumber, user.email).addSubscription,
        };
        call(data);
      },
      // Remain
      editSubscription: subscriptionNumber => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `edit subscription`,
          description: messages(subscriptionNumber, user.email)
            .editSubscription,
        };
        call(data);
      },
      addMembership: membershipId => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `add membership`,
          description: messages(membershipId, user.email).addMembership,
        };
        call(data);
      },
      editMembership: membershipId => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `edit membership`,
          description: messages(membershipId, user.email).editMembership,
        };
        call(data);
      },
      deleteMembership: membershipId => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `delete membership`,
          description: messages(membershipId, user.email).deleteMembership,
        };
        call(data);
      },
      addPaymentInfo: last4Digit => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `add payment card`,
          description: messages(last4Digit, user.email).addPaymentInfo,
        };
        call(data);
      },
      deletePaymentCard: cardId => {
        const data = {
          module,
          submodule: modules.profile,
          activity_type: `delete payment card`,
          description: messages(cardId, user.email).deletePaymentCard,
        };
        call(data);
      },
    };
  }
  //25-11-22 Saurav - Activity Log related code
  static application(appName, module = modules.application) {
    const submodule = appName || "";
    return {
      visitAndGoToDashboard: ({ module, activity_type, site_id }) => {
        let moDule = module === undefined ? "Applications" : module;
        let activityType =
          activity_type === undefined ? "Visit Application" : activity_type;
        const data = {
          module: moDule,
          submodule,
          activity_type: activityType,
          description: messages(sitesData[site_id]).visitAndGoToDashboard,
        };
        call(data);
      },
      visitApplicationInApplications: ({ module, activity_type }) => {
        const data = {
          module,
          submodule,
          activity_type,
          description: messages(module).visitApplicationInApplications,
        };
        call(data);
      },
      applicationTopNavbar: ({ module, activity_type }) => {
        const data = {
          module,
          submodule,
          activity_type,
          description: messages(activity_type).applicationTopNavbar,
        };
        call(data);
      },
      editApplication: app => {
        const data = {
          module,
          submodule,
          activity_type: `edit application`,
          description: messages(app).editApplication,
        };
        call(data);
      },
      addProduct: productName => {
        const data = {
          module,
          submodule,
          activity_type: `add product`,
          description: messages(productName).addProduct,
        };
        call(data);
      },
      editProduct: productId => {
        const data = {
          module,
          submodule,
          activity_type: `edit product`,
          description: messages(productId).editProduct,
        };
        call(data);
      },
      addCoupon: couponCode => {
        const data = {
          module,
          submodule,
          activity_type: `add coupon`,
          description: messages(couponCode).addCoupon,
        };
        call(data);
      },
      editCoupon: couponId => {
        const data = {
          module,
          submodule,
          activity_type: `edit coupon`,
          description: messages(couponId).editCoupon,
        };
        call(data);
      },
      deleteCoupon: couponCode => {
        const data = {
          module,
          submodule,
          activity_type: `delete coupon`,
          description: messages(couponCode).deleteCoupon,
        };
        call(data);
      },
      addLogMeetup: Meetup => {
        const data = {
          module,
          submodule,
          activity_type: `add Meetup`,
          description: messages(Meetup).addLogMeetup,
        };
        call(data);
      },
      editLogMeetup: meetupId => {
        const data = {
          module,
          submodule,
          activity_type: `edit Meetup`,
          description: messages(meetupId).editLogMeetup,
        };
        call(data);
      },
      deleteLogMeetup: meetup => {
        const data = {
          module,
          submodule,
          activity_type: `delete Meetup`,
          description: messages(meetup).deleteLogMeetup,
        };
        call(data);
      },
      addApplicationMembership: membershipTitle => {
        const data = {
          module,
          submodule,
          activity_type: `add membership`,
          description: messages(membershipTitle).addApplicationMembership, //20 jan arvina changes
        };
        call(data);
      },
      editApplicationMembership: membershipId => {
        const data = {
          module,
          submodule,
          activity_type: `edit membership`,
          description: messages(membershipId).editApplicationMembership, //20 jan arvina changes
        };
        call(data);
      },
      deleteApplicationMembership: membershipId => {
        const data = {
          module,
          submodule,
          activity_type: `delete membership`,
          description: messages(membershipId).deleteApplicationMembership,
        };
        call(data);
      },
      addMenu: (type, title) => {
        const data = {
          module,
          submodule,
          activity_type: `add ${type} menu`,
          description: messages(type, title).addMenu,
        };
        call(data);
      },
      editMenu: (type, title) => {
        const data = {
          module,
          submodule,
          activity_type: `edit ${type} menu`,
          description: messages(type, title).editMenu,
        };
        call(data);
      },
      deleteMenu: (type, menuId) => {
        const data = {
          module,
          submodule,
          activity_type: `delete ${type} menu`,
          description: messages(type, menuId).deleteMenu,
        };
        call(data);
      },
      editPaymentMethod: () => {
        const data = {
          module,
          submodule,
          activity_type: `edit payment method`,
          description: messages().editPaymentMethod,
        };
        call(data);
      },
      editEmailService: () => {
        const data = {
          module,
          submodule,
          activity_type: `edit email service`,
          description: messages().editEmailService,
        };
        call(data);
      },
      editSMSService: () => {
        const data = {
          module,
          submodule,
          activity_type: `edit sms service`,
          description: messages().editSMSService,
        };
        call(data);
      },
      addTutorial: title => {
        const data = {
          module,
          submodule,
          activity_type: `add tutorial`,
          description: messages(title).addTutorial,
        };
        call(data);
      },
      editTutorial: title => {
        const data = {
          module,
          submodule,
          activity_type: `edit tutorial`,
          description: messages(title).editTutorial,
        };
        call(data);
      },
      deleteTutorial: tutorialId => {
        const data = {
          module,
          submodule,
          activity_type: `delete tutorial`,
          description: messages(tutorialId).deleteTutorial,
        };
        call(data);
      },
      addFAQ: title => {
        const data = {
          module,
          submodule,
          activity_type: `add faq`,
          description: messages(title).addFAQ,
        };
        call(data);
      },
      editFAQ: title => {
        const data = {
          module,
          submodule,
          activity_type: `edit faq`,
          description: messages(title).editFAQ,
        };
        call(data);
      },
      deleteFAQ: faqId => {
        const data = {
          module,
          submodule,
          activity_type: `delete faq`,
          description: messages(faqId).deleteFAQ,
        };
        call(data);
      },
      reOrderFAQ: () => {
        const data = {
          module,
          submodule,
          activity_type: `reorder faq`,
          description: messages().reOrderFAQ,
        };
        call(data);
      },
      addPolicy: title => {
        const data = {
          module,
          submodule,
          activity_type: `add policy`,
          description: messages(title).addPolicy,
        };
        call(data);
      },
      editPolicy: title => {
        const data = {
          module,
          submodule,
          activity_type: `edit policy`,
          description: messages(title).editPolicy,
        };
        call(data);
      },
      deletePolicy: tutorialId => {
        const data = {
          module,
          submodule,
          activity_type: `delete policy`,
          description: messages(tutorialId).deletePolicy,
        };
        call(data);
      },
      editSystemPage: title => {
        const data = {
          module,
          submodule,
          activity_type: `configure system page`,
          description: messages(title).editSystemPage,
        };
        call(data);
      },
      editMobileApplication: () => {
        const data = {
          module,
          submodule,
          activity_type: `edit mobile application`,
          description: messages().editMobileApplication,
        };
        call(data);
      },
      editRateConfig: () => {
        const data = {
          module,
          submodule,
          activity_type: `edit rate configuration`,
          description: messages().editRateConfig,
        };
        call(data);
      },
      editTVApplication: () => {
        const data = {
          module,
          submodule,
          activity_type: `edit tv application`,
          description: messages().editTVApplication,
        };
        call(data);
      },
      addGame: title => {
        const data = {
          module,
          submodule,
          activity_type: `add game`,
          description: messages(title).addGame,
        };
        call(data);
      },
      editGame: gameId => {
        const data = {
          module,
          submodule,
          activity_type: `edit game`,
          description: messages(gameId).editGame,
        };
        call(data);
      },
      deleteGame: gameId => {
        const data = {
          module,
          submodule,
          activity_type: `delete game`,
          description: messages(gameId).deleteGame,
        };
        call(data);
      },
      addHabit: title => {
        const data = {
          module,
          submodule,
          activity_type: `add habit`,
          description: messages(title).addHabit,
        };
        call(data);
      },
      editHabit: habitId => {
        const data = {
          module,
          submodule,
          activity_type: `edit habit`,
          description: messages(habitId).editHabit,
        };
        call(data);
      },
      deleteHabit: habitId => {
        const data = {
          module,
          submodule,
          activity_type: `delete habit`,
          description: messages(habitId).deleteHabit,
        };
        call(data);
      },

      addHabitCategory: title => {
        const data = {
          module,
          submodule,
          activity_type: `add habit category`,
          description: messages(title).addHabitCategory,
        };
        call(data);
      },
      editHabitCategory: categoryId => {
        const data = {
          module,
          submodule,
          activity_type: `edit habit category`,
          description: messages(categoryId).editHabitCategory,
        };
        call(data);
      },
      deleteHabitCategory: categoryId => {
        const data = {
          module,
          submodule,
          activity_type: `delete habit category`,
          description: messages(categoryId).deleteHabitCategory,
        };
        call(data);
      },

      addIcebreaker: title => {
        const data = {
          module,
          submodule,
          activity_type: `add icebreaker`,
          description: messages(title).addIcebreaker,
        };
        call(data);
      },
      editIcebreaker: gameId => {
        const data = {
          module,
          submodule,
          activity_type: `edit icebreaker`,
          description: messages(gameId).editIcebreaker,
        };
        call(data);
      },
      deleteIcebreaker: icebreakerId => {
        const data = {
          module,
          submodule,
          activity_type: `delete icebreaker`,
          description: messages(icebreakerId).deleteIcebreaker,
        };
        call(data);
      },
      addFilter: title => {
        const data = {
          module,
          submodule,
          activity_type: `add filter`,
          description: messages(title).addFilter,
        };
        call(data);
      },
      editFilter: gameId => {
        const data = {
          module,
          submodule,
          activity_type: `edit filter`,
          description: messages(gameId).editFilter,
        };
        call(data);
      },
      deleteFilter: icebreakerId => {
        const data = {
          module,
          submodule,
          activity_type: `delete filter`,
          description: messages(icebreakerId).deleteFilter,
        };
        call(data);
      },
      deleteReview: reviewId => {
        const data = {
          module,
          submodule,
          activity_type: `delete review`,
          description: messages(reviewId).deleteReview,
        };
        call(data);
      },
      sendNotification: title => {
        const data = {
          module,
          submodule,
          activity_type: `add notification`,
          description: messages(title).sendNotification,
        };
        call(data);
      },
      editDefaultCountdown: () => {
        const data = {
          module,
          submodule,
          activity_type: `edit default countdown`,
          description: messages().editDefaultCountdown,
        };
        call(data);
      },
      addIntoCountdownTool: (type, value) => {
        const data = {
          module,
          submodule,
          activity_type: `add ${type}`,
          description: messages(type, value).addIntoCountdownTool,
        };
        call(data);
      },
      reOrderCountdownTool: type => {
        const data = {
          module,
          submodule,
          activity_type: `reorder ${type}`,
          description: messages(type).reOrderCountdownTool,
        };
        call(data);
      },
      addBanner: title => {
        const data = {
          module,
          submodule,
          activity_type: `add banner`,
          description: messages(title).addBanner,
        };
        call(data);
      },
      changeDefaultBanner: bannerId => {
        const data = {
          module,
          submodule,
          activity_type: `change default banner`,
          description: messages(bannerId).changeDefaultBanner,
        };
        call(data);
      },
      deleteBanner: bannerId => {
        const data = {
          module,
          submodule,
          activity_type: `delete banner`,
          description: messages(bannerId).deleteBanner,
        };
        call(data);
      },
      editEmailTemplate: title => {
        const data = {
          module,
          submodule,
          activity_type: `edit email template`,
          description: messages(title).editEmailTemplate,
        };
        call(data);
      },
      addAffiliate: userId => {
        const data = {
          module,
          submodule,
          activity_type: `add affiliate`,
          description: messages(userId).addAffiliate,
        };
        call(data);
      },
      editAffiliate: affiliateId => {
        const data = {
          module,
          submodule,
          activity_type: `edit affiliate`,
          description: messages(affiliateId).editAffiliate,
        };
        call(data);
      },
      deleteAffiliate: affiliateId => {
        const data = {
          module,
          submodule,
          activity_type: `delete affiliate`,
          description: messages(affiliateId).deleteAffiliate,
        };
        call(data);
      },
      addReferral: affiliateId => {
        const data = {
          module,
          submodule,
          activity_type: `add referral`,
          description: messages(affiliateId).addReferral,
        };
        call(data);
      },
      editReferral: referralId => {
        const data = {
          module,
          submodule,
          activity_type: `edit referral`,
          description: messages(referralId).editReferral,
        };
        call(data);
      },
      deleteReferral: referralId => {
        const data = {
          module,
          submodule,
          activity_type: `delete referral`,
          description: messages(referralId).deleteReferral,
        };
        call(data);
      },
      markedAsPaid: referralId => {
        const data = {
          module,
          submodule,
          activity_type: `marked as paid referral`,
          description: messages(referralId).markedAsPaid,
        };
        call(data);
      },
      addPayout: affiliateId => {
        const data = {
          module,
          submodule,
          activity_type: `add payout`,
          description: messages(affiliateId).addPayout,
        };
        call(data);
      },
      deletePayout: payoutId => {
        const data = {
          module,
          submodule,
          activity_type: `delete payout`,
          description: messages(payoutId).deletePayout,
        };
        call(data);
      },
      redirectToCustomerApplication: ({ user, application }) => {
        const data = {
          module: user,
          submodule,
          activity_type: `Redirect to ${submodule.toLowerCase()} application`,
          description: messages(user, application.toLowerCase())
            .redirectToCustomerApplication,
        };
        call(data);
      },
    };
  }
  static volumeBuilder(volume, ministry) {
    const module = modules.volumeBuilder;
    const submodule = `${volume}${ministry ? `/${ministry}` : ""}` || "";
    return {
      addSeries: title => {
        const data = {
          module,
          submodule,
          activity_type: `add series`,
          description: messages(title).addSeries,
        };
        call(data);
      },
      addPost: (type, title) => {
        const data = {
          module,
          submodule,
          activity_type: `add ${type}`,
          description: messages(type, title).addPost,
        };
        call(data);
      },
      editPost: (type, id) => {
        const data = {
          module,
          submodule,
          activity_type: `edit ${type}`,
          description: messages(type, id).editPost,
        };
        call(data);
      },
      addDashboardPage: title => {
        const data = {
          module,
          submodule,
          activity_type: `add dashboard page`,
          description: messages(title).addDashboardPage,
        };
        call(data);
      },
      editDashboardPage: pageId => {
        const data = {
          module,
          submodule,
          activity_type: `edit dashboard page`,
          description: messages(pageId).editDashboardPage,
        };
        call(data);
      },
      addTipVideo: title => {
        const data = {
          module,
          submodule,
          activity_type: `add tip video`,
          description: messages(title).addTipVideo,
        };
        call(data);
      },
      editTipVideo: videoId => {
        const data = {
          module,
          submodule,
          activity_type: `edit tip video`,
          description: messages(videoId).editTipVideo,
        };
        call(data);
      },
      // Curriculum
      editGeneral: seriesId => {
        const data = {
          module,
          submodule,
          activity_type: `edit general`,
          description: messages(seriesId).editGeneral,
        };
        call(data);
      },
      editEmail: (type, seriesId) => {
        const data = {
          module,
          submodule,
          activity_type: `edit email`,
          description: messages(type, seriesId).editEmail,
        };
        call(data);
      },
      editMemoryVerse: seriesId => {
        const data = {
          module,
          submodule,
          activity_type: `edit memory verse`,
          description: messages(seriesId).editMemoryVerse,
        };
        call(data);
      },
      addTutorial: (type, title, seriesId) => {
        const data = {
          module,
          submodule,
          activity_type: `add tutorial`,
          description: messages(type ? ` ${type}` : "", title, seriesId)
            .addSeriesTutorial,
        };
        call(data);
      },
      editTutorial: (type, title, seriesId) => {
        const data = {
          module,
          submodule,
          activity_type: `edit tutorial`,
          description: messages(type ? ` ${type}` : "", title, seriesId)
            .editSeriesTutorial,
        };
        call(data);
      },
      deleteTutorial: (type, title, seriesId) => {
        const data = {
          module,
          submodule,
          activity_type: `delete tutorial`,
          description: messages(type ? ` ${type}` : "", title, seriesId)
            .deleteSeriesTutorial,
        };
        call(data);
      },
      // Page Editor
      editDescription: (title, pageId) => {
        const data = {
          module,
          submodule,
          activity_type: `edit description`,
          description: messages(title, pageId).editDescription,
        };
        call(data);
      },
      editPosts: (type, title, pageId) => {
        const data = {
          module,
          submodule,
          activity_type: `edit ${type}`,
          description: messages(type, title, pageId).editPosts,
        };
        call(data);
      },
      // Slidr
      addSlideshow: (type, weekNumber, seriesId) => {
        const data = {
          module,
          submodule,
          activity_type: `add slideshow`,
          description: messages(type, weekNumber, seriesId).addSlideshow,
        };
        call(data);
      },
      deleteSlideshow: (type, weekNumber, seriesId) => {
        const data = {
          module,
          submodule,
          activity_type: `delete slideshow`,
          description: messages(type, weekNumber, seriesId).deleteSlideshow,
        };
        call(data);
      },
      importSlideshow: (type, weekNumber, seriesId) => {
        const data = {
          module,
          submodule,
          activity_type: `import slideshow`,
          description: messages(type, weekNumber, seriesId).importSlideshow,
        };
        call(data);
      },
      saveSlideshow: slideshowId => {
        const data = {
          module,
          submodule: "Slidr",
          activity_type: `edit slideshow`,
          description: messages(slideshowId).saveSlideshow,
        };
        call(data);
      },
      publishSlideshow: slideshowId => {
        const data = {
          module,
          submodule: "Slidr",
          activity_type: `publish slideshow`,
          description: messages(slideshowId).publishSlideshow,
        };
        call(data);
      },
      saveSlideshowSettings: slideshowId => {
        const data = {
          module,
          submodule: "Slidr",
          activity_type: `edit slideshow settings`,
          description: messages(slideshowId).saveSlideshowSettings,
        };
        call(data);
      },
      editSlideshowTitle: (title, slideshowId) => {
        const data = {
          module,
          submodule: "Slidr",
          activity_type: `edit slideshow title`,
          description: messages(title, slideshowId).editSlideshowTitle,
        };
        call(data);
      },

      // Hub
      addHubCalenderItem: title => {
        const data = {
          module,
          submodule,
          activity_type: `add calender item`,
          description: messages(title).addHubCalenderItem,
        };
        call(data);
      },
      editHubCalenderItem: id => {
        const data = {
          module,
          submodule,
          activity_type: `edit calender item`,
          description: messages(id).editHubCalenderItem,
        };
        call(data);
      },
      deleteHubCalenderItem: id => {
        const data = {
          module,
          submodule,
          activity_type: `delete calender item`,
          description: messages(id).deleteHubCalenderItem,
        };
        call(data);
      },
      addHubAnnouncementItem: title => {
        const data = {
          module,
          submodule,
          activity_type: `add announcement item`,
          description: messages(title).addHubAnnouncementItem,
        };
        call(data);
      },
      editHubAnnouncementItem: id => {
        const data = {
          module,
          submodule,
          activity_type: `edit announcement item`,
          description: messages(id).editHubAnnouncementItem,
        };
        call(data);
      },
      deleteHubAnnouncementItem: id => {
        const data = {
          module,
          submodule,
          activity_type: `delete announcement item`,
          description: messages(id).deleteHubAnnouncementItem,
        };
        call(data);
      },
      addGrowBuilderMessageItem: title => {
        const data = {
          module,
          submodule,
          activity_type: `add Grow Builder Message Item`,
          description: messages(title).addGrowBuilderMessageItem,
        };
        call(data);
      },
      editGrowBuilderMessageItem: id => {
        const data = {
          module,
          submodule,
          activity_type: `edit Grow Builder Message Item`,
          description: messages(id).editGrowBuilderMessageItem,
        };
        call(data);
      },
      deleteGrowBuilderMessageItem: id => {
        const data = {
          module,
          submodule,
          activity_type: `delete Grow Builder Message Item`,
          description: messages(id).deleteGrowBuilderMessageItem,
        };
        call(data);
      },
      exportGrowBuilderMessageItem: id => {
        const data = {
          module,
          submodule,
          activity_type: `export Grow Builder Message Item`,
          description: messages(id).exportGrowBuilderMessageItem,
        };
        call(data);
      },
      saveGrowBuilderMessageItem: id => {
        const data = {
          module,
          submodule,
          activity_type: `save Grow Builder Message Item`,
          description: messages(id).saveGrowBuilderMessageItem,
        };
        call(data);
      },
      addVolume: title => {
        const data = {
          module,
          submodule,
          activity_type: `add Grow Builder Volume`,
          description: messages(title).addGrowAppBuilder,
        };
        call(data);
      },
      editVolume: id => {
        const data = {
          module,
          submodule,
          activity_type: `edit Grow Builder Volume`,
          description: messages(id).editGrowAppBuilder,
        };
        call(data);
      },
      deleteVolume: id => {
        const data = {
          module,
          submodule,
          activity_type: `delete Grow Builder Volume`,
          description: messages(id).deleteGrowAppBuilder,
        };
        call(data);
      },
      exportVolume: id => {
        const data = {
          module,
          submodule,
          activity_type: `export Grow Builder Volume`,
          description: messages(id).exportGrowAppBuilder,
        };
        call(data);
      },
      saveVolume: id => {
        const data = {
          module,
          submodule,
          activity_type: `save Grow Builder Volume`,
          description: messages(id).saveGrowAppBuilder,
        };
        call(data);
      },
    };
  }
  static permissionBuilder() {
    const module =
      modules.permissionBuilder === undefined
        ? "Menu Permission"
        : modules.permissionBuilder;
    const submodule = "";
    return {
      addPermission: title => {
        const data = {
          module,
          submodule,
          activity_type: `add permission profile`,
          description: messages(title).addPermission,
        };
        call(data);
      },
      editPermission: id => {
        const data = {
          module,
          submodule,
          activity_type: `edit permission profile`,
          description: messages(id).editPermission,
        };
        call(data);
      },

      deletePermission: id => {
        const data = {
          module,
          submodule,
          activity_type: `delete permission profile`,
          description: messages(id).deletePermission,
        };
        call(data);
      },
    };
  }
  static redirection() {
    const module = modules.redirection;
    const submodule = "";
    return {
      addRedirection: title => {
        const data = {
          module,
          submodule,
          activity_type: `add redirection`,
          description: messages(title).addRedirection,
        };
        call(data);
      },
      editRedirection: id => {
        const data = {
          module,
          submodule,
          activity_type: `edit redirection`,
          description: messages(id).editRedirection,
        };
        call(data);
      },
      deleteRedirection: id => {
        const data = {
          module,
          submodule,
          activity_type: `delete redirection`,
          description: messages(id).deleteRedirection,
        };
        call(data);
      },
    };
  }
  static page(submoduleName) {
    const module = modules.pages;
    const submodule = submoduleName || "";
    return {
      addAmazonPage: title => {
        const data = {
          module,
          submodule,
          activity_type: `add amazon page`,
          description: messages(title).addAmazonPage,
        };
        call(data);
      },
      editAmazonPage: id => {
        const data = {
          module,
          submodule,
          activity_type: `edit amazon page`,
          description: messages(id).editAmazonPage,
        };
        call(data);
      },
      deleteAmazonPage: id => {
        const data = {
          module,
          submodule,
          activity_type: `delete amazon page`,
          description: messages(id).deleteAmazonPage,
        };
        call(data);
      },
      addApplicationMenu: title => {
        const data = {
          module,
          submodule,
          activity_type: `add application menu`,
          description: messages(title).addApplicationMenu,
        };
        call(data);
      },
      editApplicationMenu: id => {
        const data = {
          module,
          submodule,
          activity_type: `edit application menu`,
          description: messages(id).editApplicationMenu,
        };
        call(data);
      },
      deleteApplicationMenu: id => {
        const data = {
          module,
          submodule,
          activity_type: `delete application menu`,
          description: messages(id).deleteApplicationMenu,
        };
        call(data);
      },
      addSecuityQuestion: title => {
        const data = {
          module,
          submodule,
          activity_type: `add security question`,
          description: messages(title).addSecuityQuestion,
        };
        call(data);
      },
      deleteSecuityQuestion: id => {
        const data = {
          module,
          submodule,
          activity_type: `delete security question`,
          description: messages(id).deleteSecuityQuestion,
        };
        call(data);
      },
    };
  }
  static cloudStorage() {
    const module = modules.cloudStorage;
    const submodule = "";
    return {
      addFolderFile: title => {
        const data = {
          module,
          submodule,
          activity_type: `Create Files or Folders`,
          description: messages(title).addFolderFile,
        };
        call(data);
      },
      shareFolderFile: title => {
        const data = {
          module,
          submodule,
          activity_type: `Share Files or Folders`,
          description: messages(title).shareFolderFile,
        };
        call(data);
      },
      unShareFolderFile: id => {
        const data = {
          module,
          submodule,
          activity_type: `UnShare Files or Folders`,
          description: messages(id).unShareFolderFile,
        };
        call(data);
      },
      deleteFolderFile: title => {
        const data = {
          module,
          submodule,
          activity_type: `Delete Files or Folders`,
          description: messages(title).deleteFolderFile,
        };
        call(data);
      },
    };
  }
  static cloudS3() {
    const module = modules.cloudS3;
    const submodule = "";
    return {
      addFolderFile: title => {
        const data = {
          module,
          submodule,
          activity_type: `Created`,
          description: messages(title).addFolderFile,
        };
        call(data);
      },
      shareFolderFile: title => {
        const data = {
          module,
          submodule,
          activity_type: `Shared`,
          description: messages(title).shareFolderFile,
        };
        call(data);
      },
      unShareFolderFile: id => {
        const data = {
          module,
          submodule,
          activity_type: `UnShared`,
          description: messages(id).unShareFolderFile,
        };
        call(data);
      },
      deleteFolderFile: title => {
        const data = {
          module,
          submodule,
          activity_type: `Deleted`,
          description: messages(title).deleteFolderFile,
        };
        call(data);
      },
      restoreFolderFile: title => {
        const data = {
          module,
          submodule,
          activity_type: `Restored`,
          description: messages(title).restoreFolderFile,
        };
        call(data);
      },
    };
  }
  static sidebarMenu(module) {
    return {
      redirection: (submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Redirection`,
          description: messages(module).sideMenuRedirection,
        };
        call(data);
      },
    };
  }
  // static tableActions(module) {
  //   return {
  //     searched: (title = "", submodule = "") => {
  //       const data = {
  //         module,
  //         submodule,
  //         activity_type: `Search`,
  //         description: messages(title).searchTable,
  //       };
  //       call(data);
  //     },
  //     changedDateRange: (startDate, endDate, submodule = "") => {
  //       const data = {
  //         module,
  //         submodule,
  //         activity_type: `Changed Date Range`,
  //         description: messages(startDate, endDate).dateRange,
  //       };
  //       call(data);
  //     },
  //     changedDate: (startDate, submodule = "") => {
  //       const data = {
  //         module,
  //         submodule,
  //         activity_type: `Changed Date`,
  //         description: messages(startDate).date,
  //       };
  //       call(data);
  //     },
  //     changedTimeZone: (timezone, submodule = "") => {
  //       const data = {
  //         module,
  //         submodule,
  //         activity_type: `Changed Timezone`,
  //         description: messages(timezone).timezone,
  //       };
  //       call(data);
  //     },
  //     applicationFilter: (application, submodule = "") => {
  //       const data = {
  //         module,
  //         submodule,
  //         activity_type: `Changed application`,
  //         description: messages(application).filterByApplication,
  //       };
  //       call(data);
  //     },
  //     categoryFilter: (application, submodule = "") => {
  //       const data = {
  //         module,
  //         submodule,
  //         activity_type: `Changed category`,
  //         description: messages(application).filterByCategory,
  //       };
  //       call(data);
  //     },
  //     ministryTypeFilter: (application, submodule = "") => {
  //       const data = {
  //         module,
  //         submodule,
  //         activity_type: `Changed ministry type`,
  //         description: messages(application).filterByMinistryType,
  //       };
  //       call(data);
  //     },
  //     volumeFilter: (application, submodule = "") => {
  //       const data = {
  //         module,
  //         submodule,
  //         activity_type: `Changed volume`,
  //         description: messages(application).filterByVolume,
  //       };
  //       call(data);
  //     },
  //     moduleFilter: (dataModule, submodule = "") => {
  //       const data = {
  //         module,
  //         submodule,
  //         activity_type: `Changed module`,
  //         description: messages(dataModule).filterByModule,
  //       };
  //       call(data);
  //     },
  //     roleFilter: (role, submodule = "") => {
  //       const data = {
  //         module,
  //         submodule,
  //         activity_type: `Changed role`,
  //         description: messages(role).filterByRole,
  //       };
  //       call(data);
  //     },
  //   };
  // }
  static customer(module) {
    return {
      redirectStaffOrCustomerAccount: ({ activity_type, user }) => {
        const data = {
          module,
          submodule: "",
          activity_type,
          description: messages(user).redirectToCustomerOrStaffAccount,
        };
        call(data);
      },
    };
  }
  static subscription(submodule) {
    return {
      redirectToSubscription: ({ module, activity_type, number }) => {
        const data = {
          module,
          submodule,
          activity_type,
          description: messages(number).redirectToSubscription,
        };
        call(data);
      },
      redirectToMemberShip: ({
        module = "",
        activity_type,
        memberShipName,
      }) => {
        const data = {
          module,
          submodule,
          activity_type,
          description: messages(memberShipName).redirectToMemberShip,
        };
        call(data);
      },
    };
  }
  static notes(submodule) {
    return {
      redirection: ({ module, activity_type }) => {
        const data = {
          module,
          submodule,
          activity_type,
          description: messages(module).redirectionToNotes,
        };
        call(data);
      },
    };
  }
  static invoice(submodule) {
    return {
      preview: ({ module, activity_type, email_id }) => {
        const data = {
          module,
          submodule,
          activity_type,
          description: messages(email_id).previewInvoice,
        };
        call(data);
      },
    };
  }
  static tableActions(module) {
    return {
      searched: (title = "", submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Search`,
          description: messages(title).searchTable,
        };
        call(data);
      },
      changedDateRange: (startDate, endDate, submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Changed Date Range`,
          description: messages(startDate, endDate).dateRange,
        };
        call(data);
      },
      changedDate: (startDate, submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Changed Date`,
          description: messages(startDate).date,
        };
        call(data);
      },
      changedTimeZone: (timezone, submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Changed Timezone`,
          description: messages(timezone).timezone,
        };
        call(data);
      },
      applicationFilter: (application, submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Changed application`,
          description: messages(application).filterByApplication,
        };
        call(data);
      },
      categoryFilter: (application, submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Changed category`,
          description: messages(application).filterByCategory,
        };
        call(data);
      },
      ministryTypeFilter: (application, submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Changed ministry type`,
          description: messages(application).filterByMinistryType,
        };
        call(data);
      },
      volumeFilter: (application, submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Changed volume`,
          description: messages(application).filterByVolume,
        };
        call(data);
      },
      moduleFilter: (dataModule, submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Changed module`,
          description: messages(dataModule).filterByModule,
        };
        call(data);
      },
      roleFilter: (role, submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Changed role`,
          description: messages(role).filterByRole,
        };
        call(data);
      },
      statusFilter: (role, submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Changed status`,
          description: messages(role).filterByMinistryType,
        };
        call(data);
      },
      openEditor: (role, submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Opened Editor`,
          description: messages(role).openSeriesEditor,
        };
        call(data);
      },
      permissionEditor: (role, submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Opened Permission Editor`,
          description: messages(role).openPermissionEditor,
        };
        call(data);
      },
      tabChange: (role, submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Tab Change`,
          description: messages(role).tabChange,
        };
        call(data);
      },
    };
  }
  static settingAction(module) {
    return {
      leftBarMenuChange: (title = "", submodule = "") => {
        const data = {
          module,
          submodule,
          activity_type: `Menu Change`,
          description: messages(title).menuChange,
        };
        call(data);
      },
    };
  }
}

export default Log;
