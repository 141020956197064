import { axiosApiAdmin } from "helpers/api_helper";
import {
  ADD_HABIT_URL,
  DELETE_HABIT_URL,
  EDIT_HABIT_URL,
  HABIT_DETAIL,
  LIST_HABIT,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  addHabitError,
  deleteHabitError,
  editHabitError,
  fetchHabit,
  fetchHabitByIdError,
  fetchHabitByIdSuccess,
  fetchHabitError,
  fetchHabitSuccess,
  setErrorMessage,
  setSuccessMessage,
} from "store/actions";
import {
  ADD_HABIT,
  DELETE_HABIT,
  EDIT_HABIT,
  FETCH_HABIT,
  GET_HABIT_DETAIL,
} from "./actionTypes";

const addHabits = data => axiosApiAdmin.post(ADD_HABIT_URL, data);
const editHabits = data => axiosApiAdmin.put(EDIT_HABIT_URL, data);
const fetchHabits = data => axiosApiAdmin.post(LIST_HABIT, data);
const deleteHabits = data => axiosApiAdmin.put(DELETE_HABIT_URL, data);
const fetchHabitByIds = data => axiosApiAdmin.get(HABIT_DETAIL + `/${data}`);

function* handleFetchHabitAdmin({ payload }) {
  const {
    search,
    is_admin,
    is_from,
    sizePerPage,
    page,
    sortOrder,
    sortField,
    category_index,
  } = yield select(State => State.HabitReducer);
  const data = {
    page_record: !!payload ? payload.page_record : sizePerPage,
    page_no: !!payload ? payload.page_no : page,
    is_admin: !!payload ? payload.is_admin : is_admin,
    is_from: !!payload ? payload.is_from : is_from,
    search,
    sort_order: sortOrder,
    sort_field: sortField,
    category_index: category_index,
  };
  try {
    const res = yield call(fetchHabits, data);
    if (res.data?.data) {
      yield put(fetchHabitSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchHabitError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAddHabit({ payload: { data, cb } }) {
  try {
    const res = yield call(addHabits, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchHabit());
    }
    if (!!cb) cb();
  } catch (err) {
    yield put(addHabitError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleEditHabit({ payload: { data, cb } }) {
  try {
    const res = yield call(editHabits, data);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchHabit());
    }
    if (!!cb) cb();
  } catch (err) {
    yield put(editHabitError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleGetHabitDetail({ payload }) {
  try {
    const res = yield call(fetchHabitByIds, payload);
    if (res?.data?.data) {
      yield put(fetchHabitByIdSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchHabitByIdError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteHabit({ payload: { data, cb } }) {
  try {
    const res = yield call(deleteHabits, data);
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchHabit());
    }
    if (!!cb) cb();
  } catch (err) {
    yield put(deleteHabitError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* habitSaga() {
  yield takeEvery(FETCH_HABIT, handleFetchHabitAdmin);
  yield takeEvery(ADD_HABIT, handleAddHabit);
  yield takeEvery(EDIT_HABIT, handleEditHabit);
  yield takeEvery(DELETE_HABIT, handleDeleteHabit);
  yield takeEvery(GET_HABIT_DETAIL, handleGetHabitDetail);
}

export default habitSaga;
