import {
  FETCH_BLOG_LINK_POST_REPORT_SUCCESS,
  FETCH_BLOG_NON_LINK_NUM_POST_REPORT_SUCCESS,
  FETCH_BLOG_NON_LINK_POST_REPORT_SUCCESS,
  FETCH_BLOG_NUM_LINK_POST_REPORT_SUCCESS,
  FETCH_BLOG_POTENTIAL_LINKED_POST_NUM_SUCCESS,
  FETCH_BLOG_POTENTIAL_LINKED_POST_SUCCESS,
  FETCH_BLOG_SITEMAP,
  FETCH_BLOG_SITEMAP_CHART,
  FETCH_BLOG_SITEMAP_CHART_SUCCESS,
  FETCH_BLOG_SITEMAP_SUCCESS,
  FETCH_BLOG_TOTAL_POST_REPORT_SUCCESS,
  GET_BLOGSITEMAP_PAGES,
  GET_BLOGSITEMAP_SIZEPERPAGES,
  GET_SEARCH_BLOGSITEMAP,
  GET_SORT_FIELD_BLOG_SITEMAP,
  GET_SORT_ORDER_BLOG_SITEMAP,
  SORT_ITEMS_SET,
} from "./actionTypes";

export const getBlogSiteMapReport = data => ({
  type: FETCH_BLOG_SITEMAP,
  payload: data,
});

export const getBlogSiteMapReportSuccess = data => ({
  type: FETCH_BLOG_SITEMAP_SUCCESS,
  payload: data,
});

export const getBlogSiteMapReportLinkedPostSuccess = data => ({
  type: FETCH_BLOG_LINK_POST_REPORT_SUCCESS,
  payload: data,
});

export const getBlogSiteMapReportNonLinkedPostSuccess = data => ({
  type: FETCH_BLOG_NON_LINK_POST_REPORT_SUCCESS,
  payload: data,
});

export const getBlogSiteMapReportNumberLinkedPostSuccess = data => ({
  type: FETCH_BLOG_NUM_LINK_POST_REPORT_SUCCESS,
  payload: data,
});

export const getBlogSiteMapReportNumberNonLinkedPostSuccess = data => ({
  type: FETCH_BLOG_NON_LINK_NUM_POST_REPORT_SUCCESS,
  payload: data,
});

export const getBlogSiteMapReportTotalLinkedPostSuccess = data => ({
  type: FETCH_BLOG_TOTAL_POST_REPORT_SUCCESS,
  payload: data,
});

export const getBlogSiteMapChart = () => ({
  type: FETCH_BLOG_SITEMAP_CHART,
});

export const getBlogSiteMapChartSuccess = data => ({
  type: FETCH_BLOG_SITEMAP_CHART_SUCCESS,
  payload: data,
});

//Search
export const getSearch = data => ({
  type: GET_SEARCH_BLOGSITEMAP,
  payload: data,
});
//Sort
export const getSortFieldBlogSitemap = data => ({
  type: GET_SORT_FIELD_BLOG_SITEMAP,
  payload: data,
});
export const getSortOrderBlogSitemap = data => ({
  type: GET_SORT_ORDER_BLOG_SITEMAP,
  payload: data,
});
//Pagination
export const getBlogSitemapPages = data => ({
  type: GET_BLOGSITEMAP_PAGES,
  payload: data,
});
export const getBlogSitemapSizePerPage = data => ({
  type: GET_BLOGSITEMAP_SIZEPERPAGES,
  payload: data,
});

export const getSortBlogSitemapSizePerPage = data => ({
  type: SORT_ITEMS_SET,
  payload: data,
});
export const getBlogSitemapPotential = data => ({
  type: FETCH_BLOG_POTENTIAL_LINKED_POST_SUCCESS,
  payload: data,
});
export const getBlogSitemapPotentialNum = data => ({
  type: FETCH_BLOG_POTENTIAL_LINKED_POST_NUM_SUCCESS,
  payload: data,
});
