export const SET_ANALYTICS_REVENUE_ACTIVE_TAB =
  "SET_ANALYTICS_REVENUE_ACTIVE_TAB";
export const SET_ANALYTICS_REVENUE_RANGE_DATE =
  "SET_ANALYTICS_REVENUE_RANGE_DATE";
export const SET_ANALYTICS_REVENUE_COMPARE_TYPE =
  "SET_ANALYTICS_REVENUE_COMPARE_TYPE";
export const SET_ANALYTICS_REVENUE_BY = "SET_ANALYTICS_REVENUE_BY";
export const SET_ANALYTICS_REVENUE_CURRENT_SITE =
  "SET_ANALYTICS_REVENUE_CURRENT_SITE";
export const ANALYTICS_REVENUE_SET_TZ = "ANALYTICS_REVENUE_SET_TZ";

//CLEAR

export const CLEAR_REVENUE_REPORT = "CLEAR_REVENUE_REPORT";
export const CLEAR_SEARCH_QUERY_REPORT = "CLEAR_SEARCH_QUERY_REPORT";

// TABLE
export const SET_ANALYTICS_REVENUE_PAGE = "SET_ANALYTICS_REVENUE_PAGE";
export const SET_ANALYTICS_REVENUE_SIZE_PER_PAGE =
  "SET_ANALYTICS_REVENUE_SIZE_PER_PAGE";
export const SET_ANALYTICS_REVENUE_SORT_FIELD =
  "SET_ANALYTICS_REVENUE_SORT_FIELD";
export const SET_ANALYTICS_REVENUE_SORT_ORDER =
  "SET_ANALYTICS_REVENUE_SORT_ORDER";

// APIS
export const FETCH_REVENUE_REPORTS = "FETCH_REVENUE_REPORTS";
export const FETCH_REVENUE_REPORTS_SUCCESS = "FETCH_REVENUE_REPORTS_SUCCESS";
export const FETCH_REVENUE_REPORTS_ERROR = "FETCH_REVENUE_REPORTS_ERROR";

export const FETCH_REVENUE_GRAPH_DATA = "FETCH_REVENUE_GRAPH_DATA";
export const FETCH_REVENUE_GRAPH_DATA_SUCCESS =
  "FETCH_REVENUE_GRAPH_DATA_SUCCESS";
export const FETCH_REVENUE_GRAPH_DATA_ERROR = "FETCH_REVENUE_GRAPH_DATA_ERROR";

export const FETCH_REVENUE_TABLE_DATA = "FETCH_REVENUE_TABLE_DATA";
export const FETCH_REVENUE_TABLE_DATA_SUCCESS =
  "FETCH_REVENUE_TABLE_DATA_SUCCESS";
export const FETCH_REVENUE_TABLE_DATA_ERROR = "FETCH_REVENUE_TABLE_DATA_ERROR";
