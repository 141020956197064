import {
  ADD_EDIT_SERIES_EDITOR,
  ADD_EDIT_SERIES_EDITOR_ERROR,
  ADD_EDIT_SERIES_EDITOR_SUCCESS,
  ADD_EDIT_VOLUME_FOLDER,
  ADD_EDIT_VOLUME_FOLDER_ERROR,
  ADD_EDIT_VOLUME_FOLDER_SUCCESS,
  ADD_EDIT_VOLUME_SERIES,
  ADD_EDIT_VOLUME_SERIES_ERROR,
  ADD_EDIT_VOLUME_SERIES_SUCCESS,
  CLEAR_SERIES,
  DELETE_SERIES,
  DELETE_SERIES_ERROR,
  DELETE_SERIES_SUCCESS,
  DELETE_VOLUME_FOLDER,
  DELETE_VOLUME_FOLDER_SUCCESS,
  FETCH_ALL_BOARD_DATA,
  FETCH_BOARD,
  FETCH_SERIES,
  FETCH_SERIES_EDITOR_DETAILS,
  FETCH_SERIES_EDITOR_DETAILS_ERROR,
  FETCH_SERIES_EDITOR_DETAILS_SUCCESS,
  FETCH_SERIES_ERROR,
  FETCH_SERIES_SUCCESS,
  FETCH_TIP_VIDEOS,
  FETCH_TIP_VIDEOS_ERROR,
  FETCH_TIP_VIDEOS_SUCCESS,
  FETCH_VOLUME_BUILDER_FOLDERS,
  FETCH_VOLUME_BUILDER_FOLDER_ERROR,
  FETCH_VOLUME_BUILDER_FOLDER_SUCCESS,
  GET_AMAZON_INTERNALS,
  GET_AMAZON_INTERNALS_ERROR,
  GET_AMAZON_INTERNALS_SUCCESS,
  GET_VOLUME_FOLDER_DETAILS,
  GET_VOLUME_FOLDER_DETAILS_SUCCESS,
  GET_VOLUME_SERIES_DETAILS,
  GET_VOLUME_SERIES_DETAILS_SUCCESS,
  SAVE_AMAZON_INTERNALS,
  SAVE_AMAZON_INTERNALS_ERROR,
  SAVE_AMAZON_INTERNALS_SUCCESS,
  SET_ACTIVE_TAB_ID,
  SET_CATEGORY_VBS_ID,
  SET_IS_VBS,
  SET_SEARCH_SERIES,
  SET_TIP_VIDEO_SEARCH,
  SET_TYPES,
} from "./actionTypes";

// fatch page folder
export const fetchVolumeBuilderFolders = data => ({
  type: FETCH_VOLUME_BUILDER_FOLDERS,
  payload: data,
});

export const fetchVolumeBuilderFolderSuccess = data => ({
  type: FETCH_VOLUME_BUILDER_FOLDER_SUCCESS,
  payload: data,
});

export const fetchVolumeBuilderFolderError = data => ({
  type: FETCH_VOLUME_BUILDER_FOLDER_ERROR,
  payload: data,
});

export const addEditVolumeFolder = data => ({
  type: ADD_EDIT_VOLUME_FOLDER,
  payload: data,
});
export const addEditVolumeFolderSuccess = () => ({
  type: ADD_EDIT_VOLUME_FOLDER_SUCCESS,
});
export const addEditVolumeFolderError = () => ({
  type: ADD_EDIT_VOLUME_FOLDER_ERROR,
});

export const getVolumeFolderDetails = data => ({
  type: GET_VOLUME_FOLDER_DETAILS,
  payload: data,
});

export const getVolumeFolderDetailsSuccess = data => {
  return {
    type: GET_VOLUME_FOLDER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const deleteVolumeFolder = data => ({
  type: DELETE_VOLUME_FOLDER,
  payload: data,
});

export const deleteVolumeFolderSuccess = data => {
  return {
    type: DELETE_VOLUME_FOLDER_SUCCESS,
    payload: data,
  };
};

// fetching pages
export const fetchSeries = data => {
  return {
    type: FETCH_SERIES,
    payload: data,
  };
};

export const fetchSeriesSuccess = data => {
  return {
    type: FETCH_SERIES_SUCCESS,
    payload: data,
  };
};

export const fetchSeriesError = data => {
  return {
    type: FETCH_SERIES_ERROR,
    payload: data,
  };
};

export const addEditVolumeSeries = data => ({
  type: ADD_EDIT_VOLUME_SERIES,
  payload: data,
});
export const addEditVolumeSeriesSuccess = () => ({
  type: ADD_EDIT_VOLUME_SERIES_SUCCESS,
});
export const addEditVolumeSeriesError = () => ({
  type: ADD_EDIT_VOLUME_SERIES_ERROR,
});

export const getVolumeSeriesDetails = data => ({
  type: GET_VOLUME_SERIES_DETAILS,
  payload: data,
});

export const getVolumeSeriesDetailsSuccess = data => {
  return {
    type: GET_VOLUME_SERIES_DETAILS_SUCCESS,
    payload: data,
  };
};

// deleting series
export const deleteSeries = data => ({
  type: DELETE_SERIES,
  payload: data,
});

export const deleteSeriesSuccess = data => ({
  type: DELETE_SERIES_SUCCESS,
  payload: data,
});

export const deleteSeriesError = data => ({
  type: DELETE_SERIES_ERROR,
  payload: data,
});

/** Series Editor */
export const fetchSeriesEditorDetails = data => {
  return {
    type: FETCH_SERIES_EDITOR_DETAILS,
    payload: data,
  };
};

export const fetchSeriesEditorDetailsSuccess = data => {
  return {
    type: FETCH_SERIES_EDITOR_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchSeriesEditorDetailsError = data => {
  return {
    type: FETCH_SERIES_EDITOR_DETAILS_ERROR,
    payload: data,
  };
};

export const addEditSeriesEditor = data => ({
  type: ADD_EDIT_SERIES_EDITOR,
  payload: data,
});

export const addEditSeriesEditorSuccess = data => ({
  type: ADD_EDIT_SERIES_EDITOR_SUCCESS,
  payload: data,
});

export const addEditSeriesEditorError = () => ({
  type: ADD_EDIT_SERIES_EDITOR_ERROR,
});

export const setSeriesSearch = data => ({
  type: SET_SEARCH_SERIES,
  payload: data,
});
export const setIsVBS = data => ({
  type: SET_IS_VBS,
  payload: data,
});
export const setTypes = data => ({
  type: SET_TYPES,
  payload: data,
});
/** Series Editor End*/

export const clearSeries = () => ({
  type: CLEAR_SERIES,
});

export const fetchTipVideos = () => ({
  type: FETCH_TIP_VIDEOS,
});

export const fetchBoard = () => ({
  type: FETCH_BOARD,
});

export const fetchTipVideosSuccess = data => ({
  type: FETCH_TIP_VIDEOS_SUCCESS,
  payload: data,
});

export const fetchBoardDataSuccess = data => ({
  type: FETCH_ALL_BOARD_DATA,
  payload: data,
});

export const fetchTipVideosError = () => ({
  type: FETCH_TIP_VIDEOS_ERROR,
});
export const setTipVideoSearch = data => ({
  type: SET_TIP_VIDEO_SEARCH,
  payload: data,
});

/** Series Amazon Code */
export const saveAmazonInternals = data => ({
  type: SAVE_AMAZON_INTERNALS,
  payload: data,
});

export const saveAmazonInternalsSuccess = data => ({
  type: SAVE_AMAZON_INTERNALS_SUCCESS,
  payload: data,
});

export const saveAmazonInternalsError = () => ({
  type: SAVE_AMAZON_INTERNALS_ERROR,
});

export const getAmazonInternals = data => {
  return {
    type: GET_AMAZON_INTERNALS,
    payload: data,
  };
};

export const getAmazonInternalsSuccess = data => {
  return {
    type: GET_AMAZON_INTERNALS_SUCCESS,
    payload: data,
  };
};

export const getAmazonInternalsError = data => {
  return {
    type: GET_AMAZON_INTERNALS_ERROR,
    payload: data,
  };
};

export const setVBSCategory = data => {
  return {
    type: SET_CATEGORY_VBS_ID,
    payload: data,
  };
};

export const setActiveTab = data => {
  return {
    type: SET_ACTIVE_TAB_ID,
    payload: data,
  };
};
