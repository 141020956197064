import {
  CLEAR_AMAZON_PAGES_FEATURES,
  EDIT_SYSTEM_PAGE,
  EDIT_SYSTEM_PAGE_ERROR,
  EDIT_SYSTEM_PAGE_SUCCESS,
  FETCH_AMAZON_PAGES,
  FETCH_AMAZON_PAGES_ERROR,
  FETCH_AMAZON_PAGES_SUCCESS,
  FETCH_SYSTEM_PAGES,
  FETCH_SYSTEM_PAGES_ERROR,
  FETCH_SYSTEM_PAGES_SUCCESS,
  SET_AMAZON_PAGES_PAGE,
  SET_AMAZON_PAGES_SEARCH,
  SET_AMAZON_PAGES_SIZE_PER_PAGE,
  SYSTEM_PAGE_DELETE,
  SYSTEM_PAGE_DELETE_ERROR,
  SYSTEM_PAGE_DELETE_SUCCESS,
} from "./actionTypes";

const initialState = {
  pages: [],
  loadingPages: false,
  loadingPageButton: false,
  amazonPages: [],
  loadingAmazonPages: false,
  search: "",
  sizePerPage: 25,
  page: 1,
  totalSize: 0,
  loadingDeletePages: false,
};

const systemPagesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_SYSTEM_PAGES:
      return {
        ...state,
        loadingPages: true,
      };
    case FETCH_SYSTEM_PAGES_SUCCESS:
      return {
        ...state,
        loadingPages: false,
        pages: payload,
      };
    case FETCH_SYSTEM_PAGES_ERROR:
      return {
        ...state,
        loadingPages: false,
      };
    case EDIT_SYSTEM_PAGE:
      return {
        ...state,
        loadingPageButton: true,
      };
    case EDIT_SYSTEM_PAGE_SUCCESS:
      return {
        ...state,
        loadingPageButton: false,
      };
    case EDIT_SYSTEM_PAGE_ERROR:
      return {
        ...state,
        loadingPageButton: false,
      };
    case FETCH_AMAZON_PAGES:
      return {
        ...state,
        loadingAmazonPages: true,
      };
    case FETCH_AMAZON_PAGES_SUCCESS:
      return {
        ...state,
        amazonPages: payload.rows,
        loadingAmazonPages: false,
        totalSize: payload.count,
      };
    case FETCH_AMAZON_PAGES_ERROR:
      return {
        ...state,
        loadingAmazonPages: false,
      };
    case SET_AMAZON_PAGES_SEARCH:
      return {
        ...state,
        search: payload,
      };
    case SET_AMAZON_PAGES_PAGE:
      return {
        ...state,
        page: payload,
      };
    case SET_AMAZON_PAGES_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    case CLEAR_AMAZON_PAGES_FEATURES:
      return {
        ...state,
        search: "",
        sizePerPage: 25,
        page: 1,
        totalSize: 0,
      };
    case SYSTEM_PAGE_DELETE:
      return {
        ...state,
        loadingDeletePages: true,
      };
    case SYSTEM_PAGE_DELETE_SUCCESS:
      return {
        ...state,
        loadingDeletePages: false,
      };

    case SYSTEM_PAGE_DELETE_ERROR:
      return {
        ...state,
        loadingDeletePages: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default systemPagesReducer;
