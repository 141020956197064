export const modules = {
  // User
  customer: "Customer",
  staff: "Staff",
  profile: "Profile",
  application: "Application",
  volumeBuilder: "Volume Builder",
  menuPermission: "Menu Permission",
  cloudStorage: "Cloud Storage",
  cloudS3: "Cloud S3",
  redirection: "Redirection",
  pages: "Pages",
};

export const messages = (...args) => ({
  // User
  addCustomer: `New customer ${args[0]} added`,
  addStaff: `New staff ${args[0]} added`,
  editCustomer: `Customer ${args[0]} modified`,
  editStaff: `Staff ${args[0]} modified`,
  activeDeActive: `${args[0]} ${args[1]}`,
  changeUsername: `Username of ${args[0]} changed to ${args[1]}`,
  resetPassword: `Reset password of ${args[0]}`,
  changePassword: `Change password of ${args[0]}`,
  updateBillingAddress: `Modified ${args[0]} billing address`,
  updateShippingAddress: `Modified ${args[0]} shipping address`,
  shareUnlimitedDashboard: `Toggle unlimited shared dashboard of ${args[0]} to ${args[1]}`,
  visitApplication: `Visit ${args[0]} application of ${args[1]}`,
  addSubscription: `Added subscription (#${args[0]}) of ${args[1]}`,
  editSubscription: `Modified subscription (#${args[0]}) of ${args[1]}`,
  addMembership: `Added membership (${args[0]}) of ${args[1]}`,
  editMembership: `Modified membership (${args[0]}) of ${args[1]}`,
  deleteMembership: `Deleted membership (${args[0]}) of ${args[1]}`,
  addPaymentInfo: `Added payment card (ends with ${args[0]}) of ${args[1]}`,
  deletePaymentCard: `Deleted payment card (${args[0]}) of ${args[1]}`,
  // Application

  //25-11-22 Saurav - Activity Log related code
  visitAndGoToDashboard: `Visit ${args[0]} site`,
  applicationTopNavbar: `In application, Menu selected item is ${args[0]}`,
  visitApplicationInApplications: `Visit to ${args[0]}`,
  editApplication: `Modified ${args[0]} application data`,
  addProduct: `Added product (${args[0]})`,
  editProduct: `Modified product (${args[0]}) data`,
  addCoupon: `Added coupon (${args[0]})`,
  editCoupon: `Modified coupon (${args[0]}) data`,
  deleteCoupon: `Deleted coupon (${args[0]})`,
  addApplicationMembership: `Added membership (${args[0]})`,
  editApplicationMembership: `Modified membership (${args[0]}) data`,
  deleteApplicationMembership: `Deleted membership (${args[0]})`,
  addMenu: `Added new ${args[0]} menu (${args[1]})`,
  editMenu: `ModiApplicationfied ${args[0]} menu (${args[1]})`,
  deleteMenu: `Deleted ${args[0]} menu (${args[1]})`,
  editPaymentMethod: `Modified payment method`,
  editEmailService: `Modified email service`,
  editSMSService: `Modified sms service`,
  addTutorial: `Added new tutorial (${args[0]})`,
  editTutorial: `Modified tutorial (${args[0]})`,
  deleteTutorial: `Deleted tutorial (${args[0]})`,
  addFAQ: `Added new FAQ (${args[0]})`,
  editFAQ: `Modified FAQ (${args[0]})`,
  deleteFAQ: `Deleted FAQ (${args[0]})`,
  reOrderFAQ: `Changed FAQ's order`,
  addPolicy: `Added new policy page (${args[0]})`,
  editPolicy: `Modified policy page (${args[0]})`,
  deletePolicy: `Deleted policy page (${args[0]})`,
  editSystemPage: `Modified system page (${args[0]})`,
  editMobileApplication: `Modified mobile application`,
  editTVApplication: `Modified tv application`,
  addGame: `Added new game (${args[0]})`,
  editGame: `Modified game (${args[0]})`,
  deleteGame: `Deleted game (${args[0]})`,
  addHabit: `Added new habit (${args[0]})`,
  editHabit: `Modified habit (${args[0]})`,
  deleteHabit: `Deleted habit (${args[0]})`,

  addHabitCategory: `Added new habit category (${args[0]})`,
  editHabitCategory: `Modified habit category (${args[0]})`,
  deleteHabitCategory: `Deleted habit category (${args[0]})`,

  addIcebreaker: `Added new icebreaker (${args[0]})`,
  editIcebreaker: `Modified icebreaker (${args[0]})`,
  deleteIcebreaker: `Deleted icebreaker (${args[0]})`,
  addFilter: `Added new filter (${args[0]})`,
  editFilter: `Modified filter (${args[0]})`,
  deleteFilter: `Deleted filter (${args[0]})`,
  deleteReview: `Deleted review (${args[0]})`,
  sendNotification: `Sended notification (${args[0]})`,
  editDefaultCountdown: `Modified default countdown`,
  addIntoCountdownTool: `Added (${args[1]}) ${args[0]}`,
  reOrderCountdownTool: `Changed ${args[0]} order`,
  addBanner: `Added new banner`,
  changeDefaultBanner: `Changed default banner to (${args[0]})`,
  deleteBanner: `Deleted banner (${args[0]})`,
  editEmailTemplate: `Modified email template (${args[0]})`,
  editRateConfig: `Modified rate configuration`,
  addAffiliate: `Added affiliate user (${args[0]})`,
  editAffiliate: `Modified affiliate (${args[0]})`,
  deleteAffiliate: `Deleted affiliate (${args[0]})`,
  addReferral: `Added referral for affiliate (affiliate id: ${args[0]})`,
  editReferral: `Modified referral (referral id: ${args[0]})`,
  deleteReferral: `Deleted referral (${args[0]})`,
  markedAsPaid: `Referral (${args[0]}) marked as paid`,
  addPayout: `Added payout for affiliate (affiliate id: ${args[0]})`,
  deletePayout: `Deleted payout (${args[0]})`,

  // Volume Builder
  addSeries: `Added new series (${args[0]})`,
  addPost: `Added new ${args[0]} (${args[1]})`,
  editPost: `Modified ${args[0]} (${args[1]})`,
  addDashboardPage: `Added new dashboard page (${args[0]})`,
  editDashboardPage: `Modified dashboard page (${args[0]})`,
  addTipVideo: `Added new tip video (${args[0]})`,
  editTipVideo: `Modified tip video (${args[0]})`,
  editGeneral: `Modified general data of series id: ${args[0]}`,
  editEmail: `Modified (${args[0]}) email data of series id: ${args[1]}`,
  editMemoryVerse: `Modified memory verse data of series id: ${args[0]}`,
  addSeriesTutorial: `Added new tutorial (${args[1]}) in${args[0]} series id: ${args[2]}`,
  editSeriesTutorial: `Modified tutorial (${args[1]}) in${args[0]} series id: ${args[2]}`,
  deleteSeriesTutorial: `Deleted tutorial (${args[1]}) from${args[0]} series id: ${args[2]}`,
  editDescription: `Modified description of (${args[0]}) dashboard page id: ${args[1]}`,
  editPosts: `Modified ${args[0]} of (${args[1]}) dashboard page id: ${args[2]}`,
  addSlideshow: `Added new slideshow in ${args[0]} week ${args[1]} series id: ${args[2]}`,
  importSlideshow: `Imported slideshow in ${args[0]} week ${args[1]} series id: ${args[2]}`,
  deleteSlideshow: `Deleted slideshow from ${args[0]} week ${args[1]} series id: ${args[2]}`,
  saveSlideshow: `Modified slideshow (${args[0]})`,
  publishSlideshow: `Published slideshow (${args[0]})`,
  saveSlideshowSettings: `Modified slideshow settings (${args[0]})`,
  editSlideshowTitle: `Modified slideshow title to (${args[0]}) of slideshow id: ${args[1]}`,

  //Hub
  addHubCalenderItem: `Added new hub calender item (${args[0]})`,
  editHubCalenderItem: `Modified hub calender item (${args[0]})`,
  deleteHubCalenderItem: `Deleted hub calender item (${args[0]})`,
  addHubAnnouncementItem: `Added new hub announcement item (${args[0]})`,
  editHubAnnouncementItem: `Modified hub announcement item (${args[0]})`,
  deleteHubAnnouncementItem: `Deleted hub announcement item (${args[0]})`,

  //Grow Builder
  addGrowBuilderMessageItem: `Added new message item (${args[0]})`,
  editGrowBuilderMessageItem: `Modified message item (${args[0]})`,
  deleteGrowBuilderMessageItem: `Deleted message item (${args[0]})`,
  exportGrowBuilderMessageItem: `Exported message item (${args[0]})`,
  saveGrowBuilderMessageItem: `Saved message item (${args[0]})`,

  //Grow App Builder
  addGrowAppBuilder: `Added New Volume (${args[0]})`,
  editGrowAppBuilder: `Modified Volume (${args[0]})`,
  deleteGrowAppBuilder: `Deleted Volume (${args[0]})`,
  exportGrowAppBuilder: `Exported Volume (${args[0]})`,
  saveGrowAppBuilder: `Saved Volume (${args[0]})`,

  //Permission Builder
  addPermission: `Added new permission profile (${args[0]})`,
  editPermission: `Modified permission profile (${args[0]})`,
  deletePermission: `Deleted permission profile (${args[0]})`,

  //Redirection
  addRedirection: `Added new redirection (${args[0]})`,
  editRedirection: `Modified redirection (${args[0]})`,
  deleteRedirection: `Deleted redirection (${args[0]})`,

  //Pages
  addAmazonPage: `Added new amazon page (${args[0]})`,
  editAmazonPage: `Modified amazon page (${args[0]})`,
  deleteAmazonPage: `Deleted amazon page (${args[0]})`,
  addApplicationMenu: `Added new Application menu page (${args[0]})`,
  editApplicationMenu: `Modified Application menu page (${args[0]})`,
  deleteApplicationMenu: `Deleted Application menu page (${args[0]})`,

  //Cloud Storage
  addFolderFile: args[0],
  shareFolderFile: args[0],
  unShareFolderFile: args[0],
  deleteFolderFile: args[0],
  restoreFolderFile: args[0],

  //25-11-22 Saurav - Activity Log related code
  //Side menu
  sideMenuRedirection: `Redirect to ${args[0]}`,

  //Custom table
  searchTable: `Searched ${args[0]}`,

  //range
  dateRange: `Changed date range to ${args[0]} - ${args[1]}`,
  timezone: `Changed timezone to ${args[0]}`,
  date: `Changed date to ${args[0]}`,

  filterByApplication: `Filtered By ${args[0]} Application`,
  filterByModule: `Filtered By ${args[0]} Module`,
  filterByCategory: `Filtered By ${args[0]} Category`,
  filterByMinistryType: `Filtered By ${args[0]} type`,
  filterByVolume: `Filtered By ${args[0]} Volume`,
  filterByRole: `Filtered By ${args[0]} Role`,

  openSeriesEditor: `${args[0]} Series Editor opened`,
  openPermissionEditor: `${args[0]} Permission Editor opened`,
  openModal: `${args[0]} - Modal opened`,

  //setting actions

  menuChange: `Menu Changed to ${args[0]}`,
  tabChange: `Tab Changed to ${args[0]}`,
  //customer
  redirectToCustomerOrStaffAccount: `Redirect to ${args[0]} user`,
  redirectToCustomerApplication: `Redirect to ${
    args[0] + " " + args[1]
  } application`,

  //subscription
  redirectToSubscription: `Redirect to #${args[0]} subscription`,
  redirectToMemberShip: `Redirect to ${args[0]} membership`,
  redirectionToNotes: `Redirect to ${args[0]}'s edit subscription`,
  previewInvoice: `Preview the invoice of ${args[0]} number`,
});
