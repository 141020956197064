// Smit 22-11

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_EMAIL_MESSAGE = "LOGIN_EMAIL_MESSAGE";
export const API_ERROR = "LOGIN_API_ERROR";
export const SET_USER = "SET_USER";

export const FETCH_LOGGED_IN_USER_PROFILE = "FETCH_LOGGED_IN_USER_PROFILE";
export const FETCH_LOGGED_IN_USER_PROFILE_SUCCESS =
  "FETCH_LOGGED_IN_USER_PROFILE_SUCCESS";
export const FETCH_LOGGED_IN_USER_PROFILE_ERROR =
  "FETCH_LOGGED_IN_USER_PROFILE_ERROR";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
