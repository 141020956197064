import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADMIN_ACTIVTY_LOG,
  DELETE_FILES,
  EDIT_FILE_NAME,
  EDIT_FILE_NAME_ERROR,
  EDIT_FILE_NAME_LOADING,
  GET_DIR_FILES,
  SHARE_FOLDER,
  STORE_DIR_CLOUD,
} from "./actionTypes";

import { axiosApiAdmin, axiosApiCloud } from "helpers/api_helper";

import Log from "helpers/logger";
import {
  ACTIVITY_LOG_DATA,
  DELETE_FILE_FROM_SERVER,
  FETCH_CLOUD_DIR_DATA,
  REGISTER_CLOUD_LOG,
  SAVE_CLOUD_DIR_DATA,
  SHARE_FOLDER_LINK,
} from "helpers/url_helper";
import { toast } from "react-toastify";
import {
  adminActivityLogError,
  adminActivityLogSuccess,
  deleteFilesError,
  deleteFilesSuccess,
  getDirDataError,
  getDirDataSuccess,
  shareFolderDownloadLinkSuccess,
  shareFolderInstructionLinkSuccess,
  shareFolderLinkError,
  shareFolderPreviewLinkSuccess,
  storeDirDataError,
  storeDirDataSuccess,
} from "./actions";

const saveFileData = data => axiosApiAdmin.post(SAVE_CLOUD_DIR_DATA, data);
const getFileData = data => axiosApiAdmin.post(FETCH_CLOUD_DIR_DATA, data);

// const deleteData = data => axiosApiAdmin.post(DELETE_FILES_DATA, data);
const deleteData = data => axiosApiCloud.post(DELETE_FILE_FROM_SERVER, data);

const registerLog = log => axiosApiAdmin.post(REGISTER_CLOUD_LOG, log);
const shareFolder = data => axiosApiAdmin.post(SHARE_FOLDER_LINK, data);
const adminActivity = data => axiosApiAdmin.post(ACTIVITY_LOG_DATA, data);

function* handleAddFileData({ payload, cb }) {
  try {
    const res = yield call(saveFileData, payload);

    if (res.status && res?.data) {
      let log = {
        request: {},
        response: {},
        activity_type: 1,
        status: 200,
      };
      if (payload.addFile) {
        log.request = JSON.stringify({
          path: payload.title,
          action: "Created",
        });
        log.activity_type = 8;
        log.response = JSON.stringify(res);
        cb(res.data);
        Log.cloudS3().addFolderFile(payload.title);
      }

      yield put(storeDirDataSuccess(res.data.data));
      call(registerLog(log));
    }
  } catch (err) {
    cb({ customError: err });
    yield put(storeDirDataError(err));
  }
}

function* handleEditFileData({ payload, cb }) {
  try {
    const res = yield call(saveFileData, payload);
    if (res.status && res?.data) {
      yield put({ type: EDIT_FILE_NAME, payload: res });
      cb(res.data);
    }
  } catch (err) {
    cb({ customError: err });
    toast.error("something wants wrong");
    yield put({ type: EDIT_FILE_NAME_ERROR, payload: err });
  }
}

function* handleFetchDirData({ payload, cb }) {
  try {
    const res = yield call(getFileData, payload);
    if (res.status && res?.data) {
      yield put(
        getDirDataSuccess({ data: res?.data?.data, Page_No: payload.page_no })
      );
      cb(res.data.data);
    }
  } catch (err) {
    yield put(getDirDataError());
  }
}

function* handleDeleteData({ payload: data, cb }) {
  try {
    const res = yield call(deleteData, {
      id: data?.id,
      is_deleted: data?.is_deleted,
    });
    if (res.status && res.data) {
      let log = {
        request: {},
        response: {},
        activity_type: data.removeFile ? 11 : 13,
        status: 200,
      };
      if (data.removeFile) {
        log.request = JSON.stringify({
          path: data.path,
          action: "Deleted",
        });
        log.response = JSON.stringify(res);
        Log.cloudS3().deleteFolderFile(data.path);
      } else {
        log.request = JSON.stringify({
          path: data.path,
          action: "Restored",
        });
        log.response = JSON.stringify(res);
        Log.cloudS3().restoreFolderFile(data.path);
      }
      yield put(deleteFilesSuccess(res.data));
      cb(res.data);
      call(registerLog(log)).then(res => {});
    }
  } catch (err) {
    yield put(deleteFilesError());
  }
}

function* handleShareFolderLink({ payload, cb }) {
  try {
    const res = yield call(shareFolder, {
      resource_id: payload.resource_id,
      shareFile: payload.shareFile,
    });
    if (res.status && res.data) {
      let log = {
        request: {},
        response: {},
        activity_type: 9,
        status: 200,
      };
      if (payload.shareFile) {
        log.request = JSON.stringify({
          path: payload.link,
          action: "Shared File Or Folder",
        });
        log.response = JSON.stringify(res);
        // Log.cloudS3().addFolderFile(payload.path);
        cb(res.data);
        Log.cloudS3().shareFolderFile(res.data.data.link);
      }
      if (payload.type === "download_link") {
        yield put(shareFolderDownloadLinkSuccess(res.data.data.link));
      } else if (payload.type === "preview_link") {
        yield put(shareFolderPreviewLinkSuccess(res.data.data.link));
      } else {
        yield put(shareFolderInstructionLinkSuccess(res.data.data.link));
      }
      if (payload.shareFile) {
        call(registerLog(log));
      }
    }
  } catch (error) {
    yield put(shareFolderLinkError());
  }
}

function* handleActivityLog({ payload: data, cb }) {
  try {
    const res = yield call(adminActivity, data);
    if (res.data) {
      yield put(
        adminActivityLogSuccess({
          data: res?.data?.data,
          Page_No: data.page_no,
        })
      );
      // cb(res?.data?.data);
    }
  } catch (err) {
    yield put(adminActivityLogError(err.message));
  }
}

function* cloudS3Saga() {
  yield takeEvery(STORE_DIR_CLOUD, handleAddFileData);
  yield takeEvery(GET_DIR_FILES, handleFetchDirData);
  yield takeEvery(DELETE_FILES, handleDeleteData);
  yield takeEvery(SHARE_FOLDER, handleShareFolderLink);
  yield takeEvery(ADMIN_ACTIVTY_LOG, handleActivityLog);
  yield takeEvery(EDIT_FILE_NAME_LOADING, handleEditFileData);
}

export default cloudS3Saga;
