// Va 05 - 01 - 21
import { axiosApiAdmin } from "helpers/api_helper";
import {
  FETCH_INVOICE_URL,
  FETCH_TRANSACTION_LOGS_URL,
} from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchInvoiceDataError,
  fetchInvoiceDataSuccess,
  fetchTransactionLogError,
  fetchTransactionLogSuccess,
  setErrorMessage,
} from "store/actions";
import { FETCH_INVOICE_DATA, FETCH_TRANSACTION_LOG } from "./actionTypes";

const fetchTransaction = data =>
  axiosApiAdmin.post(FETCH_TRANSACTION_LOGS_URL, data);

const fetchInvoice = data => axiosApiAdmin.get(`${FETCH_INVOICE_URL}/${data}`);

function* handleFetchTransaction({ payload }) {
  try {
    const res = yield call(fetchTransaction, payload);
    if (res.status) {
      yield put(fetchTransactionLogSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchTransactionLogError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchInvoice({ payload }) {
  try {
    const res = yield call(fetchInvoice, payload);
    if (res.status) {
      yield put(fetchInvoiceDataSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchInvoiceDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* transactionSaga() {
  yield takeEvery(FETCH_TRANSACTION_LOG, handleFetchTransaction);
  yield takeEvery(FETCH_INVOICE_DATA, handleFetchInvoice);
}

export default transactionSaga;
