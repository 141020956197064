import { dynamicSiteId } from "constants/siteDetails";
import { compareTypes } from "modules/Analytics/common/constants";
import DateRange from "modules/Analytics/common/utils";
import moment from "moment";
import {
  ANALYTICS_COUPON_SET_TZ,
  CLEAR_COUPON_REPORT,
  CLEAR_SEARCH_QUERY_REPORT,
  FETCH_COUPONS_GRAPH_DATA,
  FETCH_COUPONS_GRAPH_DATA_ERROR,
  FETCH_COUPONS_GRAPH_DATA_SUCCESS,
  FETCH_COUPONS_REPORTS,
  FETCH_COUPONS_REPORTS_ERROR,
  FETCH_COUPONS_REPORTS_SUCCESS,
  FETCH_COUPONS_TABLE_DATA,
  FETCH_COUPONS_TABLE_DATA_ERROR,
  FETCH_COUPONS_TABLE_DATA_SUCCESS,
  SET_ANALYTICS_COUPONS_ACTIVE_TAB,
  SET_ANALYTICS_COUPONS_BY,
  SET_ANALYTICS_COUPONS_COMPARE_TYPE,
  SET_ANALYTICS_COUPONS_PAGE,
  SET_ANALYTICS_COUPONS_RANGE_DATE,
  SET_ANALYTICS_COUPONS_SIZE_PER_PAGE,
  SET_ANALYTICS_COUPONS_SORT_FIELD,
  SET_ANALYTICS_COUPONS_SORT_ORDER,
  SET_ANALYTICS_COUPON_CURRENT_SITE,
  SET_SEARCH_COUPON_DATA,
} from "./actionTypes";

const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();
const defaultCompareType = compareTypes.PERIOD;

const reports = {
  discount_orders: {
    value: 0,
    previousPeriodValue: 0,
  },
  amount: {
    value: 0,
    previousPeriodValue: 0,
  },
};

const data = [];

const initialState = {
  loadingReports: false,
  reports: reports,
  tempChartData: {
    cp: [],
    pp: [],
  },
  chartData: data,
  loadingChartData: false,
  activeTab: "discount_orders",
  range: {
    startDate,
    endDate,
  },
  compareToPrevious: defaultCompareType,
  comparedRange: new DateRange(startDate, endDate).getPreviousDates(
    defaultCompareType
  ),
  by: "day",
  tableData: {
    loading: false,
    rows: [],
    totalSize: 0,
    ordersCount: 0,
    page: 1,
    sizePerPage: 25,
    sortOrder: "DESC",
    sortField: "orders",
    serachCoupon: "",
    tableFooter: [],
  },
  siteId: dynamicSiteId.Curriculum,
  tz: "-0400",
};

const change = (key, value, state) => ({
  ...state,
  tableData: {
    ...state.tableData,
    [key]: value,
  },
});

const analyticsCouponsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ANALYTICS_COUPON_SET_TZ:
      return {
        ...state,
        tz: payload,
      };
    case SET_ANALYTICS_COUPON_CURRENT_SITE:
      return {
        ...state,
        siteId: payload,
      };
    case CLEAR_SEARCH_QUERY_REPORT:
      return {
        ...state,
        range: {
          ...state.range,
          startDate,
          endDate,
        },
        tableData: {
          ...state.tableData,
          searchCoupon: "",
        },
      };
    case CLEAR_COUPON_REPORT:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          page: 1,
        },
      };
    case SET_ANALYTICS_COUPONS_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };
    case SET_ANALYTICS_COUPONS_RANGE_DATE:
      const prevBy = state.by;
      const newBy = new DateRange(
        payload.startDate,
        payload.endDate
      ).getByOptions()[0].value;
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
        comparedRange: new DateRange(
          payload.startDate,
          payload.endDate
        ).getPreviousDates(state.compareToPrevious),
        ...(prevBy !== newBy ? { by: newBy } : {}),
      };
    case SET_ANALYTICS_COUPONS_COMPARE_TYPE:
      return {
        ...state,
        compareToPrevious: payload,
        comparedRange: new DateRange(
          state.range.startDate,
          state.range.endDate
        ).getPreviousDates(payload),
      };
    case SET_ANALYTICS_COUPONS_BY:
      return {
        ...state,
        by: payload,
      };

    // TABLE
    case SET_ANALYTICS_COUPONS_PAGE:
      return change("page", payload, state);
    case SET_ANALYTICS_COUPONS_SIZE_PER_PAGE:
      return change("sizePerPage", payload, state);
    case SET_ANALYTICS_COUPONS_SORT_FIELD:
      return change("sortField", payload, state);
    case SET_ANALYTICS_COUPONS_SORT_ORDER:
      return change("sortOrder", payload, state);

    // APIS
    case FETCH_COUPONS_REPORTS:
      return {
        ...state,
        loadingReports: true,
      };
    case FETCH_COUPONS_REPORTS_SUCCESS:
      return {
        ...state,
        reports: payload,
        loadingReports: false,
      };
    case FETCH_COUPONS_REPORTS_ERROR:
      return {
        ...state,
        loadingReports: false,
      };
    case FETCH_COUPONS_GRAPH_DATA:
      return {
        ...state,
        loadingChartData: true,
      };
    case FETCH_COUPONS_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        chartData: payload
          ? payload.map(x => ({
              cp_date_time: x.current.start_date,
              cp: x.current.current_count,
              pp: x.previous.previous_count,
              pp_date_time: x.previous.start_date,
            }))
          : data,
        loadingChartData: false,
      };
    case FETCH_COUPONS_GRAPH_DATA_ERROR:
      return {
        ...state,
        loadingChartData: false,
      };
    case FETCH_COUPONS_TABLE_DATA:
      return change("loading", true, state);
    case FETCH_COUPONS_TABLE_DATA_SUCCESS:
      return {
        ...state,
        tableData: {
          ...state.tableData,
          loading: false,
          rows: payload.rows,
          totalSize: payload.totalSize,
          ordersCount: payload.orderCounts,
          tableFooter: payload.tableFooter,
        },
      };
    case FETCH_COUPONS_TABLE_DATA_ERROR:
      return change("loading", false, state);

    case SET_SEARCH_COUPON_DATA:
      return change("searchCoupon", payload, state);
    default:
      return state;
  }
};

export default analyticsCouponsReducer;
