import { axiosApiAdmin } from "helpers/api_helper";
import {
  GET_RENEWALS_REPORTS_MONTH_DATA,
  GET_RENEWALS_TABLE_MONTH_DATA,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { setErrorMessage } from "store/actions";
import {
  getRenewalSubscriptionTableDataError,
  getRenewalSubscriptionTableDataSuccess,
  getSubscriptionReportsError,
  getSubscriptionReportsSuccess,
} from "./action";
import {
  FETCH_SUBSCRIPTION_REPORTS,
  FETCH_SUBSCRIPTION_TABLE_DATA,
} from "./actionTypes";

const fetchRenewalReports = data =>
  axiosApiAdmin.post(GET_RENEWALS_REPORTS_MONTH_DATA, data);
const fetchRevenueTableData = data =>
  axiosApiAdmin.post(GET_RENEWALS_TABLE_MONTH_DATA, data);

function* handleFetchSubscriptionReports(data) {
  try {
    const { year, month } = yield select(state => state.AnalyticsRenewal);
    const payload = {
      year,
      month,
      ministry_type: parseInt(data?.payload),
    };

    const res = yield call(fetchRenewalReports, payload);
    if (res.status && res.data.data.data != {}) {
      yield put(getSubscriptionReportsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getSubscriptionReportsError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* handleFetchSubscriptionTableData(data) {
  try {
    const {
      tableData: { page, sizePerPage, sortOrder, sortField },
      month,
      year,
    } = yield select(state => state.AnalyticsRenewal);

    const payload = {
      page_record: sizePerPage,
      page_no: page,
      sort_field: sortField,
      sort_order: sortOrder,
      month: month,
      year: year,
      ministry_type: data.payload,
    };

    const res = yield call(fetchRevenueTableData, payload);
    if (res.status) {
      if (res.data?.data) {
        yield put(
          getRenewalSubscriptionTableDataSuccess({
            totalSize: res.data.data.count,
            ordersCount: 0,
            rows: res.data.data.rows,
            cancelled: res.data.data.cancelled_count,
            overdue: res.data.data.over_due_count,
            due: res.data.data.due_count,
            renewed: res.data.data.renewed_count,
          })
        );
      }
    }
  } catch (err) {
    yield put(getRenewalSubscriptionTableDataError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* renewalSaga() {
  yield takeEvery(FETCH_SUBSCRIPTION_REPORTS, handleFetchSubscriptionReports);
  yield takeEvery(
    FETCH_SUBSCRIPTION_TABLE_DATA,
    handleFetchSubscriptionTableData
  );
}

export default renewalSaga;
