import {
  ADD_EDIT_SERIES_EDITOR,
  ADD_EDIT_SERIES_EDITOR_ERROR,
  ADD_EDIT_SERIES_EDITOR_SUCCESS,
  ADD_EDIT_VOLUME_FOLDER,
  ADD_EDIT_VOLUME_FOLDER_ERROR,
  ADD_EDIT_VOLUME_FOLDER_SUCCESS,
  ADD_EDIT_VOLUME_SERIES,
  ADD_EDIT_VOLUME_SERIES_ERROR,
  ADD_EDIT_VOLUME_SERIES_SUCCESS,
  CLEAR_SERIES,
  DELETE_SERIES,
  DELETE_SERIES_ERROR,
  DELETE_SERIES_SUCCESS,
  DELETE_VOLUME_FOLDER,
  DELETE_VOLUME_FOLDER_ERROR,
  DELETE_VOLUME_FOLDER_SUCCESS,
  FETCH_ALL_BOARD_DATA,
  FETCH_SERIES,
  FETCH_SERIES_EDITOR_DETAILS,
  FETCH_SERIES_EDITOR_DETAILS_ERROR,
  FETCH_SERIES_EDITOR_DETAILS_SUCCESS,
  FETCH_SERIES_ERROR,
  FETCH_SERIES_SUCCESS,
  FETCH_TIP_VIDEOS,
  FETCH_TIP_VIDEOS_ERROR,
  FETCH_TIP_VIDEOS_SUCCESS,
  FETCH_VOLUME_BUILDER_FOLDERS,
  FETCH_VOLUME_BUILDER_FOLDER_ERROR,
  FETCH_VOLUME_BUILDER_FOLDER_SUCCESS,
  GET_AMAZON_INTERNALS,
  GET_AMAZON_INTERNALS_ERROR,
  GET_AMAZON_INTERNALS_SUCCESS,
  GET_VOLUME_FOLDER_DETAILS,
  GET_VOLUME_FOLDER_DETAILS_SUCCESS,
  GET_VOLUME_SERIES_DETAILS,
  GET_VOLUME_SERIES_DETAILS_SUCCESS,
  SAVE_AMAZON_INTERNALS,
  SAVE_AMAZON_INTERNALS_ERROR,
  SAVE_AMAZON_INTERNALS_SUCCESS,
  SET_ACTIVE_TAB_ID,
  SET_CATEGORY_VBS_ID,
  SET_IS_VBS,
  SET_SEARCH_SERIES,
  SET_TIP_VIDEO_SEARCH,
  SET_TYPES,
} from "./actionTypes";
// import { useParams } from "react-router-dom"

const initialState = {
  loadingFolders: false,
  volumeFolder: [],
  volumeFolderDetails: null,
  volumeSeriesDetails: null,
  types: {
    catActiveTab: null,
    MinistryType: 1,
  },
  loading: false,
  loadingAddEditVolumeButton: false,
  loadingAddEditVolumeSeriesButton: false,
  seriesEditorDetails: null,
  loadingSeriesDetails: false,
  series: [],
  search: "",
  isVBS: false,
  tipVideos: [],
  allBoardData: {},
  loadingTipVideo: false,
  tipVideoSearch: "",
  loadingGeneralSave: false,
  loadingAmazonSave: false,
  amazonInternalData: [],
  loadingAmazonData: false,
  activeTab: 1,
};

const VolumeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_VOLUME_BUILDER_FOLDERS:
      return {
        ...state,
        loadingFolders: true,
      };
    case SET_IS_VBS:
      return {
        ...state,
        isVBS: payload,
      };
    case SET_TYPES:
      return {
        ...state,
        types: {
          ...state.types,
          ...payload,
        },
      };
    case SET_CATEGORY_VBS_ID:
      return {
        ...state,
        types: {
          ...state.types,
          catActiveTab: payload.category_id,
          MinistryType: payload.ministry_type,
        },
      };
    case SET_ACTIVE_TAB_ID:
      return {
        ...state,
        activeTab: payload,
      };
    case FETCH_VOLUME_BUILDER_FOLDER_SUCCESS:
      return {
        ...state,
        volumeFolder: payload.volumes,
        loadingFolders: false,
        types: {
          ...state.types,
          catActiveTab: payload.category_id,
          MinistryType: payload.ministry_type,
        },
      };
    case FETCH_VOLUME_BUILDER_FOLDER_ERROR:
      return {
        ...state,
        loadingFolders: false,
      };
    case ADD_EDIT_VOLUME_FOLDER:
      return {
        ...state,
        loadingAddEditVolumeButton: true,
      };
    case ADD_EDIT_VOLUME_FOLDER_SUCCESS:
      return {
        ...state,
        loadingAddEditVolumeButton: false,
      };
    case ADD_EDIT_VOLUME_FOLDER_ERROR:
      return {
        ...state,
        loadingAddEditVolumeButton: false,
      };
    case GET_VOLUME_FOLDER_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case GET_VOLUME_FOLDER_DETAILS_SUCCESS:
      return {
        ...state,
        volumeFolderDetails: payload.volume,
        loading: false,
      };
    case DELETE_VOLUME_FOLDER:
      return {
        ...state,
        loading: true,
      };
    case DELETE_VOLUME_FOLDER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_VOLUME_FOLDER_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_SERIES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_SERIES_SUCCESS:
      return {
        ...state,
        series: payload.data.series,
        loading: false,
      };
    case FETCH_SERIES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SET_SEARCH_SERIES:
      return {
        ...state,
        search: payload,
      };

    case ADD_EDIT_VOLUME_SERIES:
      return {
        ...state,
        loadingAddEditVolumeSeriesButton: true,
      };
    case ADD_EDIT_VOLUME_SERIES_SUCCESS:
      return {
        ...state,
        loadingAddEditVolumeSeriesButton: false,
      };
    case ADD_EDIT_VOLUME_SERIES_ERROR:
      return {
        ...state,
        loadingAddEditVolumeSeriesButton: false,
      };

    case GET_VOLUME_SERIES_DETAILS:
      return {
        ...state,
        loadingSeriesDetails: true,
      };
    case GET_VOLUME_SERIES_DETAILS_SUCCESS:
      return {
        ...state,
        volumeSeriesDetails: payload.series,
        loadingSeriesDetails: false,
      };
    case DELETE_SERIES:
      return {
        ...state,
        loadingSeriesDetails: true,
      };
    case DELETE_SERIES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_SERIES_ERROR:
      return {
        ...state,
        loading: false,
      };
    /** Series Editor */
    case FETCH_SERIES_EDITOR_DETAILS:
      return {
        ...state,
        loadingSeriesDetails: true,
      };
    case FETCH_SERIES_EDITOR_DETAILS_SUCCESS:
      return {
        ...state,
        seriesEditorDetails: payload.data,
        loadingSeriesDetails: false,
      };
    case FETCH_SERIES_EDITOR_DETAILS_ERROR:
      return {
        ...state,
        loadingSeriesDetails: false,
      };

    case ADD_EDIT_SERIES_EDITOR:
      return {
        ...state,
        loadingGeneralSave: true,
      };
    case ADD_EDIT_SERIES_EDITOR_SUCCESS:
      return {
        ...state,
        loadingGeneralSave: false,
      };
    case ADD_EDIT_SERIES_EDITOR_ERROR:
      return {
        ...state,
        loadingGeneralSave: false,
      };
    case SAVE_AMAZON_INTERNALS:
      return {
        ...state,
        loadingAmazonSave: true,
      };
    case SAVE_AMAZON_INTERNALS_SUCCESS:
      return {
        ...state,
        loadingAmazonSave: false,
      };
    case SAVE_AMAZON_INTERNALS_ERROR:
      return {
        ...state,
        loadingAmazonSave: false,
      };
    case GET_AMAZON_INTERNALS:
      return {
        ...state,
        loadingAmazonData: true,
      };
    case GET_AMAZON_INTERNALS_SUCCESS:
      return {
        ...state,
        amazonInternalData: payload.amazon_data,
        loadingAmazonData: false,
      };
    case GET_AMAZON_INTERNALS_ERROR:
      return {
        ...state,
        loadingAmazonData: false,
      };
    case CLEAR_SERIES:
      return {
        ...state,
        series: [],
      };
    case FETCH_TIP_VIDEOS:
      return {
        ...state,
        loadingTipVideo: true,
      };
    case FETCH_TIP_VIDEOS_SUCCESS:
      return {
        ...state,
        loadingTipVideo: false,
        tipVideos: payload,
      };
    case FETCH_ALL_BOARD_DATA:
      return {
        ...state,
        allBoardData: payload,
      };
    case FETCH_TIP_VIDEOS_ERROR:
      return {
        ...state,
        loadingTipVideo: false,
      };
    case SET_TIP_VIDEO_SEARCH:
      return {
        ...state,
        tipVideoSearch: payload,
      };

    default:
      return state;
  }
};

export default VolumeReducer;
