import {
  ADD_REMOVE_FILES,
  GET_CS_USER,
  GET_CS_USER_SUCCESS,
  GET_FILES_LIST,
  GET_FILES_SUCCESS,
  GET_SHARED_FILES,
  GET_SHARED_FILES_SUCCESS,
  REMOVE_SHARE_FILE,
} from "./actionTypes";

const init = {
  loading: false,
  cloudStorageFiles: [],
  cloudStorageUser: [],
  cloudStorageShares: [],
};

const CloudStorage = (state = init, actions) => {
  const { type, payload } = actions;
  switch (type) {
    case GET_CS_USER:
      return {
        ...state,
      };
    case GET_CS_USER_SUCCESS:
      return {
        ...state,
        cloudStorageUser: payload,
      };
    case GET_SHARED_FILES:
      return {
        ...state,
      };
    case REMOVE_SHARE_FILE:
      return {
        ...state,
      };
    case GET_SHARED_FILES_SUCCESS:
      return {
        ...state,
        cloudStorageShares: payload,
      };
    case GET_FILES_SUCCESS:
      return {
        ...state,
        cloudStorageFiles: payload,
        loading: true,
      };

    case GET_FILES_LIST:
      return {
        ...state,
        cloudStorageFilesList: payload,
      };
    case ADD_REMOVE_FILES:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default CloudStorage;
