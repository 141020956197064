export const FETCH_REDIRECTION_LIST = "FETCH_REDIRECTION_LIST";
export const FETCH_REDIRECTION_LIST_SUCCESS = "FETCH_REDIRECTION_LIST_SUCCESS";
export const FETCH_REDIRECTION_LIST_ERROR = "FETCH_REDIRECTION_LIST_ERROR";

export const SET_REDIRECTION_PAGE = "SET_REDIRECTION_PAGE";
export const SET_REDIRECTION_SIZE_PER_PAGE = "SET_REDIRECTION_SIZE_PER_PAGE";
export const SET_REDIRECTION_SEARCH = "SET_REDIRECTION_SEARCH";
export const SET_REDIRECTION_FILTER = "SET_REDIRECTION_FILTER";
export const CLEAR_REDIRECTION_FEATURES = "CLEAR_REDIRECTION_FEATURES";

export const ADD_UPDATE_REDIRECTION = "ADD_UPDATE_REDIRECTION";
export const ADD_UPDATE_REDIRECTION_ERROR = "ADD_UPDATE_REDIRECTION_ERROR";
export const ADD_UPDATE_REDIRECTION_SUCCESS = "ADD_UPDATE_REDIRECTION_SUCCESS";

export const SET_STATUS_REDIRECTION_LINK = "SET_STATUS_REDIRECTION_LINK";
export const SET_STATUS_REDIRECTION_LINK_SUCCESS =
  "SET_STATUS_REDIRECTION_LINK_SUCCESS";

export const DELETE_REDIRECTION_LINK = "DELETE_REDIRECTION_LINK";

/** get redirect folder list */
export const FETCH_REDIRECT_FOLDER_LIST = "FETCH_REDIRECT_FOLDER_LIST";
export const FETCH_REDIRECT_FOLDER_LIST_SUCCESS =
  "FETCH_REDIRECT_FOLDER_LIST_SUCCESS";
export const FETCH_REDIRECT_FOLDER_LIST_ERROR =
  "FETCH_REDIRECT_FOLDER_LIST_ERROR";

export const CREATE_REDIRECT_FOLDER_LIST = "CREATE_REDIRECT_FOLDER_LIST";
export const CREATE_REDIRECT_FOLDER_LIST_SUCCESS =
  "CREATE_REDIRECT_FOLDER_LIST_SUCCESS";
export const CREATE_REDIRECT_FOLDER_LIST_ERROR =
  "CREATE_REDIRECT_FOLDER_LIST_ERROR";

export const DELETE_REDIRECT_FOLDER_LIST = "DELETE_REDIRECT_FOLDER_LIST";
export const DELETE_REDIRECT_FOLDER_LIST_SUCCESS =
  "DELETE_REDIRECT_FOLDER_LIST_SUCCESS";
export const DELETE_REDIRECT_FOLDER_LIST_ERROR =
  "DELETE_REDIRECT_FOLDER_LIST_ERROR";

export const ADD_UPDATE_REDIRECTION_FOLDER = "ADD_UPDATE_REDIRECTION_FOLDER";
export const ADD_UPDATE_REDIRECTION_FOLDER_ERROR =
  "ADD_UPDATE_REDIRECTION_FOLDER_ERROR";
export const ADD_UPDATE_REDIRECTION_FOLDER_SUCCESS =
  "ADD_UPDATE_REDIRECTION_FOLDER_SUCCESS";
