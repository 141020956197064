// Va 05 - 01 - 21

import {
  CLEAR_INVOICE,
  CLEAR_TRANSACTION,
  FETCH_INVOICE_DATA,
  FETCH_INVOICE_DATA_ERROR,
  FETCH_INVOICE_DATA_SUCCESS,
  FETCH_TRANSACTION_LOG,
  FETCH_TRANSACTION_LOG_ERROR,
  FETCH_TRANSACTION_LOG_SUCCESS,
} from "./actionTypes";

export const fetchTransactionLog = data => ({
  type: FETCH_TRANSACTION_LOG,
  payload: data,
});

export const fetchTransactionLogSuccess = data => ({
  type: FETCH_TRANSACTION_LOG_SUCCESS,
  payload: data,
});

export const fetchTransactionLogError = payload => ({
  type: FETCH_TRANSACTION_LOG_ERROR,
  payload,
});

export const fetchInvoiceData = data => ({
  type: FETCH_INVOICE_DATA,
  payload: data,
});

export const fetchInvoiceDataSuccess = data => ({
  type: FETCH_INVOICE_DATA_SUCCESS,
  payload: data,
});

export const fetchInvoiceDataError = payload => ({
  type: FETCH_INVOICE_DATA_ERROR,
  payload,
});

export const clearTransaction = () => ({
  type: CLEAR_TRANSACTION,
});
export const clearInvoice = () => ({
  type: CLEAR_INVOICE,
});
