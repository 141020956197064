import { axiosApiAdmin } from "helpers/api_helper";
import {
  GET_SUPPORT_TICKET,
  LIST_SUPPORT_TICKET_NOTES,
} from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchSupportTicketError,
  fetchSupportTicketNotesError,
  fetchSupportTicketNotesSuccess,
  fetchSupportTicketSuccess,
  setErrorMessage,
} from "store/actions";
import { FETCH_SUPPORT_TICKET, FETCH_SUPPORT_TICKET_NOTE } from "./actionTypes";
const fetchSupportTicketData = data =>
  axiosApiAdmin.post(GET_SUPPORT_TICKET, { id: data.payload.id });
const fetchSupportTicketNotes = data =>
  axiosApiAdmin.post(LIST_SUPPORT_TICKET_NOTES, data);
function* handleFetchSupporTicket(data) {
  try {
    const res = yield call(fetchSupportTicketData, data);
    if (res.data?.data?.data) {
      yield put(fetchSupportTicketSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(fetchSupportTicketError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleFetchSupportNotes(data) {
  try {
    const res = yield call(fetchSupportTicketNotes, data.payload);
    if (res.data?.data?.data) {
      yield put(fetchSupportTicketNotesSuccess(res.data.data.data.rows));
    }
  } catch (err) {
    yield put(fetchSupportTicketNotesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
export default function* SupportPagesSaga() {
  yield takeEvery(FETCH_SUPPORT_TICKET_NOTE, handleFetchSupportNotes);
  yield takeEvery(FETCH_SUPPORT_TICKET, handleFetchSupporTicket);
}
