// Va 05 - 01 - 21
import { axiosApiAdmin } from "helpers/api_helper";
import { LIST_LOGIN_LOGS } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchLoginLogError,
  fetchLoginLogSuccess,
  setErrorMessage,
} from "store/actions";
import { FETCH_LOGIN_LOGS } from "./actionTypes";

const fetchLoginLogs = data => axiosApiAdmin.post(LIST_LOGIN_LOGS, data);

function* handleFetchLoginLogs({ payload }) {
  try {
    const res = yield call(fetchLoginLogs, payload);
    if (res?.data?.data?.rows) {
      yield put(fetchLoginLogSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchLoginLogError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* loginLogsSaga() {
  yield takeEvery(FETCH_LOGIN_LOGS, handleFetchLoginLogs);
}

export default loginLogsSaga;
