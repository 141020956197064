//KI-09-12-21
import {
  ADD_CATEGORY,
  ADD_CATEGORY_ERROR,
  ADD_CATEGORY_SUCCESS,
  DELETE_CATEGORY,
  DELETE_CATEGORY_ERROR,
  DELETE_CATEGORY_SUCCESS,
  EDIT_CATEGORY,
  EDIT_CATEGORY_ERROR,
  EDIT_CATEGORY_SUCCESS,
  FETCH_HABIT_CATEGORY,
  FETCH_HABIT_CATEGORY_ERROR,
  FETCH_HABIT_CATEGORY_SUCCESS,
  FETCH_LINKS,
  FETCH_LINKS_ERROR,
  FETCH_LINKS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  categoryList: [],
  iconList: [],
  sortField: "title",
  sortOrder: "asc",
};

const categoryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: [...state.categoryList, payload],
      };

    case ADD_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case EDIT_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: [...state.categoryList, payload],
      };

    case EDIT_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case FETCH_HABIT_CATEGORY:
      return {
        ...state,
        loading: true,
      };

    case FETCH_HABIT_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: payload.rows,
        loading: false,
      };

    case FETCH_HABIT_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    case FETCH_LINKS:
      return {
        ...state,
        loading: true,
      };

    case FETCH_LINKS_SUCCESS:
      return {
        ...state,
        iconList: payload,
        loading: false,
      };

    case FETCH_LINKS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_CATEGORY:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default categoryReducer;
