import {
  CLEAR_EMAIL_FILTER,
  CLEAR_EMAIL_REPORT,
  FETCH_EMAIL_LOG_DATA,
  FETCH_EMAIL_LOG_DATA_ERROR,
  FETCH_EMAIL_LOG_DATA_SUCCESS,
  SET_EMAIL_LOG_RANGE_DATE,
  SET_EMAIL_LOG_RANGE_DATE_FILTER,
  SET_EMAIL_LOG_RANGE_DATE_PAGE,
  SET_EMAIL_LOG_RANGE_DATE_SIZE_PER_PAGE,
  SET_EMAIL_LOG_SEARCH,
  SET_EMAIL_LOG_SORT_FIELD,
  SET_EMAIL_LOG_SORT_ORDER,
} from "./actionTypes";

// table data
export const getEmailLogDataReport = data => ({
  type: FETCH_EMAIL_LOG_DATA,
  payload: data,
});

export const getEmailLogDataReportSuccess = data => ({
  type: FETCH_EMAIL_LOG_DATA_SUCCESS,
  payload: data,
});

export const getEmailLogDataReportError = data => ({
  type: FETCH_EMAIL_LOG_DATA_ERROR,
});

//date selection
export const setEmailLogRangeDate = data => ({
  type: SET_EMAIL_LOG_RANGE_DATE,
  payload: data,
});

//page
export const setEmailLogRangeDatePage = data => ({
  type: SET_EMAIL_LOG_RANGE_DATE_PAGE,
  payload: data,
});

//page record
export const setEmailLogRangeDateSizePerPage = data => ({
  type: SET_EMAIL_LOG_RANGE_DATE_SIZE_PER_PAGE,
  payload: data,
});

//sorting
export const setEmailLogSortField = data => ({
  type: SET_EMAIL_LOG_SORT_FIELD,
  payload: data,
});

export const setEmailLogSortOrder = data => ({
  type: SET_EMAIL_LOG_SORT_ORDER,
  payload: data,
});

//search
export const setEmailLogSearch = data => {
  return {
    type: SET_EMAIL_LOG_SEARCH,
    payload: data,
  };
};

//filter
export const setEmailLogFilter = data => ({
  type: SET_EMAIL_LOG_RANGE_DATE_FILTER,
  payload: data,
});

export const clearEmailReport = () => ({
  type: CLEAR_EMAIL_REPORT,
});

export const clearEmailFilter = () => ({
  type: CLEAR_EMAIL_FILTER,
});
