import {
  FETCH_CAMPAIGN_REPORTS,
  FETCH_CAMPAIGN_REPORTS_ERROR,
  FETCH_CAMPAIGN_REPORTS_SUCCESS,
} from "./actionTypes";

export const getCampaignData = data => ({
  type: FETCH_CAMPAIGN_REPORTS,
  payload: data,
});

export const getCampaignDataSuccess = data => ({
  type: FETCH_CAMPAIGN_REPORTS_SUCCESS,
  payload: data,
});

export const getCampaignDataError = () => ({
  type: FETCH_CAMPAIGN_REPORTS_ERROR,
});
