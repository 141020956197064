import {
  CLEAR_AMAZON_PAGES_FEATURES,
  EDIT_SYSTEM_PAGE,
  EDIT_SYSTEM_PAGE_ERROR,
  EDIT_SYSTEM_PAGE_SUCCESS,
  FETCH_AMAZON_PAGES,
  FETCH_AMAZON_PAGES_ERROR,
  FETCH_AMAZON_PAGES_SUCCESS,
  FETCH_SYSTEM_PAGES,
  FETCH_SYSTEM_PAGES_ERROR,
  FETCH_SYSTEM_PAGES_SUCCESS,
  SET_AMAZON_PAGES_PAGE,
  SET_AMAZON_PAGES_SEARCH,
  SET_AMAZON_PAGES_SIZE_PER_PAGE,
  SYSTEM_PAGE_DELETE,
  SYSTEM_PAGE_DELETE_ERROR,
  SYSTEM_PAGE_DELETE_SUCCESS,
} from "./actionTypes";

export const fetchSystemPages = data => ({
  type: FETCH_SYSTEM_PAGES,
  payload: data,
});
export const fetchSystemPagesSuccess = data => ({
  type: FETCH_SYSTEM_PAGES_SUCCESS,
  payload: data,
});
export const fetchSystemPagesError = () => ({
  type: FETCH_SYSTEM_PAGES_ERROR,
});

export const editSystemPage = data => ({
  type: EDIT_SYSTEM_PAGE,
  payload: data,
});
export const editSystemPageSuccess = () => ({
  type: EDIT_SYSTEM_PAGE_SUCCESS,
});
export const editSystemPageError = () => ({
  type: EDIT_SYSTEM_PAGE_ERROR,
});

export const fetchAmazonPages = data => ({
  type: FETCH_AMAZON_PAGES,
  payload: data,
});
export const fetchAmazonPagesSuccess = data => ({
  type: FETCH_AMAZON_PAGES_SUCCESS,
  payload: data,
});
export const fetchAmazonPagesError = () => ({
  type: FETCH_AMAZON_PAGES_ERROR,
});

export const setAmazonPagesSearch = data => ({
  type: SET_AMAZON_PAGES_SEARCH,
  payload: data,
});
export const setAmazonPagesPage = data => ({
  type: SET_AMAZON_PAGES_PAGE,
  payload: data,
});
export const clearAmazonPagesFeatures = () => ({
  type: CLEAR_AMAZON_PAGES_FEATURES,
});

export const setAmazonPagesSizePerPage = data => ({
  type: SET_AMAZON_PAGES_SIZE_PER_PAGE,
  payload: data,
});

export const systemPageDelete = data => ({
  type: SYSTEM_PAGE_DELETE,
  payload: data,
});

export const systemPageDeleteSuccess = data => ({
  type: SYSTEM_PAGE_DELETE_SUCCESS,
  payload: data,
});

export const systemPageDeleteError = data => ({
  type: SYSTEM_PAGE_DELETE_ERROR,
  payload: data,
});
