export const FETCH_DISPUTED_TRANSACTIONS = "FETCH_DISPUTED_TRANSACTIONS";
export const FETCH_DISPUTED_TRANSACTIONS_SUCCESS =
  "FETCH_DISPUTED_TRANSACTIONS_SUCCESS";
export const FETCH_DISPUTED_TRANSACTIONS_ERROR =
  "FETCH_DISPUTED_TRANSACTIONS_ERROR";

export const CLEAR_FAIL_SUBSCRIPTION = "CLEAR_FAIL_SUBSCRIPTION";

export const SET_PAGE_DISPUTED_TRANSACTIONS = "SET_PAGE_DISPUTED_TRANSACTIONS";

export const SET_SIZE_PER_PAGE_DISPUTED_TRANSACTIONS =
  "SET_SIZE_PER_PAGE_DISPUTED_TRANSACTIONS";

export const SET_SEARCH_DISPUTED_TRANSACTIONS =
  "SET_SEARCH_DISPUTED_TRANSACTIONS";

export const SET_SORT_FIELD_DISPUTED_TRANSACTIONS =
  "SET_SORT_FIELD_DISPUTED_TRANSACTIONS";

export const SET_SORT_ORDER_DISPUTED_TRANSACTIONS =
  "SET_SORT_ORDER_DISPUTED_TRANSACTIONS";

export const SET_ORDER_RANGE_DATE_DISPUTED_TRANSACTIONS =
  "SET_ORDER_RANGE_DATE_DISPUTED_TRANSACTIONS";

export const VERIFY_EVIDENCE = "VERIFY_EVIDENCE";
