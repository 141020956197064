import {
  ADMIN_ACTIVTY_LOG,
  ADMIN_ACTIVTY_LOG_ERROR,
  ADMIN_ACTIVTY_LOG_SUCCESS,
  CLEAR_CLOUD_PAGE,
  DELETE_FILES,
  DELETE_FILES_ERROR,
  DELETE_FILES_SUCCESS,
  EDIT_FILE_NAME_LOADING,
  GET_DIR_FILES,
  GET_DIR_FILES_ERROR,
  GET_DIR_FILES_SUCCESS,
  SET_CLOUD_PAGE,
  SET_CLOUD_SIZE_PER_PAGE,
  SET_SEARCH_CLOUD_PAGE,
  SET_SELECTED_IDS,
  SHARE_FOLDER,
  SHARE_FOLDER_DOWNLOAD_SUCCESS,
  SHARE_FOLDER_ERROR,
  SHARE_FOLDER_INSTRUCTION_SUCCESS,
  SHARE_FOLDER_PREVIEW_SUCCESS,
  SORT_CLOUD_PAGE,
  SORT_ORDER_CLOUD_SIZE_PER_PAGE,
  STORE_DIR_CLOUD,
  STORE_DIR_CLOUD_ERROR,
  STORE_DIR_CLOUD_SUCCESS,
  UPDATE_DOWNLOAD_STATUS,
} from "./actionTypes";

export const storeDirData = (data, cb) => ({
  type: STORE_DIR_CLOUD,
  payload: data,
  cb,
});

export const clearCloudPage = data => ({
  type: CLEAR_CLOUD_PAGE,
});

export const setSearchCloudPage = data => ({
  type: SET_SEARCH_CLOUD_PAGE,
  payload: data,
});

export const setCloudPage = data => ({
  type: SET_CLOUD_PAGE,
  payload: data,
});

export const setCloudSizePerPage = data => ({
  type: SET_CLOUD_SIZE_PER_PAGE,
  payload: data,
});

export const setSortTitleCloudPage = data => ({
  type: SORT_CLOUD_PAGE,
  payload: data,
});

export const setSortOrderCloudSizePerPage = data => ({
  type: SORT_ORDER_CLOUD_SIZE_PER_PAGE,
  payload: data,
});

export const storeDirDataSuccess = data => ({
  type: STORE_DIR_CLOUD_SUCCESS,
  payload: data,
});

export const storeDirDataError = data => ({
  type: STORE_DIR_CLOUD_ERROR,
});

export const getDirData = (data, cb) => ({
  type: GET_DIR_FILES,
  payload: data,
  cb,
});

export const getDirDataSuccess = data => ({
  type: GET_DIR_FILES_SUCCESS,
  payload: data,
});

export const getDirDataError = data => ({
  type: GET_DIR_FILES_ERROR,
});

//Delete

export const deleteFiles = (data, cb) => ({
  type: DELETE_FILES,
  payload: data,
  cb,
});
export const deleteFilesSuccess = (data, cb) => ({
  type: DELETE_FILES_SUCCESS,
  payload: data,
  cb,
});
export const deleteFilesError = message => ({
  type: DELETE_FILES_ERROR,
  payload: message,
});

//SHARE LINK

export const shareFolderLink = (data, cb) => ({
  type: SHARE_FOLDER,
  payload: data,
  cb: cb,
});
export const shareFolderDownloadLinkSuccess = data => ({
  type: SHARE_FOLDER_DOWNLOAD_SUCCESS,
  payload: data,
});
export const shareFolderPreviewLinkSuccess = data => ({
  type: SHARE_FOLDER_PREVIEW_SUCCESS,
  payload: data,
});
export const shareFolderInstructionLinkSuccess = data => ({
  type: SHARE_FOLDER_INSTRUCTION_SUCCESS,
  payload: data,
});
export const shareFolderLinkError = message => ({
  type: SHARE_FOLDER_ERROR,
  payload: message,
});
export const adminActivityLog = (data, cb) => ({
  type: ADMIN_ACTIVTY_LOG,
  payload: data,
  cb,
});
export const adminActivityLogSuccess = (data, cb) => ({
  type: ADMIN_ACTIVTY_LOG_SUCCESS,
  payload: data,
  cb,
});
export const adminActivityLogError = message => ({
  type: ADMIN_ACTIVTY_LOG_ERROR,
  payload: message,
});

export const handleSelectedId = data => ({
  type: SET_SELECTED_IDS,
  payload: data,
});

export const updateDownloadStatus = data => ({
  type: UPDATE_DOWNLOAD_STATUS,
  payload: data,
});

export const editFileName = (data, cb) => ({
  type: EDIT_FILE_NAME_LOADING,
  payload: data,
  cb,
});
