import { axiosApiAdmin } from "helpers/api_helper";
import {
  LIST_ANALYTICS_REGISTER_USERS_GRAPH_DATA,
  LIST_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA,
  LIST_ANALYTICS_USERS_COUNTS,
  LIST_ANALYTICS_USERS_DATA,
  LIST_ANALYTICS_USERS_GRAPH_DATA,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  getAnalyticsRegisterGraphDataError,
  getAnalyticsRegisterUserGraphDataSuccess,
  getAnalyticsSubscribedUserGraphDataError,
  getAnalyticsSubscribedUserGraphDataSuccess,
  getAnalyticsUserCountError,
  getAnalyticsUserCountSuccess,
  getAnalyticsUserDataError,
  getAnalyticsUserDataSuccess,
  getAnalyticsUserGraphDataError,
  getAnalyticsUserGraphDataSuccess,
  setErrorMessage,
} from "store/actions";
import {
  GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA,
  GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA,
  GET_ANALYTICS_USERS_GRAPH_DATA,
  GET_ANALYTICS_USER_COUNT,
  GET_ANALYTICS_USER_DATA,
} from "./actionTypes";

import { tzs } from "modules/Analytics/common/constants";
import { DateTimeTz } from "modules/Analytics/common/utils";

const fetchAnalyticsUsersCount = payload =>
  axiosApiAdmin.post(LIST_ANALYTICS_USERS_COUNTS, payload);
const fetchAnanlyticsUsersGraphData = payload =>
  axiosApiAdmin.post(LIST_ANALYTICS_USERS_GRAPH_DATA, payload);
const fetchAnanlyticsSubscribedUsersGraphData = payload =>
  axiosApiAdmin.post(LIST_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA, payload);
const fetchAnalyticsUsersData = payload =>
  axiosApiAdmin.post(LIST_ANALYTICS_USERS_DATA, payload);

const fetchAnalyticsRegisterUsersGraphData = payload =>
  axiosApiAdmin.post(LIST_ANALYTICS_REGISTER_USERS_GRAPH_DATA, payload);

function* handleAnalyticsRegisterUsersGraphData({ payload }) {
  try {
    const res = yield call(fetchAnalyticsRegisterUsersGraphData, payload);
    if (res.status && res?.data?.data) {
      yield put(getAnalyticsRegisterUserGraphDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getAnalyticsRegisterGraphDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAnalyticsUsersData({ payload }) {
  try {
    const res = yield call(fetchAnalyticsUsersData, payload);
    if (res.status && res?.data?.data) {
      yield put(getAnalyticsUserDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getAnalyticsUserDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAnalyticsSubscribedUsersGraphData({ payload }) {
  try {
    const res = yield call(fetchAnanlyticsSubscribedUsersGraphData, payload);
    if (res.status && res?.data?.data) {
      yield put(getAnalyticsSubscribedUserGraphDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getAnalyticsSubscribedUserGraphDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAnalyticsUsersGraphData({ payload }) {
  try {
    const res = yield call(fetchAnanlyticsUsersGraphData, payload);
    if (res.status && res?.data?.data) {
      yield put(getAnalyticsUserGraphDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getAnalyticsUserGraphDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleAnalyticsUsersCount() {
  try {
    const { range, comparedRange, siteId, tz } = yield select(
      state => state.AnalyticsUsers
    );

    const payload = {
      current_year_date_range: {
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(range.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      past_year_date_range: {
        start_date: DateTimeTz(comparedRange.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(comparedRange.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      site_id: siteId,
      tz: tzs[tz],
    };
    const res = yield call(fetchAnalyticsUsersCount, payload);
    if (res.status && res?.data?.data) {
      yield put(getAnalyticsUserCountSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getAnalyticsUserCountError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* AnalyticsUsersSaga() {
  yield takeEvery(GET_ANALYTICS_USER_COUNT, handleAnalyticsUsersCount);
  yield takeEvery(
    GET_ANALYTICS_USERS_GRAPH_DATA,
    handleAnalyticsUsersGraphData
  );
  yield takeEvery(
    GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA,
    handleAnalyticsSubscribedUsersGraphData
  );
  yield takeEvery(GET_ANALYTICS_USER_DATA, handleAnalyticsUsersData);
  yield takeEvery(
    GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA,
    handleAnalyticsRegisterUsersGraphData
  );
}

export default AnalyticsUsersSaga;
