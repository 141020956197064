// Va 05 - 01 - 21

import {
  CLEAR_LOGIN_LOGS,
  FETCH_LOGIN_LOGS,
  FETCH_LOGIN_LOGS_ERROR,
  FETCH_LOGIN_LOGS_SUCCESS,
} from "./actionTypes";

export const fetchLoginLog = data => {
  return {
    type: FETCH_LOGIN_LOGS,
    payload: data,
  };
};
export const fetchLoginLogSuccess = data => {
  return {
    type: FETCH_LOGIN_LOGS_SUCCESS,
    payload: data,
  };
};
export const fetchLoginLogError = () => {
  return {
    type: FETCH_LOGIN_LOGS_ERROR,
  };
};
export const clearLoginLogs = () => ({
  type: CLEAR_LOGIN_LOGS,
});
