export const GET_ALL_CONTENT = "GET_ALL_CONTENT";
export const GET_ALL_CONTENT_SUCCESS = "GET_ALL_CONTENT_SUCCESS";
export const GET_ALL_CONTENT_ERROR = "GET_ALL_CONTENT_ERROR";
export const SET_CURRENT_TAB_ID = "SET_CURRENT_TAB_ID";

// fetch all
export const LIST_ALL_POSTS = "LIST_ALL_POSTS";
export const LIST_ALL_POSTS_SUCCESS = "LIST_ALL_POSTS_SUCCESS";
export const LIST_ALL_POSTS_ERROR = "LIST_ALL_POSTS_ERROR";

// features
export const SET_POSTS_PAGE = "SET_POSTS_PAGE";
export const SET_POSTS_SIZE_PER_PAGE = "SET_POSTS_SIZE_PER_PAGE";
export const SET_POSTS_SEARCH = "SET_POSTS_SEARCH";
export const SET_POSTS_SORT_FIELD = "SET_POSTS_SORT_FIELD";
export const SET_POSTS_SORT_ORDER = "SET_POSTS_SORT_ORDER";

// delete
export const DELETE_POST = "DELETE_POST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_ERROR = "DELETE_POST_ERROR";

// add edit
export const ADD_EDIT_CURRICULUM_POST = "ADD_EDIT_CURRICULUM_POST";
export const ADD_EDIT_CURRICULUM_POST_SUCCESS =
  "ADD_EDIT_CURRICULUM_POST_SUCCESS";
export const ADD_EDIT_CURRICULUM_POST_ERROR = "ADD_EDIT_CURRICULUM_POST_ERROR";

export const FETCH_CURRICULUM_POST_DETAILS = "FETCH_CURRICULUM_POST_DETAILS";
export const FETCH_CURRICULUM_POST_DETAILS_SUCCESS =
  "FETCH_CURRICULUM_POST_DETAILS_SUCCESS";
export const FETCH_CURRICULUM_POST_DETAILS_ERROR =
  "FETCH_CURRICULUM_POST_DETAILS_ERROR";

export const FETCH_HEADER_FOOTER_DATA = "FETCH_HEADER_FOOTER_DATA";
export const FETCH_HEADER_FOOTER_DATA_SUCCESS =
  "FETCH_HEADER_FOOTER_DATA_SUCCESS";
export const FETCH_HEADER_FOOTER_DATA_ERROR = "FETCH_HEADER_FOOTER_DATA_ERROR";

export const ADD_EDIT_PAGE_EDITOR = "ADD_EDIT_PAGE_EDITOR";
export const ADD_EDIT_PAGE_EDITOR_SUCCESS = "ADD_EDIT_PAGE_EDITOR_SUCCESS";
export const ADD_EDIT_PAGE_EDITOR_ERROR = "ADD_EDIT_PAGE_EDITOR_ERROR";

// Series
export const SAVE_PAGE_SERIES = "SAVE_PAGE_SERIES";
export const SAVE_PAGE_SERIES_SUCCESS = "SAVE_PAGE_SERIES_SUCCESS";
export const SAVE_PAGE_SERIES_ERROR = "SAVE_PAGE_SERIES_ERROR";
// Posts
export const SAVE_PAGE_POSTS = "SAVE_PAGE_POSTS";
export const SAVE_PAGE_POSTS_SUCCESS = "SAVE_PAGE_POSTS_SUCCESS";
export const SAVE_PAGE_POSTS_ERROR = "SAVE_PAGE_POSTS_ERROR";

export const CLEAR_CURRICULUM_POST = " CLEAR_CURRICULUM_POST";
export const CLEAR_POSTS = "CLEAR_POSTS";

// Add message series post
export const ADD_SERIES_POST = "ADD_SERIES_POST";
export const ADD_SERIES_POST_SUCCESS = "ADD_SERIES_POST_SUCCESS";
export const ADD_SERIES_POST_ERROR = "ADD_SERIES_POST_ERROR";

// List message series post
export const GET_MESSAGE_POST = "GET_MESSAGE_POST";
export const GET_MESSAGE_POST_SUCCESS = "GET_MESSAGE_POST_SUCCESS";
export const GET_MESSAGE_POST_ERROR = "GET_MESSAGE_POST_ERROR";

// List APP series post
export const GET_SERIES_POST = "GET_SERIES_POST";
export const GET_SERIES_POST_SUCCESS = "GET_SERIES_POST_SUCCESS";
export const GET_SERIES_POST_ERROR = "GET_SERIES_POST_ERROR";

/** To update redux when ON CLOSE BUTTON */
export const EMPTY_SERIES_POST = "EMPTY_SERIES_POST";

/** To update redux when SERIES is deleted */
export const DELETE_AB_SERIES = "DELETE_AB_SERIES";

export const SORT_FREE_VBS_PAGES = "SORT_FREE_VBS_PAGES";

export const DELETE_POSTS_FOLDER = "DELETE_POSTS_FOLDER";
export const DELETE_POSTS_FOLDER_SUCCESS = "DELETE_POSTS_FOLDER_SUCCESS";
export const DELETE_POSTS_FOLDER_ERROR = "DELETE_POSTS_FOLDER_ERROR";

export const GET_SERIES_SORT_POST = "GET_SERIES_SORT_POST";
export const GET_SERIES_SORT_POST_SUCCESS = "GET_SERIES_SORT_POST_SUCCESS";
export const GET_SERIES_SORT_POST_ERROR = "GET_SERIES_SORT_POST_ERROR";

export const GET_SORT_SERIES_POST = "GET_SORT_SERIES_POST";
export const GET_SORT_SERIES_POST_SUCCESS = "GET_SORT_SERIES_POST_SUCCESS";
export const GET_SORT_SERIES_POST_ERROR = "GET_SORT_SERIES_POST_SUCCESS";
