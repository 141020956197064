import {
  FETCH_SUPPORT_TICKET,
  FETCH_SUPPORT_TICKET_ERROR,
  FETCH_SUPPORT_TICKET_NOTE,
  FETCH_SUPPORT_TICKET_NOTE_ERROR,
  FETCH_SUPPORT_TICKET_NOTE_SUCCESS,
  FETCH_SUPPORT_TICKET_SUCCESS,
} from "./actionTypes";

export const fetchSupportTicketData = data => ({
  type: FETCH_SUPPORT_TICKET,
  payload: data,
});

export const fetchSupportTicketSuccess = data => ({
  type: FETCH_SUPPORT_TICKET_SUCCESS,
  payload: data,
});

export const fetchSupportTicketError = () => ({
  type: FETCH_SUPPORT_TICKET_ERROR,
});

export const fetchSupportTicketNotesSuccess = data => ({
  type: FETCH_SUPPORT_TICKET_NOTE_SUCCESS,
  payload: data,
});

export const fetchSupportTicketNotes = data => ({
  type: FETCH_SUPPORT_TICKET_NOTE,
  payload: data,
});
export const fetchSupportTicketNotesError = () => ({
  type: FETCH_SUPPORT_TICKET_NOTE_ERROR,
});
