import {
  CLEAR_SUBSCRIPTION_FEATURES,
  CLEAR_SUB_STATE,
  EDIT_SUBSCRIPTION,
  EDIT_SUBSCRIPTION_ERROR,
  EDIT_SUBSCRIPTION_SUCCESS,
  FETCH_NEW_SUBSCRIPTION,
  FETCH_NEW_SUBSCRIPTION_ERROR,
  FETCH_NEW_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION,
  FETCH_SUBSCRIPTION_COUNTRY,
  FETCH_SUBSCRIPTION_COUNTRY_SUCCESS,
  FETCH_SUBSCRIPTION_ERROR,
  FETCH_SUBSCRIPTION_STATE,
  FETCH_SUBSCRIPTION_STATE_SUCCESS,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_TOTAL_SUBSCRIPTION,
  FETCH_TOTAL_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION_DATA,
  GET_SUBSCRIPTION_DATA_SUCCESS,
  RESET_DISCOUNT,
  SEND_RECEIPT,
  SEND_RECEIPT_SUCCESS,
  SEND_REFUND_RECEIPT,
  SEND_REFUND_RECEIPT_SUCCESS,
  SET_BUTTON_TYPE,
  SET_SUBSCRIPTION,
  SET_SUBSCRIPTION_PAGE,
  SET_SUBSCRIPTION_SEARCH,
  SET_SUBSCRIPTION_SIZE_PER_PAGE,
  SET_SUBSCRIPTION_SORT_FIELD,
  SET_SUBSCRIPTION_SORT_ORDER,
  SET_SUBSCRIPTION_STATUS,
  SET_SUBSCRIPTION_TOTAL_VALUE,
  SUBMIT_SUBSCRIPTION,
  SUBMIT_SUBSCRIPTION_ERROR,
  SUBMIT_SUBSCRIPTION_SUCCESS,
  VERIFY_COUPON,
  VERIFY_COUPON_ERROR,
  VERIFY_COUPON_SUCCESS,
} from "./actionTypes";

export const setSubscriptionPage = data => ({
  type: SET_SUBSCRIPTION_PAGE,
  payload: data,
});

export const setSubscriptionSizePerPage = data => ({
  type: SET_SUBSCRIPTION_SIZE_PER_PAGE,
  payload: data,
});

export const setSubscriptionSearch = data => ({
  type: SET_SUBSCRIPTION_SEARCH,
  payload: data,
});

export const setSubscriptionSortField = data => ({
  type: SET_SUBSCRIPTION_SORT_FIELD,
  payload: data,
});

export const setSubscriptionSortOrder = data => ({
  type: SET_SUBSCRIPTION_SORT_ORDER,
  payload: data,
});
export const clearSubscriptionFeatures = () => ({
  type: CLEAR_SUBSCRIPTION_FEATURES,
});

export const fetchSubscription = data => ({
  type: FETCH_SUBSCRIPTION,
  payload: data,
});

export const fetchSubscriptionSuccess = data => ({
  type: FETCH_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const fetchSubscriptionError = () => ({
  type: FETCH_SUBSCRIPTION_ERROR,
});

// new subscription
export const getNewSubscription = data => {
  return {
    type: FETCH_NEW_SUBSCRIPTION,
    payload: data,
  };
};

export const getNewSubscriptionSuccess = data => ({
  type: FETCH_NEW_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const getNewSubscriptionError = () => ({
  type: FETCH_NEW_SUBSCRIPTION_ERROR,
});

// fetching country
export const fetchSubscriptionCountry = data => ({
  type: FETCH_SUBSCRIPTION_COUNTRY,
  payload: data,
});

export const fetchSubscriptionCountrySuccess = data => ({
  type: FETCH_SUBSCRIPTION_COUNTRY_SUCCESS,
  payload: data,
});

// fetching state
export const fetchSubscriptionState = data => ({
  type: FETCH_SUBSCRIPTION_STATE,
  payload: data,
});

export const fetchSubscriptionStateSuccess = data => ({
  type: FETCH_SUBSCRIPTION_STATE_SUCCESS,
  payload: data,
});

// verify coupon
export const verifyCoupon = data => {
  return {
    type: VERIFY_COUPON,
    payload: data,
  };
};

export const verifyCouponSuccess = data => ({
  type: VERIFY_COUPON_SUCCESS,
  payload: data,
});

export const verifyCouponError = () => ({
  type: VERIFY_COUPON_ERROR,
});

// submit subscription
export const submitSubscription = data => {
  return {
    type: SUBMIT_SUBSCRIPTION,
    payload: data,
  };
};

export const submitSubscriptionSuccess = () => ({
  type: SUBMIT_SUBSCRIPTION_SUCCESS,
});

export const submitSubscriptionError = () => ({
  type: SUBMIT_SUBSCRIPTION_ERROR,
});

// fetching subscription detail
export const fetchSubscriptionById = data => ({
  type: EDIT_SUBSCRIPTION,
  payload: data,
});

export const fetchSubscriptionByIdSuccess = data => ({
  type: EDIT_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const fetchSubscriptionByIdError = () => ({
  type: EDIT_SUBSCRIPTION_ERROR,
});

export const fetchTotalSubscription = () => ({
  type: FETCH_TOTAL_SUBSCRIPTION,
});
export const fetchTotalSubscriptionSuccess = data => ({
  type: FETCH_TOTAL_SUBSCRIPTION_SUCCESS,
  payload: data,
});
export const setSubscriptionStatus = status => ({
  type: SET_SUBSCRIPTION_STATUS,
  payload: status,
});
export const setSubscription = data => ({
  type: SET_SUBSCRIPTION,
  payload: data,
});

export const resetDiscount = data => ({
  type: RESET_DISCOUNT,
  payload: data,
});

export const getSubscriptionData = id => ({
  type: GET_SUBSCRIPTION_DATA,
  id: id,
});
export const getSubscriptionDataSuccess = data => ({
  type: GET_SUBSCRIPTION_DATA_SUCCESS,
  payload: data,
});
export const clearSubState = () => ({
  type: CLEAR_SUB_STATE,
});

// send charge receipt
export const sendReceipt = data => ({
  type: SEND_RECEIPT,
  payload: data,
});

export const sendReceiptSuccess = data => ({
  type: SEND_RECEIPT_SUCCESS,
  payload: data,
});

// send refund receipt
export const sendRefundReceipt = data => ({
  type: SEND_REFUND_RECEIPT,
  payload: data,
});

export const sendRefundReceiptSuccess = data => ({
  type: SEND_REFUND_RECEIPT_SUCCESS,
  payload: data,
});

export const setSubmitBtnType = data => ({
  type: SET_BUTTON_TYPE,
  payload: data,
});

export const setSubscriptionTotalValue = data => ({
  type: SET_SUBSCRIPTION_TOTAL_VALUE,
  payload: data,
});
