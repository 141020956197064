// Smit : 23-11 / 24-11

import { axiosApiAdmin } from "helpers/api_helper";
import {
  DELETE_SYSTEM_PAGES,
  LIST_AMAZON_PAGES,
  LIST_SYSTEM_PAGES,
  UPDATE_SYSTEM_PAGE,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  editSystemPageError,
  editSystemPageSuccess,
  fetchAmazonPagesError,
  fetchAmazonPagesSuccess,
  fetchSystemPagesError,
  fetchSystemPagesSuccess,
  setErrorMessage,
  setSuccessMessage,
  systemPageDeleteError,
  systemPageDeleteSuccess,
} from "store/actions";
import {
  EDIT_SYSTEM_PAGE,
  FETCH_AMAZON_PAGES,
  FETCH_SYSTEM_PAGES,
  SYSTEM_PAGE_DELETE,
} from "./actionTypes";

const fetchSystemPages = data => axiosApiAdmin.post(LIST_SYSTEM_PAGES, data);
const fetchAmazonPages = data => axiosApiAdmin.post(LIST_AMAZON_PAGES, data);
const editSystemPage = data => axiosApiAdmin.put(UPDATE_SYSTEM_PAGE, data);
const deleteSystemPage = id =>
  axiosApiAdmin.delete(`${DELETE_SYSTEM_PAGES}/${id}`);

function* handleFetchSystemPages({ payload }) {
  try {
    const res = yield call(fetchSystemPages, payload);
    if (res.data?.data?.data) {
      yield put(fetchSystemPagesSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(fetchSystemPagesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleFetchAmazonPages({ payload }) {
  const series_id = payload?.series_id || 0;
  const type = payload?.type || 0;

  try {
    const { search, sizePerPage, page } = yield select(
      state => state.SystemPages
    );
    const res = yield call(fetchAmazonPages, {
      page_record: sizePerPage,
      page_no: page,
      search,
      type_id: series_id ? +series_id : 0,
      type: type ? type : 0,
    });

    if (res.data?.data?.rows) {
      yield put(fetchAmazonPagesSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchAmazonPagesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleEditSystemPage({ payload: { data, cb } }) {
  try {
    const res = yield call(editSystemPage, data);
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(editSystemPageSuccess());
      cb();
    }
  } catch (err) {
    yield put(editSystemPageError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteSystemPage({ payload: { id, cb } }) {
  try {
    const res = yield call(deleteSystemPage, id);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(systemPageDeleteSuccess());
      cb();
    }
  } catch (err) {
    yield put(systemPageDeleteError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* systemPagesSaga() {
  yield takeEvery(FETCH_SYSTEM_PAGES, handleFetchSystemPages);
  yield takeEvery(FETCH_AMAZON_PAGES, handleFetchAmazonPages);
  yield takeEvery(EDIT_SYSTEM_PAGE, handleEditSystemPage);
  yield takeEvery(SYSTEM_PAGE_DELETE, handleDeleteSystemPage);
}

export default systemPagesSaga;
