import { LISTING_CALENDAR, LISTING_LOADING_DATA } from "./actionType";

const initialState = {
  allCalenderCardData: [],
  allCalenderCardCount: 0,
  allCalenderCardDataLoading: false,
};

const CalenderCardCancelReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LISTING_CALENDAR:
      return {
        ...state,
        allCalenderCardCount: payload?.data?.data?.count,
        allCalenderCardData: payload?.data?.data?.rows,
      };
    case LISTING_LOADING_DATA:
      return {
        ...state,
        allCalenderCardDataLoading: payload,
      };
    default:
      return state;
  }
};

export default CalenderCardCancelReducer;
