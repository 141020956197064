export const FETCH_MEMBERS = "FETCH_MEMBERS";
export const FETCH_MEMBERS_SUCCESS = "FETCH_MEMBERS_SUCCESS";
export const FETCH_MEMBERS_ERROR = "FETCH_MEMBERS_ERROR";

export const FETCH_USER_MEMBERSHIP = "FETCH_USER_MEMBERSHIP";
export const FETCH_USER_MEMBERSHIP_SUCCESS = "FETCH_USER_MEMBERSHIP_SUCCESS";
export const FETCH_USER_MEMBERSHIP_ERROR = "FETCH_USER_MEMBERSHIP_ERROR";

export const CREATE_NEW_USER_MEMBERSHIP = "CREATE_NEW_USER_MEMBERSHIP";
export const CREATE_NEW_USER_MEMBERSHIP_SUCCESS =
  "CREATE_NEW_USER_MEMBERSHIP_SUCCESS";
export const CREATE_NEW_USER_MEMBERSHIP_ERROR =
  "CREATE_NEW_USER_MEMBERSHIP_ERROR";

export const SAVE_USER_MEMBERSHIP = "SAVE_USER_MEMBERSHIP";
export const SAVE_USER_MEMBERSHIP_SUCCESS = "SAVE_USER_MEMBERSHIP_SUCCESS";
export const SAVE_USER_MEMBERSHIP_ERROR = "SAVE_USER_MEMBERSHIP_ERROR";

export const DELETE_USER_MEMBERSHIP = "DELETE_USER_MEMBERSHIP";
export const DELETE_USER_MEMBERSHIP_SUCCESS = "DELETE_USER_MEMBERSHIP_SUCCESS";
export const DELETE_USER_MEMBERSHIP_ERROR = "DELETE_USER_MEMBERSHIP_ERROR";

export const SET_MEMBER_PAGE = "SET_MEMBER_PAGE";
export const SET_MEMBER_SIZE_PER_PAGE = "SET_MEMBER_SIZE_PER_PAGE";
export const SET_MEMBER_SEARCH = "SET_MEMBER_SEARCH";
export const SET_MEMBER_SORT_FIELD = "SET_MEMBER_SORT_FIELD";
export const SET_MEMBER_SORT_ORDER = "SET_MEMBER_SORT_ORDER";
export const CLEAR_MEMBERSHIP_FEATURES = "CLEAR_MEMBERSHIP_FEATURES";
