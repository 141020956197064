import { compareTypes } from "modules/AnalyticsDashboard/constants";
import DateRange from "modules/AnalyticsDashboard/utils";
import moment from "moment";

const {
  FETCH_NOT_ACTIVE_SUBSCRIPTION,
  FETCH_NOT_ACTIVE_SUBSCRIPTION_SUCCESS,
  FETCH_NOT_ACTIVE_SUBSCRIPTION_ERROR,
  SET_SEARCH_NOT_ACTIVE_SUBSCRIPTION,
  SET_SORT_ORDER_NOT_ACTIVE_SUBSCRIPTION,
  SET_SORT_FIELD_NOT_ACTIVE_SUBSCRIPTION,
  SET_SIZE_PER_PAGE_NOT_ACTIVE_SUBSCRIPTION,
  SET_PAGE_NOT_ACTIVE_SUBSCRIPTION,
  SET_ORDER_RANGE_DATE_NOT_ACTIVE_SUBSCRIPTION,
  //SET_PAGE_SUPPORT_TICKETS,
  FILTER_BY_APPLICATIONS,
} = require("./actionType");

const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();
const defaultCompareType = compareTypes.PERIOD;

const initialState = {
  range: {
    startDate,
    endDate,
  },

  compareToPrevious: defaultCompareType,
  comparedRange: new DateRange(startDate, endDate).getPreviousDates(
    defaultCompareType
  ),
  by: "day",
  loadingNotActiveSubscription: false,

  NotActiveSubscriptionTableData: {
    loading: false,
    rows: [],
    totalSize: 0,
    search: "",
    page: 1,
    sizePerPage: 25,
    sortOrder: "DESC",
    sortField: "created_date",
    filter: "",
  },
  tz: "-0400",
};

const change = (key, value, state) => ({
  ...state,
  NotActiveSubscriptionTableData: {
    ...state.NotActiveSubscriptionTableData,
    [key]: value,
  },
});

const FormerSubscriptionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_NOT_ACTIVE_SUBSCRIPTION:
      return change("loading", true, state);

    case FETCH_NOT_ACTIVE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        NotActiveSubscriptionTableData: {
          ...state.NotActiveSubscriptionTableData,
          loading: false,
          rows: payload.rows,
          totalSize: payload.totalSize,
        },
      };

    case FETCH_NOT_ACTIVE_SUBSCRIPTION_ERROR:
      return change("loading", false, state);

    case SET_SEARCH_NOT_ACTIVE_SUBSCRIPTION:
      return change("search", payload, state);

    case SET_PAGE_NOT_ACTIVE_SUBSCRIPTION:
      return change("page", payload, state);

    case SET_SIZE_PER_PAGE_NOT_ACTIVE_SUBSCRIPTION:
      return change("sizePerPage", payload, state);

    case SET_SORT_FIELD_NOT_ACTIVE_SUBSCRIPTION:
      return change("sortField", payload, state);

    case SET_SORT_ORDER_NOT_ACTIVE_SUBSCRIPTION:
      return change("sortOrder", payload, state);

    // case SET_PAGE_SUPPORT_TICKETS:
    //   return {
    //     ...state,
    //     NotActiveSubscriptionTableData: {
    //       ...state.NotActiveSubscriptionTableData,
    //       page: payload,
    //     },
    //   };

    case FILTER_BY_APPLICATIONS:
      return {
        ...state,
        NotActiveSubscriptionTableData: {
          ...state.NotActiveSubscriptionTableData,
          filter: payload,
        },
      };

    case SET_ORDER_RANGE_DATE_NOT_ACTIVE_SUBSCRIPTION:
      const prevBy = state.by;
      const newBy = new DateRange(
        payload.startDate,
        payload.endDate
      ).getByOptions()[0].value;
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
        comparedRange: new DateRange(
          payload.startDate,
          payload.endDate
        ).getPreviousDates(state.compareToPrevious),
        ...(prevBy !== newBy ? { by: newBy } : {}),
      };

    default:
      return state;
  }
};

export default FormerSubscriptionReducer;
