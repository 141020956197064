// Va 06 - 01 - 21

import {
  CLEAR_ACTIVE_SUBSCRIPTIONS,
  FETCH_ACTIVE_SUBSCRIPTIONS,
  FETCH_ACTIVE_SUBSCRIPTIONS_ERROR,
  FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS,
} from "./actionTypes";

// fetching active Subscriptions
export const fetchActiveSubscriptions = data => {
  return {
    type: FETCH_ACTIVE_SUBSCRIPTIONS,
    payload: data,
  };
};

export const fetchActiveSubscriptionsSuccess = data => {
  return {
    type: FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS,
    payload: data,
  };
};

export const fetchActiveSubscriptionsError = data => {
  return {
    type: FETCH_ACTIVE_SUBSCRIPTIONS_ERROR,
    payload: data,
  };
};
export const clearActiveSubscriptions = () => ({
  type: CLEAR_ACTIVE_SUBSCRIPTIONS,
});
