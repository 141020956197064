import {
  ANALYTICS_ORDER_SET_TZ,
  FETCH_ORDER_GRAPH_DATA,
  FETCH_ORDER_GRAPH_DATA_ERROR,
  FETCH_ORDER_GRAPH_DATA_SUCCESS,
  FETCH_ORDER_REPORTS,
  FETCH_ORDER_REPORTS_ERROR,
  FETCH_ORDER_REPORTS_SUCCESS,
  FETCH_ORDER_TABLE_DATA,
  FETCH_ORDER_TABLE_DATA_ERROR,
  FETCH_ORDER_TABLE_DATA_SUCCESS,
  SET_ANALYTICS_ORDER_ACTIVE_TAB,
  SET_ANALYTICS_ORDER_BY,
  SET_ANALYTICS_ORDER_COMPARE_TYPE,
  SET_ANALYTICS_ORDER_CURRENT_SITE,
  SET_ANALYTICS_ORDER_PAGE,
  SET_ANALYTICS_ORDER_RANGE_DATE,
  SET_ANALYTICS_ORDER_SIZE_PER_PAGE,
  SET_ANALYTICS_ORDER_SORT_FIELD,
  SET_ANALYTICS_ORDER_SORT_ORDER,
} from "./actionsTypes";

export const setOrderTZ = data => ({
  type: ANALYTICS_ORDER_SET_TZ,
  payload: data,
});

export const setAnalyticsOrderSiteId = data => ({
  type: SET_ANALYTICS_ORDER_CURRENT_SITE,
  payload: data,
});

export const setAnalyticsOrderActiveTab = data => ({
  type: SET_ANALYTICS_ORDER_ACTIVE_TAB,
  payload: data,
});

export const setAnalyticOrderRangeDate = data => ({
  type: SET_ANALYTICS_ORDER_RANGE_DATE,
  payload: data,
});

export const setAnalyticOrderCompareType = data => ({
  type: SET_ANALYTICS_ORDER_COMPARE_TYPE,
  payload: data,
});

export const setAnalyticsOrderBy = data => ({
  type: SET_ANALYTICS_ORDER_BY,
  payload: data,
});

// TABLE
export const setAnalyticsOrderPage = data => ({
  type: SET_ANALYTICS_ORDER_PAGE,
  payload: data,
});
export const setAnalyticsOrderSizePerPage = data => ({
  type: SET_ANALYTICS_ORDER_SIZE_PER_PAGE,
  payload: data,
});
export const setAnalyticsOrderSortField = data => ({
  type: SET_ANALYTICS_ORDER_SORT_FIELD,
  payload: data,
});
export const setAnalyticsOrderSortOrder = data => ({
  type: SET_ANALYTICS_ORDER_SORT_ORDER,
  payload: data,
});

export const getOrderReports = data => ({
  type: FETCH_ORDER_REPORTS,
  payload: data,
});

export const getOrderReportsSuccess = data => ({
  type: FETCH_ORDER_REPORTS_SUCCESS,
  payload: data,
});

export const getOrderReportsError = () => ({
  type: FETCH_ORDER_REPORTS_ERROR,
});

export const getOrderGraphData = data => ({
  type: FETCH_ORDER_GRAPH_DATA,
  payload: data,
});

export const getOrderGraphDataSuccess = data => ({
  type: FETCH_ORDER_GRAPH_DATA_SUCCESS,
  payload: data,
});

export const getOrderGraphDataError = () => ({
  type: FETCH_ORDER_GRAPH_DATA_ERROR,
});

export const getOrderTableData = () => ({
  type: FETCH_ORDER_TABLE_DATA,
});

export const getOrderTableDataSuccess = data => ({
  type: FETCH_ORDER_TABLE_DATA_SUCCESS,
  payload: data,
});

export const getOrderTableDataError = () => ({
  type: FETCH_ORDER_TABLE_DATA_ERROR,
});
