import {
  FiArrowDown,
  FiArrowDownLeft,
  FiArrowDownRight,
  FiArrowLeft,
  FiArrowRight,
  FiArrowUp,
  FiArrowUpLeft,
  FiArrowUpRight,
  FiRotateCcw,
} from "react-icons/fi";
import { toast } from "react-toastify";
import { v4 as uuidV4 } from "uuid";
{
  /*arvina changes 23/11/2022 digital board add  */
}
export class Card {
  constructor(row_id) {
    this.card_id = uuidV4();
    this.backend_card_id = 0;
    this.row_id = row_id;
    this.title = "";
    this.body = "";
    this.title_color = "#000000";
    this.body_color = "#000000";
    this.title_font_size = 32;
    this.body_font_size = 24;
    this.color = colors[0];
    this.opacity = 1;
    this.note = "";
    this.colspan = 1;
    this.has_data = 0;
    this.is_title_bold = 0;
    this.is_title_italic = 0;
    this.is_body_bold = 0;
    this.is_body_italic = 0;
    this.date = "";
  }
}

export class Row {
  constructor() {
    this.row_id = uuidV4();
    this.card_ids = [];
  }
}

export const isMobileOrTabletDevice = () => {
  let check = false;
  (a => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export class StateChanger {
  constructor(state) {
    this.state = state;
  }
  change(key, value) {
    if (Array.isArray(key))
      if (Array.isArray(value) && key.length === value.length)
        return {
          ...this.state,
          ...key.reduce((a, b, index) => {
            a[b] = value[index];
            return a;
          }, {}),
        };
      else return this.state;
    else
      return {
        ...this.state,
        [key]: value,
      };
  }
}

const gradientTypes = {
  LINEAR: "linear-gradient",
  RADIAL: "radial-gradient",
};

export const directions = [
  { direction: "to top", type: gradientTypes.LINEAR, Icon: FiArrowUp },
  {
    direction: "to top right",
    type: gradientTypes.LINEAR,
    Icon: FiArrowUpRight,
  },
  { direction: "to right", type: gradientTypes.LINEAR, Icon: FiArrowRight },
  {
    direction: "to bottom right",
    type: gradientTypes.LINEAR,
    Icon: FiArrowDownRight,
  },
  { direction: "to bottom", type: gradientTypes.LINEAR, Icon: FiArrowDown },
  {
    direction: "to bottom left",
    type: gradientTypes.LINEAR,
    Icon: FiArrowDownLeft,
  },
  { direction: "to left", type: gradientTypes.LINEAR, Icon: FiArrowLeft },
  { direction: "to top left", type: gradientTypes.LINEAR, Icon: FiArrowUpLeft },
  { direction: "circle", type: gradientTypes.RADIAL, Icon: FiRotateCcw },
];

export const BOARD_SIZE = 30;

export const notificationTypes = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
};

export const userBoardRoles = {
  VIEWER: "0",
  EDITOR: "1",
};

export const initialInviteBoardData = {
  showModal: false,
  fromUser: "",
  boardTitle: "",
  inviteBoardId: 0,
};

export const boardAccessTypes = {
  VIEW: 0,
  EDIT: 1,
};

export const invitedUserStatus = {
  PENDING: 0,
  ACCEPTED: 1,
  REJECTED: 2,
};

export const loop = size => Array(size).fill(true);

export const getRandom = Arr => Arr[Math.floor(Math.random() * Arr.length)];

export const isFunc = func => !!func && typeof func === "function";

export class AppToast {
  constructor(value, type = notificationTypes.ERROR) {
    this.message = value?.response?.data?.message || value?.message || value;
    this.type = type;
  }
  display() {
    toast[this.type](this.message);
  }
}

export const ArrToObj = (arr, key) => {
  const newObj = {};
  if (Array.isArray(arr)) {
    arr.forEach(item => {
      let k = key;
      if (typeof key === "function") k = key(item);
      newObj[k] = item;
    });
  }
  return newObj;
};

export const getColor = (colors, type, direction) => {
  const data = [...Object.values(colors)].filter(c => c.isSelected);
  return data.length === 1
    ? data[0].value
    : `${type}(${direction}, ${data.map(c => c.value).join(", ")})`;
};

export const rows = {};

export const cards = {};

export const fontSizes = [10, 14, 16, 20, 24, 28, 32, 36, 40, 44, 46, 48, 52];

export const DEFAULT_BACKGROUND_COLOR = "#000000";

export const resetCardValues = {
  title: "",
  body: "",
  title_font_size: 36,
  title_color: "#000000",
  body_color: "#000000",
  body_font_size: 20,
  is_title_bold: false,
  is_title_italic: false,
  is_body_bold: false,
  is_body_italic: false,
  color: "",
  opacity: 1,
  note: "",
  date: null,
  has_data: 0,
};

export const getBackground = (
  backgroundType,
  backgroundData,
  backgroundImages
) => {
  if (!!backgroundData)
    switch (backgroundType) {
      case 1: {
        const data = JSON.parse(backgroundData);
        const direction = directions.find(
          dir => data.direction === dir.direction
        );
        if (!!direction && !!data)
          return getColor(data.colors, direction.type, direction.direction);
        return DEFAULT_BACKGROUND_COLOR;
      }
      case 2: {
        return `url(${backgroundData}) repeat`;
      }
      case 3: {
        if (!backgroundImages?.length) return DEFAULT_BACKGROUND_COLOR;
        return `url(${backgroundData}) repeat`;
      }
      default:
        return DEFAULT_BACKGROUND_COLOR;
    }
  else return DEFAULT_BACKGROUND_COLOR;
};

export const getOS = () => {
  if (navigator.userAgent.indexOf("Win") !== -1) return OS.WINDOWS;
  else if (navigator.userAgent.indexOf("Mac") !== -1) return OS.MAC;
  else if (navigator.userAgent.indexOf("Linux") !== -1) return OS.LINUX;
  else if (navigator.userAgent.indexOf("Android") !== -1) return OS.ANDROID;
  else return OS.IOS;
};

export const OS = {
  MAC: "Mac OS",
  IOS: "iOS",
  WINDOWS: "Windows",
  ANDROID: "Android",
  LINUX: "Linux",
};

export const keys = {
  [OS.WINDOWS]: "ctrlKey",
  [OS.LINUX]: "ctrlKey",
  [OS.MAC]: "metaKey",
};

export const modes = {
  EDIT: "edit",
  ADD: "add",
};

export const colors = [
  "#ffffff",
  "#313744",
  "#ef8f48",
  "#e74e4e",
  "#e3b12d",
  "#B5cd56",
  "#80d0dd",
  // "#f27b9e",
];

export const sizes = {
  1: "col-lg-1",
  2: "col-lg-2",
  3: "col-lg-3",
  4: "col-lg-4",
  5: "col-lg-5",
  6: "col-lg-6",
  7: "col-lg-7",
  8: "col-lg-8",
  9: "col-lg-9",
  10: "col-lg-10",
  11: "col-lg-11",
  12: "col-lg-12",
};

export const slug = data =>
  typeof data !== "string" ? "" : data.toLocaleLowerCase().replace(/\s+/g, "-");

export const getEmptyCardId = (rows, cards) => {
  const cardIds = Object.values(rows).flatMap(row => row.card_ids);
  let cardId = "";
  for (let i = 0; i < cardIds.length; i++) {
    if (!cards[cardIds[i]]?.has_data) {
      cardId = cardIds[i];
      break;
    }
  }
  return cardId;
};

export const getRowIndex = (rows, rowId) => Object.keys(rows).indexOf(rowId);
export const getCardIndex = (rows, rowId, cardId) =>
  rows[rowId].card_ids.indexOf(cardId);

export const createBoard = cardsArr => {
  const cardsObj = ArrToObj(
    cardsArr,
    obj => `${obj.row_index}-${obj.col_index}`
  );

  const tempCards = Object.values(cardsObj);

  const newCards = {};

  const newRows = loop(BOARD_SIZE).reduce((a, _, row_index) => {
    const row = new Row();

    const totalCards = tempCards
      .filter(card => card.row_index === row_index)
      .reduce((a, b) => a - (b.colspan - 1), BOARD_SIZE);

    const cards = loop(totalCards).map((_, col_index) => {
      const card = new Card(row.row_id);
      const dataCard = cardsObj[`${row_index}-${col_index}`];

      if (!!dataCard) {
        card.card_id = uuidV4();
        card.backend_card_id = dataCard.card_id;
        card.title = dataCard.title;
        card.body = dataCard.body;
        card.title_color = dataCard.title_color;
        card.body_color = dataCard.body_color;
        card.title_font_size = dataCard.title_font_size;
        card.body_font_size = dataCard.body_font_size;
        card.color = dataCard.color;
        card.opacity = dataCard.opacity;
        card.note = dataCard.note;
        card.colspan = dataCard.colspan;
        card.has_data = 1;
        card.is_title_bold = dataCard.is_title_bold;
        card.is_title_italic = dataCard.is_title_italic;
        card.is_body_bold = dataCard.is_body_bold;
        card.is_body_italic = dataCard.is_body_italic;
        card.date = dataCard.date;
      }
      return card;
    });

    const cardIds = [];

    cards.forEach(card => {
      newCards[card.card_id] = card;
      cardIds.push(card.card_id);
    });

    row.card_ids = cardIds;

    a[row.row_id] = row;

    return a;
  }, {});

  return {
    cards: newCards,
    rows: newRows,
  };
};
