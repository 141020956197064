// Va 06 - 01 - 21
import { axiosApiAdmin } from "helpers/api_helper";
import { GET_USER_ACTIVE_MEMBERSHIPS } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchActiveMembershipError,
  fetchActiveMembershipSuccess,
  setErrorMessage,
} from "store/actions";
import { FETCH_ACTIVE_MEMBERSHIPS } from "./actionTypes";

const fetchActiveMemberships = data =>
  axiosApiAdmin.post(GET_USER_ACTIVE_MEMBERSHIPS, data);

function* handleFetchActiveMembership({ payload }) {
  try {
    const res = yield call(fetchActiveMemberships, payload);
    if (res?.data?.data) {
      yield put(fetchActiveMembershipSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchActiveMembershipError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* activeMembershipSaga() {
  yield takeEvery(FETCH_ACTIVE_MEMBERSHIPS, handleFetchActiveMembership);
}

export default activeMembershipSaga;
