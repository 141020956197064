// Va 05 - 01 - 21

import {
  CLEAR_SUPPORT_TICKETS,
  FATCH_LIST_SUBSCRIPTION_REPORT,
  FATCH_LIST_SUBSCRIPTION_REPORT_ERROR,
  FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT_NULL,
  FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT_SUCCESS,
  FATCH_LIST_SUBSCRIPTION_REPORT_SUCCESS,
  SET_PAGE_SUPPORT_TICKETS,
  SET_SEARCH_SUPPORT_TICKETS,
  SET_SIZE_PER_PAGE_SUPPORT_TICKETS,
  SET_SORT_FIELD_SUPPORT_TICKETS,
  SET_SORT_ORDER_SUPPORT_TICKETS,
} from "./actionTypes";

const initialState = {
  subscriptionReport: [],
  loading: false,
  totalSize: 0,
  sizePerPage: 10,
  search: "",
  page: 1,
  sortField: "post_id",
  sortOrder: "DESC",
  subscriptionReportExport: [],
};

const subscriptionReportReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_SUPPORT_TICKETS:
      return {
        ...state,
        subscriptionReport: [],
      };
    case FATCH_LIST_SUBSCRIPTION_REPORT:
      return {
        ...state,
        loading: true,
      };
    case FATCH_LIST_SUBSCRIPTION_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        subscriptionReport: payload.rows,
        totalSize: payload.count,
      };
    case FATCH_LIST_SUBSCRIPTION_REPORT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_PAGE_SUPPORT_TICKETS:
      return {
        ...state,
        page: payload,
      };

    case SET_SIZE_PER_PAGE_SUPPORT_TICKETS:
      return {
        ...state,
        sizePerPage: payload,
      };

    case SET_SEARCH_SUPPORT_TICKETS:
      return {
        ...state,
        search: payload,
        page: 1,
      };

    case SET_SORT_FIELD_SUPPORT_TICKETS:
      return {
        ...state,
        sortField: action.payload,
      };

    case SET_SORT_ORDER_SUPPORT_TICKETS:
      return {
        ...state,
        sortOrder: action.payload,
      };
    case FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT_SUCCESS:
      return {
        ...state,
        subscriptionReportExport: action.payload,
      };
    case FATCH_LIST_SUBSCRIPTION_REPORT_EXPORT_NULL:
      return {
        ...state,
        subscriptionReportExport: [],
      };

    default:
      return state;
  }
};

export default subscriptionReportReducer;
