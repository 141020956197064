import {
  CLEAR_MEMBERSHIP_FEATURES,
  CREATE_NEW_USER_MEMBERSHIP,
  CREATE_NEW_USER_MEMBERSHIP_ERROR,
  CREATE_NEW_USER_MEMBERSHIP_SUCCESS,
  DELETE_USER_MEMBERSHIP,
  DELETE_USER_MEMBERSHIP_ERROR,
  DELETE_USER_MEMBERSHIP_SUCCESS,
  FETCH_MEMBERS,
  FETCH_MEMBERS_ERROR,
  FETCH_MEMBERS_SUCCESS,
  FETCH_USER_MEMBERSHIP,
  FETCH_USER_MEMBERSHIP_ERROR,
  FETCH_USER_MEMBERSHIP_SUCCESS,
  SAVE_USER_MEMBERSHIP,
  SAVE_USER_MEMBERSHIP_ERROR,
  SAVE_USER_MEMBERSHIP_SUCCESS,
  SET_MEMBER_PAGE,
  SET_MEMBER_SEARCH,
  SET_MEMBER_SIZE_PER_PAGE,
  SET_MEMBER_SORT_FIELD,
  SET_MEMBER_SORT_ORDER,
} from "./actionTypes";

const INIT_STATE = {
  members: [],
  totalSize: 0,
  search: "",
  sizePerPage: 25,
  page: 1,
  sortField: "membership_name",
  sortOrder: "ASC",
  loading: false,
  userMembership: {},
  loadingSave: false,
};
const member = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_MEMBERS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MEMBERS_SUCCESS:
      return {
        ...state,
        members: action.payload?.data ? action.payload.data : [],
        totalSize: action?.payload.count ? action?.payload.count : 0,
        loading: false,
      };
    case FETCH_MEMBERS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case CREATE_NEW_USER_MEMBERSHIP:
      return {
        ...state,
        loading: true,
      };
    case CREATE_NEW_USER_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        userMembership: action.payload,
      };
    case CREATE_NEW_USER_MEMBERSHIP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case FETCH_USER_MEMBERSHIP:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USER_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        userMembership: action.payload || {},
        loading: false,
      };
    case FETCH_USER_MEMBERSHIP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SAVE_USER_MEMBERSHIP:
      return {
        ...state,
        loadingSave: true,
      };
    case SAVE_USER_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        userMembership: action.payload,
        loadingSave: false,
      };
    case SAVE_USER_MEMBERSHIP_ERROR:
      return {
        ...state,
        loadingSave: false,
      };

    case DELETE_USER_MEMBERSHIP:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_USER_MEMBERSHIP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_MEMBER_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_MEMBER_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: action.payload,
      };
    case SET_MEMBER_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_MEMBER_SORT_FIELD:
      return {
        ...state,
        sortField: action.payload,
      };
    case SET_MEMBER_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.payload,
      };
    case CLEAR_MEMBERSHIP_FEATURES:
      return {
        ...state,
        totalSize: 0,
        search: "",
        sizePerPage: 25,
        page: 1,
        sortField: "membership_name",
        sortOrder: "ASC",
      };
    default:
      return state;
  }
};
export default member;
