// Mrinal : 23-11

import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  ADD_EDIT_PRODUCT,
  ADD_EDIT_PRODUCT_FOLDER,
  DELETE_PRODUCT,
  DELETE_PRODUCT_FOLDER,
  FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT,
  FETCH_PRODUCTS,
  FETCH_PRODUCT_FOLDERS,
  FETCH_PRODUCT_SITES,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_FOLDER_DETAILS,
} from "./actionTypes";

import { axiosApiAdmin } from "helpers/api_helper";
import {
  ADD_EDIT_SINGLE_PRODUCT_FOLDER,
  DELETE_SINGLE_PRODUCT,
  DELETE_SINGLE_PRODUCT_FOLDER,
  GET_SINGLE_PRODUCT_FOLDER_DETAILS,
  LIST_ALL_PRODUCTS,
  LIST_ALL_PRODUCTS_VOLUMES_WITH_COUNT,
  LIST_ALL_PRODUCT_FOLDERS,
  LIST_ALL_SITES_URL,
  PRODUCT_DETAILS,
  UPDATE_PRODUCT,
} from "helpers/url_helper";

import {
  addEditProductError,
  addEditProductSuccess,
  deleteProductFolderSuccess,
  deleteProductSuccess,
  fetchProductError,
  fetchProductFolders,
  fetchProductFolderSuccess,
  fetchProducts,
  fetchProductSitesSuccess,
  fetchProductSuccess,
  getProductDetailsSuccess,
  getProductFolderDetailsSuccess,
  getVolumesWithCountError,
  getVolumesWithCountSuccess,
  setErrorMessage,
  setSuccessMessage,
} from "store/actions";

const fetchListProducts = data => axiosApiAdmin.post(LIST_ALL_PRODUCTS, data);
const fetchProductListFolders = data =>
  axiosApiAdmin.post(LIST_ALL_PRODUCT_FOLDERS, data);
const addEditProduct = data => axiosApiAdmin.post(UPDATE_PRODUCT, data);
const getNewProductDetails = data =>
  axiosApiAdmin.get(`${PRODUCT_DETAILS}/${data}`);
const deleteSingleProduct = data =>
  axiosApiAdmin.post(DELETE_SINGLE_PRODUCT, data);
const addEditProductFolder = data =>
  axiosApiAdmin.post(ADD_EDIT_SINGLE_PRODUCT_FOLDER, data);
const getProductFolderDetails = data =>
  axiosApiAdmin.get(`${GET_SINGLE_PRODUCT_FOLDER_DETAILS}/${data}`);
const deleteSingleProductFolder = data =>
  axiosApiAdmin.get(`${DELETE_SINGLE_PRODUCT_FOLDER}/${data}`);
const fetchProductSite = data => axiosApiAdmin.get(LIST_ALL_SITES_URL, data);
const fetchProductVolumes = () =>
  axiosApiAdmin.get(LIST_ALL_PRODUCTS_VOLUMES_WITH_COUNT);

function* handleFetchProducts({ payload }) {
  try {
    const { search, sizePerPage, page, sortField, sortOrder, category_id } =
      yield select(state => state.Products);
    const { siteId } = yield select(state => state.Applications);
    let data = {
      search: search,
      page_record: sizePerPage,
      page_no: page,
      filter: [],
      sort_field: sortField,
      sort_order: sortOrder,
      site_id: siteId,
      category_id,
    };
    const res = yield call(fetchListProducts, data);
    if (res.data?.data) {
      yield put(fetchProductSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchProductError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchProductFolders({ payload }) {
  try {
    const res = yield call(fetchProductListFolders, payload);
    if (res.data?.data) {
      yield put(fetchProductFolderSuccess(res.data.data));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handelAddEditProduct({ payload: { values, history, callBack } }) {
  try {
    const res = yield call(addEditProduct, values);
    if (res.data) {
      yield put(addEditProductSuccess());
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchProducts());
      callBack();
    }
  } catch (err) {
    yield put(addEditProductError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* getProductDetails({ payload }) {
  try {
    const res = yield call(getNewProductDetails, payload);
    if (res.data?.data) {
      yield put(getProductDetailsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* deleteProduct({ payload }) {
  try {
    const res = yield call(deleteSingleProduct, payload);

    if (res.data?.data) {
      yield put(deleteProductSuccess(res.data.data));
      yield put(fetchProducts());
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handelAddEditProductFolder(payload) {
  try {
    const res = yield call(addEditProductFolder, payload.payload);
    if (res.data?.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(fetchProductFolders());
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handelGetProductFolderDetails(payload) {
  try {
    const res = yield call(getProductFolderDetails, payload.payload);
    if (res.data?.data) {
      yield put(getProductFolderDetailsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleDeleteProductFolder({ payload }) {
  try {
    const res = yield call(deleteSingleProductFolder, payload);
    if (res.data?.data) {
      yield put(deleteProductFolderSuccess(res.data.data));
      yield put(fetchProductFolders());
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchProductSites({ payload }) {
  try {
    const res = yield call(fetchProductSite, payload);
    if (res.data?.data?.data?.rows) {
      yield put(fetchProductSitesSuccess(res.data.data.data.rows));
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleFetchProductVolumes() {
  try {
    const res = yield call(fetchProductVolumes);
    if (res.data?.data?.volumes) {
      yield put(getVolumesWithCountSuccess(res.data.data.volumes));
    }
  } catch (err) {
    yield put(getVolumesWithCountError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* productsSaga() {
  yield takeEvery(FETCH_PRODUCTS, handleFetchProducts);
  yield takeEvery(FETCH_PRODUCT_FOLDERS, handleFetchProductFolders);
  yield takeEvery(ADD_EDIT_PRODUCT, handelAddEditProduct);
  yield takeEvery(GET_PRODUCT_DETAILS, getProductDetails);
  yield takeEvery(DELETE_PRODUCT, deleteProduct);
  yield takeEvery(ADD_EDIT_PRODUCT_FOLDER, handelAddEditProductFolder);
  yield takeEvery(GET_PRODUCT_FOLDER_DETAILS, handelGetProductFolderDetails);
  yield takeEvery(DELETE_PRODUCT_FOLDER, handleDeleteProductFolder);
  yield takeEvery(FETCH_PRODUCT_SITES, handleFetchProductSites);
  yield takeEvery(
    FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT,
    handleFetchProductVolumes
  );
}

export default productsSaga;
