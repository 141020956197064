// Va 06 - 01 - 21

import {
  CLEAR_ACTIVE_PRODUCTS,
  FETCH_ACTIVE_PRODUCT,
  FETCH_ACTIVE_PRODUCT_ERROR,
  FETCH_ACTIVE_PRODUCT_SUCCESS,
} from "./actionTypes";

// fetching active product
export const fetchActiveProduct = data => {
  return {
    type: FETCH_ACTIVE_PRODUCT,
    payload: data,
  };
};

export const fetchActiveProductSuccess = data => {
  return {
    type: FETCH_ACTIVE_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const fetchActiveProductError = data => {
  return {
    type: FETCH_ACTIVE_PRODUCT_ERROR,
    payload: data,
  };
};

export const clearActiveProducts = () => ({
  type: CLEAR_ACTIVE_PRODUCTS,
});
