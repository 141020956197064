//list subsctiption report
export const FATCH_NEW_LIST_ONBOARDING = "FATCH_NEW_LIST_ONBOARDING";
export const FATCH_NEW_LIST_ON_BOARD_SUCCESS =
  "FATCH_NEW_LIST_ON_BOARD_SUCCESS";
export const FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_ERROR =
  "FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_ERROR";

export const CLEAR_NEW_ACTIVE_SUBSCRIPTION = "CLEAR_NEW_ACTIVE_SUBSCRIPTION";

//TABLE SUBSCRIPTION REPORT
export const SET_NEW_PAGE_ACTIVE_SUBSCRIPTION =
  "SET_NEW_PAGE_ACTIVE_SUBSCRIPTION";
export const SET_NEW_SIZE_PER_PAGE_ACTIVE_SUBSCRIPTION =
  "SET_NEW_SIZE_PER_PAGE_ACTIVE_SUBSCRIPTION";
export const SET_NEW_SEARCH_ACTIVE_SUBSCRIPTION =
  "SET_NEW_SEARCH_ACTIVE_SUBSCRIPTION";
export const SET_NEW_SORT_FIELD_ACTIVE_SUBSCRIPTION =
  "SET_NEW_SORT_FIELD_ACTIVE_SUBSCRIPTION";
export const SET_NEW_SORT_ORDER_ACTIVE_SUBSCRIPTION =
  "SET_NEW_SORT_ORDER_ACTIVE_SUBSCRIPTION";

export const FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT =
  "FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT";
export const FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT_SUCCESS =
  "FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT_SUCCESS";
export const FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT_NULL =
  "FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT_NULL";

export const SET_NEW_ORDER_RANGE_DATE_ACTIVE_SUBSCRIPTION =
  "SET_NEW_ORDER_RANGE_DATE_ACTIVE_SUBSCRIPTION";

//SET_PAGE_ACTIVE_SUBSCRIPTION
