import { axiosApiAdmin } from "helpers/api_helper";
import { OVERALL_PERFORMANCE, OVERALL_SUBSCRIPTIONS } from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { setErrorMessage } from "store/actions";
import { tzs } from "../constants";
import { DateTimeTz } from "../utils";
import {
  fetchOverallPerformanceDataError,
  fetchOverallPerformanceDataSuccess,
  fetchOverallSubscriptionsDataError,
  fetchOverallSubscriptionsDataSuccess,
} from "./actions";
import {
  ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS,
  ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS,
} from "./actionTypes";

const fetchPerformanceReports = data =>
  axiosApiAdmin.post(OVERALL_PERFORMANCE, data);

const fetchSubscriptionsReports = data =>
  axiosApiAdmin.post(OVERALL_SUBSCRIPTIONS, data);

function* handleFetchPerformanceReports() {
  try {
    const { range, comparedRange, tz, siteId } = yield select(
      state => state.AnalyticsDashboard
    );

    const payload = {
      range: {
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(range.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      compared_range: {
        start_date: DateTimeTz(comparedRange.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(comparedRange.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      site_id: siteId,
      tz: tzs[tz],
    };

    const res = yield call(fetchPerformanceReports, payload);
    if (res.status) {
      yield put(fetchOverallPerformanceDataSuccess(res.data.data.reports));
    }
  } catch (err) {
    yield put(fetchOverallPerformanceDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* handleFetchSubscriptionsReports({ payload: { siteId } }) {
  try {
    const res = yield call(fetchSubscriptionsReports, { site_id: siteId });
    if (res.status) {
      yield put(
        fetchOverallSubscriptionsDataSuccess(res.data.data.subscription)
      );
    }
  } catch (err) {
    yield put(fetchOverallSubscriptionsDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* analyticsDashboardSaga() {
  yield takeEvery(
    ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS,
    handleFetchPerformanceReports
  );
  yield takeEvery(
    ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS,
    handleFetchSubscriptionsReports
  );
}
export default analyticsDashboardSaga;
