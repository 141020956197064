export const FETCH_THIRDPARTY_LIST = "FETCH_THIRDPARTY_LIST";
export const FETCH_THIRDPARTY_LIST_SUCCESS = "FETCH_THIRDPARTY_LIST_SUCCESS";
export const FETCH_THIRDPARTY_LIST_ERROR = "FETCH_THIRDPARTY_LIST_ERROR";
export const SET_ACTIVE_TABS = "SET_ACTIVE_TABS";

export const FETCH_ACTIVE_INACTIVE = "FETCH_ACTIVE_INACTIVE";
export const FETCH_ACTIVE_INACTIVE_SUCCESS = "FETCH_ACTIVE_INACTIVE_SUCCESS";

export const FETCH_THIRDPARTY_CONFIG_DETAILS =
  "FETCH_THIRDPARTY_CONFIG_DETAILS";
export const FETCH_THIRDPARTY_CONFIG_DETAILS_SUCCESS =
  "FETCH_THIRDPARTY_CONFIG_DETAILS_SUCCESS";

export const SAVE_THIRDPARTY_CONFIG_DETAILS = "SAVE_THIRDPARTY_CONFIG_DETAILS";
export const SAVE_THIRDPARTY_CONFIG_DETAILS_SUCCESS =
  "SAVE_THIRDPARTY_CONFIG_DETAILS_SUCCESS";
export const SAVE_THIRDPARTY_CONFIG_DETAILS_ERROR =
  "SAVE_THIRDPARTY_CONFIG_DETAILS_ERROR";

export const CIRCLE_SPACES_LIST = "CIRCLE_SPACES_LIST";
export const CIRCLE_SPACES_LIST_SUCCESS = "CIRCLE_SPACES_LIST_SUCCESS";
export const CIRCLE_SPACES_LIST_ERROR = "CIRCLE_SPACES_LIST_ERROR";

export const GET_THIRDPARTY_LOGS = "GET_THIRDPARTY_LOGS";
export const GET_THIRDPARTY_LOGS_SUCCESS = "GET_THIRDPARTY_LOGS_SUCCESS";
export const GET_THIRDPARTY_LOGS_ERROR = "GET_THIRDPARTY_LOGS_ERROR";

export const SET_LOGS_PAGES = "SET_LOGS_PAGES";
export const SET_LOGS_SEARCH = "SET_LOGS_SEARCH";
export const SET_LOGS_SIZE_PER_PAGE = "SET_LOGS_SIZE_PER_PAGE";

export const SET_ACTIVITY_TYPE = "SET_ACTIVITY_TYPE";
export const SET_STATUS_TYPE = "SET_STATUS_TYPE";
export const CLEAR_ACTIVITY_STATUS = "CLEAR_ACTIVITY_STATUS";
export const CLEAR_LOG_FEATURES = "CLEAR_LOG_FEATURES";

export const GET_SHIPMENT_METHOD = "GET_SHIPMENT_METHOD";
export const GET_SHIPMENT_METHOD_SUCCESS = "GET_SHIPMENT_METHOD_SUCCESS";
export const GET_SHIPMENT_METHOD_ERROR = "GET_SHIPMENT_METHOD_ERROR";

export const GET_SYCU_PRODUCTS = "GET_SYCU_PRODUCTS";
export const GET_SYCU_PRODUCTS_SUCCESS = "GET_SYCU_PRODUCTS_SUCCESS";
export const GET_SYCU_PRODUCTS_ERROR = "GET_SYCU_PRODUCTS_ERROR";

export const GET_SHIPBOB_PRODUCTS = "GET_SHIPBOB_PRODUCTS";
export const GET_SHIPBOB_PRODUCTS_SUCCESS = "GET_SHIPBOB_PRODUCTS_SUCCESS";
export const GET_SHIPBOB_PRODUCTS_ERROR = "GET_SHIPBOB_PRODUCTS_ERROR";

export const GET_SYCU_MAP_PRODUCTS = "GET_SYCU_MAP_PRODUCTS";
export const GET_SYCU_MAP_PRODUCTS_SUCCESS = "GET_SYCU_MAP_PRODUCTS_SUCCESS";
export const GET_SYCU_MAP_PRODUCTS_ERROR = "GET_SYCU_MAP_PRODUCTS_ERROR";

export const SAVE_SYCU_PRODUCTS = "SAVE_SYCU_PRODUCTS";
export const SAVE_SYCU_PRODUCTS_SUCCESS = "SAVE_SYCU_PRODUCTS_SUCCESS";
export const SAVE_SYCU_PRODUCTS_ERROR = "SAVE_SYCU_PRODUCTS_ERROR";

export const UPDATE_SHIPMENT = "UPDATE_SHIPMENT";
export const UPDATE_SHIPMENT_SUCCESS = "UPDATE_SHIPMENT_SUCCESS";
export const UPDATE_SHIPMENT_ERROR = "UPDATE_SHIPMENT_ERROR";

export const GET_SHIPBOB_CHANNEL = "GET_SHIPBOB_METHOD";
export const GET_SHIPBOB_CHANNEL_SUCCESS = "GET_SHIPBOB_METHOD_SUCCESS";
export const GET_SHIPBOB_CHANNEL_ERROR = "GET_SHIPBOB_METHOD_ERROR";

export const UPDATE_SHIPBOB_CHANNEL = "UPDATE_SHIPBOB_CHANNEL";
export const UPDATE_SHIPBOB_CHANNEL_SUCCESS = "UPDATE_SHIPBOB_CHANNEL_SUCCESS";
export const UPDATE_SHIPBOB_CHANNEL_ERROR = "UPDATE_SHIPBOB_CHANNEL_ERROR";
