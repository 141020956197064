import {
  defaultDocumentTitle,
  defaultImageParameters,
  defaultTextParameters,
  generateDefaultSlide,
  generateDefaultSlideWithoutText,
  initialBackgroundImageState,
} from "constants/slidr";
import clonedeep from "lodash.clonedeep";
import max from "lodash/max";
import { v4 as uuidV4 } from "uuid";
import {
  DECREMENT_NEW_SLIDE_COUNTER,
  INCREMENT_NEW_SLIDE_COUNTER,
  RESET_SLIDR,
  SAVE_SLIDE_SHOW,
  SAVE_SLIDE_SHOW_ERROR,
  SAVE_SLIDE_SHOW_SUCCESS,
  SET_GLOBAL_COPY_FIELD,
  SET_GLOBAL_PASTE_FIELD,
  SET_LAST_PUBLISHED,
  SET_SLIDE_SHOW_ID,
  SET_SLIDR_ACTIVE_FIELD,
  SET_SLIDR_ACTIVE_SLIDE,
  SET_SLIDR_BACKGROUND_OR_IMAGE_VIDEO,
  SET_SLIDR_DOCUMENT_TITLE,
  SET_SLIDR_FIELD_VALUE,
  SET_SLIDR_SETTINGS_OPTIONS,
  SET_SLIDR_SLIDES,
  SLIDR_ACTION_REDO,
  SLIDR_ACTION_UNDO,
  SLIDR_ADD_IMAGE,
  SLIDR_ADD_SLIDE,
  SLIDR_ADD_SLIDE_BELOW_CURRENT_SLIDE,
  SLIDR_ADD_SLIDE_WITHOUT_TEXT,
  SLIDR_ADD_TEXT,
  SLIDR_COPY_SLIDE,
  SLIDR_DELETE_SLIDE,
  SLIDR_FIELD_COPY,
  SLIDR_FIELD_DELETE,
  SLIDR_SET_SHOW_VIDEO_PREVIEW,
  SLIDR_SLIDES_CHANGE,
} from "./actionTypes";

const initialState = {
  loadingSave: false,
  slideShowId: null,
  lastPublished: "",
  settings: {
    id: 0,
    title: defaultDocumentTitle,
    feedCode: "",
    presentationSize: 1,
    autoSlide: 0,
    isImage: false,
    img: "",
    size: "cover",
    position: "center",
    repeat: "no-repeat",
    repeatSlideshow: false,
    slideNumbers: false,
    grids: false,
    color: null,
  },
  slides: [],
  activeField: "",
  activeSlide: "",
  isImage: false,
  showVideoPreview: false,
  copy: {
    isImage: false,
    field: null,
  },
  stack: {
    undo: [],
    redo: [],
  },
  newSlides: 0,
};

const getNewZIndex = slide =>
  (max([
    ...slide.imageFields.map(i => i.zIndex),
    ...slide.textFields.map(i => i.zIndex),
  ]) || 1000) + 1;

const removeStyle = (htmlString, value, replaceWith = "") =>
  htmlString.replaceAll(
    RegExp(`\\b${value}\\s*:\\s*([^;]+?)\\s*(;|$)`, "gi"),
    replaceWith
  );

const slidrReducer = (state = clonedeep(initialState), action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LAST_PUBLISHED:
      return {
        ...state,
        lastPublished: payload,
      };
    case INCREMENT_NEW_SLIDE_COUNTER:
      return {
        ...state,
        newSlides: state.newSlides + 1,
      };
    case DECREMENT_NEW_SLIDE_COUNTER:
      return {
        ...state,
        newSlides: state.newSlides - 1,
      };
    case SAVE_SLIDE_SHOW:
      return {
        ...state,
        loadingSave: true,
      };
    case SAVE_SLIDE_SHOW_SUCCESS:
      return {
        ...state,
        loadingSave: false,
      };
    case SAVE_SLIDE_SHOW_ERROR:
      return {
        ...state,
        loadingSave: false,
      };

    case SET_SLIDE_SHOW_ID:
      return {
        ...state,
        slideShowId: payload,
      };
    case SET_SLIDR_DOCUMENT_TITLE:
      return {
        ...state,
        settings: {
          ...state.settings,
          title: payload,
        },
      };
    case SET_SLIDR_ACTIVE_SLIDE:
      return {
        ...state,
        activeSlide: payload,
        activeField: "",
      };
    case SET_SLIDR_SLIDES:
      return {
        ...state,
        slides: payload,
      };
    case SLIDR_ADD_SLIDE:
      const addNewSlide = { ...clonedeep(generateDefaultSlide()), id: payload };
      return {
        ...state,
        slides: [
          ...state.slides,
          {
            ...addNewSlide,
            backgroundType: state.settings.isImage ? "image" : "color",
            backgroundImage: state.settings.isImage
              ? {
                  url: state.settings.img,
                  repeat: state.settings.repeat,
                  size: state.settings.size,
                  position: state.settings.position,
                }
              : {
                  ...initialBackgroundImageState,
                },
            backgroundColor: state.settings.isImage
              ? {
                  r: "255",
                  g: "255",
                  b: "255",
                  a: "1",
                }
              : !!state.settings.color
              ? state.settings.color
              : {
                  r: "255",
                  g: "255",
                  b: "255",
                  a: "1",
                },
          },
        ],
        activeSlide: addNewSlide.id,
      };
    case SLIDR_ADD_SLIDE_BELOW_CURRENT_SLIDE:
      const addNewSlideData = {
        ...clonedeep(generateDefaultSlide()),
        id: payload.id,
        backgroundType: state.settings.isImage ? "image" : "color",
        backgroundImage: state.settings.isImage
          ? {
              url: state.settings.img,
              repeat: state.settings.repeat,
              size: state.settings.size,
              position: state.settings.position,
            }
          : {
              ...initialBackgroundImageState,
            },
        backgroundColor: state.settings.isImage
          ? {
              r: "255",
              g: "255",
              b: "255",
              a: "1",
            }
          : !!state.settings.color
          ? state.settings.color
          : {
              r: "255",
              g: "255",
              b: "255",
              a: "1",
            },
      };
      const slidesArr = clonedeep(state.slides);
      slidesArr.splice(payload.index, 0, addNewSlideData);
      return {
        ...state,
        slides: slidesArr,
        activeSlide: addNewSlideData.id,
      };

    case SLIDR_ADD_SLIDE_WITHOUT_TEXT:
      const addSlideWithImage = {
        ...clonedeep(generateDefaultSlideWithoutText()),
        id: payload.id,
        backgroundType: payload.url ? "image" : "color",
        backgroundImage: payload.url
          ? {
              url: payload.url,
              repeat: "no-repeat",
              size: "cover",
              position: "center",
            }
          : {
              ...initialBackgroundImageState,
            },
        backgroundColor: state.settings.isImage
          ? {
              r: "255",
              g: "255",
              b: "255",
              a: "1",
            }
          : !!state.settings.color
          ? state.settings.color
          : {
              r: "255",
              g: "255",
              b: "255",
              a: "1",
            },
      };
      const slidesArr1 = clonedeep(state.slides);
      slidesArr1.splice(payload.currentIndex + 1, 0, addSlideWithImage);
      return {
        ...state,
        slides: slidesArr1,
        activeSlide: addSlideWithImage.id,
      };

    case SLIDR_COPY_SLIDE:
      const slides = clonedeep(state.slides);
      const newSlide = {
        ...clonedeep(slides.find(slide => slide.id === payload.copied_id)),
        id: payload.id,
      };
      const index = slides.findIndex(slide => slide.id === payload.copied_id);
      slides.splice(index + 1, 0, newSlide);
      return {
        ...state,
        slides,
        activeSlide: newSlide.id,
      };
    case SLIDR_DELETE_SLIDE:
      const deleteSlides = clonedeep(state.slides);
      const deleteIndex = deleteSlides.findIndex(slide => slide.id === payload);
      return {
        ...state,
        slides: deleteSlides.filter(slide => slide.id !== payload),
        activeSlide:
          payload === state.activeSlide
            ? deleteIndex === 0
              ? deleteSlides[1].id
              : deleteSlides[deleteIndex - 1].id
            : state.activeSlide,
      };
    case SET_SLIDR_ACTIVE_FIELD:
      return {
        ...state,
        activeField: payload.id,
        isImage: !!payload.isImage,
      };

    case SET_SLIDR_FIELD_VALUE:
      const fieldType =
        payload.isImage !== undefined && payload.isImage !== null
          ? payload.isImage
            ? "imageFields"
            : "textFields"
          : "";
      return {
        ...state,
        slides: state.slides.map(slide => {
          if (slide.id === state.activeSlide) {
            slide[
              !!fieldType
                ? fieldType
                : state.isImage
                ? "imageFields"
                : "textFields"
            ].map(field => {
              if (
                field.id === (!!payload.id ? payload.id : state.activeField)
              ) {
                if (
                  ["isBold", "isItalic", "isUnderline"].includes(payload.key)
                ) {
                  field.text = removeStyle(field.text, "font-weight");
                  field.text = removeStyle(field.text, "font-style");
                  field.text = removeStyle(field.text, "font-decoration");
                } else if (payload.key === "fontFamily") {
                  field.text = removeStyle(
                    field.text,
                    "font-family",
                    "font-family: inherit;"
                  );
                } else if (payload.key === "fontSize") {
                  field.text = removeStyle(field.text, "font-size");
                } else if (payload.key === "color") {
                  field.text = removeStyle(field.text, "color");
                } else if (payload.key === "textAlign") {
                  field.text = removeStyle(field.text, "text-align");
                } else if (payload.key === "lineHeight") {
                  field.text = removeStyle(field.text, "line-height");
                }
                field[payload.key] = payload.value;
              }
              return field;
            });
          }
          return slide;
        }),
      };
    case SLIDR_FIELD_DELETE:
      return {
        ...state,
        slides: state.slides.map(slide => {
          if (slide.id === state.activeSlide) {
            slide[state.isImage ? "imageFields" : "textFields"] = slide[
              state.isImage ? "imageFields" : "textFields"
            ].filter(field => field.id !== state.activeField);
          }
          return slide;
        }),
        activeField: "",
      };
    case SLIDR_FIELD_COPY:
      return {
        ...state,
        slides: state.slides.map(slide => {
          if (slide.id === state.activeSlide) {
            const activeTextField = slide[
              state.isImage ? "imageFields" : "textFields"
            ].find(field => field.id === state.activeField);
            slide[state.isImage ? "imageFields" : "textFields"] = [
              ...slide[state.isImage ? "imageFields" : "textFields"],
              clonedeep({ ...activeTextField, id: uuidV4() }),
            ];
          }
          return slide;
        }),
      };
    case SLIDR_ADD_TEXT:
      return {
        ...state,
        slides: state.slides.map(slide => {
          if (slide.id === state.activeSlide) {
            slide.textFields = [
              ...slide.textFields,
              clonedeep({
                ...defaultTextParameters,
                id: uuidV4(),
                zIndex: getNewZIndex(slide),
              }),
            ];
          }
          return slide;
        }),
      };
    case SLIDR_ADD_IMAGE:
      return {
        ...state,
        slides: state.slides.map(slide => {
          if (slide.id === state.activeSlide) {
            slide.imageFields = [
              ...slide.imageFields,
              {
                ...defaultImageParameters,
                id: uuidV4(),
                url: payload,
                zIndex: getNewZIndex(slide),
              },
            ];
          }
          return slide;
        }),
      };
    case SET_SLIDR_BACKGROUND_OR_IMAGE_VIDEO:
      if (payload.type === "video") {
        const copySlide = clonedeep({
          ...generateDefaultSlide(),
          id: payload.value.id,
        });
        const newSlides2 = clonedeep(state.slides);
        const currentSideIndex = state.slides.findIndex(
          slide => slide.id === state.activeSlide
        );
        newSlides2.splice(
          currentSideIndex + 1,
          0,
          clonedeep({
            ...copySlide,
            videoUrl: payload.value.videoUrl,
            videoPreviewImage: payload.value.videoPreviewImage,
            textFields: [],
            backgroundType: state.settings.isImage ? "image" : "color",
            backgroundImage: state.settings.isImage
              ? {
                  url: state.settings.img,
                  repeat: state.settings.repeat,
                  size: state.settings.size,
                  position: state.settings.position,
                }
              : {
                  ...initialBackgroundImageState,
                },
            backgroundColor: state.settings.isImage
              ? {
                  r: "0",
                  g: "0",
                  b: "0",
                  a: "1",
                }
              : state.settings.color || {
                  r: "0",
                  g: "0",
                  b: "0",
                  a: "1",
                },
          })
        );
        return {
          ...state,
          slides: clonedeep(newSlides2),
          activeSlide: copySlide.id,
        };
      } else {
        return {
          ...state,
          slides: state.slides.map(slide => {
            if (slide.id === state.activeSlide) {
              if (payload.type === "color") {
                slide.backgroundColor = payload.value;
                slide.backgroundImage = {
                  ...initialBackgroundImageState,
                };
                slide.backgroundType = "color";
              } else if (payload.type === "image") {
                slide.backgroundImage = payload.value;
                slide.backgroundColor = {
                  r: "255",
                  g: "255",
                  b: "255",
                  a: "1",
                };
                slide.backgroundType = "image";
              } else {
              }
            }
            return slide;
          }),
          activeSlide: state.activeSlide,
        };
      }

    case SLIDR_SET_SHOW_VIDEO_PREVIEW:
      return { ...state, showVideoPreview: payload };
    case SET_SLIDR_SETTINGS_OPTIONS:
      const isPresentationSizeChange =
        state.settings.presentationSize !== payload.presentationSize;
      const isBgValueChange = payload.isBgValueChange;
      delete payload.isBgValueChange;
      return {
        ...state,
        settings: {
          ...state.settings,
          ...payload,
        },
        slides: state.slides.map(slide => {
          if (isPresentationSizeChange) {
            slide.textFields.map(textField => {
              if (
                payload.presentationSize === 2 &&
                +textField.width.replace("px", "") > 960
              ) {
                textField.width = "960px";
              }
              return textField;
            });
          }
          if (isBgValueChange) {
            if (payload.isImage) {
              slide.backgroundType = "image";
              slide.backgroundImage = {
                url: payload.img,
                repeat: payload.repeat,
                size: payload.size,
                position: payload.position,
              };
              slide.backgroundColor = {
                r: "255",
                g: "255",
                b: "255",
                a: "1",
              };
            } else {
              if (!!payload.color) {
                slide.backgroundType = "color";
                slide.backgroundColor = payload.color;
                slide.backgroundImage = { ...initialBackgroundImageState };
              }
            }
          }
          return slide;
        }),
      };
    case SET_GLOBAL_COPY_FIELD:
      return {
        ...state,
        copy: {
          isImage: payload.isImage,
          field: payload.field,
        },
      };
    case SET_GLOBAL_PASTE_FIELD:
      return {
        ...state,
        slides: state.slides.map(slide => {
          if (slide.id === state.activeSlide) {
            const field = clonedeep(state.copy.field);
            slide[state.copy.isImage ? "imageFields" : "textFields"] = [
              ...slide[state.isImage ? "imageFields" : "textFields"],
              clonedeep({
                ...field,
                id: uuidV4(),
                zIndex: getNewZIndex(slide),
              }),
            ];
          }

          return slide;
        }),
      };
    case SLIDR_SLIDES_CHANGE:
      return {
        ...state,
        stack: {
          ...state.stack,
          undo: [...clonedeep(state.stack.undo), clonedeep(payload)],
          redo: state.stack.redo.length ? [] : clonedeep(state.stack.redo),
        },
      };
    case SLIDR_ACTION_UNDO:
      const newUndoState = clonedeep(state.stack.undo);
      const addToRedo = newUndoState.pop();
      const newSlides = newUndoState[newUndoState.length - 1];
      const activeSlideId = newSlides.find(
        slide => slide.id === state.activeSlide
      )?.id;

      return {
        ...state,
        stack: {
          ...state.stack,
          undo: clonedeep(newUndoState),
          redo: [clonedeep(addToRedo), ...clonedeep(state.stack.redo)],
        },
        slides: clonedeep(newSlides),
        activeSlide: !!activeSlideId
          ? activeSlideId
          : newSlides[newSlides.length - 1].id,
      };

    case SLIDR_ACTION_REDO:
      const newRedoState = clonedeep(state.stack.redo);
      const addToUndo = newRedoState.shift();

      return {
        ...state,
        stack: {
          ...state.stack,
          undo: [...clonedeep(state.stack.undo), clonedeep(addToUndo)],
          redo: clonedeep(newRedoState),
        },

        slides: clonedeep(addToUndo),
      };
    case RESET_SLIDR:
      return {
        ...clonedeep(initialState),
      };

    default:
      return {
        ...state,
      };
  }
};

export default slidrReducer;
