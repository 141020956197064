import moment from "moment";
import {
  LIST_SIGNUP_METRIC_REPORT_ERROR,
  LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA,
  LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA_ERROR,
  LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA_SUCCESS,
  LIST_SIGNUP_METRIC_REPORT_LOADING,
  LIST_SIGNUP_METRIC_REPORT_SUCCESS,
} from "./actionTypes";
const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();

const initialState = {
  range: {
    startDate,
    endDate,
  },
  tz: "-0400",
  matrixDataLoading: false,
  matrixGraphDataLoading: false,
  matrixData: [],
  matrixGraphData: [],
};

const SuccessMetricReportReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LIST_SIGNUP_METRIC_REPORT_LOADING:
      return {
        ...state,
        matrixDataLoading: true,
      };

    case LIST_SIGNUP_METRIC_REPORT_SUCCESS:
      return {
        ...state,
        matrixData: payload,
        matrixDataLoading: false,
      };

    case LIST_SIGNUP_METRIC_REPORT_ERROR:
      return {
        ...state,
        matrixDataLoading: false,
      };

    case LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA:
      return {
        ...state,
        matrixGraphDataLoading: true,
      };

    case LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        matrixGraphData: payload,
        matrixGraphDataLoading: false,
      };

    case LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA_ERROR:
      return {
        ...state,
        matrixGraphDataLoading: false,
      };

    default:
      return state;
  }
};

export default SuccessMetricReportReducer;
