import {
  ADD_HABIT,
  ADD_HABIT_ERROR,
  ADD_HABIT_SUCCESS,
  CLEAR_HABIT_FEATURES,
  DELETE_HABIT,
  DELETE_HABIT_ERROR,
  DELETE_HABIT_SUCCESS,
  EDIT_HABIT,
  EDIT_HABIT_ERROR,
  EDIT_HABIT_SUCCESS,
  FETCH_HABIT,
  FETCH_HABIT_ERROR,
  FETCH_HABIT_SUCCESS,
  GET_HABIT_DETAIL,
  GET_HABIT_DETAIL_ERROR,
  GET_HABIT_DETAIL_SUCCESS,
  SET_CATEGORY_ID_HABIT,
  SET_FROM,
  SET_PAGE_HABIT,
  SET_SEARCH_HABIT,
  SET_SIZE_PER_PAGE_HABIT,
  SET_SORT_FILED_HABIT,
  SET_SORT_ORDER_HABIT,
} from "./actionTypes";

export const fetchHabit = data => {
  return {
    type: FETCH_HABIT,
    payload: data,
  };
};

export const fetchHabitSuccess = data => ({
  type: FETCH_HABIT_SUCCESS,
  payload: data,
});

export const fetchHabitError = data => ({
  type: FETCH_HABIT_ERROR,
  payload: data,
});

export const fetchHabitById = data => ({
  type: GET_HABIT_DETAIL,
  payload: data,
});

export const fetchHabitByIdSuccess = data => ({
  type: GET_HABIT_DETAIL_SUCCESS,
  payload: data,
});

export const fetchHabitByIdError = () => ({
  type: GET_HABIT_DETAIL_ERROR,
});

export const setPageHabit = data => ({
  type: SET_PAGE_HABIT,
  payload: data,
});

export const setSizePerPageHabit = data => ({
  type: SET_SIZE_PER_PAGE_HABIT,
  payload: data,
});

export const setSearchHabit = data => ({
  type: SET_SEARCH_HABIT,
  payload: data,
});

export const setIsFrom = data => ({
  type: SET_FROM,
  payload: data,
});

export const clearHabitFeatures = () => ({
  type: CLEAR_HABIT_FEATURES,
});

export const addHabit = data => ({
  type: ADD_HABIT,
  payload: data,
});

export const addHabitSuccess = data => ({
  type: ADD_HABIT_SUCCESS,
  payload: data,
});

export const addHabitError = () => ({
  type: ADD_HABIT_ERROR,
});

export const editHabit = data => ({
  type: EDIT_HABIT,
  payload: data,
});

export const editHabitSuccess = data => ({
  type: EDIT_HABIT_SUCCESS,
  payload: data,
});

export const editHabitError = () => ({
  type: EDIT_HABIT_ERROR,
});

export const deleteHabit = data => ({
  type: DELETE_HABIT,
  payload: data,
});

export const deleteHabitSuccess = data => ({
  type: DELETE_HABIT_SUCCESS,
  payload: data,
});

export const deleteHabitError = data => ({
  type: DELETE_HABIT_ERROR,
  payload: data,
});

export const setSortFieldHabit = data => ({
  type: SET_SORT_FILED_HABIT,
  payload: data,
});

export const setSortOrderHabit = data => ({
  type: SET_SORT_ORDER_HABIT,
  payload: data,
});

export const setCategoryIdHabit = data => ({
  type: SET_CATEGORY_ID_HABIT,
  payload: data,
});
