// Va 06 - 01 - 21

import {
  CLEAR_ACTIVE_PRODUCTS,
  FETCH_ACTIVE_PRODUCT,
  FETCH_ACTIVE_PRODUCT_ERROR,
  FETCH_ACTIVE_PRODUCT_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  activeProducts: [],
  totalSize: 0,
  sizePerPage: 25,
};

const activeProductReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_ACTIVE_PRODUCTS:
      return {
        ...state,
        activeProducts: [],
      };
    case FETCH_ACTIVE_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ACTIVE_PRODUCT_SUCCESS:
      return {
        ...state,
        activeProducts: [...state.activeProducts, ...payload.data],
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_ACTIVE_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default activeProductReducer;
