export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_ERROR = "FETCH_ORDERS_ERROR";
export const SET_ORDERS_PAGE = "SET_ORDERS_PAGE";
export const SET_ORDERS_SIZE_PER_PAGE = "SET_ORDERS_SIZE_PER_PAGE";
export const SET_ORDER_SEARCH = "SET_ORDER_SEARCH";
export const CLEAR_ORDERS_FEATURES = "CLEAR_ORDERS_FEATURES";
export const FILTER_BY_ORDER_STATUS = "FILTER_BY_ORDER_STATUS";
export const SET_ORDER_SORT_FIELD = "SET_ORDER_SORT_FIELD";
export const SET_ORDER_SORT_ORDER = "SET_ORDER_SORT_ORDER";
