import { axiosApiAdmin } from "helpers/api_helper";
import {
  GET_COUPONS_GRAPH_DATA,
  GET_COUPONS_REPORTS_DATA,
  GET_COUPONS_Table_DATA,
} from "helpers/url_helper";
import { tzs } from "modules/Analytics/common/constants";
import { DateTimeTz } from "modules/Analytics/common/utils";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  getCouponsGraphDataError,
  getCouponsGraphDataSuccess,
  getCouponsReportsError,
  getCouponsReportsSuccess,
  getCouponsTableDataError,
  getCouponsTableDataSuccess,
  setErrorMessage,
} from "store/actions";
import {
  FETCH_COUPONS_GRAPH_DATA,
  FETCH_COUPONS_REPORTS,
  FETCH_COUPONS_TABLE_DATA,
} from "./actionTypes";

const fetchCouponsReports = data =>
  axiosApiAdmin.post(GET_COUPONS_REPORTS_DATA, data);

const fetchCouponsGraphData = data =>
  axiosApiAdmin.post(GET_COUPONS_GRAPH_DATA, data);

const fetchCouponsTableData = data =>
  axiosApiAdmin.post(GET_COUPONS_Table_DATA, data);

function* handleFetchCouponsReports() {
  try {
    const { range, comparedRange, siteId, tz } = yield select(
      state => state.AnalyticsCoupons
    );
    const payload = {
      current_date_range: {
        start_date: DateTimeTz(range.startDate)
          // .startTime(tz)
          // .format("YYYY-MM-DD HH:mm"),
          .format("YYYY-MM-DD"),
        end_date: DateTimeTz(range.endDate)
          // .endTime(tz)
          // .format("YYYY-MM-DD HH:mm"),
          .format("YYYY-MM-DD"),
      },
      past_date_range: {
        start_date: DateTimeTz(comparedRange.startDate)
          // .startTime(tz)
          // .format("YYYY-MM-DD HH:mm"),
          .format("YYYY-MM-DD"),
        end_date: DateTimeTz(comparedRange.endDate)
          // .endTime(tz)
          // .format("YYYY-MM-DD HH:mm"),
          .format("YYYY-MM-DD"),
      },
      site_id: siteId,
      tz: tzs[tz],
    };

    const res = yield call(fetchCouponsReports, payload);
    if (res.status) {
      yield put(getCouponsReportsSuccess(res.data.data.reports));
    }
  } catch (err) {
    yield put(getCouponsReportsError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* handleFetchCouponsGraphData() {
  try {
    const { range, comparedRange, activeTab, by, siteId, tz } = yield select(
      state => state.AnalyticsCoupons
    );
    const payload = {
      current_range: {
        start_date: DateTimeTz(range.startDate)
          // .startTime(tz)
          // .format("YYYY-MM-DD HH:mm"),
          .format("YYYY-MM-DD"),
        end_date: DateTimeTz(range.endDate)
          // .endTime(tz)
          // .format("YYYY-MM-DD HH:mm"),
          .format("YYYY-MM-DD"),
      },
      previous_range: {
        start_date: DateTimeTz(comparedRange.startDate)
          // .startTime(tz)
          // .format("YYYY-MM-DD HH:mm"),
          .format("YYYY-MM-DD"),
        end_date: DateTimeTz(comparedRange.endDate)
          // .endTime(tz)
          // .format("YYYY-MM-DD HH:mm"),
          .format("YYYY-MM-DD"),
      },
      filter: by,
      type: activeTab,
      site_id: siteId,
      tz: tzs[tz],
    };

    const res = yield call(fetchCouponsGraphData, payload);

    if (res.status) {
      yield put(getCouponsGraphDataSuccess(res.data?.data?.rows || []));
    }
  } catch (err) {
    yield put(getCouponsGraphDataError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* handleFetchCouponsTableData() {
  try {
    const {
      tableData: { page, sizePerPage, sortOrder, sortField, searchCoupon },
      range,
      comparedRange,
      siteId,
      tz,
    } = yield select(state => state.AnalyticsCoupons);

    const payload = {
      page_record: sizePerPage,
      page_no: page,
      sort_field: sortField,
      sort_order: sortOrder,
      current_date_range: {
        start_date: DateTimeTz(range.startDate)
          // .startTime(tz)
          // .format("YYYY-MM-DD HH:mm"),
          .format("YYYY-MM-DD"),
        end_date: DateTimeTz(range.endDate)
          // .endTime(tz)
          // .format("YYYY-MM-DD HH:mm"),
          .format("YYYY-MM-DD"),
      },
      past_date_range: {
        start_date: DateTimeTz(comparedRange.startDate)
          // .startTime(tz)
          // .format("YYYY-MM-DD HH:mm"),
          .format("YYYY-MM-DD"),
        end_date: DateTimeTz(comparedRange.endDate)
          // .endTime(tz)
          // .format("YYYY-MM-DD HH:mm"),
          .format("YYYY-MM-DD"),
      },
      search: searchCoupon,
      site_id: siteId,
      tz: tzs[tz],
    };

    const res = yield call(fetchCouponsTableData, payload);
    if (res.status) {
      if (res.data?.data)
        yield put(
          getCouponsTableDataSuccess({
            totalSize: res.data.data.count,
            ordersCount: 0,
            rows: res.data.data.rows,
            tableFooter: {
              amount_discounted: !!res?.data?.data?.amount_discounted
                ? res?.data?.data?.amount_discounted.toFixed(2)
                : 0,
              coupon_count: res.data.data.coupon_count,
              orders: res.data.data.orders,
            },
          })
        );
    }
  } catch (err) {
    yield put(getCouponsTableDataError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* couponsSaga() {
  yield takeEvery(FETCH_COUPONS_REPORTS, handleFetchCouponsReports);
  yield takeEvery(FETCH_COUPONS_GRAPH_DATA, handleFetchCouponsGraphData);
  yield takeEvery(FETCH_COUPONS_TABLE_DATA, handleFetchCouponsTableData);
}

export default couponsSaga;
