// Va 05 - 01 - 21
import { axiosApiAdmin } from "helpers/api_helper";
import { FETCH_EMAIL_LOGS_URL } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchEmailLogsError,
  fetchEmailLogsSuccess,
  setErrorMessage,
} from "store/actions";
import { FETCH_EMAIL_LOGS } from "./actionTypes";

const fetchEmailLogs = data => axiosApiAdmin.post(FETCH_EMAIL_LOGS_URL, data);

function* handleFetchEmailLogs({ payload }) {
  try {
    const res = yield call(fetchEmailLogs, payload);
    if (res.status) {
      yield put(fetchEmailLogsSuccess(res.data));
    }
  } catch (err) {
    yield put(fetchEmailLogsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* emailLogsSaga() {
  yield takeEvery(FETCH_EMAIL_LOGS, handleFetchEmailLogs);
}

export default emailLogsSaga;
