import {
  ADD_EDIT_FILTERS,
  ADD_EDIT_FILTERS_ERROR,
  ADD_EDIT_FILTERS_SUCCESS,
  CLEAR_FILTER_FEATURES,
  DELETE_FILTERS,
  DELETE_FILTERS_ERROR,
  DELETE_FILTERS_SUCCESS,
  FETCH_FILTERS,
  FETCH_FILTERS_ERROR,
  FETCH_FILTERS_SUCCESS,
  GET_FILTERS_DETAILS,
  GET_FILTERS_DETAILS_ERROR,
  GET_FILTERS_DETAILS_SUCCESS,
  SET_PAGE_FILTERS,
  SET_SEARCH_FILTERS,
  SET_SIZE_PER_PAGE_FILTERS,
  SET_SORT_FIELD_FILTERS,
  SET_SORT_ORDER_FILTERS,
} from "./actionsTypes";

export const fetchFilters = data => {
  return {
    type: FETCH_FILTERS,
    payload: data,
  };
};

export const fetchFiltersSuccess = data => {
  return {
    type: FETCH_FILTERS_SUCCESS,
    payload: data,
  };
};

export const fetchFiltersError = data => {
  return {
    type: FETCH_FILTERS_ERROR,
    payload: data,
  };
};

export const setPageFilters = data => {
  return {
    type: SET_PAGE_FILTERS,
    payload: data,
  };
};

export const setSizePerPageFilters = data => {
  return {
    type: SET_SIZE_PER_PAGE_FILTERS,
    payload: data,
  };
};

export const setSearchFilters = data => {
  return {
    type: SET_SEARCH_FILTERS,
    payload: data,
  };
};

export const clearFilterFeatures = () => {
  return {
    type: CLEAR_FILTER_FEATURES,
  };
};

export const setSortFieldFilters = data => {
  return {
    type: SET_SORT_FIELD_FILTERS,
    payload: data,
  };
};

export const setSortOrderFilters = data => {
  return {
    type: SET_SORT_ORDER_FILTERS,
    payload: data,
  };
};

export const addEditFilters = (data, filterType, cb) => {
  return {
    type: ADD_EDIT_FILTERS,
    payload: data,
    filterType: filterType,
    cb,
  };
};

export const addEditFiltersSuccess = data => {
  return {
    type: ADD_EDIT_FILTERS_SUCCESS,
    payload: data,
  };
};

export const addEditFiltersError = () => {
  return {
    type: ADD_EDIT_FILTERS_ERROR,
  };
};

export const deleteFilters = data => {
  return {
    type: DELETE_FILTERS,
    payload: data,
  };
};

export const deleteFiltersSuccess = data => {
  return {
    type: DELETE_FILTERS_SUCCESS,
    payload: data,
  };
};

export const deleteFiltersError = data => {
  return {
    type: DELETE_FILTERS_ERROR,
    payload: data,
  };
};

export const fetchFiltersById = data => {
  return {
    type: GET_FILTERS_DETAILS,
    payload: data,
  };
};

export const fetchFiltersByIdSuccess = data => {
  return {
    type: GET_FILTERS_DETAILS_SUCCESS,
    payload: data,
  };
};

export const fetchFiltersByIdError = () => {
  return {
    type: GET_FILTERS_DETAILS_ERROR,
  };
};
