import Applications from "modules/applications/store/reducer";
import Login from "modules/authentication/store/reducer";
import Coupon from "modules/coupons/store/reducer";
import Dashboard from "modules/dashboard/store/reducer";
import MembershipPlan from "modules/membership/membershipPlan/store/reducer";
import UserMembership from "modules/membership/userMembership/store/reducer";
import Products from "modules/product/store/reducer";
import ActiveMembership from "modules/profile/store/activeMembership/reducer";
import ActiveProduct from "modules/profile/store/activeProduct/reducer";
import ActiveSubscriptions from "modules/profile/store/activeSubscriptions/reducer";
import EmailLogs from "modules/profile/store/emailLogs/reducer";
import LoginLogs from "modules/profile/store/loginLogs/reducer";
import PaymentMethod from "modules/profile/store/paymentMethods/reducer";
import Profile from "modules/profile/store/reducer";
import supportTicketsReducer from "modules/profile/store/supportTickets/reducer";
import Transaction from "modules/profile/store/transaction/reducer";
import Subscription from "modules/subscription/store/reducer";
import VolumeReducer from "modules/tools/store/reducer";
import CurriculumPostSeriesReducer from "modules/tools/store/SeriesEditor/reducer";
import VolumepagesReducer from "modules/tools/store/volume-product/reducer";
import User from "modules/users/store/reducer";
// Game Module
import Filters from "modules/Game/Filters/store/reducer";
import Game from "modules/Game/Games/store/reducer";
import Icebreaker from "modules/Game/Icebreakers/store/reducer";
import Review from "modules/Game/Review/store/reducer";
//Habit module
import HabitReducer from "modules/Habit/Habits/store/reducer";
import { combineReducers } from "redux";

import Layout from "modules/layout/store/reducer";

import Common from "./common/reducer";

import ApplicationSubscriptions from "modules/applications/store/subscriptions/reducer";
import PermissionBuilder from "modules/permissionBuilder/store/reducer";
import SystemPages from "modules/systemPages/store/reducer";
import CurriculumPostPage from "modules/tools/store/PageEditor/reducer";
import Slidr from "modules/tools/store/Slidr/reducer";
import OrderNote from "./orderNote/reducer";
//profile

import CloudS3Reducer from "modules/CloudS3/store/reducer";
import CloudStorage from "modules/CloudStorage/store/reducer";
import DebuggerTool from "modules/debuggerTool/store/reducer";
import Integrations from "modules/integration/store/reducer";
import Redirections from "modules/redirections/store/reducer";
import Report from "modules/Reports/store/reducers";

import Orders from "modules/applications/store/orders/reducer";
import EmailReducer from "modules/EmailLogs/store/reducer";
import Log from "modules/Logs/store/reducer";

import AnalyticsRenewal from "modules/Analytics/Renewal/store/reducer";
import RealtimeReducer from "modules/Realtime/store/reducer";

import ActiveSubscription from "modules/ActiveSubscriptionReport/store/reducer";
import AnalyticsCoupons from "modules/Analytics/Coupons/store/reducer";
import AnalyticsEmails from "modules/Analytics/Emails/store/reducer";
import AnalyticsOrders from "modules/Analytics/Orders/store/reducer";
import AnalyticsProducts from "modules/Analytics/Products/store/reducer";
import AnalyticsRevenue from "modules/Analytics/Revenues/store/reducer";
import SubscriptionReducer from "modules/Analytics/Subscriptions/store/reducer";
import AnalyticsUsers from "modules/Analytics/Users/store/reducer";
import AnalyticsDashboard from "modules/AnalyticsDashboard/store/reducer";
import FreeTrialReducer from "modules/applications/components/FreeTrial/store/reducer";
import GrowBooksReducer from "modules/applications/components/GrowBooks/store/reducer";
import GrowConSlider from "modules/applications/components/GrowCon Slidr/store/reducer";
import BlogSiteMap from "modules/BlogSiteMap/store/reducer";
import CalenderCardCancelReducer from "modules/CalenderCardCancel/store/reducer";
import CampaignDataReducer from "modules/Campaign Report/store/reducer.js";
import CancelSubscriptionReport from "modules/CancelSubscriptionReport/store/reducer";
import CheckoutCampaignReportReducer from "modules/CheckoutCampaignReport/store/reducer.js";
import ChurchesReducer from "modules/Churches/store/reducer";
import DisputedTransactions from "modules/DisputedTransactions/store/reducer";
import ExpiredSubscription from "modules/ExpiredSubscription/store/reducer";
import FailSubscription from "modules/FailSubscription/store/reducer";
import FeedbackOption from "modules/Feedback Options/store/reducer";
import FormerSubscription from "modules/FormerSubscription/store/reducer";
import FreeTrialOnBoardingReport from "modules/FreeTrialOnBoardingReport/store/reducer";
import geoLocation from "modules/GeoLocation/store/reducer";
import category from "modules/Habit/Categories/store/reducer";
import LiveChatArchiveReducer from "modules/LiveChatArchives/store/reducer";
import metricReport from "modules/MetricReport/store/reducer";
import ModalFeedbackReport from "modules/ModalFeedbackReport/store/reducer";
import NewActiveSubscriptionReducer from "modules/NewActiveSubscriptionReport/store/reducer";
import adminActivityLogs from "modules/profile/store/activeLog/reducer";
import SubscriptionFeedbackReport from "modules/SubscriptionFeedbackReport/store/reducer";
import subscriptionReportReducer from "modules/SubscriptionReport/store/reducer";
import SuccessSubscription from "modules/SuccessSubscriptionReport/store/reducer";
import SupportTicket from "modules/SupportTicket/store/reducer";
import BoardReducer from "modules/tools/pages/DigitalBoard/store/reducer";
import GrowCon from "../modules/GrowConReport/store/reducer";

const rootReducer = combineReducers({
  AnalyticsDashboard,
  Applications,
  Login,
  Dashboard,
  Coupon,
  MembershipPlan,
  UserMembership,
  Products,
  Subscription,
  User,
  Profile,
  PaymentMethod,
  LoginLogs,
  ActiveProduct,
  ActiveMembership,
  ActiveSubscriptions,
  EmailLogs,
  Transaction,
  VolumeReducer,
  VolumepagesReducer,
  CurriculumPostSeriesReducer,
  Layout,
  CurriculumPostPage,
  Common,
  OrderNote,
  SystemPages,
  Game,
  HabitReducer,
  Icebreaker,
  Filters,
  PermissionBuilder,
  ApplicationSubscriptions,
  Review,
  Slidr,
  Redirections,
  Integrations,
  CloudStorage,
  CloudS3Reducer,
  supportTicketsReducer,
  DebuggerTool,
  Report,
  Orders,
  Log,
  EmailReducer,
  AnalyticsRenewal,
  AnalyticsRevenue,
  AnalyticsCoupons,
  AnalyticsProducts,
  AnalyticsUsers,
  SubscriptionReducer,
  AnalyticsEmails,
  AnalyticsOrders,
  RealtimeReducer,
  category,
  FailSubscription,
  subscriptionReportReducer,
  DisputedTransactions,
  adminActivityLogs,
  FormerSubscription,
  SuccessSubscription,
  ActiveSubscription,
  ExpiredSubscription,
  LiveChatArchiveReducer,
  geoLocation,
  metricReport,
  CampaignDataReducer,
  CheckoutCampaignReportReducer,
  CancelSubscriptionReport,
  FeedbackOption,
  SubscriptionFeedbackReport,
  GrowCon,
  BlogSiteMap,
  GrowConSlider,
  FreeTrialReducer,
  GrowBooksReducer,
  FreeTrialOnBoardingReport,
  NewActiveSubscriptionReducer,
  ChurchesReducer,
  CalenderCardCancelReducer,
  ModalFeedbackReport,
  BoardReducer,
  SupportTicket,
});

export default rootReducer;
