// Smit 22-11

import {
  API_ERROR,
  CLEAR_MESSAGES,
  FETCH_LOGGED_IN_USER_PROFILE,
  FETCH_LOGGED_IN_USER_PROFILE_ERROR,
  FETCH_LOGGED_IN_USER_PROFILE_SUCCESS,
  LOGIN_EMAIL_MESSAGE,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  user: null,
  isAuth: false,
  success: "",
  loadingProfile: false,
  userPermissions: [],
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_MESSAGES:
      return {
        ...state,
        success: "",
        error: "",
      };
    case LOGIN_EMAIL_MESSAGE:
      state = {
        ...state,
        success: action.payload,
        loading: false,
      };
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case LOGOUT_USER:
      state = { ...state, user: null, isAuth: false, permissions: [] };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;

    case FETCH_LOGGED_IN_USER_PROFILE:
      return {
        ...state,
        loadingProfile: true,
      };
    case FETCH_LOGGED_IN_USER_PROFILE_SUCCESS:
      const permissions = [];
      action.payload.sycu_permissions?.forEach(permission => {
        const parentJson = JSON.parse(permission.json_value);
        if (!!parentJson && parentJson.value) {
          permissions.push(parentJson.key);
        }
      });

      return {
        ...state,
        loadingProfile: false,
        user: action.payload,
        isAuth: true,
        userPermissions: permissions,
      };
    case FETCH_LOGGED_IN_USER_PROFILE_ERROR:
      return {
        ...state,

        loadingProfile: false,

        isAuth: false,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
