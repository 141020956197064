export const SET_ANALYTICS_SUBSCRIPTION_ACTIVE_TAB =
  "SET_ANALYTICS_SUBSCRIPTION_ACTIVE_TAB";
export const SET_ANALYTICS_NEW_SUBSCRIPTION_RANGE_DATE =
  "SET_ANALYTICS_NEW_SUBSCRIPTION_RANGE_DATE";
export const SET_ANALYTICS_RENEW_SUBSCRIPTION_RANGE_DATE =
  "SET_ANALYTICS_RENEW_SUBSCRIPTION_RANGE_DATE";
export const SET_ANALYTICS_SUBSCRIPTION_COMPARE_TYPE =
  "SET_ANALYTICS_SUBSCRIPTION_COMPARE_TYPE";
export const SET_ANALYTICS_NEW_SUBSCRIPTION_BY =
  "SET_ANALYTICS_NEW_SUBSCRIPTION_BY";
export const SET_ANALYTICS_RENEW_SUBSCRIPTION_BY =
  "SET_ANALYTICS_RENEW_SUBSCRIPTION_BY";

export const SET_ANALYTICS_SUBSCRIPTION_CURRENT_SITE =
  "SET_ANALYTICS_SUBSCRIPTION_CURRENT_SITE";

export const ANALYTICS_SUBSCRIPTION_SET_TZ = "ANALYTICS_SUBSCRIPTION_SET_TZ";

// subscription
export const CLEAR_SEARCH_QUERY_REPORT = "CLEAR_SEARCH_QUERY_REPORT";

export const FETCH_SUBSCRIPTION_REPORT_DATA = "FETCH_SUBSCRIPTION_REPORT_DATA";
export const FETCH_SUBSCRIPTION_REPORT_DATA_SUCCESS =
  "FETCH_SUBSCRIPTION_REPORT_DATA_SUCCESS";
export const FETCH_SUBSCRIPTION_REPORT_DATA_ERROR =
  "FETCH_SUBSCRIPTION_REPORT_DATA_ERROR";

export const CLEAR_SUBSCRIPTION_REPORT = "CLEAR_SUBSCRIPTION_REPORT";

export const FETCH_SUBSCRIPTION_REPORT_DATE_WISE =
  "FETCH_SUBSCRIPTION_REPORT_DATE_WISE";
export const FETCH_SUBSCRIPTION_REPORT_DATE_WISE_SUCCESS =
  "FETCH_SUBSCRIPTION_REPORT_DATE_WISE_SUCCESS";
export const FETCH_SUBSCRIPTION_REPORT_DATE_WISE_ERROR =
  "FETCH_SUBSCRIPTION_REPORT_DATE_WISE_ERROR";

export const FETCH_NEW_SUBSCRIPTION_CHART_DATA =
  "FETCH_NEW_SUBSCRIPTION_CHART_DATA";
export const FETCH_NEW_SUBSCRIPTION_CHART_DATA_SUCCESS =
  "FETCH_NEW_SUBSCRIPTION_CHART_DATA_SUCCESS";
export const FETCH_NEW_SUBSCRIPTION_CHART_DATA_ERROR =
  "FETCH_NEW_SUBSCRIPTION_CHART_DATA_ERROR";

export const FETCH_RENEWED_SUBSCRIPTION_CHART_DATA =
  "FETCH_RENEWED_SUBSCRIPTION_CHART_DATA";
export const FETCH_RENEWED_SUBSCRIPTION_CHART_DATA_SUCCESS =
  "FETCH_RENEWED_SUBSCRIPTION_CHART_DATA_SUCCESS";
export const FETCH_RENEWED_SUBSCRIPTION_CHART_DATA_ERROR =
  "FETCH_NEW_SUBSCRIPTION_CHART_DATA_ERROR";

// TABLE
export const SET_ANALYTICS_SUBSCRIPTION_PAGE =
  "SET_ANALYTICS_SUBSCRIPTION_PAGE";
export const SET_ANALYTICS_SUBSCRIPTION_SIZE_PER_PAGE =
  "SET_ANALYTICS_SUBSCRIPTION_SIZE_PER_PAGE";
export const SET_ANALYTICS_SUBSCRIPTION_SORT_FIELD =
  "SET_ANALYTICS_SUBSCRIPTION_SORT_FIELD";
export const SET_ANALYTICS_SUBSCRIPTION_SORT_ORDER =
  "SET_ANALYTICS_SUBSCRIPTION_SORT_ORDER";
