//LIVE CHAT LIST
export const FETCH_LIVE_CHAT_ARC_DATA = "FETCH_LIVE_CHAT_ARC_DATA";
export const FETCH_LIVE_CHAT_ARC_DATA_SUCCESS =
  "FETCH_LIVE_CHAT_ARC_DATA_SUCCESS";
export const FETCH_LIVE_CHAT_ARC_DATA_ERROR = "FETCH_LIVE_CHAT_ARC_DATA_ERROR";
export const SET_LIVE_CHAT_ARC_DATA = "SET_LIVE_CHAT_ARC_DATA";
export const FETCH_LIVE_CHAT_ARC_DATA_LOADING =
  "FETCH_LIVE_CHAT_ARC_DATA_LOADING";
export const SET_LIST_LIVE_CHAT = "SET_LIST_LIVE_CHAT";
export const SET_LIST_LIVE_CHAT_USER_INFO = "SET_LIST_LIVE_CHAT_USER_INFO";
export const SET_ACTIVE_CHAT_ID = "SET_ACTIVE_CHAT_ID";
export const SET_PRE_CHAT_FORM_DATA = "SET_PRE_CHAT_FORM_DATA";
export const SET_CUSTOMER_RANDOM_COLOR = "SET_CUSTOMER_RANDOM_COLOR";
export const SET_CUSTOMER_USER_NAME = "SET_CUSTOMER_USER_NAME";

//LIST ACTIONS
export const SET_LIVE_CHAT_ARC_DATE_RANGE = "SET_LIVE_CHAT_ARC_DATE_RANGE";
export const SET_LIVE_CHAT_ARC_SEARCH = "SET_LIVE_CHAT_ARC_SEARCH";
export const SET_LIVE_CHAT_ARC_ORDER = "SET_LIVE_CHAT_ARC_ORDER";
export const SET_LIVE_CHAT_PAGE_NO = "SET_LIVE_CHAT_PAGE_NO";
export const SET_LIVE_CHAT_PAGINATION = "SET_LIVE_CHAT_PAGINATION";
export const CLEAR_PAGINATION = "CLEAR_PAGINATION";

//LIVE CHAT
export const FETCH_LIVE_CHAT = "FETCH_LIVE_CHAT";
export const FETCH_LIVE_CHAT_LOADING = "FETCH_LIVE_CHAT_LOADING";

//USER DETAIL INFO
export const FETCH_USER_INFO_DETAILS = "FETCH_USER_INFO_DETAILS";
export const FETCH_USER_INFO_DETAILS_LOADING =
  "FETCH_USER_INFO_DETAILS_LOADING";
