import {
  CLEAR_MEMBERSHIP_FEATURES,
  DELETE_MEMBERSHIP,
  DELETE_MEMBERSHIP_ERROR,
  DELETE_MEMBERSHIP_SUCCESS,
  FETCH_MEMBERSHIP,
  FETCH_MEMBERSHIP_ERROR,
  FETCH_MEMBERSHIP_PAGES,
  FETCH_MEMBERSHIP_PAGES_ERROR,
  FETCH_MEMBERSHIP_PAGES_SUCCESS,
  FETCH_MEMBERSHIP_PRODUCT,
  FETCH_MEMBERSHIP_PRODUCT_ERROR,
  FETCH_MEMBERSHIP_PRODUCT_SUCCESS,
  FETCH_MEMBERSHIP_SUCCESS,
  SAVE_MEMBERSHIP,
  SAVE_MEMBERSHIP_ERROR,
  SAVE_MEMBERSHIP_SUCCESS,
  SET_MEMBERSHIP_PAGE,
  SET_MEMBERSHIP_SEARCH,
  SET_MEMBERSHIP_SIZE_PER_PAGE,
  SET_MEMBERSHIP_SORT_FIELD,
  SET_MEMBERSHIP_SORT_ORDER,
} from "./actionTypes";
const INIT_STATE = {
  loading: false,
  memberships: [],
  products: [],
  allPages: [],
  totalSize: 0,
  search: "",
  sizePerPage: 25,
  page: 1,
  sortField: "membership_id",
  sortOrder: "DESC",
};
const membership = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_MEMBERSHIP:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        memberships: action.payload.rows,
        totalSize: action.payload.count,
        loading: false,
      };
    case FETCH_MEMBERSHIP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case FETCH_MEMBERSHIP_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MEMBERSHIP_PRODUCT_SUCCESS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case FETCH_MEMBERSHIP_PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SAVE_MEMBERSHIP:
      return {
        ...state,
        loading: true,
      };
    case SAVE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SAVE_MEMBERSHIP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case DELETE_MEMBERSHIP:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_MEMBERSHIP_ERROR:
      return {
        ...state,
        loading: false,
      };

    case FETCH_MEMBERSHIP_PAGES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MEMBERSHIP_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        allPages: action.payload,
      };
    case FETCH_MEMBERSHIP_PAGES_ERROR:
      return {
        ...state,
        loading: false,
      };

    case SET_MEMBERSHIP_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_MEMBERSHIP_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: action.payload,
      };
    case SET_MEMBERSHIP_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case SET_MEMBERSHIP_SORT_FIELD:
      return {
        ...state,
        sortField: action.payload,
      };
    case SET_MEMBERSHIP_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.payload,
      };
    case CLEAR_MEMBERSHIP_FEATURES:
      return {
        ...state,
        totalSize: 0,
        search: "",
        sizePerPage: 25,
        page: 1,
        sortField: "membership_id",
        sortOrder: "DESC",
      };
    default:
      return state;
  }
};
export default membership;
