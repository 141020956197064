export const compareTypes = {
  PERIOD: "period",
  YEAR: "year",
};

export const timeZones = [
  {
    tz: "EDT (UTC-04:00)",
    utcOffSet: "-0400",
  },
  {
    tz: "UTC",
    utcOffSet: "0000",
  },
];

export const tzs = {
  "-0400": 1,
  "0000": 2,
};
