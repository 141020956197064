import { dynamicSiteId } from "constants/siteDetails";
import { compareTypes } from "modules/Analytics/common/constants";
import DateRange from "modules/Analytics/common/utils";
import moment from "moment";
import {
  ANALYTICS_ORDER_SET_TZ,
  FETCH_ORDER_GRAPH_DATA,
  FETCH_ORDER_GRAPH_DATA_ERROR,
  FETCH_ORDER_GRAPH_DATA_SUCCESS,
  FETCH_ORDER_REPORTS,
  FETCH_ORDER_REPORTS_ERROR,
  FETCH_ORDER_REPORTS_SUCCESS,
  FETCH_ORDER_TABLE_DATA,
  FETCH_ORDER_TABLE_DATA_ERROR,
  FETCH_ORDER_TABLE_DATA_SUCCESS,
  SET_ANALYTICS_ORDER_ACTIVE_TAB,
  SET_ANALYTICS_ORDER_BY,
  SET_ANALYTICS_ORDER_COMPARE_TYPE,
  SET_ANALYTICS_ORDER_CURRENT_SITE,
  SET_ANALYTICS_ORDER_PAGE,
  SET_ANALYTICS_ORDER_RANGE_DATE,
  SET_ANALYTICS_ORDER_SIZE_PER_PAGE,
  SET_ANALYTICS_ORDER_SORT_FIELD,
  SET_ANALYTICS_ORDER_SORT_ORDER,
} from "./actionsTypes";

const startDate = moment().clone().startOf("month").toDate();
const endDate = moment().toDate();
const defaultCompareType = compareTypes.PERIOD;

const reports = {
  orders: {
    value: 0,
    previousPeriodValue: 0,
  },
  net_sales: {
    value: 0,
    previousPeriodValue: 0,
  },
  average_order_value: {
    value: 0,
    previousPeriodValue: 0,
  },
  average_item_per_order: {
    value: 0,
    previousPeriodValue: 0,
  },
};

const initialStates = {
  activeTab: "orders",
  reports: reports,
  by: "day",
  range: {
    startDate,
    endDate,
  },
  compareToPrevious: defaultCompareType,
  comparedRange: new DateRange(startDate, endDate).getPreviousDates(
    defaultCompareType
  ),
  loadingOrderReports: false,
  loadingOrderChartData: false,
  orderChartData: [],
  ordersTableData: {
    loading: false,
    rows: [],
    totalSize: 0,
    ordersCount: 0,
    page: 1,
    sizePerPage: 25,
    sortOrder: "DESC",
    sortField: "created_date",
  },
  siteId: dynamicSiteId.Curriculum,
  tz: "-0400",
};

const change = (key, value, state) => ({
  ...state,
  ordersTableData: {
    ...state.ordersTableData,
    [key]: value,
  },
});

const AnalyticsOrdersReducer = (state = initialStates, action) => {
  const { type, payload } = action;

  switch (type) {
    case ANALYTICS_ORDER_SET_TZ:
      return {
        ...state,
        tz: payload,
      };
    case SET_ANALYTICS_ORDER_CURRENT_SITE:
      return {
        ...state,
        siteId: payload,
      };

    case SET_ANALYTICS_ORDER_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };

    case SET_ANALYTICS_ORDER_RANGE_DATE:
      const prevBy = state.by;
      const newBy = new DateRange(
        payload.startDate,
        payload.endDate
      ).getByOptions()[0].value;
      return {
        ...state,
        range: {
          ...state.range,
          ...payload,
        },
        comparedRange: new DateRange(
          payload.startDate,
          payload.endDate
        ).getPreviousDates(state.compareToPrevious),
        ...(prevBy !== newBy ? { by: newBy } : {}),
      };

    case SET_ANALYTICS_ORDER_COMPARE_TYPE:
      return {
        ...state,
        compareToPrevious: payload,
        comparedRange: new DateRange(
          state.range.startDate,
          state.range.endDate
        ).getPreviousDates(payload),
      };

    case SET_ANALYTICS_ORDER_BY:
      return {
        ...state,
        by: payload,
      };

    //TABLE

    case SET_ANALYTICS_ORDER_PAGE:
      return change("page", payload, state);
    case SET_ANALYTICS_ORDER_SIZE_PER_PAGE:
      return change("sizePerPage", payload, state);
    case SET_ANALYTICS_ORDER_SORT_FIELD:
      return change("sortField", payload, state);
    case SET_ANALYTICS_ORDER_SORT_ORDER:
      return change("sortOrder", payload, state);

    //APIS

    case FETCH_ORDER_REPORTS:
      return {
        ...state,
        loadingOrderReports: true,
      };
    case FETCH_ORDER_REPORTS_SUCCESS:
      return {
        ...state,
        reports: payload,
        loadingOrderReports: false,
      };
    case FETCH_ORDER_REPORTS_ERROR:
      return {
        ...state,
        loadingOrderReports: false,
      };

    case FETCH_ORDER_GRAPH_DATA:
      return {
        ...state,
        loadingOrderChartData: true,
      };
    case FETCH_ORDER_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        orderChartData: payload.map(item => {
          return {
            cp_date_time: item.cp_date_time,
            pp_date_time: item.pp_date_time,
            cp: item.cp,
            pp: item.pp,
          };
        }),
        loadingOrderChartData: false,
      };
    case FETCH_ORDER_GRAPH_DATA_ERROR:
      return {
        ...state,
        loadingOrderChartData: false,
      };

    case FETCH_ORDER_TABLE_DATA:
      return change("loading", true, state);
    case FETCH_ORDER_TABLE_DATA_SUCCESS:
      return {
        ...state,
        ordersTableData: {
          ...state.ordersTableData,
          loading: false,
          rows: payload.rows,
          totalSize: payload.totalSize,
          ordersCount: payload.orderCounts || 0,
        },
      };
    case FETCH_ORDER_TABLE_DATA_ERROR:
      return change("loading", false, state);

    default:
      return state;
  }
};

export default AnalyticsOrdersReducer;
