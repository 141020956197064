//GET Digital Board ADMIN
export const FETCH_DIGITAL_BOARD_DATA = "FETCH_DIGITAL_BOARD_DATA";
export const FETCH_DIGITAL_BOARD_DATA_SUCCESS =
  "FETCH_DIGITAL_BOARD_DATA_SUCCESS";
export const FETCH_DIGITAL_BOARD_DATA_ERROR = "FETCH_DIGITAL_BOARD_DATA_ERROR";

export const SET_DIGITAL_BOARD_DATA_PAGE = "SET_DIGITAL_BOARD_DATA_PAGE";
export const SET_DIGITAL_BOARD_DATA_SIZE_PER_PAGE =
  "SET_DIGITAL_BOARD_DATA_SIZE_PER_PAGE";
export const SET_DIGITAL_BOARD_DATA_SORT_FIELD =
  "SET_DIGITAL_BOARD_DATA_SORT_FIELD";
export const SET_DIGITAL_BOARD_DATA_SORT_ORDER =
  "SET_DIGITAL_BOARD_DATA_SORT_ORDER";

export const SET_DIGITAL_BOARD_DATA_SEARCH = "SET_DIGITAL_BOARD_DATA_SEARCH";

export const SET_BOARD_TITLE = "SET_BOARD_TITLE";
export const LOADING_BOARD_DETAILS = "LOADING_BOARD_DETAILS";
export const SET_BACKGROUND_DETAILS = "SET_BACKGROUND_DETAILS";
export const SET_BACKGROUND_IMAGES_LOADING = "SET_BACKGROUND_IMAGES_LOADING";
export const SET_BACKGROUND_IMAGES = "SET_BACKGROUND_IMAGES";

// EDITOR
export const EDIT_BOARD_TEMPLATE_DATA = "EDIT_BOARD_TEMPLATE_DATA";

export const DELETE_BOARD_TEMPLATE_DATA = "DELETE_BOARD_TEMPLATE_DATA";

export const CREATE_UPDATE_CARD_BOARD = "CREATE_UPDATE_CARD_BOARD";
export const CREATE_UPDATE_CARD_LOADING = "CREATE_UPDATE_CARD_LOADING";
export const UPDATE_CARD_DETAILS = "UPDATE_CARD_DETAILS";
export const DELETE_CARD = "DELETE_CARD";
export const DUPLICATE_CARD = "DUPLICATE_CARD";

export const PUBLISH_DIGITAL_BOARD = "PUBLISH_DIGITAL_BOARD";
export const SET_PUBLISH_ADMIN_TEMPLATE = "SET_PUBLISH_ADMIN_TEMPLATE";
export const UPDATE_CARD_COORDINATES = "UPDATE_CARD_COORDINATES";
export const DUPLICATE_CARD_DETAILS = "DUPLICATE_CARD_DETAILS";
export const CREATE_DIGITAL_BOARD = "CREATE_DIGITAL_BOARD";
export const LIST_BOARD_BG_LIBRARY = "LIST_BOARD_BG_LIBRARY";
export const DELETE_CARD_LOADING = "DELETE_CARD_LOADING";

export const SET_OWNER_INFO = "SET_OWNER_INFO";
export const SET_INVITED_USERS = "SET_INVITED_USERS";
export const SET_IS_INVITED_USER = "SET_IS_INVITED_USER";
export const SET_IS_PUBLIC = "SET_IS_PUBLIC";

//canvas
export const SET_CANVAS = "SET_CANVAS";
export const SET_ZOOM_LEVEL = "SET_ZOOM_LEVEL";
export const UPDATE_CARD_CANVAS_COORDS = "UPDATE_CARD_CANVAS_COORDS";
export const EDITOR_LIST_CANVAS_BOARD = "EDITOR_LIST_CANVAS_BOARD";
export const PREVIEW_LIST_CANVAS_BOARD = "PREVIEW_LIST_CANVAS_BOARD";
export const SET_CANVAS_CARD_COORDINATE = "SET_CANVAS_CARD_COORDINATE";

export const UPDATE_CANVAS_CARD_COORDS = "UPDATE_CANVAS_CARD_COORDS";
export const UPDATE_MULTI_CARD_COORDS = "UPDATE_MULTI_CARD_COORDS";
export const SET_FETCH_BOARD = "SET_FETCH_BOARD";

export const SET_BOARD_TABLE_LOADER = "SET_BOARD_TABLE_LOADER";
export const SET_ACTIVE_CARD_DATA = "SET_ACTIVE_CARD_DATA";
export const IMPORT_BOARD = "IMPORT_BOARD";
export const SET_BOARD_DATA = "SET_BOARD_DATA";
export const SET_IMPORTED_DATA = "SET_IMPORTED_DATA";