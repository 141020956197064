import {
  ADMIN_ACTIVTY_LOG,
  ADMIN_ACTIVTY_LOG_ERROR,
  ADMIN_ACTIVTY_LOG_SUCCESS,
  CLEAR_CLOUD_PAGE,
  EDIT_FILE_NAME,
  EDIT_FILE_NAME_ERROR,
  EDIT_FILE_NAME_LOADING,
  GET_DIR_FILES,
  GET_DIR_FILES_ERROR,
  GET_DIR_FILES_SUCCESS,
  SET_CLOUD_PAGE,
  SET_CLOUD_SIZE_PER_PAGE,
  SET_SEARCH_CLOUD_PAGE,
  SET_SELECTED_IDS,
  SHARE_FOLDER,
  SHARE_FOLDER_DOWNLOAD_SUCCESS,
  SHARE_FOLDER_ERROR,
  SHARE_FOLDER_INSTRUCTION_SUCCESS,
  SHARE_FOLDER_PREVIEW_SUCCESS,
  SORT_CLOUD_PAGE,
  SORT_ORDER_CLOUD_SIZE_PER_PAGE,
  STORE_DIR_CLOUD,
  STORE_DIR_CLOUD_ERROR,
  STORE_DIR_CLOUD_SUCCESS,
  UPDATE_DOWNLOAD_STATUS,
} from "./actionTypes";

const initialState = {
  loading: false,
  storeFileLoading: null,
  cloudDirData: [],
  storeDirData: [],
  LinkOfDownloadFolder: "",
  LinkOfPreviewFolder: "",
  LinkOfInstructionFolder: "",
  activityLog: [],
  pageNo: 1,
  pageRecord: 15,
  sortField: "title",
  sortOrder: "asc",
  searchValue: "",
  count: null,
  selectedIds: [],
  editLoading: false,
};

const CloudS3Reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CLEAR_CLOUD_PAGE:
      return {
        ...state,
        pageNo: 1,
      };
    case SET_SEARCH_CLOUD_PAGE:
      return {
        ...state,
        searchValue: payload,
      };
    case SET_CLOUD_PAGE:
      return {
        ...state,
        pageNo: payload,
      };
    case SET_CLOUD_SIZE_PER_PAGE:
      return {
        ...state,
        pageRecord: payload,
      };
    case SORT_CLOUD_PAGE:
      return {
        ...state,
        sortField: payload,
      };
    case SORT_ORDER_CLOUD_SIZE_PER_PAGE:
      return {
        ...state,
        sortOrder: payload,
      };
    case STORE_DIR_CLOUD:
      return {
        ...state,
        storeFileLoading: true,
      };
    case STORE_DIR_CLOUD_SUCCESS:
      return {
        ...state,
        storeFileLoading: false,
        storeDirData: payload,
      };
    case STORE_DIR_CLOUD_ERROR:
      return {
        ...state,
        storeFileLoading: false,
      };
    case GET_DIR_FILES:
      return {
        ...state,
        loading: true,
      };
    case GET_DIR_FILES_SUCCESS:
      if (payload.Page_No === 1) {
        return {
          ...state,
          loading: false,
          count: payload.data.count,
          cloudDirData: payload.data.rows,
          selectedIds: [],
        };
      } else {
        return {
          ...state,
          loading: false,
          cloudDirData: [...state.cloudDirData, ...payload.data.rows],
        };
      }
    case GET_DIR_FILES_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SHARE_FOLDER:
      return {
        ...state,
        loading: true,
      };
    case SHARE_FOLDER_DOWNLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        LinkOfDownloadFolder: payload,
      };
    case SHARE_FOLDER_PREVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        LinkOfPreviewFolder: payload,
      };
    case SHARE_FOLDER_INSTRUCTION_SUCCESS:
      return {
        ...state,
        loading: false,
        LinkOfInstructionFolder: payload,
      };
    case SHARE_FOLDER_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ADMIN_ACTIVTY_LOG:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_ACTIVTY_LOG_SUCCESS:
      if (payload.Page_No === 1) {
        return {
          ...state,
          loading: false,
          count: payload.data.count,
          activityLog: payload.data.rows,
        };
      } else {
        return {
          ...state,
          loading: false,
          activityLog: [...state.activityLog, ...payload.data.rows],
        };
      }
    case ADMIN_ACTIVTY_LOG_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SET_SELECTED_IDS:
      return {
        ...state,
        selectedIds: payload,
      };
    case UPDATE_DOWNLOAD_STATUS:
      return {
        ...state,
        cloudDirData: state.cloudDirData.map(data =>
          parseInt(data.id) === parseInt(payload)
            ? { ...data, is_downloaded: 1 }
            : data
        ),
      };
    case EDIT_FILE_NAME_LOADING:
      return {
        ...state,
        editLoading: true,
      };
    case EDIT_FILE_NAME:
      return {
        ...state,
        editLoading: false,
      };
    case EDIT_FILE_NAME_ERROR:
      return {
        ...state,
        editLoading: false,
      };
    default:
      return state;
  }
};

export default CloudS3Reducer;
