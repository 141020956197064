import { axiosApiAdmin } from "helpers/api_helper";
import {
  GET_DISPUTED_TRANSACTIONS,
  VERIFY_EVIDENCE_DATA,
} from "helpers/url_helper";
import { DateTimeTz } from "modules/Analytics/common/utils";
import { toast } from "react-toastify";
import { setErrorMessage } from "store/actions";
import {
  getDisputedTransactionsError,
  getDisputedTransactionsSuccess,
} from "./action";
import { FETCH_DISPUTED_TRANSACTIONS, VERIFY_EVIDENCE } from "./actionType";

const { takeEvery, put, select, call } = require("redux-saga/effects");

const fetchDisputedTransactionsData = payload =>
  axiosApiAdmin.post(GET_DISPUTED_TRANSACTIONS, payload);

const handleVerifyEvidenceData = payload =>
  axiosApiAdmin.post(VERIFY_EVIDENCE_DATA, payload);

function* DisputedTransactionsSaga() {
  function* handleFetchDisputedTransactions({ cb }) {
    try {
      const {
        disputedTransactionsTableData: {
          loading,
          rows,
          totalSize,
          ordersCount,
          page,
          sizePerPage,
          sortOrder,
          sortField,
          search,
        },
        range,
        tz,
      } = yield select(state => state.DisputedTransactions);
      let pageNo = search.length > 0 ? 1 : page;
      const payload = {
        search: search,
        page_record: sizePerPage,
        page_no: pageNo,
        sort_field: sortField,
        sort_order: sortOrder,
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD"),
        end_date: DateTimeTz(range.endDate).format("YYYY-MM-DD"), // .endTime(tz) 4HoursIssue comment
      };

      const res = yield call(fetchDisputedTransactionsData, payload);
      if (res?.status && res.data?.data) {
        cb(res);
        yield put(
          getDisputedTransactionsSuccess({
            totalSize: res?.data?.data?.count,
            rows: res?.data?.data?.rows,
          })
        );
      }
    } catch (err) {
      yield put(getDisputedTransactionsError());
      yield put(setErrorMessage(err?.response?.data?.message || err.message));
    }
  }
  function* handleVerifyEvidence({ payload, cb }) {
    try {
      const res = yield call(handleVerifyEvidenceData, payload);
      if (res) {
        cb(res);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    }
  }

  yield takeEvery(FETCH_DISPUTED_TRANSACTIONS, handleFetchDisputedTransactions);
  yield takeEvery(VERIFY_EVIDENCE, handleVerifyEvidence);
}

export default DisputedTransactionsSaga;
