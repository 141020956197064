import { axiosApiAdmin } from "helpers/api_helper";
import { LIVE_CHAT_ARCHIVE_DATA } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchLiveChatDataSuccess,
  setErrorMessage,
  setLiveChatDataList,
} from "store/actions";
import { FETCH_LIVE_CHAT_ARC_DATA } from "./actionTypes";

const getLiveChatArchiveData = data =>
  axiosApiAdmin.post(LIVE_CHAT_ARCHIVE_DATA, data);

function* handleFetchLiveChatArchive({ payload }) {
  try {
    const res = yield call(getLiveChatArchiveData, payload);
    if (res?.status) {
      yield put(fetchLiveChatDataSuccess(res?.data?.data));

      yield put(
        setLiveChatDataList({
          data: res?.data?.data?.rows.map(chatData => chatData.chat_data.data),
          chatId: res?.data?.data?.rows.map(chatData => chatData.chat_id),
        })
      );
    }
  } catch (err) {
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* liveChatArchiveSaga() {
  yield takeEvery(FETCH_LIVE_CHAT_ARC_DATA, handleFetchLiveChatArchive);
}

export default liveChatArchiveSaga;
