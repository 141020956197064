import { axiosApiBoard } from "helpers/api_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";

import {
  CREATE_UPDATE_CARD_BOARD,
  DELETE_CARD,
  DUPLICATE_CARD_DETAILS,
  EDIT_BOARD_TEMPLATE_DATA,
  FETCH_DIGITAL_BOARD_DATA,
  IMPORT_BOARD,
  LIST_BOARD_BG_LIBRARY,
  PREVIEW_LIST_CANVAS_BOARD,
  PUBLISH_DIGITAL_BOARD,
  UPDATE_CANVAS_CARD_COORDS,
  UPDATE_MULTI_CARD_COORDS,
} from "./actionTypes";

import {
  createUpdateCardBoard,
  deleteCardLoading,
  fetchDigitalBoardError,
  fetchDigitalBoardSuccess,
  setBackgroundDetails,
  setBackgroundImages,
  setBoardData,
  setBoardTitle,
  setCanvasData,
  setCreateUpdateCardLoading,
  setEditorBoardData,
  setFetchBoardLoading,
  setInvitedUsers,
  setIsInvitedUser,
  setIsPublic,
  setLoadingBoardDetails,
  setOwnerInfo,
  setPublishAdminTemplate,
  setZoomLevel,
  updateCardCoordinatesCanvas,
} from "./actions";

import {
  CREATE_UPDATE_CARD,
  DELETE_DIGITAL_BOARD_CARD,
  EDIT_BOARD_DETAILS,
  GET_DIGITAL_BOARD_DATA,
  IMPORT_BOARD_DATA,
  LIST_BOARD_BACKGROUND_LIBRARY,
  PREVIEW_BOARD_DATA,
  PUBLISH_BOARD,
  UPDATE_CANVAS_CARD_COORDINATES,
  UPDATE_CARD_COORDINATES_INDEX,
} from "helpers/url_helper";
import moment from "moment";
import { AppToast, createBoard, notificationTypes } from "../utils";

const getBoardData = data => axiosApiBoard.post(GET_DIGITAL_BOARD_DATA, data);
const editBoardData = data =>
  axiosApiBoard.get(`${EDIT_BOARD_DETAILS}/${data}`);
const deleteBoardData = data =>
  axiosApiBoard.post(DELETE_DIGITAL_BOARD_CARD, data);
const publishBoardData = data => axiosApiBoard.post(PUBLISH_BOARD, data);
const updateCardIndexData = data =>
  axiosApiBoard.post(UPDATE_CARD_COORDINATES_INDEX, data);
const createCardBoardData = data =>
  axiosApiBoard.post(CREATE_UPDATE_CARD, data);
const previewBoardData = data =>
  axiosApiBoard.get(`${PREVIEW_BOARD_DATA}/${data}`);
const listBackgroundImageLibrary = data =>
  axiosApiBoard.post(LIST_BOARD_BACKGROUND_LIBRARY);
const updateCardCoordsCanvas = data =>
  axiosApiBoard.post(UPDATE_CANVAS_CARD_COORDINATES, data);
const importBoard = data => axiosApiBoard.post(IMPORT_BOARD_DATA, data);

function* handleBoardListData({ payload }) {
  try {
    const res = yield call(getBoardData, payload);
    if (res.data.data) {
      yield put(fetchDigitalBoardSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchDigitalBoardError());
    new AppToast(err).display();
  }
}

function* handleBoardPublishData({ payload }) {
  try {
    const { canvasLoading } = yield select(state => state.BoardReducer);
    yield put(setCreateUpdateCardLoading(true));
    const res = yield call(publishBoardData, payload);
    if (res.data.data) {
      new AppToast(
        `Board ${
          payload.publish_admin_template === 0 ? "Unpublished" : "Published"
        } Successfully`,
        notificationTypes.SUCCESS
      ).display();
      yield put(setCreateUpdateCardLoading(false));
      yield put(setCanvasData(!canvasLoading));
    }
  } catch (err) {
    new AppToast(err).display();
    yield put(setCreateUpdateCardLoading(false));
  }
}

function* handleCreateUpdateCardDetails({ payload }) {
  try {
    const { canvasLoading } = yield select(state => state.BoardReducer);
    const { ...rest } = payload.data;
    const res = yield call(createCardBoardData, {
      board_id: payload.data.boardId,
      ...rest,
    });
    if (res.status) {
      yield put(setCanvasData(!canvasLoading));
      typeof payload.cb === "function" &&
        payload.cb({ status: false, id: res.data.data.card.card_id });
      payload.data.action &&
        new AppToast(
          `${payload.data.action === 3 && "Card Updated"}`,
          notificationTypes.SUCCESS
        ).display();
    }
  } catch (err) {
    yield put(setCreateUpdateCardLoading(false));
    new AppToast(err).display();
  }
}

function* handleEditBoardDetails({ payload }) {
  try {
    yield put(setLoadingBoardDetails(true));
    yield put(setFetchBoardLoading(true));
    const res = yield call(editBoardData, payload.id);

    if (res.status) {
      if (res.data?.data?.boardData) {
        const {
          background_data,
          background_type,
          board_name,
          boardCards,
          invited_users,
          first_name,
          last_name,
          profile_image,
          is_invited,
          public_sharing,
          zoom_level,
          publish_admin_template,
          email,
        } = res.data.data.boardData;

        const { cards, rows } = createBoard(boardCards);

        yield put(setIsInvitedUser(is_invited));
        yield put(setZoomLevel(zoom_level));
        yield put(setBoardTitle(board_name));
        yield put(setEditorBoardData(res.data.data.boardData?.boardCards));
        yield put(setBoardData(res.data.data.boardData));
        yield put(setLoadingBoardDetails(false));
        yield put(setFetchBoardLoading(false));
        yield put(
          setBackgroundDetails({
            backgroundData: background_data,
            backgroundType: background_type,
          })
        );
        yield put(setPublishAdminTemplate(publish_admin_template));
        yield put(
          setOwnerInfo({
            name: `${first_name} ${last_name}`,
            imgUrl: profile_image,
            email: email,
          })
        );
        yield put(setInvitedUsers(invited_users || []));
        yield put(setIsPublic(public_sharing));
      }
    }
  } catch (err) {
    yield put(setLoadingBoardDetails(false));
    yield put(setFetchBoardLoading(false));
    new AppToast(err).display();
  }
}

function* handlePreviewBoardData({ payload }) {
  try {
    yield put(setLoadingBoardDetails(true));
    yield put(setFetchBoardLoading(true));
    const res = yield call(previewBoardData, payload.id);

    if (res.status) {
      if (res.data?.data?.boardData) {
        const {
          background_data,
          background_type,
          board_name,
          public_sharing,
          zoom_level,
        } = res.data.data.boardData;

        yield put(setZoomLevel(zoom_level));
        yield put(setBoardTitle(board_name));
        yield put(setEditorBoardData(res.data.data.boardData?.boardCards));
        yield put(setBoardData(res.data.data.boardData));
        yield put(setLoadingBoardDetails(false));
        yield put(setFetchBoardLoading(false));
        yield put(
          setBackgroundDetails({
            backgroundData: background_data,
            backgroundType: background_type,
          })
        );
        yield put(setIsPublic(public_sharing));
      }
    }
  } catch (err) {
    yield put(setLoadingBoardDetails(false));
    yield put(setFetchBoardLoading(false));
    new AppToast(err).display();
  }
}

function* importBoardData({payload:{ payload, cb = () => {}}}){
    try {
      yield put(setLoadingBoardDetails(true));
      yield put(setFetchBoardLoading(true));
      const res = yield call(importBoard, payload);
      if (res?.status) {
        yield put(setLoadingBoardDetails(false));
        yield put(setFetchBoardLoading(false));
        cb();
      }
    } catch (err) {
      yield put(setLoadingBoardDetails(false));
      yield put(setFetchBoardLoading(false));
      new AppToast(err).display();
    }
}

function* handleCardUpdateIndexes({ payload }) {
  try {
    const { canvasLoading } = yield select(state => state.BoardReducer);
    const res = yield call(updateCardIndexData, {
      cards: payload.type === "reset" ? payload.resetElementCoords : payload,
      type: 2,
    });
    if (res.status) {
      yield put(
        updateCardCoordinatesCanvas(
          payload.type === "reset" ? payload.resetElementCoords : payload
        )
      );
      if (payload.type === "reset") {
        yield put(setCanvasData(!canvasLoading));
      }
    }
  } catch (err) {
    new AppToast(err).display();
  }
}

function* handleDuplicateCard({ payload }) {
  try {
    const { ...rest } = payload.data;
    yield put(
      createUpdateCardBoard(
        {
          ...rest,
          card_id: 0,
          date: moment(payload.data.date).isBefore()
            ? moment().format("MM/DD/YYYY")
            : payload.data.date,
        },
        status => payload.cb(status)
      )
    );
  } catch (err) {
    new AppToast(err).display();
  }
}

function* handleDeleteCards({ payload }) {
  try {
    const { canvasLoading } = yield select(state => state.BoardReducer);
    yield put(deleteCardLoading(true));
    const res = yield call(deleteBoardData, payload);
    if (res.status) {
      yield put(deleteCardLoading(false));
      yield put(setCanvasData(!canvasLoading));
      new AppToast(
        `${payload.action === 1 ? "Card Deleted" : "Card Restored"}`,
        notificationTypes.SUCCESS
      ).display();
    }
  } catch (err) {
    new AppToast(err).display();
    yield put(deleteCardLoading(false));
  }
}

function* handleListBackgroundImageLibrary() {
  try {
    const res = yield call(listBackgroundImageLibrary);
    if (res.data.data) {
      yield put(
        setBackgroundImages(
          res.data.data.background.rows.map(img => ({
            id: img.background_library_id,
            url: img.image_url,
          }))
        )
      );
    }
  } catch (err) {
    new AppToast(err).display();
  }
}

function* handleUpdateCardCanvasCoords({ payload }) {
  try {
    const res = yield call(updateCardCoordsCanvas, payload);
    if (res.status) {
      yield put(updateCardCoordinatesCanvas(payload));
    }
  } catch (err) {
    new AppToast(err).display();
  }
}

function* boardSaga() {
  yield takeEvery(FETCH_DIGITAL_BOARD_DATA, handleBoardListData);
  yield takeEvery(PREVIEW_LIST_CANVAS_BOARD, handlePreviewBoardData);
  yield takeEvery(EDIT_BOARD_TEMPLATE_DATA, handleEditBoardDetails);
  yield takeEvery(UPDATE_MULTI_CARD_COORDS, handleCardUpdateIndexes);
  yield takeEvery(CREATE_UPDATE_CARD_BOARD, handleCreateUpdateCardDetails);
  yield takeEvery(PUBLISH_DIGITAL_BOARD, handleBoardPublishData);
  yield takeEvery(LIST_BOARD_BG_LIBRARY, handleListBackgroundImageLibrary);
  yield takeEvery(UPDATE_CANVAS_CARD_COORDS, handleUpdateCardCanvasCoords);
  yield takeEvery(DUPLICATE_CARD_DETAILS, handleDuplicateCard);
  yield takeEvery(DELETE_CARD, handleDeleteCards);
  yield takeEvery(IMPORT_BOARD, importBoardData);
}

export default boardSaga;
