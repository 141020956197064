import {
  CLEAR_ADMIN_ACTIVITY_LOG,
  FETCH_ADMIN_ACTIVE_LOGS,
  FETCH_ADMIN_ACTIVE_LOGS_ERROR,
  FETCH_ADMIN_ACTIVE_LOGS_SUCCESS,
} from "./actionTypes";

const initialState = {
  fetchLogsLoading: false,
  activeLogs: [],
  count: 0,
};

const adminActivityLogReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ADMIN_ACTIVE_LOGS:
      return {
        ...state,
        fetchLogsLoading: true,
      };
    case FETCH_ADMIN_ACTIVE_LOGS_SUCCESS:
      return {
        ...state,
        activeLogs: [...state.activeLogs, ...payload.rows],
        fetchLogsLoading: false,
        count: payload.count,
      };
    case FETCH_ADMIN_ACTIVE_LOGS_ERROR:
      return {
        ...state,
        fetchLogsLoading: false,
      };
    case CLEAR_ADMIN_ACTIVITY_LOG:
      return {
        ...state,
        activeLogs: [],
      };
    default:
      return state;
  }
};

export default adminActivityLogReducer;
