import {
  ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS,
  ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS_ERROR,
  ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS_SUCCESS,
  ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS,
  ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS_ERROR,
  ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS_SUCCESS,
  ANALYTICS_DASHBOARD_SET_BY,
  ANALYTICS_DASHBOARD_SET_CHART_TYPE,
  ANALYTICS_DASHBOARD_SET_COMPARE_RANGE,
  ANALYTICS_DASHBOARD_SET_RANGE,
  ANALYTICS_DASHBOARD_SET_SETTINGS,
  ANALYTICS_DASHBOARD_SET_SETTINGS_KEY_VALUE,
  ANALYTICS_DASHBOARD_SET_SITE,
  ANALYTICS_DASHBOARD_SET_TZ,
} from "./actionTypes";

export const setSite = data => ({
  type: ANALYTICS_DASHBOARD_SET_SITE,
  payload: data,
});

export const setRange = data => ({
  type: ANALYTICS_DASHBOARD_SET_RANGE,
  payload: data,
});

export const setCompareRange = data => ({
  type: ANALYTICS_DASHBOARD_SET_COMPARE_RANGE,
  payload: data,
});

export const setBy = data => ({
  type: ANALYTICS_DASHBOARD_SET_BY,
  payload: data,
});

export const setChartType = data => ({
  type: ANALYTICS_DASHBOARD_SET_CHART_TYPE,
  payload: data,
});

export const setSettingsKeyValue = data => ({
  type: ANALYTICS_DASHBOARD_SET_SETTINGS_KEY_VALUE,
  payload: data,
});
export const setSettings = data => ({
  type: ANALYTICS_DASHBOARD_SET_SETTINGS,
  payload: data,
});

export const setTZ = data => ({
  type: ANALYTICS_DASHBOARD_SET_TZ,
  payload: data,
});

// Apis

//PERFORMANCE
export const fetchOverallPerformanceData = () => ({
  type: ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS,
});

export const fetchOverallPerformanceDataSuccess = data => ({
  type: ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS_SUCCESS,
  payload: data,
});

export const fetchOverallPerformanceDataError = () => ({
  type: ANALYTICS_DASHBOARD_FETCH_PERFORMANCE_REPORTS_ERROR,
});

//SUBSCRIPTIONS
export const fetchOverallSubscriptionsData = data => ({
  type: ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS,
  payload: data,
});

export const fetchOverallSubscriptionsDataSuccess = data => ({
  type: ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS_SUCCESS,
  payload: data,
});

export const fetchOverallSubscriptionsDataError = () => ({
  type: ANALYTICS_DASHBOARD_FETCH_SUBSCRIPTIONS_REPORTS_ERROR,
});
