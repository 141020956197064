// APIS
export const FETCH_SUBSCRIPTION_REPORTS = "FETCH_SUBSCRIPTION_REPORTS";
export const FETCH_SUBSCRIPTION_REPORTS_SUCCESS =
  "FETCH_SUBSCRIPTION_REPORTS_SUCCESS";
export const FETCH_SUBSCRIPTION_REPORTS_ERROR =
  "FETCH_SUBSCRIPTION_REPORTS_ERROR";

// TABLE
export const SET_ANALYTICS_SUBSCRIPTION_RENEWAL_PAGE =
  "SET_ANALYTICS_SUBSCRIPTION_RENEWAL_PAGE";
export const SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SIZE_PER_PAGE =
  "SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SIZE_PER_PAGE";
export const SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SORT_FIELD =
  "SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SORT_FIELD";
export const SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SORT_ORDER =
  "SET_ANALYTICS_SUBSCRIPTION_RENEWAL_SORT_ORDER";

export const FETCH_SUBSCRIPTION_TABLE_DATA = "FETCH_SUBSCRIPTION_TABLE_DATA";
export const FETCH_SUBSCRIPTION_TABLE_DATA_SUCCESS =
  "FETCH_SUBSCRIPTION_TABLE_DATA_SUCCESS";
export const FETCH_SUBSCRIPTION_TABLE_DATA_ERROR =
  "FETCH_SUBSCRIPTION_TABLE_DATA_ERROR";

export const SET_RENEWAL_ANALYTICS_YEAR = "SET_RENEWAL_ANALYTICS_YEAR";
export const SET_RENEWAL_ANALYTICS_MONTH = "SET_RENEWAL_ANALYTICS_MONTH";
