import { FETCH_LIST_CHURCH_DATA } from "./actionType";

const initialState = {
  churchList: [],
};

const ChurchesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_LIST_CHURCH_DATA:
      return {
        ...state,
        churchList: payload?.churchData,
      };
    default:
      return state;
  }
};

export default ChurchesReducer;
