import { axiosApiAdmin } from "helpers/api_helper";
import { GET_CALENDER_CARD_REPORT } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { listingDataLoading, listingDataReport } from "./action";
import { GET_ADD_NEW_CALENDAR } from "./actionType";

const fetchAllCalendarDataSitemap = data => {
  return axiosApiAdmin.post(GET_CALENDER_CARD_REPORT, data);
};

function* fetchShipCardBobOrders({ payload }) {
  try {
    yield put(listingDataLoading(true));
    const res = yield call(fetchAllCalendarDataSitemap, payload);
    if (res.status) {
      yield put(listingDataReport(res));
      yield put(listingDataLoading(false));
    }
  } catch (err) {
    yield put(listingDataLoading(false));
    console.error(err);
  }
}

function* ShipmentCalenderSaga() {
  yield takeEvery(GET_ADD_NEW_CALENDAR, fetchShipCardBobOrders);
}

export default ShipmentCalenderSaga;
