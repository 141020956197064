import { axiosApiSlidr } from "helpers/api_helper";
import Log from "helpers/logger";
import { SAVE_SLIDESHOW } from "helpers/url_helper";
import { generateSlideString, getVideoType } from "modules/tools/utils/slider";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { setErrorMessage } from "store/actions";
import { saveSlideshowError, saveSlideshowSuccess } from "./actions";
import {
  SAVE_SLIDE_SHOW,
  SLIDR_ADD_SLIDE,
  SLIDR_COPY_SLIDE,
} from "./actionTypes";

const saveSlideshow = data => axiosApiSlidr.post(SAVE_SLIDESHOW, data);

function* handleSaveSlideshow({ payload: data }) {
  try {
    const {
      slides,
      slideShowId,
      settings: { presentationSize },
    } = yield select(state => state.Slidr);

    const payload = slides.map((slide, index) => ({
      slide_id: slide.id,
      content: generateSlideString(slide, presentationSize),
      sort_order: index + 1,
      slide_type: !!slide.videoUrl ? 2 : 1,
      video_url: !!slide.videoUrl ? slide.videoUrl : "",
      video_type: !!slide.videoUrl ? getVideoType(slide.videoUrl) : 0,
    }));

    const res = yield call(saveSlideshow, {
      slides: payload,
      slideshow_id: slideShowId,
    });

    if (res.status) {
      Log.volumeBuilder().saveSlideshow(slideShowId);

      yield put(saveSlideshowSuccess());
      if (!!data?.cb && typeof data?.cb === "function")
        data.cb(res.data.message);
    }
  } catch (err) {
    yield put(saveSlideshowError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* slidrSaga() {
  yield takeEvery(
    [SAVE_SLIDE_SHOW, SLIDR_ADD_SLIDE, SLIDR_COPY_SLIDE],
    handleSaveSlideshow
  );
}

export default slidrSaga;
