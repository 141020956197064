// Report
export const SET_ANALYTICS_EMAILS_RANGE_DATE =
  "SET_ANALYTICS_EMAILS_RANGE_DATE";

export const ANALYTICS_EMAIL_SET_TZ = "ANALYTICS_EMAIL_SET_TZ";

// Table
export const SET_ANALYTICS_EMAIL_BY = "SET_ANALYTICS_EMAIL_BY";
export const GET_EMAIL_LIST = "GET_EMAIL_LIST";
export const GET_EMAIL_LIST_SUCCESS = "GET_EMAIL_LIST_SUCCESS";
export const GET_EMAIL_LIST_ERROR = "GET_EMAIL_LIST_ERROR";
export const SET_ANALYTICS_EMAIL_SEARCH = "SET_ANALYTICS_EMAIL_SEARCH";
export const SET_ANALYTICS_EMAIL_PAGE = "SET_ANALYTICS_EMAIL_PAGE";
export const SET_ANALYTICS_EMAIL_SIZE_PER_PAGE =
  "SET_ANALYTICS_EMAIL_SIZE_PER_PAGE";
export const SET_ANALYTICS_EMAIL_SORT_ORDER = "SET_ANALYTICS_EMAIL_SORT_ORDER";
export const SET_ANALYTICS_EMAIL_SORT_FIELD = "SET_ANALYTICS_EMAIL_SORT_FIELD";
export const FETCH_ANALYTICS_EMAIL_SITES = "FETCH_ANALYTICS_EMAIL_SITES";

export const GET_TOTAL_BY_DATE = "GET_TOTAL_BY_DATE";
export const GET_TOTAL_BY_DATE_SUCCESS = "GET_TOTAL_BY_DATE_SUCCESS";
export const GET_TOTAL_BY_DATE_ERROR = "GET_TOTAL_BY_DATE_ERROR";

export const GET_TOTAL_PAGE = "GET_TOTAL_PAGE";
export const GET_TOTAL_SIZE_PER_PAGE = "GET_TOTAL_SIZE_PER_PAGE";
export const GET_TOTAL_SORT_ORDER = "GET_TOTAL_SORT_ORDER";
export const GET_TOTAL_SORT_FIELD = "GET_TOTAL_SORT_FIELD";
export const GET_TOTAL_SEARCH = "GET_TOTAL_SEARCH";
export const CLEAR_VIEW_TOTAL_DATA = "CLEAR_VIEW_TOTAL_DATA";

// Graph
export const GET_ANALYTICS_EMAIL_GRAPH = "GET_ANALYTICS_EMAIL_GRAPH";
export const GET_ANALYTICS_EMAIL_GRAPH_SUCCESS =
  "GET_ANALYTICS_EMAIL_GRAPH_SUCCESS";
export const GET_ANALYTICS_EMAIL_GRAPH_ERROR =
  "GET_ANALYTICS_EMAIL_GRAPH_ERROR";
export const SET_ANALYTICS_EMAIL_GRAPH_BY = "SET_ANALYTICS_EMAIL_GRAPH_BY";
