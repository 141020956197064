import {
  ADD_EDIT_CURRICULUM_POST,
  ADD_EDIT_CURRICULUM_POST_ERROR,
  ADD_EDIT_CURRICULUM_POST_SUCCESS,
  ADD_EDIT_PAGE_EDITOR,
  ADD_EDIT_PAGE_EDITOR_ERROR,
  ADD_EDIT_PAGE_EDITOR_SUCCESS,
  ADD_SERIES_POST,
  ADD_SERIES_POST_ERROR,
  ADD_SERIES_POST_SUCCESS,
  CLEAR_CURRICULUM_POST,
  CLEAR_POSTS,
  DELETE_AB_SERIES,
  DELETE_POST,
  DELETE_POSTS_FOLDER,
  DELETE_POSTS_FOLDER_ERROR,
  DELETE_POSTS_FOLDER_SUCCESS,
  DELETE_POST_ERROR,
  DELETE_POST_SUCCESS,
  EMPTY_SERIES_POST,
  FETCH_CURRICULUM_POST_DETAILS,
  FETCH_CURRICULUM_POST_DETAILS_ERROR,
  FETCH_CURRICULUM_POST_DETAILS_SUCCESS,
  FETCH_HEADER_FOOTER_DATA,
  FETCH_HEADER_FOOTER_DATA_ERROR,
  FETCH_HEADER_FOOTER_DATA_SUCCESS,
  GET_ALL_CONTENT,
  GET_ALL_CONTENT_ERROR,
  GET_ALL_CONTENT_SUCCESS,
  GET_MESSAGE_POST,
  GET_MESSAGE_POST_ERROR,
  GET_MESSAGE_POST_SUCCESS,
  GET_SERIES_POST,
  GET_SERIES_POST_ERROR,
  GET_SERIES_POST_SUCCESS,
  GET_SERIES_SORT_POST,
  GET_SERIES_SORT_POST_ERROR,
  GET_SERIES_SORT_POST_SUCCESS,
  GET_SORT_SERIES_POST,
  GET_SORT_SERIES_POST_ERROR,
  GET_SORT_SERIES_POST_SUCCESS,
  LIST_ALL_POSTS,
  LIST_ALL_POSTS_ERROR,
  LIST_ALL_POSTS_SUCCESS,
  SAVE_PAGE_POSTS,
  SAVE_PAGE_POSTS_ERROR,
  SAVE_PAGE_POSTS_SUCCESS,
  SAVE_PAGE_SERIES,
  SAVE_PAGE_SERIES_ERROR,
  SAVE_PAGE_SERIES_SUCCESS,
  SET_CURRENT_TAB_ID,
  SET_POSTS_PAGE,
  SET_POSTS_SEARCH,
  SET_POSTS_SIZE_PER_PAGE,
  SET_POSTS_SORT_FIELD,
  SET_POSTS_SORT_ORDER,
  SORT_FREE_VBS_PAGES,
} from "./actionTypes";

export const getAllContent = data => ({
  type: GET_ALL_CONTENT,
  payload: data,
});

export const getAllContentSuccess = data => ({
  type: GET_ALL_CONTENT_SUCCESS,
  payload: data,
});

export const getAllContentError = () => ({
  type: GET_ALL_CONTENT_ERROR,
});
export const setCurrentTab = data => ({
  type: SET_CURRENT_TAB_ID,
  payload: data,
});

export const listAllPosts = data => ({
  type: LIST_ALL_POSTS,
  payload: data,
});

export const listAllPostsSuccess = data => ({
  type: LIST_ALL_POSTS_SUCCESS,
  payload: data,
});

export const listAllPostsError = () => ({
  type: LIST_ALL_POSTS_ERROR,
});

export const DeletePostsFolder = (data, cb) => ({
  type: DELETE_POSTS_FOLDER,
  payload: data,
  cb: cb,
});

export const DeletePostsFolderSuccess = data => ({
  type: DELETE_POSTS_FOLDER_SUCCESS,
  payload: data,
});

export const DeletePostsFolderError = () => ({
  type: DELETE_POSTS_FOLDER_ERROR,
});

export const setPostsPage = data => ({
  type: SET_POSTS_PAGE,
  payload: data,
});

export const setPostsSizePerPage = data => ({
  type: SET_POSTS_SIZE_PER_PAGE,
  payload: data,
});

export const setPostsSearch = data => ({
  type: SET_POSTS_SEARCH,
  payload: data,
});

export const setPostsSortField = data => ({
  type: SET_POSTS_SORT_FIELD,
  payload: data,
});

export const setPostsSortOrder = data => ({
  type: SET_POSTS_SORT_ORDER,
  payload: data,
});

export const deletePost = data => ({
  type: DELETE_POST,
  payload: data,
});
export const deletePostSuccess = data => ({
  type: DELETE_POST_SUCCESS,
  payload: data,
});
export const deletePostError = () => ({
  type: DELETE_POST_ERROR,
});

export const addEditCurriculumPost = data => ({
  type: ADD_EDIT_CURRICULUM_POST,
  payload: data,
});

export const addEditCurriculumPostSuccess = data => ({
  type: ADD_EDIT_CURRICULUM_POST_SUCCESS,
  payload: data,
});

export const addEditCurriculumPostError = () => ({
  type: ADD_EDIT_CURRICULUM_POST_ERROR,
});

export const fetchCurriculumPostDetails = data => ({
  type: FETCH_CURRICULUM_POST_DETAILS,
  payload: data,
});
export const clearCurriculumPost = () => ({
  type: CLEAR_CURRICULUM_POST,
});

export const fetchCurriculumPostDetailsSuccess = data => ({
  type: FETCH_CURRICULUM_POST_DETAILS_SUCCESS,
  payload: data,
});

export const fetchCurriculumPostDetailsError = () => ({
  type: FETCH_CURRICULUM_POST_DETAILS_ERROR,
});

export const fetchHeaderFooter = () => ({
  type: FETCH_HEADER_FOOTER_DATA,
});
export const fetchHeaderFooterSuccess = data => ({
  type: FETCH_HEADER_FOOTER_DATA_SUCCESS,
  payload: data,
});
export const fetchHeaderFooterError = () => ({
  type: FETCH_HEADER_FOOTER_DATA_ERROR,
});

export const addEditPageEditor = data => ({
  type: ADD_EDIT_PAGE_EDITOR,
  payload: data,
});
export const addEditPageEditorSuccess = () => ({
  type: ADD_EDIT_PAGE_EDITOR_SUCCESS,
});
export const addEditPageEditorError = () => ({
  type: ADD_EDIT_PAGE_EDITOR_ERROR,
});
export const savePageSeries = data => ({
  type: SAVE_PAGE_SERIES,
  payload: data,
});
export const savePageSeriesSuccess = () => ({
  type: SAVE_PAGE_SERIES_SUCCESS,
});
export const savePageSeriesError = () => ({
  type: SAVE_PAGE_SERIES_ERROR,
});
export const savePagePosts = data => ({
  type: SAVE_PAGE_POSTS,
  payload: data,
});
export const savePagePostsSuccess = () => ({
  type: SAVE_PAGE_POSTS_SUCCESS,
});
export const savePagePostsError = () => ({
  type: SAVE_PAGE_POSTS_ERROR,
});
export const clearPosts = () => ({
  type: CLEAR_POSTS,
});
//Add series post
export const addSeriesPost = data => ({
  type: ADD_SERIES_POST,
  payload: data,
});
export const addSeriesPostSuccess = () => ({
  type: ADD_SERIES_POST_SUCCESS,
});
export const addSeriesPostError = () => ({
  type: ADD_SERIES_POST_ERROR,
});
//Get all message series post
export const getAllMessagePost = data => ({
  type: GET_MESSAGE_POST,
  payload: data,
});
export const getAllMessagePostSuccess = data => ({
  type: GET_MESSAGE_POST_SUCCESS,
  payload: data,
});
export const getAllMessagePostError = data => ({
  type: GET_MESSAGE_POST_ERROR,
  payload: data,
});

//Get all volume series post
export const getAllSeriesPost = data => ({
  type: GET_SERIES_POST,
  payload: data,
});
export const getAllSeriesPostSuccess = data => ({
  type: GET_SERIES_POST_SUCCESS,
  payload: data,
});
export const getAllSeriesPostError = data => ({
  type: GET_SERIES_POST_ERROR,
  payload: data,
});
//Delete section from redux
export const emptySeriesPost = data => {
  return {
    type: EMPTY_SERIES_POST,
    payload: data,
  };
};
//Delete series from redux
export const deleteSeriesRedux = data => {
  return {
    type: DELETE_AB_SERIES,
    payload: data,
  };
};

export const sortFreeVBS = (data, cb) => {
  return {
    type: SORT_FREE_VBS_PAGES,
    payload: data,
    cb: cb,
  };
};
//Get all post sort order data
export const getAllSeriesSortPost = data => ({
  type: GET_SERIES_SORT_POST,
  payload: data,
});
export const getAllSeriesSortPostSuccess = data => ({
  type: GET_SERIES_SORT_POST_SUCCESS,
  payload: data,
});
export const getAllSeriesSortPostError = data => ({
  type: GET_SERIES_SORT_POST_ERROR,
  payload: data,
});

//Get all series sort order data
export const getAllSortSeriesPost = data => ({
  type: GET_SORT_SERIES_POST,
  payload: data,
});
export const getAllSortSeriesPostSuccess = data => ({
  type: GET_SORT_SERIES_POST_SUCCESS,
  payload: data,
});
export const getAllSortSeriesPostError = data => ({
  type: GET_SORT_SERIES_POST_ERROR,
  payload: data,
});
