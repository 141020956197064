import { axiosApiAdmin } from "helpers/api_helper";
import {
  LIST_NEW_SUBSCRIPTION_CHART,
  LIST_RENEWED_SUBSCRIPTION_CHART,
  LIST_SUBSCRIPTION_REPORT_DATA_URL,
  LIST_SUBSCRIPTION_REPORT_DATE_WISE,
} from "helpers/url_helper";
import { tzs } from "modules/Analytics/common/constants";
import { DateTimeTz } from "modules/Analytics/common/utils";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  getNewSubscriptionChartDataError,
  getNewSubscriptionChartDataSuccess,
  getRenewedSubscriptionChartDataError,
  getRenewedSubscriptionChartDataSuccess,
  getSubscriptionCountError,
  getSubscriptionCountSuccess,
  getSubscriptionReportDateWiseError,
  getSubscriptionReportDateWiseSuccess,
  setErrorMessage,
} from "store/actions";
import {
  FETCH_NEW_SUBSCRIPTION_CHART_DATA,
  FETCH_RENEWED_SUBSCRIPTION_CHART_DATA,
  FETCH_SUBSCRIPTION_REPORT_DATA,
  FETCH_SUBSCRIPTION_REPORT_DATE_WISE,
} from "./actionTypes";

const getSubscriptionsCount = data =>
  axiosApiAdmin.post(LIST_SUBSCRIPTION_REPORT_DATA_URL, data);

const getSubscriptionsDateWise = data =>
  axiosApiAdmin.post(LIST_SUBSCRIPTION_REPORT_DATE_WISE, data);

const getNewSubscriptionsChartData = data =>
  axiosApiAdmin.post(LIST_NEW_SUBSCRIPTION_CHART, data);

const getRenewedSubscriptionsChartData = data =>
  axiosApiAdmin.post(LIST_RENEWED_SUBSCRIPTION_CHART, data);

function* handleFetchRenewedSubscriptionChartData() {
  try {
    const { range, comparedRange, filter_renew, siteId, tz } = yield select(
      state => state.SubscriptionReducer
    );

    const payload = {
      current_range: {
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(range.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      previous_range: {
        start_date: DateTimeTz(comparedRange.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(comparedRange.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      filter_renew,
      site_id: siteId,
      tz: tzs[tz],
    };
    const res = yield call(getRenewedSubscriptionsChartData, payload);
    if (res.data?.data) {
      yield put(getRenewedSubscriptionChartDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getRenewedSubscriptionChartDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchNewSubscriptionChartData() {
  try {
    const { range, comparedRange, filter_new, siteId, tz } = yield select(
      state => state.SubscriptionReducer
    );

    const payload = {
      current_range: {
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(range.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      previous_range: {
        start_date: DateTimeTz(comparedRange.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(comparedRange.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      filter_new,
      site_id: siteId,
      tz: tzs[tz],
    };
    const res = yield call(getNewSubscriptionsChartData, payload);
    if (res.data?.data) {
      yield put(getNewSubscriptionChartDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getNewSubscriptionChartDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchSubscriptionReport({ payload }) {
  try {
    const res = yield call(getSubscriptionsCount, { site_id: payload });
    if (res.data?.data) {
      yield put(getSubscriptionCountSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getSubscriptionCountError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchSubscriptionReportDateWise() {
  try {
    const { subscriptionCountDateWise, range, siteId, tz } = yield select(
      state => state.SubscriptionReducer
    );

    const payload = {
      page_record: subscriptionCountDateWise.pageRecord,
      page_no: subscriptionCountDateWise.pageNo,
      sort_field: subscriptionCountDateWise.sortField,
      sort_order: subscriptionCountDateWise.sortOrder,
      start_date: DateTimeTz(range.startDate)
        .startTime(tz)
        .format("YYYY-MM-DD HH:mm"),
      end_date: DateTimeTz(range.endDate)
        .endTime(tz)
        .format("YYYY-MM-DD HH:mm"),
      site_id: siteId,
      tz: tzs[tz],
    };

    const res = yield call(getSubscriptionsDateWise, payload);
    if (res.status) {
      yield put(getSubscriptionReportDateWiseSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getSubscriptionReportDateWiseError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* analyticsSubscriptionSaga() {
  yield takeEvery(
    FETCH_RENEWED_SUBSCRIPTION_CHART_DATA,
    handleFetchRenewedSubscriptionChartData
  );

  yield takeEvery(
    FETCH_NEW_SUBSCRIPTION_CHART_DATA,
    handleFetchNewSubscriptionChartData
  );

  yield takeEvery(
    FETCH_SUBSCRIPTION_REPORT_DATA,
    handleFetchSubscriptionReport
  );

  yield takeEvery(
    FETCH_SUBSCRIPTION_REPORT_DATE_WISE,
    handleFetchSubscriptionReportDateWise
  );
}

export default analyticsSubscriptionSaga;
