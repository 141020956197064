import { axiosApiAdmin } from "helpers/api_helper";
import { GET_FAIL_SUBSCRIPTION } from "helpers/url_helper";
import { DateTimeTz } from "modules/Analytics/common/utils";
import { setErrorMessage } from "store/actions";
import { getFailSubscriptionError, getFailSubscriptionSuccess } from "./action";
import { FETCH_FAIL_SUBSCRIPTION } from "./actionType";

const { takeEvery, put, select, call } = require("redux-saga/effects");

const fetchFailSubscriptionData = payload =>
  axiosApiAdmin.post(GET_FAIL_SUBSCRIPTION, payload);

function* FailSubscriptionSaga() {
  function* handleFetchFailSubscription() {
    try {
      const {
        failSubscriptionTableData: {
          loading,
          rows,
          totalSize,
          ordersCount,
          page,
          sizePerPage,
          sortOrder,
          sortField,
          search,
        },
        range,
        tz,
      } = yield select(state => state.FailSubscription);
      let PageNo = search.length > 0 ? 1 : page;
      const payload = {
        search: search,
        page_record: sizePerPage,
        page_no: PageNo,
        sort_field: sortField,
        sort_order: sortOrder,
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD"),
        end_date: DateTimeTz(range.endDate).format("YYYY-MM-DD"), // .endTime(tz) 4HoursIssue comment
      };

      const res = yield call(fetchFailSubscriptionData, payload);
      if (res?.status && res.data?.data) {
        yield put(
          getFailSubscriptionSuccess({
            totalSize: res?.data?.data?.count,
            rows: res?.data?.data?.rows,
          })
        );
      }
    } catch (err) {
      yield put(getFailSubscriptionError());
      yield put(setErrorMessage(err?.response?.data?.message || err.message));
    }
  }

  yield takeEvery(FETCH_FAIL_SUBSCRIPTION, handleFetchFailSubscription);
}

export default FailSubscriptionSaga;
