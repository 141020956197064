// Va 05 - 01 - 21
import { axiosApiAdmin } from "helpers/api_helper";
import { NEW_LIST_ACTIVE_SUBSCRIPTION } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchlistSubscriptionReportForExportSuccess,
  fetchNewListActiveSubscriptionError,
  fetchNewListActiveSubscriptionSuccess,
  setErrorMessage,
} from "store/actions";
import {
  FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION,
  FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT,
} from "./actionTypes";

const fetchNewActiveSubscription = data =>
  axiosApiAdmin.post(NEW_LIST_ACTIVE_SUBSCRIPTION, data);

function* handleNewFetchActiveSubscription({ payload, cb }) {
  try {
    const res = yield call(fetchNewActiveSubscription, payload);
    if (res) {
      cb(res);

      yield put(fetchNewListActiveSubscriptionSuccess(res));
    }
  } catch (err) {
    yield put(fetchNewListActiveSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleNewFetchActiveSubscriptionExport({ payload }) {
  try {
    const res = yield call(fetchNewActiveSubscription, payload);
    if (res) {
      yield put(
        fetchlistSubscriptionReportForExportSuccess(res.data.data.rows)
      );
    }
  } catch (err) {
    yield put(fetchNewListActiveSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

export function* NewActiveSubscriptionSaga() {
  yield takeEvery(
    FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION,
    handleNewFetchActiveSubscription
  );
}

export function* NewActiveSubscriptionExportSaga() {
  yield takeEvery(
    FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT,
    handleNewFetchActiveSubscriptionExport
  );
}
