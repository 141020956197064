import { axiosApiAdmin } from "helpers/api_helper";
import { GET_EMAIL_LOG_DATA } from "helpers/url_helper";
import moment from "moment";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { FETCH_EMAIL_LOG_DATA } from "./actionTypes";

import { setErrorMessage } from "store/actions";
import {
  getEmailLogDataReportError,
  getEmailLogDataReportSuccess,
} from "../../EmailLogs/store/actions";

const getEmailLogDataReports = data =>
  axiosApiAdmin.post(GET_EMAIL_LOG_DATA, data);

function* handleFetchEmailLogDataReport() {
  try {
    const { range, emails, filter } = yield select(state => state.EmailReducer);

    const payload = {
      range: {
        start_date: moment(range.startDate).format("YYYY-MM-DD"),
        end_date: moment(range.endDate).format("YYYY-MM-DD"),
      },
      search: emails.search,
      page_record: emails.pageRecord,
      page_no: emails.pageNo,
      filter: [
        {
          ...(filter.title ? { title: filter.title } : {}),
          ...(!!filter.status ? { status: filter.status } : {}),
        },
      ],
      sort_order: emails.sortOrder,
      sort_field: emails.sortField,
    };

    const res = yield call(getEmailLogDataReports, payload);
    if (res.data?.data) {
      yield put(getEmailLogDataReportSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getEmailLogDataReportError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* emailLogSaga() {
  yield takeEvery(FETCH_EMAIL_LOG_DATA, handleFetchEmailLogDataReport);
}

export default emailLogSaga;
