// Va 24 - 11 - 21

// ADD/EDIT COUPON
export const ADD_EDIT_COUPON = "ADD_EDIT_COUPON";
export const ADD_EDIT_COUPON_SUCCESS = "ADD_EDIT_COUPON_SUCCESS";
export const ADD_EDIT_COUPON_ERROR = "ADD_EDIT_COUPON_ERROR";

// GET COUPON DETAIL
export const GET_COUPON_DETAILS = "GET_COUPON_DETAILS";
export const GET_COUPON_DETAILS_SUCCESS = "GET_COUPON_DETAILS_SUCCESS";
export const GET_COUPON_DETAILS_ERROR = "GET_COUPON_DETAILS_ERROR";

// GET COUPON
export const FETCH_COUPON = "FETCH_COUPON";
export const FETCH_COUPON_SUCCESS = "FETCH_COUPON_SUCCESS";
export const FETCH_COUPON_ERROR = "FETCH_COUPON_ERROR";

// Table
export const SET_COUPON_PAGE = "SET_COUPON_PAGE";
export const SET_COUPON_SEARCH = "SET_COUPON_SEARCH";
export const SET_COUPON_SIZE_PER_PAGE = "SET_COUPON_SIZE_PER_PAGE";
export const SET_COUPON_SORT_FIELD = "SET_COUPON_SORT_FIELD";
export const SET_COUPON_SORT_ORDER = "SET_COUPON_SORT_ORDER";
export const CLEAR_COUPON_FEATURES = "CLEAR_COUPON_FEATURES";

// DELETE COUPON
export const DELETE_COUPONS = "DELETE_COUPON";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_ERROR = "DELETE_COUPON_ERROR";
