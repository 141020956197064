// Va 05 - 01 - 21

import {
  CLEAR_SUCCESS_SUBSCRIPTION,
  FATCH_LIST_SUCCESS_SUBSCRIPTION,
  FATCH_LIST_SUCCESS_SUBSCRIPTION_ERROR,
  FATCH_LIST_SUCCESS_SUBSCRIPTION_EXPORT,
  FATCH_LIST_SUCCESS_SUBSCRIPTION_EXPORT_NULL,
  FATCH_LIST_SUCCESS_SUBSCRIPTION_EXPORT_SUCCESS,
  FATCH_LIST_SUCCESS_SUBSCRIPTION_SUCCESS,
  SET_ORDER_RANGE_DATE_SUCCESS_SUBSCRIPTION,
  SET_PAGE_SUCCESS_SUBSCRIPTION,
  SET_SEARCH_SUCCESS_SUBSCRIPTION,
  SET_SIZE_PER_PAGE_SUCCESS_SUBSCRIPTION,
  SET_SORT_FIELD_SUCCESS_SUBSCRIPTION,
  SET_SORT_ORDER_SUCCESS_SUBSCRIPTION,
} from "./actionTypes";

export const fetchlistSuccessSubscription = (data, cb) => ({
  type: FATCH_LIST_SUCCESS_SUBSCRIPTION,
  payload: data,
  cb: cb,
});

export const fetchlistSuccessSubscriptionForExport = (data, cb) => ({
  type: FATCH_LIST_SUCCESS_SUBSCRIPTION_EXPORT,
  payload: data,
  cb: cb,
});

export const fetchlistSuccessSubscriptionForExportSuccess = data => ({
  type: FATCH_LIST_SUCCESS_SUBSCRIPTION_EXPORT_SUCCESS,
  payload: data,
});
export const fetchlistSuccessSubscriptionForExportNull = data => ({
  type: FATCH_LIST_SUCCESS_SUBSCRIPTION_EXPORT_NULL,
  payload: data,
});
export const fetchlistSuccessSubscriptionSuccess = data => ({
  type: FATCH_LIST_SUCCESS_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const fetchlistSuccessSubscriptionError = () => ({
  type: FATCH_LIST_SUCCESS_SUBSCRIPTION_ERROR,
});
export const clearSuccessSubscription = () => ({
  type: CLEAR_SUCCESS_SUBSCRIPTION,
});

export const setPageSuccessSubscription = data => {
  return {
    type: SET_PAGE_SUCCESS_SUBSCRIPTION,
    payload: data,
  };
};

export const setSizePerPageSuccessSubscription = data => {
  return {
    type: SET_SIZE_PER_PAGE_SUCCESS_SUBSCRIPTION,
    payload: data,
  };
};

export const setSearchSuccessSubscription = data => {
  return {
    type: SET_SEARCH_SUCCESS_SUBSCRIPTION,
    payload: data,
  };
};

export const setSortFieldSuccessSubscription = data => ({
  type: SET_SORT_FIELD_SUCCESS_SUBSCRIPTION,
  payload: data,
});

export const setSortOrderSuccessSubscription = data => ({
  type: SET_SORT_ORDER_SUCCESS_SUBSCRIPTION,
  payload: data,
});

export const setOrderRangeDateSuccessSubscription = data => ({
  type: SET_ORDER_RANGE_DATE_SUCCESS_SUBSCRIPTION,
  payload: data,
});
