import {
  ADD_EDIT_PRODUCT,
  ADD_EDIT_PRODUCT_ERROR,
  ADD_EDIT_PRODUCT_FOLDER,
  ADD_EDIT_PRODUCT_SUCCESS,
  CLEAR_PRODUCT_FEATURES,
  DELETE_PRODUCT,
  DELETE_PRODUCT_ERROR,
  DELETE_PRODUCT_FOLDER,
  DELETE_PRODUCT_FOLDER_SUCCESS,
  DELETE_PRODUCT_SUCCESS,
  FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT,
  FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT_ERROR,
  FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT_SUCCESS,
  FETCH_PRODUCTS,
  FETCH_PRODUCT_ERROR,
  FETCH_PRODUCT_FOLDERS,
  FETCH_PRODUCT_FOLDER_ERROR,
  FETCH_PRODUCT_FOLDER_SUCCESS,
  FETCH_PRODUCT_SITES,
  FETCH_PRODUCT_SITES_ERROR,
  FETCH_PRODUCT_SITES_SUCCESS,
  FETCH_PRODUCT_SUCCESS,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_ERROR,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_FOLDER_DETAILS,
  GET_PRODUCT_FOLDER_DETAILS_SUCCESS,
  SET_PRODUCT_FILTER_CATEGORY_ID,
  SET_PRODUCT_PAGE,
  SET_PRODUCT_SEARCH,
  SET_PRODUCT_SIZE_PER_PAGE,
  SET_PRODUCT_SORT_FIELD,
  SET_PRODUCT_SORT_ORDER,
} from "./actionTypes";

export const fetchProducts = data => {
  return {
    type: FETCH_PRODUCTS,
    payload: data,
  };
};

export const fetchProductFolders = data => ({
  type: FETCH_PRODUCT_FOLDERS,
  payload: data,
});

export const fetchProductSuccess = data => ({
  type: FETCH_PRODUCT_SUCCESS,
  payload: data,
});

export const fetchProductError = data => ({
  type: FETCH_PRODUCT_ERROR,
  payload: data,
});

export const fetchProductFolderSuccess = data => ({
  type: FETCH_PRODUCT_FOLDER_SUCCESS,
  payload: data,
});

export const fetchProductFolderError = data => ({
  type: FETCH_PRODUCT_FOLDER_ERROR,
  payload: data,
});

// add/updating product
export const addEditProduct = data => ({
  type: ADD_EDIT_PRODUCT,
  payload: data,
});

export const addEditProductSuccess = data => ({
  type: ADD_EDIT_PRODUCT_SUCCESS,
  payload: data,
});

export const addEditProductError = () => ({
  type: ADD_EDIT_PRODUCT_ERROR,
});

export const getProductDetails = data => ({
  type: GET_PRODUCT_DETAILS,
  payload: data,
});

export const getProductDetailsSuccess = data => {
  return {
    type: GET_PRODUCT_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getProductDetailsError = () => ({
  type: GET_PRODUCT_DETAILS_ERROR,
});

export const deleteProduct = data => ({
  type: DELETE_PRODUCT,
  payload: data,
});

export const deleteProductSuccess = data => {
  return {
    type: DELETE_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const deleteProductError = () => ({
  type: DELETE_PRODUCT_ERROR,
});

export const addEditProductFolder = data => ({
  type: ADD_EDIT_PRODUCT_FOLDER,
  payload: data.productFolderDetails,
});

export const getProductFolderDetails = data => ({
  type: GET_PRODUCT_FOLDER_DETAILS,
  payload: data,
});

export const getProductFolderDetailsSuccess = data => {
  return {
    type: GET_PRODUCT_FOLDER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const deleteProductFolder = data => ({
  type: DELETE_PRODUCT_FOLDER,
  payload: data,
});

export const deleteProductFolderSuccess = data => {
  return {
    type: DELETE_PRODUCT_FOLDER_SUCCESS,
    payload: data,
  };
};

export const fetchProductSites = data => ({
  type: FETCH_PRODUCT_SITES,
  payload: data,
});

export const fetchProductSitesSuccess = data => ({
  type: FETCH_PRODUCT_SITES_SUCCESS,
  payload: data,
});

export const fetchProductSitesError = data => ({
  type: FETCH_PRODUCT_SITES_ERROR,
  payload: data,
});

export const setProductPage = data => ({
  type: SET_PRODUCT_PAGE,
  payload: data,
});
export const setProductSizePerPage = data => ({
  type: SET_PRODUCT_SIZE_PER_PAGE,
  payload: data,
});
export const setProductSearch = data => ({
  type: SET_PRODUCT_SEARCH,
  payload: data,
});
export const setProductSortField = data => ({
  type: SET_PRODUCT_SORT_FIELD,
  payload: data,
});
export const setProductSortOrder = data => ({
  type: SET_PRODUCT_SORT_ORDER,
  payload: data,
});
export const clearProductFeatures = data => ({
  type: CLEAR_PRODUCT_FEATURES,
});

export const getVolumesWithCount = () => ({
  type: FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT,
});
export const getVolumesWithCountSuccess = data => ({
  type: FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT_SUCCESS,
  payload: data,
});
export const getVolumesWithCountError = () => ({
  type: FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT_ERROR,
});
export const setProductCategoryId = data => ({
  type: SET_PRODUCT_FILTER_CATEGORY_ID,
  payload: data,
});
