// Va 05 - 01 - 21

import {
  CLEAR_NEW_ACTIVE_SUBSCRIPTION,
  FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_ERROR,
  FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT,
  FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT_NULL,
  FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT_SUCCESS,
  FATCH_NEW_LIST_ONBOARDING,
  FATCH_NEW_LIST_ON_BOARD_SUCCESS,
  FETCH_MODAL_FEEDBACK_LIST,
  SET_NEW_ORDER_RANGE_DATE_ACTIVE_SUBSCRIPTION,
  SET_NEW_PAGE_ACTIVE_SUBSCRIPTION,
  SET_NEW_SEARCH_ACTIVE_SUBSCRIPTION,
  SET_NEW_SIZE_PER_PAGE_ACTIVE_SUBSCRIPTION,
  SET_NEW_SORT_FIELD_ACTIVE_SUBSCRIPTION,
  SET_NEW_SORT_ORDER_ACTIVE_SUBSCRIPTION,
} from "./actionTypes";

export const fetchOnBoardFreeTrialList = (data, cb) => ({
  type: FATCH_NEW_LIST_ONBOARDING,
  payload: data,
  cb: cb,
});

export const fetchModalFeedbackList = (data, cb) => ({
  type: FETCH_MODAL_FEEDBACK_LIST,
  payload: data,
  cb: cb,
});
export const fetchNewListActiveSubscriptionForExport = data => ({
  type: FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT,
  payload: data,
});

export const fetchNewListActiveSubscriptionForExportSuccess = data => ({
  type: FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT_SUCCESS,
  payload: data,
});
export const fetchNewListActiveSubscriptionForExportNull = data => ({
  type: FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_EXPORT_NULL,
  payload: data,
});
export const fetchNewListOnBoardSuccess = data => ({
  type: FATCH_NEW_LIST_ON_BOARD_SUCCESS,
  payload: data,
});

// export const fetchModa = data => ({
//   type: FATCH_NEW_LIST_ON_BOARD_SUCCESS,
//   payload: data,
// });

export const fetchNewListActiveSubscriptionError = () => ({
  type: FATCH_NEW_LIST_ACTIVE_SUBSCRIPTION_ERROR,
});
export const clearNewActiveSubscription = () => ({
  type: CLEAR_NEW_ACTIVE_SUBSCRIPTION,
});

export const setNewPageActiveSubscription = data => {
  return {
    type: SET_NEW_PAGE_ACTIVE_SUBSCRIPTION,
    payload: data,
  };
};

export const setNewSizePerPageActiveSubscription = data => {
  return {
    type: SET_NEW_SIZE_PER_PAGE_ACTIVE_SUBSCRIPTION,
    payload: data,
  };
};

export const setNewSearchActiveSubscription = data => {
  return {
    type: SET_NEW_SEARCH_ACTIVE_SUBSCRIPTION,
    payload: data,
  };
};

export const setNewSortFieldActiveSubscription = data => ({
  type: SET_NEW_SORT_FIELD_ACTIVE_SUBSCRIPTION,
  payload: data,
});

export const setNewSortOrderActiveSubscription = data => ({
  type: SET_NEW_SORT_ORDER_ACTIVE_SUBSCRIPTION,
  payload: data,
});

export const setNewOrderRangeDateActiveSubscription = data => ({
  type: SET_NEW_ORDER_RANGE_DATE_ACTIVE_SUBSCRIPTION,
  payload: data,
});
