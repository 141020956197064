// Va 05 - 01 - 21
import { axiosApiAdmin } from "helpers/api_helper";
import { LIST_SUCCESS_SUBSCRIPTION } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchlistSubscriptionReportForExportSuccess,
  fetchlistSuccessSubscriptionError,
  fetchlistSuccessSubscriptionSuccess,
  setErrorMessage,
} from "store/actions";
import {
  FATCH_LIST_SUCCESS_SUBSCRIPTION,
  FATCH_LIST_SUCCESS_SUBSCRIPTION_EXPORT,
} from "./actionTypes";

const fetchSuccessSubscription = data =>
  axiosApiAdmin.post(LIST_SUCCESS_SUBSCRIPTION, data);

function* handleFetchSuccessSubscription({ payload, cb }) {
  try {
    const res = yield call(fetchSuccessSubscription, payload);
    if (res) {
      cb(res);
      yield put(fetchlistSuccessSubscriptionSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchlistSuccessSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchSuccessSubscriptionExport({ payload, cb }) {
  try {
    const res = yield call(fetchSuccessSubscription, payload);
    if (res) {
      cb(res.data.data.rows);
      yield put(
        fetchlistSubscriptionReportForExportSuccess(res.data.data.rows)
      );
    }
  } catch (err) {
    yield put(fetchlistSuccessSubscriptionError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

export function* SuccessSubscriptionSaga() {
  yield takeEvery(
    FATCH_LIST_SUCCESS_SUBSCRIPTION,
    handleFetchSuccessSubscription
  );
}

export function* SuccessSubscriptionExportSaga() {
  yield takeEvery(
    FATCH_LIST_SUCCESS_SUBSCRIPTION_EXPORT,
    handleFetchSuccessSubscriptionExport
  );
}
