import { axiosApiAdmin } from "helpers/api_helper";
import {
  DELETE_POST_FOLDER_BY_ID,
  GET_ALL_PAGE_UI_COMPONENTS,
  GET_CURRICULUM_POST_DETAIL,
  LIST_ALL_CURRICULUM_POST,
  LIST_ALL_CURRICULUM_POST_PAGE,
  LIST_ALL_OLD_CURRICULUM_POST,
  LIST_MESSAGE_POST,
  LIST_SERIES_POST,
  LIST_SERIES_SORT_POST,
  LIST_SORT_SERIES_POST,
  REMOVE_CURRICULUM_POST,
  SAVE_PAGE_CONNECTED_POSTS,
  SAVE_PAGE_CONNECTED_SERIES,
  SAVE_PAGE_EDITOR,
  SORT_FREE_VBS_PAGES_API,
  UPDATE_CURRICULUM_POST,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  ADD_EDIT_CURRICULUM_POST,
  ADD_EDIT_PAGE_EDITOR,
  DELETE_POST,
  DELETE_POSTS_FOLDER,
  FETCH_CURRICULUM_POST_DETAILS,
  FETCH_HEADER_FOOTER_DATA,
  GET_ALL_CONTENT,
  GET_MESSAGE_POST,
  GET_SERIES_POST,
  GET_SERIES_SORT_POST,
  GET_SORT_SERIES_POST,
  LIST_ALL_POSTS,
  SAVE_PAGE_POSTS,
  SAVE_PAGE_SERIES,
  SORT_FREE_VBS_PAGES,
} from "./actionTypes";

import {
  addEditCurriculumPostError,
  addEditCurriculumPostSuccess,
  addEditPageEditorError,
  addEditPageEditorSuccess,
  deletePostError,
  DeletePostsFolderError,
  DeletePostsFolderSuccess,
  fetchCurriculumPostDetailsError,
  fetchCurriculumPostDetailsSuccess,
  fetchHeaderFooterError,
  fetchHeaderFooterSuccess,
  getAllContentError,
  getAllContentSuccess,
  getAllMessagePostError,
  getAllMessagePostSuccess,
  getAllSeriesPostError,
  getAllSeriesPostSuccess,
  getAllSeriesSortPostSuccess,
  getAllSortSeriesPostSuccess,
  listAllPostsError,
  listAllPostsSuccess,
  savePagePostsError,
  savePagePostsSuccess,
  savePageSeriesError,
  savePageSeriesSuccess,
  setPostsPage,
} from "./actions";

import { musicCategoryId } from "constants/volumes";
import { toast } from "react-toastify";
import { setErrorMessage, setSuccessMessage } from "store/actions";

const fetchAllContent = data =>
  axiosApiAdmin.get(`${LIST_ALL_CURRICULUM_POST_PAGE}/${data}`);
const fetchAllPostsByPageId = data =>
  axiosApiAdmin.post(LIST_ALL_CURRICULUM_POST, data);
const fetchAllOldPostsByPageId = data =>
  axiosApiAdmin.post(LIST_ALL_OLD_CURRICULUM_POST, data);
const deleteCurriculumPost = data =>
  axiosApiAdmin.delete(`${REMOVE_CURRICULUM_POST}/${data}`);
const addEditCurriculumPost = data =>
  axiosApiAdmin.post(UPDATE_CURRICULUM_POST, data);
const fetchCurriculumPostDetails = data =>
  axiosApiAdmin.post(`${GET_CURRICULUM_POST_DETAIL}`, data);
const fetchUiComponents = () => axiosApiAdmin.get(GET_ALL_PAGE_UI_COMPONENTS);
const addEditCurriculumPageEditor = data =>
  axiosApiAdmin.post(SAVE_PAGE_EDITOR, data);
const savePageConnectedSeries = data =>
  axiosApiAdmin.post(SAVE_PAGE_CONNECTED_SERIES, data);
const savePageConnectedPosts = data =>
  axiosApiAdmin.post(SAVE_PAGE_CONNECTED_POSTS, data);
const listMessagePost = data => axiosApiAdmin.post(LIST_MESSAGE_POST, data);
const listSeriesPost = data => axiosApiAdmin.post(LIST_SERIES_POST, data);
const listSeriessortPost = data =>
  axiosApiAdmin.post(LIST_SERIES_SORT_POST, data);
const listSortSeriesPost = data =>
  axiosApiAdmin.post(LIST_SORT_SERIES_POST, data);
const updateSortOrderData = data =>
  axiosApiAdmin.post(SORT_FREE_VBS_PAGES_API, data);
const DeletePostsFolderData = data =>
  axiosApiAdmin.delete(`${DELETE_POST_FOLDER_BY_ID}/${data.id}`);

function* getAllContent({ payload }) {
  try {
    const res = yield call(fetchAllContent, payload);

    if (res.data?.data?.page) {
      yield put(getAllContentSuccess(res.data.data.page));
    }
  } catch (err) {
    yield put(getAllContentError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* getAllPostsByPageId({ payload }) {
  try {
    const { search, sizePerPage, page, currentTabId } = yield select(
      state => state.CurriculumPostPage
    );
    const {
      types: { MinistryType, catActiveTab },
    } = yield select(state => state.VolumeReducer);
    if (payload?.page_id) {
      let res;
      if (!!payload?.isOldPosts) {
        res = yield call(fetchAllOldPostsByPageId, {
          search,
          is_from_old: payload.is_from_old,
          page_record: payload.sizePerPage || sizePerPage,
          page_no: !!payload?.page ? payload.page : page,
          content_type_id: !!payload?.content_type_id
            ? payload.content_type_id
            : currentTabId,
          category_id:
            payload?.content_type_id === 12
              ? parseInt(musicCategoryId)
              : [1, 7].includes(payload.category_id)
              ? payload.category_id
              : catActiveTab === 1 || catActiveTab === 7
              ? 0
              : catActiveTab,
          ministry_type: MinistryType,
          page_id: +payload.is_from_old === 1 ? 0 : parseInt(payload?.page_id),
        });
      } else {
        res = yield call(fetchAllPostsByPageId, {
          search,
          is_from_old: payload.is_from_old,
          page_record: payload.sizePerPage || sizePerPage,
          page_no: !!payload?.page ? payload.page : page,
          content_type_id: !!payload?.content_type_id
            ? payload.content_type_id
            : currentTabId,
          category_id:
            payload?.content_type_id === 12
              ? parseInt(musicCategoryId)
              : [1, 7].includes(payload.category_id)
              ? payload.category_id
              : catActiveTab === 1 || catActiveTab === 7
              ? 0
              : catActiveTab,
          ministry_type: MinistryType,
          page_id: +payload.is_from_old === 1 ? 0 : parseInt(payload?.page_id),
        });
      }
      if (!!payload?.page) yield put(setPostsPage(payload.page));
      if (res.status && res.data?.data?.posts_list) {
        yield put(listAllPostsSuccess(res.data.data.posts_list));
      }
    } else {
      const newPayload = {
        search,
        is_from_old: payload.is_from_old,
        page_record: payload.sizePerPage || sizePerPage,
        page_no: !!payload?.page ? payload.page : page,
        content_type_id: !!payload?.content_type_id
          ? payload.content_type_id
          : currentTabId,
        category_id:
          payload?.content_type_id === 12
            ? parseInt(musicCategoryId)
            : [1, 7].includes(payload.category_id)
            ? payload.category_id
            : catActiveTab === 1 || catActiveTab === 7
            ? 0
            : catActiveTab,
        ministry_type: MinistryType,
      };
      let res;
      if (!!payload?.isOldPosts) {
        res = yield call(fetchAllOldPostsByPageId, newPayload);
      } else {
        res = yield call(fetchAllPostsByPageId, newPayload);
      }
      if (!!payload?.page) yield put(setPostsPage(payload.page));
      if (res.status && res.data?.data?.posts_list) {
        yield put(listAllPostsSuccess(res.data.data.posts_list));
      }
    }
  } catch (err) {
    yield put(listAllPostsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* deletePostById({ payload: { postId, cb } }) {
  try {
    const res = yield call(deleteCurriculumPost, postId);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      cb();
    }
  } catch (err) {
    yield put(deletePostError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* addEditPost({ payload: { data, cb } }) {
  try {
    const res = yield call(addEditCurriculumPost, {
      ...data,
    });
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(addEditCurriculumPostSuccess());
      cb();
    }
  } catch (err) {
    yield put(addEditCurriculumPostError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* fetchPostById({ payload }) {
  try {
    const res = yield call(fetchCurriculumPostDetails, {
      post_id: payload.post_id,
      page_id: payload.page_id,
    });
    if (res.data?.data) {
      yield put(fetchCurriculumPostDetailsSuccess(res.data.data.post));
    }
  } catch (err) {
    yield put(fetchCurriculumPostDetailsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* fetchHeaderFooter() {
  try {
    const res = yield call(fetchUiComponents);
    if (res.data?.data?.data) {
      yield put(fetchHeaderFooterSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(fetchHeaderFooterError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* addEditPageEditor({ payload: { data, cb } }) {
  try {
    const res = yield call(addEditCurriculumPageEditor, data);
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(addEditPageEditorSuccess());
      if (!!cb) cb();
    }
  } catch (err) {
    yield put(addEditPageEditorError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* savePageSeries({ payload: { data, cb } }) {
  try {
    const res = yield call(savePageConnectedSeries, data);
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(savePageSeriesSuccess());
      if (!!cb) cb();
    }
  } catch (err) {
    yield put(savePageSeriesError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
function* savePagePosts({ payload: { data, cb } }) {
  try {
    const res = yield call(savePageConnectedPosts, data);
    if (res.status) {
      yield put(setSuccessMessage(res.data.message));
      yield put(savePagePostsSuccess());
      if (!!cb) cb();
    }
  } catch (err) {
    yield put(savePagePostsError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}
//Get message builder List
function* getMessagePost({ payload }) {
  try {
    const res = yield call(listMessagePost, payload);
    if (res.data?.data?.data) {
      yield put(getAllMessagePostSuccess(res.data.data.data));
    }
  } catch (err) {
    yield put(getAllMessagePostError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

//Get series builder List
function* getSeriesPost({ payload }) {
  try {
    const res = yield call(listSeriesPost, payload);
    if (res.data?.data) {
      yield put(getAllSeriesPostSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getAllSeriesPostError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

//GEt series builder list
function* getSeriesSortPost({ payload }) {
  try {
    const res = yield call(listSeriessortPost, payload);
    if (res.data?.data) {
      yield put(getAllSeriesSortPostSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getAllSeriesSortPostSuccess());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

//GEt series builder list
function* getSortSeriesPost({ payload }) {
  try {
    const res = yield call(listSortSeriesPost, payload);
    if (res.data?.data) {
      yield put(getAllSortSeriesPostSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getAllSortSeriesPostSuccess());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* updateSortOrder({ payload, cb }) {
  try {
    const res = yield call(updateSortOrderData, payload);
    if (res) {
      cb(res);
    }
  } catch (err) {
    toast.error(err.response?.data?.message || err.message);
  }
}

function* deleteFolderPost({ payload, cb }) {
  try {
    const res = yield call(DeletePostsFolderData, payload);
    if (res.data) {
      yield put(setSuccessMessage(res.data.message));
      yield put(DeletePostsFolderSuccess());
      if (res) {
        cb(res.data);
      }
    }
  } catch (err) {
    yield put(DeletePostsFolderError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* curriculumPostPageSaga() {
  yield takeEvery(GET_ALL_CONTENT, getAllContent);
  yield takeEvery(LIST_ALL_POSTS, getAllPostsByPageId);
  yield takeEvery(DELETE_POST, deletePostById);
  yield takeEvery(ADD_EDIT_CURRICULUM_POST, addEditPost);
  yield takeEvery(FETCH_CURRICULUM_POST_DETAILS, fetchPostById);
  yield takeEvery(FETCH_HEADER_FOOTER_DATA, fetchHeaderFooter);
  yield takeEvery(ADD_EDIT_PAGE_EDITOR, addEditPageEditor);
  yield takeEvery(SAVE_PAGE_SERIES, savePageSeries);
  yield takeEvery(SAVE_PAGE_POSTS, savePagePosts);
  yield takeEvery(GET_MESSAGE_POST, getMessagePost);
  yield takeEvery(GET_SERIES_POST, getSeriesPost);
  yield takeEvery(SORT_FREE_VBS_PAGES, updateSortOrder);
  yield takeEvery(DELETE_POSTS_FOLDER, deleteFolderPost);
  yield takeEvery(GET_SERIES_SORT_POST, getSeriesSortPost);
  yield takeEvery(GET_SORT_SERIES_POST, getSortSeriesPost);
}

export default curriculumPostPageSaga;
