import { axiosApiAdmin } from "helpers/api_helper";
import {
  GET_REVENUE_GRAPH_DATA,
  GET_REVENUE_REPORTS_DATA,
  GET_REVENUE_Table_DATA,
} from "helpers/url_helper";
import { tzs } from "modules/Analytics/common/constants";
import { DateTimeTz } from "modules/Analytics/common/utils";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  getRevenueGraphDataError,
  getRevenueGraphDataSuccess,
  getRevenueReportsError,
  getRevenueReportsSuccess,
  getRevenueTableDataError,
  getRevenueTableDataSuccess,
  setErrorMessage,
} from "store/actions";
import {
  FETCH_REVENUE_GRAPH_DATA,
  FETCH_REVENUE_REPORTS,
  FETCH_REVENUE_TABLE_DATA,
} from "./actionTypes";

const fetchRevenueReports = data =>
  axiosApiAdmin.post(GET_REVENUE_REPORTS_DATA, data);

const fetchRevenueGraphData = data =>
  axiosApiAdmin.post(GET_REVENUE_GRAPH_DATA, data);

const fetchRevenueTableData = data =>
  axiosApiAdmin.post(GET_REVENUE_Table_DATA, data);

function* handleFetchRevenueReports() {
  try {
    const { range, comparedRange, siteId, tz } = yield select(
      state => state.AnalyticsRevenue
    );

    const payload = {
      range: {
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(range.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      compared_range: {
        start_date: DateTimeTz(comparedRange.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(comparedRange.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      site_id: siteId,
      tz: tzs[tz],
    };

    const res = yield call(fetchRevenueReports, payload);
    if (res.status) {
      yield put(getRevenueReportsSuccess(res.data.data.reports));
    }
  } catch (err) {
    yield put(getRevenueReportsError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* handleFetchRevenueGraphData() {
  try {
    const { range, comparedRange, activeTab, by, siteId, tz } = yield select(
      state => state.AnalyticsRevenue
    );

    const payload = {
      range: {
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(range.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      compared_range: {
        start_date: DateTimeTz(comparedRange.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(comparedRange.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      by,
      type: activeTab,
      site_id: siteId,
      tz: tzs[tz],
    };

    const res = yield call(fetchRevenueGraphData, payload);

    if (res.status) {
      yield put(getRevenueGraphDataSuccess(res.data?.data?.graph || []));
    }
  } catch (err) {
    yield put(getRevenueGraphDataError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* handleFetchRevenueTableData() {
  try {
    const {
      tableData: { page, sizePerPage, sortOrder, sortField },
      range,
      siteId,
      tz,
    } = yield select(state => state.AnalyticsRevenue);

    const payload = {
      page_record: sizePerPage,
      page_no: page,
      sort_field: sortField,
      sort_order: sortOrder,
      range: {
        start_date: DateTimeTz(range.startDate)
          .startTime(tz)
          .format("YYYY-MM-DD HH:mm"),
        end_date: DateTimeTz(range.endDate)
          .endTime(tz)
          .format("YYYY-MM-DD HH:mm"),
      },
      site_id: siteId,
      tz: tzs[tz],
    };

    const res = yield call(fetchRevenueTableData, payload);
    if (res.status) {
      if (res.data?.data)
        yield put(
          getRevenueTableDataSuccess({
            totalSize: res.data.data.count,
            ordersCount: 0,
            rows: res.data.data.rows,
          })
        );
    }
  } catch (err) {
    yield put(getRevenueTableDataError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

function* revenueSaga() {
  yield takeEvery(FETCH_REVENUE_REPORTS, handleFetchRevenueReports);
  yield takeEvery(FETCH_REVENUE_GRAPH_DATA, handleFetchRevenueGraphData);
  yield takeEvery(FETCH_REVENUE_TABLE_DATA, handleFetchRevenueTableData);
}

export default revenueSaga;
