import {
  API_ERROR,
  CLEAR_MESSAGES,
  FETCH_LOGGED_IN_USER_PROFILE,
  FETCH_LOGGED_IN_USER_PROFILE_ERROR,
  FETCH_LOGGED_IN_USER_PROFILE_SUCCESS,
  LOGIN_EMAIL_MESSAGE,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT_USER,
  SET_USER,
} from "./actionTypes";

export const loginUser = data => {
  return {
    type: LOGIN_USER,
    payload: data,
  };
};

export const loginUserSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const apiError = message => {
  return {
    type: API_ERROR,
    payload: message,
  };
};

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const setUser = data => ({
  type: SET_USER,
  payload: data,
});

export const fetchProfile = data => ({
  type: FETCH_LOGGED_IN_USER_PROFILE,
  payload: data,
});

export const fetchProfileSuccess = data => ({
  type: FETCH_LOGGED_IN_USER_PROFILE_SUCCESS,
  payload: data,
});

export const fetchProfileError = () => ({
  type: FETCH_LOGGED_IN_USER_PROFILE_ERROR,
});

export const setLoginEmailMessage = data => ({
  type: LOGIN_EMAIL_MESSAGE,
  payload: data,
});

export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
});
