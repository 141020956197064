import {
  ADD_EDIT_HUB_ANNOUNCEMENT,
  ADD_EDIT_HUB_ANNOUNCEMENT_ERROR,
  ADD_EDIT_HUB_ANNOUNCEMENT_SUCCESS,
  ADD_EDIT_HUB_CALENDAR_ITEMS,
  ADD_EDIT_HUB_CALENDAR_ITEMS_ERROR,
  ADD_EDIT_HUB_CALENDAR_ITEMS_SUCCESS,
  DELETE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT_ERROR,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_CALENDAR_ITEMS,
  DELETE_CALENDAR_ITEMS_ERROR,
  DELETE_CALENDAR_ITEMS_SUCCESS,
  DELETE_ELEMENT_REDUX,
  FETCH_APP_BUILDER_DETAIL_LISTING,
  FETCH_APP_BUILDER_DETAIL_LISTING_ERROR,
  FETCH_APP_BUILDER_DETAIL_LISTING_SUCCESS,
  FETCH_BUILDING_BLOCKS_LISTING,
  FETCH_BUILDING_BLOCKS_LISTING_ERROR,
  FETCH_BUILDING_BLOCKS_LISTING_SUCCESS,
  FETCH_MB_ELEMENTS,
  FETCH_MB_ELEMENTS_ERROR,
  FETCH_MB_ELEMENTS_SUCCESS,
  FETCH_RESOURCE_CURR_DETAIL,
  FETCH_RESOURCE_CURR_DETAIL_ERROR,
  FETCH_RESOURCE_CURR_DETAIL_SUCCESS,
  HANDLE_ELEMENT_INDEX_SUCCESS,
  HANDLE_MB_INDEX_SUCCESS,
  LIST_ANNOUNCEMENT,
  LIST_ANNOUNCEMENT_ERROR,
  LIST_ANNOUNCEMENT_SUCCESS,
  LIST_CALENDAR_ITEMS,
  LIST_CALENDAR_ITEMS_ERROR,
  LIST_CALENDAR_ITEMS_SUCCESS,
  MB_DELETE_ELEMENT,
  MB_DELETE_ELEMENT_ERROR,
  MB_DELETE_ELEMENT_SUCCESS,
  MB_DUPLICATE_ELEMENT,
  MB_DUPLICATE_ELEMENT_ERROR,
  MB_DUPLICATE_ELEMENT_SUCCESS,
  MESSAGE_VIDEO_ELEMENT,
  MESSAGE_VIDEO_ELEMENT_ERROR,
  MESSAGE_VIDEO_ELEMENT_SUCCESS,
  SAVE_ON_CHANGE,
  SAVE_ON_CHANGE_ERROR,
  SAVE_ON_CHANGE_SUCCESS,
  SET_AB_SECTION,
  SET_ANNOUNCEMENT_PAGE,
  SET_ANNOUNCEMENT_SIZE_PER_PAGE,
  SET_CALENDAR_PAGE,
  SET_CALENDAR_SIZE_PER_PAGE,
  SET_VISIBLE_APP_UPDATE,
  SET_VISIBLE_UPDATE,
} from "./actionTypes";

const initialState = {
  pageData: null,
  loading: false,
  currentTabId: 0,
  totalSize: 10,
  search: "",
  sizePerPage: 2,
  page: 1,
  totalSizeAnnouncement: 10,
  announcementSizePerPage: 2,
  announcementPage: 1,
  announcement: [],
  loadingAnnouncement: false,
  calenderItem: [],
  loadingCalenderItem: false,
  BuildingBlockListing: [],
  MBElements: [],
  duplicateLoading: false,
  saveLoading: false,
  SeriesElements: [],
  resourcesElements: [],
  resourcesLoading: false,
};

const CurriculumPostSeriesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_EDIT_HUB_ANNOUNCEMENT:
      return {
        ...state,
        loading: true,
      };
    case ADD_EDIT_HUB_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_EDIT_HUB_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case LIST_ANNOUNCEMENT:
      return {
        ...state,
        loadingAnnouncement: true,
      };
    case LIST_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        loadingAnnouncement: false,
        announcement: payload,
      };
    case LIST_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        loadingAnnouncement: false,
      };
    case LIST_CALENDAR_ITEMS:
      return {
        ...state,
        loadingCalenderItem: true,
      };
    case LIST_CALENDAR_ITEMS_SUCCESS:
      return {
        ...state,
        loadingCalenderItem: false,
        calenderItem: payload,
      };
    case LIST_CALENDAR_ITEMS_ERROR:
      return {
        ...state,
        loadingCalenderItem: false,
      };
    case DELETE_ANNOUNCEMENT:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case DELETE_CALENDAR_ITEMS:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CALENDAR_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_CALENDAR_ITEMS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ADD_EDIT_HUB_CALENDAR_ITEMS:
      return {
        ...state,
        loading: true,
      };
    case ADD_EDIT_HUB_CALENDAR_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_EDIT_HUB_CALENDAR_ITEMS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case SET_CALENDAR_PAGE:
      return {
        ...state,
        page: payload,
      };
    case SET_CALENDAR_SIZE_PER_PAGE:
      return {
        ...state,
        sizePerPage: payload,
      };
    case SET_ANNOUNCEMENT_PAGE:
      return {
        ...state,
        announcementPage: payload,
      };
    case SET_ANNOUNCEMENT_SIZE_PER_PAGE:
      return {
        ...state,
        announcementSizePerPage: payload,
      };
    /** MB building blocks list */
    case FETCH_BUILDING_BLOCKS_LISTING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_BUILDING_BLOCKS_LISTING_SUCCESS:
      return {
        ...state,
        BuildingBlockListing: payload,
        loading: false,
      };
    case FETCH_BUILDING_BLOCKS_LISTING_ERROR:
      return {
        ...state,
        loading: true,
      };
    /** MB ELEMENT list */
    case FETCH_MB_ELEMENTS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MB_ELEMENTS_SUCCESS:
      return {
        ...state,
        MBElements: action.payload,
        loading: false,
        totalSize: payload.length,
      };
    case FETCH_MB_ELEMENTS_ERROR:
      return {
        ...state,
        loading: false,
      };
    //MB ELEMENT DUPLICATE
    case MB_DUPLICATE_ELEMENT:
      return {
        ...state,
        duplicateLoading: true,
      };
    case MB_DUPLICATE_ELEMENT_SUCCESS:
      return {
        ...state,
        duplicateLoading: false,
      };
    case MB_DUPLICATE_ELEMENT_ERROR:
      return {
        ...state,
        duplicateLoading: true,
      };
    //MB delete element
    case MB_DELETE_ELEMENT:
      return {
        ...state,
        deleteLoading: true,
      };
    case MB_DELETE_ELEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MB_DELETE_ELEMENT_ERROR:
      return {
        ...state,
        deleteLoading: true,
      };
    //MB save on change
    case SAVE_ON_CHANGE:
      return {
        ...state,
        saveLoading: true,
      };
    case SAVE_ON_CHANGE_SUCCESS:
      return {
        ...state,
        saveLoading: false,
      };
    case SAVE_ON_CHANGE_ERROR:
      return {
        ...state,
        saveLoading: true,
      };
    case DELETE_ELEMENT_REDUX:
      return {
        ...state,
        //deleting element from redux
        MBElements: {
          ...state.MBElements,
          ...(payload.elementId && payload.isDeleteSeries === 0
            ? {
                sycu_build_elements_details:
                  state.MBElements.sycu_build_elements_details.filter(
                    item => item.build_elements_details_id !== payload.elementId
                  ),
              }
            : {}),
        },
      };
    case SET_VISIBLE_UPDATE:
      return {
        ...state,
        MBElements: {
          ...state.MBElements,
          ...state.MBElements.sycu_build_elements_details.filter(item =>
            item.build_elements_details_id === payload.id
              ? (item.is_visible = payload.is_visable)
              : ""
          ),
        },
      };

    /**  Message Builder Placehpolder loading **/

    case HANDLE_MB_INDEX_SUCCESS:
      return {
        ...state,
        MBElements: {
          ...state.MBElements,
          ...{
            sycu_build_elements_details: [
              ...state.MBElements.sycu_build_elements_details,
              payload,
            ],
          },
        },
      };

    case HANDLE_ELEMENT_INDEX_SUCCESS: {
      const updatedArray = [...state.MBElements.sycu_build_elements_details];
      const swapPositions = (array, x, y) => {
        var tmp = array[x];
        array[x] = array[y];
        array[y] = tmp;
        return array;
      };
      return {
        ...state,
        MBElements: {
          ...state.MBElements,
          sycu_build_elements_details: swapPositions(
            updatedArray,
            payload.destination.index,
            payload.source.index
          ),
        },
      };
    }
    /** Message Builder Video Element */
    case MESSAGE_VIDEO_ELEMENT:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_VIDEO_ELEMENT_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case MESSAGE_VIDEO_ELEMENT_ERROR:
      return {
        ...state,
        loading: true,
      };
    /** AppBuilderDetail list */
    case FETCH_APP_BUILDER_DETAIL_LISTING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_APP_BUILDER_DETAIL_LISTING_SUCCESS:
      return {
        ...state,
        SeriesElements: action.payload,
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_APP_BUILDER_DETAIL_LISTING_ERROR:
      return {
        ...state,
        loading: true,
      };
    case FETCH_RESOURCE_CURR_DETAIL:
      return {
        ...state,
        resourcesLoading: true,
      };
    case FETCH_RESOURCE_CURR_DETAIL_SUCCESS:
      return {
        ...state,
        resourcesElements: payload,
        resourcesLoading: false,
      };
    case FETCH_RESOURCE_CURR_DETAIL_ERROR:
      return {
        ...state,
        resourcesLoading: false,
      };
    // case UPDATE_RESOURCE_CURR_DETAIL:
    //   return {
    //     ...state,
    //     resourcesLoading: true,
    //   };
    // case UPDATE_RESOURCE_CURR_DETAIL_SUCCESS:
    //   return {
    //     ...state,
    //     resourcesElements: payload,
    //   };
    // case UPDATE_RESOURCE_CURR_DETAIL_ERROR:
    //   return {
    //     ...state,
    //     resourcesLoading: false,
    //   };
    //deleting element from redux
    case SET_AB_SECTION:
      return {
        ...state,
        //deleting element from redux
        SeriesElements: {
          ...state.SeriesElements,
          ...(payload.elementId && payload.isDeleteSeries === 1
            ? {
                sycu_build_elements_details:
                  state.SeriesElements.sycu_build_elements_details.filter(
                    item => item.build_elements_details_id !== payload.elementId
                  ),
              }
            : {}),
        },
      };
    //On/Off Slider App Elements
    case SET_VISIBLE_APP_UPDATE:
      return {
        ...state,
        SeriesElements: {
          ...state.SeriesElements,
          ...state.SeriesElements.sycu_build_elements_details.filter(item =>
            item.build_elements_details_id === payload.id
              ? (item.is_visible = payload.is_visable)
              : item
          ),
        },
      };
    default:
      return state;
  }
};

export default CurriculumPostSeriesReducer;
