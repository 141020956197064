// Mrinal : 23-11

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCT_FOLDERS = "FETCH_PRODUCT_FOLDERS";
export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_PRODUCT_ERROR = "FETCH_PRODUCT_ERROR";
export const FETCH_PRODUCT_FOLDER_SUCCESS = "FETCH_PRODUCT_FOLDER_SUCCESS";
export const FETCH_PRODUCT_FOLDER_ERROR = "FETCH_PRODUCT_FOLDER_ERROR";
export const ADD_EDIT_PRODUCT = "ADD_EDIT_PRODUCT";
export const ADD_EDIT_PRODUCT_SUCCESS = "ADD_EDIT_PRODUCT_SUCCESS";
export const ADD_EDIT_PRODUCT_ERROR = "ADD_EDIT_PRODUCT_ERROR";
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
export const GET_PRODUCT_DETAILS_ERROR = "GET_PRODUCT_DETAILS_ERROR";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_ERROR = "DELETE_PRODUCT_ERROR";
export const ADD_EDIT_PRODUCT_FOLDER = "ADD_EDIT_PRODUCT_FOLDER";
export const ADD_EDIT_PRODUCT_FOLDER_SUCCESS =
  "ADD_EDIT_PRODUCT_FOLDER_SUCCESS";
export const ADD_EDIT_PRODUCT_FOLDER_ERROR = "ADD_EDIT_PRODUCT_FOLDER_ERROR";
export const GET_PRODUCT_FOLDER_DETAILS = "GET_PRODUCT_FOLDER_DETAILS";
export const GET_PRODUCT_FOLDER_DETAILS_SUCCESS =
  "GET_PRODUCT_FOLDER_DETAILS_SUCCESS";
export const DELETE_PRODUCT_FOLDER = "DELETE_PRODUCT_FOLDER";
export const DELETE_PRODUCT_FOLDER_SUCCESS = "DELETE_PRODUCT_FOLDER_SUCCESS";
export const DELETE_PRODUCT_FOLDER_ERROR = "DELETE_PRODUCT_FOLDER_ERROR";

// Va : 28-12
export const FETCH_PRODUCT_SITES = "FETCH_PRODUCT_SITES";
export const FETCH_PRODUCT_SITES_SUCCESS = "FETCH_PRODUCT_SITES_SUCCESS";
export const FETCH_PRODUCT_SITES_ERROR = "FETCH_PRODUCT_SITES_ERROR";

export const SET_PRODUCT_PAGE = "SET_PRODUCT_PAGE";
export const SET_PRODUCT_SIZE_PER_PAGE = "SET_PRODUCT_SIZE_PER_PAGE";
export const SET_PRODUCT_SEARCH = "SET_PRODUCT_SEARCH";
export const SET_PRODUCT_SORT_FIELD = "SET_PRODUCT_SORT_FIELD";
export const SET_PRODUCT_SORT_ORDER = "SET_PRODUCT_SORT_ORDER";
export const CLEAR_PRODUCT_FEATURES = "CLEAR_PRODUCT_FEATURES";

export const FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT =
  "FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT";
export const FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT_SUCCESS =
  "FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT_SUCCESS";
export const FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT_ERROR =
  "FETCH_LIST_ALL_PRODUCT_VOLUMES_WITH_COUNT_ERROR";
export const SET_PRODUCT_FILTER_CATEGORY_ID = "SET_PRODUCT_FILTER_CATEGORY_ID";
