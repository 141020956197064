import { FETCH_LIST_CHURCH, FETCH_LIST_CHURCH_DATA } from "./actionType";

export const fetchChurshes = data => {
  return {
    type: FETCH_LIST_CHURCH,
    payload: data,
  };
};
export const fetchListChurshes = data => {
  return {
    type: FETCH_LIST_CHURCH_DATA,
    payload: data,
  };
};
