// Va 06 - 01 - 21

import {
  CLEAR_ACTIVE_SUBSCRIPTIONS,
  FETCH_ACTIVE_SUBSCRIPTIONS,
  FETCH_ACTIVE_SUBSCRIPTIONS_ERROR,
  FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS,
} from "./actionTypes";

const initialState = {
  loading: false,
  activeSubscriptions: [],
  totalSize: 0,
  sizePerPage: 25,
};

const activeSubscriptionsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ACTIVE_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ACTIVE_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        activeSubscriptions: [
          ...state.activeSubscriptions,
          ...payload.subscription_list,
        ],
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_ACTIVE_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ACTIVE_SUBSCRIPTIONS:
      return {
        ...state,
        activeSubscriptions: [],
      };

    default:
      return state;
  }
};

export default activeSubscriptionsReducer;
