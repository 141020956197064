import { axiosApiAdmin } from "helpers/api_helper";
import { CANCEL_SUBSCRIPTION_REPORT } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  fetchCancelSubscription,
  fetchCancelSubscriptionError,
} from "./actions";
import { FETCH_CANCEL_SUBSCRIPTION_LOADING } from "./actionTypes";

const fetchCancelSubscriptions = data =>
  axiosApiAdmin.post(CANCEL_SUBSCRIPTION_REPORT, data);

function* handleFetchCancelSubscription({ payload }) {
  try {
    const res = yield call(fetchCancelSubscriptions, payload);
    if (!!res) {
      yield put(fetchCancelSubscription(res.data.data));
    }
  } catch (err) {
    yield put(fetchCancelSubscriptionError());
  }
}

export default function* CancelSubscriptionSaga() {
  yield takeEvery(
    FETCH_CANCEL_SUBSCRIPTION_LOADING,
    handleFetchCancelSubscription
  );
}
