import {
  LIST_SIGNUP_METRIC_REPORT_ERROR,
  LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA,
  LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA_ERROR,
  LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA_SUCCESS,
  LIST_SIGNUP_METRIC_REPORT_LOADING,
  LIST_SIGNUP_METRIC_REPORT_SUCCESS,
} from "./actionTypes";

export const getMatrixDataLoading = data => ({
  type: LIST_SIGNUP_METRIC_REPORT_LOADING,
  payload: data,
});

export const getMatrixDataSuccess = data => ({
  type: LIST_SIGNUP_METRIC_REPORT_SUCCESS,
  payload: data,
});

export const getMatrixDataError = data => ({
  type: LIST_SIGNUP_METRIC_REPORT_ERROR,
  payload: data,
});

export const getMatrixGraphData = data => ({
  type: LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA,
  payload: data,
});

export const getMatrixGraphDataSuccess = data => ({
  type: LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA_SUCCESS,
  payload: data,
});

export const getMatrixGraphDataError = data => ({
  type: LIST_SIGNUP_METRIC_REPORT_GRAPH_DATA_ERROR,
  payload: data,
});
