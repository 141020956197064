import { axiosApiAdmin } from "helpers/api_helper";
import { SUBSCRIPTION_FEEDBACK_REPORT } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  subscriptionFeedbackError,
  subscriptionFeedbackSuccess,
} from "./actions";
import { SUBSCRIPTION_FEEDBACK_LOADING } from "./actionTypes";

const fetchSubscriptionFeedbackReportApi = data =>
  axiosApiAdmin.post(SUBSCRIPTION_FEEDBACK_REPORT, data);

function* handleFetchSubscriptionReport({ payload }) {
  try {
    const res = yield call(fetchSubscriptionFeedbackReportApi, payload);
    if (res) {
      yield put(subscriptionFeedbackSuccess(res.data.data));
    }
  } catch (err) {
    yield put(subscriptionFeedbackError());
  }
}

export default function* SubscriptionFeedBackReportSaga() {
  yield takeEvery(SUBSCRIPTION_FEEDBACK_LOADING, handleFetchSubscriptionReport);
}
