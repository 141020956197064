import {
  DECREMENT_NEW_SLIDE_COUNTER,
  INCREMENT_NEW_SLIDE_COUNTER,
  RESET_SLIDR,
  SAVE_SLIDE_SHOW,
  SAVE_SLIDE_SHOW_ERROR,
  SAVE_SLIDE_SHOW_SUCCESS,
  SET_GLOBAL_COPY_FIELD,
  SET_GLOBAL_PASTE_FIELD,
  SET_LAST_PUBLISHED,
  SET_SLIDE_SHOW_ID,
  SET_SLIDR_ACTIVE_FIELD,
  SET_SLIDR_ACTIVE_SLIDE,
  SET_SLIDR_BACKGROUND_OR_IMAGE_VIDEO,
  SET_SLIDR_DOCUMENT_TITLE,
  SET_SLIDR_FIELD_VALUE,
  SET_SLIDR_SETTINGS_OPTIONS,
  SET_SLIDR_SLIDES,
  SLIDR_ACTION_REDO,
  SLIDR_ACTION_UNDO,
  SLIDR_ADD_IMAGE,
  SLIDR_ADD_SLIDE,
  SLIDR_ADD_SLIDE_BELOW_CURRENT_SLIDE,
  SLIDR_ADD_SLIDE_WITHOUT_TEXT,
  SLIDR_ADD_TEXT,
  SLIDR_COPY_SLIDE,
  SLIDR_DELETE_SLIDE,
  SLIDR_FIELD_COPY,
  SLIDR_FIELD_DELETE,
  SLIDR_SET_SHOW_VIDEO_PREVIEW,
  SLIDR_SLIDES_CHANGE,
} from "./actionTypes";

export const setSlideShowId = data => ({
  type: SET_SLIDE_SHOW_ID,
  payload: data,
});
export const setSlidrDocumentTitle = data => ({
  type: SET_SLIDR_DOCUMENT_TITLE,
  payload: data,
});

export const setActiveSlide = data => ({
  type: SET_SLIDR_ACTIVE_SLIDE,
  payload: data,
});

export const addText = () => ({
  type: SLIDR_ADD_TEXT,
});

export const addSlide = data => ({
  type: SLIDR_ADD_SLIDE,
  payload: data,
});

export const addSlideWithoutText = data => ({
  type: SLIDR_ADD_SLIDE_WITHOUT_TEXT,
  payload: data,
});

export const copySlide = data => ({
  type: SLIDR_COPY_SLIDE,
  payload: data,
});
export const deleteSlide = data => ({
  type: SLIDR_DELETE_SLIDE,
  payload: data,
});
export const setSlides = data => ({
  type: SET_SLIDR_SLIDES,
  payload: data,
});
export const setActiveField = data => ({
  type: SET_SLIDR_ACTIVE_FIELD,
  payload: data,
});

export const setFieldValue = data => ({
  type: SET_SLIDR_FIELD_VALUE,
  payload: data,
});
export const fieldDelete = () => ({
  type: SLIDR_FIELD_DELETE,
});
export const fieldCopy = () => ({
  type: SLIDR_FIELD_COPY,
});
export const addImage = data => ({
  type: SLIDR_ADD_IMAGE,
  payload: data,
});
export const setSlidrBackgroundOrVideo = data => ({
  type: SET_SLIDR_BACKGROUND_OR_IMAGE_VIDEO,
  payload: data,
});
export const setShowVideoPreview = data => ({
  type: SLIDR_SET_SHOW_VIDEO_PREVIEW,
  payload: data,
});
export const setSettingsOptions = data => ({
  type: SET_SLIDR_SETTINGS_OPTIONS,
  payload: data,
});
export const setGlobalCopyField = data => ({
  type: SET_GLOBAL_COPY_FIELD,
  payload: data,
});
export const setGlobalPasteField = () => ({
  type: SET_GLOBAL_PASTE_FIELD,
});

export const slidesChange = data => ({
  type: SLIDR_SLIDES_CHANGE,
  payload: data,
});
export const actionUndo = () => ({
  type: SLIDR_ACTION_UNDO,
});
export const actionRedo = () => ({
  type: SLIDR_ACTION_REDO,
});
export const resetSlidr = () => ({
  type: RESET_SLIDR,
});
export const saveSlideshow = data => ({
  type: SAVE_SLIDE_SHOW,
  payload: data,
});
export const saveSlideshowSuccess = () => ({
  type: SAVE_SLIDE_SHOW_SUCCESS,
});
export const saveSlideshowError = () => ({
  type: SAVE_SLIDE_SHOW_ERROR,
});
export const incrementNewSlideCounter = () => ({
  type: INCREMENT_NEW_SLIDE_COUNTER,
});
export const decrementNewSlideCounter = () => ({
  type: DECREMENT_NEW_SLIDE_COUNTER,
});
export const setLastPublished = data => ({
  type: SET_LAST_PUBLISHED,
  payload: data,
});
export const addSlideBelowSlide = data => ({
  type: SLIDR_ADD_SLIDE_BELOW_CURRENT_SLIDE,
  payload: data,
});
