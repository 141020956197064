//list subsctiption report
export const FATCH_LIST_EXPIRED_SUBSCRIPTION =
  "FATCH_LIST_EXPIRED_SUBSCRIPTION";
export const FATCH_LIST_EXPIRED_SUBSCRIPTION_SUCCESS =
  "FATCH_LIST_EXPIRED_SUBSCRIPTION_SUCCESS";
export const FATCH_LIST_EXPIRED_SUBSCRIPTION_ERROR =
  "FATCH_LIST_EXPIRED_SUBSCRIPTION_ERROR";

export const CLEAR_EXPIRED_SUBSCRIPTION = "CLEAR_EXPIRED_SUBSCRIPTION";

//TABLE SUBSCRIPTION REPORT
export const SET_PAGE_EXPIRED_SUBSCRIPTION = "SET_PAGE_EXPIRED_SUBSCRIPTION";
export const SET_SIZE_PER_PAGE_EXPIRED_SUBSCRIPTION =
  "SET_SIZE_PER_PAGE_EXPIRED_SUBSCRIPTION ";
export const SET_SEARCH_EXPIRED_SUBSCRIPTION =
  "SET_SEARCH_EXPIRED_SUBSCRIPTION ";
export const SET_SORT_FIELD_EXPIRED_SUBSCRIPTION =
  "SET_SORT_FIELD_EXPIRED_SUBSCRIPTION";
export const SET_SORT_ORDER_EXPIRED_SUBSCRIPTION =
  "SET_SORT_ORDER_EXPIRED_SUBSCRIPTION";

export const FATCH_LIST_EXPIRED_SUBSCRIPTION_EXPORT =
  "FATCH_LIST_EXPIRED_SUBSCRIPTION_EXPORT";
export const FATCH_LIST_EXPIRED_SUBSCRIPTION_EXPORT_SUCCESS =
  "FATCH_LIST_SEXPIRED_SUBSCRIPTION_EXPORT_SUCCESS";
export const FATCH_LIST_EXPIRED_SUBSCRIPTION_EXPORT_NULL =
  "FATCH_LIST_EXPIRED_SUBSCRIPTION_EXPORT_NULL";

export const SET_ORDER_RANGE_DATE_EXPIRED_SUBSCRIPTION =
  "SET_ORDER_RANGE_DATE_EXPIRED_SUBSCRIPTION";
