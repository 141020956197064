import { axiosApiAdmin } from "helpers/api_helper";
import { GET_ADMIN_ACTIVITY_LOGS } from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { setErrorMessage } from "store/actions";
import { fetchLogsError, fetchLogsSuccess } from "./actions";
import { FETCH_LOGS } from "./actionTypes";

const fetchLogs = data => axiosApiAdmin.post(GET_ADMIN_ACTIVITY_LOGS, data);

function* handleAdminActivityLogs() {
  try {
    const { search, page_record, page_no, sortField, sortOrder, filter } =
      yield select(state => state.Log);
    let pageNo = search.length > 0 ? 1 : page_no;
    const data = {
      search,
      sortField,
      sortOrder,
      filter,
      pageNo,
      page_record,
    };

    const res = yield call(fetchLogs, data);
    if (res.status) {
      yield put(fetchLogsSuccess(res.data.data));
    }
  } catch (err) {
    yield put(fetchLogsError());
    yield put(setErrorMessage(err.response.data.message || err.message));
  }
}

function* logSaga() {
  yield takeEvery(FETCH_LOGS, handleAdminActivityLogs);
}

export default logSaga;
