// Va 06 - 01 - 21

import {
  CLEAR_ACTIVE_MEMBERSHIPS,
  FETCH_ACTIVE_MEMBERSHIPS,
  FETCH_ACTIVE_MEMBERSHIPS_ERROR,
  FETCH_ACTIVE_MEMBERSHIPS_SUCCESS,
  FETCH_DELETE_USERID,
} from "./actionTypes";

const initialState = {
  loading: false,
  activeMemberships: [],
  totalSize: 0,
  sizePerPage: 25,
};

const activeMembershipReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ACTIVE_MEMBERSHIPS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ACTIVE_MEMBERSHIPS_SUCCESS:
      return {
        ...state,
        activeMemberships: [...state.activeMemberships, ...payload.rows],
        loading: false,
        totalSize: payload.count,
      };
    case FETCH_ACTIVE_MEMBERSHIPS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_ACTIVE_MEMBERSHIPS:
      return {
        ...state,
        activeMemberships: [],
      };

    case FETCH_DELETE_USERID:
      return {
        ...state,
        activeMemberships: state.activeMemberships.filter(
          x => x.user_membership_id !== payload
        ),
      };

    default:
      return state;
  }
};

export default activeMembershipReducer;
