import {
  ADD_EDIT_USER,
  ADD_EDIT_USER_ERROR,
  ADD_EDIT_USER_SUCCESS,
  CHANGE_USER_ROLE,
  CLEAR_USER_FEATURES,
  DELETE_USERS,
  DELETE_USERS_ERROR,
  DELETE_USERS_SUCCESS,
  FETCH_RESET_USER,
  FETCH_TOTAL_USERS,
  FETCH_TOTAl_USERS_SUCCESS,
  FETCH_USERS,
  FETCH_USERS_ERROR,
  FETCH_USERS_SUCCESS,
  FETCH_USER_BY_ID,
  FETCH_USER_BY_ID_ERROR,
  FETCH_USER_BY_ID_SUCCESS,
  FILTER_BY_APPLICATIONS,
  SET_IS_CUSTOMER,
  SET_USER,
  SET_USER_PAGE,
  SET_USER_SEARCH,
  SET_USER_SIZE_PER_PAGE,
  SET_USER_SORT_FIELD,
  SET_USER_SORT_ORDER,
} from "./actionTypes";

export const setUserPage = data => ({
  type: SET_USER_PAGE,
  payload: data,
});

export const setUserSizePerPage = data => ({
  type: SET_USER_SIZE_PER_PAGE,
  payload: data,
});

export const setUserSearch = data => ({
  type: SET_USER_SEARCH,
  payload: data,
});

export const setUserSortField = data => ({
  type: SET_USER_SORT_FIELD,
  payload: data,
});

export const setUserSortOrder = data => ({
  type: SET_USER_SORT_ORDER,
  payload: data,
});

export const fetchUser = data => ({
  type: FETCH_USERS,
  payload: data,
});

export const fetchUserSuccess = data => ({
  type: FETCH_USERS_SUCCESS,
  payload: data,
});

export const fetchUserError = () => ({
  type: FETCH_USERS_ERROR,
});

export const fetchUserById = data => ({
  type: FETCH_USER_BY_ID,
  payload: data,
});
export const fetchUserByIdSuccess = data => ({
  type: FETCH_USER_BY_ID_SUCCESS,
  payload: data,
});
export const fetchUserByIdError = () => ({
  type: FETCH_USER_BY_ID_ERROR,
});

export const addEditUser = data => ({
  type: ADD_EDIT_USER,
  payload: data,
});

export const setUser = data => ({
  type: SET_USER,
  payload: data,
});

export const addEditUserSuccess = data => ({
  type: ADD_EDIT_USER_SUCCESS,
  payload: data,
});

export const addEditUserError = () => ({
  type: ADD_EDIT_USER_ERROR,
});

export const deleteUsers = data => ({
  type: DELETE_USERS,
  payload: data,
});

export const deleteUsersSuccess = data => ({
  type: DELETE_USERS_SUCCESS,
  payload: data,
});
export const deleteUsersError = data => ({
  type: DELETE_USERS_ERROR,
  payload: data,
});

export const fetchResetUser = data => ({
  type: FETCH_RESET_USER,
  payload: data,
});

export const fetchTotalUsers = () => ({
  type: FETCH_TOTAL_USERS,
});

export const fetchTotalUsersSuccess = data => ({
  type: FETCH_TOTAl_USERS_SUCCESS,
  payload: data,
});

export const changeUserRole = data => ({
  type: CHANGE_USER_ROLE,
  payload: data,
});

export const setISCustomer = data => ({
  type: SET_IS_CUSTOMER,
  payload: data,
});

export const setFilterByApplications = site_id => ({
  type: FILTER_BY_APPLICATIONS,
  payload: site_id,
});

export const clearUserFeatures = data => ({
  type: CLEAR_USER_FEATURES,
  payload: data,
});
