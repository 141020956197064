// Va 06 - 01 - 21

// GET ACTIVE MEMBERSHIPS
export const FETCH_ACTIVE_MEMBERSHIPS = "FETCH_ACTIVE_MEMBERSHIPS";
export const FETCH_ACTIVE_MEMBERSHIPS_SUCCESS =
  "FETCH_ACTIVE_MEMBERSHIPS_SUCCESS";
export const FETCH_ACTIVE_MEMBERSHIPS_ERROR = "FETCH_ACTIVE_MEMBERSHIPS_ERROR";
export const CLEAR_ACTIVE_MEMBERSHIPS = "CLEAR_ACTIVE_MEMBERSHIPS";

export const FETCH_DELETE_USERID = "FETCH_DELETE_USERID";
