export const FETCH_MEMBERSHIP = "FETCH_MEMBERSHIP";
export const FETCH_MEMBERSHIP_SUCCESS = "FETCH_MEMBERSHIP_SUCCESS";
export const FETCH_MEMBERSHIP_ERROR = "FETCH_MEMBERSHIP_ERROR";

export const FETCH_MEMBERSHIP_PRODUCT = "FETCH_MEMBERSHIP_PRODUCT";
export const FETCH_MEMBERSHIP_PRODUCT_SUCCESS =
  "FETCH_MEMBERSHIP_PRODUCT_SUCCESS";
export const FETCH_MEMBERSHIP_PRODUCT_ERROR = "FETCH_MEMBERSHIP_PRODUCT_ERROR";

export const FETCH_MEMBERSHIP_PAGES = "FETCH_MEMBERSHIP_PAGES";
export const FETCH_MEMBERSHIP_PAGES_SUCCESS = "FETCH_MEMBERSHIP_PAGES_SUCCESS";
export const FETCH_MEMBERSHIP_PAGES_ERROR = "FETCH_MEMBERSHIP_PAGES_ERROR";

export const SAVE_MEMBERSHIP = "SAVE_MEMBERSHIP";
export const SAVE_MEMBERSHIP_SUCCESS = "SAVE_MEMBERSHIP_SUCCESS";
export const SAVE_MEMBERSHIP_ERROR = "SAVE_MEMBERSHIP_ERROR";

export const DELETE_MEMBERSHIP = "DELETE_MEMBERSHIP";
export const DELETE_MEMBERSHIP_SUCCESS = "DELETE_MEMBERSHIP_SUCCESS";
export const DELETE_MEMBERSHIP_ERROR = "DELETE_MEMBERSHIP_ERROR";

export const SET_MEMBERSHIP_PAGE = "SET_MEMBERSHIP_PAGE";
export const SET_MEMBERSHIP_SIZE_PER_PAGE = "SET_MEMBERSHIP_SIZE_PER_PAGE";
export const SET_MEMBERSHIP_SEARCH = "SET_MEMBERSHIP_SEARCH";
export const SET_MEMBERSHIP_SORT_FIELD = "SET_MEMBERSHIP_SORT_FIELD";
export const SET_MEMBERSHIP_SORT_ORDER = "SET_MEMBERSHIP_SORT_ORDER";
export const CLEAR_MEMBERSHIP_FEATURES = "CLEAR_MEMBERSHIP_FEATURES";
