import {
  ADD_NOTE,
  ADD_NOTE_ERROR,
  ADD_NOTE_SUCCESS,
  DELETE_NOTE,
  DELETE_NOTE_ERROR,
  DELETE_NOTE_SUCCESS,
  FETCH_CAMPAINGN,
  FETCH_CAMPAINGN_ERROR,
  FETCH_CAMPAINGN_SUCCESS,
  FETCH_NOTE,
  FETCH_NOTE_ERROR,
  FETCH_NOTE_SUCCESS,
} from "./actionTypes";

// submit note
export const addNote = data => {
  return {
    type: ADD_NOTE,
    payload: data,
  };
};

export const addNoteSuccess = data => ({
  type: ADD_NOTE_SUCCESS,
  payload: data,
});

export const addNoteError = () => ({
  type: ADD_NOTE_ERROR,
});

// fetching note
export const fetchNote = data => {
  return {
    type: FETCH_NOTE,
    payload: data,
  };
};

export const fetchCampaign = data => {
  return {
    type: FETCH_CAMPAINGN,
    payload: data,
  };
};

export const fetchCampaignSuccess = data => {
  return {
    type: FETCH_CAMPAINGN_SUCCESS,
    payload: data,
  };
};

export const fetchCampaignError = data => {
  return {
    type: FETCH_CAMPAINGN_ERROR,
    payload: data,
  };
};

export const fetchNoteSuccess = data => {
  return {
    type: FETCH_NOTE_SUCCESS,
    payload: data,
  };
};

export const fetchNoteError = data => {
  return {
    type: FETCH_NOTE_ERROR,
    payload: data,
  };
};

// deleting note
export const deleteNotes = data => ({
  type: DELETE_NOTE,
  payload: data,
});

export const deleteNoteSuccess = data => ({
  type: DELETE_NOTE_SUCCESS,
  payload: data,
});

export const deleteNoteError = () => ({
  type: DELETE_NOTE_ERROR,
});
