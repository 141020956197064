export const FETCH_PERMISSION_PROFILES = "FETCH_PERMISSION_PROFILES";
export const FETCH_PERMISSION_PROFILES_SUCCESS =
  "FETCH_PERMISSION_PROFILES_SUCCESS";
export const FETCH_PERMISSION_PROFILES_ERROR =
  "FETCH_PERMISSION_PROFILES_ERROR";

export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS";
export const FETCH_PERMISSIONS_SUCCESS = "FETCH_PERMISSIONS_SUCCESS";
export const FETCH_PERMISSIONS_ERROR = "FETCH_PERMISSIONS_ERROR";

export const DELETE_PERMISSION_PROFILE = "DELETE_PERMISSION_PROFILE";
export const DELETE_PERMISSION_PROFILE_SUCCESS =
  "DELETE_PERMISSION_PROFILE_SUCCESS";
export const DELETE_PERMISSION_PROFILE_ERROR =
  "DELETE_PERMISSION_PROFILE_ERROR";
