import {
  CLEAR_MEMBERSHIP_FEATURES,
  DELETE_MEMBERSHIP,
  DELETE_MEMBERSHIP_ERROR,
  DELETE_MEMBERSHIP_SUCCESS,
  FETCH_MEMBERSHIP,
  FETCH_MEMBERSHIP_ERROR,
  FETCH_MEMBERSHIP_PAGES,
  FETCH_MEMBERSHIP_PAGES_ERROR,
  FETCH_MEMBERSHIP_PAGES_SUCCESS,
  FETCH_MEMBERSHIP_PRODUCT,
  FETCH_MEMBERSHIP_PRODUCT_ERROR,
  FETCH_MEMBERSHIP_PRODUCT_SUCCESS,
  FETCH_MEMBERSHIP_SUCCESS,
  SAVE_MEMBERSHIP,
  SAVE_MEMBERSHIP_ERROR,
  SAVE_MEMBERSHIP_SUCCESS,
  SET_MEMBERSHIP_PAGE,
  SET_MEMBERSHIP_SEARCH,
  SET_MEMBERSHIP_SIZE_PER_PAGE,
  SET_MEMBERSHIP_SORT_FIELD,
  SET_MEMBERSHIP_SORT_ORDER,
} from "./actionTypes";

export const fetchMembership = membership => ({
  type: FETCH_MEMBERSHIP,
  payload: membership,
});
export const fetchMembershipSuccess = membership => ({
  type: FETCH_MEMBERSHIP_SUCCESS,
  payload: membership,
});
export const fetchMembershipError = () => ({
  type: FETCH_MEMBERSHIP_ERROR,
});

export const fetchMembershipProduct = product => ({
  type: FETCH_MEMBERSHIP_PRODUCT,
  payload: product,
});
export const fetchMembershipProductSuccess = product => ({
  type: FETCH_MEMBERSHIP_PRODUCT_SUCCESS,
  payload: product,
});
export const fetchMembershipProductError = product => ({
  type: FETCH_MEMBERSHIP_PRODUCT_ERROR,
  payload: product,
});

export const fetchMembershipPages = page => ({
  type: FETCH_MEMBERSHIP_PAGES,
  payload: page,
});
export const fetchMembershipPagesSuccess = pages => ({
  type: FETCH_MEMBERSHIP_PAGES_SUCCESS,
  payload: pages,
});
export const fetchMembershipPagesError = () => ({
  type: FETCH_MEMBERSHIP_PAGES_ERROR,
});

export const saveMembership = membership => ({
  type: SAVE_MEMBERSHIP,
  payload: membership,
});
export const saveMembershipSuccess = membership => ({
  type: SAVE_MEMBERSHIP_SUCCESS,
  payload: membership,
});
export const saveMembershipError = membership => ({
  type: SAVE_MEMBERSHIP_ERROR,
});

export const deleteMembership = membership => ({
  type: DELETE_MEMBERSHIP,
  payload: membership,
});
export const deleteMembershipSuccess = membership => ({
  type: DELETE_MEMBERSHIP_SUCCESS,
  payload: membership,
});
export const deleteMembershipError = () => ({
  type: DELETE_MEMBERSHIP_ERROR,
});

export const setMembershipPage = data => ({
  type: SET_MEMBERSHIP_PAGE,
  payload: data,
});
export const setMembershipSizePerPage = data => ({
  type: SET_MEMBERSHIP_SIZE_PER_PAGE,
  payload: data,
});
export const setMembershipSearch = data => ({
  type: SET_MEMBERSHIP_SEARCH,
  payload: data,
});
export const setMembershipSortField = data => ({
  type: SET_MEMBERSHIP_SORT_FIELD,
  payload: data,
});
export const setMembershipSortOrder = data => ({
  type: SET_MEMBERSHIP_SORT_ORDER,
  payload: data,
});
export const clearMembershipFeatures = () => ({
  type: CLEAR_MEMBERSHIP_FEATURES,
});
