export const volumeIds = {
  MUSIC: +process.env.REACT_APP_VOLUME_MUSIC_ID,
  "3_MONTH_PACK": +process.env.REACT_APP_VOLUME_3_MONTH_PACK_ID,
  FREE_TRIAL: +process.env.REACT_APP_VOLUME_FREE_TRIAL_ID,
  FREE_VBS: +process.env.REACT_APP_VOLUME_FREE_VBS_ID,
};

export const ministries = {
  1: "kids",
  2: "students",
  3: "group",
  4: "vbs",
};

export const volume9Id = process.env.REACT_APP_ENV === "dev" ? 475 : 376;
export const volume8Id = process.env.REACT_APP_ENV === "dev" ? 440 : 341;
export const volume7Id = process.env.REACT_APP_ENV === "dev" ? 401 : 256;
export const musicCategoryId = process.env.REACT_APP_ENV === "dev" ? 183 : 188;
export const vbsPageId = process.env.REACT_APP_ENV === "dev" ? 32 : 62;
export const threeMonthsPack = process.env.REACT_APP_ENV === "dev" ? 0 : 1;
