export const FETCH_NEW_REGISTERED_USER_CHART =
  "FETCH_NEW_REGISTERED_USER_CHART";
export const FETCH_NEW_REGISTERED_USER_CHART_SUCCESS =
  "FETCH_NEW_REGISTERED_USER_CHART_SUCCESS";
export const FETCH_NEW_REGISTERED_USER_CHART_ERROR =
  "FETCH_NEW_REGISTERED_USER_CHART_ERROR";

export const FETCH_CANCELLED_SUBSCRIPTION_CHART =
  "FETCH_CANCELLED_SUBSCRIPTION_CHART";
export const FETCH_CANCELLED_SUBSCRIPTION_CHART_SUCCESS =
  "FETCH_CANCELLED_SUBSCRIPTION_CHART_SUCCESS";
export const FETCH_CANCELLED_SUBSCRIPTION_CHART_ERROR =
  "FETCH_CANCELLED_SUBSCRIPTION_CHART_ERROR";

export const FETCH_RENEWED_SUBSCRIPTION_CHART =
  "FETCH_RENEWED_SUBSCRIPTION_CHART";
export const FETCH_RENEWED_SUBSCRIPTION_CHART_SUCCESS =
  "FETCH_RENEWED_SUBSCRIPTION_CHART_SUCCESS";
export const FETCH_RENEWED_SUBSCRIPTION_CHART_ERROR =
  "FETCH_RENEWED_SUBSCRIPTION_CHART_ERROR";

export const FETCH_NEW_SUBSCRIPTION_CHART = "FETCH_NEW_SUBSCRIPTION_CHART";
export const FETCH_NEW_SUBSCRIPTION_CHART_SUCCESS =
  "FETCH_NEW_SUBSCRIPTION_CHART_SUCCESS";
export const FETCH_NEW_SUBSCRIPTION_CHART_ERROR =
  "FETCH_NEW_SUBSCRIPTION_CHART_ERROR";

export const FETCH_DASHBOARD_CARD_DATA = "FETCH_DASHBOARD_CARD_DATA";
export const FETCH_DASHBOARD_CARD_DATA_SUCCESS =
  "FETCH_DASHBOARD_CARD_DATA_SUCCESS";
export const FETCH_DASHBOARD_CARD_DATA_ERROR =
  "FETCH_DASHBOARD_CARD_DATA_ERROR";

export const FETCH_EARNINGS_CHART_DATA = "FETCH_EARNINGS_CHART_DATA";
export const FETCH_EARNINGS_CHART_DATA_SUCCESS =
  "FETCH_EARNINGS_CHART_DATA_SUCCESS";
export const FETCH_EARNINGS_CHART_DATA_ERROR =
  "FETCH_EARNINGS_CHART_DATA_ERROR";

export const FETCH_TOP_SELLING_PRODUCTS_CHART_DATA =
  "FETCH_TOP_SELLING_PRODUCTS_CHART_DATA";
export const FETCH_TOP_SELLING_PRODUCTS_CHART_DATA_SUCCESS =
  "FETCH_TOP_SELLING_PRODUCTS_CHART_DATA_SUCCESS";
export const FETCH_TOP_SELLING_PRODUCTS_CHART_DATA_ERROR =
  "FETCH_TOP_SELLING_PRODUCTS_CHART_DATA_ERROR";

export const FETCH_TOP_VISITORS_CHART_DATA = "FETCH_TOP_VISITORS_CHART_DATA";
export const FETCH_TOP_VISITORS_CHART_DATA_SUCCESS =
  "FETCH_TOP_VISITORS_CHART_DATA_SUCCESS";
export const FETCH_TOP_VISITORS_CHART_DATA_ERROR =
  "FETCH_TOP_VISITORS_CHART_DATA_ERROR";

export const FETCH_SALES_ANALYTICS_CHART_DATA =
  "FETCH_TOP_CUSTOMERS_CHART_DATA";
export const FETCH_SALES_ANALYTICS_CHART_DATA_SUCCESS =
  "FETCH_TOP_CUSTOMERS_CHART_DATA_SUCCESS";
export const FETCH_SALES_ANALYTICS_CHART_DATA_ERROR =
  "FETCH_TOP_CUSTOMERS_CHART_DATA_ERROR";

export const FETCH_ORDER_BY_PRODUCT_CHART_DATA =
  "FETCH_ORDER_BY_PRODUCT_CHART_DATA";
export const FETCH_ORDER_BY_PRODUCT_CHART_DATA_SUCCESS =
  "FETCH_ORDER_BY_PRODUCT_CHART_DATA_SUCCESS";
export const FETCH_ORDER_BY_PRODUCT_CHART_DATA_ERROR =
  "FETCH_ORDER_BY_PRODUCT_CHART_DATA_ERROR";

export const COMPONENT_LOADER_DASHBOARD = "COMPONENT_LOADER_DASHBOARD";

export const LIST_ALL_SYSTEM_CONFIGURATION = "LIST_ALL_SYSTEM_CONFIGURATION";
export const LIST_ALL_SYSTEM_CONFIGURATION_SUCCESS =
  "LIST_ALL_SYSTEM_CONFIGURATION_SUCCESS";
export const LIST_ALL_SYSTEM_CONFIGURATION_ERROR =
  "LIST_ALL_SYSTEM_CONFIGURATION_ERROR";
