// Va 06 - 01 - 21

import {
  CLEAR_ACTIVE_MEMBERSHIPS,
  FETCH_ACTIVE_MEMBERSHIPS,
  FETCH_ACTIVE_MEMBERSHIPS_ERROR,
  FETCH_ACTIVE_MEMBERSHIPS_SUCCESS,
  FETCH_DELETE_USERID,
} from "./actionTypes";

// fetching active membership
export const fetchActiveMembership = data => {
  return {
    type: FETCH_ACTIVE_MEMBERSHIPS,
    payload: data,
  };
};

export const fetchActiveMembershipSuccess = data => {
  return {
    type: FETCH_ACTIVE_MEMBERSHIPS_SUCCESS,
    payload: data,
  };
};

export const fetchActiveMembershipError = data => {
  return {
    type: FETCH_ACTIVE_MEMBERSHIPS_ERROR,
    payload: data,
  };
};
export const clearActiveMemberShips = () => ({
  type: CLEAR_ACTIVE_MEMBERSHIPS,
});

export const fetchDeleteUserId = data => ({
  type: FETCH_DELETE_USERID,
  payload: data,
});
