export const FETCH_SYSTEM_PAGES = "FETCH_SYSTEM_PAGES";
export const FETCH_SYSTEM_PAGES_SUCCESS = "FETCH_SYSTEM_PAGES_SUCCESS";
export const FETCH_SYSTEM_PAGES_ERROR = "FETCH_SYSTEM_PAGES_ERROR";

export const FETCH_AMAZON_PAGES = "FETCH_AMAZON_PAGES";
export const FETCH_AMAZON_PAGES_SUCCESS = "FETCH_AMAZON_PAGES_SUCCESS";
export const FETCH_AMAZON_PAGES_ERROR = "FETCH_AMAZON_PAGES_ERROR";

export const SET_AMAZON_PAGES_SEARCH = "SET_AMAZON_PAGES_SEARCH";
export const SET_AMAZON_PAGES_PAGE = "SET_AMAZON_PAGES_PAGE";
export const SET_AMAZON_PAGES_SIZE_PER_PAGE = "SET_AMAZON_PAGES_SIZE_PER_PAGE";
export const CLEAR_AMAZON_PAGES_FEATURES = "CLEAR_AMAZON_PAGES_FEATURES";

export const EDIT_SYSTEM_PAGE = "EDIT_SYSTEM_PAGE";
export const EDIT_SYSTEM_PAGE_SUCCESS = "EDIT_SYSTEM_PAGE_SUCCESS";
export const EDIT_SYSTEM_PAGE_ERROR = "EDIT_SYSTEM_PAGE_ERROR";

export const SYSTEM_PAGE_DELETE = "SYSTEM_PAGE_DELETE";
export const SYSTEM_PAGE_DELETE_SUCCESS = "SYSTEM_PAGE_DELETE_SUCCESS";
export const SYSTEM_PAGE_DELETE_ERROR = "SYSTEM_PAGE_DELETE_ERROR";
