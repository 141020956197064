import { axiosApiAdmin } from "helpers/api_helper";
import {
  GET_REGISTERED_USERS,
  GET_RENEWALS_REPORTS_DATA,
  LIST_PRODUCT_REVENUE_REPORT_DATA_URL,
  LIST_REVENUE_REPORT_DATA_URL,
} from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  getProductRevenueReportDataError,
  getProductRevenueReportDataSuccess,
  getRenewalDataError,
  getRenewalDataSuccess,
  getRevenueReportDataError,
  getRevenueReportDataSuccess,
  listRegisteredUsersError,
  listRegisteredUsersSuccess,
  setErrorMessage,
} from "store/actions";
import {
  GET_PRODUCT_REVENUE_REPORT_DATA,
  GET_RENEWAL_DATA,
  GET_REVENUE_REPORT_DATA,
  LIST_REGISTERED_USERS,
} from "./actionTypes";

const fetchRevenueReportData = data =>
  axiosApiAdmin.post(LIST_REVENUE_REPORT_DATA_URL, data);
const fetchProductRevenueReportData = data =>
  axiosApiAdmin.post(LIST_PRODUCT_REVENUE_REPORT_DATA_URL, data);
const fetchRenewalData = payload =>
  axiosApiAdmin.post(GET_RENEWALS_REPORTS_DATA, payload);
const fetchRegisteredUsers = payload =>
  axiosApiAdmin.post(GET_REGISTERED_USERS, payload);

//Registered Users

function* handleRegisteredUsers({ payload }) {
  try {
    const res = yield call(fetchRegisteredUsers, payload);
    if (res?.status === 200 && res?.data?.data) {
      yield put(listRegisteredUsersSuccess(res.data.data));
    }
  } catch (err) {
    yield put(listRegisteredUsersError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

//Renewal Report

function* handleRenewalData({ payload }) {
  try {
    const res = yield call(fetchRenewalData, payload);
    if (res?.status === 200 && res?.data?.data) {
      yield put(getRenewalDataSuccess(res.data.data));
    }
  } catch (err) {
    yield put(getRenewalDataError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

//Revenue Report Data
function* handleListRevenueReportData({ payload }) {
  try {
    const res = yield call(fetchRevenueReportData, payload);
    if (res?.data?.statusCode === 200 && res?.data?.data)
      yield put(
        getRevenueReportDataSuccess({
          rows: res.data.data.rows.map((item, index) => ({
            ...item,
            id: index,
          })),
          count: res.data.data.count,
        })
      );
  } catch (err) {
    yield put(getRevenueReportDataError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}

//PRODUCT Revenue Report Data
function* handleListProductRevenueReportData({ payload }) {
  try {
    const res = yield call(fetchProductRevenueReportData, payload);
    if (res?.data?.statusCode === 200 && res?.data?.data)
      yield put(getProductRevenueReportDataSuccess(res.data.data));
  } catch (err) {
    yield put(getProductRevenueReportDataError());
    yield put(setErrorMessage(err?.response?.data?.message || err.message));
  }
}
function* reportSaga() {
  yield takeEvery(GET_REVENUE_REPORT_DATA, handleListRevenueReportData);
  yield takeEvery(
    GET_PRODUCT_REVENUE_REPORT_DATA,
    handleListProductRevenueReportData
  );
  yield takeEvery(GET_RENEWAL_DATA, handleRenewalData);
  yield takeEvery(LIST_REGISTERED_USERS, handleRegisteredUsers);
}

export default reportSaga;
