export const GET_GEO_DATA_LOADING = "GET_GEO_DATA_LOADING";
export const GET_GEO_DATA_SUCCESS = "GET_GEO_DATA_SUCCESS";
export const GET_GEO_DATA_ERROR = "GET_GEO_DATA_ERROR";

export const FETCH_CONFIG_DATA_LOADING = "FETCH_CONFIG_DATA_LOADING";
export const FETCH_CONFIG_DATA_SUCCESS = "FETCH_CONFIG_DATA_SUCCESS";
export const FETCH_CONFIG_DATA_ERROR = "FETCH_CONFIG_DATA_ERROR";

export const SAVE_CONFIG_DATA_LOADING = "SAVE_CONFIG_DATA_LOADING";
export const SAVE_CONFIG_DATA_SUCCESS = "SAVE_CONFIG_DATA_SUCCESS";
export const SAVE_CONFIG_DATA_ERROR = "SAVE_CONFIG_DATA_ERROR";
