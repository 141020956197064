import {
  CLEAR_USER_DETAILS,
  COPY_RESET_PASSWORD_LINK,
  COPY_RESET_PASSWORD_LINK_ERROR,
  COPY_RESET_PASSWORD_LINK_SUCCESS,
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_ERROR,
  FETCH_USER_PROFILE_SUCCESS,
  RESET_PASSWORD_EMAIL,
  RESET_PASSWORD_EMAIL_ERROR,
  RESET_PASSWORD_EMAIL_SUCCESS,
  SET_ACCESS_ACCOUNT_DATA,
  SET_IS_CUSTOMER_ROUTE,
  SET_USER_ID,
  SET_USER_NAME,
  USER_APPLICATION_STUFF,
  USER_APPLICATION_STUFF_ERROR,
  USER_APPLICATION_STUFF_SUCCESS,
  USER_SHARED_ACTIVE_DEACTIVE,
  USER_SHARED_ACTIVE_DEACTIVE_ERROR,
  USER_SHARED_ACTIVE_DEACTIVE_SUCCESS,
} from "./actionTypes";

export const setUserName = data => ({
  type: SET_USER_NAME,
  payload: data,
});
export const setUserId = data => ({
  type: SET_USER_ID,
  payload: data,
});

export const fetchUserProfile = data => ({
  type: FETCH_USER_PROFILE,
  payload: data,
});
export const fetchUserProfileSuccess = data => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload: data,
});
export const fetchUserProfileError = () => ({
  type: FETCH_USER_PROFILE_ERROR,
});
export const resetPasswordEmail = data => ({
  type: RESET_PASSWORD_EMAIL,
  payload: data,
});

export const resetPasswordEmailSuccess = data => ({
  type: RESET_PASSWORD_EMAIL_SUCCESS,
  payload: data,
});
export const resetPasswordEmailError = () => ({
  type: RESET_PASSWORD_EMAIL_ERROR,
});

export const copyResetPasswordLink = data => ({
  type: COPY_RESET_PASSWORD_LINK,
  payload: data,
});
export const copyResetPasswordLinkSuccess = data => ({
  type: COPY_RESET_PASSWORD_LINK_SUCCESS,
  payload: data,
});
export const copyResetPasswordLinkError = () => ({
  type: COPY_RESET_PASSWORD_LINK_ERROR,
});

export const userApplicationStuff = data => ({
  type: USER_APPLICATION_STUFF,
  payload: data,
});
export const userApplicationStuffSuccess = data => ({
  type: USER_APPLICATION_STUFF_SUCCESS,
  payload: data,
});
export const userApplicationStuffError = () => ({
  type: USER_APPLICATION_STUFF_ERROR,
});

export const setAccessAccountData = data => ({
  type: SET_ACCESS_ACCOUNT_DATA,
  payload: data,
});

export const clearUserDetails = () => ({
  type: CLEAR_USER_DETAILS,
});

export const userSharedActiveDeactive = data => ({
  type: USER_SHARED_ACTIVE_DEACTIVE,
  payload: data,
});

export const userSharedActiveDeactiveSuccess = () => ({
  type: USER_SHARED_ACTIVE_DEACTIVE_SUCCESS,
});

export const userSharedActiveDeactiveError = () => ({
  type: USER_SHARED_ACTIVE_DEACTIVE_ERROR,
});

export const setIsCustomerRoute = data => ({
  type: SET_IS_CUSTOMER_ROUTE,
  payload: data,
});
