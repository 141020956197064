import {
  CLEAR_NOT_ACTIVE_SUBSCRIPTION,
  FETCH_NOT_ACTIVE_SUBSCRIPTION,
  FETCH_NOT_ACTIVE_SUBSCRIPTION_ERROR,
  FETCH_NOT_ACTIVE_SUBSCRIPTION_SUCCESS,
  //SET_PAGE_SUPPORT_TICKETS,
  FILTER_BY_APPLICATIONS,
  SET_ORDER_RANGE_DATE_NOT_ACTIVE_SUBSCRIPTION,
  SET_PAGE_NOT_ACTIVE_SUBSCRIPTION,
  SET_SEARCH_NOT_ACTIVE_SUBSCRIPTION,
  SET_SIZE_PER_PAGE_NOT_ACTIVE_SUBSCRIPTION,
  SET_SORT_FIELD_NOT_ACTIVE_SUBSCRIPTION,
  SET_SORT_ORDER_NOT_ACTIVE_SUBSCRIPTION,
} from "./actionType";

export const getNotActiveSubscription = () => ({
  type: FETCH_NOT_ACTIVE_SUBSCRIPTION,
});

export const getNotActiveSubscriptionSuccess = data => ({
  type: FETCH_NOT_ACTIVE_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const getNotActiveSubscriptionError = () => ({
  type: FETCH_NOT_ACTIVE_SUBSCRIPTION_ERROR,
});

export const clearNotActiveSubscriptions = () => ({
  type: CLEAR_NOT_ACTIVE_SUBSCRIPTION,
});

export const setPageNotActiveSubscriptions = data => {
  return {
    type: SET_PAGE_NOT_ACTIVE_SUBSCRIPTION,
    payload: data,
  };
};

export const setSizePerPageNotActiveSubscriptions = data => {
  return {
    type: SET_SIZE_PER_PAGE_NOT_ACTIVE_SUBSCRIPTION,
    payload: data,
  };
};

export const setSearchNotActiveSubscriptions = data => {
  return {
    type: SET_SEARCH_NOT_ACTIVE_SUBSCRIPTION,
    payload: data,
  };
};

export const setSortFieldNotActiveSubscriptions = data => ({
  type: SET_SORT_FIELD_NOT_ACTIVE_SUBSCRIPTION,
  payload: data,
});

export const setSortOrderNotActiveSubscriptions = data => ({
  type: SET_SORT_ORDER_NOT_ACTIVE_SUBSCRIPTION,
  payload: data,
});

export const setOrderRangeDateNotActiveSubscription = data => ({
  type: SET_ORDER_RANGE_DATE_NOT_ACTIVE_SUBSCRIPTION,
  payload: data,
});

export const setFilterByApplications = site_id => ({
  type: FILTER_BY_APPLICATIONS,
  payload: site_id,
});

// export const setPageSupportTickets = data => {
//   return {
//     type: SET_PAGE_SUPPORT_TICKETS,
//     payload: data,
//   };
// };
