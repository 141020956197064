// Mrinal : 23-11

import { axiosApiAdmin } from "helpers/api_helper";
import { LIST_CHURCH } from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { fetchProductError, setErrorMessage } from "store/actions";
import { fetchListChurshes } from "./action";
import { FETCH_LIST_CHURCH } from "./actionType";

const fetchListProducts = data => axiosApiAdmin.get(LIST_CHURCH);

function* handleFetchProducts({ payload }) {
  try {
    const res = yield call(fetchListProducts);
    if (res.data?.data) {
      yield put(fetchListChurshes(res.data.data));
    }
  } catch (err) {
    yield put(fetchProductError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* churchesSaga() {
  yield takeEvery(FETCH_LIST_CHURCH, handleFetchProducts);
}

export default churchesSaga;
