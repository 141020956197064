import {
  CLEAR_FAIL_SUBSCRIPTION,
  FETCH_DISPUTED_TRANSACTIONS,
  FETCH_DISPUTED_TRANSACTIONS_ERROR,
  FETCH_DISPUTED_TRANSACTIONS_SUCCESS,
  SET_ORDER_RANGE_DATE_DISPUTED_TRANSACTIONS,
  SET_PAGE_DISPUTED_TRANSACTIONS,
  SET_SEARCH_DISPUTED_TRANSACTIONS,
  SET_SIZE_PER_PAGE_DISPUTED_TRANSACTIONS,
  SET_SORT_FIELD_DISPUTED_TRANSACTIONS,
  SET_SORT_ORDER_DISPUTED_TRANSACTIONS,
  VERIFY_EVIDENCE,
} from "./actionType";

export const getDisputedTransactions = cb => ({
  type: FETCH_DISPUTED_TRANSACTIONS,
  cb: cb,
});

export const getDisputedTransactionsSuccess = data => ({
  type: FETCH_DISPUTED_TRANSACTIONS_SUCCESS,
  payload: data,
});

export const getDisputedTransactionsError = () => ({
  type: FETCH_DISPUTED_TRANSACTIONS_ERROR,
});

export const clearFailSubscriptions = () => ({
  type: CLEAR_FAIL_SUBSCRIPTION,
});

export const setPageDisputedTransactions = data => {
  return {
    type: SET_PAGE_DISPUTED_TRANSACTIONS,
    payload: data,
  };
};

export const setSizePerPageDisputedTransactions = data => {
  return {
    type: SET_SIZE_PER_PAGE_DISPUTED_TRANSACTIONS,
    payload: data,
  };
};

export const setSearchDisputedTransactions = data => {
  return {
    type: SET_SEARCH_DISPUTED_TRANSACTIONS,
    payload: data,
  };
};

export const setSortFieldDisputedTransactions = data => ({
  type: SET_SORT_FIELD_DISPUTED_TRANSACTIONS,
  payload: data,
});

export const setSortOrderDisputedTransactions = data => ({
  type: SET_SORT_ORDER_DISPUTED_TRANSACTIONS,
  payload: data,
});

export const setOrderRangeDateDisputedTransactions = data => ({
  type: SET_ORDER_RANGE_DATE_DISPUTED_TRANSACTIONS,
  payload: data,
});

export const verifyEvidence = (data, cb) => ({
  type: VERIFY_EVIDENCE,
  payload: data,
  cb: cb,
});
