import { axiosApiAccount, axiosApiAdmin } from "helpers/api_helper";
import {
  COPY_RESET_PASSWORD_LINK_URL,
  EMAIL_RESET_USER,
  FETCH_USER_APPLICATION_URL,
  FETCH_USER_PROFILE_BY_ID,
  USER_SHARED_DASHBOARD_LIMIT,
} from "helpers/url_helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  COPY_RESET_PASSWORD_LINK,
  FETCH_USER_PROFILE,
  RESET_PASSWORD_EMAIL,
  USER_APPLICATION_STUFF,
  USER_SHARED_ACTIVE_DEACTIVE,
} from "./actionTypes";

import {
  fetchUserProfileError,
  fetchUserProfileSuccess,
  setErrorMessage,
  setSuccessMessage,
  userApplicationStuffError,
  userApplicationStuffSuccess,
  userSharedActiveDeactiveError,
  userSharedActiveDeactiveSuccess,
} from "store/actions";
import {
  copyResetPasswordLinkError,
  copyResetPasswordLinkSuccess,
  resetPasswordEmailError,
  resetPasswordEmailSuccess,
} from "./actions";

const fetchUserProfileById = id =>
  axiosApiAccount.get(`${FETCH_USER_PROFILE_BY_ID}/${id}`);
const resetPasswordEmail = data => axiosApiAccount.post(EMAIL_RESET_USER, data);
const copyResetPasswordLink = data =>
  axiosApiAccount.post(COPY_RESET_PASSWORD_LINK_URL, data);
const fetchUserApplicationStuff = data =>
  axiosApiAdmin.get(`${FETCH_USER_APPLICATION_URL}/${data}`);

const fetchUserSharedActiveDeactive = payload =>
  axiosApiAdmin.post(USER_SHARED_DASHBOARD_LIMIT, payload);

function* handleUserSharedActiveDeactive({ payload: { data, cb } }) {
  try {
    const res = yield call(fetchUserSharedActiveDeactive, data);
    if (res?.status && res?.data?.data) {
      yield put(userSharedActiveDeactiveSuccess());
      yield put(setSuccessMessage(res.data.message));
      cb();
    }
  } catch (err) {
    yield put(userSharedActiveDeactiveError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchUserProfile({ payload }) {
  try {
    const res = yield call(fetchUserProfileById, payload);
    if (res.data?.data?.user) {
      yield put(fetchUserProfileSuccess(res.data.data.user));
    }
  } catch (err) {
    yield put(fetchUserProfileError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleResetPasswordEmail({ payload: { data, cb } }) {
  try {
    const res = yield call(resetPasswordEmail, data);
    if (res.status) {
      yield put(resetPasswordEmailSuccess());
      yield put(setSuccessMessage(res.data.message));
      cb();
    }
  } catch (err) {
    yield put(resetPasswordEmailError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleCopyResetPasswordLink({ payload: { data, cb } }) {
  try {
    const res = yield call(copyResetPasswordLink, data);

    if (res.status) {
      cb(res.data.data);
      yield put(copyResetPasswordLinkSuccess(res.data.data));
    }
  } catch (err) {
    yield put(copyResetPasswordLinkError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* handleFetchUserApplicationStuff({ payload }) {
  try {
    const res = yield call(fetchUserApplicationStuff, payload);

    if (res.status) {
      yield put(userApplicationStuffSuccess(res.data));
    }
  } catch (err) {
    yield put(userApplicationStuffError());
    yield put(setErrorMessage(err.response?.data?.message || err.message));
  }
}

function* profileSaga() {
  yield takeEvery(FETCH_USER_PROFILE, handleFetchUserProfile);
  yield takeEvery(RESET_PASSWORD_EMAIL, handleResetPasswordEmail);
  yield takeEvery(USER_APPLICATION_STUFF, handleFetchUserApplicationStuff);
  yield takeEvery(USER_SHARED_ACTIVE_DEACTIVE, handleUserSharedActiveDeactive);
  yield takeEvery(COPY_RESET_PASSWORD_LINK, handleCopyResetPasswordLink);
}

export default profileSaga;
