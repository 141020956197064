import {
  ANALYTICS_USER_PAGE_SIZE,
  ANALYTICS_USER_SET_TZ,
  ANALYTICS_USER_SIZE_PER_PAGE,
  ANALYTICS_USER_SORT_FIELD,
  ANALYTICS_USER_SORT_ORDER,
  CLEAR_SEARCH_QUERY_REPORT,
  GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA,
  GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA_ERROR,
  GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA_SUCCESS,
  GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA,
  GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA_ERROR,
  GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA_SUCCESS,
  GET_ANALYTICS_USERS_GRAPH_DATA,
  GET_ANALYTICS_USERS_GRAPH_DATA_ERROR,
  GET_ANALYTICS_USERS_GRAPH_DATA_SUCCESS,
  GET_ANALYTICS_USER_COUNT,
  GET_ANALYTICS_USER_COUNT_ERROR,
  GET_ANALYTICS_USER_COUNT_SUCCESS,
  GET_ANALYTICS_USER_DATA,
  GET_ANALYTICS_USER_DATA_ERROR,
  GET_ANALYTICS_USER_DATA_SUCCESS,
  SET_ANALYTICS_USERS_ACTIVE_TAB,
  SET_ANALYTICS_USERS_COMPARE_TYPE,
  SET_ANALYTICS_USERS_RANGE_DATE,
  SET_ANALYTICS_USER_CURRENT_SITE,
  SET_BY_DAY,
} from "./actionTypes";

export const setUsersTZ = data => ({
  type: ANALYTICS_USER_SET_TZ,
  payload: data,
});

export const setAnalyticsUserSiteId = data => ({
  type: SET_ANALYTICS_USER_CURRENT_SITE,
  payload: data,
});
export const setAnalyticsUsersActiveTab = data => ({
  type: SET_ANALYTICS_USERS_ACTIVE_TAB,
  payload: data,
});

export const clearSearchQueryReport = () => ({
  type: CLEAR_SEARCH_QUERY_REPORT,
});

export const setAnalyticUsersRangeDate = data => ({
  type: SET_ANALYTICS_USERS_RANGE_DATE,
  payload: data,
});

export const setAnalyticUsersCompareType = data => ({
  type: SET_ANALYTICS_USERS_COMPARE_TYPE,
  payload: data,
});

export const getAnalyticsUserCount = data => ({
  type: GET_ANALYTICS_USER_COUNT,
  payload: data,
});

export const getAnalyticsUserCountSuccess = data => ({
  type: GET_ANALYTICS_USER_COUNT_SUCCESS,
  payload: data,
});

export const getAnalyticsUserCountError = () => ({
  type: GET_ANALYTICS_USER_COUNT_ERROR,
});

export const getAnalyticsRegisterUserGraphData = data => ({
  type: GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA,
  payload: data,
});

export const getAnalyticsRegisterUserGraphDataSuccess = data => ({
  type: GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA_SUCCESS,
  payload: data,
});

export const getAnalyticsRegisterGraphDataError = () => ({
  type: GET_ANALYTICS_REGISTER_USERS_GRAPH_DATA_ERROR,
});

export const getAnalyticsUserGraphData = data => ({
  type: GET_ANALYTICS_USERS_GRAPH_DATA,
  payload: data,
});

export const getAnalyticsUserGraphDataSuccess = data => ({
  type: GET_ANALYTICS_USERS_GRAPH_DATA_SUCCESS,
  payload: data,
});

export const getAnalyticsUserGraphDataError = () => ({
  type: GET_ANALYTICS_USERS_GRAPH_DATA_ERROR,
});

export const getAnalyticsUserData = data => ({
  type: GET_ANALYTICS_USER_DATA,
  payload: data,
});

export const getAnalyticsUserDataSuccess = data => ({
  type: GET_ANALYTICS_USER_DATA_SUCCESS,
  payload: data,
});

export const getAnalyticsUserDataError = () => ({
  type: GET_ANALYTICS_USER_DATA_ERROR,
});

export const analyticsUsersPageSize = data => ({
  type: ANALYTICS_USER_PAGE_SIZE,
  payload: data,
});

export const analyticsUsersPageSizePerPage = data => ({
  type: ANALYTICS_USER_SIZE_PER_PAGE,
  payload: data,
});

export const analyticsUsersPageSortField = data => ({
  type: ANALYTICS_USER_SORT_FIELD,
  payload: data,
});

export const analyticsUsersPageSizeSortOrder = data => ({
  type: ANALYTICS_USER_SORT_ORDER,
  payload: data,
});

export const getAnalyticsSubscribedUserGraphData = data => ({
  type: GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA,
  payload: data,
});

export const getAnalyticsSubscribedUserGraphDataSuccess = data => ({
  type: GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA_SUCCESS,
  payload: data,
});

export const getAnalyticsSubscribedUserGraphDataError = () => ({
  type: GET_ANALYTICS_SUBSCRIBED_USERS_GRAPH_DATA_ERROR,
});

export const setByDay = data => ({
  type: SET_BY_DAY,
  payload: data,
});
