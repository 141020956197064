// Va 24 - 11 - 21
import {
  ADD_EDIT_COUPON,
  ADD_EDIT_COUPON_ERROR,
  ADD_EDIT_COUPON_SUCCESS,
  CLEAR_COUPON_FEATURES,
  DELETE_COUPONS,
  DELETE_COUPON_ERROR,
  DELETE_COUPON_SUCCESS,
  FETCH_COUPON,
  FETCH_COUPON_ERROR,
  FETCH_COUPON_SUCCESS,
  GET_COUPON_DETAILS,
  GET_COUPON_DETAILS_ERROR,
  GET_COUPON_DETAILS_SUCCESS,
  SET_COUPON_PAGE,
  SET_COUPON_SEARCH,
  SET_COUPON_SIZE_PER_PAGE,
  SET_COUPON_SORT_FIELD,
  SET_COUPON_SORT_ORDER,
} from "./actionTypes";

const initialState = {
  couponLoading: false,
  couponEditLoading: false,
  coupons: [],
  couponTotalSize: 0,
  couponPage: 1,
  couponSearch: "",
  couponSizePerPage: 25,
  couponSortField: "coupon_id",
  couponSortOrder: "DESC",
  coupon: null,
};

const couponsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ADD_EDIT_COUPON:
      return {
        ...state,
        couponEditLoading: true,
      };
    case ADD_EDIT_COUPON_SUCCESS:
      return {
        ...state,
        couponEditLoading: false,
      };
    case ADD_EDIT_COUPON_ERROR:
      return {
        ...state,
        couponEditLoading: false,
      };
    case GET_COUPON_DETAILS:
      return {
        ...state,
        couponLoading: true,
      };
    case GET_COUPON_DETAILS_SUCCESS:
      return {
        ...state,
        coupon: payload,
        couponLoading: false,
      };
    case GET_COUPON_DETAILS_ERROR:
      return {
        ...state,
        couponLoading: false,
      };
    case FETCH_COUPON:
      return {
        ...state,
        couponLoading: true,
      };
    case FETCH_COUPON_SUCCESS:
      return {
        ...state,
        coupons: payload.data.rows,
        couponLoading: false,
        couponTotalSize: payload.data.count,
      };
    case FETCH_COUPON_ERROR:
      return {
        ...state,
        couponLoading: false,
      };
    case SET_COUPON_PAGE:
      return {
        ...state,
        couponPage: payload,
      };
    case SET_COUPON_SEARCH:
      return {
        ...state,
        couponSearch: payload,
      };
    case SET_COUPON_SIZE_PER_PAGE:
      return {
        ...state,
        couponSizePerPage: payload,
      };
    case SET_COUPON_SORT_FIELD:
      return {
        ...state,
        couponSortField: payload,
      };
    case SET_COUPON_SORT_ORDER:
      return {
        ...state,
        couponSortOrder: payload,
      };
    case CLEAR_COUPON_FEATURES:
      return {
        ...state,
        couponTotalSize: 0,
        couponPage: 1,
        couponSearch: "",
        couponSizePerPage: 25,
        couponSortField: "coupon_id",
        couponSortOrder: "DESC",
      };
    case DELETE_COUPONS:
      return {
        ...state,
        couponLoading: true,
      };
    case DELETE_COUPON_SUCCESS:
      return {
        ...state,
        couponLoading: false,
      };
    case DELETE_COUPON_ERROR:
      return {
        ...state,
        couponLoading: false,
      };
    default:
      return state;
  }
};

export default couponsReducer;
