import { axiosApiAdmin } from "helpers/api_helper";
import {
  DELETE_MEMBERSHIP_URL,
  GET_MEMBERSHIP_URL,
  GET_PAGE_URL,
  GET_PRODUCT,
  SAVE_MEMBERSHIP_URL,
} from "helpers/url_helper";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  deleteMembershipError,
  deleteMembershipSuccess,
  fetchMembership,
  fetchMembershipError,
  fetchMembershipPagesError,
  fetchMembershipPagesSuccess,
  fetchMembershipProductError,
  fetchMembershipProductSuccess,
  fetchMembershipSuccess,
  saveMembershipError,
  saveMembershipSuccess,
  setErrorMessage,
  setSuccessMessage,
} from "store/actions";
import {
  DELETE_MEMBERSHIP,
  FETCH_MEMBERSHIP,
  FETCH_MEMBERSHIP_PAGES,
  FETCH_MEMBERSHIP_PRODUCT,
  SAVE_MEMBERSHIP,
} from "./actionTypes";

const fetchMembershipCall = data =>
  axiosApiAdmin.post(GET_MEMBERSHIP_URL, data);
const fetchProductCall = data => axiosApiAdmin.post(GET_PRODUCT, data);
const fetchPagesCall = data => axiosApiAdmin.get(GET_PAGE_URL);
const saveMembershipCall = data =>
  axiosApiAdmin.post(SAVE_MEMBERSHIP_URL, data);
const deleteMembershipCall = payload =>
  axiosApiAdmin.get(`${DELETE_MEMBERSHIP_URL}` + "/" + `${payload}`);

function* handleFetchMembership() {
  try {
    const { search, sizePerPage, page, sortField, sortOrder } = yield select(
      state => state.MembershipPlan
    );
    const { siteId } = yield select(state => state.Applications);

    const payload = {
      search,
      page_record: sizePerPage,
      page_no: page,
      sortField,
      sortOrder,
      site_id: siteId,
    };
    const response = yield call(fetchMembershipCall, payload);
    if (response.data.statusCode === 200 && response?.data?.data) {
      yield put(fetchMembershipSuccess(response.data.data));
    } else {
      yield put(fetchMembershipError());
      yield put(setErrorMessage(response.data.message));
    }
  } catch (error) {
    yield put(fetchMembershipError());
    yield put(setErrorMessage(error.response?.data?.message || error.message));
  }
}
function* handleFetchProduct({ payload }) {
  try {
    const response = yield call(fetchProductCall, payload);
    if (response.data.statusCode === 200 && response?.data?.data?.rows) {
      yield put(fetchMembershipProductSuccess(response.data.data.rows));
    } else {
      yield put(fetchMembershipProductError());
      yield put(setErrorMessage(response.data.message));
    }
  } catch (error) {
    yield put(fetchMembershipProductError());
    yield put(setErrorMessage(error.response?.data?.message || error.message));
  }
}
function* handleFetchPages() {
  try {
    const response = yield call(fetchPagesCall);
    if (response?.data?.statusCode === 200 && response?.data?.data?.pages) {
      yield put(fetchMembershipPagesSuccess(response.data.data.pages));
    } else {
      yield put(fetchMembershipPagesError());
      yield put(setErrorMessage(response.data.message));
    }
  } catch (error) {
    yield put(fetchMembershipPagesError());
    yield put(setErrorMessage(error.response?.data.message || error.message));
  }
}
function* handleSubmitMembership({ payload: { data, cb } }) {
  try {
    const response = yield call(saveMembershipCall, data);
    if (response.data.statusCode === 200) {
      yield put(setSuccessMessage(response.data.message));
      yield put(saveMembershipSuccess());
      yield put(fetchMembership());
      if (!!cb) cb();
    } else {
      yield put(saveMembershipError());
      yield put(setErrorMessage(response.data.message));
    }
  } catch (error) {
    yield put(saveMembershipError());
    yield put(setErrorMessage(error.response?.data?.message || error.message));
  }
}
function* handleDeleteMembership({ payload: { data, cb } }) {
  try {
    const response = yield call(deleteMembershipCall, data);
    if (response.data.statusCode === 200) {
      yield put(setSuccessMessage(response.data.message));
      yield put(deleteMembershipSuccess());
      yield put(fetchMembership());
      if (!!cb) cb();
    } else {
      yield put(deleteMembershipError());
      yield put(setErrorMessage(response.data.message));
    }
  } catch (error) {
    yield put(deleteMembershipError());
    yield put(setErrorMessage(error.response?.data?.message || error.message));
  }
}
function* membershipSaga() {
  yield takeEvery(FETCH_MEMBERSHIP, handleFetchMembership);
  yield takeEvery(FETCH_MEMBERSHIP_PRODUCT, handleFetchProduct);
  yield takeEvery(FETCH_MEMBERSHIP_PAGES, handleFetchPages);
  yield takeEvery(SAVE_MEMBERSHIP, handleSubmitMembership);
  yield takeEvery(DELETE_MEMBERSHIP, handleDeleteMembership);
}
export default membershipSaga;
