//list subsctiption report
export const FATCH_LIST_ACTIVE_SUBSCRIPTION = "FATCH_LIST_ACTIVE_SUBSCRIPTION";
export const FATCH_LIST_ACTIVE_SUBSCRIPTION_SUCCESS =
  "FATCH_LIST_ACTIVE_SUBSCRIPTION_SUCCESS";
export const FATCH_LIST_ACTIVE_SUBSCRIPTION_ERROR =
  "FATCH_LIST_ACTIVE_SUBSCRIPTION_ERROR";

export const CLEAR_ACTIVE_SUBSCRIPTION = "CLEAR_ACTIVE_SUBSCRIPTION";

//TABLE SUBSCRIPTION REPORT
export const SET_PAGE_ACTIVE_SUBSCRIPTION = "SET_PAGE_ACTIVE_SUBSCRIPTION";
export const SET_SIZE_PER_PAGE_ACTIVE_SUBSCRIPTION =
  "SET_SIZE_PER_PAGE_ACTIVE_SUBSCRIPTION ";
export const SET_SEARCH_ACTIVE_SUBSCRIPTION = "SET_SEARCH_ACTIVE_SUBSCRIPTION ";
export const SET_SORT_FIELD_ACTIVE_SUBSCRIPTION =
  "SET_SORT_FIELD_ACTIVE_SUBSCRIPTION";
export const SET_SORT_ORDER_ACTIVE_SUBSCRIPTION =
  "SET_SORT_ORDER_ACTIVE_SUBSCRIPTION";

export const FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT =
  "FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT";
export const FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT_SUCCESS =
  "FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT_SUCCESS";
export const FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT_NULL =
  "FATCH_LIST_ACTIVE_SUBSCRIPTION_EXPORT_NULL";

export const SET_ORDER_RANGE_DATE_ACTIVE_SUBSCRIPTION =
  "SET_ORDER_RANGE_DATE_ACTIVE_SUBSCRIPTION";
