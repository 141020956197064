import {
  ANALYTICS_COUPON_SET_TZ,
  CLEAR_COUPON_REPORT,
  CLEAR_SEARCH_QUERY_REPORT,
  FETCH_COUPONS_GRAPH_DATA,
  FETCH_COUPONS_GRAPH_DATA_ERROR,
  FETCH_COUPONS_GRAPH_DATA_SUCCESS,
  FETCH_COUPONS_REPORTS,
  FETCH_COUPONS_REPORTS_ERROR,
  FETCH_COUPONS_REPORTS_SUCCESS,
  FETCH_COUPONS_TABLE_DATA,
  FETCH_COUPONS_TABLE_DATA_ERROR,
  FETCH_COUPONS_TABLE_DATA_SUCCESS,
  SET_ANALYTICS_COUPONS_ACTIVE_TAB,
  SET_ANALYTICS_COUPONS_BY,
  SET_ANALYTICS_COUPONS_COMPARE_TYPE,
  SET_ANALYTICS_COUPONS_PAGE,
  SET_ANALYTICS_COUPONS_RANGE_DATE,
  SET_ANALYTICS_COUPONS_SIZE_PER_PAGE,
  SET_ANALYTICS_COUPONS_SORT_FIELD,
  SET_ANALYTICS_COUPONS_SORT_ORDER,
  SET_ANALYTICS_COUPON_CURRENT_SITE,
  SET_SEARCH_COUPON_DATA,
} from "./actionTypes";

export const setCouponTZ = data => ({
  type: ANALYTICS_COUPON_SET_TZ,
  payload: data,
});

export const setAnalyticsCouponSiteId = data => ({
  type: SET_ANALYTICS_COUPON_CURRENT_SITE,
  payload: data,
});

export const clearCouponReport = () => ({
  type: CLEAR_COUPON_REPORT,
});

export const clearSearchQueryReport = () => ({
  type: CLEAR_SEARCH_QUERY_REPORT,
});

export const setAnalyticsCouponsActiveTab = data => ({
  type: SET_ANALYTICS_COUPONS_ACTIVE_TAB,
  payload: data,
});

export const setAnalyticCouponsRangeDate = data => ({
  type: SET_ANALYTICS_COUPONS_RANGE_DATE,
  payload: data,
});

export const setAnalyticCompareType = data => ({
  type: SET_ANALYTICS_COUPONS_COMPARE_TYPE,
  payload: data,
});

export const setAnalyticsCouponsBy = data => ({
  type: SET_ANALYTICS_COUPONS_BY,
  payload: data,
});

// TABLE
export const setAnalyticsCouponsPage = data => ({
  type: SET_ANALYTICS_COUPONS_PAGE,
  payload: data,
});
export const setAnalyticsCouponsSizePerPage = data => ({
  type: SET_ANALYTICS_COUPONS_SIZE_PER_PAGE,
  payload: data,
});
export const setAnalyticsCouponsSortField = data => ({
  type: SET_ANALYTICS_COUPONS_SORT_FIELD,
  payload: data,
});
export const setAnalyticsCouponsSortOrder = data => ({
  type: SET_ANALYTICS_COUPONS_SORT_ORDER,
  payload: data,
});

// APIS
export const getCouponsReports = data => ({
  type: FETCH_COUPONS_REPORTS,
  payload: data,
});

export const getCouponsReportsSuccess = data => ({
  type: FETCH_COUPONS_REPORTS_SUCCESS,
  payload: data,
});

export const getCouponsReportsError = () => ({
  type: FETCH_COUPONS_REPORTS_ERROR,
});

export const getCouponsGraphData = data => ({
  type: FETCH_COUPONS_GRAPH_DATA,
  payload: data,
});

export const getCouponsGraphDataSuccess = data => ({
  type: FETCH_COUPONS_GRAPH_DATA_SUCCESS,
  payload: data,
});

export const getCouponsGraphDataError = () => ({
  type: FETCH_COUPONS_GRAPH_DATA_ERROR,
});

export const getCouponsTableData = () => ({
  type: FETCH_COUPONS_TABLE_DATA,
});

export const getCouponsTableDataSuccess = data => ({
  type: FETCH_COUPONS_TABLE_DATA_SUCCESS,
  payload: data,
});

export const getCouponsTableDataError = () => ({
  type: FETCH_COUPONS_TABLE_DATA_ERROR,
});

export const setSearchCouponData = data => ({
  type: SET_SEARCH_COUPON_DATA,
  payload: data,
});
